import React, { useState, useEffect } from 'react';
import styles from './arrests.module.css';

import DeleteIcon from '../../../../elements/svg/deleteIcon';

import Button from '../../../../elements/buttons/button';
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect';
import TextHalf from '../../../../elements/viewData/textHalf';


import {isBSE, isBSD, isCSE, isCSD, isALE,
    isLLE, isLLD, isLLP, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD, isLAW, isLAE, isLAP, isLPE,
    isAPE, isAPD,
    isLRE, isLSE, isLSP
} from '../../../../../redux/procedure'


const ArrestsItem = ({item, count, auction, bankAccount, bankAccounts, setBankAccounts}) => {
    //const [restrictionDate, setRestrictionDate] = useState(item.restrictionDate);
    //const [restrictionOrganization, setRestrictionOrganization] = useState(item.restrictionOrganization);



    const [banksGroupe, setBanksGroupe] = useState(item.accountType)
    const [holdersType, setHoldersType] = useState(item.holderType !== null ? item.holderType : null);
    const [percent, setPercent] = useState(item.percent !== null ? item.percent : null);


    const [accounts, setAccounts] = useState(item?.accounts !== null ? item.accounts : [])



    const [typeGroupe, setTypeGroupe] = useState([]);

    useEffect(() => {
        let tmp = []
        for(let ii = 0; ii < bankAccounts.length; ii++){
            if(bankAccounts[ii].accountType !== null) tmp.push(bankAccounts[ii].accountType)
        }
        setTypeGroupe(tmp)
    }, [])

    function elementDisabledCreate(){
        if(auction.status !== null){
            if(auction.status !== 'active_rectification'){
                let tmpStartData = null
                let tmpEndData = null
                    if(auction.rectificationPeriod !== null){
                        tmpStartData = new Date(auction.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(auction.rectificationPeriod.endDate).getTime()
                    }
                if(
                    (
                        isBSE(auction.sellingMethod) || isBSD(auction.sellingMethod) ||
                        isALE(auction.sellingMethod) ||
                        isCSE(auction.sellingMethod) || isCSD(auction.sellingMethod) ||
                        isRLE(auction.sellingMethod) || isRLD(auction.sellingMethod) ||
                        isCLE(auction.sellingMethod) || isCLD(auction.sellingMethod) ||
                        isNLE(auction.sellingMethod) || isNLD(auction.sellingMethod) ||
                        isLAE(auction.sellingMethod) || isLAW(auction.sellingMethod) || isLAP(auction.sellingMethod) ||
                        isAPE(auction.sellingMethod) || isAPD(auction.sellingMethod)
                        || isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)
                        ) &&
                    (tmpStartData < Date.now() && tmpEndData > Date.now())
                ){
                    return null
                }else if(isBRE(auction.sellingMethod) || isBRD(auction.sellingMethod) || isBRW(auction.sellingMethod)){
                    if(
                        auction.status === 'complete' ||
                        auction.status === 'cancelled' ||
                        auction.status === 'unsuccessful'
                    ){
                        return 'disabled'
                    }else if(
                        auction.status === 'active_auction' ||
                        auction.status === 'pending_payment' ||
                        auction.status === 'active_qualification'
                    ){
                        return null
                    }else if(auction.status === 'active_awarded'){
                        if(auction.contracts !== null && auction.contracts.length > 0){
                            let tmp
                            tmp = auction.contracts.filter(
                                (e) => {
                                    return (e.status === 'pending')
                                }
                            )
                            if(tmp.length > 0) return 'disabled'
                        }
                    }
                    return null
                }else if(isSPE(auction.sellingMethod) || isSPD(auction.sellingMethod)){
                    return isDisabled()
                }else if(isLPE(auction.sellingMethod)){
                    return isDisabled()
                }else{
                    return 'disabled'
                }
            }
        }
    }

    function optionBankAccount(){
        if( 
            isLRE(auction.sellingMethod) || isLSE(auction.sellingMethod) || isLSP(auction.sellingMethod)
        ){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                ]
        }
        if( 
            isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)
        ){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                    {value: 'other', name: 'Інші платежі'},
                    {value: 'advancePayment', name: 'Рахунок для авансового внеску'},
                    {value: 'lease', name: 'Рахунок для оплати орендних платежів'}
                ]
        }
        if( 
            isALE(auction.sellingMethod)
        ){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if( 
           isBRE(auction.sellingMethod) || isBRD(auction.sellingMethod) || isBRW(auction.sellingMethod)
        ){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    /*{value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'organizer', name: 'Організатор'},
                    {value: 'advancePayment', name: 'Авансовий внесок'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                    {value: 'lease', name: 'Орендні платежі'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'other', name: 'Інші платежі'}*/
                ]
        }
        if(isRLE(auction.sellingMethod) || isRLD(auction.sellingMethod)){
            return [
                {value: 'lease', name: 'Орендні платежі', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'organizer', name: 'Організатор'}
            ]
        }
        if(isCLE(auction.sellingMethod) || isCLD(auction.sellingMethod)){
            return [
                {value: 'lease', name: 'Орендні платежі', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'other', name: 'Інші платежі'}
            ]
        }
        if(isSPE(auction.sellingMethod) || isSPD(auction.sellingMethod)){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'payment', name: 'Плата за лот'},
                ]
        }
        if(isNLE(auction.sellingMethod) || isNLD(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if(isLAE(auction.sellingMethod) || isLAP(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
                ]
        }
        if(isLAW(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
                ]
        }
        if(isLPE(auction.sellingMethod)){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'payment', name: 'Плата за лот'},
                ]
        }
        if(isAPE(auction.sellingMethod)){
            return [
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if(isAPD(auction.sellingMethod)){
            return [
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }

    function holders(){
        return [
            {value: "balanceHolder", name: "Балансоутримувач"},
            {value: "landlord", name: "Орендодавець"},
            {value: "budget", name: "Бюджет"},
        ]
    }
    function addOptionHolders(type){
        return holders()
        .filter((i) =>{
            let flagAdvancePaymentBalanceHolder = false
            let flagAdvancePaymentLandlord = false
            let flagAdvancePaymentBudget = false
            let flagLeaseBalanceHolder = false
            let flagLeaseLandlord = false
            let flagLeaseBudget = false

            for(let ii = 0; ii < bankAccounts.length; ii++){
                if(bankAccounts[ii].accountType === "advancePayment"){
                    if(bankAccounts[ii].holderType === 'balanceHolder'){
                        flagAdvancePaymentBalanceHolder = true
                    }
                    if(bankAccounts[ii].holderType === 'landlord'){
                        flagAdvancePaymentLandlord = true
                    }
                    if(bankAccounts[ii].holderType === 'budget'){
                        flagAdvancePaymentBudget = true
                    }
                }
                if(bankAccounts[ii].accountType === "lease"){
                    if(bankAccounts[ii].holderType === 'balanceHolder'){
                        flagLeaseBalanceHolder = true
                    }
                    if(bankAccounts[ii].holderType === 'landlord'){
                        flagLeaseLandlord = true
                    }
                    if(bankAccounts[ii].holderType === 'budget'){
                        flagLeaseBudget = true
                    }
                }
            }
            if(type === "advancePayment"){
                if(i.value === "balanceHolder"){
                    if(!flagAdvancePaymentBalanceHolder) return i
                }
                if(i.value === "landlord"){
                    if(!flagAdvancePaymentLandlord) return i
                }
                if(i.value === "budget"){
                    if(!flagAdvancePaymentBudget) return i
                }
            }
            if(type === "lease"){
                if(i.value === "balanceHolder"){
                    if(!flagLeaseBalanceHolder) return i
                }
                if(i.value === "landlord"){
                    if(!flagLeaseLandlord) return i
                }
                if(i.value === "budget"){
                    if(!flagLeaseBudget) return i
                }
            }
        })
        .map((i) =>{
            return i
        })
    }

    function addOption(){
        return optionBankAccount()
        .filter((i) =>{
            let flag = false
            for(let ii = 0; ii < typeGroupe.length; ii++){
                if(i.value === typeGroupe[ii]) flag = true
                if(i.value === "advancePayment" || i.value === "lease") flag = false
            }
            if(!flag) return i
        })
        .map((i) =>{
            return i
        })
    }

    function hType(data){
        if(data === "balanceHolder") return "Балансоутримувач"
        if(data === "landlord") return "Орендодавець"
        if(data === "budget") return "Бюджет"
        return null
    }

    function holderType(){
        if((isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)) && (banksGroupe === "advancePayment" || banksGroupe === "lease")){
            return (
                <>
                    <div className={styles.arrestLine}>
                        <div className={styles.arrestLineElem}>
                            <Select 
                                data={{
                                    label:"Тип власника об'єкта",
                                    target: holdersType,
                                    //descr: "Обрати банківський рахунок"
                                }}
                                onChange={ (e) => {
                                    setHoldersType(e)
                                    let tmpAll = [...bankAccounts]
                                    tmpAll[count].holderType = e
                                    setBankAccounts(tmpAll)
                                }}
                                options={addOptionHolders(banksGroupe)}
                                disabled={isDisabled()}
                            />
                        </div>
                        <div className={styles.arrestLineElem}>
                            <Input
                                label="Відсоток"
                                value={percent}
                                onChange={(e)=>{ 
                                    setPercent(e)
                                    let tmpAll = [...bankAccounts]
                                    tmpAll[count].percent = Number(e)
                                    setBankAccounts(tmpAll)
                                }}
                                //required
                                disabled={isDisabled()}
                            />
                        </div>
                    </div>
                    <div className={styles.arrestLine}>
                        {holdersType !== null
                            ? <div className={styles.arrestLineElem}>
                                <TextHalf 
                                    title="Обраний тип власника об'єкта"
                                    data={hType(holdersType)}
                                />
                            </div>
                            : null
                        }
                    </div>
                </>
            )
        }
        return null
    }

    function selectBankAuction(){
        let tmp = null;
        if(bankAccount !== null){
            tmp = bankAccount
            .filter((i) => {
                return i.accountIdentifications !== null
            })
            .filter((i) => {
                let tmpUAH = false
                let tmpUSD = false
                let tmpEUR = false
                for(let ii = 0; ii < accounts.length; ii++){
                    if(accounts[ii].currency === "UAH") tmpUAH = true
                    if(accounts[ii].currency === "USD") tmpUSD = true
                    if(accounts[ii].currency === "EUR") tmpEUR = true
                }
                if(i.currency === 'UAH'){
                    if(!tmpUAH) return i    
                }
                if(i.currency === 'USD'){
                    if(!tmpUSD) return i    
                }
                if(i.currency === 'EUR'){
                    if(!tmpEUR) return i    
                }
            })
            .map(
                (i) => {
                    let block = <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div >{i.bankName.hasOwnProperty("uk_UA") === true ? i.bankName.uk_UA : i.bankName}</div>
                        <div style={{marginRight: '0', color: '#92929D'}}>
                            {`${i.currency} - 
                            ${i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null
                            /*i.accountIdentifications[i.accountIdentifications.length - 1].id}*/}`}
                        </div>
                    </div>
                    //return {value: i.accountIdentifications[i.accountIdentifications.length - 1].id + i.description, name: block}
                    let tmpSave = {
                        bankName: i.bankName.hasOwnProperty("uk_UA") === true ? {uk_UA: i.bankName.uk_UA} : {uk_UA: i.bankName},
                        description: i.description.hasOwnProperty("uk_UA") === true ? {uk_UA: i.description.uk_UA} : {uk_UA: i.description},
                        accountHolder: i.accountHolder,
                        accountIdentifications: [{
                            id: i.accountIdentifications[i.accountIdentifications.length - 1].id,
                            description: i.accountIdentifications[i.accountIdentifications.length - 1].description,
                            scheme: i.accountIdentifications[i.accountIdentifications.length - 1].scheme,
                        }],
                        currency: i.currency
                    }
                    return {value: tmpSave, name: block}
                }
            )
        }
        return tmp
    }
    function selectBankAuctionALL(){
        let tmp = null;
        if(bankAccount !== null){
            tmp = bankAccount
            .filter((i) => {
                return i.accountIdentifications !== null
            })
            .map(
                (i) => {
                    let block = <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div >{i.bankName.hasOwnProperty("uk_UA") === true ? i.bankName.uk_UA : i.bankName}</div>
                        <div style={{marginRight: '0', color: '#92929D'}}>
                            {`${i.currency} - 
                            ${i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null
                            /*i.accountIdentifications[i.accountIdentifications.length - 1].id}*/}`}
                        </div>
                    </div>
                    //return {value: i.accountIdentifications[i.accountIdentifications.length - 1].id + i.description, name: block}
                    let tmpSave = {
                        bankName: i.bankName.hasOwnProperty("uk_UA") === true ? {uk_UA: i.bankName.uk_UA} : {uk_UA: i.bankName},
                        description: i.description.hasOwnProperty("uk_UA") === true ? {uk_UA: i.description.uk_UA} : {uk_UA: i.description},
                        accountHolder: i.accountHolder,
                        accountIdentifications: [{
                            id: i.accountIdentifications[i.accountIdentifications.length - 1].id,
                            description: i.accountIdentifications[i.accountIdentifications.length - 1].description,
                            scheme: i.accountIdentifications[i.accountIdentifications.length - 1].scheme,
                        }],
                        currency: i.currency
                    }
                    return {value: tmpSave, name: block}
                }
            )
        }
        return tmp
    }

    function accountsType(countMain){
        let tmp = null
        if(accounts !== null && accounts.length > 0){
            tmp = accounts
            .map((i, count) => {
                return (
                    <div className={styles.banksNameLine}>
                        <div style={setDelStyle('#696974')}>{i.bankName.hasOwnProperty("uk_UA") === true ? i.bankName.uk_UA : i.bankName}</div>
                        <div style={setDelStyle('#92929D')}>
                            {i.currency}
                            <div style={{fontSize: '13px'}}>{i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null}</div>
                        </div>
                        <div style={setDelStyle('#92929D')}>{i.description !== null && i.description.hasOwnProperty("uk_UA") === true ? i.description.uk_UA : i.description}</div>
                        <button className={styles.deleteBtn}
                            title='Видалити'
                            variant="contained"
                            onClick={
                                () => {
                                    if(!isDisabled()){
                                        let tmp = []
                                        for(let ii = 0; ii < accounts.length; ii++){
                                            if(ii !== count) tmp.push(accounts[ii])
                                        }
                                        setAccounts(tmp)
                                        let tmpAll = [...bankAccounts]
                                        tmpAll[countMain].accounts = tmp
                                        setBankAccounts(tmpAll)
                                    }
                                }
                            }
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                )
            })
        }
        return tmp
    }

    function setDelStyle(color){
        //if (!bankDel)
        return {color: color, display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
        //else
        return {color: '#BFBFC6', display: 'flex', flexFlow: 'row', gap: '8px', alignItems: 'center'}
    }

    function isDisabled(){
        if(auction.status === 'active_tendering') return true
        return false
    }

    return (
        <div className={styles.bankBlock}>
            <div className={styles.arrestLine}>
                <div className={styles.arrestLineElem}>
                    <Select 
                        data={{
                            label:"Банківські рахунки",
                            target: banksGroupe,
                            descr: "Обрати банківський рахунок"
                        }}
                        onChange={ 
                            (e) => {
                                setBanksGroupe(e)
                                let tmpAll = [...bankAccounts]
                                tmpAll[count].accountType = e
                                setBankAccounts(tmpAll)
                                let tmp = []
                                for(let ii = 0; ii < bankAccounts.length; ii++){
                                    if(bankAccounts[ii].accountType !== null) tmp.push(bankAccounts[ii].accountType)
                                }
                                setTypeGroupe(tmp)
                            }
                        }
                        options={addOption()}
                        disabled={isLAW(auction.sellingMethod) ? false : elementDisabledCreate()}
                    />
                </div>
                <div className={styles.arrestLineElem}>
                    <Select 
                        data={{
                            label:"Рахунки",
                            target: null,
                            descr: "Обрати банківський рахунок"
                        }}
                        onChange={ (e) => {
                            //setBank(e)
                            let tmpAcc = [...accounts, e]
                            setAccounts(tmpAcc)
                            let tmpAll = [...bankAccounts]
                            tmpAll[count].accounts = tmpAcc
                            setBankAccounts(tmpAll)

                        }}
                        options={isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod) ? selectBankAuction() : selectBankAuctionALL()}
                        disabled={isDisabled()}
                    />
                </div>
            </div>
            <div  className={styles.banksWrap}>{accountsType(count)}</div>
            {holderType()}
            <div className={styles.arrestLine + " " + styles.arrestLineElem}>
                {bankAccounts[count].status !== true
                ? <Button
                        height={'40px'}
                        font={'14px'}
                        color='blue'
                        data={'Видалити групу'}
                        onClick={()=>{
                            let tmpAll = [...bankAccounts]
                            tmpAll[count].status = true
                            setBankAccounts(tmpAll)
                        }}
                        disabled={isDisabled()}
                    />
                : <Button
                        height={'40px'}
                        font={'14px'}
                        color='blue'
                        data={'Відмінити видалення групи'}
                        onClick={()=>{
                            let tmpAll = [...bankAccounts]
                            tmpAll[count].status = false
                            setBankAccounts(tmpAll)
                        }}
                        disabled={isDisabled()}
                    />
                }
            </div>
        </div>
    )
}

export default ArrestsItem