import React from 'react';
import style from './invalidValue.module.css';

import {connect} from 'react-redux';

import {
    togetherApiSaveValue,
    auctionBidEndPay,
} from '../../../../../../redux/togetherApi.js';
import {setUniversalError} from '../../../../../../redux/actions.js';

const InvalidValue = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>{pushText(props)}</div>
                {buttonBlok(props)}
            </div>
        </div>
    )
}

function pushText(props){
    if(props.togetherApiPage.saveValue.type === 1){
        return 'Пропозиція нижче необхідної. Збільшить суму.'
    }else{
        return `Ваша пропозиція ${props.togetherApiPage.saveValue.stavka.tmpMany} перевищує минулу більш ніж у 10 разів. Ви впевнені що хочете продовжити?`
    }
}

function buttonBlok(props){
    if(props.togetherApiPage.saveValue.type === 1){
        return <div className={style.popup_message_button}
            onClick={
                () => {
                    props.togetherApiSaveValue(null)
                }
            }
        >
            Закрити
        </div>
    }else{
        return (
            <>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.togetherApiSaveValue(null)
                        }
                    }
                >
                    Ні
                </div>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            postBidUpdate(props)
                            let tmpArrBid = []
                            if(props.togetherApiPage.arrayAuctionsEndPay === null){
                                tmpArrBid.push({id: props.togetherApiPage.saveValue.stavka.data, value: Number(props.togetherApiPage.saveValue.stavka.tmpMany)})
                            }else{
                                tmpArrBid = props.togetherApiPage.arrayAuctionsEndPay
                                let flag = null
                                for(let mm = 0; mm < tmpArrBid.length; mm++){
                                    if(tmpArrBid[mm].id === props.togetherApiPage.saveValue.stavka.data) flag = mm
                                }
                                if(flag !== null){
                                    tmpArrBid[flag] = {id: props.togetherApiPage.saveValue.stavka.data, value: Number(props.togetherApiPage.saveValue.stavka.tmpMany)}
                                }else{
                                    tmpArrBid.push({id: props.togetherApiPage.saveValue.stavka.data, value: Number(props.togetherApiPage.saveValue.stavka.tmpMany)})
                                }
                            }
                            props.auctionBidEndPay(tmpArrBid)
                        }
                    }
                >
                    Так
                </div>
            </>
        )
    }
}

async function postBidUpdate(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/room/auctions/${props.togetherApiPage.saveValue.stavka.tmpObj}/bid?acc_token=${props.togetherApiPage.saveValue.stavka.tmpObj2}&bidder_id=${props.togetherApiPage.saveValue.stavka.tmpObj3}`
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            /*"bid": Number(props.togetherApiPage.saveValue)*/
            "bid": props.togetherApiPage.saveValue.stavka.tmpMany
        })
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let text = `Ставку оновлено на ${props.togetherApiPage.saveValue.stavka.tmpMany}`
            //let tmp = JSON.stringify({title: 'Апдейт ставки', message: JSON.stringify(json1)})
            let tmp = JSON.stringify({title: 'Оновлення ставки', message: text})
            props.setUniversalError(tmp)
            let tmpMany = document.getElementById(props.togetherApiPage.saveValue.stavka.data)
            tmpMany.value = ''
            props.togetherApiSaveValue(null)
        }else{
            let tmpMany = document.getElementById(props.togetherApiPage.saveValue.stavka.data)
            tmpMany.value = ''
            props.togetherApiSaveValue(null)
            //Тут надо подключать функцию с сокетом
            //props.togetherApiActiveAuctions(json1)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    togetherApiSaveValue,
    setUniversalError,
    auctionBidEndPay,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvalidValue)