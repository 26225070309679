import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount, addAuctionPeriodProperty, addBankProperty} from './utilsCreateBody'

export function createBodyNLE(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotId')
        body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        body = addProperty(data, body, 'sellingMethod')
        body = addPropertyMonga(data, body, 'x_documentRequirements')
        body = addPropertyMonga(data, body, 'x_additionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addProperty(data, body, 'valueAddedTaxCharged')
        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'previousAuctionId')
        body = addPropertyDiscount(data, body, 'discount')
        body = addBankProperty(data, body, 'banks')
        return body
    }
    return null
}