import React from 'react';
import styles from './realEstateProps.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    changeCreateItemDgfRealEstatePropsTotalBuildingArea,
    changeCreateItemDgfRealEstatePropsTotalObjectArea,
    changeCreateItemDgfRealEstatePropsUsableArea,
    changeCreateItemDgfRealEstatePropsConstructionYear,
    changeCreateItemDgfRealEstatePropsConstructionTechnology,
    changeCreateItemDgfRealEstatePropsLivingArea,
    changeCreateItemDgfRealEstatePropsKitchenArea,
    changeCreateItemDgfRealEstatePropsLandArea,
    changeCreateItemDgfRealEstatePropsLocationInBuilding,
    changeCreateItemDgfRealEstatePropsFloors,
    changeCreateItemDgfRealEstatePropsGeneralCondition,
    changeCreateItemDgfRealEstatePropsServiceElectricity,
    changeCreateItemDgfRealEstatePropsPowerSupplyCapacity,
    changeCreateItemDgfRealEstatePropsPowerSupplyClass,
    changeCreateItemDgfRealEstatePropsServiceWater,
    changeCreateItemDgfRealEstatePropsServiceSewerage,
    changeCreateItemDgfRealEstatePropsServiceGas,
    changeCreateItemDgfRealEstatePropsServiceCentralHeating,
    changeCreateItemDgfRealEstatePropsServiceAutonomousHeating,
    changeCreateItemDgfRealEstatePropsServiceHeatingCounter,
    changeCreateItemDgfRealEstatePropsServiceVentilation,
    changeCreateItemDgfRealEstatePropsServiceAirConditioning,
    changeCreateItemDgfRealEstatePropsServicePhone,
    changeCreateItemDgfRealEstatePropsServiceTV,
    changeCreateItemDgfRealEstatePropsServiceInternet,
    changeCreateItemDgfRealEstatePropsServiceElevator,
    changeCreateItemDgfRealEstatePropsServiceSecurityAlarm,
    changeCreateItemDgfRealEstatePropsServiceFireAlarm,
    changeCreateItemDgfRealEstatePropsServicesDescription,
    changeCreateItemDgfRealEstatePropsServicesAccounting,
    changeCreateItemDgfRealEstatePropsLandTax,
} from '../../../../../redux/actions.js';

const WindowItems = ( props ) => {
    return (
        <div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Загальна площа будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.totalBuildingArea}
                        onChange={(event)=>{
                            //totalBuildingArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsTotalBuildingArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Загальна площа об''єкта в будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.totalObjectArea}
                        onChange={(event)=>{
                            //totalObjectArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsTotalObjectArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Корисна площа об''єкта в будівлі, кв.м."
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.usableArea}
                        onChange={(event)=>{
                            //usableArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsUsableArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Рік будівництва"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.constructionYear}
                        onChange={(event)=>{
                            //constructionYear	integer($int64)
                            props.changeCreateItemDgfRealEstatePropsConstructionYear(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Технологія будівництва"
                        value={props.createItems.dgf.realEstateProps.constructionTechnology}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsConstructionTechnology(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesConstructionTechnology(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Житлова площа, кв. м"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.livingArea}
                        onChange={(event)=>{
                            //livingArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsLivingArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа кухні, кв. м"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.kitchenArea}
                        onChange={(event)=>{
                            //kitchenArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsKitchenArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа ділянки, кв. м"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.landArea}
                        onChange={(event)=>{
                            //landArea	number($float)
                            props.changeCreateItemDgfRealEstatePropsLandArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Місце розташування об’єкта в будівлі"
                        value={props.createItems.dgf.realEstateProps.locationInBuilding}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsLocationInBuilding(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesLocationInBuilding(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер поверху або поверхів"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.floors}
                        onChange={(event)=>{
                            //floors	string
                            props.changeCreateItemDgfRealEstatePropsFloors(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Технічний стан об'єкта"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.generalCondition}
                        onChange={(event)=>{
                            //generalCondition	string
                            props.changeCreateItemDgfRealEstatePropsGeneralCondition(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Чи приєднаний об'єкт до електромережі"
                        value={props.createItems.dgf.realEstateProps.serviceElectricity}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceElectricity(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Потужність електромережі, кВт"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.powerSupplyCapacity}
                        onChange={(event)=>{
                            //powerSupplyCapacity	number($float)
                            props.changeCreateItemDgfRealEstatePropsPowerSupplyCapacity(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Ступінь потужності електромережі"
                        value={props.createItems.dgf.realEstateProps.powerSupplyClass}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsPowerSupplyClass(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesPowerSupplyClass(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Водозабезпечення присутнє"
                        value={props.createItems.dgf.realEstateProps.serviceWater}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceWater(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Каналізація присутня"
                        value={props.createItems.dgf.realEstateProps.serviceSewerage}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceSewerage(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Газифікація присутня"
                        value={props.createItems.dgf.realEstateProps.serviceGas}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceGas(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Централізоване опалення присутнє"
                        value={props.createItems.dgf.realEstateProps.serviceCentralHeating}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceCentralHeating(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Автономне опалення присутнє"
                        value={props.createItems.dgf.realEstateProps.serviceAutonomousHeating}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceAutonomousHeating(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Лічильник опалення присутній"
                        value={props.createItems.dgf.realEstateProps.serviceHeatingCounter}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceHeatingCounter(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Вентиляція присутня"
                        value={props.createItems.dgf.realEstateProps.serviceVentilation}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceVentilation(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Кондиціонування присутнє"
                        value={props.createItems.dgf.realEstateProps.serviceAirConditioning}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceAirConditioning(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Телефонізація присутня"
                        value={props.createItems.dgf.realEstateProps.servicePhone}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServicePhone(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Телебачення присутнє"
                        value={props.createItems.dgf.realEstateProps.serviceTV}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceTV(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Інтернет присутній"
                        value={props.createItems.dgf.realEstateProps.serviceInternet}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceInternet(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Ліфт присутній"
                        value={props.createItems.dgf.realEstateProps.serviceElevator}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceElevator(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Охоронна сигналізація присутня"
                        value={props.createItems.dgf.realEstateProps.serviceSecurityAlarm}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceSecurityAlarm(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Пожежна сигналізація присутня"
                        value={props.createItems.dgf.realEstateProps.serviceFireAlarm}
                        onChange={(event)=>{
                            props.changeCreateItemDgfRealEstatePropsServiceFireAlarm(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=""
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Додаткова інформація щодо комунікацій, що є в об''єкті"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.servicesDescription}
                        onChange={(event)=>{
                            //servicesDescription	string
                            props.changeCreateItemDgfRealEstatePropsServicesDescription(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.servicesAccounting}
                        onChange={(event)=>{
                            //servicesAccounting	string
                            props.changeCreateItemDgfRealEstatePropsServicesAccounting(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                        variant="outlined"
                        value={props.createItems.dgf.realEstateProps.landTax}
                        onChange={(event)=>{
                            //landTax	string
                            props.changeCreateItemDgfRealEstatePropsLandTax(event.target.value)
                        }}
                    />
                </div>
            </div>
    </div>
    )
}

function dictionariesPowerSupplyClass(props){
    if(props.dictionaries.powerSupplyClass !== null){
        return props.dictionaries.powerSupplyClass.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function dictionariesLocationInBuilding(props){
    if(props.dictionaries.floorType !== null){
        return props.dictionaries.floorType.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function dictionariesConstructionTechnology(props){
    if(props.dictionaries.constructionTechnology !== null){
        return props.dictionaries.constructionTechnology.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    changeCreateItemDgfRealEstatePropsTotalBuildingArea,
    changeCreateItemDgfRealEstatePropsTotalObjectArea,
    changeCreateItemDgfRealEstatePropsUsableArea,
    changeCreateItemDgfRealEstatePropsConstructionYear,
    changeCreateItemDgfRealEstatePropsConstructionTechnology,
    changeCreateItemDgfRealEstatePropsLivingArea,
    changeCreateItemDgfRealEstatePropsKitchenArea,
    changeCreateItemDgfRealEstatePropsLandArea,
    changeCreateItemDgfRealEstatePropsLocationInBuilding,
    changeCreateItemDgfRealEstatePropsFloors,
    changeCreateItemDgfRealEstatePropsGeneralCondition,
    changeCreateItemDgfRealEstatePropsServiceElectricity,
    changeCreateItemDgfRealEstatePropsPowerSupplyCapacity,
    changeCreateItemDgfRealEstatePropsPowerSupplyClass,
    changeCreateItemDgfRealEstatePropsServiceWater,
    changeCreateItemDgfRealEstatePropsServiceSewerage,
    changeCreateItemDgfRealEstatePropsServiceGas,
    changeCreateItemDgfRealEstatePropsServiceCentralHeating,
    changeCreateItemDgfRealEstatePropsServiceAutonomousHeating,
    changeCreateItemDgfRealEstatePropsServiceHeatingCounter,
    changeCreateItemDgfRealEstatePropsServiceVentilation,
    changeCreateItemDgfRealEstatePropsServiceAirConditioning,
    changeCreateItemDgfRealEstatePropsServicePhone,
    changeCreateItemDgfRealEstatePropsServiceTV,
    changeCreateItemDgfRealEstatePropsServiceInternet,
    changeCreateItemDgfRealEstatePropsServiceElevator,
    changeCreateItemDgfRealEstatePropsServiceSecurityAlarm,
    changeCreateItemDgfRealEstatePropsServiceFireAlarm,
    changeCreateItemDgfRealEstatePropsServicesDescription,
    changeCreateItemDgfRealEstatePropsServicesAccounting,
    changeCreateItemDgfRealEstatePropsLandTax,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)