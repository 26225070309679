import React, {useState} from 'react';

import {connect} from 'react-redux';

import procedure from '../../../redux/procedure';

import {changeAwardRejectPopUpStatus,} from '../../../redux/actions/awards/awards.js';

import {setAwardAllUserPopUp, setAwardAllUserPopUpUser} from '../../../redux/actions.js';
import {setDataAddress} from '../../../redux/createAddress';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../redux/actions/contracts/contracts.js';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ContractOther from './../../product/contractNew/contractForm/other'
import ContractLreLseLsp from './../../product/contractNew/contractForm/LreLspLse'
import ContractBRW from './../../product/contractNew/contractForm/Brw'
import ContractSPE from './../../product/contractNew/contractForm/Spe'
import ContractNLE from './../../product/contractNew/contractForm/nle'
import ContractLAE from './../../product/contractNew/contractForm/lae'
import ContractLAP from './../../product/contractNew/contractForm/lap'
import ContractLPE from './../../product/contractNew/contractForm/lpe'
import ContractAPE from './../../product/contractNew/contractForm/ape'

import ContractREM from "./view/ContractREM";
import styles from "../award/edit/bySellingMethod/awardEditModule.module.css";

const components = {
    [procedure.SELLING_METHOD_RENEWABLES_MULTI_AWARDS]: ContractREM,
}

const ContractList = (props) => {
    const [slim, setSlim] = useState({state: false, text: 'Розгорнути'})

    function Slimmer() {
        slim.state ? setSlim({state: false, text: 'Розгорнути'}) : setSlim({state: true, text: 'Згорнути'})
    }

    function renderListOld(props) {
        switch (true) {
            case procedure.isRCE(props.auction.sellingMethod):
            case procedure.isRCD(props.auction.sellingMethod):
                return null
            case procedure.isBRW(props.auction.sellingMethod):
                return <ContractBRW/>
            case procedure.isSPE(props.auction.sellingMethod):
            case procedure.isSPD(props.auction.sellingMethod):
                return <ContractSPE/>
            case procedure.isLRE(props.auction.sellingMethod):
            case procedure.isLSE(props.auction.sellingMethod):
            case procedure.isLSP(props.auction.sellingMethod):
                return <ContractLreLseLsp/>
            case procedure.isNLE(props.auction.sellingMethod):
            case procedure.isNLD(props.auction.sellingMethod):
                return <ContractNLE/>
            case procedure.isLAE(props.auction.sellingMethod):
            case procedure.isLAW(props.auction.sellingMethod):
                return <ContractLAE/>
            case procedure.isLAP(props.auction.sellingMethod):
                return <ContractLAP/>
            case procedure.isLPE(props.auction.sellingMethod):
                return <ContractLPE/>
            case procedure.isAPE(props.auction.sellingMethod):
            case procedure.isAPD(props.auction.sellingMethod):
                return <ContractAPE/>
            case procedure.isREM(props.auction.sellingMethod):
                return <ContractREM/>
            default:
                return <ContractOther/>
        }
    }

    if (props.auction?.contracts?.length > 0) {
        if (!components.hasOwnProperty(props?.auction?.sellingMethodClear)) {
            return renderListOld(props)
        }

        let contractComponentListRendered = []
        if (props?.auction?.contracts?.length > 0) {
            contractComponentListRendered = props.auction.contracts.map(
                (contract, count) => {
                    const Component = components[props?.auction?.sellingMethodClear]
                    return <Component contract={contract} key={contract.id} count={count}/>
                }
            )
        }

        if (contractComponentListRendered?.length > 0) {
            return (
                <div className={styles.awardBlock}>
                    <div className={styles.awardBlock_title}>Кваліфікація учасників договір</div>
                    <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                    {slim.state ?
                        <div className={styles.awardBlockContent}>
                            {contractComponentListRendered}
                        </div> : null
                    }
                </div>
            )
        }

    }
    return null
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractList)
