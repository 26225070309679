import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionTitle} from '../../../redux/actions.js';


import {TranslationsUk} from '../../../redux/translations.uk.js';

import TextInput from '../../elements/textInput'
import {setDataAddress,} from '../../../redux/createAddress';

const WindowAuctionTitle = ( props ) => {
    let data = {}
    data.styles = styles.inputText
    data.name = TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.title.legalName
    data.value = props.auctionsMy.title !== null ? props.auctionsMy.title.hasOwnProperty('uk_UA') ? props.auctionsMy.title.uk_UA : props.auctionsMy.title : props.auctionsMy.title
    data.payloadType = 'CREATE_AUCTION_TITLE'
    data.function = props.setDataAddress
    data.help = ""
    data.status = "00"
    return (
                <TextInput
                    data={data}
                />
    )
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionTitle)
