import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'
import TextFullHeader from '../../structureLot/auctionType/textFullHeader'

const LotPreviousAuctionBidderString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('previousAuctionBidder') === true){
            if(props.auctionId.previousAuctionBidder !== null){
                return (
                    <>
                        <TextFullHeader
                            title="Учасник з попереднього аукціону"
                            data=''
                        />
                        {viewName(props.auctionId.previousAuctionBidder)}
                        {viewRepresentativeInfo(props.auctionId.previousAuctionBidder)}
                        {viewAddress(props.auctionId.previousAuctionBidder)}
                        {viewIdentifier(props.auctionId)}
                    </>
                )
            }
        }
    }
    return null
}

function viewName(data){
    if(data.hasOwnProperty('name') === true){
        if(data.name !== null){
            return (
                <TextHalf
                    title="Повна юридична назва організації або ПІБ"
                    data={data.name.uk_UA}
                />
            )
        }
    }
    return null
}

function viewRepresentativeInfo(data){
    if(data.hasOwnProperty('representativeInfo') === true){
        if(data.representativeInfo !== null){
            return (
                <TextHalf
                    title="Інформація щодо підтвердження повноважень"
                    data={data.representativeInfo}
                />
            )
        }
    }
    return null
}


function viewAddress(data){
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            return (
                <>
                    <TextFullHeader
                        title="Адреса"
                        data=''
                    />
                    <TextHalf
                        title="Країна"
                        data={data.address.countryName.uk_UA}
                    />
                    <TextHalf
                        title="Поштовий індекс"
                        data={data.address.postalCode}
                    />
                    <TextHalf
                        title="Область"
                        data={data.address.region.uk_UA}
                    />
                    <TextHalf
                        title="Населений пункт"
                        data={data.address.locality.uk_UA}
                    />
                    <TextHalf
                        title="Адреса"
                        data={data.address.streetAddress.uk_UA}
                    />
                    {viewAddressID(data.address)}
                </>
            )
        }
    }
    return null
}

function viewAddressID(data){
    if(data.hasOwnProperty('addressID') === true){
        if(data.addressID !== null){
            return (
                <>
                    <TextFullHeader
                        title="Код об’єктів адміністративно-територіального устрою України"
                        data=''
                    />
                    <TextHalf
                        title="Країна"
                        data={data.addressID.scheme}
                    />
                    <TextHalf
                        title="Поштовий індекс"
                        data={data.addressID.name.uk_UA}
                    />
                    <TextHalf
                        title="Область"
                        data={data.addressID.id}
                    />
                </>
            )
        }
    }
    return null
}

function viewIdentifier(data){
    if(data.hasOwnProperty('currentTenant') === true){
        if(data.currentTenant !== null){
            if(data.hasOwnProperty('identifier') === true){
                if(data.identifier !== null){
                    if(data.identifier.scheme !== null && data.identifier.legalName !== null && data.identifier.id !== null){
                        return (
                            <>
                                <TextFullHeader
                                    title="Ідентифікатори організації або особи"
                                    data=''
                                />
                                <TextHalf
                                    title="Ідентифікатори організації"
                                    data={data.identifier.scheme}
                                />
                                <TextHalf
                                    title="Повна юридична назва організації"
                                    data={data.identifier.legalName}
                                />
                                <TextHalf
                                    title="Код ЄДРПОУ або ІПН або паспорт"
                                    data={data.identifier.id}
                                />
                            </>
                        )
                    }
                }
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotPreviousAuctionBidderString)