import { 
    REG_EXP_TIMBER_DIAMETR,
    
    CHANGE_GEOLOGIV_DATA_VALUE,
    CHANGE_DOCUMENTATION_VALUE,
    CHANGE_DATE_USES,
} from "../../types";

//Auction Items

export function setRecExpTimberDiametr(value) {
    return {
        type: REG_EXP_TIMBER_DIAMETR,
        payload: value
    }
}

export function changeCreateItemGeologivDataValue(value) {
    return {
        type: CHANGE_GEOLOGIV_DATA_VALUE,
        payload: value
    }
}

export function changeCreateItemDocumentationValue(value) {
    return {
        type: CHANGE_DOCUMENTATION_VALUE,
        payload: value
    }
}

export function changeCreateItemDateUse(value) {
    return {
        type: CHANGE_DATE_USES,
        payload: value
    }
}