import React from 'react';

import {connect} from 'react-redux';

import AccessDetailsString from './../../../../../product/lotInformation/auctionElements/accessDetails'
import PreviousAuctionIdString from './../../../../../product/lotInformation/auctionElements/previousAuctionId'
import NormativeMonetaryValuationString from './../../../../../product/lotInformation/auctionElements/normativeMonetaryValuation'
import ExpertMonetaryValuationString from './../../../../../product/lotInformation/auctionElements/expertMonetaryValuation'
import BudgetSpentString from './../../../../../product/lotInformation/auctionElements/budgetSpent'
import RegistrationFeeString from './../../../../../product/lotInformation/auctionElements/registrationFee'
import ValueAddedTaxChargedString from './../../../../../product/lotInformation/auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './../../../../../product/lotInformation/auctionElements/leaseDuration'
import AuctionUrlString from './../../../../../product/lotInformation/auctionElements/auctionUrl'
import GuaranteeString from './../../../../../product/lotInformation/auctionElements/guarantee'
import MinimalStepString from './../../../../../product/lotInformation/auctionElements/minimalStep_old'
import XAdditionalInformationString from './../../../../../product/lotInformation/auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './../../../../../product/lotInformation/auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './../../../../../product/lotInformation/auctionElements/sellingMethod'
import LotIdentifierString from './../../../../../product/lotInformation/auctionElements/lotIdentifier'
import TenderAttemptsString from './../../../../../product/lotInformation/auctionElements/tenderAttempts'
import AuctionIdString from './../../../../../product/lotInformation/auctionElements/auctionId'
import DescriptionString from './../../../../../product/lotInformation/auctionElements/description'
import ValueString from './../../../../../product/lotInformation/auctionElements/value'
import LeaseTypeString from './../../../../../product/lotInformation/auctionElements/leaseType'
import SaleTypeString from './../../../../../product/lotInformation/auctionElements/saleType'
import ConditionsString from './../../../../../product/lotInformation/auctionElements/conditions'
import RestrictionsString from './../../../../../product/lotInformation/auctionElements/restrictions'
import LotCurrentTenantString from './../../../../../product/lotInformation/auctionElements/currentTenant'
import LotPreviousAuctionBidderString from './../../../../../product/lotInformation/auctionElements/previousAuctionBidder'
import XPlatformLegalDetailsString from './../../../../../product/lotInformation/auctionElements/xPlatformLegalDetailsNew'
import XDocumentRequirements from './../../../../../product/lotInformation/auctionElements/xDocumentRequirements'
import NonRemovable from './../../../../../product/lotInformation/auctionElements/nonRemovable'
import DatePublished from './../../../../../product/lotInformation/auctionElements/datePublished'
import DateModified from './../../../../../product/lotInformation/auctionElements/dateModified'
import DisqualifiedBidsDetails from './../../../../../product/lotInformation/auctionElements/disqualifiedBidsDetails'
import IsPerishableString from './../../../../../product/lotInformation/auctionElements/isPerishable'
import PreparationValue from './../../../../../product/lotInformation/auctionElements/preparationValue'
import DutchStep from './../../../../../product/lotInformation/auctionElements/dutchStep'
import SlimmerBlock from "../../../../../elements/slimmerBlock/slimmerBlock";

const DefaultAuctionInfo = (props) => {

    function viewDisqualifiedBidsDetails(props){
        let tmp = []
        let count = 0
        for(let key in props.auctionId.disqualifiedBidsDetails){
            tmp[count] = <DisqualifiedBidsDetails info={props.auctionId.disqualifiedBidsDetails[key]}/> 
            count++
        }
        return (
            <div style={{width:"100%"}}>
                <h3>Виключені учасники</h3>
                {tmp}
            </div>
        )
    }

    let tmp = <>
        <ValueString />
        <AuctionIdString />
        <DescriptionString />
        <TenderAttemptsString />
        <LotIdentifierString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <AccessDetailsString />
        <PreviousAuctionIdString />
        <AuctionUrlString />
        <LeaseDurationString />
        <ValueAddedTaxChargedString />
        <RegistrationFeeString />
        <PreparationValue />

        <NormativeMonetaryValuationString />
        <ExpertMonetaryValuationString />
        <BudgetSpentString />
        <LeaseTypeString />
        <SaleTypeString />
        <ConditionsString />
        <RestrictionsString />
        <LotCurrentTenantString />
        <LotPreviousAuctionBidderString />
        <XPlatformLegalDetailsString />
        <XDocumentRequirements />
        <NonRemovable />
        <DatePublished />
        <DateModified />
        <IsPerishableString />
        <DutchStep />
        {props?.auction?.disqualifiedBidsDetails !== null ? viewDisqualifiedBidsDetails(props) : null}
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultAuctionInfo)
