import React, {useEffect, useState, useRef} from 'react'
import styles from './multiSelect.module.css'
import CheckBox from './chBox/multiChbox'
import Bage from './bage/bage';
import { checkProp } from '../../../../redux/check';
// import CheckBxList from '../checkBxList'

const MultiSelect = (props) => {
    const [selectPopDown, setSelectPopDown] = useState(false)
    const [complete, setComplete] = useState(`${styles.inPutSelect}`)
    const [info, setInfo] = useState(`${styles.inPutInfo}`)

    const [error, setError] = useState(null)
    const [valueArr, setValueArr] = useState(props.value !== null ? props.value : [])
    const [items, setItems] = useState(props.options || null)


    useEffect(() => {
        if(props.error) {
            setInfo(`${styles.inPutInfo} ${styles.attent}`);
        } else {
            setInfo(`${styles.inPutInfo}`);
        }
    });

    useEffect(() => {
        if (!!props.options) {
            const tmp = [];
            for (let key in props.options) {
                tmp.push(
                    <CheckBox
                        key={props.options[key].id}
                        label={props.options[key].description.hasOwnProperty('uk_UA') ? props.options[key].description.uk_UA : props.options[key].description}
                        id={props.options[key].id}
                        checked={checkProp(props, 'value') && props.value.includes(props.options[key].id) || false }
                        onChange={() => toggleArray(props.options[key].id)}
                    />
                )
            }
            return setItems(tmp);
        }
        if (selectPopDown) setError(null)
    }, [selectPopDown]);
    
    useEffect(() => {
        props.intendedUse(valueArr)
    }, [valueArr]);

    const toggleArray = (name) => {
        //const arr = valueArr;
        const arr = props.value;
        const index = arr.indexOf(name)
        const maxLength = props.maxLength || 100
        if (arr.length < maxLength) {
            if (index !== -1) {
                arr.splice(index, 1);
            } else {
                arr.push(name);
            }
            if(checkProp(props, 'setUpLevel')){
                //setAdditionalClassification(JSON.parse(event))
                props.setUpLevel(arr)
            }
            setValueArr(arr);
            setError(null)
        } else if (arr.length === maxLength) {
            setError(null)
            if (index !== -1) {
                arr.splice(index, 1);
            }
            setValueArr(arr);
            setError(`Максимальна кількість об\'єктів - ${maxLength}`)
        } else {
            setError(`Максимальна кількість об\'єктів - ${maxLength}`)
        }
    }

    function selectToggle() {
        if (selectPopDown){
            setSelectPopDown(!selectPopDown)
            setComplete(`${styles.inPutSelect} `)
            setError(null)
        } else {
            setSelectPopDown(!selectPopDown)
            setComplete(`${styles.inPutSelect} ${styles.focus}`)
        }
    }

    function bagesMapper() {
        let tmp
        //tmp = valueArr.map(i => {
        tmp = props.value.map(i => {
            for (let key in props.options){
                if(props.options[key].id === i){
                    return <Bage description={props.options[key].description.hasOwnProperty('uk_UA') ? props.options[key].description.uk_UA : props.options[key].description} key={i} />
                }
            }
        })
        return tmp
    }

    const PopDown = () => {
        const selectRef = useRef();

        useEffect(() => { // setting height of popdown
            let position = selectRef.current.getBoundingClientRect()
            if (selectRef.current.getBoundingClientRect().bottom > window.innerHeight) {
                selectRef.current.style.height = `${window.innerHeight - position.top - 20}px`
            } else if (selectRef.current.getBoundingClientRect().bottom < window.innerHeight) {
                selectRef.current.style.height = `auto`
            }
        }, [selectPopDown])

        function optionsList(){
            return props.options.map(i => { 
                //if (valueArr.includes(i.id) ){
                if (props.value.includes(i.id) ){
                    return <CheckBox
                                key={i.id}
                                label={i.description.hasOwnProperty('uk_UA') ? i.description.uk_UA : i.description}
                                id={i.id}
                                checked={true}
                                onChange={() => toggleArray(i.id)}
                            />
                }
                return <CheckBox
                            key={i.id}
                            label={i.description.hasOwnProperty('uk_UA') ? i.description.uk_UA : i.description}
                            id={i.id}
                            checked={false}
                            onChange={() => toggleArray(i.id)}
                        />
            })
        }



        if (props.options !== null) {
            return <>
                <div className={styles.wrapper} onClick={selectToggle}></div>
                <div className={styles.selectPopdown} ref={selectRef} >
                    <div className={styles.content}>
                        {optionsList()}
                    </div>
                    <div className={styles.bottomPart}>
                        <div className={styles.reset}
                            onClick={() => {
                                setValueArr([])
                            }}
                        >
                            Скинути
                        </div>
                    </div>
                </div>
            </>
        }
    }
    
    if(props?.value !== null){
        return (
            <>
                <div className={styles.bagesContainer}>
                    {bagesMapper(props.value)}
                </div>
                <div className={info}>
                    
                    <div className={styles.inPutWrap}>
                        <div className={complete} onClick={selectToggle}>
                            {!!error
                                ?<div className={styles.error}>{error}</div>
                                : 'Оберіть'
                            }
                        </div>
                        {props.label
                            ? <div className={styles.label}>{props.label}</div>
                            : ''
                        }
                    </div>
                    {selectPopDown ? <PopDown /> : null }
                </div>
                {props.error ? <p className={styles.descript}>{props.error}</p> : props.descr ? <p className={styles.descript}>{props.descr}</p> : ''}
            </>
        )
    }
    return null
}

export default MultiSelect;