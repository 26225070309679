import React from 'react';

import {connect} from 'react-redux';

const pagingButtonNext = ( ) => {
    return (
        <svg enableBackground="new 0 0 32 32"  id="Слой_1" version="1.1" viewBox="0 0 32 32"  xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path clipRule="evenodd" d="M11.727,26.71l9.977-9.999  c0.394-0.395,0.394-1.034,0-1.429h0v0l-9.97-9.991c-0.634-0.66-1.748-0.162-1.723,0.734v19.943  C9.988,26.861,11.094,27.345,11.727,26.71z M19.567,15.997l-7.55,7.566V8.431L19.567,15.997z" fill="#121313" fillRule="evenodd" id="Arrow_Drop_Right"/>
            <g/>
            <g/>
            <g/>
            <g/>
            <g/>
            <g/>
        </svg>
    )
}

export default connect(null, null)(pagingButtonNext)
