import React, { Fragment } from 'react';
import styles from './windowAwards.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';
import {changeCreateAuctionTab} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import ListDocuments from './itemsList/itemsList.js';
import ReturnButton from '../returnButton'
import {modifiDateString} from '../../../redux/modifiDate'

const WindowAuction = ( props ) => {
    let tmpText = 'Інформація про учасника';
    let tmpAward = null;
    if(props.itemAward !== null && props.userProfiles.canCreateAuction === true){
        let tmpClassification = <>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис ставки:</div>
                <div className={styles.lineWrapAwards_content}></div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис схеми:</div>
                <div className={styles.lineWrapAwards_content}></div>
            </div>
        </>
        if(props.itemAward.bid.classification !== null){
            tmpClassification = <>
                <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                    <div className={styles.lineWrapAwards_title}>Опис ставки:</div>
                    <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.classification.description}</div>
                </div>
                <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                    <div className={styles.lineWrapAwards_title}>Опис схеми:</div>
                    <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.classification.scheme + ' ' + props.itemAward.bid.classification.classificationId}</div>
                </div>
            </> 
        }
        let tmpQuantity = <div>Кількість:</div>
        if(props.itemAward.bid.quantity !== null && props.itemAward.bid.unit !== null){
            tmpQuantity = <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Кількість:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.quantity + ' ' + props.itemAward.bid.unit.symbol}</div>
            </div>
        }
        tmpAward = <div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон Id:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.id}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон від:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.createdAt, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Ідентіфікатор аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.auctionId}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Дата початку кваліфікації учасника аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.startDate, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Найменування аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.title}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.description}</div>
            </div>

            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Покупець:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].name}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані назва:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.name}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані телефон:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.telephone}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані факс:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.faxNumber}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Контактні дані e-mail:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.bidders[0].contactPoint.email}</div>
            </div>

            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Адреса:</div>
                <div className={styles.lineWrapAwards_content}>{
                    props.itemAward.bid.bidders[0].address.postalCode + ' ' + 
                    props.itemAward.bid.bidders[0].address.countryName + ' ' +
                    props.itemAward.bid.bidders[0].address.region + ' ' +
                    props.itemAward.bid.bidders[0].address.locality + ' ' +
                    props.itemAward.bid.bidders[0].address.streetAddress
                }</div>
            </div>
            

            {tmpClassification}
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Цінова пропозиція:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.bid.value.amount + ' ' + props.itemAward.bid.value.currency}</div>
            </div>
            {tmpQuantity}
            <div>
                {viewDocument(props)}
            </div>
            <div>Документи пропозиції:</div>
            {viewBidDoc(props, props.itemAward)}
        </div>
    }else if(props.itemAward !== null && props.userProfiles.canCreateBid === true){
        tmpAward = <div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон Id:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.id}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Аукціон від:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.createdAt, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Ідентіфікатор аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.auctionId}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Дата проведення аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{modifiDateString(props.itemAward.auction.startDate, 3, false)}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Найменування аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.title}</div>
            </div>
            <div className={styles.lineWrapAwards + ' ' + styles.notCanter}>
                <div className={styles.lineWrapAwards_title}>Опис аукціону:</div>
                <div className={styles.lineWrapAwards_content}>{props.itemAward.auction.description}</div>
            </div>
            <div>
                {viewDocument(props)}
            </div>
            <div>Документи пропозиції:</div>
            {viewBidDoc(props, props.itemAward)}
        </div>
    }
    return (
        <div className={styles.documentWrap}>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    {/*<div className={styles.headers}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeDocumentId(null)
                                    props.changeDocumentTitle('')
                                    props.changeDocumentDescription('')
                                    props.changeDocumentLang('uk')
                                }
                            }
                        >
                            Створити новий документ
                        </Button>
                        </div>*/}
                    <ListDocuments />
                </div>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {tmpAward}
                </div>
            </div>
            {/*<Button
                className={styles.buttonExitDocument}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        props.changeCreateAuctionTab(0);
                        props.changeAuctionsSwitchWindow(0)
                        props.setPopUpAuctionMyNull()
                    }
                }
            >
                Повернутися до списку аукціонів
            </Button>*/}
            <ReturnButton />
        </div>
    )
}

function viewBidDoc(props, data){
    let tmp = null
    if(data !== null){
        if(data.bid !== null){
            if(data.bid.documents.length > 0){
                tmp = data.bid.documents.map(
                    (e, count) => {
                        let tmpStart = null
                        let tmpEdit = null
                        if(e.datePublished !== null) tmpStart = modifiDateString(e.datePublished, 3, false)
                        if(e.dateModified !== null) tmpEdit = modifiDateString(e.dateModified, 3, false)
                        return (
                            <a href={e.url} target='_blank'>
                                <div className={styles.lineWrapAwards}>
                                    <div className={styles.lineWrapAwards_text}>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Документ №:</div><div className={styles.lineWrapAwards_content}>{count+1}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Назва:</div><div className={styles.lineWrapAwards_content}>{e.title}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Опис:</div><div className={styles.lineWrapAwards_content}>{e.description}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Тип документу:</div><div className={styles.lineWrapAwards_content}>{viewDataType(e.documentType)}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Дата створення:</div><div className={styles.lineWrapAwards_contentDate}>{tmpStart}</div>
                                        </div>
                                        <div className={styles.lineWrapAwards}>
                                            <div className={styles.lineWrapAwards_title}>Дата редагування:</div><div className={styles.lineWrapAwards_contentDate}>{tmpEdit}</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    }
                )
            }
        }
    }
    return <div>{tmp}</div>
}

function viewDataType(dataType){
    switch (dataType){
        case 'commercialProposal': return 'Заява на участь'
        case 'x_nonSanctionedStatement': return 'Заява про непоширення обмежувальних заходів (санкції)'
        case 'qualificationDocuments': return 'Документи що підтверджують кваліфікацію'
        case 'x_passport': return 'Копія паспорта або документа, що посвідчує особу'
        case 'x_IPN': return 'Копія ІПН/РНОКПП'
        case 'x_tenderersRegisterExtract': return 'Витяг з ЄДРПОУ'
        case 'x_nonResidentRegistrations': return 'Документ про реєстрацію у державі її місцезнаходження (нерезидент)'
        case 'eligibilityDocuments': return 'Документи що підтверджують відповідність'
        case 'x_tenderersGuarantee': return 'Фінансове забезпечення'
        case 'x_tenderersLand': return 'Право на земельну ділянку'
        case 'x_tenderersElectricalGrid': return 'Договір про приєднання об\'єкта електроенергетики'
        case 'x_tenderersBeneficialOwner': return 'Інформація про кінцевого бенефіціарного власника (або його відсутність)'
        case 'x_tenderersGoverningBody': return 'Інформація про органи управління'
        case 'x_tenderersRelatedParties': return 'Інформація про пов\'язаних осіб'
        case 'x_tenderersGenerationType': return 'Довідка про вид ВДЕ (для технологічно нейтральних)'
        case 'digitalSignature': return 'Цифровий підпис'
        case 'rejectionProtocol': return 'Документ, що підтверджує дискваліфікацію'
        case 'auctionProtocol': return 'Протокол аукціону'
        case 'act': return 'Документ, що підтверджує відмову'
        case 'contractSigned': return 'Акт'
        case 'contractAnnexe': return 'Додатки до акту'
        case 'courtOrder': return 'Постанова'
        default: return null
    }
}


function viewDocument(props){
    let tmp = null;
    if(props.itemAward.documents !== null){
        if(props.itemAward.documents.length > 0){
            tmp = props.itemAward.documents.map(
                (i, count) => {

                    return (
                        <a href={i.url} target='_blank'>
                            <div className={styles.lineWrapAwards}>
                                <div className={styles.lineWrapAwards_text}>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Документ №:</div><div className={styles.lineWrapAwards_content}>{count+1}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Назва:</div><div className={styles.lineWrapAwards_content}>{i.title}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Опис:</div><div className={styles.lineWrapAwards_content}>{i.description}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Тип документу:</div><div className={styles.lineWrapAwards_content}>{viewDataType(i.documentType)}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Дата створення:</div><div className={styles.lineWrapAwards_contentDate}>{modifiDateString(i.datePublished, 3, false)}</div>
                                    </div>
                                    <div className={styles.lineWrapAwards}>
                                        <div className={styles.lineWrapAwards_title}>Дата редагування:</div><div className={styles.lineWrapAwards_contentDate}>{modifiDateString(i.dateModified, 3, false)}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )
                }
            )
        }
    }
    if(tmp === null){
        tmp = <div>Доданих документів не знайдено</div>
    }else if(tmp.length === 0){
        tmp = <div>Доданих документів не знайдено</div>
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        createDocuments: state.start.createDocuments,
        itemAward: state.start.itemAward,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    changeCreateAuctionTab,
    setPopUpAuctionMyNull,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)