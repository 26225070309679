import React, { useState, useEffect } from 'react';
import styles from './localization.module.css';

import {connect} from 'react-redux';
import Input from '../../../elements/inputs/input'
import Select from '../../../elements/inputs/inputSelect'
import Button from '../../../elements/buttons/button';

import {isALE, isBSE, isBSD, isCSE, isCSD, isBRE, isBRD} from '../../../../redux/procedure'


import { popUpAddress } from '../../../../redux/actions/address/address.js';
import ZuMap from '../../../map/zuMaps'
import { setDataAddress } from '../../../../redux/createAddress';
import { checkProp, isMongo } from '../../../../redux/check';

const Localization = ({address, location, setAdress, setLocation, ...props}) => {

    const [country, setCountry] = useState(checkProp(address, 'country') ? isMongo(address.country) : 'Україна')
    const [region, setRegion] = useState(checkProp(address, 'region') ? isMongo(address.region) : null)
    const [locality, setLocality] = useState(checkProp(address, 'locality') ? isMongo(address.locality) : null)
    const [street, setStreet] = useState(checkProp(address, 'streetAddress') ? isMongo(address.streetAddress) : null)
    const [postal, setPostal] = useState(checkProp(address, 'postalCode') ? address.postalCode : null)

    const [latitude, setLatitude] = useState(checkProp(location, 'latitude') ? location.latitude : '50.44395')
    const [longitude, setLongitude] = useState(checkProp(location, 'longitude') ? location.longitude : '30.51589')


    useEffect(() => {
        setAdress({
            addressID: {
                scheme: 'koatuu',
                /*name: {
                    uk_UA: region,
                },*/
                id: setKoatuu(region)
            },
            streetAddress: street !== null && street !== '' ? {uk_UA: street} : null,
            locality: locality !== null && locality !== '' ? {uk_UA: locality} : null,
            region: {
                uk_UA: region,
            },
            postalCode: postal !== null && postal !== '' ? postal : null,
            countryName: {
                uk_UA: country,
            }
        })
        if (region === 'Київ') setLocality('Київ')
    }, [country, region, locality, street, postal])

    useEffect(() => {
        setLocation({
                latitude: latitude,
                longitude: longitude
        })
    }, [ latitude, longitude])

    function latLongDisable(data){
        if( 
            isBSE(data) || isBSD(data) ||
            isALE(data) ||
            isCSE(data) || isCSD(data) ||
            isBRE(data) || isBRD(data)
        ) return true
        return false
    }


    function setNewAddress() {
        let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
        let tmpLat = map.dataset.lat.split('.')
        let tmpLng = map.dataset.lng.split('.')
        let newAddress = {
            //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
            address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house : ''}`,
            postcode: map.dataset.postcode,
            region: map.dataset.region,
            //lat: map.dataset.lat,
            lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
            //lng: map.dataset.lng,
            lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
            locality: map.dataset.city,
            street: map.dataset.street,
            country: map.dataset.country,
        }
        if (map.dataset.country === 'Росія'){
            alert(`Додавання розташування на Росії наразі неможливе. Ми передали координати в штаб ракетних військ Збройних Сил України`)
        }
        else if (map.dataset.region.indexOf('Крим') !== -1 ){
            alert(`Нажаль територія Криму тимчасово окупована. Додати розташування неможливо.`)
        }
        else if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
            if(map.dataset.country === 'Донецька область'){
                setCountry('Україна')
            }else{
                setCountry(newAddress.country)
            }

            if(newAddress.region === undefined || newAddress.region === 'undefined') setRegion(null)
            else if(newAddress.locality === 'Київ') setRegion('Київ')
            else setRegion(newAddress.region)

            if(newAddress.region === 'Київ') setLocality('Київ')
            else setLocality(newAddress.locality)

            setStreet(newAddress.address)
            setPostal(newAddress.postcode)

            setLatitude(newAddress.lat)
            setLongitude(newAddress.lng)
        } else {
            alert(`Обирати об'єкти можна лише на території України`)
        }
    }

    return (
            <div className={styles.wrapBlock}>
                <div className={styles.wrapBlockTitle}>Розташування</div>
                    <ZuMap
                        latitude = {latitude}
                        longitude = {longitude}
                    />
                <div className={styles.lineButtons}>
                    <Button
                        data={'Додати обрану адресу'}
                        color={'blue'}
                        onClick={
                            () => {
                                setNewAddress()
                            }
                        }
                    />
                </div>
            <div className={styles.preTitle}>Адреса</div>
            <Input
                label='Країна'
                disabled
                value={country}
                onChange={(event)=>{
                    setCountry(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.countryName)}
            />
            <Select
                data={{
                    label: "Область",
                    target: region,
                    
                    required: true,
                }}
                onChange={(e) => { setRegion(e) }}
                // error={redBlock(props.createItems.landRental.addressItem.region)}
                options={regionsList}
            />
            <Input
                label='Населенний пункт'
                value={locality}
                onChange={(event)=>{
                    setLocality(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.locality)}
            />
            <Input
                label='Адреса'
                value={street}
                onChange={(event)=>{
                    setStreet(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
            />
            <Input
                label='Поштовий індекс'
                value={postal}
                onChange={(event)=>{
                    setPostal(event)
                }}
                maxlength={'5'}
                // required
                // disabled={blokedInput(props, '11') 45654987-84654654}
                error={postal !== null && postal.length > 5 ? 'Індекс не може бути довшим ніж 5 цифр' : false}
            />
            <div className={styles.preTitle}>Координати</div>
            <div className={styles.halfBlock}>
                 <div style={{width: '49%'}}>
                     <Input
                        label='Широта'
                        value={latitude}
                        onChange={(event)=>{
                            setLatitude(event)
                        }}
                        disabled={latLongDisable(props.auction.sellingMethod)}
                        // error={redBlock(props, props.createItems.landRental.location.latitude)}
                    />
                </div>
                 <div style={{width: '49%'}}>
                     <Input
                        label='Довгота'
                        value={longitude}
                        onChange={(event)=>{
                            setLongitude(event)
                        }}
                        disabled={latLongDisable(props.auction.sellingMethod)}
                        // error={redBlock(props, props.createItems.landRental.location.latitude)}
                    />
                </div>
            </div>
        </div>
    )
}

function setKoatuu(data){
    for (let key in regionsList) { 
        if( data === regionsList[key].value) return regionsList[key].koatuu
    } 
}

const regionsList = [
        { name: 'Севастополь', value: 'Севастополь', koatuu: "8500000000"},
        { name: 'Київ', value: 'Київ', koatuu: "8000000000"},
        { name: 'Чернігівська область', value: 'Чернігівська область', koatuu: "7400000000"},
        { name: 'Чернівецька область', value: 'Чернівецька область', koatuu: "7300000000"},
        { name: 'Черкаська область', value: 'Черкаська область', koatuu: "7100000000"},
        { name: 'Хмельницька область', value: 'Хмельницька область', koatuu: "6800000000"},
        { name: 'Херсонська область', value: 'Херсонська область', koatuu: "6500000000"},
        { name: 'Харківська область', value: 'Харківська область', koatuu: "6300000000"},
        { name: 'Тернопільська область', value: 'Тернопільська область', koatuu: "6100000000"},
        { name: 'Сумська область', value: 'Сумська область', koatuu: "5900000000"},
        { name: 'Рівненська область', value: 'Рівненська область', koatuu: "5600000000"},
        { name: 'Полтавська область', value: 'Полтавська область', koatuu: "5300000000"},
        { name: 'Одеська область', value: 'Одеська область', koatuu: "5100000000"},
        { name: 'Миколаївська область', value: 'Миколаївська область', koatuu: "4800000000"},
        { name: 'Львівська область', value: 'Львівська область', koatuu: "4600000000"},
        { name: 'Луганська область', value: 'Луганська область', koatuu: "4400000000"},
        { name: 'Кіровоградська область', value: 'Кіровоградська область', koatuu: "3500000000"},
        { name: 'Київська область', value: 'Київська область', koatuu: "3200000000"},
        { name: 'Івано-Франківська область', value: 'Івано-Франківська область', koatuu: "2600000000"},
        { name: 'Запорізька область', value: 'Запорізька область', koatuu: "2300000000"},
        { name: 'Закарпатська область', value: 'Закарпатська область', koatuu: "2100000000"},
        { name: 'Житомирська область', value: 'Житомирська область', koatuu: "1800000000"},
        { name: 'Донецька область', value: 'Донецька область', koatuu: "1400000000"},
        { name: 'Дніпропетровська область', value: 'Дніпропетровська область', koatuu: "1200000000"},
        { name: 'Волинська область', value: 'Волинська область', koatuu: "0700000000"},
        { name: 'Вінницька область', value: 'Вінницька область', koatuu: "0500000000"},
        { name: 'Автономна Республіка Крим', value: 'Автономна Республіка Крим', koatuu: "0100000000"},
]

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auction: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    // changeLoader,
    // getMyAuction,
    popUpAddress,
    // setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(Localization)