import { 
    CHANGE_DISCOUNT_PERCENT,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID,
    DISCOUNT_ON_OFF,
    CHANGE_DISCOUNT_ID,
} from "../../types";

export function changeDiscountId(value) {
    return {
        type: CHANGE_DISCOUNT_ID,
        payload: value
    }
}

export function changeDiscountPercent(value) {
    return {
        type: CHANGE_DISCOUNT_PERCENT,
        payload: value
    }
}

export function changeDiscountPreviousAuctionCurrency(value) {
    return {
        type: CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY,
        payload: value
    }
}

export function changeDiscountPreviousAuctionAmount(value) {
    return {
        type: CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT,
        payload: value
    }
}

export function changeDiscountPreviousAuctionId(value) {
    return {
        type: CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID,
        payload: value
    }
}

export function discountAuc(value) {
    return {
        type: DISCOUNT_ON_OFF,
        payload: value
    }
}