import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionDescription} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import TextArea from '../../elements/inputs/textArea';

import {disabledElement} from '../disabled'
import { checkProp, isMongo } from '../../../redux/check';

const WindowAuctionDescription = ( props ) => {
    let tmp;
    if(props.typeElem === 1){
        tmp = <TextArea
                    value={checkProp(props,'auctionsMy') ? isMongo(props.auctionsMy.description) : null}
                    disabled={disabledElement(props.auctionsMy, '00')}
                    label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.description.legalName}
                    required
                    onChange={(e)=>{ props.changeCreateAuctionDescription(e) }}
                    error = {redBlock(props, props.auctionsMy.description)}
                    descr=""
                />
        //     <div>
        //         <TextField
        //             error = {redBlock(props, props.auctionsMy.description)}
        //             disabled={disabledElement(props.auctionsMy, '00')}
        //             className={styles.textAreaWidth}
        //             id="outlined-multiline-static"
        //             label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.description.legalName}
        //             multiline
        //             rows={10}
        //             value={ props.auctionsMy.description !== null ? props.auctionsMy.description.hasOwnProperty('uk_UA') ? props.auctionsMy.description.uk_UA : props.auctionsMy.description : props.auctionsMy.description}
        //             variant="outlined"
        //             onChange={(event)=>{
        //                 props.changeCreateAuctionDescription(event.target.value)
        //             }}
        //             helperText="Обов'язкове поле"
        //         />
        //     </div>
        // </div>
    }else{
        tmp = <div className={styles.groupElem}>
            <div>
                <TextField
                    error = {redBlock(props, props.auctionsMy.description)}
                    disabled={disabledElement(props.auctionsMy, '00')}
                    className={styles.textAreaWidth}
                    id="outlined-multiline-static"
                    label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.description.legalName}
                    multiline
                    rows={10}
                    value={ props.auctionsMy.description !== null ? props.auctionsMy.description.hasOwnProperty('uk_UA') ? props.auctionsMy.description.uk_UA : props.auctionsMy.description : props.auctionsMy.description}
                    variant="outlined"
                    onChange={(event)=>{
                        props.changeCreateAuctionDescription(event.target.value)
                    }}
                    helperText=""
                />
            </div>
        </div>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDescription)
