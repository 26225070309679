import React, {useState} from 'react'
import styles from './bidEditNewPopup.module.css'
import {connect} from 'react-redux';
import TextHalf from '../../../elements/viewData/textHalf';

import {changeBidAward, setUniversalError} from '../../../../redux/actions.js';
import {changeBidPopUpEdit, auctionsBidsGetId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {setDataAddress} from '../../../../redux/createAddress';
import {changeLoader} from '../../../../redux/actions.js';

import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';

import {
    saveArrBids,
} from '../../../../redux/allAuctionBids.js';
import {getDefaultHeaders, getUserBidsListByAuction} from "../../../_api/bidApi";
import {getCurrency} from "../../../../redux/check";
import procedure from "../../../../redux/procedure";

const BidEditPopUp = (props) => {
    const [bidIsValid, setBidIsValid] = useState(false)
    const [bidQuantity, setBidQuantity] = useState(props?.bid?.quantity)

    let tmpHealp = null
    if (props.bidEdit.status === "active") {
        tmpHealp = <InstructionBlock
            title='Важлива інформація'
            content={
                <>
                    <p>Увага! Всі зміни, внесені до Вашої початкової закритої пропозиції після перевірки та
                        підтвердження майданчиком, повторно не перевіряються та є повністю Вашою відповідальністю!</p>
                    <p>Натискаючи "Зберегти", Ви погоджуєтесь з умовами.</p>
                </>
            }
        />
    }
    return (
        <div className={styles.wrapMain}>
            <div className={styles.wrap}>
                <div
                    className={styles.close}
                    onClick={
                        //props.actionClose
                        () => {
                            props.changeBidPopUpEdit(null)
                        }
                    }
                >✕
                </div>
                <h3 className={styles.popupTitle}>Редагувати пропозицію</h3>
                <p className={styles.description}>Відкорегуйте вашу пропозицію</p>
                {tmpHealp}
                <TextHalf
                    title='Початкова ціна продажу лоту'
                    data={`${viewBaseAuctionValue(props)} ${(getCurrency(props.auctionId.value.currency ?? 'UAH'))}`}
                />
                <div className={styles.bidLotCost_input}>
                    <input
                        className={styles.inputNumber}
                        type='number'
                        value={viewValue(props)}
                        onChange={(e) => {
                            let value = parseFloat(e.target.value)
                            value = Math.round((value + Number.EPSILON) * 100) / 100
                            if (procedure.isREM(props.auctionId.sellingMethod)) {
                                value = value > props?.auctionId?.value?.amount ? props?.auctionId?.value?.amount : value
                                value = value < 0 ? 0.01 : value
                                setBidIsValid(true)
                            } else {
                                setBidIsValid(value >= props?.auctionId?.value?.amount)
                            }

                            props.setDataAddress(value, 'SET_EDIT_BID_VALUE')
                        }}
                    />
                    <div className={styles.bidLotCost_title}>Цінова пропозиція</div>
                </div>
                {procedure.isREM(props.auctionId.sellingMethod) ?
                    <div
                        className={styles.bidLotCost_descr}>{`Пропозиція має бути менша або рівна ${viewBaseAuctionValue(props)} ${(getCurrency(props.auctionId.value.currency ?? 'UAH'))}.`}</div>
                    :
                    <div
                        className={styles.bidLotCost_descr}>{`Пропозиція має бути вища або рівна ${viewBaseAuctionValue(props)} ${(getCurrency(props.auctionId.value.currency ?? 'UAH'))}.`}</div>
                }
                {createInputQuantity(props, bidQuantity, setBidQuantity, setBidIsValid)}
                <div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                         onClick={
                             () => {
                                 props.changeBidPopUpEdit(null)
                             }
                         }
                    >
                        Скасувати
                    </div>
                    {bidIsValid ?
                        <div className={styles.btnDone}
                             onClick={
                                 () => {
                                     updateBid(props, bidQuantity)
                                 }
                             }
                        >Зберегти</div>
                        :
                        <div className={styles.btnDisabled}>Зберегти</div>
                    }
                </div>
            </div>
        </div>
    )
}

function createInputQuantity(props, bidQuantity, setBidQuantity, setBidIsValid) {
    if (procedure.isREM(props?.auctionId?.sellingMethod)) {
        return (
            <>
                <div className={styles.bidLotCost_input}>
                    <input
                        className={styles.inputNumber}
                        type='number'
                        value={bidQuantity}
                        onChange={(e) => {
                            const quantity = props?.auctionId?.items[0]?.quantity
                            let value = parseFloat(e.target.value)
                            value = Math.round((value + Number.EPSILON) * 100) / 100
                            if (isNaN(value)) {
                                setBidQuantity(null)
                                setBidIsValid(false)
                                return;
                            }
                            if (value <= 0) return
                            if (value > quantity) value = quantity
                            setBidQuantity(value)
                            setBidIsValid(true)
                        }}
                    />
                    <div className={styles.bidLotCost_title}>Розмір частки квоти в заяві (кВт)</div>
                </div>
                <div
                    className={styles.bidLotCost_descr}>{`Пропозиція має бути менша за ${props?.auctionId?.items[0]?.quantity}.`}</div>
            </>
        )
    }
    return null
}

function viewValue(props) {
    if (props.bidEdit !== null) {
        if (props.bidEdit.value !== null) {
            return props.bidEdit.value.amount
        }
    }
    return null
}

function viewBaseAuctionValue(props) {
    if (props.auctionId !== null) {
        if (props.auctionId.value !== null) {
            return startCostAuction(props.auctionId.value.amount)
        }
    }
    return null
}

function startCostAuction(data) {
    let tmp = null
    let amount = null
    if (data !== null) {
        amount = String(data);
        if (amount !== null) {
            if (amount.indexOf('.') !== -1) {
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            } else {
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'
            }
        }
    }
    return tmp + ',' + amount
}

async function updateBid(props, bidQuantity) {
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.bidEdit.id}`;
    let tmp = 0
    if (props.bidEdit.value.amount !== '' && props.bidEdit.value.amount !== null) {
        if (String(props.bidEdit.value.amount).indexOf(',')) {
            tmp = String(props.bidEdit.value.amount).replace(/,/gi, '.')
        } else {
            tmp = String(props.bidEdit.value.amount)
        }
    }
    let body = {
        value: {
            currency: props.bidEdit.value.currency,
            amount: Number(tmp),
            valueAddedTaxIncluded: true,
        }
    }
    if (procedure.isREM(props?.auctionId?.sellingMethod)) {
        body.quantity = bidQuantity
    }

    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: getDefaultHeaders(props)
    }).catch(() => {
        props.changeBidPopUpEdit(null)
        props.changeLoader(false)
        props.setUniversalError(JSON.stringify({
            title: 'Помилка оновлення данних контракту',
            message: ''
        }))
        return null
    })
    if (response === undefined || response === 'undefined') {
        props.changeBidPopUpEdit(null)
        props.changeLoader(false)
        return null
    } else {
        if (response.status !== 200) {
            props.changeBidPopUpEdit(null)
            props.changeLoader(false)
            props.setUniversalError(JSON.stringify({
                title: 'Помилка оновлення данних пропозиції',
                message: ''
            }))
            return null
        }
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            props.changeBidPopUpEdit(null)
            props.changeLoader(false)
            return null
        }
    }
    await getUserBidsListByAuction(props, props?.auctionId?.id)
    props.changeBidPopUpEdit(null)
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        bidAward: state.start.bidAward,
        bidEdit: state.start.bidPopUpEdit,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,

        token: state.start.token,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeBidPopUpEdit,
    setDataAddress,
    changeLoader,
    auctionsBidsGetId,
    saveArrBids,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidEditPopUp)