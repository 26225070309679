import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import TextFull from "../structureLot/auctionType/textFull";
import {TranslationsUk} from "../../../redux/translations.uk";
import procedure from "../../../redux/procedure";

const QuantityLimitBlock = (props) => {
    if (props?.auction && procedure.isREM(props?.auction?.sellingMethod) && props?.auction?.awards?.length > 0) {
        return (
            <div className={styles.infoBlock}>
                <h3>Розрахункова сумарна потужність</h3>
                <TextFull
                    title={TranslationsUk['renewables-multiAwards']['RenewablesMultiAwardsProcedure'].x_quantityLimit?.legalName + ':'}
                    data={props?.auction?.x_quantityLimit + ' кВт'}
                />
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(QuantityLimitBlock)
