import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {minNumberOfQualifiedBids} from '../../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';

const RailWayCargoDutchMinNumberOfQualifiedBids = ( props ) => {
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            return (
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Мінімальна кількість учасників"
                            value={props.auctionsMy.minNumberOfQualifiedBids}
                            onChange={(event)=>{
                                props.minNumberOfQualifiedBids(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати мінімальне число учасників"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            <option key={1} value='1'>1</option>
                            <option key={2} value='2'>2</option>
                        </TextField>
                    </div>
                </div>
            )
        default:
            return null
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

const mapDispatchToProps = {
    minNumberOfQualifiedBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(RailWayCargoDutchMinNumberOfQualifiedBids)