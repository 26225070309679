import React, {useState} from 'react'
import styles from './bidAddDocNewPopup.module.css'

import {connect} from 'react-redux';

import {
    auctionsBidsGetId,
} from '../../../redux/actions/auctionBid/auctionBid.js';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractDocId,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {contractsAuctionIdGet, saveHistoryDocsContracts} from '../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import ESignCheckBlock from '../../elements/ecp/ecpCheckBlock';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
//import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import {editAwardDoc} from '../../../redux/editAwardDoc.js';



let files

const BidAddDocPopUp = (props) => {
    const [dropArea, setDropArea] = useState({text: 'Додайте файли документів', style: styles.dropZone })

    

    function dragStartHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Відпустіть мишу для завантаження файлу', style: styles.dropZoneActive })
    }

    function dragLeaveHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Додайте файли документів', style: styles.dropZone })
    }
    
    function onDporHandler(e) {
        e.preventDefault()
        files = [...e.dataTransfer.files]
         let tmp = <>
                        <div>{`Додано файл:
                            ${files[0].name.length > 45 ? files[0].name.substr(0, 50) + '...' : files[0].name}`}
                        </div>
                        <div>
                            {`розмір: ${(files[0].size / 1000000).toFixed(2)} Mb, тип: ${files[0].type}`}
                        </div>
                    </>
                    setDropArea({
                        text: tmp, style: styles.dropZoneActive
                    })
    }

    let tmpTypeDoc = 'null'
    let tmpTitle = ''
    let tmpDescription = ''
    if(props.popUpContractUniversal !== null){
        tmpTypeDoc = props.popUpContractUniversal.type
        tmpTitle = props.popUpContractUniversal.title
        tmpDescription = props.popUpContractUniversal.description
    }

    let dataFilesAtribute = {
        actionNo: null,
        closeAC: props.changeContractApprowePopUpStatus,
    }


///////////////////////////////////////////////////////
let tmpUrl = null
let tmpUrlNew
    let tmpUrlKep = null
let tmpRelatedDocument = null
if(props.popUpContractUniversal.id === null){
    tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document`
}else{
    tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document/${props.popUpContractUniversal.id}`
    tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document`
    if(props.popUpContractApprowe !== null){
        if(props.popUpContractApprowe.fullAuction !== null){
            if(props.popUpContractApprowe.fullAuction.contracts !== null){
                if(props.popUpContractApprowe.fullAuction.contracts.length > 0 ){
                    for(let l = 0; l < props.popUpContractApprowe.fullAuction.contracts.length; l++){
                        if(props.popUpContractApprowe.fullAuction.contracts[l].id === props.popUpContractApprowe.contractId){
                            if(props.popUpContractApprowe.fullAuction.contracts[l].documents !== null){
                                if(props.popUpContractApprowe.fullAuction.contracts[l].documents.length > 0){
                                    for(let t = 0; t < props.popUpContractApprowe.fullAuction.contracts[l].documents.length; t++){
                                        if(props.popUpContractApprowe.fullAuction.contracts[l].documents[t].id === props.popUpContractUniversal.id){
                                            tmpRelatedDocument = props.popUpContractApprowe.fullAuction.contracts[l].documents[t].documentId
                                        }
                                    }
                                    if(tmpRelatedDocument !== null){
                                        for(let d = 0; d < props.popUpContractApprowe.fullAuction.contracts[l].documents.length; d++){
                                            if(props.popUpContractApprowe.fullAuction.contracts[l].documents[d].relatedDocument === tmpRelatedDocument){
                                                tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document/${props.popUpContractApprowe.fullAuction.contracts[l].documents[d].id}`
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
///////////////////////////////////////////////////////



    if(files){
        dataFilesAtribute.fileName = tmpTitle
        dataFilesAtribute.fileType = tmpTypeDoc
        dataFilesAtribute.fileDiscription = tmpDescription
        dataFilesAtribute.fileFile = files
        dataFilesAtribute.uploadUrlNew = tmpUrlNew
        dataFilesAtribute.uploadUrl = tmpUrl
        dataFilesAtribute.uploadUrlKep = tmpUrlKep
        dataFilesAtribute.documentType = 'contract'
        dataFilesAtribute.relatedDocument = tmpRelatedDocument
        dataFilesAtribute.changeLoader = props.changeLoader
        //dataFilesAtribute.combineUpadateKEP = combineUpadateKEP
        dataFilesAtribute.auctionsGetAwards = props.auctionsGetAwards
        dataFilesAtribute.contractsAuctionIdGet = props.contractsAuctionIdGet
        dataFilesAtribute.auctionsBidsGetId = props.auctionsBidsGetId
        dataFilesAtribute.aucId = props.popUpContractApprowe.aucId
    }
    return (
            <div className={styles.wrapMain}>
            <div className={styles.wrap}>
                <div
                    className={styles.close}
                    onClick={
                        () => {
                            props.setContractDocId(null)
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeDocumentFileName(null)
                            props.changeCA('null')
                            props.togetherApiDocFileName(null)
                            props.ecpApiPass('')
                            props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    ✕
                </div>
                {namePopUp(props)}
                <div className={styles.bidLotCost_input}>
                    {/*<Select
                        text='Тип документу'
                        list={}
                    />*/}
                    {viewSelect(props)}

                    <input 
                        className={styles.inputText}
                        type='text'
                        placeholder='Публічна назва документу'
                        value={props.popUpContractUniversal.title}
                        onInput={(e) => {
                            // props.changeBidAward(e.target.value)
                            props.setContractDocTitle(e.target.value)
                        }}
                    />
                    <textarea
                        className={styles.inputTextArea}
                        placeholder='Опис документу'
                        value={props.popUpContractUniversal.description}
                        onInput={(e) => {
                            props.setContractDocDescription(e.target.value)
                        }}
                    />


                    <div className={dropArea.style}
                        onDragStart={ e => dragStartHandler(e)}
                        onDragLeave={ e => dragLeaveHandler(e)}
                        onDragOver={ e => dragStartHandler(e)}
                        onDrop={ e => onDporHandler(e)}
                    >
                        <p>{dropArea.text}</p>
                        <label className={styles.btnPin}>Прикріпити
                            <input type="file"
                                name="file"
                                id="inputFile"
                            onInput={(e) => {
                                    e.preventDefault()
                                    files = document.querySelector('#inputFile').files
                                let tmp = <>
                                    <div>{`Додано файл:
                                        ${files[0].name.length > 45 ? files[0].name.substr(0, 50) + '...' : files[0].name}`}
                                    </div>
                                        <div>
                                            {`розмір: ${(files[0].size / 1000000).toFixed(2)} Mb, тип: ${files[0].type}`}
                                        </div>
                                            </>
                                    setDropArea({
                                        text: tmp, style: styles.dropZoneActive
                                    })
                                        // props.changeBidAward(e.target.value)
                                    }}></input>
                        </label>
                        
                    </div>
                </div>
                <ESignCheckBlock 
                    dataFilesAtribute={dataFilesAtribute}
                />
                {/*<div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                        onClick={
                            () => {
                                props.setContractDocId(null)
                                props.setContractDocTitle('')
                                props.setContractDocType('null')
                                props.setContractDocDescription('')
                                props.changeDocumentFileName(null)
                                props.changeCA('null')
                                props.togetherApiDocFileName(null)
                                props.ecpApiPass('')
                                props.changeContractApprowePopUpStatus(null)
                            }
                        }
                    >
                        Скасувати
                    </div>
                    {viewButtons(props)}
                    </div>*/}
                {buttonsBlock(props)}
            </div>
        </div>
    )
}

function buttonsBlock(props){
    if(props.togetherApiPage.ecpOnOff === false){
        return (
            <div className={styles.bottomPart}>
                <div className={styles.btnCancel}
                    onClick={
                        ()=>{
                            props.setContractDocId(null)
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeDocumentFileName(null)
                            props.changeCA('null')
                            props.togetherApiDocFileName(null)
                            props.ecpApiPass('')
                            props.changeContractApprowePopUpStatus(null)
                        }
                    }
                >
                    Скасувати
                </div>
                {viewButtons(props)}
            </div>
        )
    }
    return null
}

function viewButtons(props){
    if(
        props.popUpContractUniversal.title !== '' &&
        props.popUpContractUniversal.title !== null &&
        props.popUpContractUniversal.type !== 'null' &&
        props.popUpContractUniversal.type !== null
    ){
        return (
            <div className={styles.btnDone}
                onClick={
                    () => {
                        //combineCreateDoc(props, props.auctionId.id)
                        if(props.popUpContractUniversal.id === null){
                            uploadNewDoc(props)
                        }else{
                            if(files === undefined || files === 'undefined'){
                                patchNewDocWithoutFiles(props)
                            }else{
                                patchNewDocFiles(props)
                            }
                        }
                    }
                }
            >
                Зберегти
            </div>
        )
    }
    return null
}

function viewSelect(props){
    if(props.popUpContractUniversal.id !== null){
        return (
            <div className={styles.selectWrap}>
                <select
                    disabled='disabled'
                    className={styles.selectTypeFile}
                    value={props.popUpContractUniversal.type}
                    onChange={
                        (e)=>{
                            props.setContractDocType(e.target.value)
                        }
                    }
                >
                    <option selected disabled default value='null'>Обрати</option>
                    {listDoc(props)}
                </select>
            </div>
        )
    }
    return (
        <div className={styles.selectWrap}>
            <select
                className={styles.selectTypeFile}
                value={props.popUpContractUniversal.type}
                onChange={
                    (e)=>{
                        props.setContractDocType(e.target.value)
                    }
                }
            >
                <option selected disabled default value='null'>Обрати</option>
                {listDoc(props)}
            </select>
        </div>
    )
}

function listDoc(props){
    if(
        props.auctionId.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionId.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        return (
            <>
                <option value='transferAct'>Акт приймання-передачі</option>
                <option value='contractSigned'>Підписаний договір</option>
                <option value='contractNotice'>Повідомлення про договір</option>
                <option value='contractAnnexe'>Додатки до договору</option>
            </>
        )
    }else if(
        props.auctionId.sellingMethod === 'landRental-english' ||
        props.auctionId.sellingMethod === 'landRental-english-fast' ||
        props.auctionId.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionId.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionId.sellingMethod === 'landSell-english' ||
        props.auctionId.sellingMethod === 'landSell-english-fast' ||
        props.auctionId.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionId.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(props.contractAuctionIdGet[props.popUpContractApprowe.count].status === 'signed'){
            if(props.popUpContractUniversal.id === null){
                return (
                    <>
                        <option value='paymentInformation'>Відомості про сплату</option>
                    </>
                )
            }else{
                return (
                    <>
                        <option disabled value='contractSigned'>Підписаний договір</option>
                        <option disabled value='contractNotice'>Повідомлення про договір</option>
                        <option disabled value='contractAnnexe'>Додатки до договору</option>
                        <option disabled value='paymentInformation'>Відомості про сплату</option>
                    </>
                )
            }
        }else{
            return (
                <>
                    <option value='contractSigned'>Підписаний договір</option>
                    <option value='contractNotice'>Повідомлення про договір</option>
                    <option value='contractAnnexe'>Додатки до договору</option>
                </>
            )
        }
    }else{
        return (
            <>
                <option value='contractSigned'>Підписаний договір</option>
                <option value='contractNotice'>Повідомлення про договір</option>
                <option value='contractAnnexe'>Додатки до договору</option>
            </>
        )
    }
}

function namePopUp(props){
    if(props.bidEdit !== null){
        if(props.bidEdit.hasOwnProperty('id') === true){
            return (
                <>
                    <h3 className={styles.popupTitle}>Редагувати документ</h3>
                    <p className={styles.description}>Ви редагуєте документ контракту</p>
                </>
            )
        }
    }
    return (
        <>
            <h3 className={styles.popupTitle}>Додати документ</h3>
            <p className={styles.description}>Ви додаєте документ до контракту</p>
        </>
    )
}

async function patchNewDocWithoutFiles(props){
    await props.changeLoader(true)
    let body = {}
    if(props.popUpContractUniversal.title !== '' && props.popUpContractUniversal.title !== null) body.title = props.popUpContractUniversal.title
    if(props.popUpContractUniversal.description !== '' && props.popUpContractUniversal.description !== null) body.description = props.popUpContractUniversal.description
    if(props.popUpContractUniversal.type !== 'null' && props.popUpContractUniversal.type !== null) body.documentType = props.popUpContractUniversal.type
    body.language = 'uk'
    body.documentOf = 'contract'
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document/${props.popUpContractUniversal.id}`
    await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error);
    let data1 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag
    }
    let headers1 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    await props.contractsAuctionIdGet(data1, headers1, props.auctionId.id)
    await getContractsAuctionHistory(props)
    await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
}

async function patchNewDocFiles(props){
    await props.changeLoader(true)
    const addFileInp = files;
    //const addFileInp = document.getElementById('input__fileDel');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    //formData.append('Content-Type', 'multipart/form-data')
    formData.append('documentType', props.popUpContractUniversal.type)
    formData.append('file', addFileInp[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let body = {}
            if(props.popUpContractUniversal.title !== '' && props.popUpContractUniversal.title !== null) body.title = props.popUpContractUniversal.title
            if(props.popUpContractUniversal.description !== '' && props.popUpContractUniversal.description !== null) body.description = props.popUpContractUniversal.description
            if(props.popUpContractUniversal.type !== 'null' && props.popUpContractUniversal.type !== null) body.documentType = props.popUpContractUniversal.type
            body.language = 'uk'
            body.documentOf = 'contract'
            body.token = json.jwt
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document/${props.popUpContractUniversal.id}`
            await fetch(endPoint, {
                method: "PATCH",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error);
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            await props.contractsAuctionIdGet(data1, headers1, props.auctionId.id)
            await getContractsAuctionHistory(props)
            await props.changeLoader(false)
            await props.setContractDocId(null)
            await props.setContractDocTitle('')
            await props.setContractDocType('null')
            await props.setContractDocDescription('')
            await props.changeContractApprowePopUpStatus(null)
        }
    }
}

async function getContractsAuctionHistory(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/contracts/${props.popUpContractApprowe.contractId}/documents`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        props.saveHistoryDocsContracts(json)
    }
}

async function uploadNewDoc(props){
    await props.changeLoader(true)
    const addFileInp = files;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.popUpContractUniversal.type)
    formData.append('file', addFileInp[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let body = {}
            if(props.popUpContractUniversal.title !== '' && props.popUpContractUniversal.title !== null) body.title = props.popUpContractUniversal.title
            if(props.popUpContractUniversal.description !== '' && props.popUpContractUniversal.description !== null) body.description = props.popUpContractUniversal.description
            if(props.popUpContractUniversal.type !== 'null' && props.popUpContractUniversal.type !== null) body.documentType = props.popUpContractUniversal.type
            body.language = 'uk'
            body.documentOf = 'contract'
            body.token = json.jwt
            const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/document`
            const response1 = await fetch(endPoint1, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json1 = await response1.json()
                if(json1.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json1)})
                    props.setUniversalError(tmp)
                    await props.changeLoader(false)
                }else{
                    let data1 = {
                        domen: process.env.REACT_APP_END_POINT_BD,
                        version: 'v1',
                        local: props.langFlag
                    }
                    let headers1 = {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                    await props.contractsAuctionIdGet(data1, headers1, props.auctionId.id)
                    await props.changeLoader(false)
                    await props.setContractDocId(null)
                    await props.setContractDocTitle('')
                    await props.setContractDocType('null')
                    await props.setContractDocDescription('')
                }
            }
        }
    }
    await props.changeLoader(false)
    await props.setContractDocId(null)
    await props.setContractDocTitle('')
    await props.setContractDocType('null')
    await props.setContractDocDescription('')
    await props.changeContractApprowePopUpStatus(null)
}




const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionsGetId,
        bidEdit: state.start.bidEdit,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,
        createDocuments: state.start.createDocuments,
        popUpContractUniversal: state.start.popUpContractUniversal,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,

    setUniversalError,
    changeContractApprowePopUpStatus,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,

    changeContractErrorPopUpStatus,

    setContractDocId,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    
    contractsAuctionIdGet,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    
    changeLoader,
    editAwardDoc,

    saveHistoryDocsContracts,
    auctionsBidsGetId,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidAddDocPopUp)