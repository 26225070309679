import React, { useState } from 'react';
import styles from './arrests.module.css';

import Button from '../../../../elements/buttons/button';
import Input from '../../../../elements/inputs/input';
import InputDate from '../../../../elements/inputs/inputDate';
import Checkbox from '../../../../elements/inputs/checkBox';

const ArrestsItem = ({item, arrest, setArrest, count}) => {
    const [statementID, setStatementID] = useState(item.statementID);
    const [restrictionDate, setRestrictionDate] = useState(item.restrictionDate);
    const [restrictionOrganization, setRestrictionOrganization] = useState(item.restrictionOrganization);
    const [isRemovable, setIsRemovable] = useState(item.isRemovable);
    const [status, setStatus] = useState(item?.status ? item.status : false);

    const [edit, setEdit] = useState(false);

    let body = {
        statementID: statementID,
        restrictionDate: restrictionDate,
        restrictionOrganization: restrictionOrganization,
        isRemovable: isRemovable,
        status: status
    }

    function deleteItem(data){
        let tmp = [...arrest]
        tmp[count].status = data
        setArrest(null)
        setArrest(tmp)
    }
    function handleEdit(){
        setEdit(!edit)
    }
    function handleSave(){
        let tmp = [...arrest]
        tmp[count] = body
        setArrest(null)
        setArrest(tmp)
        setEdit(!edit)
    }
    function handleChancel(){
        setStatementID(item.statementID)
        setRestrictionDate(item.restrictionDate)
        setRestrictionOrganization(item.restrictionOrganization)
        setIsRemovable(item.isRemovable)
        setEdit(!edit)
    }

    return (
        <div className={styles.bankBlock}>
            <div className={styles.arrestLine}>
                <div className={styles.arrestLineElem}>
                    <Input
                        label="Номер виконавчого провадження"
                        value={statementID}
                        onChange={(e)=>{ setStatementID(e) }}
                        required
                        disabled={edit ? null : 'disabled'}
                    />
                </div>
                <div className={styles.arrestLineElem}>
                    <InputDate
                        data={{
                            label: "Дата виконавчого провадження",
                            target: restrictionDate,
                        }}
                        onChange={ e => setRestrictionDate(e) }
                        disabled={edit ? null : 'disabled'}
                    />
                </div>
            </div>
            <div className={styles.arrestLine}>
                <div className={styles.arrestLineElem}>
                    <Input
                        label="Орган що видав"
                        value={restrictionOrganization}
                        onChange={(e)=>{ setRestrictionOrganization(e) }}
                        disabled={edit ? null : 'disabled'}
                    />
                </div>
                <div className={styles.arrestLineElem}>
                    <Checkbox 
                        className={styles.checkbox}
                        value={isRemovable}
                        onChange={setIsRemovable}
                        required
                        disabled={edit ? null : 'disabled'}
                    >
                        Арешт може бути знятий після продажу виконавцем *
                    </Checkbox>
                </div>
            </div>
            <div className={styles.arrestLine + " " + styles.arrestLineElem}>
                { edit
                    ? <Button
                            height={'40px'}
                            font={'14px'}
                            color='blue'
                            data={'Зберегти'}
                            onClick={handleSave}
                        />
                    : status
                        ? null
                        : <Button
                                height={'40px'}
                                font={'14px'}
                                color='blue'
                                data={'Редагувати'}
                                onClick={handleEdit}
                            />
                }
                { edit
                    ? <Button
                            height={'40px'}
                            font={'14px'}
                            color='blue'
                            data={'Відминити'}
                            onClick={handleChancel}
                        />
                    : null }
                { status
                    ? <Button
                            height={'40px'}
                            font={'14px'}
                            color='blue'
                            data={'Поновити'}
                            onClick={()=>{
                                deleteItem(false)
                                setStatus(false)
                            }}
                        />
                    : <Button
                            height={'40px'}
                            font={'14px'}
                            color='blue'
                            data={'Видалити'}
                            onClick={()=>{
                                deleteItem(true)
                                setStatus(true)
                            }}
                        />
                }
            </div>
        </div>
    )
}

export default ArrestsItem