import procedure, {
    isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD, isLLP, isLRE,
    isLSE, isLSP,  isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE,
    isAPE, isAPD
} from './procedure';

import { checkProp } from './check';



// Типы документов бида
export function setBidDocTypes(type, x = null, resident = null, status = false)
{
        if (isTIE(type)) {
            return [
                { value: 'x_nonSanctionedStatement', name: 'Заява про непоширення обмежувальних заходів (санкції)', main: true },
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документа, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_nonResidentRegistrations', name: 'Документ про реєстрацію у державі її місцезнаходження (нерезидент)', main: false },
                { value: 'eligibilityDocuments', name: 'Документи що підтверджують відповідність', main: false },
            ]
        }
        if (isSUD(type) || isSUE(type)) {
            return [
                { value: 'x_nonSanctionedStatement', name: 'Заява про непоширення обмежувальних заходів (санкції)', main: false },
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документа, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'eligibilityDocuments', name: 'Документи що підтверджують відповідність', main: false },
            ]
        }
        if (isRCE(type) || isRCD(type) || isGFE(type) || isGFD(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'eligibilityDocuments', name: 'Документи що підтверджують відповідність', main: false },
            ]
        }
        if (isLLE(type) || isLLD(type) || isLLP(type)) {
            return [
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документа, що посвідчує особу', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_nonResidentRegistrations', name: 'Документ про реєстрацію у державі її місцезнаходження (нерезидент)', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false}
            ]
        }
        if (isLRE(type) || isLSE(type) || isLSP(type)) {
            let result = [
                { value: 'commercialProposal', name: 'Заява на участь', main: true },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                { value: 'x_passport', name: 'Копія паспорта або документа, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'x_registerExtract', name: 'Копія витягу з ЄДРПОУ або документ про реєстрацію у державі місцезнаходження', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                // { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
            ]
            if (x === true) {
                let tmp = { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true }
                result.splice(1, 0, tmp)
            }
        return result
        }
        if (isBSE(type) || isBSD(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'eligibilityDocuments', name: 'Документи що підтверджують відповідність', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_passport', name: 'Копія паспорта', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
            ]
        }
        if (isALE(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_registerExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія РНОКПП', main: false },
            ]
        }
        if (isCSE(type) || isCSD(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія РНОКПП', main: false },
            ]
        }
        if (isBRE(type) || isBRD(type) || isBRW(type)) {
            return [
                //{ value: 'commercialProposal', name: 'Заява на участь', main: false },
                //{ value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія РНОКПП', main: false },
            ]
        }

        if (isRLE(type) || isRLD(type)){
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія РНОКПП', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'x_nonResidentRegistrations', name: 'Документ про реєстрацію у державі її місцезнаходження (для юридичних осіб - нерезидентів)', main: false },
            ]
        }

        if (isCLE(type) || isCLD(type)){
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія РНОКПП', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'x_nonResidentRegistrations', name: 'Документ про реєстрацію у державі її місцезнаходження (для юридичних осіб - нерезидентів)', main: false },
            ]
        }

        if (isSPE(type) || isSPD(type)) {
            return [
                
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_passport', name: 'Копія паспорта', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
                { value: 'writtenConsent', name: 'Письмова згода потенційного покупця щодо взяття на себе зобов\'язань визначених умовами продажу', main: false },
                { value: 'financialStatements', name: 'Остання річна або квартальна фінансова звітність', main: false },
                { value: 'noRestrictionsStatement', name: 'Заява про те, що потенційний покупець не підпадає під обмеження, передбаченні статтею 8 ЗУ', main: true },
                { value: 'objectAcquaintance', name: 'Заява про ознайомлення з об\'єктом', main: true },
            ]
        }

        if (isNLE(type) || isNLD(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують кваліфікацію', main: false },
                { value: 'eligibilityDocuments', name: 'Документи що підтверджують відповідність', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
            ]
        }
        if (isLAE(type) || isLAW(type) || isLAP(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: true },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'x_registerExtract', name: 'Копія витягу з ЄДРПОУ або документ про реєстрацію у державі місцезнаходження', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
            ]
        }
        if (isLAE(type) || isLAW(type) || isLAP(type)) {
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: true },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'x_registerExtract', name: 'Копія витягу з ЄДРПОУ або документ про реєстрацію у державі місцезнаходження', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
            ]
        }

    if (type.startsWith('renewables-multiAwards')) {
        return [
            {value: 'x_guarantee', name: 'Фінансове забезпечення', main: true},
            {value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: true},
            {value: 'x_governingBodyInfo', name: 'Інформація про органи управління', main: false},
            {value: 'x_relatedParties', name: 'Інформація про пов\'язаних осіб', main: false},
            {value: 'x_generationType', name: 'Довідка із зазначенням виду альтернативного джерела енергії', main: false},
            {value: 'eligibilityDocuments', name: 'Договір про приєднання об\'єкта електроенергетики', main: false},
        ]
    }

        if (isLPE(type)) {
            if(resident === "UA-EDR"){
                return [
                    { value: 'commercialProposal', name: 'Заява на участь', main: false },
                    { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                    { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                    { value: 'financialStatements', name: 'Остання річна або квартальна фінансова звітність', main: true },
                    { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника або інформація про відсутність кінцевого бенефіціарного власника і про причину його відсутності', main: false },
                    { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
                    { value: 'fonds', name: 'Інформація про джерела походження коштів для придбання об\'єкта великої приватизації', main: false },
                    { value: 'propertyStatus', name: 'Документ про майновий стан і доходи', main: false },
                    { value: 'agreement', name: 'Згода про взяття на себе зобов\'язань, визначених умовами продажу', main: true },
                    { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                    { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                ]
            }
            if(resident === "UA-IPN"
                || resident === "UA-PASSPORT"
                || resident === "UA-ID-CARD"
                || resident === "UA-IPN-FOP"
            ){
                return [
                    { value: 'commercialProposal', name: 'Заява на участь', main: false },
                    { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                    { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                    { value: 'financialStatements', name: 'Остання річна або квартальна фінансова звітність', main: false },
                    { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника або інформація про відсутність кінцевого бенефіціарного власника і про причину його відсутності', main: false },
                    { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
                    { value: 'fonds', name: 'Інформація про джерела походження коштів для придбання об\'єкта великої приватизації', main: true },
                    { value: 'propertyStatus', name: 'Документ про майновий стан і доходи', main: false },
                    { value: 'agreement', name: 'Згода про взяття на себе зобов\'язань, визначених умовами продажу', main: true },
                    { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                    { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
                ]
            }
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                { value: 'financialStatements', name: 'Остання річна або квартальна фінансова звітність', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника або інформація про відсутність кінцевого бенефіціарного власника і про причину його відсутності', main: false },
                { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: false },
                { value: 'fonds', name: 'Інформація про джерела походження коштів для придбання об\'єкта великої приватизації', main: false },
                { value: 'propertyStatus', name: 'Документ про майновий стан і доходи', main: false },
                { value: 'agreement', name: 'Згода про взяття на себе зобов\'язань, визначених умовами продажу', main: true },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: true },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: true },
            ]
        }
        if (isAPE(type) || isAPD(type)) {
            if(resident === "UA-EDR"){
                if(status){
                    return [
                        { value: 'commercialProposal', name: 'Заява на участь', main: false },
                        { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                        { value: 'x_IPN', name: 'Копія ІПН', main: false },
                        { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: true },
                        { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: true },
                        { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                        { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                        { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                        { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
                    ]    
                }
                return [
                    { value: 'commercialProposal', name: 'Заява на участь', main: false },
                    { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                    { value: 'x_IPN', name: 'Копія ІПН', main: false },
                    { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: true },
                    { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: true },
                    { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                    { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                    { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                    //{ value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
                ]
            }
            if(resident === "UA-IPN"
                || resident === "UA-PASSPORT"
                || resident === "UA-ID-CARD"
                || resident === "UA-IPN-FOP"
            ){
                if(status){
                    return [
                        { value: 'commercialProposal', name: 'Заява на участь', main: false },
                        { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: true },
                        { value: 'x_IPN', name: 'Копія ІПН', main: true },
                        { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                        { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                        { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                        { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                        { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                        { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
                    ]    
                }
                return [
                    { value: 'commercialProposal', name: 'Заява на участь', main: false },
                    { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: true },
                    { value: 'x_IPN', name: 'Копія ІПН', main: true },
                    { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                    { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                    { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                    { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                    { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                    //{ value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
                ]
            }
            if(status){
                return [
                    { value: 'commercialProposal', name: 'Заява на участь', main: false },
                    { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                    { value: 'x_IPN', name: 'Копія ІПН', main: false },
                    { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                    { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                    { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                    { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                    { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                    { value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
                ]    
            }
            return [
                { value: 'commercialProposal', name: 'Заява на участь', main: false },
                { value: 'x_passport', name: 'Копія паспорта або документу, що посвідчує особу', main: false },
                { value: 'x_IPN', name: 'Копія ІПН', main: false },
                { value: 'x_tenderersRegisterExtract', name: 'Витяг з ЄДРПОУ або копія документа про реєстрацію', main: false },
                { value: 'x_ultimateBeneficiaryInfo', name: 'Інформація про кінцевого бенефіціарного власника', main: false },
                { value: 'x_registrationFeeApproval', name: 'Документ, що підтверджує сплату реєстраційного внеску', main: false },
                { value: 'x_guaranteeApproval', name: 'Документ, що підтверджує сплату гарантійного внеску', main: false },
                { value: 'qualificationDocuments', name: 'Документи що підтверджують відповідність вимогам', main: false },
                //{ value: 'admissionReason', name: 'Підстави для допуску дискваліфікованого учасника', main: true },
            ]
        }
    return null
}

export function getBidDocType(aucType, docValue, x = null, resident = null) {
    let tmpArr = setBidDocTypes(aucType, true, resident)
    for (let key in tmpArr) {
        if (tmpArr[key].value === docValue ) return tmpArr[key].name
    }
    if (docValue === 'auctionProtocol'){
        if(isLAP(aucType)){
            return 'Протокол про результати земельних торгів'
        }else{
            return 'Протокол аукціону'
        }
    }
    if (docValue === 'digitalSignature') { return 'Цифровий підпис' }
    if (docValue === 'admissionReason') { return 'Підстави для допуску дискваліфікованого учасника' }
    return 'error'
}

// типы документов AYKA, x - для передачи типа айтема, или еще чего
export function setAucDocTypes(auction) {
    if (isTIE(auction.sellingMethod)) {
        let result = [
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: true },
            { value: 'contractProforma', name: 'Типова форма договору', main: false },
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
        if (auction.status !== null) { // опис причин редагування, если аук не чернетка
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isSUD(auction.sellingMethod) || isSUE(auction.sellingMethod)) {
        let result = [
            { value: 'x_auctionDocumentationPackage', name: 'Пакет аукціонної документації', main: false },
            { value: 'x_submissionBidProcedure', name: 'Порядок подання заявок для участі в аукціоні', main: false },
            { value: 'x_nonSanctionedRequirements', name: 'Вимоги до заявників (санкції)', main: false },
            { value: 'x_brokerPaymentConditions', name: 'Порядок та строки сплати винагороди оператору', main: false },
            { value: 'x_qualificationSigningTerms', name: 'Строки підписання протоколу проведення аукціону та договорів купівлі-продажу', main: false },
            { value: 'evaluationCriteria', name: 'Перелік даних, які надаються заявниками', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'illustration', name: 'Ілюстрація', main: false },
            { value: 'contractProforma', name: 'Типова форма договору', main: false },
            { value: 'contractProformaEN', name: 'Типова форма договору (англ.)', main: false },
        ]
        if (auction.status !== null) { // опис причин редагування, если аук не чернетка
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isRCE(auction.sellingMethod) || isRCD(auction.sellingMethod)) {
        let result =  [
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: null },
            { value: 'illustration', name: 'Ілюстрація', main: null },
            { value: 'notice', name: 'Паспорт торгів', main: null },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: null },
            { value: 'x_presentation', name: 'Презентація', main: null },
        ]
        if (auction.status !== null) { // опис причин редагування, если аук не чернетка
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isGFE(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрація', main: null },
            { value: 'notice', name: 'Паспорт торгів', main: null },
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: null },
            { value: 'evaluationCriteria', name: 'Перелік даних, які надаються заявниками', main: null },
            { value: 'contractProforma', name: 'Типова форма договору', main: null },
            { value: 'x_presentation', name: 'Презентація', main: null },
            { value: 'x_dgfPublicAssetCertificate', name: 'Публічний паспорт активу (майна)', main: null },
            { value: 'bidders', name: 'Інформація про учасників', main: null },
            { value: 'x_nda', name: 'Договір про нерозголошення (NDA)', main: null }, //TODO: Публичность документа???
            { value: 'cancellationDetails', name: 'Причини скасування', main: null }
        ]
        if (auction.status !== null) { // опис причин редагування, если аук не чернетка
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isGFD(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрація', main: null },
            { value: 'notice', name: 'Паспорт торгів', main: null },
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: null },
            { value: 'evaluationCriteria', name: 'Перелік даних, які надаються заявниками', main: null },
            { value: 'contractProforma', name: 'Типова форма договору', main: null },
            { value: 'x_presentation', name: 'Презентація', main: null },
            { value: 'x_dgfPublicAssetCertificate', name: 'Публічний паспорт активу (майна)', main: null },
            { value: 'bidders', name: 'Інформація про учасників', main: null },
            { value: 'x_nda', name: 'Договір про нерозголошення (NDA)', main: null },
            { value: 'cancellationDetails', name: 'Причини скасування', main: null },
        ]
        if (auction.status !== null) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)) {
        let result = [
            { value: 'contractProforma', name: 'Проект договору оренди', main: true },
            { value: 'illustration', name: 'Фотографічне зображення майна', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
            { value: 'x_approvedDocument', name: 'Копія рішень, дозволів та згоди', main: false },
            { value: 'x_agreementCopy', name: 'Копії договорів', main: false },
            { value: 'x_leaseObjectInformation', name: 'Характеристики та документи об’єкта оренди', main: false },
            { value: 'x_assessmentDocument', name: 'Документи щодо оцінки об\'єкта', main: false },
            { value: 'x_currentTenantDocument', name: 'Документи щодо прав чинного орендаря', main: false },
        ]
        if(auction.items !== null){
            if(auction.items.length > 0){
                for (let key in auction.items){
                    if (auction.items[key].hasOwnProperty('itemType')) {
                        if (auction.items[key].itemType === 'realEstate') {
                            let tmp = { value: 'x_itemPlan', name: 'Поверховий план об\'єкта або план поверха', main: true }
                            result.splice(2, 0, tmp)
                        } else if (auction.items[key].itemType === 'jointPropertyComplex' || auction.items[key].itemType === 'otherProperty') {
                            let tmp = { value: 'x_itemPlan', name: 'Поверховий план об\'єкта або план поверха', main: false }
                            result.splice(2, 0, tmp)
                        }
                    }
                }
            }
        }
        if (auction.status === "active_rectification") {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isLRE(auction.sellingMethod)) {
        let result = [
            { value: 'contractProforma', name: 'Проєкт договору', main: true },
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
        ]
        if (auction.status === "active_rectification") {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isLSE(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
            { value: 'contractProforma', name: 'Проект договору', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
        ]
        if (auction.status === "active_rectification") {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isLSP(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
            { value: 'contractProforma', name: 'Проект договору', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
        ]
        if (auction.status === "active_rectification") {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
    return result
    }
    if (isBSE(auction.sellingMethod) || isBSD(auction.sellingMethod)) {
        let result = [
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: true },
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'contractProforma', name: 'Типова форма договору', main: false },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
        if (auction.status !== null && auction.isPerishable !== true) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isALE(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: true },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Типова форма договору', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
        if (auction.status !== null) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isCSE(auction.sellingMethod) || isCSD(auction.sellingMethod)) {
        let illustration = false
        let tmpDate = Date.now()
        if(auction.items !== null){
            if(auction.items.length > 0){
                 for (let key in auction.items){
                    if ( checkProp(auction.items[key], 'classification') && getAucItemType(auction.items[key].classification.id, auction.sellingMethod) === 'asset') illustration = true
                }
            }
        }
        let result = [
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'illustration', name: 'Ілюстрації', main: illustration },
            { value: 'contractProforma', name: 'Проєкт договору', main: false },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
        if (auction.status !== null && auction.isPerishable !== true) {
            let clarificacity = false
            if (auction.status === 'active_tendering' && 
            checkProp(auction, 'rectificationPeriod') && 
            checkProp(auction.rectificationPeriod, 'endDate') && 
            (tmpDate < Date.parse(auction.rectificationPeriod.endDate)) ){
                clarificacity = true
            }
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: clarificacity }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isBRE(auction.sellingMethod) || isBRD(auction.sellingMethod)) {
        let illustration = false
        let contractProforma = false
        let tmpDate = Date.now()
        if(auction.items !== null){
            if(auction.items.length > 0){
                 for (let key in auction.items){
                    if ( checkProp(auction.items[key], 'classification') && getAucItemType(auction.items[key].classification.id, auction.sellingMethod) === 'asset') illustration = true
                    if ( checkProp(auction.items[key], 'classification') && getAucItemType(auction.items[key].classification.id, auction.sellingMethod) === 'claimRights') contractProforma = true
                }
            }
        }
        let result = [
            { value: 'illustration', name: 'Ілюстрації', main: illustration },
            { value: 'video', name: 'Відеоматеріали', main: false },
            { value: 'restrictions', name: 'Обмеження', main: false },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'courtDecision', name: 'Рішення суду', main: false },
            { value: 'minutesOfTheCreditorsCommittee', name: 'Протокол комітету кредиторів', main: false },
            { value: 'letterOfTheSecuredCreditor', name: 'Лист забезпеченого кредитора', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Проєкт акту/договору', main: contractProforma },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
        if (auction.status !== null && auction.isPerishable !== true) {
            let clarificacity = false
            if (auction.status === 'active_tendering' && 
            checkProp(auction, 'rectificationPeriod') && 
            checkProp(auction.rectificationPeriod, 'endDate') && 
            (tmpDate < Date.parse(auction.rectificationPeriod.endDate)) ){
                clarificacity = true
            }
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: clarificacity }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isBRW(auction.sellingMethod)) {
        let illustration = false
        let contractProforma = false
            if(auction?.items?.length > 0){
                 for (let key in auction.items){
                    if ( checkProp(auction.items[key], 'classification') && getAucItemType(auction.items[key].classification.id, auction.sellingMethod) === 'asset') illustration = true
                    if ( checkProp(auction.items[key], 'classification') && getAucItemType(auction.items[key].classification.id, auction.sellingMethod) === 'claimRights') contractProforma = true
                }
            }

        return [
            { value: 'illustration', name: 'Ілюстрації', main: illustration },
            { value: 'video', name: 'Відеоматеріали', main: false },
            { value: 'restrictions', name: 'Обмеження', main: false },
            { value: 'courtDecision', name: 'Рішення суду', main: false },
            { value: 'minutesOfTheCreditorsCommittee', name: 'Протокол комітету кредиторів', main: false },
            { value: 'letterOfTheSecuredCreditor', name: 'Лист забезпеченого кредитора', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'contractProforma', name: 'Проєкт акту/договору', main: contractProforma },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
    }
    if (isRLE(auction.sellingMethod) || isRLD(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Проект договору оренди', main: true },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]

        if (auction.status !== null) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування.', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isCLE(auction.sellingMethod) || isCLD(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Проект договору оренди', main: true },
            { value: 'x_presentation', name: 'Презентація', main: false },
            { value: 'property_doc', name: 'Документи на майно', main: false },
        ]

        if (auction.status !== null) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isSPE(auction.sellingMethod) || isSPD(auction.sellingMethod)) {
        return [
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'notice', name: 'Інформаційне повідомлення', main: false },
            { value: 'technicalSpecifications', name: 'Інформація про об\'єкт малої приватизації', main: false },
            { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
            { value: 'contractProforma', name: 'Проект договору', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]

    }
    if (isNLE(auction.sellingMethod) || isNLD(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Типова форма договору', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
            { value: 'x_nonperformingLoansPublicAssetCertificate', name: 'Публічний паспорт активу (майна)', main: false },
            { value: 'x_nda', name: 'Договір про нерозголошення (NDA)', main: false },
        ]
        if (auction.status !== null && auction.isPerishable !== true) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }

    if (isLAE(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: true },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Проект акту/договору', main: false },
        ]
        if (auction.status !== null && auction.status !== 'active_tendering') {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isLAW(auction.sellingMethod)) {
        return [
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
            { value: 'contractProforma', name: 'Проект акту', main: false },
        ]
    }
    if (isLAP(auction.sellingMethod)) {
        let result = [
            { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
            { value: 'notice', name: 'Паспорт торгів', main: false },
            { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: true },
            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'contractProforma', name: 'Проект акту/договору', main: false },
        ]
        if (auction.status !== null && auction.status !== 'active_tendering') {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true }
            result.splice(2, 0, tmp)
        }
        return result
    }
    if (isLPE(auction.sellingMethod)) {
        return [
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'notice', name: 'Інформаційне повідомлення', main: false },
            { value: 'technicalSpecifications', name: 'Інформація про об\'єкт великої приватизації', main: false },
            { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
            { value: 'contractProforma', name: 'Проект договору', main: false },
            { value: 'x_presentation', name: 'Презентація', main: false },
        ]
    }
    if (procedure.isREM(auction.sellingMethod)) {
        if (auction.status === 'qualification' || auction.status === 'active_qualification') {
            return [
                { value: 'x_verificationAct', name: 'Акт про результати перевірки документів учасників', main: false },
                { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: false },
            ]
        }

        let result = [
            { value: 'contractProforma', name: 'Типова форма договору про надання послуги', main: true },
            { value: 'x_lotInfoEN', name: 'Документ, що містить оголошення англійською мовою', main: true },

            { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
            { value: 'illustration', name: 'Ілюстрації', main: false },
            { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
            { value: 'x_verificationAct', name: 'Акт про результати перевірки документів учасників', main: false },
            { value: 'guaranteeTemplate', name: 'Примірна форма банківської гарантії для участі в аукціоні', main: false },
        ]
        if (auction.status !== null /*&& auction.status !== 'active_tendering'*/) {
            let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування.', main: false }
            result.splice(2, 0, tmp)
        }
        return result
    }

    if (isAPE(auction.sellingMethod) || isAPD(auction.sellingMethod)) {
        let result
        if(auction.items !== null
            && auction.items.length > 0
            && auction.items[0].hasOwnProperty('classification')
            && auction.items[0].classification !== null
            && auction.items[0].classification.hasOwnProperty('id')
            && (
                auction.items[0].classification.id === '04000000-8'
                || auction.items[0].classification.id === '05000000-5'
                || auction.items[0].classification.id === '06000000-2'
                || auction.items[0].classification.id === '16000000-5'
                || auction.items[0].classification.id === '30000000-9'
                || auction.items[0].classification.id === '32000000-3'
                || auction.items[0].classification.id === '34000000-7'
                || auction.items[0].classification.id === '39000000-2'
                || auction.items[0].classification.id === '41000000-9'
                || auction.items[0].classification.id === '42000000-6'
            )
        ){
            result = [
                { value: 'illustration', name: 'Ілюстрації', main: true },
                { value: 'notice', name: 'Паспорт торгів', main: false },
                { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
                { value: 'contractProforma', name: 'Проект договору', main: false },
            ]
        }else{
            result = [
                { value: 'illustration', name: 'Ілюстрації', main: false },
                { value: 'notice', name: 'Паспорт торгів', main: false },
                { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
                { value: 'contractProforma', name: 'Проект договору', main: false },
            ]
        }

            if (auction.status !== null) {
                let tmp = { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true }
                result.splice(2, 0, tmp)
            }
        //}
        return result
    }
    return null
}

export function getAwardDocType(aucType, value) {
    switch(value){
        case 'paymentInformation':
            return 'Інформація про повний розрахунок'
        case 'partialPayment':
            return 'Інформація про часткову оплату'
        case 'auctionProtocol':
            switch (true) {
                case procedure.isSPE(aucType):
                case procedure.isSPD(aucType):
                case procedure.isLPE(aucType):
                case procedure.isAPE(aucType):
                case procedure.isAPD(aucType):
                    return 'Протокол про результати електронного аукціону'
                case procedure.isLAW(aucType):
                    return 'Протокол попереднього аукціону'
                case procedure.isLAP(aucType):
                    return 'Протокол про результати земельних торгів'
                default:
                    return 'Протокол аукціону'
            }
        case 'rejectionProtocol':
            switch (true) {
                case procedure.isSPE(aucType):
                case procedure.isSPD(aucType) :
                case procedure.isLPE(aucType):
                    return 'Рішення про відмову у затвердженні протоколу або договору'
                case procedure.isLLE(aucType):
                case procedure.isLLD(aucType) :
                case procedure.isLLP(aucType):
                    return 'Протокол відхилення'
                case procedure.isLAP(aucType):
                case procedure.isAPE(aucType):
                case procedure.isAPD(aucType):
                    return 'Рішення Організатора про відмову (дискваліфікацію)'
                case procedure.isREM(aucType):
                    return 'Акт про невідповідність'
                default:
                    return 'Документ, що підтверджує дискваліфікацію'
                }
        case 'act':
            switch (true) {
                case procedure.isSPE(aucType) :
                case procedure.isSPD(aucType) :
                case procedure.isLPE(aucType) :
                case procedure.isAPE(aucType) :
                case procedure.isAPD(aucType):
                    return 'Акт про відмову переможця'
                case procedure.isLLE(aucType):
                case procedure.isLLD(aucType):
                case procedure.isLLP(aucType):
                    return 'Акт про відмову / непідписання'
                case procedure.isLAE(aucType):
                case procedure.isLAW(aucType):
                case procedure.isLAP(aucType):
                    return 'Акт що підтверджує відмову переможця'
                case procedure.isREM(aucType):
                    return 'Акт про відмову'
                default:
                    return 'Документ, що підтверджує відмову'
            }
        case 'digitalSignature': return 'digitalSignature'
        case 'redemptionDecision': return 'Рішення про приватизацію шляхом викупу'
        case 'saleProtocol': return 'Протокол про продаж майна ЗК'
        case 'applicationOfTheSecuredCreditor': return 'Заява забезпеченого кредитора'
        case 'contractSigned': return 'Акт'
        case 'contractAnnexe': return 'Додатки до акту'
        case 'courtOrder': return 'Постанова'
        case 'assetNotice': return "Рішення про реалізацію"
        case '***': return '***'
        default: return value
    }
}

export function getProlongation(aucType, value){
    switch(value){
        case 'prolongationProtocol': return 'Документ, що дозволяє пролонгацію'
        case 'prolongationConsent': return 'Згода на продовження терміну підписання'
        case 'digitalSignature': return 'digitalSignature'
        default: return value
    }
}

export function getAucDocType(aucType, docValue) {
        function getArrays(aucType){
            if (isTIE(aucType)) {
                return [
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: true },
                    { value: 'contractProforma', name: 'Типова форма договору', main: true },
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isSUD(aucType) || isSUE(aucType)) {
                return [
                    { value: 'x_auctionDocumentationPackage', name: 'Пакет аукціонної документації', main: true },
                    { value: 'x_submissionBidProcedure', name: 'Порядок подання заявок для участі в аукціоні', main: true },
                    { value: 'x_nonSanctionedRequirements', name: 'Вимоги до заявників (санкції)', main: true },
                    { value: 'x_brokerPaymentConditions', name: 'Порядок та строки сплати винагороди оператору', main: true },
                    { value: 'x_qualificationSigningTerms', name: 'Строки підписання протоколу проведення аукціону та договорів купівлі-продажу', main: true },
                    { value: 'evaluationCriteria', name: 'Перелік даних, які надаються заявниками', main: true },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'illustration', name: 'Ілюстрація', main: false },
                    { value: 'contractProforma', name: 'Типова форма договору', main: false },
                    { value: 'contractProformaEN', name: 'Типова форма договору (англ.)', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isRCE(aucType) || isRCD(aucType)) {
                return [
                        { value: 'technicalSpecifications', name: 'Технічні специфікації', main: null },
                        { value: 'illustration', name: 'Ілюстрація', main: null },
                        { value: 'notice', name: 'Паспорт торгів', main: null },
                        { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: null },
                        { value: 'x_presentation', name: 'Презентація', main: null },
                        { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                        { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                    ]
            }
            if (isGFE(aucType) || isGFD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрація', main: null },
                    { value: 'notice', name: 'Паспорт торгів', main: null },
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: null },
                    { value: 'evaluationCriteria', name: 'Перелік даних, які надаються заявниками', main: null },
                    { value: 'contractProforma', name: 'Типова форма договору', main: null },
                    { value: 'x_presentation', name: 'Презентація', main: null },
                    { value: 'x_dgfPublicAssetCertificate', name: 'Публічний паспорт активу (майна)', main: null },
                    { value: 'bidders', name: 'Інформація про учасників', main: null },
                    { value: 'x_nda', name: 'Договір про нерозголошення (NDA)', main: null }, //TODO: Публичность документа???
                    { value: 'cancellationDetails', name: 'Причини скасування', main: null },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLLE(aucType) || isLLD(aucType) || isLLP(aucType)) {
                return [
                    { value: 'contractProforma', name: 'Проект договору оренди', main: true },
                    { value: 'illustration', name: 'Фотографічне зображення майна', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'x_approvedDocument', name: 'Копія рішень, дозволів та згоди', main: false },
                    { value: 'x_agreementCopy', name: 'Копії договорів', main: false },
                    { value: 'x_leaseObjectInformation', name: 'Характеристики та документи об’єкта оренди', main: false },
                    { value: 'x_assessmentDocument', name: 'Документи щодо оцінки об\'єкта', main: false },
                    { value: 'x_currentTenantDocument', name: 'Документи щодо прав чинного орендаря', main: false },
                    { value: 'x_itemPlan', name: 'Поверховий план об\'єкта або план поверха', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLRE(aucType)) {
                return [
                    { value: 'contractProforma', name: 'Проєкт договору', main: true },
                    { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLSE(aucType)) {
                return [
                    { value: 'illustration', name: 'Фотографічне зображення майна', main: true },
                    { value: 'contractProforma', name: 'Проект договору', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLSP(aucType)) {
                return [
                    { value: 'illustration', name: 'Фотографічне зображення майна', main: true },
                    { value: 'contractProforma', name: 'Проект договору', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]

            }
            if (isBSE(aucType) || isBSD(aucType)) {
                return [
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: true },
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'contractProforma', name: 'Типова форма договору', main: false },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isALE(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: true },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Типова форма договору', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isCSE(aucType) || isCSD(aucType)) {
                return [
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'contractProforma', name: 'Проєкт договору', main: false },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: false },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isBRE(aucType) || isBRD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'video', name: 'Відеоматеріали', main: null },
                    { value: 'restrictions', name: 'Обмеження', main: null },
                    { value: 'courtDecision', name: 'Рішення суду', main: null },
                    { value: 'minutesOfTheCreditorsCommittee', name: 'Протокол комітету кредиторів', main: null },
                    { value: 'letterOfTheSecuredCreditor', name: 'Лист забезпеченого кредитора', main: null },
                    { value: 'notice', name: 'Паспорт торгів', main: null },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: null },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: null },
                    { value: 'contractProforma', name: 'Проєкт акту/договору', main: null },
                    { value: 'x_presentation', name: 'Презентація', main: null },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: false },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isBRW(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'video', name: 'Відеоматеріали', main: null },
                    { value: 'restrictions', name: 'Обмеження', main: null },
                    { value: 'courtDecision', name: 'Рішення суду', main: null },
                    { value: 'minutesOfTheCreditorsCommittee', name: 'Протокол комітету кредиторів', main: null },
                    { value: 'letterOfTheSecuredCreditor', name: 'Лист забезпеченого кредитора', main: null },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: null },
                    { value: 'contractProforma', name: 'Проєкт акту/договору', main: null },
                    { value: 'x_presentation', name: 'Презентація', main: null },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту, опис причин редагування', main: false },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isRLE(aucType) || isRLD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Проект договору оренди', main: true },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: false },
                ]
            }
            if (isCLE(aucType) || isCLD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Проект договору оренди', main: true },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'property_doc', name: 'Документи на майно', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: false },
                ]
            }
            if (isSPE(aucType) || isSPD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'notice', name: 'Інформаційне повідомлення', main: false },
                    { value: 'technicalSpecifications', name: "Інформація про об'єкт малої приватизації", main: false },
                    { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
                    { value: 'contractProforma', name: 'Проект договору', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'assetNotice', name: "Рішення про затвердження переліку об’єктів МП", main: false}
                ]
            }
            if (isNLE(aucType) || isNLD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: false },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Типова форма договору', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                    { value: 'x_nonperformingLoansPublicAssetCertificate', name: 'Публічний паспорт активу (майна)', main: false },
                    { value: 'x_nda', name: 'Договір про нерозголошення (NDA)', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLAE(aucType) || isLAW(aucType) || isLAP(aucType)) {
                return [
                    { value: 'illustration', name: 'Фотографічні зображення земельної ділянки та ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Копії документів та матеріалів на лот', main: true },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Проект акту/договору', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }
            if (isLPE(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'notice', name: 'Інформаційне повідомлення', main: false },
                    { value: 'technicalSpecifications', name: "Інформація про об'єкт великої приватизації", main: false },
                    { value: 'evaluationCriteria', name: 'Рішення про затвердження умов продажу', main: false },
                    { value: 'contractProforma', name: 'Проєкт договору', main: false },
                    { value: 'x_presentation', name: 'Презентація', main: false },
                ]
            }
            if (isAPE(aucType) || isAPD(aucType)) {
                return [
                    { value: 'illustration', name: 'Ілюстрації', main: true },
                    { value: 'notice', name: 'Паспорт торгів', main: false },
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: true },
                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'contractProforma', name: 'Проект договору', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування', main: true },
                    { value: 'digitalSignature', name: 'Цифровий підпис', main: true }
                ]
            }

            if (procedure.isREM(aucType)) {
                return  [
                    { value: 'contractProforma', name: 'Типова форма договору про надання послуги', main: true },
                    { value: 'x_lotInfoEN', name: 'Документ, що містить оголошення англійською мовою', main: true },

                    { value: 'evaluationCriteria', name: 'Кваліфікаційні вимоги', main: false },
                    { value: 'illustration', name: 'Ілюстрації', main: false },
                    { value: 'technicalSpecifications', name: 'Технічні специфікації', main: false },
                    { value: 'x_verificationAct', name: 'Акт про результати перевірки документів учасників', main: false },
                    { value: 'guaranteeTemplate', name: 'Примірна форма банківської гарантії для участі в аукціоні', main: false },
                    { value: 'clarifications', name: 'Погодження змін до опису лоту. Опис причин редагування.', main: false },
                ]
            }
        }

    for (let key in getArrays(aucType)){
        if (getArrays(aucType)[key].value === docValue){
            return getArrays(aucType)[key].name
        }
    }
}

export function getAucItemType(id, aucType){ // функция нужна для определения типа айтема, и вывода нужных доков
    if (!id) return
    let tmpId = id[0] + id[1]
    if (isCSE(aucType) || isCSD(aucType) || isBRE(aucType) || isBRD(aucType) || isBRW(aucType) || isSPE(aucType) || isSPD(aucType) || isLPE(aucType)){
        if(['07','08'].includes(tmpId)) return 'claimRights'
        if(['04','05','06','16','30','32','34','39','42'].includes(tmpId)) return 'asset'
        return null
    }
}