import React from 'react';
import style from './awardApprowe.module.css';

import {connect} from 'react-redux';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardErrorPopUpStatus, changeAwardApprowePopUpStatus} from '../../../../redux/actions/awards/awards.js';

const DeleteBidDocument = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Затвердити пропозицію</div>
                {createView(props)}
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.changeAwardApprowePopUpStatus(null)
                        }
                    }
                >
                    Закрити
                </div>
            </div>
        </div>
    )
}

/*
Загрузка документа
Разблокировка кнопки удалить
Отсылка картинки
Подключение ее к документу
Создание документа
Отсылка реджекта аварда с подключением документа
*/


function createView(props){
    let tmp;
    tmp = (
        <div className={style.documentsForm}>
            <div className={style.groupElem}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

function buttonCreateImg(props){

    return (
        <div className={style.formLine}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props)
                }}
                id="addFileForm"
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__fileDel"
                        className={style.input + ' ' + style.input__file}
                    />
                </div>
                <button
                    type="submit"
                    className={style.popup_message_button}
                >
                    Затвердити
                </button>
            </form>
        </div>
    )
}

function handleSubmit(e, props){
    e.preventDefault();
    promisUploadFiles11(props)
}

async function promisUploadFiles11(props){
    const addFileInp = document.getElementById('input__fileDel');
    let endPoint;
    if(
        props.popUpAwardReject.sellingMethod === "dgf-english" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', 'auctionProtocol')
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
        const response1 = await fetch(endPoint1, {
            method: "POST",
            body: JSON.stringify({
                title: 'Затвердження аварду',//props.createDocuments.title,
                description: 'Затвердження аварду',//props.createDocuments.description,
                documentType: 'auctionProtocol',
                language: 'uk',//props.createDocuments.language,              //не меняем
                documentOf: 'bid',                           //не меняем
                //documentOf: 'award',                           //не меняем
                //hash: "string",
                token: json.jwt                 //не меняем
            }),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response1 === undefined || response1 === 'undefined'){
        }else{
            const json1 = await response1.json()
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardApprowe.aucId}/award/${props.popUpAwardApprowe.awardId}/documents/${json1.id}`
            const response2 = await fetch(endPoint2, {
                method: "POST",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardApprowe.aucId}/award/${props.popUpAwardApprowe.awardId}/approve`
                const response3 = await fetch(endPoint3, {
                    method: "POST",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        props.changeAwardErrorPopUpStatus(json3)
                    }else{
                        props.changeAwardApprowePopUpStatus(null)
                    }
                }
            }
        }
    }
}






const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardApprowe: state.start.popUpAwardApprowe,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardApprowePopUpStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)