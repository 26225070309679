import React from 'react';

import {connect} from 'react-redux';

import LotId from './auctionElements/lotId'
import AccessDetailsString from './auctionElements/accessDetails'
import PreviousAuctionIdString from './auctionElements/previousAuctionId'
import NormativeMonetaryValuationString from './auctionElements/normativeMonetaryValuation'
import ExpertMonetaryValuationString from './auctionElements/expertMonetaryValuation'
import BudgetSpentString from './auctionElements/budgetSpent'
import RegistrationFeeString from './auctionElements/registrationFee'
import ValueAddedTaxChargedString from './auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './auctionElements/leaseDuration'
import AuctionUrlString from './auctionElements/auctionUrl'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import LeaseTypeString from './auctionElements/leaseType'
import SaleTypeString from './auctionElements/saleType'
import ConditionsString from './auctionElements/conditions'
import RestrictionsString from './auctionElements/restrictions'
import LotCurrentTenantString from './auctionElements/currentTenant'
import LotPreviousAuctionBidderString from './auctionElements/previousAuctionBidder'
import XPlatformLegalDetailsString from './auctionElements/xPlatformLegalDetails'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'
import IsPerishableString from './auctionElements/isPerishable'
import Discount from './auctionElements/discount'
import DutchStep from './auctionElements/dutchStep'
import DisqualifiedBidsDetails from './auctionElements/disqualifiedBidsDetails'

import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
const LotInformation = (props) => {

    function viewDisqualifiedBidsDetails(props){
        let tmp = []
        let count = 0
        for(let key in props.auctionId.disqualifiedBidsDetails){
            tmp[count] = <DisqualifiedBidsDetails info={props.auctionId.disqualifiedBidsDetails[key]}/> 
            count++
        }
        return (
            <div style={{width:"100%"}}>
                <h3>Виключені учасники</h3>
                {tmp}
            </div>
        )
    }

    let tmp = <>
        <LotId />
        <ValueString />
        <DescriptionString />
        <AuctionIdString />
        <TenderAttemptsString />
        <LotIdentifierString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <DutchStep />
        <AccessDetailsString />
        <PreviousAuctionIdString />
        <Discount />
        <AuctionUrlString />
        <LeaseDurationString />
        <IsPerishableString />
        <ValueAddedTaxChargedString />
        <RegistrationFeeString />
        <NormativeMonetaryValuationString />
        <ExpertMonetaryValuationString />
        <BudgetSpentString />
        <LeaseTypeString />
        <SaleTypeString />
        <ConditionsString />
        <RestrictionsString />
        <LotCurrentTenantString />
        <LotPreviousAuctionBidderString />
        <XPlatformLegalDetailsString />
        <XDocumentRequirements />
        {props.auctionId.disqualifiedBidsDetails !== null ? viewDisqualifiedBidsDetails(props) : null}
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LotInformation)
