import React from 'react';

import { Switch, Route } from 'react-router-dom'
import {connect} from 'react-redux';

import CreateAuctionLists from './createAuctionLists';
import CreateAuctionNew from './createAuctionNew';

import EditAuction from './windowAuction/createEditAndCloneAuction/edit/editTabMySQL'
import EditAuctionMonga from './windowAuction/createEditAndCloneAuction/edit/editTabMonga'

import {Redirect} from 'react-router-dom';

const CreateAuctionsRouts = () => {
    if (localStorage.getItem('token') !== null){
        let tmpLocal = JSON.parse(localStorage.getItem('token'))
        let tmpNowTimeStamp = Date.now();
        if (tmpLocal === null || tmpLocal?.timeStamp < tmpNowTimeStamp) {
            return <Redirect to='/'/>
        }
    }

    //if(window.location.pathname.indexOf('/create_auctions/bridge/') !== -1 && props.bridge) return <Redirect to='/create_auctions'/>

    return (
        <>
            <Switch>
                <Route exact path='/create_auctions'
                    render={ () => <CreateAuctionLists /> }
                />
                <Route path='/create_auctions/bridge'
                    render={ () => <CreateAuctionLists /> }
                />
                <Route exact path='/create_auctions/new'
                    render={ () => <CreateAuctionNew /> }
                />
                <Route exact path='/create_auctions/edit/:number'
                    render={ () => <EditAuction /> }
                />
                <Route exact path='/create_auctions/editNew/:number'
                    render={ () => <EditAuctionMonga /> }
                />
            </Switch>
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        bridge: state.start.bridge.openPopUp
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAuctionsRouts)
