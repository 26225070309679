import React from 'react';
import styles from './sidebar.module.css';

import {checkProp, getCurrency} from '../../../../../redux/check';
import procedure, { viewAuctionType } from '../../../../../redux/procedure';
import {useSelector} from 'react-redux';

import { getAuctionsStatus } from "../../../../../api/dictonaries/statusAuctions"

const Sidebar = ( { data}) => {
    const auction = useSelector((state) => state.start.auctionsMy)

    return (
        <>
            <div className={styles.sideBlock}>
                <div className={styles.aucType}>
                    {viewAuctionType(auction.sellingMethod)}
                </div>
                <div className={styles.aucStatus}>
                    <p>Статус</p>
                    <p className={styles.lotStatus}>
                        {getAuctionsStatus(auction.sellingMethod, auction.status)}
                    </p>
                    { checkProp(auction, 'auctionId') ? 
                        <>
                            <p>ID:</p>
                            <p> {auction.auctionId} </p>
                        </> : null
                    }
                </div>
                <div className={styles.lotStartPriceBlock}>
                    <p>Початкова ціна продажу лоту</p>
                    <p className={styles.lotStartPrice}>
                        {startCostAuction(auction)}
                        <span> {getCurrency(auction?.value?.currency, procedure.isREM(auction.sellingMethod) ? 'євроцентів' : 'грн')}</span>
                    </p>
                </div>
            </div>
        </>
    )
}


function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(viewAmount(data));
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'
            }
        }
    }
    if(tmp === "n'ull") tmp = 0
    return tmp + ',' + amount
}

function viewAmount(auction){
    let tmp = null;
    if(auction.hasOwnProperty('value') === true){
        if(auction.value !== null){
            if(auction.value.hasOwnProperty('amount') === true){
                if(auction.value.amount !== null){
                    tmp = auction.value.amount;
                }
            }
        }
    }
    return tmp
}

export default Sidebar