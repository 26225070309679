import React from 'react';
import styles from './header.module.css';

import Logo from './logo/logo.js';
import Faq from './faq/faq.js';
import News from './news/news.js';
import Contacts from './contacts/contacts.js';
import Login from './login/login.js';
import Lang from './lang/lang.js';

import {connect} from 'react-redux';
import {changeMenu} from '../../redux/actions';

import {userProfileGet} from '../../redux/actions/oldSiteUsers/oldSiteUsers.js';

const Header = (props) => {
    return (
        <>
            <div
                className={styles.wrap}
                onClick={
                    () => {
                        if(props.token !== null && props.userProfiles === null){
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: props.langFlag,
                            }
                            let tmp = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            userProfileGet(data, tmp)
                        }
                    
                    }
                }
            >
                <div className={styles.content}>
                    {/*<div className={styles.mobMenuIcon}
                        onClick={
                            () => {
                                mobileMenu ? changeMenu(false) : changeMenu(true)
                            }
                        }
                    >
                        <img src={iconMenu} alt='' />
                    </div>*/}
                    <Logo />
                    <Faq />
                    <News />
                    <Contacts />
                    <Login />
                    <Lang />
                </div>
            </div>
            <div className={styles.wrapB}></div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        mobileMenu: state.start.mobileMenu,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeMenu,
    userProfileGet
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)