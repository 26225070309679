import React,{useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';
import LeaseRules from './leaseRules/leaseRules';
import LeaseDuration from '../../../../elems/leaseDuration/leaseDuration';
import InstructionBlock from '../../../../../elements/viewData/instructionBlock/instructionBlock'
import SlimmerBlock from '../../../../../elements/slimmerBlock/slimmerBlock';

import BanksAccount from '../../bankNew/banks';

//import { setTenderAttemptList, createBodyCSE } from './utils';
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import LocationOrg from '../../../../elems/localization/locationOrg';

import { setDutchStepQuantityList } from '../lib/utils';

const AuctionLLD = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)
    const bankAccount = useSelector(state => state.start.bankAccount)
    

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 3)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    //const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data?.discount,'discount') ? data?.discount?.discount : false)
    const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : {
        amount: 710,
        currency: "UAH",
    })
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') ? data.valueAddedTaxCharged : true)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [martialLawDisclaimer, setMartialLawDisclaimer] = useState(checkProp(data, 'martialLawDisclaimer') ? data.martialLawDisclaimer : true)
    const [calcOnBookValue, setCalcOnBookValue] = useState(checkProp(data, 'calcOnBookValue') ? data.calcOnBookValue : true)
    const [valuePeriod, setValuePeriod] = useState(checkProp(data, 'valuePeriod') ? data.valuePeriod : 'month')

    const [leaseDuration, setLeaseDuration] = useState(checkProp(data, 'leaseDuration') ? data.leaseDuration : null)
    const [leaseRules, setLeaseRules] = useState(checkProp(data, 'leaseRules') ? data.leaseRules : null)

    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])

    const [ownershipType, setOwnershipType] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'ownershipType')) ? data.relatedOrganizations.ownershipType : null)
    // const [, setPropertyOwnerName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'name')) ? isMongo(data.relatedOrganizations.propertyOwner.name) : null)
    const [propertyOwnerIdentifierScheme, setPropertyOwnerIdentifierScheme] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'identifier') && checkProp(data.relatedOrganizations.propertyOwner.identifier, 'scheme')) ? data.relatedOrganizations.propertyOwner.identifier.scheme : null)
    const [propertyOwnerIdentifierLegalName, setPropertyOwnerIdentifierLegalName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'identifier') && checkProp(data.relatedOrganizations.propertyOwner.identifier, 'scheme')) ? isMongo(data.relatedOrganizations.propertyOwner.identifier.legalName) : null)
    const [propertyOwnerIdentifierId, setPropertyOwnerIdentifierId] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'identifier') && checkProp(data.relatedOrganizations.propertyOwner.identifier, 'scheme')) ? data.relatedOrganizations.propertyOwner.identifier.id : null)
    
    
    const [addressPropertyOwner, setAdressPropertyOwner] = useState(
        (checkProp(data, 'relatedOrganizations')
        && checkProp(data.relatedOrganizations, 'propertyOwner')
        && checkProp(data.relatedOrganizations.propertyOwner, 'address')
    ) ? data.relatedOrganizations.propertyOwner.address : null)
    const [propertyOwnerContactPointName, setPropertyOwnerContactPointName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'contactPoint') && checkProp(data.relatedOrganizations.propertyOwner.contactPoint, 'name')) ? isMongo(data.relatedOrganizations.propertyOwner.contactPoint.name) : null)
    const [propertyOwnerContactPointEmail, setPropertyOwnerContactPointEmail] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'contactPoint') && checkProp(data.relatedOrganizations.propertyOwner.contactPoint, 'email')) ? data.relatedOrganizations.propertyOwner.contactPoint.email : null)
    const [propertyOwnerContactPointTelephone, setPropertyOwnerContactPointTelephone] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'contactPoint') && checkProp(data.relatedOrganizations.propertyOwner.contactPoint, 'telephone')) ? data.relatedOrganizations.propertyOwner.contactPoint.telephone : null)
    const [propertyOwnerContactPointFaxNumber, setPropertyOwnerContactPointFaxNumber] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'contactPoint') && checkProp(data.relatedOrganizations.propertyOwner.contactPoint, 'faxNumber')) ? data.relatedOrganizations.propertyOwner.contactPoint.faxNumber : null)
    const [propertyOwnerContactPointUrl, setPropertyOwnerContactPointUrl] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'contactPoint') && checkProp(data.relatedOrganizations.propertyOwner.contactPoint, 'url')) ? data.relatedOrganizations.propertyOwner.contactPoint.url : null)

    const [propertyOwnerRepresentativeInfo, setPropertyOwnerRepresentativeInfo] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'propertyOwner') && checkProp(data.relatedOrganizations.propertyOwner, 'representativeInfo')) ? data.relatedOrganizations.propertyOwner.representativeInfo : null)
    

    /*const [sellingEntityName, setSellingEntityName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'name')) ? isMongo(data.relatedOrganizations.sellingEntity.name) : null)
    const [sellingEntityIdentifierScheme, setSellingEntityIdentifierScheme] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'identifier') && checkProp(data.relatedOrganizations.sellingEntity.identifier, 'scheme')) ? data.relatedOrganizations.sellingEntity.identifier.scheme : null)
    const [sellingEntityIdentifierLegalName, setSellingEntityIdentifierLegalName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'identifier') && checkProp(data.relatedOrganizations.sellingEntity.identifier, 'scheme')) ? isMongo(data.relatedOrganizations.sellingEntity.identifier.legalName) : null)
    const [sellingEntityIdentifierId, setSellingEntityIdentifierId] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'identifier') && checkProp(data.relatedOrganizations.sellingEntity.identifier, 'scheme')) ? data.relatedOrganizations.sellingEntity.identifier.id : null)
    const [sellingEntityAddressCountryName, setSellingEntityAddressCountryName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'address') && checkProp(data.relatedOrganizations.sellingEntity.address, 'countryName')) ? isMongo(data.relatedOrganizations.sellingEntity.address.countryName) : null)
    const [sellingEntityAddressRegion, setSellingEntityAddressRegion] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'address') && checkProp(data.relatedOrganizations.sellingEntity.address, 'region')) ? isMongo(data.relatedOrganizations.sellingEntity.address.region) : null)
    const [sellingEntityAddressLocality, setSellingEntityAddressLocality] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'address') && checkProp(data.relatedOrganizations.sellingEntity.address, 'locality')) ? isMongo(data.relatedOrganizations.sellingEntity.address.locality) : null)
    const [sellingEntityAddressStreetAddress, setSellingEntityAddressStreetAddress] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'address') && checkProp(data.relatedOrganizations.sellingEntity.address, 'streetAddress')) ? isMongo(data.relatedOrganizations.sellingEntity.address.streetAddress) : null)
    const [sellingEntityAddressPostalCode, setSellingEntityAddressPostalCode] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'address') && checkProp(data.relatedOrganizations.sellingEntity.address, 'postalCode')) ? data.relatedOrganizations.sellingEntity.address.postalCode : null)
    const [sellingEntityContactPointName, setSellingEntityContactPointName] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint') && checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'name')) ? isMongo(data.relatedOrganizations.sellingEntity.contactPoint.name) : null)
    const [sellingEntityContactPointEmail, setSellingEntityContactPointEmail] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint') && checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'email')) ? data.relatedOrganizations.sellingEntity.contactPoint.email : null)
    const [sellingEntityContactPointTelephone, setSellingEntityContactPointTelephone] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint') && checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'telephone')) ? data.relatedOrganizations.sellingEntity.contactPoint.telephone : null)
    const [sellingEntityContactPointFaxNumber, setSellingEntityContactPointFaxNumber] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint') && checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'faxNumber')) ? data.relatedOrganizations.sellingEntity.contactPoint.faxNumber : null)
    const [sellingEntityContactPointUrl, setSellingEntityContactPointUrl] = useState((checkProp(data, 'relatedOrganizations') && checkProp(data.relatedOrganizations, 'sellingEntity') && checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint') && checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'url')) ? data.relatedOrganizations.sellingEntity.contactPoint.url : null)*/

    // const [, setSellingEntityName] = useState(null)
    const [sellingEntityIdentifierScheme, setSellingEntityIdentifierScheme] = useState(null)
    const [sellingEntityIdentifierLegalName, setSellingEntityIdentifierLegalName] = useState(null)
    const [sellingEntityIdentifierId, setSellingEntityIdentifierId] = useState(null)
    /*const [sellingEntityAddressCountryName, setSellingEntityAddressCountryName] = useState(null)
    const [sellingEntityAddressRegion, setSellingEntityAddressRegion] = useState(null)
    const [sellingEntityAddressLocality, setSellingEntityAddressLocality] = useState(null)
    const [sellingEntityAddressStreetAddress, setSellingEntityAddressStreetAddress] = useState(null)
    const [sellingEntityAddressPostalCode, setSellingEntityAddressPostalCode] = useState(null)
    */
    const [addressSellingEntity, setAdressSellingEntity] = useState(
        (checkProp(data, 'relatedOrganizations')
        && checkProp(data.relatedOrganizations, 'sellingEntity')
        && checkProp(data.relatedOrganizations.sellingEntity, 'address')
    ) ? data.relatedOrganizations.sellingEntity.address : null)
    const [sellingEntityContactPointName, setSellingEntityContactPointName] = useState(null)
    const [sellingEntityContactPointEmail, setSellingEntityContactPointEmail] = useState(null)
    const [sellingEntityContactPointTelephone, setSellingEntityContactPointTelephone] = useState(null)
    const [sellingEntityContactPointFaxNumber, setSellingEntityContactPointFaxNumber] = useState(null)
    const [sellingEntityContactPointUrl, setSellingEntityContactPointUrl] = useState(null)
    const [sellingEntityRepresentativeInfo, setSellingEntityRepresentativeInfo] = useState(null)
    



    const [dutchStepQuantity, setDutchStepQuantity] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepQuantity') ? data.dutchStep.dutchStepQuantity : null)
    const [dutchStepPercent, setDutchStepPercent] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepPercent') ? data.dutchStep.dutchStepPercent : 1)
    const [dutchStepValue, setDutchStepValue] = useState(checkProp(data, 'dutchStep') && checkProp(data.dutchStep, 'dutchStepValue') ? data.dutchStep.dutchStepValue : null)


    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: {
            uk_UA: accessDetails
        },
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        leaseDuration: leaseDuration,
        leaseRules: leaseRules,
        sellingMethod: data.sellingMethod,
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        //minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        martialLawDisclaimer: martialLawDisclaimer,
        calcOnBookValue: calcOnBookValue,
        valuePeriod: valuePeriod,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        discount: {
            discount: discount,
            discountPercent: discountPercent,
            previousAuctionValue:{
                currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
                valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
            }
        },
        bankAccounts: saveBanks(),
        // debtorInformation: {
        //     name: debtorInformationName,
        //     identifier: {
        //         scheme: identifierScheme,
        //         id: identifierId,
        //         legalName: {
        //             uk_UA: identifierLegalName
        //         }
        //     },
        //     address: {
        //         countryName: {
        //             uk_UA: addressCountryName
        //         },
        //         region: {
        //             uk_UA: addressRegion
        //         },
        //         locality: {
        //             uk_UA: addressLocality
        //         },
        //         streetAddress: {
        //             uk_UA: addressStreetAddress
        //         },
        //         postalCode: addressPostalCode
        //     },
        //     representativeInfo: representativeInfo,
        //     contactPoint: {
        //         name: {
        //             uk_UA: contactPointName
        //         },
        //         email: contactPointEmail,
        //         telephone: contactPointTelephone,
        //         faxNumber: contactPointFaxNumber,
        //         url: contactPointUrl
        //     }
        // },
        relatedOrganizations: {
            propertyOwner: {
                // name: {
                //     uk_UA: propertyOwnerName,
                // },
                identifier: {
                    scheme: propertyOwnerIdentifierScheme,
                    id: propertyOwnerIdentifierId,
                    legalName: {
                        uk_UA: propertyOwnerIdentifierLegalName
                    }
                },
                address: addressPropertyOwner,
                contactPoint: {
                    name: {
                        uk_UA: propertyOwnerContactPointName
                    },
                    email: propertyOwnerContactPointEmail,
                    telephone: propertyOwnerContactPointTelephone,
                    faxNumber: propertyOwnerContactPointFaxNumber,
                    url: propertyOwnerContactPointUrl
                },
                representativeInfo: propertyOwnerRepresentativeInfo
            },
            sellingEntity: {
                // name: {
                //     uk_UA: sellingEntityName,
                // },
                identifier: {
                    scheme: sellingEntityIdentifierScheme,
                    id: sellingEntityIdentifierId,
                    legalName: {
                        uk_UA: sellingEntityIdentifierLegalName
                    }
                },
                address: addressSellingEntity,
                contactPoint: {
                    name: {
                        uk_UA: sellingEntityContactPointName
                    },
                    email: sellingEntityContactPointEmail,
                    telephone: sellingEntityContactPointTelephone,
                    faxNumber: sellingEntityContactPointFaxNumber,
                    url: sellingEntityContactPointUrl
                },
                representativeInfo: sellingEntityRepresentativeInfo
            },
            ownershipType: ownershipType,
            currentTenants: data?.relatedOrganizations?.currentTenants,
        },
        dutchStep: {
            dutchStepQuantity: dutchStepQuantity,
            dutchStepPercent: dutchStepPercent,
            dutchStepValue: {
                currency: checkProp(dutchStepValue,'currency') ? dutchStepValue.currency : null,
                amount: checkProp(dutchStepValue,'amount') ? +dutchStepValue.amount : null
            }
        },
    }

    function saveBanks(){
        let tmp = []
        for(let ii = 0; ii < bankAccounts.length; ii++){
            if(bankAccounts[ii].hasOwnProperty('status') !== true){
                tmp.push(bankAccounts[ii])
            }else{
                if(!bankAccounts[ii].status) tmp.push(bankAccounts[ii])
            }
        }
        return tmp
    }

    useEffect(() => {
        if(checkProp(data, 'relatedOrganizations')){
            if(checkProp(data.relatedOrganizations, 'sellingEntity')){
                // if(checkProp(data.relatedOrganizations.sellingEntity, 'name')) setSellingEntityName(isMongo(data.relatedOrganizations.sellingEntity.name))
                if(checkProp(data.relatedOrganizations.sellingEntity, 'identifier')){
                    if(checkProp(data.relatedOrganizations.sellingEntity.identifier, 'scheme')) setSellingEntityIdentifierScheme(data.relatedOrganizations.sellingEntity.identifier.scheme)
                    if(checkProp(data.relatedOrganizations.sellingEntity.identifier, 'legalName')) setSellingEntityIdentifierLegalName(isMongo(data.relatedOrganizations.sellingEntity.identifier.legalName))
                    if(checkProp(data.relatedOrganizations.sellingEntity.identifier, 'id')) setSellingEntityIdentifierId(data.relatedOrganizations.sellingEntity.identifier.id)
                }
                /*if(checkProp(data.relatedOrganizations.sellingEntity, 'address')){
                    if(checkProp(data.relatedOrganizations.sellingEntity.address, 'countryName')) setSellingEntityAddressCountryName(isMongo(data.relatedOrganizations.sellingEntity.address.countryName))
                    if(checkProp(data.relatedOrganizations.sellingEntity.address, 'region')) setSellingEntityAddressRegion(isMongo(data.relatedOrganizations.sellingEntity.address.region))
                    if(checkProp(data.relatedOrganizations.sellingEntity.address, 'locality')) setSellingEntityAddressLocality(isMongo(data.relatedOrganizations.sellingEntity.address.locality))
                    if(checkProp(data.relatedOrganizations.sellingEntity.address, 'streetAddress')) setSellingEntityAddressStreetAddress(isMongo(data.relatedOrganizations.sellingEntity.address.streetAddress))
                    if(checkProp(data.relatedOrganizations.sellingEntity.address, 'postalCode')) setSellingEntityAddressPostalCode(data.relatedOrganizations.sellingEntity.address.postalCode)
                }*/
                if(checkProp(data.relatedOrganizations.sellingEntity, 'contactPoint')){
                    if(checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'name')) setSellingEntityContactPointName(isMongo(data.relatedOrganizations.sellingEntity.contactPoint.name))
                    if(checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'email')) setSellingEntityContactPointEmail(data.relatedOrganizations.sellingEntity.contactPoint.email)
                    if(checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'telephone')) setSellingEntityContactPointTelephone(data.relatedOrganizations.sellingEntity.contactPoint.telephone)
                    if(checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'faxNumber')) setSellingEntityContactPointFaxNumber(data.relatedOrganizations.sellingEntity.contactPoint.faxNumber)
                    if(checkProp(data.relatedOrganizations.sellingEntity.contactPoint, 'url')) setSellingEntityContactPointUrl(data.relatedOrganizations.sellingEntity.contactPoint.url)
                }
                if(checkProp(data.relatedOrganizations.sellingEntity, 'representativeInfo')) setSellingEntityRepresentativeInfo(data.relatedOrganizations.sellingEntity.representativeInfo)
            }
        }
    }, [])

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            //disabled={true}
                            required
                        />
                    </div>
                    { discount 
                    ? 
                    <>
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                           Застосувати знижку
                        </Checkbox>
                        <div 
                        // className={styles.twoInLine}
                            style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                        >
                            <InputAmount //"Стартова вартість попереднього аукціону"
                                title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                data={{
                                    amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                    currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                    addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                }}
                                onChange={(e) => { setPreviousAuctionValue(e) }}
                                //disabled={disabledElement(auction, '01')}
                                required
                                tax
                            />
                            <Input //"Розмір знижки від попереднього аукціону, %"
                                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                value={discountPercent}
                                type='number'
                                onChange={(e)=>{ setDiscountPercent(e) }}
                                //disabled={disabledElement(auction, '01')}
                                required
                            />
                        </div>
                    </>
                        : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }

    function dutchBlock(){
        return(
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Розмір кроку аукціону</div>
                <Select //'Кількість кроків',
                    data={{
                        label: TranslationsUk['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepQuantity.legalName, 
                        target: dutchStepQuantity,
                    }}
                    //disabled={disabledElement(auction, '01')}
                    onChange={(e) => { setDutchStepQuantity(e) }}
                    options={ setDutchStepQuantityList() }
                />
                <Input
                    label={TranslationsUk['basicSell-dutch'].BasicSellDutchProcedure.dutchStep.dutchStepPercent.legalName} //"Кількість кроків"
                    value={dutchStepPercent}
                    type='number'
                    onChange={(e)=>{ setDutchStepPercent(e) }}
                    disabled={true}
                />
                <InputAmount //"Розмір кроку голландського раунду, грн"
                    title="Розмір кроку голландського раунду, грн"
                    lable="Розмір кроку голландського раунду, грн"
                    data={{
                        amount: checkProp(dutchStepValue,'amount') ? dutchStepValue.amount : null,
                        currency: checkProp(dutchStepValue,'currency') ? dutchStepValue.currency : null,
                        // addTax: checkProp(dutchStepValue,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                    }}
                    onChange={ e => setDutchStepValue(e) }
                    //disabled={true}
                    //tax
                />
            </div>
        )
    }
    return (
        <>
            <Input //"Номер лота" 
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                //disabled={disabledElement(auction, '01')}
                required
            />
            <Input //Назва аукціону"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                //disabled={disabledElement(auction, '01')}
            />
            <TextArea //"Опис аукціону"
                value={description}
                //disabled={disabledElement(auction, '01')}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />


            <Select //'Лот виставляється',
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.tenderAttempts.legalName, 
                    target: tenderAttempts,
                    
                    // required: true,
                }}
                //disabled={disabledElement(auction, '01')}
                disabled={true}
                onChange={(e) => { 
                        setTenderAttempts(e)
                    }
                }
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.accessDetails.legalName} 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                //disabled={disabledElement(auction, '01')}
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: "Період розрахунку стартової вартості", 
                    target: valuePeriod,}}
                onChange={(e) => { setValuePeriod(e) }}
                options={
                    [
                        {value: 'month', name: "Стартова вартість за місяць"},
                        {value: 'day', name: "Стартова вартість за день"},
                        {value: 'hour', name: "Стартова вартість за годину"}
                    ]
                }
            />
            <Checkbox 
                value={martialLawDisclaimer}
                onChange={setMartialLawDisclaimer}
            >
                Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)
            </Checkbox>
            <Checkbox 
                value={calcOnBookValue}
                onChange={setCalcOnBookValue}
            >
                Стартова орендна плата розрахована від балансової вартості
            </Checkbox>

            <LeaseDuration data={leaseDuration} setData={setLeaseDuration} disabled={true} />

            <LeaseRules 
                data={leaseRules} 
                updateData={setLeaseRules}
                disabled={true}
            />

            <SlimmerBlock
                title='Балансоутримувач'
                content={<div className={styles.costBlock}>
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={propertyOwnerIdentifierLegalName}
                            disabled={true}
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Ідентифікатори організації'
                                value={propertyOwnerIdentifierLegalName}
                                onChange={(e)=>{ setPropertyOwnerIdentifierLegalName(e) }}
                                disabled={true}
                            />
                            <Select 
                                data={{
                                    label: 'Тип ідентифікації юридичної особи',
                                    target: propertyOwnerIdentifierScheme,
                                }}
                                onChange={(e) => { setPropertyOwnerIdentifierScheme(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                                disabled={true}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={propertyOwnerIdentifierId}
                                onChange={(e)=>{ setPropertyOwnerIdentifierId(e) }}
                                disabled={true}
                            />
                        </div>
                        <LocationOrg 
                            address={addressPropertyOwner}
                            setAdress={setAdressPropertyOwner}
                            dataText='Місцезнаходження Балансоутримувача аукціону'
                            disabled={true}
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Контактна особа</div>
                            <Input //ПІБ
                                label='ПІБ'
                                value={propertyOwnerContactPointName}
                                onChange={(e)=>{ setPropertyOwnerContactPointName(e) }}
                                disabled={true}
                            />
                            <Input //Адреса електронної пошти
                                label='Адреса електронної пошти'
                                value={propertyOwnerContactPointEmail}
                                onChange={(e)=>{ setPropertyOwnerContactPointEmail(e) }}
                                disabled={true}
                            />
                            <Input //Номер телефону
                                label='Номер телефону'
                                value={propertyOwnerContactPointTelephone}
                                onChange={(e)=>{ setPropertyOwnerContactPointTelephone(e) }}
                                disabled={true}
                            />
                            <Input //Номер факсу
                                label='Номер факсу'
                                value={propertyOwnerContactPointFaxNumber}
                                onChange={(e)=>{ setPropertyOwnerContactPointFaxNumber(e) }}
                                disabled={true}
                            />
                            <Input //Веб адреса
                                label='Веб адреса'
                                value={propertyOwnerContactPointUrl}
                                onChange={(e)=>{ setPropertyOwnerContactPointUrl(e) }}
                                disabled={true}
                            />
                        </div>
                        <Input //ПІБ
                            label='Інформація щодо підтвердження повноважень'
                            value={propertyOwnerRepresentativeInfo}
                            onChange={(e)=>{ setPropertyOwnerRepresentativeInfo(e) }}
                            disabled={true}
                        />
                    </div>
                }
                slim={false}
            />
            <SlimmerBlock
                title='Орендодавець'
                content={
                    <div className={styles.costBlock}>
                        {/*<div className={styles.costBlockTitle}>Орендодавець</div>*/}
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={sellingEntityIdentifierLegalName}
                            // onChange={(e)=>{ setSellingEntityName(e) }}
                            disabled={true}
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Ідентифікатори організації'
                                value={sellingEntityIdentifierLegalName}
                                onChange={(e)=>{ setSellingEntityIdentifierLegalName(e) }}
                                disabled={true}
                            />
                            <Select 
                                data={{
                                    label: 'Тип ідентифікації юридичної особи',
                                    target: sellingEntityIdentifierScheme,
                                    
                                    // required: true,
                                }}
                                onChange={(e) => { setSellingEntityIdentifierScheme(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                                disabled={true}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={sellingEntityIdentifierId}
                                onChange={(e)=>{ setSellingEntityIdentifierId(e) }}
                                disabled={true}
                            />
                        </div>
                        <LocationOrg
                            address={addressSellingEntity}
                            setAdress={setAdressSellingEntity}
                            dataText='Місцезнаходження Орендодавеця аукціону'
                            disabled={true}
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Контактна особа</div>
                            <Input //ПІБ
                                label='ПІБ'
                                value={sellingEntityContactPointName}
                                onChange={(e)=>{ setSellingEntityContactPointName(e) }}
                                disabled={true}
                            />
                            <Input //Адреса електронної пошти
                                label='Адреса електронної пошти'
                                value={sellingEntityContactPointEmail}
                                onChange={(e)=>{ setSellingEntityContactPointEmail(e) }}
                                disabled={true}
                            />
                            <Input //Номер телефону
                                label='Номер телефону'
                                value={sellingEntityContactPointTelephone}
                                onChange={(e)=>{ setSellingEntityContactPointTelephone(e) }}
                                disabled={true}
                            />
                            <Input //Номер факсу
                                label='Номер факсу'
                                value={sellingEntityContactPointFaxNumber}
                                onChange={(e)=>{ setSellingEntityContactPointFaxNumber(e) }}
                                disabled={true}
                            />
                            <Input //Веб адреса
                                label='Веб адреса'
                                value={sellingEntityContactPointUrl}
                                onChange={(e)=>{ setSellingEntityContactPointUrl(e) }}
                                disabled={true}
                            />
                        </div>
                        <Input //ПІБ
                            label='Інформація щодо підтвердження повноважень'
                            value={sellingEntityRepresentativeInfo}
                            onChange={(e)=>{ setSellingEntityRepresentativeInfo(e) }}
                            disabled={true}
                        />
                    </div>
                }
                slim={false}
            />
            <div style={{marginTop:20, width: "100%"}}>
                <Select 
                    data={{
                        label: 'Тип власності',
                        target: ownershipType,
                    }}
                    onChange={(e) => { setOwnershipType(e) }}
                    options={[
                        {name: 'Обрати', value: null},
                        {name: 'Державна', value: 'state'},
                        {name: 'Комунальна', value: 'municipal'},
                        {name: 'Сумісна',   value: 'compatible'},
                    ]}
                    disabled={true}
                />
            </div>
            {discountBlock()}
            {dutchBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InstructionBlock
                    title='Важлива інформація'
                    content="Шановні користувачі! Починаючи з 01.01.2024р. мінімальна заробітна плата в Україні встановлена на рівні 7100 грн. Просимо враховувати це при встановленні реєстраційного та гарантійного внесків!"
                />
                <InputAmount //"Стартова ціна лота"
                    title="Стартова орендна плата *"
                    lable="Стартова орендна плата"
                    data={{
                        amount: checkProp(value,'amount') ? value.amount : null,
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : false
                    }}
                    onChange={(e) => {
                                setValue(e)
                                // if(Number(e.amount) <= 20000){
                                //     setRegistrationFee({registrationFee, amount: 17})
                                // }else if(Number(e.amount) <= 50000){
                                //     setRegistrationFee({registrationFee, amount: 119})
                                // }else if(Number(e.amount) <= 200000){
                                //     setRegistrationFee({registrationFee, amount: 340})
                                // }else if(Number(e.amount) <= 1000000){
                                //     setRegistrationFee({registrationFee, amount: 510})
                                // }else if(Number(e.amount) > 1000000){
                                //     setRegistrationFee({registrationFee, amount: 1700})
                                // }
                    }}
                    
                    tax
                />
                <InputAmount //"Гарантійний внесок"
                    title="Гарантійний внесок *"
                    lable="Гарантійний внесок"
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Розмір мінімального кроку підвищення стартової орендної плати під час аукціону *"
                    lable="Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    
                />
                <InputAmount //"Реєстраційний внесок"
                    title="Реєстраційний внесок *"
                    lable="Реєстраційний внесок"
                    data={registrationFee}
                    onChange={(e) => { setRegistrationFee(e) }}
                    
                />
                <div style={{height: '20px'}}></div>
                <Checkbox 
                    value={valueAddedTaxCharged}
                    onChange={setValueAddedTaxCharged}
                    
                >
                    На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>{`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}</div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    //disabled={disabledElement(auction, '10')}
                />
            </div>
            {/*<BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />*/}
            <BanksAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} auction={auction} bankAccount={bankAccount}/>

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp
                            let bodyObj = {
                                base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if(auction.id !== null && auction.id !== ''){
                                editAuc(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionLLD