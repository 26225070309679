//Elem 0 = ua, 1 = en

export const FAQ_TITLE_TOP = ['КОРИСНЕ', 'USEFUL'];
export const FAQ_TITLE_BOTTOM = ['Інформаційна підтримка користувачів', ''];

export const NEWS_TITLE_TOP = ['НОВИНИ', 'NEWS'];
export const NEWS_TITLE_BOTTOM = ['У фокусі новин: аналітика та статті', ''];

export const CONTACTS_TITLE_TOP = ['КОНТАКТИ', 'SITE CONTACTS'];

export const LOGIN_TITLE_TOP = ['ВХІД', 'LOG IN'];
export const LOGIN_TITLE_BOTTOM_SECOND = ['або', 'or'];
export const LOGIN_TITLE_BOTTOM_FIRST = ['реєстрація', 'sign up to join'];


export const LANG_TITLE_TOP = ['Українською', 'In Ukrainian'];
export const LANG_TITLE_BOTTOM = ['In English', 'In English'];
