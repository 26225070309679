import React from 'react';
import styles from './createAuction.module.css';

import {connect} from 'react-redux';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../redux/togetherApi.js';

import {clearDataLot} from './windowItems/basicSell/clearDataItem'

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    changeLoader,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeMyCabinetCurentPage,
} from '../../redux/actions.js';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {bankAccountGet, bankAccountShemeGet, openBankWindows} from '../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../redux/actions/auctionBid/auctionBid.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
    changeCreateItemGenerationType,
    changeCreateItemUnitCode,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


import {changePopUpBankAccount, setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../redux/actions/itemUnits/itemUnits.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../redux/actions/documents/documents.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../redux/actions/discount/discount.js';

import {Link} from 'react-router-dom';

const ReturnButton = ( props ) => {
    return (
        <div className={styles.returnWrap}>
        <Link
            to="/create_auctions"
            className={styles.buttons}
            variant="contained"
            color="primary"
            onClick={
                () => {
                    props.setDataAddress(null, 'POPUP_AUCTION_MONGA')
                    props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
                    props.setDataAddress(null, 'CREATE_AUCTIONS_ZU_ATTEMP')
                    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
                    //props.changeAuctionsSwitchWindow(0)
                    props.changeCreateAuctionTab(0);
                    props.changeCreateAuctionLotIdentifier('')
                    props.changeCreateAuctionTitle('')
                    props.changeCreateAuctionDescription('')
                    props.changeCreateAuctionTenderAttempts('')
                    props.changeCreateAuctionAccessDetails('')
                    props.changeCreateAuctionXDocumentRequirements('')
                    props.changeCreateAuctionXAdditionalInformation('')
                    props.changeCreateAuctionType('null')
                    props.changeCreateAuctionValueCurrency('null')
                    props.changeCreateAuctionValueAmount('')
                    props.changeCreateAuctionGuaranteeCurrency('null')
                    props.changeCreateAuctionGuaranteeAmount('')
                    props.changeCreateAuctionMinimalStepCurrency('null')
                    props.changeCreateAuctionMinimalStepAmount('')
                    props.changeCreateAuctionDateStart('')
                    props.setPopBankAccountId('null')
                    props.setPopBankAccountIdArr(null)
                    props.getMyAuction(null)

                    props.changeDocumentId(null)
                    props.changeDocumentTitle('')
                    props.changeDocumentDescription('')
                    props.changeDocumentType('null')
                    props.changeDocumentLang('null')

                    props.changeCreateItemClassificationId('null')
                    props.changeCreateItemGenerationType('null')
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    props.changeCreateItemUnitValueCurrency('null')
                    props.changeCreateItemUnitValueAmount('')
                    props.changeCreateItemQuantity('')

                    props.changeDiscountPreviousAuctionCurrency('null')
                    props.changeDiscountPreviousAuctionAmount('')
                    props.changeDiscountPercent('')
                    props.changeDiscountPreviousAuctionId('')
                    props.setBankAccountSave([])
                    props.setBankAccountAdd([])
                    props.setBankAccountDelete([])
                    //props.setPopUpAuctionMyNull()
                    props.discountAuc(false)


                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')
                    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
                    props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
                    props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
                    //props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')

                    clearDataLot(props)

                    let data = {}
                    data.domen = process.env.REACT_APP_END_POINT_BD
                    data.version = 'v1'
                    data.local = props.langFlag
                    if(props.myCabinetPage === 0){
                        data.params = `?offset=${props.myCabinetPage}`
                    }else{
                        data.params = `?offset=${props.myCabinetPage}0` 
                    }

                    //props.auctionsGetMy(data, header)
                    loadData(props)
                }
            }
        >
            Повернутися до списку аукціонів
        </Link>
        </div>
    )
}

async function loadData(props){
    props.getMyAuction(null)
    props.setPopUpAuctionMyNull()
    props.changeAuctionsSwitchWindow(0)


    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let header5 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let data1 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: 'timber-english?limit=100'
    }
    props.unitsGetByType(data1, header)
    props.unitsGetByTypeEnergy(data1, header)
    props.unitsGetByTypeRailway(data1, header)
    props.unitsGetByTypeSubsoil(data1, header)
    props.unitsGetByTypeZU(data1, header)



    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=100'
    }
    props.auctionsGetClassificationsByType(data5, header5)
    // props.auctionsGetClassificationsByTimberSortment(data5, header5)
    // props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    // props.auctionsGetClassificationsByTimberClass(data5, header5)
    // props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    // props.auctionsGetClassificationsByTimberLength(data5, header5)
    // props.auctionsGetClassificationsByTimberStorage(data5, header5)
    // props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    // props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
    props.auctionsGetClassificationsByWagonSpecies(data5, header5)
    props.auctionsGetClassificationsByTypeNadraEnglish(data5, header5)
    props.auctionsGetClassificationsByByShemaSubsoilUsageType(data5, header5)
    props.auctionsGetClassificationsByCarryType(data5, header5)
    props.auctionsGetClassificationsByTypeRailwayCargoAdd(data5, header5)
    props.auctionsGetClassificationsByTypeRailway(data5, header5)
    props.auctionsGetWagonRestrict(data5, header5)
    props.auctionsGetWagonRestrictType(data5, header5)
    //concatDataClassification(props)
    getAdditionalClassificationZU(props)
    getData(props)
}

//////////////////////////////////////////////////

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

async function getData(props){
    
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?offset=0`
    props.changeMyCabinetCurentPage(0)
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }

    props.auctionsBidsGetMy(data, header)
    props.bankAccountGet(data, header)
    getAucionsList(props)
    await props.workMyPageCurrentAuction(0)
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length === 10) {
            await props.workMyPageNextAuction(1)
        }else{
            await props.workMyPageNextAuction(null)
        }
    }
}

async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }
        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        myCabinetPage: state.start.myCabinetPage,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,

    changeCreateItemGenerationType,
    changeCreateItemUnitCode,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,

    changeLoader,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    discountAuc,
    setDataAddress,

    ///////////////////////
    unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeMyCabinetCurentPage,
    bankAccountGet, bankAccountShemeGet, openBankWindows,
    auctionsBidsGetMy,
    workMyPageCurrentAuction,
    workMyPageNextAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnButton)