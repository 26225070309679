import React from 'react';

import {connect} from 'react-redux';

import TogetherAPIFilter from './togetherAPIFilter.js';
import TogetherAPIButtons from './togetherAPIButtons.js';
import TogetherAPIAuctions from './togetherAPIAuctions.js';
import TogetherAPIBids from './togetherAPIBids.js';
import TogetherAPIBidsDraft from './togetherAPIBidsDrawt.js';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';

const TogetherAPI = (props) => {
    let tmp = null
    if(props.userProfiles.canCreateBid === true) tmp = <TogetherAPIButtons />
    if(props.togetherApiPage.pageSwitch === 0){
        return <div>
            <TogetherAPIFilter />
            {tmp}
            <TogetherAPIAuctions />
        </div>
    }else if(props.togetherApiPage.pageSwitch === 1){
        return <div>
            <TogetherAPIFilter />
            {tmp}
            <TogetherAPIBids />
        </div>
    }else if(props.togetherApiPage.pageSwitch === 2){
        return <div>
            <TogetherAPIFilter />
            {tmp}
            <TogetherAPIBidsDraft />
        </div>
    }
    return null
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApiPage: state.start.togetherApiPage,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,    
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)
