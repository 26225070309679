import React from 'react';
import style from './posts.module.css';

import {connect} from 'react-redux';

const Posts = (props) => {
    let tmp = null;
    if(props.token !== null){
        tmp = (
            <>
                <div className={style.posts_wrap_title}>
                    <div className={style.postsContent}>
                        <div className={style.posts_title}>Повідомлення</div>
                        <div className={style.posts_text}>Тут відображаються повідомлення, які стосуються змін у Ваших закупівлях</div>
                    </div>
                </div>

                <div className={style.posts_wrap}>
                    <div className={style.postsContent}>
                        <div className={style.pasts_head}>
                            <div>Тема</div>
                            <div>Дата отримання</div>
                        </div>
                        <div className={style.pasts_body}>
                            Наразі у вас немає жодних повідомлень
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}
  
const mapDispatchToProps = {
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Posts)