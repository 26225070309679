import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount, addAuctionPeriodProperty, addBankProperty, addDutchStep} from './utilsCreateBody'

export function createBodyBSD(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        if(data.base.accessDetails !== null){
            body = addPropertyMonga(data, body, 'accessDetails')
        }else{
            body.accessDetails = null
        }
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotId')
        if(data.base.isPerishable !== true) body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        body = addProperty(data, body, 'sellingMethod')
        if(data.base.x_documentRequirements !== null){
            body = addPropertyMonga(data, body, 'x_documentRequirements')
        }else{
            body.x_documentRequirements = null
        }
        if(data.base.x_additionalInformation !== null){
            body = addPropertyMonga(data, body, 'x_additionalInformation')
        }else{
            body.x_additionalInformation = null
        }
        //body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addProperty(data, body, 'valueAddedTaxCharged')
        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'previousAuctionId')
        body = addPropertyDiscount(data, body, 'discount')
        body = addBankProperty(data, body, 'banks')
        body = addDutchStep(data, body, 'dutchStep')
        body = addProperty(data, body, 'isPerishable')
        return body
    }
    return null
}