import {
    TOGETHER_API_PAGE_AUCTION_END_PAY,

    TOGETHER_API_PAGE_SWITCH,
    TOGETHER_API_BIDS_ARR,
    TOGETHER_API_BIDS_WORK_ARR,
    TOGETHER_API_POPUP_ONOFF,

    TOGETHER_API_DOC_TITLE,
    TOGETHER_API_DOC_DESCRIPTION,
    TOGETHER_API_DOC_TYPE,
    TOGETHER_API_DOC_LANG,
    TOGETHER_API_DOC_FILENAME,
    TOGETHER_API_DOC_FILENAME_ECP,

    TOGETHER_API_ECP,
    TOGETHER_API_AUCTION_NULL,
    
    PROXY_TIMBER_SPECIES,
    PROXY_TIMBER_SORTMENT,
    PROXY_TIMBER_STORAGE,
    PROXY_TIMBER_DIAMETER,
    PROXY_TIMBER_CLASS,

    TOGETHER_API_DRAFT_BID_SUMM,
    TOGETHER_API_PULL_AUC_EI,
    TOGETHER_API_BASE_PULL_AUC_EI,
    TOGETHER_API_SAVE_DATA_LOAD,
    TOGETHER_API_SAVE_VALUE,

    TOGETHER_API_TIMER_ROUND,
    TOGETHER_API_TIMER_TIME,
    TOGETHER_API_TIMER_TIMELEFT,

    TOGETHER_API_PAGE_CURRENT_AUCTION,
    TOGETHER_API_PAGE_NEXT_AUCTION,

    WORK_MY_PAGE_CURRENT_AUCTION,
    WORK_MY__API_PAGE_NEXT_AUCTION,
} from "./types";

export function togetherApiPageSwitch(value) {
    return {
        type: TOGETHER_API_PAGE_SWITCH,
        payload: value
    }
}

export function togetherApiBidsArr(value) {
    return {
        type: TOGETHER_API_BIDS_ARR,
        payload: value
    }
}

export function togetherApiBidsWorkArr(value) {
    return {
        type: TOGETHER_API_BIDS_WORK_ARR,
        payload: value
    }
}


export function togetherApiPopUpOnOff(value) {
    return {
        type: TOGETHER_API_POPUP_ONOFF,
        payload: value
    }
}

export function togetherApiDocTitle(value) {
    return {
        type: TOGETHER_API_DOC_TITLE,
        payload: value
    }
}

export function togetherApiDocDescription(value) {
    return {
        type: TOGETHER_API_DOC_DESCRIPTION,
        payload: value
    }
}

export function togetherApiDocType(value) {
    return {
        type: TOGETHER_API_DOC_TYPE,
        payload: value
    }
}

export function togetherApiDocLang(value) {
    return {
        type: TOGETHER_API_DOC_LANG,
        payload: value
    }
}

export function togetherApiDocFileName(value) {
    return {
        type: TOGETHER_API_DOC_FILENAME,
        payload: value
    }
}

export function togetherApiDocFileNameECP(value) {
    return {
        type: TOGETHER_API_DOC_FILENAME_ECP,
        payload: value
    }
}

export function togetherApiECP(value) {
    return {
        type: TOGETHER_API_ECP,
        payload: value
    }
}

export function togetherApiAuctionClear() {
    return {
        type: TOGETHER_API_AUCTION_NULL,
        payload: null
    }
}


export function setGetTimberSpecies(value) {
    return {
        type: PROXY_TIMBER_SPECIES,
        payload: value
    }
}

export function setGetTimberSortment(value) {
    return {
        type: PROXY_TIMBER_SORTMENT,
        payload: value
    }
}

export function setGetTimberStorage(value) {
    return {
        type: PROXY_TIMBER_STORAGE,
        payload: value
    }
}

export function setGetTimberDiameter(value) {
    return {
        type: PROXY_TIMBER_DIAMETER,
        payload: value
    }
}

export function setGetTimberClass(value) {
    return {
        type: PROXY_TIMBER_CLASS,
        payload: value
    }
}

export function setDraftBidSumm(value) {
    return {
        type: TOGETHER_API_DRAFT_BID_SUMM,
        payload: value
    }
}

export function togetherApiActiveAuctions(value) {
    return {
        type: TOGETHER_API_AUCTION_NULL,
        payload: value
    }
}

export function togetherApiPullAucEI(value) {
    return {
        type: TOGETHER_API_PULL_AUC_EI,
        payload: value
    }
}

export function togetherApiBasePullAucEI(value) {
    return {
        type: TOGETHER_API_BASE_PULL_AUC_EI,
        payload: value
    }
}

export function togetherApiSaveDataLoad(value) {
    return {
        type: TOGETHER_API_SAVE_DATA_LOAD,
        payload: value
    }
}

export function togetherApiSaveValue(value) {
    return {
        type: TOGETHER_API_SAVE_VALUE,
        payload: value
    }
}

export function togetherApiTimerRound(value) {
    return {
        type: TOGETHER_API_TIMER_ROUND,
        payload: value
    }
}

export function togetherApiTimerTime(value) {
    return {
        type: TOGETHER_API_TIMER_TIME,
        payload: value
    }
}

export function togetherApiTimerTimeLeft(value) {
    return {
        type: TOGETHER_API_TIMER_TIMELEFT,
        payload: value
    }
}

export function togetherApiPageCurrentAuction(value) {
    return {
        type: TOGETHER_API_PAGE_CURRENT_AUCTION,
        payload: value
    }
}

export function togetherApiPageNextAuction(value) {
    return {
        type: TOGETHER_API_PAGE_NEXT_AUCTION,
        payload: value
    }
}

export function workMyPageCurrentAuction(value) {
    return {
        type: WORK_MY_PAGE_CURRENT_AUCTION,
        payload: value
    }
}

export function workMyPageNextAuction(value) {
    return {
        type: WORK_MY__API_PAGE_NEXT_AUCTION,
        payload: value
    }
}

export function auctionBidEndPay(value) {
    return {
        type: TOGETHER_API_PAGE_AUCTION_END_PAY,
        payload: value
    }
}
