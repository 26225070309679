//TODO: Почистить файл от лишних подключенных функций, подключить свой цсс и почистить его
import React from 'react';
import styles from './badges.module.css';

const Bage = (props) => {

        return (
            <div
                className={styles.badgeElem}
                title={props.description}
            >
                <div className={styles.badgeElem_title}>
                    {props.description}
                </div>
            </div>
        )

}

export default Bage