// noinspection ES6UnusedImports

import React from 'react';
import styles from './activeAuctionLinkNew.module.css';

import {connect} from 'react-redux';
import {getCurrency} from "../../../redux/check";

const ActiveAuctionLink = (props) => {

    let bidData = props.bidData ?? null

    if (props.bidData?.hasOwnProperty('id')) {
        //Single Bid on input, wrap it
        bidData = [bidData]
    }

    let linkToView = null,
        linksAsBidder = null

    if (bidData?.length > 0) {
        linksAsBidder = bidData.map((bid) => {
            if (bid?.status === "active" && bid.link) {
                // setLinkBlockVisible(true)
                return (
                    <a className={styles.colorLink} href={bid.link} target="_blank" rel="noopener noreferrer" key={bid.id}>
                        <div className={styles.buttonLink + ' ' + styles.buttonLinkBottom}>Вхід в аукціон в якості
                            учасника {bid?.value?.amount ? ' (ставка '+bid?.value?.amount + ' ' + getCurrency(bid?.value?.currency) + ')' :""}
                        </div>
                    </a>
                )
            }
            return null
        })
    }

    if (props.auction !== null) {
        if (props.auction.auctionUrl !== null) {
            // setLinkBlockVisible(true)
            linkToView = <a className={styles.colorLink} href={props.auction.auctionUrl} target="_blank"
                            rel="noopener noreferrer">
                <div className={styles.buttonLink}>Вхід в аукціон в якості спостерігача</div>
            </a>
        }

        // if (!isLinkBlockVisible) return null
        return (
            <div className={styles.infoBlock}>
                <h3>Посилання на аукціон</h3>
                <div className={styles.documentElem}>
                    {linkToView}
                    {linksAsBidder}
                </div>
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        history: state.start.history,
        curentBidLot: state.start.curentBidLot,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

export default connect(mapStateToProps, null)(ActiveAuctionLink)
