import React from 'react';

import TextHalf from '../../../elements/viewData/textHalf'

const AwardElementTitle = ( props ) => {
    if(props.obj.value.title !== null && props.obj.value.title !== ''){
        return (
            <>
                <TextHalf
                    title="Назва рішення про продаж майна"
                    data={props.obj.value.title.uk_UA}
                />
                <TextHalf
                    title="Опис рішення про продаж майна"
                    data={props.obj.value.description.uk_UA}
                />
            </>
        )
    }
    return null
}

export default AwardElementTitle
