import React from 'react';
import style from './contacts.module.css';

export default function Contacts(){
    return (
        <section>
            <header className={style.header}><h1>КОНТАКТИ</h1></header>
            <article className={style.contacts}>
                <div>
                    <h3>м.Київ</h3>
                    <p>вул. Воздвиженська 56, 4-й поверх, 04071</p>
                    <p>Телефон: +380 (44) 490-11-91</p>
                    <p>Viber +380&nbsp;(67) 599-11-91</p>
                    {/*<p>Viber +380&nbsp;(95) 747-08-88</p>*/}
                    <p>Електронна пошта: <a href="mailto:info@dto.com.ua">info@dto.com.ua</a></p>
                </div>
                <div className={style.contacts_iframe}>
                    <iframe title='Розміщення' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.0265931903723!2d30.51204211573172!3d50.45922947947662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce4254001525%3A0xe732e8db81201852!2z0LLRg9C70LjRhtGPINCS0L7Qt9C00LLQuNC20LXQvdGB0YzQutCwLCA1Niwg0JrQuNGX0LI!5e0!3m2!1suk!2sua!4v1482841689220" width="100%" height="300"></iframe>
                </div>
            </article>
        </section>
    )
}