import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

const ProcedurePriorityEnglish = (props) => {
    return (viewButtonsPP(props.auctionId))
}

function viewButtonsPP(data){
    if(data !== null){
        if(data.createdByCurrentUser === true){
            switch(data.sellingMethod){
                case "legitimatePropertyLease-priorityEnglish":
                case "legitimatePropertyLease-priorityEnglish-fast":
                case "legitimatePropertyLease-priorityEnglish-fast-manual":
                case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-auction":
                case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    if(
                        data.status === 'cancelled' ||
                        data.status === 'unsuccessful'
                    ){
                        let tmp = ''
                        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                            tmp = `https://registry.dto.com.ua/object/${data.registryObjectId}`
                        }else{
                            //tmp = `https://staging-registry.dto.com.ua/object/${data.registryObjectId}`
                            tmp = `https://sandbox-registry.dto.com.ua/object/${data.registryObjectId}`
                            //tmp = `https://dev-registry.dto.com.ua/object/${data.registryObjectId}`
                        }
                        return (
                            <div className={styles.lotStartPriceBlock + ' ' + styles.lineButtonLink}>
                                <p>Для публікації аукціону з оренди після втрати переважного права, необхідно змінити або зазначити тип об'єкта в переліку “Перший” тип та перейти до публікації аукціону</p>
                                <a href={tmp} taget='blank'>
                                    <div className={styles.buttonLink}>
                                        Створити аукціон (перший англійський)
                                    </div>
                                </a>
                            </div>
                        )
                    }
                    return null
                default:
                    return null
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedurePriorityEnglish)