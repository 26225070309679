import React, { useState, useEffect } from 'react';
import styles from './leaseDur.module.css'

import Input from '../../../elements/inputs/input'
import moment from 'moment';

const LeaseDuration =( {data, setData} ) => {

    const dur = moment.duration(data)
    const [years, setYears] = useState(dur._data.years || null);
    const [months, setMonths] = useState(dur._data.months || null);
    const [days, setDays] = useState(dur._data.days || null);

    useEffect(() => {
        let tmp = "P"
        if (+years >= 0 || +months >= 0 || +days >= 0) {
            if(years !== null && years !== '') tmp += `${years + 'Y'}`
            if(months !== null && months !== '') tmp += `${months + 'M'}`
            if(days !== null && days !== '') tmp += `${days + 'D'}`
            if(tmp === "P") tmp = null
            //tmp = `P${years !== null && years !== '' ? years + 'Y' : ''}${months !== null && months !== '' ? months + 'M' : ''}${days !== null && days !== '' ? days + 'D' : ''}`
        }
        setData( tmp )
    }, [years, months, days]);

    return (
        <div className={styles.blockWrap}>
            <div className={styles.title}>Строк оренди *</div>
            <div className={styles.fullWidth}>
                <div className={styles.thirdWidth}>
                    <Input
                        value={years}
                        type='number'
                        label="Років"
                        min={0}
                        max={100}
                        onChange={setYears}
                        disabled={false}
                    />
                </div>
                <div className={styles.thirdWidth}>
                    <Input
                        value={months}
                        type='number'
                        label="Місяців"
                        min={0}
                        max={12}
                        onChange={setMonths}
                        disabled={false}
                    />
                </div>
                <div className={styles.thirdWidth}>
                    <Input
                        value={days}
                        type='number'
                        label="Днів"
                        min={0}
                        max={365}
                        onChange={setDays}
                        disabled={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default LeaseDuration