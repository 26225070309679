import React from 'react';
import styles from './input.module.css';
import InputInfo from './inputInfoBlock';

const InputText = ({type, value, placeholder, label, onChange, error, descr, ...props}) => {

    function setStyle(){
        if (error) return styles.alert
        if(props.disabled) return styles.inPutHide
        return styles.inPut
    }

    return (
        <div className={styles.inPutWrap}>
            <div className={styles.inPutInfo}>
                <input type={type || 'text'}
                    disabled={props.disabled ? `disabled` : null}
                    className={setStyle()}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (type === 'number' && e.target.value.indexOf(',') > 0) onChange(e.target.value.replace(',', '.'))
                        else onChange(e.target.value)
                    }}
                    maxLength={props.maxlength}
                    ref={props.ref}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                />
                { label ? 
                    <div className={styles.label}>
                        {props.required ? label + ' *': label}
                    </div> : null
                }
                 {!!props.info ? <InputInfo data={props.info} /> : null}
            </div>
            {!!descr ? <div className={styles.descript}>{descr}</div> : null}
            {!!error ? <div className={styles.error}>{error === true ? null : error}</div> : null}
        </div>
    )
}

export default InputText