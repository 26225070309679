import React from 'react';
import style from './togetherAPI.module.css';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../redux/actions/auctions/auctions.js';
import {setUniversalError} from '../../../redux/actions.js';
import {setDataAddress} from '../../../redux/createAddress';
import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';
import {togetherApiBidsWorkArr} from '../../../redux/togetherApi.js';

const TogetherAPI = (props) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Увага! Ваша пропозиція перевищує початкову ціну більше ніж у 10 разів.</div>
                <div>Ви впевненні що хочете продовжити?</div>
                <div className={style.button_wrap}>
                    <div
                        className={style.popup_message_button}
                        onClick={
                            ()=>{
                                if(props.togetherApiHelpPopUp.datePublished === null){
                                    sendDraft(props, props.togetherApiHelpPopUp.aucId, props.togetherApiHelpPopUp.lotId)
                                }else{
                                    sendBid(props, props.togetherApiHelpPopUp.aucId, props.togetherApiHelpPopUp.lotId)
                                }
                            }
                        }
                    >Так</div>
                    <div
                        className={style.popup_message_button}
                        onClick={
                            ()=>{
                                props.setDataAddress(null, 'SET_TOGETHER_API_HELP_POPUP')
                            }
                        }
                    >Ні</div>
                </div>
            </div>
        </div>
    )
}

async function sendDraft(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/send_draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
        }else{
            await sendBid(props, tmpId, bidId)
        }
    }
}

async function sendBid(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/publish`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=25&status=active_tendering'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.togetherApiBidsWorkArr(null)
        props.setDataAddress(null, 'SET_MY_BIDS')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_ARR')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_WORK_ARR')
        await props.auctionsBidsGetMy(data, header)
        props.setDataAddress(null, 'SET_TOGETHER_API_HELP_POPUP')
    }
}



const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        togetherAPIelem: state.start.togetherAPIelem,
        togetherApiHelpPopUp: state.start.togetherApiHelpPopUp,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,
    setUniversalError,
    setDataAddress,
    auctionsBidsGetMy,
    togetherApiBidsWorkArr,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)