import React, {useState, useEffect} from 'react';
import styles from './cse.module.css';

import {connect, useDispatch} from 'react-redux';
import {getMyAuction} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import { changeLoader } from '../../../../redux/actions.js';

import Select from '../../../elements/inputs/inputSelect';
import TextFull from '../../../elements/viewData/textFull.js';

import ItemsList from './itemsList/itemsList.js';

import ReturnButton from '../../returnButton'
import Sidebar from '../../auctionEditNew/components/sidebar/sidebar'

import LandItem from './landtem/landItem'
import { unitsGetByTypeZU } from '../../../../redux/actions/itemUnits/itemUnits';

import { checkProp } from '../../../../redux/check';
import {getDefaultHeaders} from "../../../_api/bidApi";


const CommSell = ( props ) => {
    const [edit, setEdit] = useState(false)
    const [editData, setData] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        if(props.auctionsMy.items && props.auctionsMy.items.length === 0) setEdit(true)
        if(props.classificationsZU === null){
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                header: getDefaultHeaders(props)
            }
            dispatch(unitsGetByTypeZU(data, data.header))
        }
    }, [])

    useEffect(() => {
        props.setDataAddress('land', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
        props.setDataAddress("06", 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
        getAdditionalClassificationCVZU(props)
        getAdditionalClassificationZU(props)
    }, []);


    useEffect( () => {
        if(props.auctionsMy.items !== null && props.auctionsMy.items.length > 0){
            if(props.auctionsMy.items[0].additionalClassifications !== null && props.auctionsMy.items[0].additionalClassifications.length > 0){
                for(let i = 0; i < props.auctionsMy.items[0].additionalClassifications.length; i++){
                    if(props.auctionsMy.items[0].additionalClassifications[i].scheme === "kvtspz"){
                        props.setDataAddress(props.auctionsMy.items[0].additionalClassifications[i].id, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                    }
                }
            }
        }
    }, []);

    let tmpText = 'Додавання лота';
    if(props.auctionsMy !== null && props.auctionsMy.items !== null){
        if(!edit && props.auctionsMy.items.length === 0) tmpText = 'Додайте лот'
        if(!edit && props.auctionsMy.items.length > 0) tmpText = 'Список лотів'
        if(props.auctionsMy.items.length > 0 && editData !== null && edit ) tmpText = 'Редагування лота'
    }else{
        tmpText = 'Додайте лот'
    }
    if(edit && checkProp(editData, 'id')) tmpText = 'Додавання лота'

    function whatToRender(){
        if (!edit) {
            return  <div className={styles.wrapContent}>
                        <div className={styles.headers}></div>
                        <ItemsList edit={setEdit} setEdit={setData} />
                    </div>
        }
        if (edit || editData !== {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItemsLLE(props)}
                    {viewSelectTypeItems(props)}
                    {viewMainClassifMain(props)}
                    {viewMainClassif(props)}
                    {viewTypeItems(props, setEdit, editData)}
                </div>
        }
        if (edit || editData === {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItemsLLE(props)}
                    {viewSelectTypeItems(props)}
                    {viewMainClassifMain(props)}
                    {viewMainClassif(props)}
                    {viewTypeItems(props, setEdit)}
                </div>
        }
    }
    
    return (
        <div className={styles.mainWrap}>
            <Sidebar data={props.auctionsMy} />
            <div className={styles.wrapBlock}>
                <div className={styles.wrapBlockTitle}>
                    {tmpText}
                </div>
                {whatToRender()}
                <div className={styles.buttonBack}>
                    <ReturnButton />
                </div>
            </div>
        </div>
    )
}

function viewSelectTypeItemsLLE(props){
    return (
        <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
            <div>
                <Select
                    data={{
                        label: "Тип об'єкта майна",
                        target: "land",
                        info: "оберіть тип об'єкта майна"
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                        props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                        props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                    }}
                    options={[
                        {value: "land", name: "Земельна ділянка"},
                        {value: "otherProperty", name: "Інше майно"},
                        {value: "jointPropertyComplex", name: "Об'єкт ЄМК"},
                        {value: "vehicle", name: "Транспортний засіб"},
                        {value: "realEstate", name: "Нерухоме майно"}
                    ]}
                    disabled={true}
                />
            </div>
        </div>
    )
}


function viewSelectTypeItems(props){
    return (
        <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
            <div>
                <Select
                    data={{
                        label: "Основний класифікатор",
                        target: props.createItems.zu.baseClasif,
                        info: 'оберіть основний класифікатор лоту'
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                        props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                        smartClasif(props, event)
                    }}
                    options={mainCAV}
                    disabled={true}
                />
            </div>
        </div>
    )
}

const mainCAV = [
            { key:'03000000-1', value:'03', name: 'CAV 03 Сільськогосподарська, фермерська продукція, продукція рибальства, лісівництва та супутня продукція (та усі вкладені коди)'},
            { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно (та усі вкладені коди)'},
            { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)'},
            { key:'06000000-2', value:'06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
            { key:'07000000-9', value:'07', name: 'CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)'},
            { key:'08000000-6', value:'08', name: 'CAV 08 Корпоративні права (та усі вкладені коди)'},
            { key:'09000000-0', value:'09', name: 'CAV 09 Паливо (та усі вкладені коди)'},
            { key:'14000000-1', value:'14', name: 'CAV 14 Гірнича продукція, неблагородні метали та супутня продукція (та усі вкладені коди)'},
            { key:'15000000-9', value:'15', name: 'CAV 15 Продукція тваринництва, м’ясо та м’ясопродукти (та усі вкладені коди)'},
            { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка (та усі вкладені коди)'},
            { key:'18000000-9', value:'18', name: 'CAV 18 Одяг, взуття, сумки та аксесуари (та усі вкладені коди)'},
            { key:'19000000-6', value:'19', name: 'CAV 19 Шкіряні та текстильні, пластмасові та гумові матеріали (та усі вкладені коди)'},
            { key:'22000000-0', value:'22', name: 'CAV 22 Друкована та супутня продукція (та усі вкладені коди)'},
            { key:'24000000-4', value:'24', name: 'CAV 24 Хімічна продукція (та усі вкладені коди)'},
            { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)'},
            { key:'31000000-6', value:'31', name: 'CAV 31 Електротехнічне устаткування, апаратура, обладнання та матеріали; освітлювальне устаткування (та усі вкладені коди)'},
            { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)'},
            { key:'33000000-0', value:'33', name: 'CAV 33 Медичне обладнання, фармацевтична продукція та засоби особистої гігієни (та усі вкладені коди)'},
            { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)'},
            { key:'35000000-4', value:'35', name: 'CAV 35 Охоронне, протипожежне, поліцейське та оборонне обладнання (та усі вкладені коди)'},
            { key:'37000000-8', value:'37', name: 'CAV 37 Музичні інструменти, спортивні товари, ігри, іграшки, ремісничі, художні матеріали та приладдя (та усі вкладені коди)'},
            { key:'38000000-5', value:'38', name: 'CAV 38 Лабораторне, оптичне та високоточне обладнання (крім лінз) (та усі вкладені коди)'},
            { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)'},
            { key:'41000000-9', value:'41', name: 'CAV 41 Зібрана дощова та очищена вода (та усі вкладені коди)'},
            { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка (та усі вкладені коди)'},
            { key:'43000000-3', value:'43', name: 'CAV 43 Гірничодобувне та будівельне обладнання (та усі вкладені коди)'},
            { key:'44000000-0', value:'44', name: 'CAV 44 Конструкції та конструкційні матеріали; допоміжна будівельна продукція (крім електроапаратури) (та усі вкладені коди)'},
            { key:'45000000-7', value:'45', name: 'CAV 45 Будівельні роботи (та усі вкладені коди)'},
            { key:'48000000-8', value:'48', name: 'CAV 48 Пакети програмного забезпечення та інформаційні системи (та усі вкладені коди)'},
            { key:'50000000-5', value:'50', name: 'CAV 50 Послуги з ремонту і технічного обслуговування (та усі вкладені коди)'},
            { key:'51000000-9', value:'51', name: 'CAV 51 Послуги зі встановлення (крім програмного забезпечення) (та усі вкладені коди)'},
            { key:'55000000-0', value:'55', name: 'CAV 55 Готельні, ресторанні послуги та послуги з роздрібної торгівлі (та усі вкладені коди)'},
            { key:'60000000-8', value:'60', name: 'CAV 60 Транспортні послуги (крім транспортування відходів) (та усі вкладені коди)'},
            { key:'63000000-9', value:'63', name: 'CAV 63 Додаткові та допоміжні транспортні послуги; послуги туристичних агентств (та усі вкладені коди)'},
            { key:'64000000-6', value:'64', name: 'CAV 64 Поштові та телекомунікаційні послуги (та усі вкладені коди)'},
            { key:'65000000-3', value:'65', name: 'CAV 65 Комунальні послуги (та усі вкладені коди)'},
            { key:'66000000-0', value:'66', name: 'CAV 66 Фінансові та страхові послуги (та усі вкладені коди)'},
            { key:'70000000-1', value:'70', name: 'CAV 70 Послуги у сфері нерухомості (та усі вкладені коди)'},
            { key:'71000000-8', value:'71', name: 'CAV 71 Архітектурні, будівельні, інженерні та інспекційні послуги (та усі вкладені коди)'},
            { key:'72000000-5', value:'72', name: 'CAV 72 Послуги у сфері інформаційних технологій: консультування, розробка програмного забезпечення, послуги мережі Інтернет і послуги з підтримки (та усі вкладені коди)'},
            { key:'73000000-2', value:'73', name: 'CAV 73 Послуги у сфері НДДКР та пов’язані консультаційні послуги (та усі вкладені коди)'},
            { key:'75000000-6', value:'75', name: 'CAV 75 Адміністративні, оборонні послуги та послуги у сфері соціального захисту (та усі вкладені коди)'},
            { key:'76000000-3', value:'76', name: 'CAV 76 Послуги, пов’язані з нафтогазовою промисловістю (та усі вкладені коди)'},
            { key:'77000000-0', value:'77', name: 'CAV 77 Послуги у сфері сільського господарства, лісівництва, рослинництва, водного господарства та бджільництва (та усі вкладені коди)'},
            { key:'79000000-4', value:'79', name: 'CAV 79 Ділові послуги: юридичні, маркетингові, консультаційні, кадрові, поліграфічні та охоронні (та усі вкладені коди)'},
            { key:'80000000-4', value:'80', name: 'CAV 80 Послуги у сфері освіти та навчання (та усі вкладені коди)'},
            { key:'85000000-9', value:'85', name: 'CAV 85 Послуги у сфері охорони здоров’я та соціальної допомоги (та усі вкладені коди)'},
            { key:'90000000-7', value:'90', name: 'CAV 90 Послуги у сферах поводження зі стічними водами та сміттям, послуги у сферах санітарії та охорони довкілля (та усі вкладені коди)'},
            { key:'92000000-1', value:'92', name: 'CAV 92 Послуги у сфері відпочинку, культури та спорту (та усі вкладені коди)'},
            { key:'98000000-3', value:'98', name: 'CAV 98 Інші громадські, соціальні та особисті послуги (та усі вкладені коди)'},
]

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
}

function viewMainClassif(props){
    if(props.createItems.landRental.additionalClassificationsArr !== null){ // TODO: CSE-CSD ??
        return (<>
            <Select
                    data={{
                        label: "Додатковий класифікатор об'єкта *",
                        //target: props.createItems.landRental.classification,
                        target: props.createItems.landRental.additionalClassifications.id,
                        info: "Додатковий класифікатор об'єкта"
                    }}
                    onChange={(event) => {
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                        props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION')
                        loadMainClassif(props, event)
                    }}
                    options={addClassificationIdOption(props)}
                />
            </>
        )
    }
}

function viewMainClassifMain(props){
    if(props.createItems === null) return null
    if(props.createItems.landRental === null) return null
    if(props.createItems.landRental.additionalClassifications === null) return null
    if(props.createItems.landRental.additionalClassifications.id === null) return null
    return (
        <TextFull
            title="Основний класифікатор"
            data={loadMainClassif(props.createItems.landRental.additionalClassifications.id)}
        />
    )
}

function loadMainClassif(data){
    let tmpData = null
    if(data !== null){
        if(data.indexOf('.') !== -1){
            let tmp1 = data.split('.')
            tmpData = tmp1[0]
        }else{
            tmpData = data
        }
    }
    switch(tmpData){
        case "01":
            return "CAV 06121000-6 Землі сільськогосподарського призначення"
        case "02":
            return "CAV 06111000-3 Землі житлової забудови (землі, які використовуються для розміщення житлової забудови (житлові будинки, гуртожитки, господарські будівлі та інше); землі, які використовуються для розміщення гаражного будівництва)"
        case "03":
            return "CAV 06112000-0 Землі громадської забудови (землі, які використовуються для розміщення громадських будівель і споруд (готелів, офісних будівель, торговельних будівель, для публічних виступів, для музеїв та бібліотек, для навчальних та дослідних закладів, для лікарень та оздоровчих закладів), інших об'єктів загального користування)"
        case "04":
        case "05":
            return "CAV 06122000-3 Землі природно-заповідного фонду та іншого природоохоронного призначення"
        case "06":
            return "CAV 06124000-7 Землі оздоровчого призначення"
        case "07":
            return "CAV 06124000-7 Землі рекреаційного призначення"
        case "08":
            return "CAV 06125000-4 Землі історико-культурного призначення"
        case "09":
            return "CAV 06126000-1 Землі лісогосподарського призначення"
        case "10":
            return "CAV 06127000-8 Землі водного фонду"
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
            return "CAV 06128000-5 Землі промисловості, транспорту, зв'язку, енергетики, оборони та іншого призначення"
        case "16":
        case "17":
        case "18":
        case "19":
            return "CAV 06129000-2 Землі запасу, резервного фонду та загального користування"
        default:
            return ""
    }
}

function viewTypeItems(props, setEdit, data){
   return <LandItem
        edit={setEdit}
        data={data}
        classification={props.createItems.landRental.classification}
        addClassification={addClassificationIdData(props)}
        setDataAddress={props.setDataAddress}
    />
}

function addClassificationIdOption(props){
    if(props.createItems.landRental.additionalClassificationsArr !== null){
                let collatore = new Intl.Collator('uk-UA')
                let tmp = props.createItems.landRental.additionalClassificationsArr
                tmp.sort(
                    (a, b) => collatore.compare(a.id, b.id)
                )
                return tmp.map(
                    (i) => {
                        return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                    }
                )
            }
}

function addClassificationIdData(props){
    if(props.createItems.landRental.additionalClassificationsArr !== null){
                return props.createItems.landRental.additionalClassificationsArr
                .filter((i) => {
                    return i.id === props.createItems.landRental.additionalClassifications.id
                })
                .map(
                    (i) => {
                        return {
                            id: i.id,
                            description: { uk_UA: i.description.uk_UA },
                            scheme: "kvtspz"
                        }
                    }
                )
            }
    return null
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let json = await response.json()
        props.setDataAddress(json[0].id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
        props.setDataAddress(json, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
    }
}


async function getAdditionalClassificationCVZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_CVZU_DATA')
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationsZU: state.start.classificationsZU.unitCode
    }
}

const mapDispatchToProps = {
    getMyAuction,
    setDataAddress,
    changeLoader,
    unitsGetByTypeZU
}

export default connect(mapStateToProps, mapDispatchToProps)(CommSell)