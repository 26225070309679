import { 
    //default
    TOKEN_POST,
    TOKEN_CLEAR,
    SET_KEY_INTERVAL,
    SET_PROFILE_NULL,
    SET_SINGL_PASSWORD,
} from "../../types";


//default

export function postGetToken(value){
    return {type: TOKEN_POST, payload: value}
}

export function postRefToken(endPoint, refToken) {
    let data = JSON.stringify({
        "client_id" : "1",
        "client_secret" : "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=",
        "grant_type": "refresh_token",
        "refresh_token": refToken
    });
    return async dispatch => {
            const response = await fetch(`${endPoint}/api/v1/uk/token`, {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        const json = await response.json()
        json.timeStamp = await Date.now() + json.expires_in * 1000;
        dispatch({type: TOKEN_POST, payload: json})
    }
}


export function localGetToken(value){
    return {type: TOKEN_POST, payload: value}
}

export function localClearToken(){
    return {type: TOKEN_CLEAR, payload: null}
}

export function setIntervalRef(value){
    return {type: SET_KEY_INTERVAL, payload: value}
}

export function setProfileNull(value){
    return {type: SET_PROFILE_NULL, payload: value}
}

export function setSinglPasword(value){
    return {type: SET_SINGL_PASSWORD, payload: value}
}