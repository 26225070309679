import React from 'react';
import styles from './createAuction.module.css';

import {connect} from 'react-redux';

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeMyCurentPage,
} from '../../../redux/actions.js';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
    changeCreateItemGenerationType,
    changeCreateItemUnitCode,
    auctionsSearch,
} from '../../../redux/actions/auctions/auctions.js';

import {changePopUpBankAccount, setPopBankAccountId, setPopBankAccountIdArr} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../redux/actions/discount/discount.js';

import NextPage from './next'
import PrevPage from './prev'

const pagingButton = ( props ) => {
    let tmp = null
    let tmpCurrentNumber = props.myPage + 1
    if(props.auctionsGetMyData !== null){
        if(props.auctionsGetMyData.length === 10){
            if(props.myPage === 0){
                tmp = <div className={styles.paging_wrap}>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerNext(props)
                            }
                        }
                    >
                        <NextPage />
                    </div>
                </div>
            }else{
                tmp = <div className={styles.paging_wrap}>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerPrev(props)
                            }
                        }
                    >
                        <PrevPage />
                    </div>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerNext(props)
                            }
                        }
                    >
                        <NextPage />
                    </div>
                </div>
            }
        }else{
            if(props.myCabinetPage === 0){
                tmp = null
            }else{
                tmp = <div className={styles.paging_wrap}>
                    <div 
                        className={styles.paging}
                        onClick={
                            () => {
                                handlerPrev(props)
                            }
                        }
                    >
                        <PrevPage />
                    </div>
                    <div className={styles.countPage}>{tmpCurrentNumber}</div>
                </div>
            }
        }
    }
    return tmp
}

function handlerNext(props){
    let tmp = props.myPage + 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?offset=${tmp}0`

    props.changeMyCurentPage(tmp)
    //props.auctionsGetMy(data, header)
    window.scrollTo(0, 0)
    getPageSearch(props, searchData(props, tmp))
}

function handlerPrev(props){
    let tmp = props.myPage - 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    if(tmp === 0){
        data.params = `?offset=${props.tmp}`
    }else{
        data.params = `?offset=${tmp}0`
    }

    props.changeMyCurentPage(tmp)
    //props.auctionsGetMy(data, header)
    window.scrollTo(0, 0)
    getPageSearch(props, searchData(props, tmp))
}

function searchData(props, count){
    return {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=' + count * 10
    }
}

function getPageSearch(props, data){
    let keyword, authorName, classification, auctionId;
    let selectTypeKeyword = null;
    let startDateFrom = null;
    let startDateTo = null;
    if(props.search.selectTypeKeyword !== ''){
        selectTypeKeyword = props.search.selectTypeKeyword
        startDateFrom = props.search.startDateFrom
        startDateTo = props.search.startDateTo
    }
    switch (selectTypeKeyword){
        case '0':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
        case '1':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = props.search.textSting;
            break;
        case '2':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
        case '3':
            keyword = null;
            authorName = props.search.textSting;
            classification = null;
            auctionId = null;
            break;
        default:
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
    }
    let dataSearch = {
        keyword: keyword,
        authorName: authorName,
        classification: classification,
        auctionId: auctionId,
        category: props.search.category,
        status: props.search.status,
        startDateFrom: startDateFrom,
        startDateTo: startDateTo
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
    }
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionsGetMyData: state.start.auctionsGet,
        myPage: state.start.myPage,
        search: state.start.search,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,

    changeCreateItemGenerationType,
    changeCreateItemUnitCode,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    discountAuc,
    changeMyCurentPage,
    auctionsSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(pagingButton)
