import React, {useState, useEffect} from 'react';

import { checkProp } from '../../../../redux/check';

import Input from '../../../elements/inputs/input'
import Select from '../../../elements/inputs/inputSelect'
import InputDate from '../../../elements/inputs/inputDate'

const StateRegistration = ({ data, setValue, disabled = false})=> {
    const [registrationStatus, setRegistrationStatus] = useState(checkProp(data, 'registrationStatus') ? data.registrationStatus : null)
    const [registrationID, setRegistrationID] = useState(checkProp(data, 'registrationID') ? data.registrationID : null)
    const [registrationDate, setRegistrationDate] = useState(checkProp(data, 'registrationDate') ? data.registrationDate : null)

    useEffect(() => {
        setValue({
            registrationStatus: registrationStatus,
            registrationID: registrationStatus === null || registrationStatus === "notRegistered" ? null : registrationID,
            registrationDate: registrationStatus === null || registrationStatus === "notRegistered" ? null : registrationDate
        })
    }, [registrationStatus, registrationID, registrationDate])

    return (
        <div style={{ width: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
            <div style={{ width: '100%', fontSize: '16px', fontWeight: '600', marginBottom: '18px' }}>Інформація щодо реєстрації</div>
            <Select
                data={{
                    label: "Стан державної реєстрації об'єкту",
                    target: registrationStatus,
                    required: true,
                }}
                onChange={(event) => { setRegistrationStatus(event) }}
                options={[
                    {value: "notRegistered", name: 'Не зареєстровано'},
                    {value: "oldRegistration", name: 'Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно'},
                    {value: "properlyRegistered", name: 'Зареєстровано в Державному реєстрі речових прав на нерухоме майно'},
                    {value: "registrationIsNotRequired", name: 'Реєстрація не вимагається'}
                ]}
            />
            { registrationStatus === "oldRegistration" || registrationStatus === "properlyRegistered" ?
                <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                    <div style={{width: '49%'}}>
                        <Input
                            label="Номер запису"
                            value={registrationID}
                            onChange={(event)=>{ 
                                if(event === ""){
                                    setRegistrationID(null)
                                }else{
                                    setRegistrationID(event)
                                }
                            }}
                            disabled={disabled}
                        />
                    </div>
                    <div style={{width: '49%'}}>
                        <InputDate
                            data={{
                                target: registrationDate,
                                label: 'Дата реєстрації',
                                time: true
                            }}
                            onChange={(e)=>{ 
                                if(e === ''){
                                    setRegistrationDate(null)
                                }else{
                                    setRegistrationDate(e)
                                }
                            }}
                            disabled={disabled}
                        />
                    </div>
                </div> : null 
            }
        </div>
    )
}

export default StateRegistration