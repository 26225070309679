import React, {useState, useEffect, useRef} from 'react';
import styles from './inputAmount.module.css';
import { checkProp } from '../../../redux/check';
import Checkbox from './checkBox';
import InputInfo from './inputInfoBlock';

const InputAmount = ({data, onChange, title, tax, disabled, ...props}) => {
    const [value, setValue] = useState(checkProp(data, 'amount') ? data.amount : null)
    const [currency, setCurrency] = useState(checkProp(data, 'currency') ? data.currency : 'UAH')
    const [addTax, setAddTax] = useState(checkProp(data, 'addTax') ? data.addTax : true)
    const [label] = useState(props.required ? `${props.label} *` : props.label)


    const [auctionType] = useState(checkProp(data, 'auctionType') ? data.auctionType : null)

    const amount = useRef()

    useEffect(() => {
        setValue(data.amount)
    }, [data])

    useEffect(() => {
        if(!!tax) {
           onChange({
                currency: currency,
                amount: value,
                addTax: addTax
            })
        }
        else {
            onChange({
                currency: currency,
                amount: value
                })
        }
    }, [currency, value, addTax])

    return (
        <div className={styles.inPutWrap}>
            <div className={styles.title}>{props.required ? title + ' *' : title}</div>
            <div className={styles.content}>
                <div className={styles.inPutSelectWrap}>
                    <select className={styles.inPutSelect}
                        value={currency}
                        onChange={
                            (e)=>{
                                
                                setCurrency(e.target.value)
                            }
                        }
                    >
                        {auctionType !== null ? [
                            <option value="UAH" key='UAH' default>Гривня</option>,
                            <option value="USD" key='USD'>Долар США</option>,
                            <option value="EUR" key='EUR'>Євро</option>,
                        ] : [
                            <option value="UAH" key='UAH' default>Гривня</option>,
                            <option value="USD" key='USD' disabled>Долар США</option>,
                            <option value="EUR" key='EUR' disabled>Євро</option>,
                        ]}
                    </select>
                    <div className={styles.labelCur}>Валюта</div>
                </div>
                <input
                    ref={amount}
                    className={disabled ? styles.disabled : styles.inPutAmount}
                    type='number'
                    onInput={e => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        let reg2=/^\d*(\.\d{0,2})?$/;
                        if(reg.test(e.target.value) !== true && reg2.test(e.target.value)) setValue(e.target.value)
                    }}
                    disabled={disabled}
                    value={value}
                />
                <label className={styles.label} for={amount}>
                    { !!props.label ? label : 'Сума' }
                </label>
                { tax ?
                    <Checkbox
                        value={addTax}
                        onChange={setAddTax}
                        label={'з ПДВ'}
                        disabled={disabled}
                    /> : null
                 }
                 { checkProp(props,'info') ?
                    <InputInfo
                        data={props.info}
                    /> : null
                 }
            </div>
        </div>
    )
    
}

export default InputAmount