import React from 'react';
import style from './instructions.module.css';

export default function Instructions(){
    return (
        <section>
            <header className={style.header}><h1>ІНСТРУКЦІЇ</h1></header>
            <article>
                <ol>
                    <li><p><a href="https://www.dto.com.ua/files/Instr_uchast.pdf" target="_blank" rel="noopener noreferrer">Інструкція по роботі учасників відкритих електронних аукціонів на сайті майданчику “Державніторги.онлайн”</a></p></li>
                    <li><p><a href="https://www.dto.com.ua/files/Instr_org_mayn_17.03.20.pdf" target="_blank" rel="noopener noreferrer"><span>Інструкція по роботі організаторів аукціонів з продажу і оренди майна замовників на сайті майданчику “Державніторги.Онлайн”</span></a></p></li>
                    <li><p><a href="https://www.dto.com.ua/files/Inst_FGV.pdf" target="_blank" rel="noopener noreferrer"><span>Інструкція по роботі організаторів аукціонів з продажу майна і активів збанкрутілих банків на сайті майданчику “Державніторги.Онлайн”</span></a></p></li>
                    <li><p><a href="https://www.dto.com.ua/files/Privatization_22.01.20.pdf" target="_blank" rel="noopener noreferrer"><span>Інструкція по роботі представників Органу приватизації на майданчику “Державніторги.Онлайн”</span></a></p></li>
                </ol>
            </article>
        </section>
    )
}
