import React from 'react';

import {connect} from 'react-redux';


import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardApprowePopUpStatus
} from '../../../../redux/actions/awards/awards.js';
import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
} from '../../../../redux/actions/contracts/contracts.js';

import ContractDocumentsNew from "../../../product/contractNew/bidAddDocNewPopup";
import ContractRouter from "../aucType/contractRouter";
import AwardDocumentsNew from "../../../product/awardNew/bidAddDocNewPopup";

const ContractApproweMain = (props) => {
    switch (props?.popUpContractApprowe.typeView) {
        case 1:
            return <ContractDocumentsNew/>
        case 2:
            return <ContractRouter/>
        case 3:
            return <AwardDocumentsNew/>
        default:
            return null
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardApprowe: state.start.popUpAwardApprowe,
        popUpContractApprowe: state.start.popUpContractApprowe,
        timberContractPut: state.start.timberContractPut,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardApprowePopUpStatus,
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,


    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractApproweMain)