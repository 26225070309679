import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Header from '../header/header.js';
import Find from '../find/find.js';
import Footer from '../footer/footer.js';

import Pages from '../pages/pages.js';
import CreateAuction from '../createAuction/routs';
import Posts from '../posts/posts.js';
import Settings from '../settings/settings.js';
import Finance from '../finance/finance.js';
import Profile from '../profile/profile.js';
import Product from '../product/product.js';
import Helpful from '../helpful/helpful.js';
import MobMenu from '../mobMenu/mobMenu.js';

import Info from '../info/info.js';

import {connect} from 'react-redux';
import {changeWindow, changeMenu, setCheckboxBidPublish} from '../../redux/actions.js';
import Slider from '../slider/slider.js';
import {unitsGet, unitsGetByType} from '../../redux/actions/itemUnits/itemUnits.js';
import {userProfileGet} from '../../redux/actions/oldSiteUsers/oldSiteUsers.js';

import {setLogo} from '../../redux/actions/support.js';

import PopUpSendAuction from '../popUp/auction/sendAuction/sendAuction.js';
import PopUpDeleteAuction from '../popUp/auction/deleteAuction/deleteAuction.js';


import {localGetToken, setIntervalRef, postRefToken, localClearToken} from '../../redux/actions/default/default.js';

import PopUpAward from '../popUp/awards/popupAward.js';

import PopUpAwardApprowe from '../popUp/awards/awardApprowe/awardApprowe.js';
import PopUpAwardError from '../popUp/awards/awardError/awardError.js';

import PopUpContractApprowe from '../popUp/contracts/contractApprowe/contractApproweMain.js';
import PopUpContractError from '../popUp/contracts/contractError/contractError.js';

import PopUpBidError from '../popUp/bid/bidError/bidError.js';
import PopUpBidEdit from '../popUp/bid/bidEdit/bidEdit.js';
import PopUpAuctionError from '../popUp/auction/auctionError/auctionError.js';
import PopUpAuctionAnswer from '../popUp/auction/answerAuction/answerAuction.js';
import PopUpAuctionAnswerEdit from '../popUp/auction/answerAuctionEdit/answerAuctionEdit.js';

import PopUpBankAccount from '../popUp/auction/createBankAccount/createBankAccount.js';
import PopUpBankAccountComplite from '../popUp/auction/createBankAccount/createBankAccountComplite/createBankAccountComplite.js';
import PopUpBankAccountError from '../popUp/auction/createBankAccount/createBankAccountError/createBankAccountError.js';

import PopUpAuctionCreate from '../popUp/auction/createAuction/createAuction.js';
import PopUpAuctionCreateMonga from '../popUp/auction/createAuction/createAuctionMonga.js';

import PopUpAddressCreate from '../popUp/auction/createAddress/createAddress.js';
import PopUpAddressError from '../popUp/auction/createAddress/createAddressError/createAddressError.js';
import PopUpAddressComplite from '../popUp/auction/createAddress/createAddressComplite/createAddressComplite.js';

import PopUpLoader from '../popUp/loader/loader.js';

import PopUpCreateRestriction from '../popUp/auction/createRestriction/createRestriction.js';
import PopUpCreateRestrictionExclusion from '../popUp/auction/createRestrictionExclusion/createRestrictionExclusion.js';

import PopUpTogetherApi from '../popUp/togetherAPI/togetherAPI.js';

import PopUpUniversalError from '../popUp/error/error.js';

import PopUpTogetherAPI from '../pages/catalog/togetherAPI/popUp/popup.js';

import PopUpEditDocAward from '../popUp/awards/editDocument/editDocument.js';
import PaymentPopUp from '../popUp/paymentPopUp/paymentPopUp.js';
import PaymentPopUp1 from '../popUp/paymentPopUp/paymentPopUp1.js';

import PopUpBank from '../popUp/bank/popupBank.js';
import PopUpAddresses from '../popUp/address/address.js';
import PopUpOrganizations from '../popUp/organizations/organizations';
import Organization from '../createAuction/windowAuction/zuAddressOrganization'

import PopUpCreateAuctionZU from '../popUp/createAuctionZU/createAuctionZU';
import Period from '../popUp/period/period'
import BridgeHelp from '../popUp/bridge/bridge'

import TogetherAPIHelpPopUp from '../popUp/togetherAPI/togetherAPIHelpPopUp'

import PopUpContractDel from '../popUp/contracts/contractDel/contractDel'

import Error404 from '../errors/errors'

import PopUpLogin from '../popUp/login/login';

const App = (props) => {
  let tpl;
  let tmpPopUpSendMessage = null;
  if(props.popUpSendAuc !== null){
    tmpPopUpSendMessage = <PopUpSendAuction />;
  }
  let tmpPopUpDeleteMessage = null;
  if(props.popUpDelAuc !== null){
    tmpPopUpDeleteMessage = <PopUpDeleteAuction />
  }
  let tmpPopUpAwardReject = null;
  if(props.popUpAwardReject !== null){
    tmpPopUpAwardReject = <PopUpAward />
  }
  let tmpPopUpAwardApprowe = null;
  if(props.popUpAwardApprowe !== null){
    tmpPopUpAwardApprowe = <PopUpAwardApprowe />
  }
  let tmpPopUpAwardError = null;
  if(props.popUpAwardError !== null){
    tmpPopUpAwardError = <PopUpAwardError />
  }

  let tmpPopUpContractApprowe = null;
  if(props.popUpContractApprowe !== null){
    tmpPopUpContractApprowe = <PopUpContractApprowe />
  }
  let tmpPopUpContractError = null;
  if(props.popUpContractError !== null){
    tmpPopUpContractError = <PopUpContractError />
  }

  let tmpPopUpBidError = null;
  if(props.popUpBidError !== null){
    tmpPopUpBidError = <PopUpBidError />
  }
  let tmpPopUpAuctionError = null;
  if(props.popUpAuctionError !== null){
    tmpPopUpAuctionError = <PopUpAuctionError />
  }
  let tmpPopUpAuctionAnswer = null;
  if(props.questionCreate.popUp !== null){
    tmpPopUpAuctionAnswer = <PopUpAuctionAnswer />
  }
  let tmpPopUpAuctionAnswerEdit = null;
  if(props.questionCreate.popUpEdit !== null){
    tmpPopUpAuctionAnswerEdit = <PopUpAuctionAnswerEdit />
  }


  let tmpPopUpBankAccount = null;
  if(props.popUpBankAccount === true){
    tmpPopUpBankAccount = <PopUpBankAccount />
  }

  let tmpPopUpBankAccountComplite = null;
  if(props.popUpBankAccountComplite === true){
    tmpPopUpBankAccountComplite = <PopUpBankAccountComplite />
  }

  let tmpPopUpBankAccountError = null;
  if(props.popUpBankAccounErrort === true){
    tmpPopUpBankAccountError = <PopUpBankAccountError />
  }

  let tmpPopUpBidEdit = null;
  if(props.bidPopUpEdit !== null){
    tmpPopUpBidEdit = <PopUpBidEdit />
  }

  let tmpPopUpAuctionCreate = null;
  if(props.popUpAuctionCreate !== null){
    tmpPopUpAuctionCreate = <PopUpAuctionCreate />
  }

  let tmpPopUpAuctionCreateMonga = null;
  if(props.popUpAuctionCreateMonga !== null){
    tmpPopUpAuctionCreateMonga = <PopUpAuctionCreateMonga />
  }


  let tmpPopUpAddressCreate = null;
  if(props.address.popUpAddress === true){
    tmpPopUpAddressCreate = <PopUpAddressCreate />
  }
   
  let tmpPopUpAddressError = null;
  if(props.address.popUpAddressError !== null){
    tmpPopUpAddressError = <PopUpAddressError />
  }

  let tmpPopUpAddressComplite = null;
  if(props.address.popUpAddressComplite !== null){
    tmpPopUpAddressComplite = <PopUpAddressComplite />
  }
 
  let tmpLoader = null;
  if(props.loader !== false){
    tmpLoader = <PopUpLoader />
  }

  let tmpPopUpCreateRestriction = null;
  if(props.createRestrictionFlag !== null){
    tmpPopUpCreateRestriction = <PopUpCreateRestriction />
  }

  let tmpPopUpCreateRestrictionExclusions = null;
  if(props.createRestrictionExclusionsFlag !== null){
    tmpPopUpCreateRestrictionExclusions = <PopUpCreateRestrictionExclusion />;
  }

  let tmpPopUpTogetherAPIelem = null;
  if(props.togetherAPIelem.popup !== null){
    tmpPopUpTogetherAPIelem = <PopUpTogetherApi />;
  }

  let tmpUniversalError = null;
  if(props.universalError !== null){
    tmpUniversalError = <PopUpUniversalError />;
  }
  
  let tmpPopUpTogetherAPI = null;
  if(props.togetherApiPage.popUpOnOff !== 0){
    tmpPopUpTogetherAPI = <PopUpTogetherAPI />
  }

  let tmpPopUpEditDocAward = null;
  if(props.popUpEditAppruveDoc !== null){
    tmpPopUpEditDocAward = <PopUpEditDocAward />
  }

  let tmpPaymentPopup = null;
  if(props.paymentPopup !== null){
    tmpPaymentPopup = <PaymentPopUp />
  }
  let tmpPaymentPopup1 = null;
  if(props.paymentPopup1 !== null){
    tmpPaymentPopup1 = <PaymentPopUp1 />
  }
  
  let tmpPopUpBank = null;
  if(props.setOpenBankWindow === true){
    tmpPopUpBank = <PopUpBank />
  }
  
  let tmpPopUpAdsresses = null;
  if(props.setOpenAddressWindow === true){
    tmpPopUpAdsresses = <PopUpAddresses />
  }

  let tmpPopUpOrganizations = null;
  if(props.popupOrganizations === true){
    tmpPopUpOrganizations = <PopUpOrganizations />
  }
  let tmpPopUpOrganizationsEdit = null;
  if(props.addressNew.openPopUp === true){
    tmpPopUpOrganizationsEdit = <Organization />
  }
  
  let tmpPopUpCreateAuctionZU = null
  if(props.createAuctionZU.flag === true){
    tmpPopUpCreateAuctionZU = <PopUpCreateAuctionZU />
  }

  let tmpPopUpPeriod = null
  if(props.shedullePeriod.onOff === true){
    tmpPopUpPeriod = <Period />
  }

  let tmpBridge = null
  if(props.bridge.openPopUp === true){
    tmpBridge = <BridgeHelp />
  }

  let tmpTogetherApiHelpPopUp = null
  if(props.togetherApiHelpPopUp !== null){
    tmpTogetherApiHelpPopUp = <TogetherAPIHelpPopUp />
  }

  let tmpPopUpContractDel = null
  if(props.popUpContractDel !== null){
    tmpPopUpContractDel = <PopUpContractDel />
  }
  
  
  //если протух выход нужно очистить локал сторедж
  if(props.checkBoxBidPublish === true){
    if(window.location.pathname.indexOf('/tenders/') === -1 || window.location.pathname.indexOf('/auctions/') === -1){
      //props.setCheckboxBidPublish(false)
    }
  }


  let tmpLocal = null;
  if (localStorage.getItem('token') !== null){
    if(localStorage.getItem('token') === undefined ||localStorage.getItem('token') === 'undefined'){
    }else{
      tmpLocal = JSON.parse(localStorage.getItem('token'))
        if(props.keySetIntervalRefresh === null){
          props.localGetToken(tmpLocal)
          let tmpInterval = setInterval(() => {
            tmpLocal = JSON.parse(localStorage.getItem('token'))
            props.postRefToken(process.env.REACT_APP_END_POINT_BD, tmpLocal.refresh_token)
          }, 1800000);
          props.setIntervalRef(tmpInterval)
        }
    }
  }

  if(props.logo === null){
    props.setLogo(window.location.pathname.length)
  }else{
    if(window.location.pathname.length === 1){
      if(props.logo !== window.location.pathname.length){
        props.setLogo(null)
      }
    }else{
      if(props.logo !== window.location.pathname.length){
        props.setLogo(window.location.pathname.length)
      }
    }
  }

  if(props.mobMenu === true){
      tpl = <MobMenu />
  }else{
    tpl = <BrowserRouter>
      {tmpPopUpContractDel}
      {tmpTogetherApiHelpPopUp}
      {tmpPopUpSendMessage}
      {tmpPopUpDeleteMessage}
      {tmpPopUpAwardReject}
      {tmpPopUpAwardApprowe}
      {tmpPopUpAwardError}
      {tmpPopUpContractApprowe}
      {tmpPopUpContractError}
      {tmpPopUpBidError}
      {tmpPopUpAuctionError}
      {tmpPopUpAuctionAnswer}
      {tmpPopUpAuctionAnswerEdit}
      {tmpPopUpBankAccount}
      {tmpPopUpBankAccountComplite}
      {tmpPopUpBankAccountError}
      {tmpPopUpBidEdit}
      {tmpPopUpAuctionCreate}
      {tmpPopUpAuctionCreateMonga}
      {tmpPopUpAddressCreate}
      {tmpPopUpAddressError}
      {tmpPopUpAddressComplite}
      {tmpLoader}
      {tmpPopUpCreateRestriction}
      {tmpPopUpCreateRestrictionExclusions}
      {tmpPopUpTogetherAPIelem}
      {tmpUniversalError}
      {tmpPopUpTogetherAPI}
      {tmpPopUpEditDocAward}
      {tmpPaymentPopup}
      {tmpPaymentPopup1}
      {tmpPopUpBank}
      {tmpPopUpAdsresses}
      {tmpPopUpOrganizations}
      {tmpPopUpOrganizationsEdit}
      {tmpPopUpCreateAuctionZU}
      {tmpPopUpPeriod}
      {tmpBridge}
      <Header />
      <Switch>
        <Route exact path='/'
          render={ () => <Slider /> }
        />
      </Switch>
      <Find />
      <Switch>
        <Route path='/create_auctions'
          render={ () => <CreateAuction /> }
        />
        <Route exact path='/posts'
          render={ () => <Posts /> }
        />
        <Route exact path='/settings'
          render={ () => <Settings /> }
        />
        <Route exact path='/finance'
          render={ () => <Finance /> }
        />
        <Route exact path='/profile'
          render={ () => <Profile /> }
        />
        <Route exact path='/'
          render={ () => <Pages /> }
        />
        <Route path='/search/'
          render={ () => <Pages /> }
        />
        <Route path='/auctions/'
          render={ () => <Product /> }
        />
        <Route path='/helpful'
          render={ () => <Helpful /> }
        />
        <Route path='/info'
          render={ () => <Info /> }
        />
        <Route path='/404'
          render={ () => <Error404 errorCode={404}/> }
        />
        <Route path='/401'
          render={ () => <Error404  errorCode={401}/> }
        />
        <Route path='/403'
          render={ () => <Error404  errorCode={403}/> }
        />
        <Route path='/404'
          render={ () => <Error404  errorCode={404}/> }
        />
        <Route path='/500'
          render={ () => <Error404  errorCode={500}/> }
        />
        <Route path='/502'
          render={ () => <Error404  errorCode={502}/> }
        />
        <Route path='/503'
          render={ () => <Error404  errorCode={503}/> }
        />
        <Route path='/504'
          render={ () => <Error404  errorCode={504}/> }
        />
        <Route exact path='/login'
            render={ () => {
              if(props.token === null){
                return <PopUpLogin />
              }
              return <Redirect to='/' />
            }}
        />
        <Route exact path='/payment'
            render={ () => {
                return <PaymentPopUp1 />
            }}
        />
            
        
        <Route render={ () => <Error404  errorCode={404}/> } />
      </Switch>
      <Footer />
    </BrowserRouter>
  }

  if(props.windowSize === null){
    props.changeWindow(document.body.clientWidth)
  }
  if(props.windowSize > 960){
    props.changeMenu(false)
  }

  window.addEventListener(`resize`, () => {
    props.changeWindow(document.body.clientWidth)
  }, false);


  return tpl
}

const mapStateToProps = state => {
  return {
    token: state.start.token,
    auctions: state.start.auctionsGet,
    auctionsFlag: state.start.auctionsGetFlag,
    langFlag: state.start.langFlag,
    mobMenu: state.start.mobileMenu,
    windowSize: state.start.windowSize,
    unitsGets: state.start.unitsGet,
    userProfiles: state.start.userProfiles,
    popUpStatus: state.start.popUpStatus,
    logo: state.start.logo,
    popUpSendAuc: state.start.popUpSendAuc,
    popUpDelAuc: state.start.popUpDelAuc,
    popUpAwardReject: state.start.popUpAwardReject,
    popUpAwardError: state.start.popUpAwardError,
    popUpAwardApprowe: state.start.popUpAwardApprowe,

    popUpContractApprowe: state.start.popUpContractApprowe,
    popUpContractError: state.start.popUpContractError,

    popUpBidError: state.start.popUpBidError,
    popUpAuctionError: state.start.popUpAuctionError,
    questionCreate: state.start.questionCreate,
    popUpBankAccount: state.start.popUpBankAccount,
    popUpBankAccountComplite: state.start.popUpBankAccountComplite,
    popUpBankAccounErrort: state.start.popUpBankAccounErrort,
    bidPopUpEdit: state.start.bidPopUpEdit,

    popUpAuctionCreate: state.start.popUpAuctionCreate,
    popUpAuctionCreateMonga : state.start.popUpAuctionCreateMonga,
    address: state.start.address,
    loader: state.start.loader,
    
    
    createRestrictionFlag: state.start.createRestrictionFlag,
    createRestrictionExclusionsFlag: state.start.createRestrictionExclusionsFlag,

    togetherAPIelem: state.start.togetherAPIelem,
    keySetIntervalRefresh: state.start.keySetIntervalRefresh,

    universalError: state.start.universalError,
    togetherApiPage: state.start.togetherApiPage,
    popUpEditAppruveDoc: state.start.popUpEditAppruveDoc,
    paymentPopup: state.start.paymentPopup,
    paymentPopup1: state.start.paymentPopup1,
    checkBoxBidPublish: state.start.checkBoxBidPublish,
    setOpenBankWindow: state.start.setOpenBankWindow,
    setOpenAddressWindow: state.start.setOpenAddressWindow,
    popupOrganizations: state.start.popupOrganizations,
    addressNew: state.start.addressNew,
    createAuctionZU: state.start.createAuctionZU,
    shedullePeriod: state.start.shedullePeriod,
    bridge: state.start.bridge,
    togetherApiHelpPopUp: state.start.togetherApiHelpPopUp,
    popUpContractDel: state.start.popUpContractDel,
  }
}

const mapDispatchToProps = {
  changeWindow,
  changeMenu,
  unitsGet,
  unitsGetByType,
  userProfileGet,
  setLogo,
  localGetToken,
  setIntervalRef,
  postRefToken,
  localClearToken,
  setCheckboxBidPublish,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)