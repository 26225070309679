import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf';
import TextFull from '../../../elements/viewData/textFull'
import TextFullHeader from './textFullHeader'

import ZuMap from '../../../map/zuMapsNotEdit'
import SlimmerBlock from '../../../elements/slimmerBlock/slimmerBlock';

const StructureLot = (props) => {
    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            if(props.auctionId.items !== null){
                if(props.auctionId.items.length > 0){
                    tmp = props.auctionId.items
                    .map(
                        (i, count) => {
                            let tmpZuMap = null
                            if(i.hasOwnProperty('location')){
                                if(i.location !== null){
                                    if((i.location.latitude !== null && i.location.latitude !== '')  && (i.location.longitude !== null && i.location.longitude !== '')){
                                        tmpZuMap = <ZuMap
                                        latitude = {i.location.latitude}
                                        longitude = {i.location.longitude}
                                        />
                                    }
                                }
                            }
                            return (
                                <>
                                    {viewTypeAuction(i)}
                                    {tmpZuMap}
                                </>
                            )
                        }
                    )
                }
            }
        }
    }
    return (
        <SlimmerBlock
            title='Склад лоту'
            content={tmp}
            slim={false}
        />
    )
}

function viewTypeAuction(data){
    let tmpAddresPostalCode = null;
    let tmpAddresCountryName = null;
    let tmpAddresRegion = null;
    let tmpAddresLlocality = null;
    let tmpAddresIstreetAddress = null;
    let codKTU = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                if(data.address.postalCode !== null) tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                if(data.address.countryName !== null) tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                if(data.address.region !== null) tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                if(data.address.locality !== null) tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                if(data.address.streetAddress !== null) tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
            if(data.address.hasOwnProperty('addressID') === true){
                if(data.address.addressID !== null){
                    if(data.address.addressID.hasOwnProperty('name') === true){
                        if(data.address.addressID.name !== null){
                            codKTU = data.address.addressID.name.uk_UA
                        }
                    }
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = ''// `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    if(tmpAddresPostalCode !== '') address = address + tmpAddresPostalCode + ', '
    if(tmpAddresCountryName !== '') address = address + tmpAddresCountryName + ', '
    if(tmpAddresRegion !== '') address = address + tmpAddresRegion + ', '
    if(tmpAddresLlocality !== '') address = address + tmpAddresLlocality + ', '
    if(tmpAddresIstreetAddress !== '') address = address + tmpAddresIstreetAddress
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}></div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {AddClassificationScheme(data)}
            <TextHalf
                title='Місцезнаходження'
                data={address}
            />
            <TextHalf
                title='КОАТУУ'
                data={codKTU}
            />
            {viewTypeUnits(data)}
            <TextHalf
                title="Площа земельної ділянки"
                data={data.quantity}
            />
            {unitName(data)}
            {zuItemType(data)}
            {dgfLocation(data)}
        </>
    )
}

function viewTypeUnits(data){
    if(data !== null){
        if(data.hasOwnProperty('itemProps')){
            return (
                <TextHalf
                    title="Тип об'єкта майна"
                    data={strTypeObject(data)}
                />
            )
        }
    }
    return null
}

function AddClassificationScheme(data){
    if(data !== null){
        if(data.hasOwnProperty('additionalClassifications')){
            if(data.additionalClassifications !== null){
                if(data.additionalClassifications.length > 0){
                    return data.additionalClassifications.map(
                        (e) => {
                            let tmpAdd = e.scheme + ' ' + e.id + ' - ' + (e?.description ? e.description.uk_UA : null)
                            return <TextHalf
                                title="Додатковий класифікатор об'єкта"
                                data={tmpAdd}
                            />
                        }
                    )
                }
            }
        }
    }
    return null
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

function strTypeObject(data){
    if(data.itemProps.itemPropsType === 'otherProperty'){
        return "Інше окреме індивідуально визначене майно"
    }else if(data.itemProps.itemPropsType === 'jointPropertyComplex'){
        return "ЄМК"
    }else if(data.itemProps.itemPropsType === "vehicle"){
        return "Транспортний засіб"
    }else if(data.itemProps.itemPropsType === 'realEstate'){
        return "Нерухоме майно"
    }else if(data.itemProps.itemPropsType === 'land'){
        return "Земельна ділянка"
    }
}

function dgfLocation(data){
    if(data !== null){
        if(data.hasOwnProperty('location')){
            if(data.location !== null){
                return <>
                    <TextFullHeader
                        title="Географічні координати місця розташування:"
                        data=''
                    />
                    <TextHalf
                        title="Широта"
                        data={data.location.latitude}
                    />
                    <TextHalf
                        title="Довгота"
                        data={data.location.longitude}
                    />
                    {/*<TextHalf
                        title="Висота"
                        data={data.elevation}
                    />*/}
                </>
            }
        }
    }
    return null
}

function zuItemType(data){
    if(data.landProps !== null){
        return dgfRealEstateProps(data)
    }
}

function dgfRealEstateProps(data){
    if(data !== null){
        if(data.hasOwnProperty('itemProps')){
            if(data.itemProps !== null){
                return <>
                    <TextFullHeader
                        title="Характеристики земельної ділянки:"
                        data=''
                    />
                    <TextHalf
                        title="Площа ділянки, га"
                        data={data.itemProps.landArea}
                    />
                    <TextHalf
                        title="Кадастровий номер"
                        data={data.itemProps.cadastralNumber}
                    />
                    <TextHalf
                        title="Тип власності"
                        data={strOwnershipType(data.itemProps)}
                    />
                    {data.itemProps.hasOwnProperty('hasEncumbrances') === true
                        ? <TextHalf
                            title="Присутні обтяження"
                            data={data.itemProps.hasEncumbrances === true ? 'наявні' : 'відсутні'}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasEncumbrances') === true && data.itemProps.hasEncumbrances === true
                        ? <TextHalf
                            title="Інформація про наявні обтяження"
                            data={getEncumbrances(data.itemProps.encumbrances)}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasJointOwnership') === true
                        ? <TextHalf
                            title="Наявні співвласники"
                            data={data.itemProps.hasJointOwnership === true ? 'наявні' : 'відсутні'}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasJointOwnership') === true && data.itemProps.hasJointOwnership === true
                        ? <TextHalf
                            title="Інформація про співвласників"
                            data={data.itemProps.jointOwnership}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasUtilitiesAvailability') === true
                        ? <TextHalf
                            title="Інженерні мережі наявні"
                            data={data.itemProps.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasUtilitiesAvailability') === true && data.itemProps.hasUtilitiesAvailability === true
                        ? <TextHalf
                            title="Інформація про інженерні мережі"
                            data={data.itemProps.utilitiesAvailability}
                        />
                        : null
                    }
                    {data.itemProps.hasOwnProperty('hasEmphyteusisLease') && data.itemProps.hasEmphyteusisLeas !== null
                        ? <TextHalf
                            title="Право користування чужою земельною ділянкою"
                             data={data.itemProps.hasEmphyteusisLease === true ? 'наявне' : 'відсутнє'}
                        /> :
                        null
                    }
                </>
            }
        }
    }
    return null
}

function getEncumbrances(data){
    switch(data){
        case "noEncumbrances": return 'Обтяження відсутні'
        case "arrest": return 'Арешт нерухомого майна'
        case "collateral": return 'Податкова застава'
        case "restraintsOnAlienation": return 'Заборона відчуження'
        case "otherEncumbrances": return 'Інші обтяження'
        default: return data
    }
}

function strOwnershipType(data){
    if(data.ownershipType !== null){
        let tmp = ''
        if(data.ownershipType === 'compatible'){
            tmp = 'Сумісна'
        }else if(data.ownershipType === 'municipal'){
            tmp = 'Комунальна'
        }else if(data.ownershipType === 'private'){
            tmp = 'Приватна'
        }else if(data.ownershipType === 'state'){
            tmp = 'Державна'
        }else if(data.ownershipType === 'unknown'){
            tmp = 'Невідомо'
        }
        return tmp
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
