import React,{useState} from 'react';
import styles from './otherPropertyItemNew.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS, getValues, getValuesUA } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Localization from '../../../elems/localization/localization';
import Button from '../../../../elements/buttons/button';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';

// import {disabledElement} from '../../../disabled'
// import { is } from 'date-fns/locale';
import {clearDataLot} from '../clearDataItem'

const OtherPropertyItem = ( {data, classification, ...props} ) => {

    const [description, setDescriotion] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [unit, setUnit] = useState(checkProp(data, 'unit') ? data.unit.code : null)
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAdress] = useState(checkProp(data, 'address') ? data.address : null)

    let requestBody = {
        description: {
            uk_UA: description,
        },
        classification: {
            id: classification,
            scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
            description: {
                uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
            }
        },
        unit: {
            code: unit,
            name: {
                uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
            }
        },
        quantity: +quantity,
        address: address,
        location: location,
        // commercialSellItemType: null
        // additionalClassifications: null,
    }

    function viewButtons(props){
        if(
            props.auctionsMy.items === null ||
            props.auctionsMy.items.length === 0
        ){
            if(
                description !== ''
            ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        createItemZU(props, requestBody)
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
            }
        }else{
                if(
                    description !== ''
                ){
                    return (
                        <div className={styles.lineButtons}>
                            <Button
                                data={'Зберегти зміни'}
                                color={'blue'}
                                onClick={
                                    () => {
                                        if(
                                            props.createItems.zu.jointPropertyComplexItem.id === null &&
                                            props.createItems.zu.vehicleItem.id  === null &&
                                            props.createItems.zu.realEstateItem.id  === null &&
                                            props.createItems.landRental.id === null
                                        ){
                                            createItemZU(props, requestBody)
                                        }else{
                                            saveItemZU(props, requestBody, data.id)
                                        }
                                        props.edit(false)
                                    }
                                }
                            />
                            <Button
                                data={'Закрити'}
                                color={'gray'}
                                onClick={
                                    () => {
                                        clearDataLot(props)
                                        props.edit(false)
                                    }
                                }
                            />
                        </div>
                    )
                }
        }
    }

    if(props.auctionsMy.restrictions !== null && props.auctionsMy.restrictions !== ''){
        if(props.createItems.landRental.itemProps.encumbrances === null || props.createItems.landRental.itemProps.encumbrances === '' || props.createItems.landRental.itemProps.encumbrances === 'null'){
            props.setDataAddress(props.auctionsMy.restrictions, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
        }
    }

    return (
        <>
            <TextArea
                value={description}
                disabled={blokedInput(props)}
                label="Опис об'єкта"
                required
                onChange={(event)=>{ setDescriotion(event) }}
            />
            <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                <div style={{width: '49%'}}>
                    <Input
                        type="number"
                        label="Кількість одиниць"
                        value={quantity}
                        onChange={(event)=>{
                            setQuantity(event)
                        }}
                        error = {redBlock(props, props.createItems.landRental.quantity)}
                    />
                </div>
                <div style={{width: '49%'}}>
                    <Select
                        data={{
                            label: "Одиниці виміру",
                            target: unit,
                            required: true,
                            descr: 'Оберіть одиницю виміру'
                        }}
                        onChange={(event) => {
                            setUnit(event)
                        }}
                        options={classificationSubsoilUnitCode(props)}
                    />
                </div>
            </div>
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} />
            {viewButtons(props)}
        </>
    )
}

function blokedInput(){
    return null
}

function redBlock(amount){
    if(amount === null || amount === '' || amount === 'null'){
        return 'error'
    }
    return null
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .map(
            (i) => {
                return {name: i.name, value: i.code, id: i.id, symbol: i.symbol}
            }
        )
    }
}

async function createItemZU(props, requestBody){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            await createItemCS(props, requestBody)
        }
    }
}

async function saveItemZU(props, requestBody, itemId){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherPropertyItem)