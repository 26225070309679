import React from 'react';

import TextHalf from '../../../elements/viewData/textHalf'
import TextFull from '../../../elements/viewData/textFull'

const AwardElementTitleBidders = ( props ) => {
    if(props.obj.value.buyers !== null){
        if(props.obj.value.buyers.length > 0){
            if(props.obj.value.buyers[0].name !== null && props.obj.value.buyers[0].name !== ''){
                return (
                    <>
                        <TextHalf
                            title="Повна юридична назва організації або ПІБ"
                            data={props.obj.value.buyers[0].name.uk_UA}
                        />
                        
                        <TextFull
                            title="Ідентифікатори організації або особи"
                            data=""
                        />
                        <TextHalf
                            title="Ідентифікатори організації"
                            data={props.obj.value.buyers[0].identifier.scheme}
                        />
                        <TextHalf
                            title="Повна юридична назва організації"
                            data={props.obj.value.buyers[0].identifier.legalName.uk_UA}
                        />
                        <TextHalf
                            title="Код ЄДРПОУ або ІПН або паспорт"
                            data={props.obj.value.buyers[0].identifier.id}
                        />

                        {props.obj.value.buyers[0].representativeInfo !== null
                            ? <TextFull
                                title="Інформація щодо підтвердження повноважень"
                                data={props.obj.value.buyers[0].representativeInfo}
                            />
                            : null
                        }

                        <TextFull
                            title="Контактна особа"
                            data=''
                        />
                        <TextHalf
                            title="ПІБ"
                            data={props.obj.value.buyers[0].contactPoint.name.uk_UA}
                        />
                        <TextHalf
                            title="Адреса електронної пошти"
                            data={props.obj.value.buyers[0].contactPoint.email}
                        />
                        <TextHalf
                            title="Номер телефону"
                            data={props.obj.value.buyers[0].contactPoint.telephone}
                        />
                        {props.obj.value.buyers[0].contactPoint.hasOwnProperty('faxNumber') && props.obj.value.buyers[0].contactPoint.faxNumber !== null && props.obj.value.buyers[0].contactPoint.faxNumber !== ''
                            ? <TextHalf
                                title="Номер факсу"
                                data={props.obj.value.buyers[0].contactPoint.faxNumber}
                            />
                            : null
                        }
                        {props.obj.value.buyers[0].contactPoint.hasOwnProperty('url') && props.obj.value.buyers[0].contactPoint.url !== null && props.obj.value.buyers[0].contactPoint.url !== ''
                            ? <TextHalf
                                title="Веб адреса"
                                data={props.obj.value.buyers[0].contactPoint.url}
                            />
                            : null
                        }

                    </>
                )
            }
        }
    }
    return null
}

export default AwardElementTitleBidders
