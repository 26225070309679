import React from 'react';
import style from './sendAuction.module.css';

import {connect} from 'react-redux';

import {setPopUpSendAuction} from '../../../../redux/actions/auctions/auctions.js';

const PopUpSendAuction = (props) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.setPopUpSendAuction(null)
                        }
                    }
                >
                    X
                </div>
                <div className={style.popUpTitle}>Публікація аукціону</div>
                <div className={style.inputWrap}>
                    <div>Помилка публікації</div>
                </div>
                <div
                    className={style.popup_message_button}
                    onClick={
                        ()=>{
                            props.setPopUpSendAuction(null)
                        }
                    }
                >Закрити</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        popUpSendAuc: state.start.popUpSendAuc,
    }
}

const mapDispatchToProps = {
    setPopUpSendAuction
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpSendAuction)