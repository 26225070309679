import React, {useState} from 'react';
import styles from './viewAward.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../../redux/createAddress';
import PopDownBlock from '../../../elements/viewData/popdownBlock/popDownBlock';
import DocItem from '../../../documents/docItem';
import TextHalf from '../../../elements/viewData/textHalf';
import TextFull from '../../../elements/viewData/textFull';
import procedure from '../../../../redux/procedure';
import {checkProp} from '../../../../redux/check';
import {viewStringValue} from '../../../../api/valueToString.js'
import {getUpdateDataAuction, getUpdateDataBid} from "../../../product/updateData";

const ViewAward = (props) => {
    const [showDropDown, setShowDropDown] = useState(false)

    let bidValue = null
    let bidInitialValue = null
    let bidQuantity = null
    if (!procedure.isLLD(props.auction.sellingMethod)) {
        bidValue =
            <TextHalf title={'Цінова пропозиція' + procedure.isREM(props.auction.sellingMethod) ? ' за 1 кВт-год' : ''}
                      data={viewStringValue(props?.bid?.value)}/>
        bidInitialValue = props?.bid?.hasOwnProperty('initialValueAmount')
            ? props.bid.initialValueAmount !== null
                ? <TextHalf title='Закрита цінова пропозиція' data={getBidInitialValueAsString(props.bid)}/>
                : null
            : null
        if (procedure.isREM(props.auction.sellingMethod)) {
            if (props?.award)
            bidQuantity = props.bid?.quantity ?
                <TextHalf title='Розмір частки квоти в заяві' data={props.bid?.quantity + ' кВт'}/>
                : null
        }
    }
    return (
        <div className={styles.infoBlock}>
            <div className={styles.title_wrap}>
                <div
                    className={showDropDown ? styles.title_button + ' ' + styles.active : styles.title_button}
                    onClick={() => {
                        setShowDropDown(!showDropDown)
                    }}
                >Заява на участь
                </div>
            </div>
            <TextHalf title='Назва учасника' data={props.bid?.bidders[0]?.name?.uk_UA}/>
            <TextHalf title='Статус кваліфікації учасника аукціону'
                      data={props.showWinners ? statusUser(props) : statusUserAll(props, props.bid)}/>
            {bidValue}
            {bidInitialValue}
            {bidQuantity}
            <div className={styles.dropContent}>
                {showDropDown ? <div className={styles.dropContent}>
                    {renderIdentifierBlock(props.bid)}
                    {renderContactPointBlock(props.bid)}
                    {renderBidDocumentsBlock(props, props.bid)}
                    {renderAwardDocumentsBlock(props, props.award)}
                </div> : null
                }
            </div>
        </div>
    )
}

function dataECPMapper(data, document) {
    const result = data?.documents
        ?.filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === document.id
            })
    if (result?.length === 0) return null
    return result
}

function renderBidDocumentsBlock(props, bid) {
    let docScopeData = {
        aucType: props.auction.sellingMethod,
        historyUrl: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${bid.id}/documents`,
        aucId: props.auction.id,
        bidId: bid.id,
    }

    if (
        procedure.isREM(props.auction.sellingMethod)
        && procedure.isBidOwner(props.auction, bid)
        && procedure.getAwardByBid(props.auction.award, bid)?.status === 'verification'
    ) {
        docScopeData.typeOf = 'bid'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid.id}/documents`
        docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid.id}/documents`
        docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid.id}/documents`
    }

    let docArr = bid.documents
        .map(
            (document) => {
                return (
                    <DocItem
                        data={document}
                        scopeData={docScopeData}
                        dataECP={dataECPMapper(bid, document)}
                    />
                )
            }
        )

    return (
        <>
            <PopDownBlock
                title={`Документи заяви (${docArr?.length})`}
                content={docArr}
            />
        </>
    )
}
function renderAwardDocumentsBlock(props, award) {
    const docArr = award?.documents
        ?.map(
            (document) => {
                const getHistoryURL = (props, award) => {
                    if (document.documentOf === 'award') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/awards/${award.id}/documents`
                    }
                    if (document.documentOf === 'bid') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${award.bidId}/documents`
                    }
                }

                const docScopeData = {}
                docScopeData.aucId = props.auction.id
                docScopeData.aucStatus = props.auction.status
                docScopeData.aucType = props.auction.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auction.id)
                }
                docScopeData.aucOwner = props?.auction?.privateData?.isOwner
                docScopeData.historyUrl = getHistoryURL(props, award)


                docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
                // docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
                docScopeData.awardStatus = award.status
                docScopeData.docList = award.documents

                if (props?.auction?.privateData?.isOwner) {
                    docScopeData.typeOf = 'award'
                    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                }

                return (
                    <DocItem
                        data={document}
                        scopeData={docScopeData}
                        dataECP={dataECPMapper(award.documents, document)}
                        key={document.id}
                    />
                )
            }
        )
    if (docArr?.length) {
        return (
            <>
                <PopDownBlock
                    title={`Документи організатора (${docArr?.length})`}
                    content={docArr}
                />
            </>
        )
    }
    return null
}

function renderContactPointBlock(bid) {
    if (bid?.bidders?.length > 0 && checkProp(bid?.bidders[0], 'contactPoint')) {
        return (
            <PopDownBlock
                title={`Контактна особа`}
                content={
                    <>
                        <TextHalf title='ПІБ' data={bid.bidders[0].contactPoint.name.uk_UA}/>
                        <TextHalf title='Номер телефону' data={bid.bidders[0].contactPoint.telephone}/>
                        {checkProp(bid.bidders[0].contactPoint, 'faxNumber') ?
                            <TextHalf title='Номер факсу' data={bid.bidders[0].contactPoint.faxNumber}
                            /> : null}
                        {checkProp(bid.bidders[0].contactPoint, 'email') ?
                            <TextHalf title='Адреса електронної пошти'
                                      data={<a
                                          href={`mailto:${bid.bidders[0].contactPoint.email}`}>{bid.bidders[0].contactPoint.email}</a>}
                            /> : null}
                        {checkProp(bid.bidders[0].contactPoint, 'url') ?
                            <TextHalf title='Веб адреса'
                                      data={<a
                                          href={`${bid.bidders[0].contactPoint.url}`}>{bid.bidders[0].contactPoint.url}</a>}
                            /> : null}
                    </>
                }
            />
        )
    }
    return null
}

function renderIdentifierBlock(bid) {
    if (bid?.bidders[0]?.identifier !== null) {
        return (
            <PopDownBlock
                title={`Ідентифікатори організації або особи`}
                content={
                    <>
                        <TextFull title='Повна юридична назва організації'
                                  data={bid?.bidders[0]?.identifier?.legalName?.uk_UA}/>
                        <TextHalf
                            title={viewIdentifierType(bid?.bidders[0]?.identifier?.scheme) || 'Код ЄДРПОУ або ІПН або паспорт'}
                            data={bid?.bidders[0]?.identifier?.id}/>
                    </>
                }
            />
        )

    }
    return null
}

function getBidInitialValueAsString(data) {
    if (data === null) return 0
    if (data.initialValueAmount === null) return 0
    let tmpCost = {
        amount: data.initialValueAmount,
        currency: data?.value?.currency
    }
    return viewStringValue(tmpCost)
}

function statusUser(props) {
    return (props.award !== null) ? viewStatusAwards(props.award.status, props) : null
}

function statusUserAll(props, bid) {
    if (bid === null) return null
    if (props?.auction?.awards?.length > 0) {
        for (let tti = 0; tti < props.auction.awards.length; tti++) {
            if (bid.id === props.auction.awards[tti].bidId) {
                return viewStatusAwards(props.auction.awards[tti].status, props)
            }
        }
    }
    return 'Учасник не став переможцем'
}

//@todo Move to lib/helper
function viewStatusAwards(status, props) {
    switch (status) {
        case 'verification':
            return 'Перевірка документів'
        case 'waiting':
            return 'Документи перевірено'
        case 'pending':
            if (procedure.isNLE(props.auction.sellingMethod) || procedure.isNLD(props.auction.sellingMethod)) {
                return 'Очікується оплата'
            }
            return 'Очікується протокол'
        case 'pending_admission':
            switch (true) {
                case procedure.isREM(props.auction.sellingMethod):
                    return 'Підтвердження набуття статусу переможця'
                default:
                    return 'Очікується рішення про викуп'
            }

        case 'pending_waiting':
            return 'Очікується рішення'
        case 'pending_payment':
            return 'Очікується оплата'
        case 'protocol_signed':
            return 'Підписано протокол'
        case 'signed':
            if (procedure.isLAP(props.auction.sellingMethod)) {
                return 'Акт/договір підтверджено'
            }
            return 'Акт/договір підтверджено'
        case 'active':
            switch (true) {
                case procedure.isREM(props.auction.sellingMethod):
                    return 'Переможець. Договір підписано'
                case procedure.isNLE(props.auction.sellingMethod):
                case procedure.isNLD(props.auction.sellingMethod):
                    return 'Переможець'

                case procedure.isLAP(props.auction.sellingMethod):
                    return 'Переможець. Очікується акт/договір'

                case procedure.isAPE(props.auction.sellingMethod):
                case procedure.isAPD(props.auction.sellingMethod):
                    return 'Переможець. Очікується акт/договір та оплата'
                default:
                    return 'Переможець. Очікується договір'
            }
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
        case 'rejected':
            return 'Відхилено'
        default:
            return 'Учасник не став переможцем'
    }
}

//@todo use dict
function viewIdentifierType(a) {
    if (a !== null && a !== undefined) {
        switch (a) {
            case 'UA-EDR':
                return 'ЄДРПОУ'
            case 'UA-IPN':
                return 'ІПН/РНОКПП'
            case 'UA-PASSPORT':
                return 'Паспорт'
            case 'UA-ID-CARD':
                return 'ID-карта'
            case 'UA-IPN-FOP':
                return 'ІПН/РНОКПП'
            default:
                return null
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionAwards: state.start.auctionAwards,
        auction: state.start.auctionFull,
        auctionsAllBids: state.start.auctionsAllBids,
        token: state.start.token,
        arrPrivatLink: state.start.arrPrivatLink,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAward)