//import {createBodyTIE} from './createBodyTIE'
//import {createBodySUE} from './createBodySUE'
//import {createBodySUD} from './createBodySUD'
//import {createBodyRCE} from './createBodyRCE'
//import {createBodyRCD} from './createBodyRCD'
//import {createBodyLRE} from './createBodyLRE'
//import {createBodyLSE} from './createBodyLSE'
//import {createBodyLSP} from './createBodyLSP'
import {createBodyLRE} from './createBodyLRE'
import {createBodyLSE} from './createBodyLSE'
import {createBodyLSP} from './createBodyLSP'

import {createBodyLLE} from './createBodyLLE'
import {createBodyLLD} from './createBodyLLD'
import {createBodyLLP} from './createBodyLLP'

import {createBodyBSE} from './createBodyBSE'
import {createBodyBSD} from './createBodyBSD'
import {createBodyALE} from './createBodyALE'
import {createBodyCSE} from './createBodyCSE'
import {createBodyCSD} from './createBodyCSD'
import {createBodyBRE} from './createBodyBRE'
import {createBodyBRD} from './createBodyBRD'
import {createBodyBRW} from './createBodyBRW'

import {createBodyRLE} from './createBodyRLE'
import {createBodyRLD} from './createBodyRLD'

import {createBodySPE} from './createBodySPE'
import {createBodySPD} from './createBodySPD'

import {createBodyNLE} from './createBodyNLE'
import {createBodyNLD} from './createBodyNLD'

import {createBodyLAE} from './createBodyLAE'
import {createBodyLAW} from './createBodyLAW'
import {createBodyLAP, createBodyLAPCT} from './createBodyLAP'

import {createBodyLPE} from './createBodyLPE'
import {createBodyAPE} from './createBodyAPE'
import {createBodyAPD} from './createBodyAPD'


import { isBSE, isBSD, isALE,
    isBRD, isBRE, isBRW,
    isCSD, isCSE, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD,
    isLLE, isLLD, isLLP,
    isLRE, isLSE, isLSP
} from '../../../../../../redux/procedure'
import {createBodyRMA} from "../../../createEditAndCloneAuction/createBodyPostAndPatch/createBodyRMA";

export function createBody(data, oldData = null, auction){
    switch(true){
        case isLRE(data.base.sellingMethod):
            return createBodyLRE(data)
        case isLSE(data.base.sellingMethod):
            return createBodyLSE(data)
        case isLSP(data.base.sellingMethod):
            return createBodyLSP(data)
        case isLLE(data.base.sellingMethod):
            return createBodyLLE(data)
        case isLLD(data.base.sellingMethod):
            return createBodyLLD(data)
        case isLLP(data.base.sellingMethod):
            return createBodyLLP(data)
        case isBSE(data.base.sellingMethod):
            return createBodyBSE(data)
        case isBSD(data.base.sellingMethod):
            return createBodyBSD(data)
        case isALE(data.base.sellingMethod):
            return createBodyALE(data)
        case isCSE(data.base.sellingMethod):
            return createBodyCSE(data)
        case isCSD(data.base.sellingMethod):
            return createBodyCSD(data)
        case isBRE(data.base.sellingMethod):
            return createBodyBRE(data)
        case isBRW(data.base.sellingMethod):
            return createBodyBRW(data, oldData)
        case isBRD(data.base.sellingMethod):
            return createBodyBRD(data, oldData)
        case isRLE(data.base.sellingMethod):
        case isCLE(data.base.sellingMethod):
            return createBodyRLE(data)
        case isRLD(data.base.sellingMethod):
        case isCLD(data.base.sellingMethod):
            return createBodyRLD(data)
        case isSPE(data.base.sellingMethod):
            return createBodySPE(data, oldData)
        case isSPD(data.base.sellingMethod):
            return createBodySPD(data, oldData)
        case isNLE(data.base.sellingMethod):
            return createBodyNLE(data)
        case isNLD(data.base.sellingMethod):
            return createBodyNLD(data)
        case isLAE(data.base.sellingMethod):
            return createBodyLAE(data)
        case isLAW(data.base.sellingMethod):
            return createBodyLAW(data)
        case isLAP(data.base.sellingMethod):
            return createBodyLAP(data)
        case isLPE(data.base.sellingMethod):
            return createBodyLPE(data)
        case isAPE(data.base.sellingMethod):
            return createBodyAPE(data, auction)
        case isAPD(data.base.sellingMethod):
            return createBodyAPD(data, auction)
        case data.base?.sellingMethod.startsWith("renewables-multiAwards"):
            return createBodyRMA(data)
        default:
            return data.body
    }
}
export function createBodyCT(data, oldData = null){
    switch(true){
        case isLRE(data.base.sellingMethod):
            return createBodyLRE(data)
        case isLLE(data.base.sellingMethod):
            return createBodyLLE(data)
        case isLLD(data.base.sellingMethod):
            return createBodyLLD(data)
        case isLLP(data.base.sellingMethod):
            return createBodyLLP(data)
        case isBSE(data.base.sellingMethod):
            return createBodyBSE(data)
        case isBSD(data.base.sellingMethod):
            return createBodyBSD(data)
        case isALE(data.base.sellingMethod):
            return createBodyALE(data)
        case isCSE(data.base.sellingMethod):
            return createBodyCSE(data)
        case isCSD(data.base.sellingMethod):
            return createBodyCSD(data)
        case isBRE(data.base.sellingMethod):
            return createBodyBRE(data)
        case isBRW(data.base.sellingMethod):
            return createBodyBRW(data, oldData)
        case isBRD(data.base.sellingMethod):
            return createBodyBRD(data, oldData)
        case isRLE(data.base.sellingMethod):
        case isCLE(data.base.sellingMethod):
            return createBodyRLE(data)
        case isRLD(data.base.sellingMethod):
        case isCLD(data.base.sellingMethod):
            return createBodyRLD(data)
        case isSPE(data.base.sellingMethod):
            return createBodySPE(data, oldData)
        case isSPD(data.base.sellingMethod):
            return createBodySPD(data, oldData)
        case isNLE(data.base.sellingMethod):
            return createBodyNLE(data)
        case isNLD(data.base.sellingMethod):
            return createBodyNLD(data)
        case isLAE(data.base.sellingMethod):
            return createBodyLAE(data)
        case isLAW(data.base.sellingMethod):
            return createBodyLAW(data)
        case isLAP(data.base.sellingMethod):
            return createBodyLAPCT(data)
        case isLPE(data.base.sellingMethod):
            return createBodyLPE(data)
        case isAPE(data.base.sellingMethod):
            return createBodyAPE(data)
        case isAPD(data.base.sellingMethod):
            return createBodyAPD(data)
        default:
            return data.body
    }
}