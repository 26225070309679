import React from 'react';
import styles from './legitimatePropertyLease.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {
    auctionsSearch,
    auctionsSearchCount,
    changeCurentPage,
} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeMyCurentPage,
} from '../../../../redux/actions.js';
import {
    setDataAddress,
} from '../../../../redux/createAddress';


const Wood = (props) => {
    //return null
    let active = null;
    if(props.search.category === 'legitimatePropertyLease'){
        active = styles.active;
    }
    return (
        <div
            className={styles.aukpoisk_second_elem}
        >
            <Link
                onClick={
                    ()=>{
                        additionalClassificationZU(props)
                        classificationZU(props)
                        props.changeMyCurentPage(0)
                        props.setAuctionCategory('legitimatePropertyLease');
                        props.changeCurentPage(0);
                        let dataSearch = {}
                        dataSearch.order = props.search.order
                        dataSearch.region = props.search.region
                        dataSearch.category = 'legitimatePropertyLease'
                        dataSearch.priceCurrency = props.search.priceCurrency
                        dataSearch.priceFrom = props.search.priceFrom
                        dataSearch.priceTo = props.search.priceTo
                        dataSearch.measures = props.search.measures
                        dataSearch.measuresFrom = props.search.measuresFrom
                        dataSearch.measuresTo = props.search.measuresTo
                        if(props.search.status === null || props.search.status === ''){
                            dataSearch.status = null
                        }else{
                            dataSearch.status = props.search.status
                        }
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: props.langFlag,
                            params: '?offset=0'
                        }
                        if(props.token !== null){
                            let header = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            props.auctionsSearch(data, header, dataSearch);
                        }else{
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            props.auctionsSearch(data, header, dataSearch);
                        }
                        props.clearSearch()
                    }
                }
                to={`${props.aucyionsPath}/legitimatePropertyLease${getUrlFiltersParam(props.search, 'legitimatePropertyLease')}`}
            >
                <div className={styles.base + ' ' + active}>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 171.439 171.439" xmlSpace="preserve">
<path d="M21.803,171.439c-0.463,0-0.922-0.129-1.322-0.378c-0.563-0.351-0.963-0.91-1.113-1.557L1.182,91.241
	c-0.313-1.345,0.524-2.688,1.869-3.001l6.729-1.563l76.692-64.254l-4.435-4.012c-1.514,0.934-3.295,1.472-5.2,1.472
	c-5.482,0-9.941-4.46-9.941-9.941S71.355,0,76.837,0s9.941,4.46,9.941,9.941c0,1.766-0.463,3.426-1.273,4.864l4.837,4.375
	l1.117-0.936c0.902-0.757,2.21-0.781,3.141-0.058l48.281,37.561l6.189-1.438c1.347-0.309,2.688,0.525,3.001,1.87l18.187,78.263
	c0.313,1.345-0.524,2.688-1.869,3.001L22.369,171.375C22.182,171.418,21.992,171.439,21.803,171.439z M6.618,92.545l17.054,73.394
	l141.149-32.8l-17.055-73.393l-4.879,1.134c-0.022,0.006-0.045,0.011-0.068,0.016L11.556,91.397
	c-0.041,0.011-0.082,0.021-0.124,0.029L6.618,92.545z M90.216,25.809L20.553,84.174L136.61,57.205L96.188,25.759
	c-0.038,0.539-0.25,1.07-0.64,1.502c-0.925,1.024-2.506,1.104-3.531,0.178L90.216,25.809z M76.837,5
	c-2.725,0-4.941,2.217-4.941,4.941s2.217,4.941,4.941,4.941c1.361,0,2.596-0.554,3.491-1.447c0.026-0.027,0.052-0.055,0.079-0.081
	c0.849-0.888,1.372-2.091,1.372-3.413C81.778,7.217,79.562,5,76.837,5z M40.256,140.785c-1.135,0-2.162-0.778-2.432-1.931
	l-6.447-27.504c-0.315-1.344,0.519-2.689,1.864-3.004c0.829-0.194,1.659,0.048,2.247,0.579c1.199-1.163,2.694-1.983,4.362-2.374
	c2.429-0.569,4.937-0.16,7.058,1.156c2.121,1.315,3.603,3.378,4.173,5.809c1.176,5.016-1.949,10.055-6.965,11.23
	c-0.386,0.091-0.773,0.156-1.158,0.196l12.745,7.904c1.173,0.729,1.534,2.27,0.807,3.442c-0.728,1.174-2.268,1.533-3.442,0.808
	l-12.309-7.635l1.934,8.251c0.315,1.344-0.52,2.689-1.864,3.004C40.637,140.763,40.445,140.785,40.256,140.785z M41.992,111.303
	c-0.333,0-0.668,0.039-1,0.116c-1.13,0.266-2.089,0.954-2.701,1.94c-0.612,0.986-0.803,2.151-0.538,3.281
	c0.546,2.331,2.888,3.778,5.222,3.239c2.332-0.548,3.785-2.89,3.238-5.223c-0.265-1.13-0.954-2.089-1.94-2.7
	C43.577,111.525,42.792,111.303,41.992,111.303z M67.849,133.016c-1.135,0-2.162-0.778-2.432-1.931l-2.969-12.666
	c-0.003-0.011-0.005-0.021-0.008-0.033c-0.002-0.011-0.005-0.021-0.007-0.033l-3.14-13.396c0-0.002-0.001-0.005-0.002-0.008
	c-0.315-1.344,0.52-2.689,1.864-3.004L76,98.466c1.343-0.315,2.689,0.52,3.004,1.863s-0.52,2.689-1.864,3.004l-12.411,2.909
	l2.009,8.568l7.462-1.749c1.344-0.316,2.69,0.52,3.005,1.863c0.315,1.344-0.519,2.689-1.863,3.004l-7.463,1.75l1.835,7.831
	l12.412-2.908c1.343-0.315,2.689,0.52,3.004,1.863s-0.52,2.689-1.864,3.004l-14.838,3.479c-0.002,0-0.005,0.001-0.007,0.001
	C68.23,132.993,68.038,133.016,67.849,133.016z M93.49,127.005c-1.135,0-2.162-0.778-2.432-1.931l-6.126-26.136
	c-0.219-0.933,0.116-1.865,0.791-2.455c0.929-1.001,2.494-1.075,3.51-0.155l18.833,17.021l-4.407-18.801
	c-0.315-1.344,0.519-2.689,1.863-3.004c1.346-0.315,2.69,0.52,3.005,1.863l6.126,26.136c0.218,0.93-0.115,1.861-0.786,2.45
	c-0.927,1.009-2.495,1.083-3.515,0.161L91.52,105.135l4.406,18.799c0.315,1.344-0.519,2.689-1.864,3.004
	C93.871,126.982,93.679,127.005,93.49,127.005z M131.954,117.989c-1.135,0-2.162-0.778-2.432-1.931l-5.556-23.702l-6.93,1.624
	c-1.344,0.313-2.69-0.52-3.004-1.863c-0.315-1.344,0.519-2.689,1.864-3.004l18.727-4.39c1.344-0.316,2.689,0.52,3.004,1.863
	c0.315,1.344-0.519,2.689-1.864,3.004l-6.929,1.625l5.556,23.702c0.315,1.344-0.519,2.689-1.863,3.004
	C132.335,117.967,132.143,117.989,131.954,117.989z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
                </div>
                <div className={styles.aukpoisk_second_elem_text}>Оренда державного/комунального майна</div>
            </Link>
            <div className={styles.aukpoisk_second_elem_1}>
                <Link to={`${props.aucyionsPath}/legitimatePropertyLease-english${getUrlFiltersParam(props.search, 'legitimatePropertyLease-english')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                additionalClassificationZU(props)
                                classificationZU(props)
                                props.changeMyCurentPage(0)
                                props.setAuctionCategory('legitimatePropertyLease-english');
                                props.changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = props.search.order
                                dataSearch.region = props.search.region
                                dataSearch.category = 'legitimatePropertyLease-english'
                                dataSearch.priceCurrency = props.search.priceCurrency
                                dataSearch.priceFrom = props.search.priceFrom
                                dataSearch.priceTo = props.search.priceTo
                                dataSearch.measures = props.search.measures
                                dataSearch.measuresFrom = props.search.measuresFrom
                                dataSearch.measuresTo = props.search.measuresTo
                                if(props.search.status === null || props.search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = props.search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: props.langFlag,
                                    params: '?offset=0'
                                }
                                if(props.token !== null){
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }
                                props.clearSearch()
                            }
                        }
                    >
                        Оренда державного/комунального майна. Англієць
                    </div>
                </Link>
                <Link to={`${props.aucyionsPath}/legitimatePropertyLease-dutch${getUrlFiltersParam(props.search, 'legitimatePropertyLease-dutch')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                additionalClassificationZU(props)
                                classificationZU(props)
                                changeMyCurentPage(0)
                                setAuctionCategory('legitimatePropertyLease-dutch');
                                changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = props.search.order
                                dataSearch.region = props.search.region
                                dataSearch.category = 'legitimatePropertyLease-dutch'
                                dataSearch.priceCurrency = props.search.priceCurrency
                                dataSearch.priceFrom = props.search.priceFrom
                                dataSearch.priceTo = props.search.priceTo
                                dataSearch.measures = props.search.measures
                                dataSearch.measuresFrom = props.search.measuresFrom
                                dataSearch.measuresTo = props.search.measuresTo
                                if(props.search.status === null || props.search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = props.search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: props.langFlag,
                                    params: '?offset=0'
                                }
                                if(props.token !== null){
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }
                                props.clearSearch()
                            }
                        }
                    >
                        Оренда державного/комунального майна з покроковим зниженням
                    </div>
                </Link>
                <Link to={`${props.aucyionsPath}/legitimatePropertyLease-priorityEnglish${getUrlFiltersParam(props.search, 'legitimatePropertyLease-priorityEnglish')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                additionalClassificationZU(props)
                                classificationZU(props)
                                props.changeMyCurentPage(0)
                                props.setAuctionCategory('legitimatePropertyLease-priorityEnglish');
                                props.changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = props.search.order
                                dataSearch.region = props.search.region
                                dataSearch.category = 'legitimatePropertyLease-priorityEnglish'
                                dataSearch.priceCurrency = props.search.priceCurrency
                                dataSearch.priceFrom = props.search.priceFrom
                                dataSearch.priceTo = props.search.priceTo
                                dataSearch.measures = props.search.measures
                                dataSearch.measuresFrom = props.search.measuresFrom
                                dataSearch.measuresTo = props.search.measuresTo
                                if(props.search.status === null || props.search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = props.search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: props.langFlag,
                                    params: '?offset=0'
                                }
                                if(props.token !== null){
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsSearch(data, header, dataSearch);
                                }
                                props.clearSearch()
                            }
                        }
                    >
                        Оренда державного/комунального майна з використанням переважного права
                    </div>
                </Link>
            </div>
        </div>
    )
}

async function classificationZU(props){
    //let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16000000-5')
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '16')
    //let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30000000-9'))
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '30'))
    //let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32000000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '32'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    //let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '340'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '342'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '343'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '348'))
    let tmpArr8 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '349'))
        //let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
        //let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
        //let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
        //let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
        //let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    //let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39000000-2'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '39'))
    //let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42000000-6'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '42'))
    //let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18000000-9'))
    let tmpArr11 = await tmpArr10.concat(await getDictionaryJointPropertyComplex(props, '18'))
    //let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19000000-6'))
    let tmpArr12 = await tmpArr11.concat(await getDictionaryJointPropertyComplex(props, '19'))
    //let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22000000-0'))
    let tmpArr13 = await tmpArr12.concat(await getDictionaryJointPropertyComplex(props, '22'))
    //let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31000000-6'))
    let tmpArr14 = await tmpArr13.concat(await getDictionaryJointPropertyComplex(props, '31'))
    //let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33000000-0'))
    let tmpArr15 = await tmpArr14.concat(await getDictionaryJointPropertyComplex(props, '33'))
    //let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35000000-4'))
    let tmpArr16 = await tmpArr15.concat(await getDictionaryJointPropertyComplex(props, '35'))
    //let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37000000-8'))
    let tmpArr17 = await tmpArr16.concat(await getDictionaryJointPropertyComplex(props, '37'))
    //let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38000000-5'))
    let tmpArr18 = await tmpArr17.concat(await getDictionaryJointPropertyComplex(props, '38'))
    //let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43000000-3'))
    let tmpArr19 = await tmpArr18.concat(await getDictionaryJointPropertyComplex(props, '43'))
    //let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44000000-0'))
    let tmpArr20 = await tmpArr19.concat(await getDictionaryJointPropertyComplex(props, '44'))
    //let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48000000-8'))
    let tmpArr21 = await tmpArr20.concat(await getDictionaryJointPropertyComplex(props, '48'))


    let tmpArr22 = await tmpArr21.concat(getDictionaryJointPropertyComplex(props, '07'))
    let tmpArr23 = await tmpArr22.concat(await getDictionaryJointPropertyComplex(props, '08'))
    let tmpArr24 = await tmpArr23.concat(await getDictionaryJointPropertyComplex(props, '34000000-7'))
    let tmpArr25 = await tmpArr24.concat(await getDictionaryJointPropertyComplex(props, '34100000-8'))
    let tmpArr26 = await tmpArr25.concat(await getDictionaryJointPropertyComplex(props, '34400000-1'))
    let tmpArr27 = await tmpArr26.concat(await getDictionaryJointPropertyComplex(props, '34500000-2'))
    let tmpArr28 = await tmpArr27.concat(await getDictionaryJointPropertyComplex(props, '34600000-3'))
    let tmpArr29 = await tmpArr28.concat(await getDictionaryJointPropertyComplex(props, '34700000-4'))
    let tmpArr30 = await tmpArr29.concat(await getDictionaryJointPropertyComplex(props, '04'))
    props.setDataAddress(tmpArr30, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}
async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}
async function additionalClassificationZU(props){
    let tmpArr = []
    tmpArr[0] = await getAdditionalClassificationZU(props, 'QB29-3')
    tmpArr[1] = await getAdditionalClassificationZU(props, 'PA01-7')
    props.setDataAddress(tmpArr, 'SET_CLASSIFICATIONS_ZU_ADD')
}
async function getAdditionalClassificationZU(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/keyword?keyword=${mask}&scheme=CPVS&limit=100`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wood)