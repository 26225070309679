import React from 'react';

import {connect} from 'react-redux';

import {
    changeCreateAuctionDutchStepQuantity,
    changeCreateAuctionDutchStepPercent,
    changeCreateAuctionDutchStepValueAmount,
} from '../../../redux/actions.js';

import Select from '../../elements/inputs/inputSelect';
import InputAmount from '../../elements/inputs/inputAmount';
import InputText from '../../elements/inputs/input';

import {disabledElement} from '../disabled'
import { getOptions } from '../../../redux/check';

const WindowAuctionDutchSteps = ( props ) => {
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        //return null
    }



    let tmpDutchStepQuantity = null
    if(props.auctionsMy.hasOwnProperty('dutchStep') === true){
        if(props.auctionsMy.dutchStep !== null){
            if(props.auctionsMy.dutchStep.hasOwnProperty('dutchStepQuantity') === true){
                if(
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
                    props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                    props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                    props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod'
                ){
                    if(props.auctionsMy.dutchStep.dutchStepQuantity === null){
                        tmpDutchStepQuantity = 99
                        props.changeCreateAuctionDutchStepQuantity(99)
                    }else{
                        tmpDutchStepQuantity = props.auctionsMy.dutchStep.dutchStepQuantity
                    }
                }else{
                    if(props.auctionsMy.dutchStep.dutchStepQuantity !== null){
                        tmpDutchStepQuantity = props.auctionsMy.dutchStep.dutchStepQuantity
                    }
                }
            }
        }else{
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod'
            ){
                tmpDutchStepQuantity = 99
                props.changeCreateAuctionDutchStepQuantity(99)
            }
        }
    }
    let tmpDutchStepDutchStepValueCurrency = null
    if(props.auctionsMy.hasOwnProperty('dutchStep') === true){
        if(props.auctionsMy.dutchStep !== null){
            if(props.auctionsMy.dutchStep.hasOwnProperty('dutchStepValue') === true){
                if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                    if(props.auctionsMy.dutchStep.dutchStepValue.hasOwnProperty('currency') === true){
                        if(props.auctionsMy.dutchStep.dutchStepValue.currency !== null){
                            tmpDutchStepDutchStepValueCurrency = props.auctionsMy.dutchStep.dutchStepValue.currency
                        }
                    }
                }
            }
        }
    }
    let tmpDutchStepPercent = null
    let tmpDutchStepDutchStepValueAmount = null
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    ){
        tmpDutchStepPercent = 1
        //tmpDutchStepDutchStepValueAmount = props.auctionsMy.value.amount/100
        if(props.auctionsMy.hasOwnProperty('dutchStep') === true){
            if(props.auctionsMy.dutchStep !== null){
                if(props.auctionsMy.dutchStep.hasOwnProperty('dutchStepValue') === true){
                    if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                        if(props.auctionsMy.dutchStep.dutchStepValue.hasOwnProperty('amount') === true){
                            if(props.auctionsMy.dutchStep.dutchStepValue.amount !== null){
                                tmpDutchStepDutchStepValueAmount = props.auctionsMy.dutchStep.dutchStepValue.amount
                            }
                        }
                    }
                }
            }
        }
    }else{
        if(props.auctionsMy.hasOwnProperty('dutchStep') === true){
            if(props.auctionsMy.dutchStep !== null){
                if(props.auctionsMy.dutchStep.hasOwnProperty('dutchStepPercent') === true){
                    if(props.auctionsMy.dutchStep.dutchStepQuantity !== null){
                        tmpDutchStepPercent = props.auctionsMy.dutchStep.dutchStepPercent
                    }
                }
            }
            if(props.auctionsMy.dutchStep !== null){
                if(props.auctionsMy.dutchStep.hasOwnProperty('dutchStepValue') === true){
                    if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                        if(props.auctionsMy.dutchStep.dutchStepValue.hasOwnProperty('amount') === true){
                            if(props.auctionsMy.dutchStep.dutchStepValue.amount !== null){
                                tmpDutchStepDutchStepValueAmount = props.auctionsMy.dutchStep.dutchStepValue.amount
                            }
                        }
                    }
                }
            }
        }
    }
    
    return (
            <>
                <Select
                    data={{
                        label:"Кількість кроків",
                        target: tmpDutchStepQuantity,
                        // info: 'оберіть тип процедури, за яким проходитиме аукціон'
                    }}
                    onChange={(event)=>{
                                props.changeCreateAuctionDutchStepQuantity(+event)
                            }}
                    options={getOptions(viewOptionDutchStepQuantity(props), 1)}
                    descr='Оберіть кількість кроків'
                    disabled={disabledElement(props.auctionsMy, '00')}
                />
                <InputText
                    disabled={disabledElement(props.auctionsMy, '11')}
                    type='number'
                    label={'Відсоток зниження, %'}
                    value={tmpDutchStepPercent}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionDutchStepPercent(e)
                        }
                    }
                    required
                />
                <InputAmount
                    title='Розмір кроку голландського раунду, грн'
                    data={{
                        amount: tmpDutchStepDutchStepValueAmount,
                        currency: tmpDutchStepDutchStepValueCurrency
                    }}
                    onChange={(e)=> props.changeCreateAuctionDutchStepValueAmount(e.amount)}
                />
            </>
        )
}


function viewOptionDutchStepQuantity(){
    let tmp = []
    for(let i = 0; i < 99; i++){
        tmp[i] = <option key={i} value={i+1}>{i+1}</option>
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionDutchStepQuantity,
    changeCreateAuctionDutchStepPercent,
    changeCreateAuctionDutchStepValueAmount,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDutchSteps)
