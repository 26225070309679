import React, {useEffect} from 'react';
import style from './popupAward.module.css';

import {connect} from 'react-redux';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,
    changeAwardRejectRestrict,
} from '../../../redux/actions/awards/awards.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ChansellWaitingAward from './buttonsBlockChansellWaitingAuction.js';

import {setDataAddress} from '../../../redux/createAddress';
import {getUpdateDataAuction} from '../../product/updateData'

import procedure from '../../../redux/procedure';

import {getDefaultHeaders} from "../../_api/bidApi";

const ApproveOrRejectAward = ( props ) => {
    useEffect(() => {
        if(procedure.isLAE(props.auctions.sellingMethod) || procedure.isLAW(props.auctions.sellingMethod) || procedure.isLAP(props.auctions.sellingMethod)){
            props.changeAwardRejectRestrict(props.popUpAwardReject.terminationReason)
            
        }    
    }, [])
    

    let tmp = null

                if(props?.auctions?.awards?.length > 0){
                    if(props.auctions.privateData.bids !== null && props.auctions.privateData.bids.length > 0){
                        if(props.auctions.awards[props.popUpAwardReject.awards].bidId === props.auctions.privateData.bids[0].bidId){
                            if(props.auctions.awards[props.popUpAwardReject.awards].status === 'pending_waiting'||props.auctions.awards[props.popUpAwardReject.awards].status === 'pending_admission'){
                                tmp = <ChansellWaitingAward />
                            }
                        }
                    }
                    let tmpTextBuyerReject = false
                    let tmpTextBuyerApprove = false                    
                        if(props?.auctions?.awards[props.popUpAwardReject.awards]?.documents?.length > 0){
                            for(let ty = 0; ty < props.auctions.awards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctions?.privateData?.isOwner === true ||  props?.userProfiles?.isAdmin === true){
                                    if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'auctionProtocol'){
                                        tmpTextBuyerApprove = true
                                    }
                                    if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'act' || props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'rejectionProtocol'){
                                        tmpTextBuyerReject = true
                                    }
                                }
                            }                        
                    }
                    if(props?.auctions?.privateData?.isOwner === true || props?.userProfiles?.isAdmin === true){
                        let buttonApprove = null
                        let buttonReject = null
                        if(tmpTextBuyerApprove === true || procedure.isBRW(props.auctions.sellingMethod)){
                            if(procedure.isLPE(props.auctions.sellingMethod)){
                                if(props.popUpAwardReject.status !== "pending_payment") buttonApprove = buttonApproveAuctionAwards(props)
                            }else{
                                buttonApprove = buttonApproveAuctionAwards(props)
                            }
                        }

                        switch(true){
                            case procedure.isRCE(props.auctions.sellingMethod):
                            case procedure.isRCD(props.auctions.sellingMethod):
                                buttonApprove =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            approveWinerAward(props)
                                        }
                                    }
                                >
                                    Протокол затверджено
                                </div>
                                break;
                            default:
                                break;
                        }
                        tmpTextBuyerReject = statusNullIfStatusAwardCancelledOrunsuccessful(props, tmpTextBuyerReject)
                        if(tmpTextBuyerReject === true){
                            let formName = "Оберіть причину дискваліфікації або відхилення:"
                            if(procedure.isAPE(props.auctions.sellingMethod) || procedure.isAPD(props.auctions.sellingMethod)) formName = "Оберіть причину дискваліфікації/відмови учаснику:"
                            buttonReject = <>
                                <div className={style.rejectSelect}>{formName}</div>
                                <div className={style.selectWrap}>
                                    <select
                                        className={style.selectTypeFile}
                                        value={props.awardReject.restrict}
                                        placeholder='Причина діскваліфікації'
                                        onChange={
                                            (event)=>{
                                                props.changeAwardRejectRestrict(event.target.value)
                                            }
                                        }
                                    >
                                        {reasonOption(props)}
                                    </select>
                                </div>
                                {buttonRejectZemlyaSave(props)}
                                {buttonRejectZemlya(props)}
                            </>
                        }
                        tmp = <div className={style.bottomPart}>
                            {buttonReject}
                            {buttonApprove}
                        </div>
                    }
                }

    return <>
            {tmp}
        </>
}

//@todo: use dictionaries from api
function reasonOption(props){
    switch (true) {
        case procedure.isBSE(props.auctions.sellingMethod):
        case procedure.isBSD(props.auctions.sellingMethod):
        case procedure.isALE(props.auctions.sellingMethod):
        case procedure.isCSE(props.auctions.sellingMethod):
        case procedure.isCSD(props.auctions.sellingMethod):
        case procedure.isRLE(props.auctions.sellingMethod):
        case procedure.isRLD(props.auctions.sellingMethod):
        case procedure.isCLE(props.auctions.sellingMethod):
        case procedure.isCLD(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Відмова або непідписання протоколу</option>
                    <option value='2'>Відмова або непідписання договору</option>
                    <option value='3'>Відсутні обов’язкові документи</option>
                    <option value='4'>Невнесення передоплати або оплати за договором</option>
                    <option value='5'>Участь в аукціоні з більше, ніж 1 майданчика</option>
                </>
            )
        case procedure.isSPE(props.auctions.sellingMethod):
        case procedure.isSPD(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Не відповідає вимогам статті 8 ЗУ "Про приватизацію державного і комунального
                        майна"
                    </option>
                    <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено ЗУ "Про
                        приватизацію державного і комунального майна"
                    </option>
                    <option value='3'>Подав неправдиві відомості про себе</option>
                    <option value='4'>Відмовився від підписання протоколу про результати електронного аукціону</option>
                    <option value='5'>Відмовився від укладення договору</option>
                    <option value='6'>Відмовився від підписання протоколу аукціону або договору купівлі-продажу в
                        аукціоні з продажу того самого об’єкта приватизації, що підтверджується відповідним актом"
                    </option>
                    <option value='7'>Не сплатив ціну продажу об'єкта приватизації у встановлений строк</option>
                    <option value='8'>Не сплатив ціну продажу об’єкта приватизації у встановлений строк щодо того самого
                        об’єкта приватизації, що підтверджується відповідним актом
                    </option>
                </>
            )
        case procedure.isBRE(props.auctions.sellingMethod):
        case procedure.isBRD(props.auctions.sellingMethod):
        case procedure.isBRW(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Відмова або непідписання протоколу</option>
                    <option value='2'>Відмова або непідписання акту</option>
                    <option value='3'>Невнесення оплати за лот</option>
                </>
            )
        case procedure.isLLE(props.auctions.sellingMethod):
        case procedure.isLLD(props.auctions.sellingMethod):
        case procedure.isLLP(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Непідписання протоколу</option>
                    <option value='2'>Непідписання договору учасником</option>
                    <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                    <option value='4'>Неподання обов'язкових документів або відомостей</option>
                    <option value='5'>Подання неправдивих відомостей</option>
                    <option value='6'>Судові рішення та інші підстави</option>
                    <option value='7'>Непідписання договору орендодавцем</option>
                </>
            )
        case procedure.isNLE(props.auctions.sellingMethod):
        case procedure.isNLD(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Відмовився від підписання договору/протоколу</option>
                    <option value='2'>Переможець аукціону є боржником та/або поручителем за кредитними договорами та
                        договорами забезпечення виконання зобов'язань
                    </option>
                    <option value='3'>Свідомо надав неправдиву інформацію</option>
                    <option value='4'>Повна оплата коштів за лот не здійснена в строк/відмовився від оплати</option>
                    <option value='5'>Переможець аукціону брав участь в аукціонах з кількох майданчиків</option>
                    <option value='6'>Інше</option>
                </>
            )
        case procedure.isLAE(props.auctions.sellingMethod):
        case procedure.isLAW(props.auctions.sellingMethod):
        case procedure.isLAP(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Відмовився від підписання протоколу</option>
                    <option value='2'>Відмовився від укладання акту/договору</option>
                    <option value='3'>Не сплатив належну суму за придбаний лот та/або суму забезпечувального платежу
                    </option>
                    <option value='4'>Організатор не підписав протокол</option>
                    <option value='5'>Організатор не підписав акт/договір</option>
                </>
            )
        case procedure.isLPE(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Не відповідає вимогам статті 8ЗУ "Про приватизацію державного і комунального
                        майна"
                    </option>
                    <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено ЗУ "Про
                        приватизацію державного і комунального майна"
                    </option>
                    <option value='3'>Подав неправдиві відомості про себе</option>
                    <option value='4'>Відмовився від підписання договору або протоколу про результати електронного
                        аукціону
                    </option>
                    <option value='5'>Відмовився від підписання протоколу аукціону або договору купівлі-продажу в
                        аукціоні з продажу того самого об’єкта приватизації, що підтверджується відповідним актом
                    </option>
                    <option value='6'>Є кінцевим бенефіціарним власником учасника, що відмовився від підписання
                        протоколу аукціону або договору в аукціоні з продажу того самого об’єкта приватизації, що
                        підтверджується відповідним актом
                    </option>
                    <option value='7'>Не сплатив ціну продажу об’єкта приватизації у встановлений строк щодо того самого
                        об’єкта приватизації, що підтверджується відповідним актом
                    </option>
                    <option value='8'>Є пов’язаною особою з іншим потенційним покупцем, який брав участь в аукціоні
                    </option>
                </>
            )
        case procedure.isAPE(props.auctions.sellingMethod):
        case procedure.isAPD(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Є працівником організатора торгів</option>
                    <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено цим
                        Порядком
                    </option>
                    <option value='3'>Подав неправдиві відомості про себе</option>
                    <option value='4'>Вже перемагав у попередніх аукціонах по цьому лоту, де не підписав протокол чи
                        договір, не сплатив за лот вчасно або не в повному обсязі
                    </option>
                    <option value='5'>Не може бути покупцем згідно з пунктом 12 цього Порядку</option>
                    <option value='6'>Відмовився від підписання протоколу</option>
                    <option value='7'>Відмовився від підписання договору</option>
                    <option value='8'>Не сплатив ціну продажу лота у необхідні строки</option>
                </>
            )
        case procedure.isREM(props.auctions.sellingMethod):
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Подання неповного пакету документів або відомостей</option>
                    <option value='2'>Надання недостовірної інформації</option>
                    <option value='3'>Не розкриття інформації про кінцевих бенефіціарних власників</option>
                    <option value='4'>Поширення обмежувальних заходів</option>
                    <option value='5'>Перевищення обсягу річної квоти (25%)</option>
                    <option value='6'>Відмова від підписання протоколу</option>
                    <option value='7'>Відмова від підписання договору</option>
                </>
            )
        default:
            return (
                <>
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option value='1'>Відмовився від підписання протоколу</option>
                    <option value='2'>Відмовився від укладання договору</option>
                    <option value='3'>Не надав обов’язкові документи або відомості /надав неправдиві відомості</option>
                    <option value='4'>Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до
                        продажу
                    </option>
                    <option value='5'>Не відповідає встановленим Кодексом вимогам</option>
                    <option value='6'>Не отримав необхідне погодження ВРУ або КМУ</option>
                </>
            )
    }
}


function statusNullIfStatusAwardCancelledOrunsuccessful(props, data){
    if(props.auctions.awards[props.popUpAwardReject.awards].status === 'cancelled' || props.auctions.awards[props.popUpAwardReject.awards].status === 'unsuccessful '){
        return null
    }
    return data
}

function buttonCancelZemlya(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    if(props.awardReject.restrict === undefined && props.awardReject.restrict === 'undefined'){
                        let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Оберіть причину відхилення'})
                        props.setUniversalError(tmp)
                    }else{
                        if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
                            rejectWinerAwardReject(props)
                        }
                    }
                }
            }
        >
            Дискваліфікація
        </div>
    )
}

function buttonCancelZemlyaSave(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    if(props.awardReject.restrict === undefined && props.awardReject.restrict === 'undefined'){
                        let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Оберіть причину відхилення'})
                        props.setUniversalError(tmp)
                    }else{
                        if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
                            rejectWinerAwardRejectSave(props)
                        }
                    }
                }
            }
        >
            Зберегти
        </div>
    )
}

function buttonRejectZemlya(props){
    let tmpAct = null
    let tmpProtocol = null
    if(props.auctions.awards[props.popUpAwardReject.awards].documents.length > 0){
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'rejectionProtocol'){
                tmpProtocol = <>
                        {buttonCancelZemlya(props)}
                    </>
            }
        }
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'act'){
                tmpAct = buttonCancelZemlya(props)
            }
        }
    }
    if(tmpProtocol !== null) return tmpProtocol
    if(tmpAct !== null) return tmpAct
    return null
}
function buttonRejectZemlyaSave(props){
    if(!procedure.isLAE(props.auctions.sellingMethod) && !procedure.isLAW(props.auctions.sellingMethod) && !procedure.isLAP(props.auctions.sellingMethod)) return null
    return buttonCancelZemlyaSave(props)
}

function buttonApproveAuctionAwards(props){
    let tmp = null
    if (
        procedure.isREM(props.auctions.sellingMethod) &&
        props.auctions.awards[props.popUpAwardReject.awards].status === 'protocol_signed'
    ) {
        return
    }
    if(props.auctions.awards[props.popUpAwardReject.awards].status !== 'active'){
        tmp = <div
            className={style.btnAccept}
            onClick={
                () => {
                    approveWinerAward(props)
                }
            }
        >
            {procedure.isLAW(props.auctions.sellingMethod)
                ? "Підтвердження підписання акту"
                : null
            }
            {procedure.isLAE(props.auctions.sellingMethod) || procedure.isLAP(props.auctions.sellingMethod)
                ? "Підтвердження протоколу"
                : null
            }
            {(!procedure.isLAE(props.auctions.sellingMethod) && !procedure.isLAW(props.auctions.sellingMethod) && !procedure.isLAP(props.auctions.sellingMethod))
                ? "Протокол затверджено"
                : null
            }
        </div>
    }else{
        tmp = null
    }
    return tmp
}


async function approveWinerAward(props){
    props.changeLoader(true)
    let endPoint
    let newStatus

    if((procedure.isSPD(props.auctions.sellingMethod) || procedure.isSPE(props.auctions.sellingMethod)
        || procedure.isBRD(props.auctions.sellingMethod) || procedure.isBRE(props.auctions.sellingMethod) || procedure.isBRW(props.auctions.sellingMethod)
        || procedure.isLPE(props.auctions.sellingMethod)
    ) && props.auctions.status === "active_qualification"){
        newStatus = 'pending_payment'
    }else{
        newStatus = 'active'
    }

    if (procedure.isREM(props.auctions.sellingMethod)) {
        newStatus = 'protocol_signed'
    }
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/${newStatus}`

    const response = await fetch(endPoint, {
        method: "POST",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при затвердженні аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

//////////////////////

async function rejectWinerAwardReject(props){
    props.changeLoader(true)
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = '4'
        }
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
    const response2 = await fetch(endPoint2, {
        method: "POST",
        headers: getDefaultHeaders(props),
        body: JSON.stringify({
            //reason: '4'
            reason: tmpReason
        })
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json2 = await response2.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}
async function rejectWinerAwardRejectSave(props){
    props.changeLoader(true)
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = null
        }
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/termination_reason/${tmpReason}`
    const response2 = await fetch(endPoint2, {
        method: "PATCH",
        headers: getDefaultHeaders(props),
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json2 = await response2.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

async function combineUpadteAward(props){
    await getUpdateDataAuction(props, props.auctions.id)
    await props.changeLoader(false)
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionFull,

        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,

    setUniversalError,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeLoader,
    changeAwardRejectRestrict,
    contractsAuctionIdGet,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveOrRejectAward)