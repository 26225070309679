import React, { useState } from 'react';
import styles from './period.module.css';

import Time from '../../../../../../elements/inputs/inputTime';
import { checkProp } from '../../../../../../../redux/check';
import Select from '../../../../../../elements/inputs/inputSelect';

const WeekDayPeriod = ({data, name, weekDaysPeriod, setWeekDaysPeriod, index, indexPeriod, setTimeStamp, setPeriod, period}) => {
    const [hourStart, setHourStart] = useState(checkProp(data,'startTime') ? data.startTime : '00:00');
    const [hourEnd, setHourEnd] = useState(checkProp(data,'endTime') ? data.endTime : '00:00');
    const [weekday, setWeekday] = useState(checkProp(data,'weekday') ? data.weekday.uk_UA : null);

    // useEffect(() => {
    //     let tmp = {
    //         weekday: weekday,
    //         endTime: hourEnd,
    //         startTime: hourStart
    //     }
    //     let tmp2 = weekDaysPeriod
    //     setWeekDaysPeriod(tmp2.splice(index, 1, tmp))
    //   }, [weekday, hourStart, hourEnd])
    

    const weekDaysList = [
        {name: 'Понеділок', value: 'Понеділок'}, 
        {name: 'Вівторок', value: 'Вівторок'}, 
        {name: 'Середа', value: 'Середа'}, 
        {name: 'Четвер', value: 'Четвер'}, 
        {name: 'П\'ятниця', value: 'П\'ятниця'}, 
        {name: 'Субота', value: 'Субота'}, 
        {name: 'Неділя', value: 'Неділя'}
    ]

    function checkValues(){
        let start = +hourStart.replace(':', '')
        let end = +hourEnd.replace(':', '')
        if ((start !== 0) && (end !== 0) && (end - start <= 5)) return true
        return false
    }

    return (<div className={styles.weekDaysEdit}>
                <div className={styles.weekDaysContent}>
                    <Select
                        data={{
                            target: weekday,
                            label: 'Оберіть день тижня'
                        }}
                        onChange={ async (e) => {
                            setWeekday(e)
                            let tmp = weekDaysPeriod
                            tmp.forEach( (element, count) => {
                                if(index === count ) element.weekday = e
                            });
                            // await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)


                            let tmp3 = {
                                endTime: period[indexPeriod].weekdays[index].endTime,
                                startTime: period[indexPeriod].weekdays[index].startTime,
                                weekday: {
                                    uk_UA: e
                                }
                            }

                            let tmp4 = period[indexPeriod].weekdays

                            tmp4[index] = tmp3

                            let tmp2 = {
                                startDate: period[indexPeriod].startDate,
                                endDate: period[indexPeriod].endDate,
                                weekdays: tmp4
                            }

                            let tmp33 = period
                            tmp33[indexPeriod] = tmp2
                            setPeriod(tmp33)
                         }}
                        options={weekDaysList}
                    />
                    <Time
                        value={hourStart}
                        onChange={ async (e) => {
                                setHourStart(e)
                                let tmp = weekDaysPeriod
                                tmp.forEach( (element, count) => {
                                    if(index === count ) element.startTime = e
                                });
                                // await setWeekDaysPeriod(null)
                                await setWeekDaysPeriod(tmp)

                                let tmp3 = {
                                    endTime: period[indexPeriod].weekdays[index].endTime,
                                    startTime: e,
                                    weekday: {
                                        uk_UA: period[indexPeriod].weekdays[index].weekday.uk_UA
                                    }
                                }
    
                                let tmp4 = period[indexPeriod].weekdays
    
                                tmp4[index] = tmp3
    
                                let tmp2 = {
                                    startDate: period[indexPeriod].startDate,
                                    endDate: period[indexPeriod].endDate,
                                    weekdays: tmp4
                                }
    
                                let tmp33 = period
                                tmp33[indexPeriod] = tmp2
                                //setTimeStamp(Date.now())
                                setPeriod(tmp33)
                        }}
                        label={'Час початку'}
                    />
                    <Time
                        value={hourEnd}
                        onChange={ async (e) => {
                            setHourEnd(e)
                            let tmp = weekDaysPeriod
                            tmp.forEach( (element, count) => {
                                if(index === count ) element.endTime = e
                            });
                            // await setWeekDaysPeriod(null)
                            await setWeekDaysPeriod(tmp)

                            let tmp3 = {
                                endTime: e,
                                startTime: period[indexPeriod].weekdays[index].startTime,
                                weekday: {
                                    uk_UA: period[indexPeriod].weekdays[index].weekday.uk_UA
                                }
                            }

                            let tmp4 = period[indexPeriod].weekdays

                            tmp4[index] = tmp3

                            let tmp2 = {
                                startDate: period[indexPeriod].startDate,
                                endDate: period[indexPeriod].endDate,
                                weekdays: tmp4
                            }

                            let tmp33 = period
                            tmp33[indexPeriod] = tmp2
                            //setTimeStamp(Date.now())
                            setPeriod(tmp33)
                        }}
                        label={'Час завершення'}
                        error={checkValues()}
                    />
                    {/*<div className={styles.weekDaysDelete} 
                            title='Видалити графік'
                            onClick={ async ()=>{
                                    let tmp = weekDaysPeriod
                                    tmp.splice(index, 1)
                                    await setWeekDaysPeriod(null)
                                    await setWeekDaysPeriod(tmp)
                            }}
                    >
                        <DeleteIcon 
                            color={'#BFBFC6'}
                            style={styles.weekDaysDeleteSVG}
                            widthHeight={[20,20]}
                            
                        />
                    </div>*/}
                </div>
                {checkValues() ? <div className={styles.alertBlock}>Час закінчення має бути більши за час початку щонайменше на 5 хвилин</div> : null}
            </div>
    )
}


export default WeekDayPeriod;