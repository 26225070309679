import React from 'react'
import styles from './popAsk.module.css'

const PopAsk = (props) => {
    return (
            <div className={styles.wrapMain}>
                <div className={styles.wrap}>
                    <div className={styles.popUpTitle}>{props.title}</div>
                    <div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                        onClick={props.actionNo}
                    >
                        НІ
                    </div>
                        <div className={styles.btnDone}
                        onClick={props.actionYes}
                    >
                        ТАК</div>
                    </div>
                </div>
            </div>
    )
}

export default PopAsk