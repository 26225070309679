import {
    HISTORY_AUCTIONS,
    HISTORY_AWARDS,
    HISTORY_CONTRACTS,
    HISTORY_BIDS,
} from "./types";

export function historyAuctions(value) {
    return {
        type: HISTORY_AUCTIONS,
        payload: value
    }
}

export function historyAwards(value) {
    return {
        type: HISTORY_AWARDS,
        payload: value
    }
}

export function historyContrasts(value) {
    return {
        type: HISTORY_CONTRACTS,
        payload: value
    }
}

export function historyBids(value) {
    return {
        type: HISTORY_BIDS,
        payload: value
    }
}