import React from 'react';
import styles from './landProps.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse
} from '../../../../../redux/actions.js';

const WindowItems = ( props ) => {
    return (
        <div className={styles.group}>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Площа ділянки, га"
                        variant="outlined"
                        value={props.createItems.dgf.landProps.landArea}
                        onChange={(event)=>{
                            //landArea	number($float)
                            //example: 1285
                            props.changeCreateItemDgfLandPropsLandArea(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Кадастровий номер"
                        variant="outlined"
                        value={props.createItems.dgf.landProps.cadastralNumber}
                        onChange={(event)=>{
                            //cadastralNumber	string
                            props.changeCreateItemDgfLandPropsCadastralNumber(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Тип власності"
                        value={props.createItems.dgf.landProps.ownershipType}
                        onChange={(event)=>{
                            props.changeCreateItemDgfLandPropsOwnershipType(event.target.value)
                            //ownershipType	string
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберить тип власності"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesOwnershipType(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Тип власності"
                        value={props.createItems.dgf.landProps.encumbrances}
                        onChange={(event)=>{
                            props.changeCreateItemDgfLandPropsEncumbrances(event.target.value)
                            //encumbrances	string
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберить тип власності"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesEncumbrances(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Наявність співвласників"
                        variant="outlined"
                        value={props.createItems.dgf.landProps.jointOwnership}
                        onChange={(event)=>{
                            //jointOwnership	string
                            props.changeCreateItemDgfLandPropsJointOwnership(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Наявність інженерних мереж"
                        variant="outlined"
                        value={props.createItems.dgf.landProps.utilitiesAvailability}
                        onChange={(event)=>{
                            //utilitiesAvailability	string
                            props.changeCreateItemDgfLandPropsUtilitiesAvailability(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Поточне використання"
                        variant="outlined"
                        value={props.createItems.dgf.landProps.intendedUse}
                        onChange={(event)=>{
                            //intendedUse	string
                            props.changeCreateItemDgfLandPropsIntendedUse(event.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

function dictionariesOwnershipType(props){
    if(props.dictionaries.ownershipType !== null){
        return props.dictionaries.ownershipType.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}
function dictionariesEncumbrances(props){
    if(props.dictionaries.encumbrances !== null){
        return props.dictionaries.encumbrances.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    changeCreateItemDgfLandPropsId,
    changeCreateItemDgfLandPropsLandArea,
    changeCreateItemDgfLandPropsCadastralNumber,
    changeCreateItemDgfLandPropsOwnershipType,
    changeCreateItemDgfLandPropsEncumbrances,
    changeCreateItemDgfLandPropsJointOwnership,
    changeCreateItemDgfLandPropsUtilitiesAvailability,
    changeCreateItemDgfLandPropsIntendedUse
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)