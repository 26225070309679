import React from 'react';
import styles from './contactPoint.module.css';

import TextHalf from '../elements/viewData/textHalf'

const ContactPoint = (data) => {
    function contactPointName(data){
        if(data.hasOwnProperty('name')){
            if(data.name !== null){
                return data.name.uk_UA
            }
        }
        return ''
    }
    function contactPointEmail(data){
        if(data.hasOwnProperty('email')){
            if(data.email !== null){
                return data.email
            }
        }
        return ''
    }
    function contactPointTelephone(data){
        if(data.hasOwnProperty('telephone')){
            if(data.telephone !== null){
                return data.telephone
            }
        }
        return ''
    }
    function contactPointFaxNumber(data){
        if(data.hasOwnProperty('faxNumber')){
            if(data.faxNumber !== null){
                return data.faxNumber
            }
        }
        return ''
    }
    function contactPointUrl(data){
        if(data.hasOwnProperty('url')){
            if(data.url !== null){
                return data.url
            }
        }
        return ''
    }

    if(data.data !== null){
        return (
            <div className={styles.contactPoint}>
                <TextHalf
                    title='ПІБ'
                    data={contactPointName(data.data)}
                />
                <TextHalf
                    title='Адреса електронної пошти'
                    data={contactPointEmail(data.data)}
                />
                <TextHalf
                    title='Номер телефону'
                    data={contactPointTelephone(data.data)}
                />
                <TextHalf
                    title='Номер факсу'
                    data={contactPointFaxNumber(data.data)}
                />
                <TextHalf
                    title='Веб адреса'
                    data={contactPointUrl(data.data)}
                />
            </div>
        )
    }
    return null
}

export default ContactPoint