import React from 'react';
import styles from './lotInformation.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'

import {modifiDateString} from '../../../redux/modifiDate'
import {viewStringValue} from '../../../api/valueToString.js'

import AccessDetailsString from './auctionElements/accessDetails'
import PreviousAuctionIdString from './auctionElements/previousAuctionId'
import RegistrationFeeString from './auctionElements/registrationFee'
import ValueAddedTaxChargedString from './auctionElements/valueAddedTaxCharged'
import LeaseDurationString from './auctionElements/leaseDuration'
import AuctionUrlString from './auctionElements/auctionUrl'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import CurrentTenantGuaranteeFeeString from './auctionElements/currentTenantGuaranteeFee'
import RegistryObjectIdString from './auctionElements/registryObjectId'
import OwnershipType from './auctionElements/ownershipTypeLLE'


const LotInformation = (props) => {
    return (
        <div className={styles.infoBlock}>
            <h3>Інформація про лот</h3>
            <ValueString />
            <MinimalStepString />
            <GuaranteeString />
            <RegistrationFeeString />
            {strDiscount(props)}
            {DuchSteap(props)}
            <DescriptionString />
            <AuctionIdString />
            <TenderAttemptsString /> 
            <LotIdentifierString />
            <SellingMethodString />
            <XAdditionalInformationString />
            <MinNumberOfQualifiedBidsString />
            <CurrentTenantGuaranteeFeeString />
            <AccessDetailsString />
            <PreviousAuctionIdString />
            <RegistryObjectIdString />
            <AuctionUrlString />
            <LeaseDurationString />
            {strValuePeriod(props)}
            {strCalcOnBookValue(props)}
            <ValueAddedTaxChargedString />
            {strListType(props)}
            {strLeaseRules(props)}
            {strMartialLawDisclaimer(props)}
            <OwnershipType />
        </div>
    )
}

function strMartialLawDisclaimer(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('martialLawDisclaimer') === true){
            if(props.auctionId.martialLawDisclaimer !== null){
                return (
                    <TextFull
                        title="Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)"
                        data={props.auctionId.martialLawDisclaimer ? "Так" : "Ні"}
                    />
                )
            }
        }
    }
    return null
}

function DuchSteap(props){
    let tmpDutchStepPercent = null
    let tmpDutchStepQuantity = null
    let tmpDutchStepValue = null
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                if(props.auctionId.dutchStep.dutchStepPercent !== null){
                    tmpDutchStepPercent = <TextHalf
                        title='Відсоток зниження, %'
                        data={props.auctionId.dutchStep.dutchStepPercent}
                    />
                }
                if(props.auctionId.dutchStep.dutchStepQuantity !== null){
                    tmpDutchStepQuantity = <TextHalf
                        title='Кількість кроків'
                        data={props.auctionId.dutchStep.dutchStepQuantity}
                    />
                }
                if(props.auctionId.dutchStep.hasOwnProperty('dutchStepValue') === true){
                    if(props.auctionId.dutchStep.dutchStepValue !== null){
                        let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
                        tmpDutchStepValue = <TextHalf
                            title='Розмір кроку голландського раунду, грн'
                            data={tmp}
                        />
                    }
                }
            }
        }
    }
    return (
        tmpDutchStepPercent !== null
        || tmpDutchStepQuantity !== null
        || tmpDutchStepValue !== null
            ? <div className={styles.infoBlocks}>
                <TextFull
                    title="Розмір кроку аукціону"
                    data=''
                />  
                {tmpDutchStepPercent}
                {tmpDutchStepQuantity}
                {tmpDutchStepValue}
            </div>
            : null
    )
}

function strLeaseRules(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                return (
                    <>
                        {/*<TextFull
                            title="Правила та умови передачі об'єкта в оренду"
                            data=''
                        />*/}
                        <h4 style={{width:"100%"}}>Правила та умови передачі об'єкта в оренду</h4>
                        {strSchedule(props)}
                        {strIntendedUse(props)}
                        {strIntendedUseRestrictionMethod(props)}
                        {strIntendedUseRestrictionDescription(props)}
                        {strAdditionalLeaseConditions(props)}
                        {strSubleaseAllowed(props)}
                    </>
                )
            }
        }
    }
    return null
}

function strSchedule(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        return (
                            <div style={{width: "100%"}}>
                                <TextFull
                                    title="Пропонований графік оренди"
                                    data=''
                                />
                                <div className={styles.schedule}>
                                    {strScheduleHoursBy(props)}
                                    {strScheduleDaysBy(props)}
                                </div>
                                {props.auctionId.leaseRules.schedule.hasOwnProperty('period') === true
                                && props.auctionId.leaseRules.schedule.period !== null
                                    ? <div>
                                        <TextFull
                                            title="Графік погодинного використання"
                                            data=''
                                        />
                                        {viewShedulePerion(props)}
                                    </div>
                                    : null
                                }
                                <div>
                                    {strScheduleOther(props)}
                                </div>
                            </div>
                        )
                    }
                }
            }
        }
    }
    return null
}

function strScheduleDaysBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                            if(props.auctionId.leaseRules.schedule.daysBy !== null){
                                return (
                                    <>
                                        {strScheduleDaysByValue(props)}
                                        {strScheduleDaysByBy(props)}
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleDaysByValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                            if(props.auctionId.leaseRules.schedule.daysBy !== null){
                                if(props.auctionId.leaseRules.schedule.daysBy.hasOwnProperty('value') === true){
                                    if(props.auctionId.leaseRules.schedule.daysBy.value !== null){
                                        return (
                                            <>
                                              <TextFull
                                                    title="Дні"
                                                    data={props.auctionId.leaseRules.schedule.daysBy.value}
                                                />  
                                            </>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleDaysByBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                            if(props.auctionId.leaseRules.schedule.daysBy !== null){
                                if(props.auctionId.leaseRules.schedule.daysBy.hasOwnProperty('by') === true){
                                    if(props.auctionId.leaseRules.schedule.daysBy.by !== null){
                                        let tmp = ''
                                        if(props.auctionId.leaseRules.schedule.daysBy.by === 'week'){
                                            tmp = 'тиждень'
                                        }else if(props.auctionId.leaseRules.schedule.daysBy.by === 'month'){
                                            tmp = 'місяць'
                                        }
                                        return (
                                            <>
                                              <TextFull
                                                    title="За"
                                                    data={tmp}
                                                />  
                                            </>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                            if(props.auctionId.leaseRules.schedule.hoursBy !== null){
                                return (
                                    <>
                                        {strScheduleHoursByValue(props)}
                                        {strScheduleHoursByBy(props)}
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursByValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                            if(props.auctionId.leaseRules.schedule.hoursBy !== null){
                                if(props.auctionId.leaseRules.schedule.hoursBy.hasOwnProperty('value') === true){
                                    if(props.auctionId.leaseRules.schedule.hoursBy.value !== null){
                                        return (
                                            <>
                                              <TextFull
                                                    title="Години"
                                                    data={props.auctionId.leaseRules.schedule.hoursBy.value}
                                                />  
                                            </>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strScheduleHoursByBy(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                            if(props.auctionId.leaseRules.schedule.hoursBy !== null){
                                if(props.auctionId.leaseRules.schedule.hoursBy.hasOwnProperty('by') === true){
                                    if(props.auctionId.leaseRules.schedule.hoursBy.by !== null){
                                        let tmp = ''
                                        if(props.auctionId.leaseRules.schedule.hoursBy.by === 'day'){
                                            tmp = 'день'
                                        }else if(props.auctionId.leaseRules.schedule.hoursBy.by === 'week'){
                                            tmp = 'тиждень'
                                        }else if(props.auctionId.leaseRules.schedule.hoursBy.by === 'month'){
                                            tmp = 'місяць'
                                        }
                                        return (
                                            <>
                                              <TextFull
                                                    title="За"
                                                    data={tmp}
                                                />  
                                            </>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewShedulePerion(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('period') === true){
                            if(props.auctionId.leaseRules.schedule.period !== null){
                                if(props.auctionId.leaseRules.schedule.period.length > 0){
                                    return props.auctionId.leaseRules.schedule.period.map(
                                        (e, count) => {
                                            return (
                                                <div className={styles.wrapPeriod}>
                                                    <div className={styles.wrapPeriod_mainDate}>
                                                        <TextHalf
                                                            title={count+1}
                                                            data=''
                                                        />
                                                        <TextHalf
                                                            title="Дата початку"
                                                            data={modifiDateString(e.startDate, 1, false)}
                                                        />
                                                        <TextHalf
                                                            title="Дата завершення"
                                                            data={modifiDateString(e.endDate, 1, false)}
                                                        />
                                                    </div>
                                                    <div className={styles.wrapPeriod_main}>
                                                        {viewPeriodWeeks(e.weekdays)}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewPeriodWeeks(data){
    return data
    .filter(
        (e) => {
            return e !== null
        }
    )
    .map(
        (e) => {
            return (
                <div className={styles.wrapPeriod_mainDate}>
                    <TextHalf
                        title="День тижня"
                        data={e.weekday.uk_UA}
                    />
                    <TextHalf
                        title="Початок"
                        data={e.startTime}
                    />
                    <TextHalf
                        title="Кінець"
                        data={e.endTime}
                    />
                </div>
            )
        }
    )
}

function strScheduleOther(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('schedule') === true){
                    if(props.auctionId.leaseRules.schedule !== null){
                        if(props.auctionId.leaseRules.schedule.hasOwnProperty('other') === true){
                            if(props.auctionId.leaseRules.schedule.other !== null && props.auctionId.leaseRules.schedule.other !== ''){
                                return (
                                    <TextHalf
                                        title="Інший графік використання"
                                        data={props.auctionId.leaseRules.schedule.other}
                                    />
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function strIntendedUse(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('intendedUse') === true){
                    if(props.auctionId.leaseRules.intendedUse !== null){
                        if(props.auctionId.leaseRules.intendedUse.length > 0){
                            return props.auctionId.leaseRules.intendedUse.map(
                                (i) => {
                                    let tmp = ''
                                    if(i === 'agricultureFoodNoAlcoEntp'){
                                        tmp = 'Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)'
                                    }else if(i === 'atelierBeautyServices'){
                                        tmp = 'Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню'
                                    }else if(i === 'authoritiesGovernments'){
                                        tmp = 'Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів'
                                    }else if(i === 'buildingMaterialEntp'){
                                        tmp = 'Підприємства з виробництва будівельних матеріалів'
                                    }else if(i === 'concertEntertExhibEntp'){
                                        tmp = 'Підприємства з організації концертно-видовищної та виставкової діяльності'
                                    }else if(i === 'consumerServicesEntp'){
                                        tmp = 'Підприємства побутового обслуговування'
                                    }else if(i === 'coursesTrainingsEarly'){
                                        tmp = 'Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги'
                                    }else if(i === 'deputyOffice'){
                                        tmp = 'Громадська приймальня народного депутата України або депутата місцевої ради'
                                    }else if(i === 'diplomats'){
                                        tmp = 'Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні'
                                    }else if(i === 'disabilityRehabilitation'){
                                        tmp = 'Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ'
                                    }else if(i === 'education'){
                                        tmp = 'Заклади освіти'
                                    }else if(i === 'electricEquipEntp'){
                                        tmp = 'Підприємства з виробництва електричного та електронного устаткування'
                                    }else if(i === 'electricGasChemicalFerrousEntp'){
                                        tmp = 'Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії'
                                    }else if(i === 'eventsElection'){
                                        tmp = 'Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії'
                                    }else if(i === 'eventsSienceCulturalPublic'){
                                        tmp = 'Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів'
                                    }else if(i === 'exchangeAtmTelecomAdvertizingPress'){
                                        tmp = 'Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів'
                                    }else if(i === 'exhibitions'){
                                        tmp = 'Проведення виставок'
                                    }else if(i === 'ferryRailRoadTranspot'){
                                        tmp = 'Розміщення морського, залізничного та автомобільного транспорту'
                                    }else if(i === 'foodBarRestuarantExcisable'){
                                        tmp = 'Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи'
                                    }else if(i === 'foodBuffetCafeNotExcisable'){
                                        tmp = 'Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи'
                                    }else if(i === 'forestryFisheryEntp'){
                                        tmp = 'Підприємства лісового господарства, рибного господарства'
                                    }else if(i === 'fuelEntp'){
                                        tmp = 'Підприємства паливної промисловості'
                                    }else if(i === 'garmentTextileEntp'){
                                        tmp = 'Підприємства з виробництва швейної та текстильної промисловості'
                                    }else if(i === 'gymSport'){
                                        tmp = 'Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту'
                                    }else if(i === 'healthcarePharmaLaboratory'){
                                        tmp = 'Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії'
                                    }else if(i === 'libraryCinemaTheatre'){
                                        tmp = 'Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів'
                                    }else if(i === 'lotteryEntp'){
                                        tmp = 'Підприємства з випуску лотерейних білетів та проведення лотерей'
                                    }else if(i === 'metallworkLightNoTextileEntp'){
                                        tmp = 'Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості'
                                    }else if(i === 'museums'){
                                        tmp = 'Музеї'
                                    }else if(i === 'ngo'){
                                        tmp = 'Громадські об’єднання та благодійні організації'
                                    }else if(i === 'nightclubRestuarantSaunaHotelInternetclub'){
                                        tmp = 'Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе'
                                    }else if(i === 'noRestuarantRestuarantEntp'){
                                        tmp = 'Підприємства ресторанного господарства (крім ресторанів)'
                                    }else if(i === 'nonEnergyMiningEntp'){
                                        tmp = 'Підприємства з видобування неенергетичних матеріалів'
                                    }else if(i === 'nonFerrousOilGasEntp'){
                                        tmp = 'Підприємства кольорової металургії, нафтогазодобувної промисловості'
                                    }else if(i === 'officePostMassmediaBank'){
                                        tmp = 'Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг'
                                    }else if(i === 'other'){
                                        tmp = 'Інше'
                                    }else if(i === 'otherProduction'){
                                        tmp = 'Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність'
                                    }else if(i === 'parkingCarriageCarRepair'){
                                        tmp = 'Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів'
                                    }else if(i === 'pensionFund'){
                                        tmp = 'Пенсійний фонд України та його органи'
                                    }else if(i === 'publicCultural'){
                                        tmp = 'Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)'
                                    }else if(i === 'pulpPaperWasteEntp'){
                                        tmp = 'Підприємства целюлозно-паперової промисловості, переробки відходів'
                                    }else if(i === 'religion'){
                                        tmp = 'Релігійні організації для забезпечення проведення релігійних обрядів та церемоній'
                                    }else if(i === 'ritualWcRaw'){
                                        tmp = 'Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини'
                                    }else if(i === 'rubberPlasticEntp'){
                                        tmp = 'Підприємства з виробництва гумових та пластмасових виробів'
                                    }else if(i === 'science'){
                                        tmp = 'Науково-дослідні установи, наукові парки'
                                    }else if(i === 'socialServicesLaw'){
                                        tmp = 'Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”'
                                    }else if(i === 'stateMunicipalCultural'){
                                        tmp = 'Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв'
                                    }else if(i === 'stateMunicipalMassMedia'){
                                        tmp = 'Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)'
                                    }else if(i === 'stockArchive'){
                                        tmp = 'Склади. Камери схову, архіви'
                                    }else if(i === 'telecomEntp'){
                                        tmp = 'Підприємства зв’язку'
                                    }else if(i === 'tobaccoAlcoEntp'){
                                        tmp = 'Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості'
                                    }else if(i === 'tradeEntp'){
                                        tmp = 'Підприємства торгівлі'
                                    }else if(i === 'transportAdditionalEntp'){
                                        tmp = 'Підприємства з надання додаткових транспортних послуг та допоміжних операцій'
                                    }else if(i === 'transportProductionServiceEntp'){
                                        tmp = 'Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій'
                                    }else if(i === 'veterans'){
                                        tmp = 'Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів'
                                    }else if(i === 'woodFurnitureEntp'){
                                        tmp = 'Підприємства з виробництва деревини та виробів з деревини, меблів'
                                    }
                                    return (
                                        <TextHalf
                                            title="Обмеження щодо використання майна (заборонені цільові призначення)"
                                            data={tmp}
                                        />
                                    )                                    
                                }
                            )
                        }
                    }
                }
            }
        }
    }
    return null
}

function strIntendedUseRestrictionMethod(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
                    if(props.auctionId.leaseRules.intendedUseRestrictionMethod !== null){
                        let tmp = ''
                        if(props.auctionId.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                            tmp = 'Окрім зазначеного'
                        }else if(props.auctionId.leaseRules.intendedUseRestrictionMethod === 'noRestrictions'){
                            tmp = 'Без обмежень'
                        }else if(props.auctionId.leaseRules.intendedUseRestrictionMethod === 'onlyDescribed'){
                            tmp = 'Тільки зазначене'
                        }
                        return (
                            <TextHalf
                                title="Цільове призначення об'єкта оренди (за наявності)"
                                data={tmp}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strIntendedUseRestrictionDescription(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('intendedUseRestrictionDescription') === true){
                    if(props.auctionId.leaseRules.intendedUseRestrictionDescription !== null){
                        return (
                            <TextHalf
                                title="Опис обмеженнь цільового призначення об'єкта (за наявності)"
                                data={props.auctionId.leaseRules.intendedUseRestrictionDescription}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strAdditionalLeaseConditions(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('additionalLeaseConditions') === true){
                    if(props.auctionId.leaseRules.additionalLeaseConditions !== null){
                        return (
                            <TextHalf
                                title="Додаткові умови оренди майна"
                                data={props.auctionId.leaseRules.additionalLeaseConditions}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strSubleaseAllowed(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseRules') === true){
            if(props.auctionId.leaseRules !== null){
                if(props.auctionId.leaseRules.hasOwnProperty('subleaseAllowed') === true){
                    if(props.auctionId.leaseRules.subleaseAllowed !== null){
                        let tmp
                        if(props.auctionId.leaseRules.subleaseAllowed === true){
                            tmp = 'Так'
                        }else{
                            tmp = 'Ні'
                        }
                        return (
                            <TextHalf
                                title="Можливість суборенди"
                                data={tmp}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strDiscount(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true){
            if(props.auctionId.discount !== null){
                return (
                    <>
                        <TextFull
                            title="Знижки"
                            data=''
                        />
                        {strDiscountPercent(props)}
                        <TextFull
                            title="Стартова вартість попереднього аукціону"
                            data=''
                        />
                        {strPreviousAuctionValueAmount(props)}
                    </>
                )
            }
        }
    }
    return null
}

function strDiscountPercent(props){
    if(props.auctionId !== null){
        /*...if(
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionId.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
        ){*/
            if(props.auctionId.hasOwnProperty('discount') === true){
                if(props.auctionId.discount !== null){
                    if(props.auctionId.discount.hasOwnProperty('discountPercent') === true){
                        if(
                            props.auctionId.discount.discountPercent !== null &&
                            props.auctionId.discount.discountPercent !== 0
                        ){
                            return (
                                <>
                                    <TextHalf
                                        title="Розмір знижки від попереднього аукціону, %"
                                        data={props.auctionId.discount.discountPercent}
                                    />
                                </>
                            )
                        }
                    }
                }
            }
        //}
    }
    return null
}

function strPreviousAuctionValueAmount(props){


    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true){
            if(props.auctionId.discount !== null){
                if(props.auctionId.discount.hasOwnProperty('previousAuctionValue') === true){
                    if(props.auctionId.discount.previousAuctionValue !== null){
                        let tmp = viewStringValue(props.auctionId.discount.previousAuctionValue)
                        return (
                            <TextHalf
                                title="Сума"
                                data={tmp}
                            />
                        )
                    }
                }
            }
        }
    }
    return null
}

function strListType(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('listType') === true){
            if(props.auctionId.listType !== null){
                let tmp = ''
                if(props.auctionId.listType === 'First'){
                    tmp = 'Перший'
                }
                return (
                    <TextHalf
                        title="Тип переліку, до якого віднесено об'єкт"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

function strCalcOnBookValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('calcOnBookValue') === true){
            if(props.auctionId.calcOnBookValue !== null){
                let tmp
                if(props.auctionId.calcOnBookValue === true){
                    tmp = 'Так'
                }else{
                    tmp = 'Ні'
                }
                return (
                    <TextFull
                        title="Стартова орендна плата розрахована від балансової вартості"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

function strValuePeriod(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('valuePeriod') === true){
            if(props.auctionId.valuePeriod !== null){
                let tmp = ''
                if(props.auctionId.valuePeriod === 'month'){
                    tmp = 'Стартова вартість за місяць'
                }else if(props.auctionId.valuePeriod === 'hour'){
                    tmp = 'Стартова вартість за годину'
                }else if(props.auctionId.valuePeriod === 'day'){
                    tmp = 'Стартова вартість за день'
                }
                return (
                    <TextHalf
                        title="Період розрахунку стартової вартості"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotInformation)
