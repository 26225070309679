import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionXAdditionalInformation} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import InputText from '../../elements/inputs/input';

import {TranslationsUk} from '../../../redux/translations.uk.js';

import { isSUE, isSUD, isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE } from '../../../redux/procedure';

import {disabledElement} from '../disabled'

const WindowAuctionAdditionalInformation = ( props ) => {
    if(props.auctionsMy !== null){
        if(isSUE(props.auctionsMy.sellingMethod) || isSUD(props.auctionsMy.sellingMethod)) return null
        return setObovyazkovaEdit(props)
    }
    return null
}

function setObovyazkovaEdit(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
        /*props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"*/
    ){
        if(props.typeElem === 1){
            return (
                   <InputText
                        disabled={disabledElement(props.auctionsMy, '00')}
                        label={viewNameInput(props)}
                        value={props.auctionsMy.xAdditionalInformation}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXAdditionalInformation(e)
                            }
                        }
                    />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={props.auctionsMy.xAdditionalInformation}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionXAdditionalInformation(event.target.value)
                        }}
                    />
                </div>
            )
        }

    }else if(
        props.auctionsMy.sellingMethod === "timber-english" ||
        props.auctionsMy.sellingMethod === "timber-english-fast" ||
        props.auctionsMy.sellingMethod === "timber-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "timber-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "timber-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "timber-english-initial-qualification-fast"
    ){
        if(props.typeElem === 1){
            return (
                    <InputText
                        disabled={disabledElement(props.auctionsMy, '00')}
                        label={viewNameInput(props)}
                        value={props.auctionsMy.xAdditionalInformation}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXAdditionalInformation(e)
                            }
                        }
                    />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        required
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={props.auctionsMy.xAdditionalInformation}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionXAdditionalInformation(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }else{
        let tmpText
        if(props.auctionsMy.hasOwnProperty('xAdditionalInformation')){
            tmpText = props.auctionsMy.xAdditionalInformation
        }else{
            if(props.auctionsMy.x_additionalInformation !== null){
                tmpText = props.auctionsMy.x_additionalInformation.uk_UA
            }
        }
        if(props.typeElem === 1){
            return (
                    <InputText
                        disabled={disabledElement(props.auctionsMy, '00')}
                        label={viewNameInput(props)}
                        value={props.auctionsMy.xAdditionalInformation}
                        onChange={
                            (e) => {
                                props.changeCreateAuctionXAdditionalInformation(e)
                            }
                        }
                    />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={tmpText}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionXAdditionalInformation(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }
}

function viewNameInput(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        return TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.x_additionalInformation.legalName
    }else if(
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return 'Додаткові відомості'
    }else{
        return 'Інші відомості'
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionXAdditionalInformation,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionAdditionalInformation)