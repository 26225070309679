import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

const DisqualifiedBidsDetails = (props) => {
    if(props.info !== null){
        return (
            <div className={styles.infoBlock}>
                <h3>Інформація про виключеного учасника</h3>
                <div className={styles.orgTitle}>
                    <p className={styles.desc}>Повна юридична назва організації або ПІБ</p>
                    <p>{contactName(props.info)}</p>
                </div>
                <h3>Ідентифікатори організації або особи</h3>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Ідентифікатори організації</p>
                    <p>{contactScheme(props.info)}</p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                    <p>{contactEDRPOU(props.info)}</p>
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Повна юридична назва організації</p>
                    <p>{contactLegalName(props.info)}</p>
                </div>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Місцезнаходження Організатора аукціону</p>
                    <p>{contactAddress(props.info)}</p>
                </div>
                <div className={styles.orgContact}>
                    <p className={styles.desc}>Контактна особа</p>
                    <p>{contactPoint(props.info, 1)}</p>
                    <p>{contactPoint(props.info, 2)}</p>
                </div>
            </div>
        )
    }
    return null
}

function contactName(props){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('name')){
            if(props.name !== null){
                tmp += props.name.uk_UA
            }
        }
    }
    return tmp
}

function contactPoint(props, flag){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('contactPoint')){
            if(props.contactPoint !== null){
                if(flag === 1){
                    if(props.contactPoint.hasOwnProperty('name')){
                        if(props.contactPoint.name !== null){
                            tmp += props.contactPoint.name.uk_UA + ', '
                        }
                    }
                }else if(flag === 2){
                    if(props.contactPoint.hasOwnProperty('email')){
                        if(props.contactPoint.email !== null){
                            tmp += props.contactPoint.email + ', '
                        }
                    }
                    if(props.contactPoint.hasOwnProperty('telephone')){
                        if(props.contactPoint.telephone !== null){
                            tmp += props.contactPoint.telephone + ', '
                        }
                    }
                    if(props.contactPoint.hasOwnProperty('faxNumber')){
                        if(props.contactPoint.faxNumber !== null){
                            tmp += props.contactPoint.faxNumber + ', '
                        }
                    }
                    if(props.contactPoint.hasOwnProperty('url')){
                        if(props.contactPoint.url !== null){
                            tmp += props.contactPoint.url + ', '
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactScheme(props){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('identifier')){
            if(props.identifier !== null){
                if(props.identifier.hasOwnProperty('scheme')){
                    if(props.identifier.scheme !== null){
                        tmp += props.identifier.scheme
                    }
                }
            }
        }
    }
    return tmp
}
function contactEDRPOU(props){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('identifier')){
            if(props.identifier !== null){
                if(props.identifier.hasOwnProperty('id')){
                    if(props.identifier.id !== null){
                        tmp += props.identifier.id
                    }
                }
            }
        }
    }
    return tmp
}
function contactLegalName(props){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('identifier')){
            if(props.identifier !== null){
                if(props.identifier.hasOwnProperty('legalName')){
                    if(props.identifier.legalName !== null){
                        if(props.identifier.legalName.hasOwnProperty('uk_UA')){
                            if(props.identifier.legalName.uk_UA !== null){
                                tmp += props.identifier.legalName.uk_UA
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props !== null){
        if(props.hasOwnProperty('address')){
            if(props.address !== null){
                if(props.address.hasOwnProperty('postalCode')){
                    if(props.address.postalCode !== null){
                        tmp += props.address.postalCode + ', '
                    }
                }
                if(props.address.hasOwnProperty('countryName')){
                    if(props.address.countryName !== null){
                        tmp += props.address.countryName.uk_UA + ', '
                    }
                }
                if(props.address.hasOwnProperty('region')){
                    if(props.address.region !== null){
                        tmp += props.address.region.uk_UA + ', '
                    }
                }
                if(props.address.hasOwnProperty('locality')){
                    if(props.address.locality !== null){
                        tmp += props.address.locality.uk_UA + ', '
                    }
                }
                if(props.address.hasOwnProperty('streetAddress')){
                    if(props.address.streetAddress !== null){
                        tmp += props.address.streetAddress.uk_UA
                    }
                }                                    
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(DisqualifiedBidsDetails)
