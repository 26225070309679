import React from 'react';
import styles from './railway.module.css';

import {connect} from 'react-redux';
import {
    getMyAuction,
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    changeLoader,
    setUniversalError,
    changeCreateItemWagonSpecies,
    changeCreateItemLoadingRestriction,
    changeCreateItemWagonTypes,
    setCreateItemDateFrom1,
    setCreateItemDateTill1,
    setCreateItemDateFrom2,
    setCreateItemDateTill2,
    setCreateItemRouteQuantity,
    setCreateItemRoutesAccountingRouteNumber,
    setCreateItemRoutesAccountingWagonsPerRouteQuantity,
    changeCreateItemClassificationAddId,
    setEditAuctionFromId
} from '../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    auctionsGetClassificationsByWagonSpecies,
    setCreateRestrictionFlag,
    setCreateRestrictionExclusionsFlag,
    setCreateRestrictionExclusionsObject,
    setCreateRestrictionExclusionsObjectType,
    setCreateRestrictionExclusionsObjectCode,
    setCreateRestrictionExclusionsObjectName,
    setCreateLoadObject,
    setCreateLoadObjectCode,
    setCreateLoadObjectName,
    setCreateUnloadObject,
    setCreateUnloadObjectCode,
    setCreateUnloadObjectName,
    setPopUpAuctionMyNull,
} from '../../../../redux/actions/auctions/auctions.js';

import {popUpAddress} from '../../../../redux/actions/address/address.js';

import 'date-fns';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import ItemsList from './itemsList/itemsList.js';
import ReturnButton from '../../returnButton'

import { KeyboardDateTimePicker } from "@material-ui/pickers";

import ZuMap from '../../../map/zuMaps'
import {setDataAddress} from '../../../../redux/createAddress';

const WindowItems = ( props ) => {
    let tmpZuMap
    tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory+ ' ' + styles.groupElemCategoryTwo}>
        <div>
            <ZuMap
                latitude = {props.createItems.landRental.location.latitude}
                longitude = {props.createItems.landRental.location.longitude}
            />
        </div>
    </div>
    let tmpText;
    if(props.createItems.id === null){
        tmpText = 'Створення нового лоту';
    }else{
        tmpText = 'Редагування лоту';
    }
    let formItem = null;
    if(props.auctionsItemsSwitchWindow > 0){
        formItem = (
            <>
                <div className={styles.group}>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Основний класифікатор"
                                value={props.createItems.classificationId}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationId(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберить основний класифікатор"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Додатковий класифікатор"
                                value={props.createItems.additionalClassificationIds.railwayCargoAdditional}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationAddId(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберить додатковий класифікатор"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationAddIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Для залізничного транспорту"
                                value={props.createItems.wagonSpec}
                                onChange={(event)=>{
                                    props.changeCreateItemWagonSpecies(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Для залізничного транспорту"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationWagonSpecOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Одиниці виміру"
                                value={props.createItems.unitCode}
                                onChange={(event)=>{
                                    props.changeCreateItemUnitCode(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Обрати одиниці виміру"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberUnitCode(props)}
                            </TextField>
                        </div>
                    </div>
                    {tmpZuMap}
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    ()=>{
                                        setNewAddress(props)
                                    }
                                }
                            >
                                Додати обрані координати
                            </Button>
                        </div>
                    </div>
                    <div>Адреса</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Країна"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.countryName}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props.createItems.landRental.addressItem.region)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Область"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.region}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Населенний пункт"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.locality}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Вулиця, буд. №"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.streetAddress}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Поштовий індекс"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.postalCode}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Опис"
                                multiline
                                rows={10}
                                value={props.createItems.description}
                                variant="outlined"
                                onChange={(event)=>{
                                    props.changeCreateItemDescription(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>Період поставлення електронної заявки</div>
                    <div className={styles.groupElem}>

                        {requestLifeTimeFrom(props, 1)}
                        {requestLifeTimeFrom(props, 2)}
                    </div>
                    <div className={styles.groupElem}>Період подачі вагону</div>
                    <div className={styles.groupElem}>
                        {requestLifeTimeFrom(props, 3)}
                        {requestLifeTimeFrom(props, 4)}
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabled()}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Полігон навантаження"
                                defaultValue={props.createItems.loadingRestriction}
                                variant="outlined"
                                onChange={
                                    (e) => {
                                        props.changeCreateItemLoadingRestriction(e.target.value)
                                    }
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabled()}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Перелік кодів типів вагонів (вказати через кому)"
                                defaultValue={props.createItems.wagonTypes}
                                variant="outlined"
                                onChange={
                                    (e) => {
                                        props.changeCreateItemWagonTypes(e.target.value)
                                    }
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість"
                                value={props.createItems.quantity}
                                onChange={(event) => {
                                    props.changeCreateItemQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість маршрутів"
                                value={props.createItems.routeQuantity}
                                onChange={(event) => {
                                    props.setCreateItemRouteQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Порядковий номер маршруту"
                                value={props.createItems.routesAccountingRouteNumber}
                                onChange={(event) => {
                                    props.setCreateItemRoutesAccountingRouteNumber(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість вагонів по кожному маршруту"
                                value={props.createItems.routesAccountingWagonsPerRouteQuantity}
                                onChange={(event) => {
                                    props.setCreateItemRoutesAccountingWagonsPerRouteQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                    <div>
                        <div>Обмеження полігону навантаження/ вивантаження</div>
                        <div>{viewRestrict(props)}</div>
                    </div>
                    <div className={styles.groupElem}>
                        {/*<Button
                            disabled={elementDisabled(props)}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.setCreateRestrictionFlag(-1)
                                }
                            }
                        >
                            Обмеження полігону
                        </Button>*/}
                        {viewButton(props)}
                    </div>
                </div>
            </>
        )
    }
    let formButton = null;
    if(props.auctionsItemsSwitchWindow === 1){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled()}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                createItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }else if(props.auctionsItemsSwitchWindow === 2){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled()}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                saveItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeAuctionsItemsSwitchWindow(1);
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=20'
                                    }
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsGetClassificationsByTypeRailway(data, header)
                                    props.auctionsGetClassificationsByGenerationType(data, header)
                                    props.auctionsGetClassificationsByWagonSpecies(data, header)
                                }
                            }
                        >
                            Створити новий лот
                        </Button>
                    </div>
                    <ItemsList />
                </div>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {formItem}
                    {formButton}
                </div>
            </div>
            <div className={styles.bottonBlock}>
                {/*<Button
                    className={styles.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.setPopUpAuctionMyNull()
                            props.changeCreateAuctionTab(0);
                            props.changeAuctionsSwitchWindow(0)
                        }
                    }
                >
                    Повернутися до списку аукціонів
                </Button>*/}
                <ReturnButton />
            </div>
        </div>
    )
}

function viewRestrict(props){
    let tmp = null;
    if(props.createItems.auctionRestriction !== null){
        if(props.createItems.auctionRestriction.length > 0){
            tmp = props.createItems.auctionRestriction.map(
                (i) => {
                    return <div className={styles.restrict}>
                            <div>
                                <div>Ознака об'єкту полігону навантаження: {i.loadObject}</div>
                                <div>Код об'єкту полігону навантаження: {i.loadObjectCode}</div>
                                <div>Найменування об'єкту полігону навантаження: {i.loadObjectName}</div>
                            </div>
                            <div>
                                <div>Ознака об'єкту полігону вивантаження: {i.unloadObject}</div>
                                <div>Код об'єкту полігону вивантаження: {i.unloadObjectCode}</div>
                                <div>Найменування об'єкту полігону вивантаження: {i.unloadObjectName}</div>
                            </div>
                            <div>
                                <div className={styles.groupElem}>
                                    <Button
                                        disabled={elementDisabled()}
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => {
                                                props.setCreateLoadObject(i.loadObject)
                                                props.setCreateLoadObjectCode(i.loadObjectCode)
                                                props.setCreateLoadObjectName(i.loadObjectName)
                                                props.setCreateUnloadObject(i.unloadObject)
                                                props.setCreateUnloadObjectCode(i.unloadObjectCode)
                                                props.setCreateUnloadObjectName(i.unloadObjectName)
                                                props.setCreateRestrictionFlag(i.id)
                                            }
                                        }
                                    >
                                        Редагувати
                                    </Button>
                                </div>
                                <div className={styles.groupElem}>
                                    <Button
                                        disabled={elementDisabled()}
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            () => {
                                                delRestrict(props, i.id)
                                            }
                                        }
                                    >
                                        Видалити
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <div>Виключення з полігону навантаження/вивантаження</div>
                                <div>
                                    {viewRestrictExclusions(props, i)}
                                </div>
                            </div>
                            <div className={styles.groupElem}>
                                <Button
                                    disabled={elementDisabled()}
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            props.setCreateRestrictionExclusionsFlag({id: i.id, idEx: 0})
                                        }
                                    }
                                >
                                    Виключення з полігону
                                </Button>
                            </div>
                        </div>
                }
            )
        }
    }
    return tmp
}

function viewButton(props){
    if(props.auctionsItemsSwitchWindow === 2){
        return (
            <Button
                disabled={elementDisabled()}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        props.setCreateRestrictionFlag(-1)
                    }
                }
            >
                Обмеження полігону
            </Button>
        )
    }else{
        return (
            <Button
                disabled="disabled"
                variant="contained"
                color="primary"
            >
                Обмеження полігону
            </Button>
        )
    }
}

function viewRestrictExclusions(props, value){
    let tmp = null;
    if(value.auctionRestrictionExclusions !== null){
        if(value.auctionRestrictionExclusions.length > 0){
            tmp = value.auctionRestrictionExclusions.map(
                (i) => {
                    return <div className={styles.restrict}>
                        <div>Ознака об'єкту полігону виключення: {i.exclusionObject}</div>
                        <div>Ознака приналежності до: {i.exclusionObjectCode}</div>
                        <div>Код об'єкту полігону виключення: {i.exclusionObjectName}</div>
                        <div>Найменування об'єкту полігону виключення: {i.exclusionObjectType}</div>
                        <div className={styles.groupElem}>
                            <Button
                                disabled={elementDisabled()}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.setCreateRestrictionExclusionsObject(i.exclusionObject)
                                        props.setCreateRestrictionExclusionsObjectType(i.exclusionObjectCode)
                                        props.setCreateRestrictionExclusionsObjectCode(i.exclusionObjectName)
                                        props.setCreateRestrictionExclusionsObjectName(i.exclusionObjectType)
                                        props.setCreateRestrictionExclusionsFlag({id: value.id, idEx: i.id})
                                    }
                                }
                            >
                                Редагувати
                            </Button>
                        </div>
                        <div className={styles.groupElem}>
                            <Button
                                disabled={elementDisabled()}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        delRestrictExclusions(props, {id: value.id, idEx: i.id})
                                    }
                                }
                            >
                                Видалити
                            </Button>
                        </div>
                    </div>
                }
            )
        }
    }
    return tmp
}

function requestLifeTimeFrom(props, type){
    let tmp;
    let text = null;
    if(type === 1 || type === 3){
        text="Дата початку"
    }else if(type === 2 || type === 4){
        text="Дата закінчення"
    }

    let tmpData = null;
    if(type === 1){
        //tmpData = props.createItems.requestLifeTime.dateFrom.slice(0, 16)
        tmpData = props.createItems.requestLifeTime.dateFrom
    }else if(type === 2){
        //tmpData = props.createItems.requestLifeTime.dateTill.slice(0, 16)
        tmpData = props.createItems.requestLifeTime.dateTill
    }else if(type === 3){
        //tmpData = props.createItems.loadLifeTime.dateFrom.slice(0, 16)
        tmpData = props.createItems.loadLifeTime.dateFrom
    }else if(type === 4){
        //tmpData = props.createItems.loadLifeTime.dateTill.slice(0, 16)
        tmpData = props.createItems.loadLifeTime.dateTill
    }
    tmp = <div>
        {/*<TextField
            disabled={elementDisabledCreate(props)}
            id="datetime-local"
            label={text}
            type="datetime-local"
            defaultValue={tmpData}
            InputLabelProps={{
                shrink: true,
            }}
            variant="outlined"
            onChange={(event)=>{
                if(type === 1){
                    props.setCreateItemDateFrom1(event.target.value)
                }else if(type === 2){
                    props.setCreateItemDateTill1(event.target.value)
                }else if(type === 3){
                    props.setCreateItemDateFrom2(event.target.value)
                }else if(type === 4){
                    props.setCreateItemDateTill2(event.target.value)
                }
            }}
        />*/}
        <div>{text}</div>
        <KeyboardDateTimePicker
            disabled={elementDisabledCreate(props)}
            value={tmpData}
            onChange={
                (e) => {
                    if(type === 1){
                        props.setCreateItemDateFrom1(e)
                    }else if(type === 2){
                        props.setCreateItemDateTill1(e)
                    }else if(type === 3){
                        props.setCreateItemDateFrom2(e)
                    }else if(type === 4){
                        props.setCreateItemDateTill2(e)
                    }
                }
            }
            onError={console.log}
            minDate={new Date("1900-01-01T00:00")}
            ampm={false}
            format="yyyy/MM/dd HH:mm"
        />
    </div>
    return tmp
}

function elementDisabled(){
    let tmpStatus = null;
    return tmpStatus
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationIdOption(props){
    if(props.classificationsRailway.classificationsByTypeMain !== null){
        return props.classificationsRailway.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function classificationAddIdOption(props){
    if(props.classificationsRailway.railwayCargoAdditional !== null){
        return props.classificationsRailway.railwayCargoAdditional.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function classificationWagonSpecOption(props){
    if(props.classificationsRailway.wagonSpec !== null){
        return props.classificationsRailway.wagonSpec.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.name + ' ' + i.description.uk_UA}</option>
            }
        )
    }
}

function classificationTimberUnitCode(props){
    if(props.classificationsRailway.unitCode !== null){
        return props.classificationsRailway.unitCode.map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

async function createItem(props){
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            props.changeLoader(true)
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            const response = await fetch(endPoint, {
                method: "post",
                body: JSON.stringify({
                    classificationId: Number(props.createItems.classificationId),
                    additionalClassificationIds: [Number(props.createItems.additionalClassificationIds.railwayCargoAdditional)],
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    description: props.createItems.description,
                    unitCode: props.createItems.unitCode,
                    quantity: Number(props.createItems.quantity),
                    requestLifeTime: {
                        startDate: props.createItems.requestLifeTime.dateFrom,
                        endDate: props.createItems.requestLifeTime.dateTill
                    },
                    loadLifeTime: {
                        startDate: props.createItems.loadLifeTime.dateFrom,
                        endDate: props.createItems.loadLifeTime.dateTill
                    },
                    wagonTypes: props.createItems.wagonTypes,
                    routesAccountingWagonsPerRouteQuantity: props.createItems.routesAccountingWagonsPerRouteQuantity,
                    routesAccountingRouteNumber: props.createItems.routesAccountingRouteNumber,
                    routeQuantity: props.createItems.routeQuantity,
                    wagonSpecies: props.createItems.wagonSpec,
                    loadingRestriction: props.createItems.loadingRestriction
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemDescription('')
                    props.changeCreateItemAuctionId('')
                    props.changeAdditionalClassificationIds('')
                    props.changeCreateItemUnitValueCurrency('')
                    props.changeCreateItemUnitValueAmount('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
} 

async function saveItem(props){
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/item/${props.createItems.id}`;*/
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.id}`;
            props.changeLoader(true)
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    classificationId: Number(props.createItems.classificationId),
                    additionalClassificationIds: [Number(props.createItems.additionalClassificationIds.railwayCargoAdditional)],
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    description: props.createItems.description,
                    /*unitValue: {
                        currency: "UAH",
                        amount: 0,
                        valueAddedTaxIncluded: true
                    },*/
                    unitCode: props.createItems.unitCode,
                    quantity: Number(props.createItems.quantity),
                    requestLifeTime: {
                        startDate: props.createItems.requestLifeTime.dateFrom,
                        endDate: props.createItems.requestLifeTime.dateTill
                    },
                    loadLifeTime: {
                        startDate: props.createItems.loadLifeTime.dateFrom,
                        endDate: props.createItems.loadLifeTime.dateTill
                    },
                    wagonTypes: props.createItems.wagonTypes,
                    routesAccountingWagonsPerRouteQuantity: props.createItems.routesAccountingWagonsPerRouteQuantity,
                    routesAccountingRouteNumber: props.createItems.routesAccountingRouteNumber,
                    routeQuantity: props.createItems.routeQuantity,
                    wagonSpecies: props.createItems.wagonSpec,
                    loadingRestriction: props.createItems.loadingRestriction
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemDescription('')
                    props.changeCreateItemAuctionId('')
                    props.changeAdditionalClassificationIds('')
                    props.changeCreateItemUnitValueCurrency('')
                    props.changeCreateItemUnitValueAmount('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
}

async function delRestrict(props, value){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${props.createItems.id}/restriction/${value}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            props.changeLoader(false)
        }
        if(json1.hasOwnProperty('success') === true){
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                }else{
                    props.changeLoader(false)
                    await getAucionFromId(props, props.auctionsMy.id)
                }
            }
        }
    }
}

async function delRestrictExclusions(props, value){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/item/${props.createItems.id}/restriction/${value.id}/exclusions/${value.idEx}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopBankAccountError(json1)
        }
        if(json1.hasOwnProperty('success') === true){
            //await props.setPopBankAccountComplite(true)
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
            const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json2 = await response2.json()
                if(json2.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                }else{
                    props.changeLoader(false)
                    await getAucionFromId(props, props.auctionsMy.id)
                }
            }
        }
    }
}

////////////////////////////
async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemDescription('')
            props.changeCreateItemAuctionId('')
            props.changeAdditionalClassificationIds('')
            props.changeCreateItemUnitValueCurrency('')
            props.changeCreateItemUnitValueAmount('')
            props.changeCreateItemQuantity('')
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemDescription('')
            props.changeCreateItemAuctionId('')
            props.changeAdditionalClassificationIds('')
            props.changeCreateItemUnitValueCurrency('')
            props.changeCreateItemUnitValueAmount('')
            props.changeCreateItemQuantity('')
            props.setEditAuctionFromId(json)
        }
    }
}

function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(amount === null || amount === '' || amount === 'null'){
                return 'error'
            }
            return null
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        classificationsRailway: state.start.classificationsRailway,
        getAuctionsMy: state.start.auctionsGetMy,
        addressGetId: state.start.addressGetId,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByGenerationType,
    //auctionsGetClassificationsByTimberSortment,
    //auctionsGetClassificationsByTimberSpecies,
    //auctionsGetClassificationsByTimberClass,
    //auctionsGetClassificationsByTimberDiameter,
    //auctionsGetClassificationsByTimberLength,
    //auctionsGetClassificationsByTimberStorage,
    //auctionsGetClassificationsByTimberProductionYear,
    //auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    getMyAuction,
    auctionsGetClassificationsByWagonSpecies,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    popUpAddress,
    changeLoader,
    setUniversalError,
    changeCreateItemWagonSpecies,
    changeCreateItemLoadingRestriction,
    changeCreateItemWagonTypes,
    setCreateItemDateFrom1,
    setCreateItemDateTill1,
    setCreateItemDateFrom2,
    setCreateItemDateTill2,
    setCreateItemRouteQuantity,
    setCreateItemRoutesAccountingRouteNumber,
    setCreateItemRoutesAccountingWagonsPerRouteQuantity,
    changeCreateItemClassificationAddId,

    setCreateRestrictionFlag,
    setCreateRestrictionExclusionsFlag,
    setCreateRestrictionExclusionsObject,
    setCreateRestrictionExclusionsObjectType,
    setCreateRestrictionExclusionsObjectCode,
    setCreateRestrictionExclusionsObjectName,

    setCreateLoadObject,
    setCreateLoadObjectCode,
    setCreateLoadObjectName,
    setCreateUnloadObject,
    setCreateUnloadObjectCode,
    setCreateUnloadObjectName,

    setPopUpAuctionMyNull,

    setEditAuctionFromId,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)
