import React from 'react';
import styles from './helpful.module.css';

import { Switch, Route } from 'react-router-dom';

import Faq from './faq/faq.js';
import Presentations from './presentations/presentations.js';
import Publications from './publications/publications.js';

export default function Helpful() {
    return (
        <div className={styles.helpful_wrap} >
            <div className={styles.lotPages}>
                <div className={styles.lotContent}>
                    <Switch>
                        <Route path='/helpful/presentations'
                            render={ () => <Presentations /> }
                        />
                        <Route path='/helpful/faq'
                            render={ () => <Faq /> }
                        />
                        <Route path='/helpful/publications'
                            render={ () => <Publications /> }
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}