import React from "react"

const CancelIcon =( { color = '#92929D',style, widthHeight = [28, 28] })=> {
    return <svg 
                width={widthHeight[0]} 
                height={widthHeight[1]} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                    className={style}
                    fillRule="evenodd" 
                    clipRule="evenodd" 
                    d="M22.8598 19.942C24.0009 18.2439 24.6667 16.1998 24.6667 14.0001C24.6667 8.10904 19.8911 3.33341 14 3.33341C11.7985 3.33341 9.75268 4.00039 8.05372 5.14329L22.8598 19.942ZM21.1038 21.9572L6.03936 6.90023C4.35631 8.78601 3.33335 11.2736 3.33335 14.0001C3.33335 19.8911 8.10898 24.6667 14 24.6667C16.7284 24.6667 19.2175 23.6424 21.1038 21.9572ZM14 0.666748C21.3638 0.666748 27.3334 6.63628 27.3334 14.0001C27.3334 21.3639 21.3638 27.3334 14 27.3334C6.63622 27.3334 0.666687 21.3639 0.666687 14.0001C0.666687 6.63628 6.63622 0.666748 14 0.666748Z" 
                    fill={color}
                />
            </svg>
    
    
}

export default CancelIcon