import React from 'react';

import {connect} from 'react-redux';

import {changeLoader} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import {bankAccountGet} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../redux/actions.js';
import {setPopUpAuctionCreate} from '../../../../redux/actions/auctions/auctions.js';

import CreateAuction from './create/createAuction'
import EditAuction from './edit/editAuction'

const SwitcherTypeWorkAuction = ( props ) => {
    if(props.auctionsMy !== null){
        if(props.auctionsSwitchWindow !== null){
            switch(props.auctionsSwitchWindow){
                case 1:
                    return <EditAuction />
                case 2:
                    return <CreateAuction />
                default:
                    return null
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMy: state.start.auctionsMy,
    }
}

const mapDispatchToProps = {
    changeLoader,
    setDataAddress,
    bankAccountGet,
    setUniversalError,
    setPopUpAuctionCreate,
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitcherTypeWorkAuction)