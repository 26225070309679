import {
    PAYMENT_POP_UP,
    PAYMENT_POP_UP1,
    PAYMENT_POP_UP_CREATE_PDF,
    PAYMENT_POP_UP_GET_DATA,
    PAYMENT_POP_UP_SET_BANK,
} from "./types";

export function paymentPopup(value) {
    return {
        type: PAYMENT_POP_UP,
        payload: value
    }
}
export function paymentPopup1(value) {
    return {
        type: PAYMENT_POP_UP1,
        payload: value
    }
}

export function paymentPopupCreatePdf(value) {
    return {
        type: PAYMENT_POP_UP_CREATE_PDF,
        payload: value
    }
}

export function paymentPopupGetData(value) {
    return {
        type: PAYMENT_POP_UP_GET_DATA,
        payload: value
    }
}

export function paymentPopupSetBank(value) {
    return {
        type: PAYMENT_POP_UP_SET_BANK,
        payload: value
    }
}
