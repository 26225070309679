import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{

            let count = 0
            tmp = []
            for (let key in props.auction.items) {

                tmp[count] = <div key={count}>
                    {viewTypeAuction(props.auction.items[key], count)}
                </div>
                count = count++
            }
        }
    }
    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data){
    let tmpAddresPostalCode = null;
    let tmpAddresCountryName = null;
    let tmpAddresRegion = null;
    let tmpAddresLlocality = null;
    let tmpAddresIstreetAddress = null;
    let codKTU = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
        }
        if(data.address.hasOwnProperty('addressID') === true){
            if(data.address.addressID !== null){
                if(data.address.addressID.hasOwnProperty('id') === true){
                    if(data.address.addressID.id !== null){
                        codKTU = data.address.addressID.id
                    }
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}>
            {/*viewTitle(data)*/}
            </div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {data.hasOwnProperty('additionalClassifications') ? <TextFull
                title="Код CPVs:"
                data={additionalClassificationsId(data)}
            /> : null}
            <TextHalf
                title='Місцезнаходження'
                data={address}
            />
            <TextHalf
                title="Код об’єктів адміністративно-територіального устрою україни"
                data={codKTU}
            />
            {/*viewLeaseDuration(props)*/}



            <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
            {unitName(data)}
        </>
    )
}

function additionalClassificationsId(data){
    if(data.hasOwnProperty('additionalClassifications') === true){
        if(data.additionalClassifications.length > 0){
            return `${data.additionalClassifications[0].id} - ${data.additionalClassifications[0].description.uk_UA}`
        }
    }
    return null
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
