import React from 'react';
import styles from './createAuctionLoader.module.css';

import {connect} from 'react-redux';

const CreateAuctionLoader = () => {
    return <div className={styles.central}><div className={styles.loader}></div></div>
}

export default connect(null, null)(CreateAuctionLoader)
