import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import TextFullHeader from './textFullHeader'
import {modifiDateString} from '../../../../redux/modifiDate'

import {
    auctionsGetClassificationsByWagonSpecies
} from '../../../../redux/actions/auctions/auctions.js';

const StructureLot = (props) => {
    if(props.classificationsRailway.wagonSpec === null){
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }
        let header = {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsGetClassificationsByWagonSpecies(data, header)
    }
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            tmp = props.auction.items
            .map(
                (i, count) => {
                    return viewTypeAuction(props, i)
                }
            )
        }
        /*let count = 0
        tmp = []
        for (let key in props.auction.items) {
            let tmpZuMap = null
            if(props.auctionId.items[key].location !== null){
                if((props.auctionId.items[key].location.latitude !== null && props.auctionId.items[key].location.latitude !== '')  && (props.auctionId.items[key].location.longitude !== null && props.auctionId.items[key].location.longitude !== '')){
                    tmpZuMap = <ZuMap
                    latitude = {props.auctionId.items[key].location.latitude}
                    longitude = {props.auctionId.items[key].location.longitude}
                    />
                }
            }
            tmp[count] = <>
                {viewTypeAuction(props, props.auction.items[key], count)}
                {tmpZuMap}
            </>
            count = count++
        }*/
    }
    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewRestriction(props, i){
    let tmp = null
    if(i.hasOwnProperty('auctionRestriction') === true){
        if(i.auctionRestriction !== null){
            tmp = i.auctionRestriction.map(
                (e) => {
                    return (
                        <div className={styles.scladlotunMain}>
                            <TextHalf
                                title="Ознака об'єкту полігону навантаження"
                                data={typeRestrictPoligon(e.loadObject)}
                            />
                            <TextHalf
                                title="Код об'єкту полігону навантаження"
                                data={e.loadObjectCode}
                            />
                            <TextHalf
                                title="Найменування об'єкту полігону навантаження"
                                data={e.loadObjectName}
                            />
                            <TextHalf
                                title="Ознака об'єкту полігону вивантаження"
                                data={typeRestrictPoligon(e.unloadObject)}
                            />
                            <TextHalf
                                title="Код об'єкту полігону вивантаження"
                                data={e.unloadObjectCode}
                            />
                            <TextHalf
                                title="Найменування об'єкту полігону вивантаження"
                                data={e.unloadObjectName}
                            />
                            {viewRestrictionRestrict(props, e)}
                        </div>
                    )
                }
            )
        }
    }
    return (
        <div className={styles.scladlotunMain}>
            <TextFullHeader
                title="Обмеження полігону"
                data=''
            />
            {tmp}
        </div>
    )
}

function viewRestrictionRestrict(props, e){
    let tmp = null
    if(e.hasOwnProperty('auctionRestrictionExclusions') === true){
        if(e.auctionRestrictionExclusions !== null){
            tmp = e.auctionRestrictionExclusions.map(
                (z) => {
                    return (
                        <div className={styles.scladlotunMain}>
                            <TextHalf
                                title="Код об'єкту полігону виключення"
                                data={typeRestrictPoligon(z.exclusionObjectType)}
                            />
                            <TextHalf
                                title="Ознака об'єкту полігону виключення"
                                data={typeRestrictPoligon(z.exclusionObject)}
                            />
                            <TextHalf
                                title="Ознака приналежності до"
                                data={z.exclusionObjectCode}
                            />
                            <TextHalf
                                title="Найменування об'єкту полігону виключення"
                                data={z.exclusionObjectName}
                            />
                        </div>
                    )
                }
            )
        }
    }
    return (
        <div className={styles.scladlotunMain}>
            <TextFullHeader
                title="Виключення з полігону навантаження/вивантаження"
                data=''
            />
            {tmp}
        </div>
    )
}

function typeRestrictPoligon(data){
    switch(data){
        case '1':
            return 'Залізниця'
        case '2':
            return 'Залізнична адміністрація'
        case '3':
            return 'Станція'
        default: return ''
    }
}

function viewTypeAuction(props, data){
    let tmpAddresPostalCode = null;
    let tmpAddresCountryName = null;
    let tmpAddresRegion = null;
    let tmpAddresLlocality = null;
    let tmpAddresIstreetAddress = null;
    let codKTU = null;
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            if(data.address.hasOwnProperty('postalCode') === true){
                tmpAddresPostalCode = data.address.postalCode;
            }
            if(data.address.hasOwnProperty('countryName') === true){
                tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(data.address.hasOwnProperty('region') === true){
                tmpAddresRegion = data.address.region.uk_UA;
            }
            if(data.address.hasOwnProperty('locality') === true){
                tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(data.address.hasOwnProperty('streetAddress') === true){
                tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
            if(data.address.hasOwnProperty('addressIdentifier') === true){
                if(data.address.addressIdentifier !== null){
                    if(data.address.addressIdentifier.hasOwnProperty('identifier') === true){
                        if(data.address.addressIdentifier.identifier !== null){
                            codKTU = data.address.addressIdentifier.identifier
                        }
                    }
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(data.classification !== null){
        if(data.classification.hasOwnProperty('description')){
            tmpClassificationScheme = data.classification.scheme
            tmpClassificationId = data.classification.id
            tmpClassificationDescription = data.classification.description.uk_UA
        }
    }
    let address = `${tmpAddresPostalCode}, ${tmpAddresCountryName}, ${tmpAddresRegion}, ${tmpAddresLlocality}, ${tmpAddresIstreetAddress}`
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <div className={styles.borderLine}>
            {/*<TextFull
                title="Назва об'єкта"
                data={data.title}
            />*/}
            </div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            <TextFull
                title="Код CPVs:"
                data={additionalClassificationsId(data)}
            />
            { data.hasOwnProperty('address') ? data.address !== null ? <TextHalf
                title='Місцезнаходження'
                data={address}
            /> : null : null}
            { data.hasOwnProperty('address') ? data.address !== null ? <TextHalf
                title='Код об’єктів адміністративно-територіального устрою України'
                data={codKTU}
            /> : null : null}

            {viewDataItem(props, data)}
            {viewRestriction(props, data)}

            <TextHalf
                title="Кількість одиниць"
                data={data.quantity}
            />
            {unitName(data)}
        </>
    )
}

function additionalClassificationsId(data){
    if(data.hasOwnProperty('additionalClassifications') === true){
        if(data.additionalClassifications !== null){
            if(data.additionalClassifications.length > 0){
                return `${data.additionalClassifications[0].id} - ${data.additionalClassifications[0].description.uk_UA}`
            }
        }
    }
}

function viewDataItem(props, i){
    let tmp
    let tmpDate = null
    if(i.hasOwnProperty('requestLifeTime') === true){
        if(i.requestLifeTime !== null){
            tmpDate = <>
                <TextHalf
                    title="Дата початку"
                    data={modifiDateString(i.requestLifeTime.dateFrom, 3, false)}
                />
                <TextHalf
                    title="Дата завершення"
                    data={modifiDateString(i.requestLifeTime.dateTill, 3, false)}
                />
            </>
        }
    }
    let tmpLocation = null;
    if(i.hasOwnProperty('location') === true){
        if(i.location !== null){
            tmpLocation = <>
                <TextFullHeader
                    title='Географічні координати розташування'
                    data=''
                />
                <TextHalf
                    title="Широта"
                    data={i.location.latitude}
                />
                <TextHalf
                    title="Довгота"
                    data={i.location.longitude}
                />
                <TextHalf
                    title="Висота"
                    data={i.location.elevation}
                />
            </>
        }
    }
    let tmpLoadLifeTime = null
    if(i.hasOwnProperty('loadLifeTime') === true){
        if(i.loadLifeTime !== null){
            let tmpB = `${modifiDateString(i.loadLifeTime.dateFrom, 3, false)} - ${modifiDateString(i.loadLifeTime.dateTill, 3, false)}`
            tmpLoadLifeTime = <TextHalf
                title="Період подачі вагону"
                data={tmpB}
            />
        }
    }
    tmp = <>
        {tmpLoadLifeTime}
        {i.loadingRestriction !== null ? <TextHalf
            title="Полігон навантаження"
            data={i.loadingRestriction}
        /> : null}
        {tmpLocation}
        {tmpDate}
        {i.routeQuantity !== null ? <TextHalf
            title="Кількість маршрутів"
            data={i.routeQuantity}
        /> : null}
        {i.routesAccountingRouteNumber !== null ? <TextHalf
            title="Порядковий номер маршруту"
            data={i.routesAccountingRouteNumber}
        /> : null}
        {i.routesAccountingWagonsPerRouteQuantity !== null ? <TextHalf
            title="Кількість вагонів по кожному маршруту"
            data={i.routesAccountingWagonsPerRouteQuantity}
        /> : null}
        <TextHalf
            title="Код роду вагонів"
            data={classificationWagonSpecOption(props, i.wagonSpecies)}
        />
        {i.wagonTypes !== null ? <TextHalf
            title="Перелік кодів типів вагонів"
            data={i.wagonTypes}
        /> : null}
    </>
    return tmp
}

function classificationWagonSpecOption(props, spec){
    if(props.classificationsRailway.wagonSpec !== null){
        if(props.classificationsRailway.wagonSpec.length > 0){
            return props.classificationsRailway.wagonSpec
            .filter(
                (i) => {
                    return i.classificationId === spec
                }
            )
            .map(
                (i) => {
                    return i.description.uk_UA
                }
            )
        }
    }
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
        classificationsRailway: state.start.classificationsRailway,
    }
}

const mapDispatchToProps = {
    auctionsGetClassificationsByWagonSpecies,
}

export default connect(mapStateToProps, mapDispatchToProps)(StructureLot)
