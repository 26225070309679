import React, {useState} from 'react';
import styles from './textArea.module.css';
import {useDispatch} from 'react-redux';

const TextArea = (props) => {
    useDispatch();
    // const target = useSelector(state => state.start.search[props.value])

    const [value, setValue] = useState(props.data.target || '')
    const [label] = useState(props.data.necessarily ? `${props.data.label} *` : props.data.label)
    const [textAreaAlert] = useState(props.data.alert ? styles.alert : styles.textArea)

    return (
        <>
            <div className={styles.inPutInfo}>
                <textarea type="text"
                    className={textAreaAlert}
                    value={value}
                    placeholder={props.data.placeholder}
                    onInput={e => {
                        setValue(e.target.value)
                        props.data.action(e.target.value)
                    }}
                    // onKeyDown={(e) =>  keyPressDispatch(e) }
                    // onBlur={ () => dispValue() }
                />
                { props.data.label ? 
                    <div className={styles.label}>
                        {label}
                    </div> : null
                }
            </div>
            {props.data.descr ? <div className={styles.descript}>{props.data.descr}</div> : null}
        </>
    )

}


export default TextArea