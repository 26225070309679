import React from 'react';
import styles from './myBidsHeader.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../../redux/createAddress'

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
} from '../../../../redux/actions/auctions/auctions';

import {
    getMyAuction,
    changeCreateAuctionTenderAttempts,
    changeMyCabinetCurentPage,
} from '../../../../redux/actions';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount';

const MyBidsHeader = ( ) => {
    return (
        <div className={styles.wrap_listAuctions}>
            <div className={styles.wrap_listAuctions_topLine}>
                <div className={styles.listAuctions_title}>Мої пропозиції</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auctionsMyNew: state.start.auctionsMyNew,
        token: state.start.token,
        langFlag: state.start.langFlag,
        addressNew: state.start.addressNew,
        myCabinetPageCounter: state.start.myCabinetPageCounter,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    getMyAuction,
    changeCreateAuctionTenderAttempts,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,

    auctionsGetMy,
    changeMyCabinetCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBidsHeader)
