import React from 'react';
import style from './login.module.css';

import {connect} from 'react-redux';

import {setPopUpLogin, changeLoader} from '../../../redux/actions.js';
import {postGetToken, setProfileNull} from '../../../redux/actions/default/default.js';
import {setUniversalError} from '../../../redux/actions.js';

const PopUpLogin = (props) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popUpExit}
                    onClick={
                        ()=>{
                            props.setPopUpLogin(false)
                        }
                    }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>
                </div>
                <div className={style.popUpTitle}>Доступ до майданчика</div>
                <div className={style.inputWrap}>
                    <input className={style.logInPut} type='text' id='login' />
                    <div className={style.logInPutLable}>Логін</div>
                </div>
                <div className={style.inputWrap}>
                    <input className={style.logInPut}
                        type='password'
                        id='password' />
                    <div className={style.logInPutLable}>Пароль</div>
                </div>
                <div
                    className={style.popup_message_button}
                    onClick={
                        ()=>{
                            if(document.getElementById('login').value !== '' && document.getElementById('password').value !== ''){
                                postLogin(
                                    props,
                                    document.getElementById('login').value,
                                    document.getElementById('password').value
                                )
                                props.setProfileNull(null)
                                props.setPopUpLogin(false)
                            }else if(document.getElementById('password').value !== ''){
                                postSinglPassword(props, document.getElementById('password').value)
                                props.setProfileNull(null)
                                props.setPopUpLogin(false)
                            }
                        }
                    } 
                >Вхід</div>
            </div>
        </div>
    )
}

async function postLogin(props, login, password){
    let data = JSON.stringify({
        "client_id" : "1",
        "client_secret" : "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=",
        "grant_type" : "password",
        "username" : login,
        "password" : password
    });
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/token`
    props.changeLoader(true)
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: data
    })
        .catch(error => {
            props.changeLoader(false)
            console.error('Error during service worker registration:', error);
        })
    props.changeLoader(false)
    if(response === undefined || response === 'undefined'){
        let text = 'Невірний логін або пароль'
        let tmp = JSON.stringify({title: 'Помилка авторизації', message: text})
        props.setUniversalError(tmp)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let text = 'Невірний логін або пароль'
            let tmp = JSON.stringify({title: 'Помилка авторизації', message: text})
            props.setUniversalError(tmp)
        }else{
            json.timeStamp = await Date.now() + json.expires_in * 1000;
            await props.postGetToken(json)
            window.location.reload()
        }
    }
}

async function postSinglPassword(props, password){
    let data = JSON.stringify({
        "client_id" : "1",
        "client_secret" : "uHc4OVwQfGgKVYVpK1FfGOLFg36wN7ePAU258whzYws=",
        "grant_type" : "temporary_password",
        "temporary_password" : password
    });
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/token`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: data
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
        let text = 'Невірний логін або пароль'
        let tmp = JSON.stringify({title: 'Помилка авторизації', message: text})
        props.setUniversalError(tmp)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let text = 'Невірний логін або пароль'
            let tmp = JSON.stringify({title: 'Помилка авторизації', message: text})
            props.setUniversalError(tmp)
        }else{
            json.timeStamp = await Date.now() + json.expires_in * 1000;
            props.postGetToken(json)
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
    }
}

const mapDispatchToProps = {
    setPopUpLogin,
    postGetToken,
    setProfileNull,
    setUniversalError,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpLogin)