import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import TextFullHeader from './textFullHeader'
import { dateTransform } from '../../../../redux/modifiDate.js'
import { checkProp, isMongo } from '../../../../redux/check';

import ZuMap from '../../../map/zuMapsNotEdit'

const StructureLot = (props) => {
    let tmp = null;
    if(props.auction !== null){
        if(props.auction.items.length === 0){
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані товари відсутні</div>
        }else{
            tmp = props.auction.items
            .map(
                (i, count) => {
                    let tmpZuMap = null
                    if(i.hasOwnProperty('location')  === true){
                        if(i.location !== null){
                            if((i.location.latitude !== null && i.location.latitude !== '')  && (i.location.longitude !== null && i.location.longitude !== '')){
                                tmpZuMap = <ZuMap
                                latitude = {i.location.latitude}
                                longitude = {i.location.longitude}
                                />
                            }
                        }
                    }
                    return (
                        <>
                            {viewTypeAuction(i)}
                            {tmpZuMap}
                        </>
                    )
                }
            )
        }
    }
    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data){
    let tmpAddresPostalCode = '';
    let tmpAddresCountryName = '';
    let tmpAddresRegion = '';
    let tmpAddresLlocality = '';
    let tmpAddresIstreetAddress = '';
    if(checkProp(data, 'address')){
        if(data.address !== null){
            if(checkProp(data.address,'postalCode')){
                tmpAddresPostalCode = data.address.postalCode;
            }
            if(checkProp(data.address,'countryName')){
                tmpAddresCountryName = data.address.countryName.uk_UA;
            }
            if(checkProp(data.address,'region')){
                tmpAddresRegion = data.address.region.uk_UA;
            }
            if(checkProp(data.address,'locality')){
                tmpAddresLlocality = data.address.locality.uk_UA;
            }
            if(checkProp(data.address,'streetAddress')){
                tmpAddresIstreetAddress = data.address.streetAddress.uk_UA;
            }
            if(checkProp(data.address,'addressIdentifier')){
                if(checkProp(data.address.addressIdentifier,'identifier')){
                }
            }
        }
    }

    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if(checkProp(data, 'classification')){
        tmpClassificationScheme = data.classification.scheme
        tmpClassificationId = data.classification.id
        tmpClassificationDescription = checkProp(data.classification, 'description') &&
            checkProp(data.classification.description, 'uk_UA') ? data.classification.description.uk_UA : null
    }
    let address = ''
    if(tmpAddresPostalCode !== '') address = address + tmpAddresPostalCode + ', '
    if(tmpAddresCountryName !== '') address = address + tmpAddresCountryName + ', '
    if(tmpAddresRegion !== '') address = address + tmpAddresRegion + ', '
    if(tmpAddresLlocality !== '') address = address + tmpAddresLlocality + ', '
    if(tmpAddresIstreetAddress !== '') address = address + tmpAddresIstreetAddress
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    

    
    return (
        <>
            <div className={styles.borderLine}></div>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            {checkProp(data, 'additionalClassifications') ? addClassif(data) : null}
            {address !== '' ? <TextHalf
                title='Місцезнаходження'
                data={address}
            /> : null}
            {zuRegistrationDetails(data)}
            { checkProp(data,'commercialSellItemType') ? data.commercialSellItemType !== null ? <TextHalf
                title="Тип активу"
                data={viewBasicSellItemType(data)}
            /> : null : null}
            <TextHalf
                title="Тип об'єкта майна"
                data={strTypeObject(data)}
            />

            { checkProp(data,'limitedCirculationProperty') ? data.limitedCirculationProperty !== null ? <TextHalf
                title="Обмежене в обороті майно"
                data={trueFalseText(data.limitedCirculationProperty)}
            /> : null : null}

            { checkProp(data,'limitedCirculationPropertyDescription') ?
                data.limitedCirculationPropertyDescription !== null ? <TextHalf
                title="Обмеження для майна з обмеженим оборотом"
                data={isMongo(data.limitedCirculationPropertyDescription)}
            /> : null : null}

            {jointPropertyComplexQuantityOff(data)}
            {unitName(data)}
            {zuItemType(data)}
            {dgfLocation(data)}
        </>
    )
}

function trueFalseText(data){
    if(data) return 'Так'
    return 'Ні'
}

function addClassif(data){
    let tmp
    tmp = data.additionalClassifications.map(
        (i) => {
            let tmpAddClassificationScheme
            let tmpAddClassificationId
            let tmpAddClassificationDescription
            tmpAddClassificationScheme = i.scheme
            tmpAddClassificationId = i.id
            tmpAddClassificationDescription = checkProp(i, 'description') &&
                checkProp(i.description, 'uk_UA') ? i.description.uk_UA : null
            let tmpAddClassificationNameStr = `Додатковий класифікатор об'єкта ${tmpAddClassificationScheme}`
            let tmpAddClassificationSchemeStr = `${tmpAddClassificationId} - ${tmpAddClassificationDescription}`
            return (
                <TextFull
                    title={tmpAddClassificationNameStr}
                    data={tmpAddClassificationSchemeStr}
                />
            )
        }
    )
    return tmp
}

function jointPropertyComplexQuantityOff(data){
    if(data !== null){
        if(checkProp(data,'itemProps')){
            if(data.itemProps !== null){
                if(data.itemProps.itemPropsType !== 'jointPropertyComplex'){
                    return (
                        <TextHalf
                            title="Кількість одиниць"
                            data={data.quantity}
                        />
                    )
                }
            }
        }
    }
    return null
}

function viewBasicSellItemType(data){
    if(data.commercialSellItemType === 'claimRights') return 'Права вимоги'
    if(data.commercialSellItemType === 'assets') return 'Майно'
}

function unitName(data){
    if(data !== null){
        if(data.unit !== null){
            if(data.unit.name !== null){
                return <TextHalf
                    title="Одиниці виміру"
                    data={data.unit.name.uk_UA}
                />
            }
        }
    }
    return null
}

function strTypeObject(data){
    if(data.hasOwnProperty('itemProps')  === true){
        if(data.itemProps !== null){
            if(data.itemProps.itemPropsType === 'land'){
                return "Земельна ділянка"
            }else if(data.itemProps.itemPropsType === 'jointPropertyComplex'){
                return "ЄМК"
            }else if(data.itemProps.itemPropsType === "vehicle"){
                return "Транспортний засіб"
            }else if(data.itemProps.itemPropsType === 'realEstate'){
                return "Нерухоме майно"
            }
        }
    }
    return 'Інше'
}

function zuRegistrationDetails(data){
    if(data !== null){
        if(data.hasOwnProperty('registrationDetails') === true){
            if(data.registrationDetails !== null){
                let tmp = checkProp(data.registrationDetails, 'registrationDate') ? dateTransform(data.registrationDetails.registrationDate, 'time') : null
                
                return <>
                    <TextFullHeader
                        title="Інформація щодо реєстрації:"
                        data=''
                    />
                    <TextHalf
                        title="Стан державної реєстрації об'єкту"
                        data={strRegistrationStatus(data.registrationDetails.registrationStatus)}
                    />
                    {data.registrationDetails.registrationID !== null ? <TextHalf
                        title="Номер запису"
                        data={data.registrationDetails.registrationID}
                    /> : null}
                    {tmp !== null ? <TextHalf
                        title="Дата реєстрації"
                        data={tmp}
                    /> : null}
                </>
            }
        }
    }
    return null
}
function strRegistrationStatus(data){
    if(data === 'notRegistered'){
        return 'Не зареєстровано'
    }else if(data === 'oldRegistration'){
        return 'Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно'
    }else if(data === 'properlyRegistered'){
        return 'Зареєстровано в Державному реєстрі речових прав на нерухоме майно'
    }else if(data === 'registrationIsNotRequired'){
        return 'Реєстрація не вимагається'
    }
}

function dgfLocation(data){
    if(data !== null){
        if(data.hasOwnProperty('location') === true){
            if(data.location !== null){
                return <>
                    <TextFullHeader
                        title="Географічні координати місця розташування:"
                        data=''
                    />
                    <TextHalf
                        title="Широта"
                        data={data.location.latitude}
                    />
                    <TextHalf
                        title="Довгота"
                        data={data.location.longitude}
                    />
                    {/*<TextHalf
                        title="Висота"
                        data={data.elevation}
                    />*/}
                </>
            }
        }
    }
    return null
}

function zuItemType(data){
    if(data.hasOwnProperty('itemProps') === true){
        if(data.itemProps !== null){
            if(data.itemProps.itemPropsType === "land"){
                return dgfLandProps1(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'jointPropertyComplex'){
                return dgfJointPropertyComplexProps(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'vehicle'){
                return dgfVehicleProps(data.itemProps)
            }else if(data.itemProps.itemPropsType === 'realEstate'){
                return dgfRealEstateProps(data.itemProps)
            }
        }
    }
}

function dgfLandProps1(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, земельна ділянка:"
                data=''
            />
            {data.cadastralNumber ? <TextHalf
                title="Кадастровий номер"
                data={data.cadastralNumber}
            /> : null}
            {data.landArea ? <TextHalf
                title="Площа ділянки, га"
                data={data.landArea}
            /> : null}
            {data.ownershipType ? <TextHalf
                title="Тип власності"
                data={viewOwnership(data.ownershipType)}
            /> : null}
            {data.landIntendedUse ? <TextHalf
                title="Поточне використання земельної ділянки"
                data={data.landIntendedUse}
            /> : null}
            {data.hasOwnProperty('hasEncumbrances') === true
                ? <TextHalf
                    title="Присутні обтяження"
                    data={data.hasEncumbrances === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasEncumbrances') === true && data.hasEncumbrances === true
                ? <TextHalf
                    title="Інформація про наявні обтяження"
                    data={getEncumbrances(data.encumbrances)}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true
                ? <TextHalf
                    title="Наявні співвласники"
                    data={data.hasJointOwnership === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true && data.hasJointOwnership === true
                ? <TextHalf
                    title="Інформація про співвласників"
                    data={data.jointOwnership}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true
                ? <TextHalf
                    title="Інженерні мережі наявні"
                    data={data.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true && data.hasUtilitiesAvailability === true
                ? <TextHalf
                    title="Інформація про інженерні мережі"
                    data={data.utilitiesAvailability}
                />
                : null
            }
        </>
    }
}

function viewOwnership(data){
    switch(data){
        case "state": return 'Державна'
        case "private": return 'Приватна'
        case "municipal": return 'Комунальна'
        case "compatible": return 'Сумісна'
        case "unknown": return 'Невідомо'
        default: return ''
    }
}

function dgfRealEstateProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, нерухомість:"
                data=''
            />
            <TextHalf
                title="Загальна площа будівлі, кв.м."
                data={data.totalBuildingArea}
            />
            <TextHalf
                title="Загальна площа об'єкта в будівлі, кв.м."
                data={data.totalObjectArea}
            />
            <TextHalf
                title="Корисна площа об'єкта в будівлі, кв.м."
                data={data.usableArea}
            />
            <TextHalf
                title="Рік будівництва"
                data={data.constructionYear}
            />
            {strConstructionTechnology(data)}
            <TextHalf
                title="Житлова площа, кв. м."
                data={data.livingArea}
            />
            <TextHalf
                title="Площа кухні, кв. м."
                data={data.kitchenArea}
            />
            <TextHalf
                title="Площа ділянки, кв. м."
                data={data.landArea}
            />
            {strLocationInBuilding(data)}
            <TextHalf
                title="Номер поверху або поверхів"
                data={data.floors}
            />

            {checkProp(data, 'notLivingArea') ? <TextHalf
                title="Не житлова площа, кв. м"
                data={data.notLivingArea}
            /> : null}
            {checkProp(data, 'numberOfRooms') ? <TextHalf
                title="Кількість кімнат або приміщень"
                data={data.numberOfRooms}
            /> : null}
            {checkProp(data, 'numberOfStoreys') ? <TextHalf
                title="Поверховість будинку"
                data={data.numberOfStoreys}
            /> : null}
            {checkProp(data, 'realEstateTenantsInfo') ? <TextFull
                title="Інформація про наявність інших зареєстрованих, крім боржника, мешканців (зокрема дітей), орендарів та інших користувачів приміщення"
                data={data.realEstateTenantsInfo}
            /> : null}
            {checkProp(data, 'utilityRoomsDescription') ? <TextFull
                title="Інформація про допоміжні, підсобні приміщення та споруди"
                data={data.utilityRoomsDescription}
            /> : null}



            {checkProp(data, 'generalCondition') ? <TextHalf
                title="Технічний стан об'єкта"
                data={data.generalCondition}
            /> : null}
            {checkProp(data, 'serviceElectricity') ? <TextHalf
                title="Чи приєднаний об'єкт до електромережі"
                data={viewTrueOrFalse(data.serviceElectricity)}
            /> : null}
            {checkProp(data, 'powerSupplyCapacity') ? <TextHalf
                title="Потужність електромережі, кВт"
                data={data.powerSupplyCapacity}
            /> : null}
            {strPowerSupplyClass(data)}
            {checkProp(data, 'serviceWater') ? <TextHalf
                title="Водозабезпечення присутнє"
                data={viewTrueOrFalse(data.serviceWater)}
            /> : null}
            {checkProp(data, 'serviceSewerage') ? <TextHalf
                title="Каналізація присутня"
                data={viewTrueOrFalse(data.serviceSewerage)}
            /> : null}
            {checkProp(data, 'serviceGas') ? <TextHalf
                title="Газифікація присутня"
                data={viewTrueOrFalse(data.serviceGas)}
            /> : null}
            {checkProp(data, 'serviceCentralHeating') ? <TextHalf
                title="Централізоване опалення присутнє"
                data={viewTrueOrFalse(data.serviceCentralHeating)}
            /> : null}
            {checkProp(data, 'serviceAutonomousHeating') ? <TextHalf
                title="Автономне опалення присутнє"
                data={viewTrueOrFalse(data.serviceAutonomousHeating)}
            /> : null}
            {checkProp(data, 'serviceHeatingCounter') ? <TextHalf
                title="Лічильник опалення присутній"
                data={viewTrueOrFalse(data.serviceHeatingCounter)}
            /> : null}
            {checkProp(data, 'serviceVentilation') ? <TextHalf
                title="Вентиляція присутня"
                data={viewTrueOrFalse(data.serviceVentilation)}
            /> : null}
            {checkProp(data, 'serviceAirConditioning') ? <TextHalf
                title="Кондиціонування присутнє"
                data={viewTrueOrFalse(data.serviceAirConditioning)}
            /> : null}
            {checkProp(data, 'servicePhone') ? <TextHalf
                title="Телефонізація присутня"
                data={viewTrueOrFalse(data.servicePhone)}
            /> : null}
            {checkProp(data, 'serviceTV') ? <TextHalf
                title="Телебачення присутнє"
                data={viewTrueOrFalse(data.serviceTV)}
            /> : null}
            {checkProp(data, 'serviceInternet') ? <TextHalf
                title="Інтернет присутній"
                data={viewTrueOrFalse(data.serviceInternet)}
            /> : null}
            {checkProp(data, 'serviceElevator') ? <TextHalf
                title="Ліфт присутній"
                data={viewTrueOrFalse(data.serviceElevator)}
            /> : null}
            {checkProp(data, 'serviceSecurityAlarm') ? <TextHalf
                title="Охоронна сигналізація присутня"
                data={viewTrueOrFalse(data.serviceSecurityAlarm)}
            /> : null}
            {checkProp(data, 'serviceFireAlarm') ? <TextHalf
                title="Пожежна сигналізація присутня"
                data={viewTrueOrFalse(data.serviceFireAlarm)}
            /> : null}
            {checkProp(data, 'servicesDescription') ? <TextHalf
                title="Додаткова інформація щодо комунікацій, що є в об'єкті"
                data={data.servicesDescription}
            /> : null}
            {checkProp(data, 'servicesAccounting') ? <TextHalf
                title="Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                data={data.servicesAccounting}
            /> : null}
            {checkProp(data, 'landTax') ? <TextHalf
                title="Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                data={data.landTax}
            /> : null}
        </>
    }
    return null
}
function strConstructionTechnology(data){
    if(data.constructionTechnology !== null){
        let tmp = ''
        if(data.constructionTechnology === 'brick'){
            tmp = 'Цегла'
        }else if(data.constructionTechnology === 'insulatedPanel'){
            tmp = 'Утеплена панель'
        }else if(data.constructionTechnology === 'monolithicFrame'){
            tmp = 'Монолітно-каркасна'
        }else if(data.constructionTechnology === 'other'){
            tmp = 'Iнше'
        }else if(data.constructionTechnology === 'panel'){
            tmp = 'Панель'
        }
        return (
            <TextHalf
                title="Технологія будівництва"
                data={tmp}
            />
        )
    }
}
function strLocationInBuilding(data){
    if(data.locationInBuilding !== null){
        let tmp = ''
        if(data.locationInBuilding === 'aboveGround'){
            tmp = 'Надземний'
        }else if(data.locationInBuilding === 'attic'){
            tmp = 'Мансардний'
        }else if(data.locationInBuilding === 'basement'){
            tmp = 'Підвальний'
        }else if(data.locationInBuilding === 'pedimental'){
            tmp = 'Цокольний'
        }else if(data.locationInBuilding === 'technical'){
            tmp = 'Технічний'
        }
        return (
            <TextHalf
                title="Місце розташування об’єкта в будівлі"
                data={tmp}
            />
        )
    }
}
function strPowerSupplyClass(data){
    if(checkProp(data, 'powerSupplyClass')){ 
        if(data.powerSupplyClass !== null){
            let tmp
            if(data.powerSupplyClass === 'first'){
                tmp = 'Перший ступінь'
            }else if(data.powerSupplyClass === 'over50'){
                tmp = 'Понад 50 кВт'
            }else if(data.powerSupplyClass === 'second'){
                tmp = 'Другий ступінь'
            }else{
                tmp = data.powerSupplyClass
            }
            return (
                <TextHalf
                    title="Ступінь потужності електромережі"
                    data={tmp}
                />
            )
        }
    }
    return null
}


function dgfJointPropertyComplexProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, єдиний майновий комплекс:"
                data=''
            />
            <TextHalf
                title="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                data={data.quantityAndNomenclature}
            />
            <TextHalf
                title="Кількість робочих місць, ociб"
                data={data.workplacesQuantity}
            />
            <TextHalf
                title="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                data={data.buildingsInformation}
            />
            <TextHalf
                title="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                data={data.landPlotInformation}
            />
            <TextHalf
                title="Основні зобов’язання (договірні та позадоговірні)"
                data={data.mainObligations}
            />
            {data.hasOwnProperty('landProps') ? dgfLandProps(data.landProps) : null}
        </>
    }
}

function dgfVehicleProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Технічні характеристики, транспортний засіб:"
                data=''
            />
            <TextHalf
                title="Марка транспортного засобу"
                data={data.brand}
            />
            <TextHalf
                title="Модель транспортного засобу"
                data={data.model}
            />
            <TextHalf
                title="Рік випуску"
                data={data.productionYear}
            />
            <TextHalf
                title="Об’єм двигуна, см³"
                data={data.engineCapacity}
            />
            {strFuelType(data)}
            {strTransmissionType(data)}
            <TextHalf
                title="Колір"
                data={data.color}
            />
            <TextHalf
                title="Пробіг, км"
                data={data.kilometrage}
            />
            <TextHalf
                title="Номер кузова або шасі"
                data={data.identificationNumber}
            />
            <TextHalf
                title="Комплектація"
                data={data.configuration}
            />
            <TextHalf
                title="Технічний стан"
                data={data.condition}
            />
            <TextHalf
                title="Потреба у ремонті"
                data={viewTrueOrFalse(data.damagePresence)}
            />
            <TextHalf
                title="Опис пошкоджень"
                data={data.damagedDescription}
            />
        </>
    }
}

function strFuelType(data){
    if(data.fuelType !== null){
        let tmp = ''
        if(data.fuelType === 'diesel'){
            tmp = 'Дизель'
        }else if(data.fuelType === 'electric'){
            tmp = 'Електро'
        }else if(data.fuelType === 'gasoline'){
            tmp = 'Бензин'
        }else if(data.fuelType === 'hybrid'){
            tmp = 'Гібридний'
        }else if(data.fuelType === 'liquefiedPetroleumGas'){
            tmp = 'Зріджений нафтовий газ'
        }else if(data.fuelType === 'naturalGas'){
            tmp = 'Газ'
        }else if(data.fuelType === 'other'){
            tmp = 'Інший'
        }
        return (
            <TextHalf
                title="Тип палива"
                data={tmp}
            />
        )
    }
}
function strTransmissionType(data){
    if(data.transmission !== null){
        let tmp = ''
        if(data.transmission === 'automatic'){
            tmp = 'Автоматична'
        }else if(data.transmission === 'manual'){
            tmp = 'Механічна'
        }else if(data.transmission === 'other'){
            tmp = 'Інше'
        }else if(data.transmission === 'semiAutomatic'){
            tmp = 'Напівавтоматична'
        }
        return (
            <TextHalf
                title="Тип коробки передач"
                data={tmp}
            />
        )
    }
}

function dgfLandProps(data){
    if(data !== null){
        return <>
            <TextFull
                title="Характеристики земельної ділянки:"
                data=''
            />
            {data.hasOwnProperty('landArea') ? <TextHalf
                title="Площа ділянки, га"
                data={data.landArea}
            /> : null}
            <TextHalf
                title="Кадастровий номер"
                data={data.cadastralNumber}
            />
            {strOwnershipType(data)}
            <TextHalf
                title="Поточне використання"
                data={data.intendedUse}
            />
            {data.hasOwnProperty('hasEncumbrances') === true
                ? <TextHalf
                    title="Присутні обтяження"
                    data={data.hasEncumbrances === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasEncumbrances') === true && data.hasEncumbrances === true
                ? <TextHalf
                    title="Інформація про наявні обтяження"
                    data={getEncumbrances(data.encumbrances)}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true
                ? <TextHalf
                    title="Наявні співвласники"
                    data={data.hasJointOwnership === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasJointOwnership') === true && data.hasJointOwnership === true
                ? <TextHalf
                    title="Інформація про співвласників"
                    data={data.jointOwnership}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true
                ? <TextHalf
                    title="Інженерні мережі наявні"
                    data={data.hasUtilitiesAvailability === true ? 'наявні' : 'відсутні'}
                />
                : null
            }
            {data.hasOwnProperty('hasUtilitiesAvailability') === true && data.hasUtilitiesAvailability === true
                ? <TextHalf
                    title="Інформація про інженерні мережі"
                    data={data.utilitiesAvailability}
                />
                : null
            }
        </>
    }
}

function getEncumbrances(data){
    switch(data){
        case "noEncumbrances": return 'Обтяження відсутні'
        case "arrest": return 'Арешт нерухомого майна'
        case "collateral": return 'Податкова застава'
        case "restraintsOnAlienation": return 'Заборона відчуження'
        case "otherEncumbrances": return 'Інші обтяження'
        default: return null
    }
}

function strOwnershipType(data){
    if(data.ownershipType !== null){
        let tmp = ''
        if(data.ownershipType === 'compatible'){
            tmp = 'Сумісна'
        }else if(data.ownershipType === 'municipal'){
            tmp = 'Комунальна'
        }else if(data.ownershipType === 'private'){
            tmp = 'Приватна'
        }else if(data.ownershipType === 'state'){
            tmp = 'Державна'
        }else if(data.ownershipType === 'unknown'){
            tmp = 'Невідомо'
        }
        return (
            <TextHalf
                title="Тип власності"
                data={tmp}
            />
        )
    }
}

function viewTrueOrFalse(data){
    if(data === undefined || data === 'undefined') return 'Невідомо'
    if(data) return 'Так'
    return 'Ні'
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
