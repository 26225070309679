import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const XDocumentRequirementsString = (props) => {
    if(props.auctionId !== null){
        if (props.auctionId.sellingMethod.includes('subsoil-english') ||
            props.auctionId.sellingMethod.includes('subsoil-dutch')) {
            return null
        }
        if (
            (props.auctionId.hasOwnProperty('xDocumentRequirements') === true) &&
            (props.auctionId.xDocumentRequirements !== null)) {
            return (
                <TextHalf
                    title="Перелік та вимоги до оформлення документів"
                    data={props.auctionId.xDocumentRequirements.uk_UA}
                />
            )
        }
        if ((props.auctionId.hasOwnProperty('x_documentRequirements') === true) &&
            (props.auctionId.x_documentRequirements !== null)) {
            return (
                <TextHalf
                    title="Перелік та вимоги до оформлення документів"
                    data={props.auctionId.x_documentRequirements.uk_UA}
                />
            )
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(XDocumentRequirementsString)