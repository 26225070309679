import React from 'react';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../../../redux/modifiDate'
import TextHalf from '../../../../elements/viewData/textHalf'

const ContractComponentsDate = (props) => {
    let tmp = null
    if(props.dateData !== null){
        tmp = modifiDateString(props.dateData, 3, false)
    }
    return (
        <TextHalf
            title={props.dateText}
            data={tmp}
        />
    )
}

export default connect(null, null)(ContractComponentsDate)