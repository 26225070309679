import {addProperty, addPropertyValue, previousAuctionId} from './utils'

export function createBodyRCE(data){
    if(data !== null){
        let body = {}
        body = addProperty(data, body, 'title')
        body = addProperty(data, body, 'description')
        body = addProperty(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotIdentifier')
        body = addProperty(data, body, 'startDate')
        body = addProperty(data, body, 'sellingMethod')
        body = addProperty(data, body, 'xDocumentRequirements')
        body = addProperty(data, body, 'xAdditionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addProperty(data, body, 'carryType')
        body = previousAuctionId(data, body, 'previousAuctionId')
        return body
    }
    return null
}