import React from 'react';
import styles from './secondMenu.module.css';

import {connect} from 'react-redux';
import {setAuctionPath} from '../../../redux/actions/support.js';
import {changeSecondMenu} from '../../../redux/actions.js';
import {changeLotsFlag, auctionsSearch, auctionsSearchCount, changeCurentPage, changeStatusAuction} from '../../../redux/actions/auctions/auctions';

const SecondMenu = (props) => {
    return (
        <div className={styles.categoryWrapMain}>
            <div className={styles.categoryWrap}>
                <a href="https://beta.dto.com.ua/"><div className={styles.activeCategory}>Аукціони</div></a>
                <a href="https://registry.dto.com.ua/asset/assets_list"><div className={styles.category}>Об’єкти приватизації</div></a>
                <a href="https://registry.dto.com.ua/lease/objects_list"><div className={styles.category}>Перелік об’єктів оренди</div></a>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        search: state.start.search,
        langFlag: state.start.langFlag,
        menuOnOff: state.start.menuOnOff,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    changeSecondMenu,
    changeLotsFlag,
    auctionsSearch,
    auctionsSearchCount,
    changeCurentPage,
    changeStatusAuction,
    setAuctionPath
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondMenu)