import React from 'react';
import styles from './loader.module.css';

import {connect} from 'react-redux';

const Spinner = () => {
    return <div className={styles.container}>
                <div className={styles.spinner}>
                </div>
            </div>
}

export default connect(null, null)(Spinner)
