import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const OwnershipType = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('ownershipType') === true){
            if(props.auctionId.ownershipType !== null){
                return (
                    <TextHalf
                        title="Тип власності об’єкта малої приватизації"
                        data={textType(props.auctionId.ownershipType)}
                    />
                )
            }
        }
    }
    return null
}

function textType(data){
    switch(data){
        case 'state': return 'Державна'
        case 'municipal': return 'Комунальна'
        case 'compatible': return 'Сумісна'
        default: return null
    }
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(OwnershipType)