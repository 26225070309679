import React from 'react';
import style from './popupAward.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectRestrict,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,
} from '../../../redux/actions/awards/awards.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import ApproveOrRejectAward from './buttonsBlock.js';

import {editAwardDoc} from '../../../redux/editAwardDoc.js';

import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts'
import {getDefaultHeaders} from "../../_api/bidApi";

const DeleteBidDocument = ( props ) => {
    chekContracts(props)
    if(props.userProfiles.canCreateBid === true){
        if(props.awardDocument.statusDoc !== 4){
            props.changeAwardDocumentStatus(4)
        }    
    }
    return (
        <>
            <div className={style.wrapMain}>
                <div className={style.wrap}>
                    <div
                        className={style.close}
                        onClick={() => {
                            props.changeAwardRejectPopUpStatus(null)
                            props.changeAwardRejectTitle('')
                            props.changeAwardRejectDocumentType('null')
                            props.changeAwardRejectDescription('')
                            props.togetherApiDocFileName(null)
                            props.togetherApiECP(false)
                            props.ecpApiPass('')
                            props.changeCA('null')
                            props.changeDocumentFileName(null)
                        }}
                    >✕</div>

                    <div className={style.popup_main_doc}>
                        <div className={style.dataContracts}>
                            <div className={style.dataContractsTitle}>Дії з учасником</div>
                            <ApproveOrRejectAward />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function chekContracts(props){
    if(props.contractAuctionIdGet !== null && props.popUpAwardReject){
        for(let i = 0; i < props.contractAuctionIdGet.length; i++){
            if(props.popUpAwardReject.awardId === props.contractAuctionIdGet[i].award.id){
                if(props.contractAuctionIdGet[i].lotPaymentConfirmation === false){
                    return true                    
                }
            }
        }
    }
    return false
}


/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////


function blocEcpData(props){
    let fileElem;
    if(props.togetherApiPage.docData.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Вибрати ключ КЕП</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Обраний ключ КЕП:</span> <span>{props.togetherApiPage.docData.fileName}</span>
        </span>
    }
    return (
        <div className={style.uploadDoc + ' ' + style.widthBlok}>
            <div>
                <input
                    name="file"
                    type="file"
                    id="input__fileECP"
                    className={style.input + ' ' + style.input__file}
                    onChange={() => {
                        let addFileInp1 = document.getElementById('input__fileECP');
                        props.togetherApiDocFileName(addFileInp1.files[0].name)
                    }}
                />
                <label for="input__fileECP" className={style.input__file_button}>
                    {fileElem}
                </label>
            </div>
            {listCA(props)}
            <div>
                <div>
                    <TextField
                        required
                        id="filled-required"
                        className={style.inputText}
                        label="Пароль"
                        variant="outlined"
                        value={props.ecpData.pass}
                        onChange={
                            (e) => {
                                props.ecpApiPass(e.target.value)
                            }
                        }
                    />
                </div>
            </div>
        </div>
    )
}

function listCA(props){
    return <div className={style.groupElem}>
        <div>
            <TextField
                id="outlined-select-currency-native"
                className={style.selectWidth}
                select
                label="КНЕПД/АЦСК"
                value={props.togetherApiPage.listChoise}
                onChange={(event)=>{
                    props.changeCA(event.target.value)
                }}
                variant="outlined"
            >
                <option key='null' selected disabled value="null">Обрати</option>
                {optionCA(props)}
            </TextField>
        </div>
    </div>
}

function optionCA(props){
    let tmp = null
    if(props.togetherApiPage.list !== null){
        if(props.togetherApiPage.list.ca.length > 0){
            tmp = props.togetherApiPage.list.ca.map(
                (i, count) => {
                    return <option key={count} value={i.id}>{i.name}</option>
                }
            )
        }
    }
    return tmp
}

///////////////////////////////////////////////////////////////////////////////////
async function epUploadDataProzzoro(props){
    const addFileInp = document.getElementById('input__fileDel');
    let endPoint;
    if(
        props.popUpAwardReject.sellingMethod === "dgf-english" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', props.createDocuments.documentType)
    formData.append('file', addFileInp.files[0])
    return await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
}

async function epCreateDocProzzoro(props, data){
    let tmpDisc = null
    if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
        tmpDisc = props.createDocuments.description
    }
    const json = await data
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
    return await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.createDocuments.title,
            description: tmpDisc,
            documentType: props.createDocuments.documentType,
            language: props.createDocuments.language,              //не меняем
            documentOf: 'award',          //не меняем
            //hash: "string",
            token: json.jwt                 //не меняем
        }),
        headers: getDefaultHeaders(props)
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)    
}

async function epAddDocToAucProzzoro(props, data){
    const json = await data
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}/documents/${json.id}`;
    let endPoint2
    if(props.awardReject.type !== 'auctionProtocol'){
        if(props.userProfiles.canCreateBid === true){
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${props.popUpAwardReject.bidId.id}/documents/${json.id}`
        }else{
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
        }
    }else{
        endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
    }
    const response = await fetch(endPoint2, {
        method: "POST",
        headers: getDefaultHeaders(props)
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmp = await response.json()
        for(let i = 0; i < tmp.documents.length; i++){
            if(tmp.documents[i].id === json.id) return tmp.documents[i].documentId
        }
        return null
    }
}

/////////////////////////////////////////////
//Креплю КЕП
/////////////////////////////////////////////
async function signetDoc(props){
    await props.changeLoader(true)
    let prozzoroJWTFile = await epUploadDataProzzoro(props)
    let propzzoroDocId = await epCreateDocProzzoro(props, prozzoroJWTFile)
    let prozzoroStringId = await epAddDocToAucProzzoro(props, propzzoroDocId)
    if(prozzoroStringId !== null || prozzoroStringId !== undefined || prozzoroStringId !== 'undefined'){
        let data = await epSessionStart()       //Создаем сессию
        await epSessionTest(data)               //Проверка создания сессии
        await epSessionDataLoad(data)           //Загрузка документа
        await epMetadata(props, data)                  //Загрузка метадаты
        let param = {
            caId: props.togetherApiPage.listChoise,
            cadesType: "CAdESXLong",
            cleanUpForm: false,
            dataToSignQualifier: "notSignedBefore",
            dataTsVerifyOption: "ignore",
            duplicateSign: false,
            embedCertificateType: "signerAndCaCert",
            embedDataTs: "true",
            embedDataTsChecked: true,
            embedSignatureTs: "true",
            embedSignatureTsChecked: true,
            expandCreateDSCadesType: false,
            expandCreateDSTSVerify: false,
            expandCreateDSType: true,
            generateQR: false,
            showUpgradeCard: false,
            signatureTsVerifyOption: "ignore",
            signatureType: "attached",
            tsAdditionalVerifying: false,
        }
        await epSessionSetParam2(data, param)   //Настройка параметров сессии
        await epSessionMainContainer(data)      //Загрузка ключа
        await epCreateEP(props, data)                  //Подгрузка пароля ключа
        let data1 = await epGetDataEP(data)                 //Получить данные
        await epGetCreatorDataEP(data)          //Получить данные
        await epSessionDelete(data)             //Закрытие сессии*/
        await epUploadData(props, data1, prozzoroStringId)
        //Тут добавить создание документа и привязку его к биду
    }
    await props.changeLoader(false)
}

/**
 * Создание сессии
 */
async function epSessionStart(){
    let url = 'https://caas-api.dzo.com.ua/ccs/api/v1/ticket';
    const response = await fetch(url, {
            method: "POST",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Проверка наличия сессии
 */
async function epSessionTest(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 * Заменить
 */ 
async function epSessionDataLoad(data){
    const addFileInp = document.getElementById('input__fileDel');
    const url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/data`;
    const formData = new FormData();
    formData.append('uploadTicketData', addFileInp.files[0])
    const response = await fetch(url, {
        method: "POST",
        body: formData
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Передача мета данных
 */ 
async function epMetadata(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/metadata`;

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            metaData: props.createDocuments.fileName
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 */
async function epSessionSetParam2(data, param){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/option`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить ключевой контейнер сессии
 */
async function epSessionMainContainer(data){
    const addFileInpEP = document.getElementById('input__fileECP');
    const file = await toBase64(addFileInpEP.files[0])

    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/keyStore`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json' //Base64
        },
        body: JSON.stringify({
            "base64Data": file.substr(37)
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

/**
 * Создать ЭП
 */
async function epCreateEP(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            //keyStorePassword: "tect3"
            keyStorePassword: props.ecpData.pass
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
async function epGetDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/base64Data`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
           return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epUploadData(props, data, relatedDocument){
    const base64 = `data:application/pkcs7-signature;base64,${data.base64Data}`  //Файл в бейс 64
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
        upload(props, blob, relatedDocument)
    })
}

async function upload(props, blob, relatedDocument) {
    return new Promise(function(resolve, reject){
        let request = new XMLHttpRequest();
        let jsonData;
        request.onload = function() {
            if(request.status === 200 && request.readyState === 4){
                jsonData = JSON.parse(request.responseText);
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
                let tmpDisc = null
                if(props.createDocuments.description !== null && props.createDocuments.description !== ''){
                    tmpDisc = props.createDocuments.description
                }
                let typeOfAward = 'award'
                //if(props.awardReject.type === 'auctionProtocol') typeOfAward = 'bid'
                fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify({
                        title: props.awardReject.title,
                        description: tmpDisc,
                        documentType: 'digitalSignature',
                        language: 'uk',
                        documentOf: typeOfAward,          //не меняем
                        //hash: "string",
                        token: jsonData.jwt,
                        relatedDocument: relatedDocument
                    }),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(
                    async (response) => {
                        const json = await response.json()
                        let endPoint2
                        if(props.awardReject.type === 'auctionProtocol'){
                            if(props.userProfiles.canCreateBid === true){
                                endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/bid/${props.popUpAwardReject.bidId.id}/documents/${json.id}`
                            }else{
                                endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
                            }
                        }else{
                            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/documents/${json.id}`
                        }
                        const response2 = await fetch(endPoint2, {
                            method: "POST",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(response2 === undefined || response2 === 'undefined'){
                        }else{
                            let header = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`;
                            let response3
                            if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                                props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                            ){
                                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync?` + Math.random();
                                const responseAsync = await fetch(urlAsync, {
                                    method: "GET",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(responseAsync === undefined || responseAsync === 'undefined'){
                                    response3 = await fetch(endPoint3, {
                                        method: "GET",
                                        headers: header
                                    }).catch(console.error)
                                }else{
                                    response3 = await fetch(endPoint3, {
                                        method: "GET",
                                        headers: header
                                    }).catch(console.error)
                                }
                            }else{
                                response3 = await fetch(endPoint3, {
                                    method: "GET",
                                    headers: header
                                }).catch(console.error)
                            }


                            if(response3 === undefined || response3 === 'undefined'){
                            }else{
                                const json3 = await response3.json()
                                if(json3.hasOwnProperty('message') === true){
                                    //props.changeLoader(false)
                                }else{
                                    let header;
                                    if(props.token !== null){
                                        header = {
                                            'Authorization': props.token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                    }else{
                                        header = {
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    let data5 = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag
                                    }

                                    if(props.popUpAwardReject.sellingMethod !== "dgf-english" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-auction-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-english-initial-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-manual" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-qualification" ||
                                        props.popUpAwardReject.sellingMethod !== "dgf-dutch-initial-auction-manual"
                                    ){
                                        const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync?` + Math.random();
                                        const responseAsync = await fetch(urlAsync, {
                                            method: "GET",
                                            headers: {
                                                'Authorization': props.token.access_token,
                                                'Content-Type': 'application/json'
                                            }
                                        }).catch(console.error)
                                        if(responseAsync === undefined || responseAsync === 'undefined'){
                                            props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                            props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                        }else{
                                            props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                            props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                        }
                                    }else{
                                        props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
                                        props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
                                    }

                                    props.changeAwardRejectTitle('')
                                    props.changeAwardRejectDocumentType('null')
                                    props.changeAwardRejectDescription('')
                                    props.togetherApiDocFileName(null)
                                    props.togetherApiECP(false)
                                    props.ecpApiPass('')
                                    props.changeCA('null')
                                    props.changeDocumentFileName(null)
                                }
                            }
                        }
                    }
                );
                resolve(jsonData);
            }else{
                reject(request.status);
            }
        }
        request.onerror = function (error) {
            reject(error);
        }
        let fd = new FormData();
        //fd.append('name', "test.txt");
        let fileName = props.createDocuments.fileName + '.p7s'
        fd.append('name', fileName)
        fd.append("file", blob);
        //fd.append('documentType', 'illustration')
        fd.append('documentType', 'digitalSignature')
        fd.append('Content-Type', 'multipart/form-data')
        fd.append('Authorization', props.token.access_token)
        if(
            props.popUpAwardReject.sellingMethod === "dgf-english" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-auction-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-english-initial-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-manual" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-qualification" ||
            props.popUpAwardReject.sellingMethod === "dgf-dutch-initial-auction-manual"
        
        ){
            request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`, true);
        }else{
            request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`, true);
        }
        request.send(fd);
    })
}

/**
 * Получить данные ЭП
 */
async function epGetCreatorDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Удаление сессии
 * @param {*} data 
 */
async function epSessionDelete(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
    const response = await fetch(url, {
        method: "DELETE",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}
///////////////////////////////////////////

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,

        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        auctionMys: state.start.auctionMy,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,

        awardDocument: state.start.awardDocument,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectRestrict,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,

    setUniversalError,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeLoader,
    editAwardDoc,
    contractsAuctionIdGet,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)