import React from 'react';
import styles from './lotInformation.module.css';

import {connect} from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf';
import TextFull from '../../elements/viewData/textFull';

import TextFullLink from './textFullLink'

import {modifiDateString} from '../../../redux/modifiDate'

import AccessDetailsString from './auctionElements/accessDetails'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import LotIdentifierString from './auctionElements/lotIdentifier'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'

import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import {viewStringValue} from '../../../api/valueToString.js'
const LotInformation = (props) => {
    let tmp = <>
        <ValueString />
        <DescriptionString />
        <AuctionIdString />
        <TenderAttemptsString />
        <LotIdentifierString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <AccessDetailsString />
        <XDocumentRequirements />
        {strAuctionUrl(props)}
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Кількість кроків</p>
            <p>{strDutchStepQuantity(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Відсоток зниження, %</p>
            <p>{strDutchStepPercent(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Сума попереднього аукціона</p>
            <p>{strDiscountPreviousAuctionValue(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Знижка</p>
            <p>{strDiscountPercent(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Попередній аукціон</p>
            <p>{strPreviousAuctionId(props)}</p>
        </div>
        <div className={styles.lotGaranty}>
            <p className={styles.desc}>Розмір кроку голландського раунду</p>
            <p>{strDutchStepValue(props)}</p>
        </div>
        {strEligibilityCriteria(props)}
        {strVDR(props)}
        {strXDgfPlatformLegalDetails(props)}
        {strDecisionId(props)}
        {strDecisionDate(props)}
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

function strDecisionDate(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('decision') === true){
            if(props.auctionId.decision !== null){
                if(props.auctionId.decision.decisionDate !== null){
                    return (
                        <TextHalf
                            title="Дата рішення"
                            data={modifiDateString(props.auctionId.decision.decisionDate, 3, false)}
                        />
                    )
                }
            }
        }
    }
    return null
}

function strDecisionId(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('decision') === true){
            if(props.auctionId.decision !== null){
                return (
                    <TextHalf
                        title="Номер рішення"
                        data={props.auctionId.decision.decisionId}
                    />
                )
            }
        }
    }
    return null
}

function strXDgfPlatformLegalDetails(props){
    if(props.auctionId !== null){
        return (
            <TextFullLink
                title="Юридична інформація майданчиків"
                data={props.auctionId.xDgfPlatformLegalDetails}
                text='Місце та форма прийому заяв на участь в аукціоні та банківські реквізити для зарахування гарантійних внесків'
            />
        )
    }
    return null
}

function strVDR(props){
    if(props.auctionId !== null){
        return (
            <TextHalf
                title="Посилання на VDR (Virtual Data Room)"
                data={props.auctionId.virtualDataRoom}
            />
        )
    }
    return null
}

function strEligibilityCriteria(props){
    if(props.auctionId !== null){
        let tmpEligibilityCriteria = null
        if(props.auctionId.eligibilityCriteria === 'eligibilityCriteria'){
            tmpEligibilityCriteria = "Учасником електронного аукціону, предметом продажу на яких є права вимоги за кредитними договорами та договорами забезпечення, не може бути користувач, який є позичальником (боржником відносно банку) та/або поручителем (майновим поручителем) за такими кредитними договорами та/або договорами забезпечення."
        }
        return (
            <TextFull
                title="Умови участі фінансових установ"
                data={tmpEligibilityCriteria}
            />
        )
    }
    return null
}

function strDutchStepValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true && props.auctionId.dutchStep !== null){
            if(props.auctionId.dutchStep.hasOwnProperty('dutchStepValue') === true && props.auctionId.dutchStep.dutchStepValue !== null){
                if(props.auctionId.dutchStep.dutchStepValue.amount !== null){
                    let tmp = viewStringValue(props.auctionId.dutchStep.dutchStepValue)
                    return tmp
                }
            }
        }
    }
    return null
}

function strPreviousAuctionId(props){
    if(props.auctionId !== null){
        if(props.auctionId.previousAuctionId !== null){
            return props.auctionId.previousAuctionId
        }
    }
    return null
}

function strDiscountPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true && props.auctionId.discount !== null){
            if(props.auctionId.discount.hasOwnProperty('discountPercent') === true && props.auctionId.discount.discountPercent !== null){
                return props.auctionId.discount.discountPercent + '%'
            }
        }
    }
    return null
}

function strDiscountPreviousAuctionValue(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true && props.auctionId.discount !== null){
            if(props.auctionId.discount.hasOwnProperty('previousAuctionValue') === true && props.auctionId.discount.previousAuctionValue !== null){
                return props.auctionId.discount.previousAuctionValue.amount + 'грн.'
            }
        }
    }
    return null
}

function strDutchStepPercent(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true && props.auctionId.dutchStep !== null){
            return props.auctionId.dutchStep.dutchStepPercent
        }
    }
    return null
}

function strDutchStepQuantity(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true && props.auctionId.dutchStep !== null){
            return props.auctionId.dutchStep.dutchStepQuantity
        }
    }
    return null
}

function strAuctionUrl(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionUrl') === true){
            if(props.auctionId.auctionUrl !== null){
                return (
                    <TextFull
                        title="Веб-адреса для перегляду аукціону"
                        data={props.auctionId.auctionUrl}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotInformation)
