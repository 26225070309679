import React from 'react';

import {connect} from 'react-redux';

import {isLRE, isLSE, isLSP} from '../../../../redux/procedure.js';

import TextHalf from '../../../elements/viewData/textHalf'

const TenderAttemptsString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('tenderAttempts') === true){
            if(props.auctionId.tenderAttempts !== null){
                if(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)){
                    return (
                        <TextHalf
                            title="Порядковий номер аукціону"
                            data={strTenderAttempts(props.auctionId.tenderAttempts)}
                        />
                    )
                    
                }
                return (
                    <TextHalf
                        title="Лот виставляється"
                        data={strTenderAttempts(props.auctionId.tenderAttempts)}
                    />
                )
            }
        }
    }
    return null
}

function strTenderAttempts(data){
    switch(data){
        case 1:
            return 'Вперше'
        case 2:
            return 'Вдруге'
        case 3:
            return 'Втретє'
        case 4:
            return 'Вчетверте'
        case 5:
            return "Вп'яте"
        case 6:
            return 'Вшосте'
        case 7:
            return 'Всьоме'
        case 8:
            return 'Ввосьме'
        case 9:
            return "Вдев'яте"
        case 10:
            return "Вдесяте"
        default:
            return "Невідомо"
    }
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(TenderAttemptsString)