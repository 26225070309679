import React,{useState} from 'react';
import styles from './item.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS, getValues, getValuesUA } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Localization from '../../../elems/localization/localization';
import Button from '../../../../elements/buttons/button';
import StateRegistration from '../../../elems/stateRegistration/stateRegistration';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {disabledElement} from '../../../disabled'
import {clearDataLot} from '../clearDataItem'

const RealEstateItem = ( {data, classification, ...props} ) => {
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [unit, setUnit] = useState(checkProp(data, 'unit') ? data.unit.code : 'MTK')
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAdress] = useState(checkProp(data, 'address') ? data.address : null)
    const [stateRegisration, setSateRegisration] = useState(checkProp(data, 'registrationDetails') ? data.registrationDetails : null )

    const [bankRuptcyItemType] = useState(checkProp(data, 'bankRuptcyItemType') ? data.bankRuptcyItemType : null)
    const [limitedCirculationProperty, setLimitedCirculationProperty] = useState(checkProp(data, 'limitedCirculationProperty') ? data.limitedCirculationProperty : null)
    const [limitedCirculationPropertyDescription, setLimitedCirculationPropertyDescription] = useState(checkProp(data, 'limitedCirculationPropertyDescription') ? isMongo(data.limitedCirculationPropertyDescription) : null)

    const [totalBuildingArea, setTotalBuildingArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'totalBuildingArea') ? data.itemProps.totalBuildingArea : null)
    const [totalObjectArea, setTotalObjectArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'totalObjectArea') ? data.itemProps.totalObjectArea : null)
    const [usableArea, setUsableArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'usableArea') ? data.itemProps.usableArea : null)
    const [constructionYear, setConstructionYear] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'constructionYear') ? data.itemProps.constructionYear : null)
    const [constructionTechnology, setConstructionTechnology] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'constructionTechnology') ? data.itemProps.constructionTechnology : null)
    const [livingArea, setLivingArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'livingArea') ? data.itemProps.livingArea : null)
    const [kitchenArea, setKitchenArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'kitchenArea') ? data.itemProps.kitchenArea : null)
    const [landArea, setLandArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landArea') ? data.itemProps.landArea : null)
    const [locationInBuilding, setLocationInBuilding] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'locationInBuilding') ? data.itemProps.locationInBuilding : null)
    const [floors, setFloors] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'floors') ? data.itemProps.floors : null)
    // const [generalCondition, setGeneralCondition] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'generalCondition') ? data.itemProps.generalCondition : null)
    // const [serviceElectricity, setServiceElectricity] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceElectricity') ? data.itemProps.serviceElectricity : null)
    // const [powerSupplyCapacity, setPowerSupplyCapacity] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'powerSupplyCapacity') ? data.itemProps.powerSupplyCapacity : null)
    // const [powerSupplyClass, setPowerSupplyClass] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'powerSupplyClass') ? data.itemProps.powerSupplyClass : null)
    // const [serviceWater, setServiceWater] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceWater') ? data.itemProps.serviceWater : null)
    // const [serviceSewerage, setServiceSewerage] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceSewerage') ? data.itemProps.serviceSewerage : null)
    // const [serviceGas, setServiceGas] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceGas') ? data.itemProps.serviceGas : null)
    // const [serviceCentralHeating, setServiceCentralHeating] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceCentralHeating') ? data.itemProps.serviceCentralHeating : null)
    // const [serviceAutonomousHeating, setServiceAutonomousHeating] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceAutonomousHeating') ? data.itemProps.serviceAutonomousHeating : null)
    // const [serviceHeatingCounter, setServiceHeatingCounter] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceHeatingCounter') ? data.itemProps.serviceHeatingCounter : null)
    // const [serviceVentilation, setServiceVentilation] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceVentilation') ? data.itemProps.serviceVentilation : null)
    // const [serviceAirConditioning, setServiceAirConditioning] = useState(checkProp(data, 'itemProps') &&  checkProp(data.itemProps, 'serviceAirConditioning') ? data.itemProps.serviceAirConditioning : null)
    // const [servicePhone, setServicePhone] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'servicePhone') ? data.itemProps.servicePhone : null)
    // const [serviceTV, setServiceTV] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceTV') ? data.itemProps.serviceTV : null)
    // const [serviceInternet, setServiceInternet] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceInternet') ? data.itemProps.serviceInternet : null)
    // const [serviceElevator, setServiceElevator] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceElevator') ? data.itemProps.serviceElevator : null)
    // const [serviceSecurityAlarm, setServiceSecurityAlarm] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceSecurityAlarm') ? data.itemProps.serviceSecurityAlarm : null)
    // const [serviceFireAlarm, setServiceFireAlarm] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'serviceFireAlarm') ? data.itemProps.serviceFireAlarm : null)
    // const [servicesDescription, setServicesDescription] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'servicesDescription') ? data.itemProps.servicesDescription : null)
    // const [servicesAccounting, setServicesAccounting] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'servicesAccounting') ? data.itemProps.servicesAccounting : null)
    // const [landTax, setLandTax] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'landTax') ? data.itemProps.landTax : null)

    const [notLivingArea, setNotLivingArea] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'notLivingArea') ? data.itemProps.notLivingArea : null)
    const [numberOfRooms, setNumberOfRooms] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'numberOfRooms') ? data.itemProps.numberOfRooms : null)
    const [numberOfStoreys, setNumberOfStoreys] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'numberOfStoreys') ? data.itemProps.numberOfStoreys : null)
    const [utilityRoomsDescription, setUtilityRoomsDescription] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'utilityRoomsDescription') ? data.itemProps.utilityRoomsDescription : null)
    const [realEstateTenantsInfo, setRealEstateTenantsInfo] = useState(checkProp(data, 'itemProps') && checkProp(data.itemProps, 'realEstateTenantsInfo') ? data.itemProps.realEstateTenantsInfo : null)

    let requestBody = {
        description: {
            uk_UA: description,
        },
        classification: {
            id: classification,
            scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
            description: {
                uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
            }
        },
        unit: {
            code: unit,
            name: {
                uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
            }
        },
        quantity: +quantity,
        address: address,
        location: location,
        itemProps: {
            totalBuildingArea: +totalBuildingArea,
            totalObjectArea: +totalObjectArea,
            usableArea: +usableArea,
            constructionYear: constructionYear,
            constructionTechnology: constructionTechnology,
            livingArea: +livingArea,
            kitchenArea: +kitchenArea,
            landArea: +landArea,
            locationInBuilding: locationInBuilding,
            floors: floors,
            // generalCondition: generalCondition,
            // serviceElectricity: serviceElectricity,
            // powerSupplyCapacity: +powerSupplyCapacity,
            // powerSupplyClass: powerSupplyClass,
            // serviceWater: serviceWater,
            // serviceSewerage: serviceSewerage,
            // serviceGas: serviceGas,
            // serviceCentralHeating: serviceCentralHeating,
            // serviceAutonomousHeating: serviceAutonomousHeating,
            // serviceHeatingCounter: serviceHeatingCounter,
            // serviceVentilation: serviceVentilation,
            // serviceAirConditioning: serviceAirConditioning,
            // servicePhone: servicePhone,
            // serviceTV: serviceTV,
            // serviceInternet: serviceInternet,
            // serviceElevator: serviceElevator,
            // serviceSecurityAlarm: serviceSecurityAlarm,
            // serviceFireAlarm: serviceFireAlarm,
            // servicesDescription: servicesDescription,
            // servicesAccounting: servicesAccounting,
            // landTax: landTax
            notLivingArea: notLivingArea,
            numberOfRooms: numberOfRooms,
            numberOfStoreys: numberOfStoreys,
            utilityRoomsDescription: utilityRoomsDescription,
            realEstateTenantsInfo: realEstateTenantsInfo
        },
        registrationDetails: stateRegisration,
        // commercialSellItemType: null
        bankRuptcyItemType: bankRuptcyItemType,
        limitedCirculationProperty: limitedCirculationProperty,
        limitedCirculationPropertyDescription: {
            uk_UA: limitedCirculationPropertyDescription
        }
    }

    function viewButtons(props){
        if(
            description !== null && description !== '' && totalObjectArea !== null
        ){
            return (
                <div className={styles.lineButtons}>
                    <Button
                        data={'Зберегти зміни'}
                        color={'blue'}
                        onClick={
                            () => {
                                if(
                                    !checkProp(data, 'id')
                                ){
                                    createItemZU(props, requestBody)
                                }else{
                                    saveItemZU(props, requestBody, data.id)
                                }
                                props.edit(false)
                            }
                        }
                    />
                    <Button
                        data={'Закрити'}
                        color={'gray'}
                        onClick={
                            () => {
                                clearDataLot(props)
                                props.edit(false)
                            }
                        }
                    />
                </div>
            )
        }
    }


    return (
        <>
            <TextArea
                value={description}
                disabled={disabledElement(props.auctionsMy, '01')}
                label="Опис об'єкта"
                required
                onChange={(e)=>{ setDescription(e) }}
            />

            {/*<Select
                data={{
                    label: "Тип активу",
                    target: bankRuptcyItemType,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setBankRuptcyItemType(e) }}
                options={[
                    {value: 'assets', name: 'Майно'},
                    {value: 'claimRights', name: 'Права вимоги'},
                ]}
            />*/}
            <Select
                data={{
                    label: "Обмежене в обороті майно",
                    target: limitedCirculationProperty,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setLimitedCirculationProperty(e) }}
                options={[
                    {value: true, name: 'Так'},
                    {value: false, name: 'Ні'},
                ]}
            />
            {limitedCirculationProperty ? <Input
                label="Обмеження для майна з обмеженим оборотом *"
                value={limitedCirculationPropertyDescription}
                onChange={(e)=>{ setLimitedCirculationPropertyDescription(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            /> : null}

            <StateRegistration 
                data={stateRegisration} 
                setValue={(e)=> setSateRegisration(e)}
            />
            <Input
                label="Загальна площа будівлі, кв.м."
                value={totalBuildingArea}
                onChange={(e)=>{ setTotalBuildingArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Загальна площа об'єкта в будівлі, кв.м."
                value={totalObjectArea}
                onChange={(e)=>{
                    setTotalObjectArea(e)
                    //setQuantity(e)
                }}
                disabled={disabledElement(props.auctionsMy, '00')}
                error = {redBlock(props, props.createItems.zu.realEstateItem.reProps.totalObjectArea)}
                required
            />
            <div className={styles.twoInLine}>
                {/*<Input
                    label="Площа ділянки"
                    type='number'
                    value={landArea}
                    onChange={(event)=>{ 
                        setLandArea(event)
                        //setQuantity(event)
                    }}
                    disabled={disabledElement(props.auctionsMy, '01')}
                    required
                />*/}
                <Input
                    label="Кількість одиниць"
                    type='number'
                    value={quantity}
                    onChange={(event)=>{ 
                        //setLandArea(event)
                        setQuantity(event)
                    }}
                    disabled={disabledElement(props.auctionsMy, '01')}
                    required
                />
                <Select
                    data={{
                        label: "Одиниці виміру",
                        //target: 'HAR',
                        target: unit,
                        required: true,
                    }}
                    onChange={(e) => { setUnit(e) }}
                    // onChange={(event) => {
                    //     props.setDataAddress(event, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')
                    // }}
                    options={classificationSubsoilUnitCode(props)}
                />
            </div>
            <Input
                label="Корисна площа об'єкта в будівлі, кв.м."
                value={usableArea}
                onChange={(e)=>{ setUsableArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Рік будівництва"
                type="number"
                value={constructionYear}
                onChange={(e)=>{ if(e.length <= 4) setConstructionYear(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Select
                data={{
                    label: "Технологія будівництва",
                    target: constructionTechnology,
                }}
                onChange={(e) => { setConstructionTechnology(e) }}
                options={[
                    {value: "brick", name: 'Цегла'},
                    {value: "insulatedPanel", name: 'Утеплена панель'},
                    {value: "monolithicFrame", name: 'Монолітно-каркасна'},
                    {value: "panel", name: 'Панель'},
                    {value: "other", name: 'Iнше'}
                ]}
            />
            <Input
                label="Житлова площа, кв. м"
                value={livingArea}
                onChange={(e)=>{ setLivingArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Площа кухні, кв. м"
                value={kitchenArea}
                onChange={(e)=>{ setKitchenArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Площа ділянки, кв. м"
                value={landArea}
                onChange={(e)=>{ setLandArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Select
                data={{
                    label: "Місце розташування об’єкта в будівлі",
                    target: locationInBuilding,
                }}
                onChange={(e) => { setLocationInBuilding(e) }}
                options={[
                    {value: "aboveGround", name: 'Надземний'},
                    {value: "attic", name: 'Мансардний '},
                    {value: "basement", name: 'Підвальний'},
                    {value: "pedimental", name: 'Цокольний'},
                    {value: "technical", name: 'Технічний'}
                ]}
            />
            <Input
                label="Номер поверху або поверхів"
                value={floors}
                onChange={(e)=>{ setFloors(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />



            <Input
                label="Не житлова площа, кв.м"
                value={notLivingArea}
                onChange={(e)=>{ setNotLivingArea(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Кількість кімнат або приміщень"
                value={numberOfRooms}
                onChange={(e)=>{ setNumberOfRooms(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Поверховість будинку"
                value={numberOfStoreys}
                onChange={(e)=>{ setNumberOfStoreys(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Інформація про допоміжні, підсобні приміщення та споруди"
                value={utilityRoomsDescription}
                onChange={(e)=>{ setUtilityRoomsDescription(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Інформація про наявність інших зареєстрованих, крім боржника, мешканців (зокрема дітей), орендарів та інших користувачів приміщення"
                value={realEstateTenantsInfo}
                onChange={(e)=>{ setRealEstateTenantsInfo(e) }}
                disabled={disabledElement(props.auctionsMy, '01')}
            />


            <Localization address={checkProp(data, 'itemProps') && address} location={checkProp(data, 'itemProps') && location} setLocation={setLocation} setAdress={setAdress} />
            {viewButtons(props)}
        </>
    )
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        // .filter(
        //     (i) => {
        //         return i.code === 'HAR' || i.code === 'MTK'
        //     }
        // )
        .map(
            (i) => {
                return { value: i.code, name: i.name, selected: true}
            }
        )
    }
}


function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(props.auctionsMy !== null){
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                if(amount === null || amount === '' || amount === 'null'){
                    return 'error'
                }
                return null
            }
        }
    }
    return null
}

function createItemZU(props, requestBody){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            createItemCS(props, requestBody)
        }
    }
}

function saveItemZU(props, requestBody, itemId){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationCVZU: state.start.classificationCVZU,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateItem)