import React from 'react';

import {connect} from 'react-redux';

import TextHalfLink from '../../../elements/viewData/textHalfLink'

const XPlatformLegalDetailsString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('x_platformLegalDetails') === true){
            if(props.auctionId.x_platformLegalDetails !== null){
                return (
                    <TextHalfLink
                        title="Реквізити майданчиків"
                        data={props.auctionId.x_platformLegalDetails}
                        text={props.auctionId.x_platformLegalDetails}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(XPlatformLegalDetailsString)