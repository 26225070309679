import React from 'react';
import style from './info.module.css';

import { Switch, Route } from 'react-router-dom'

import Regulations from './regulations/regulations.js';
import Tariffs from './tariffs/tariffs.js';
import Instructions from './instructions/instructions.js';
import Offer from './offer/offer.js';
import Contacts from './contacts/contacts.js';

export default function Info(){
    return (
        <div className={style.info_wrap}>
            <Switch>
                <Route path='/info/rules'
                    render={ () => <Regulations /> }
                />
                <Route path='/info/tarifi'
                    render={ () => <Tariffs /> }
                />
                <Route path='/info/instrukcii'
                    render={ () => <Instructions /> }
                />
                <Route path='/info/agreement'
                    render={ () => <Offer /> }
                />
                <Route path='/info/contacs'
                    render={ () => <Contacts /> }
                />
            </Switch>
        </div>
    )
}