import React from 'react';
import styles from './zuMaps.module.css';

import {connect} from 'react-redux';

const ZuMaps = (props) => {
    let stringParam = `../../iframeMap.html?lat=${props.latitude}&lng=${props.longitude}`
    return (
        <div className={styles.terminu}>
            <h3 className={styles.terminuTitle}>Розташування об'єкту на мапі</h3>
            <iframe title='locationEdit' className={styles.map} id="target" src={stringParam}></iframe>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
    }
}

export default connect(mapStateToProps, null)(ZuMaps)