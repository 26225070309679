import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import {viewStringValue} from '../../../../api/valueToString.js'

const PreparationValueString = (props) => {
    if(props?.auction?.preparationValue) {
        let tmp = viewStringValue(props.auction.preparationValue)
        return (
            <TextFull
                title={typeTitleText()}
                data={tmp}
            />
        )
    }
    return null
}

function typeTitleText(){
    return 'Витрати на підготовку лота'
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(PreparationValueString)