import {
    SET_GET_BANK_ACCOUNT,
    SET_POP_UP_BANK_ACCOUNT,
    SET_POP_UP_BANK_ACCOUNT_COMPLITE,
    SET_POP_UP_BANK_ACCOUNT_ERROR,
    SET_POP_UP_BANK_ACCOUNT_SHEME,

    SET_POP_UP_BANK_ACCOUNT_OPUS,
    SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK,
    SET_POP_UP_BANK_ACCOUNT_NAZVA,
    SET_POP_UP_BANK_ACCOUNT_IDENTIF,
    SET_POP_UP_BANK_ACCOUNT_SHEME_ID,
    SET_POP_UP_BANK_ACCOUNT_ID,
    SET_POP_UP_BANK_ACCOUNT_ID_ARR,
    SET_POP_UP_BANK_ACCOUNT_EDIT_ID,

    WORK_BANK_ACCOUNT_SAVE,
    WORK_BANK_ACCOUNT_ADD,
    WORK_BANK_ACCOUNT_DELETE,

    OPEN_BANK_WINDOW,

    SET_POP_UP_BANK_ACCOUNT_AH_SHEME,
    SET_POP_UP_BANK_ACCOUNT_AH_LN,
    SET_POP_UP_BANK_ACCOUNT_AH_ID,
} from "../../types";

import {getAPI} from '../crud';
import {setLocal} from '../support';


export function bankAccountGet(_url, header){
    const data = {
        //url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/private/bankAccounts/my?limit=100`,
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/private/userProfile/bankAccounts?limit=100`,
        actionType: SET_GET_BANK_ACCOUNT,
        header: header
    }
    return getAPI(data);
}

export function changePopUpBankAccount(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT,
        payload: value
    }
}

export function setPopBankAccountComplite(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_COMPLITE,
        payload: value
    }
}

export function setPopBankAccountError(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_ERROR,
        payload: value
    }
}

export function bankAccountShemeGet(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/classifications/byScheme/accountIdentifiers`,
        actionType: SET_POP_UP_BANK_ACCOUNT_SHEME,
        header: header
    }
    return getAPI(data);
}


export function setPopBankAccountOpus(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_OPUS,
        payload: value
    }
}

export function setPopBankAccountNazvaBanku(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK,
        payload: value
    }
}

export function setPopBankAccountNazva(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_NAZVA,
        payload: value
    }
}

export function setPopBankAccountIdentif(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_IDENTIF,
        payload: value
    }
}

export function setPopBankAccountSheme(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_SHEME_ID,
        payload: value
    }
}

export function setPopBankAccountId(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_ID,
        payload: value
    }
}

export function setPopBankAccountIdArr(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_ID_ARR,
        payload: value
    }
}

export function setPopBankAccountEditId(value) {
    return {
        type: SET_POP_UP_BANK_ACCOUNT_EDIT_ID,
        payload: value
    }
}

export function setBankAccountSave(value) {
    return {
        type: WORK_BANK_ACCOUNT_SAVE,
        payload: value
    }
}

export function setBankAccountAdd(value) {
    return {
        type: WORK_BANK_ACCOUNT_ADD,
        payload: value
    }
}

export function setBankAccountDelete(value) {
    return {
        type: WORK_BANK_ACCOUNT_DELETE,
        payload: value
    }
}

export function openBankWindows(value) {
    return {
        type: OPEN_BANK_WINDOW,
        payload: value
    }
}

export function setPopBankAccountAccountHolderIdentifierScheme(value){
    return {
        type: SET_POP_UP_BANK_ACCOUNT_AH_SHEME,
        payload: value
    }
}
export function setPopBankAccountAccountHolderIdentifierLegalName(value){
    return {
        type: SET_POP_UP_BANK_ACCOUNT_AH_LN,
        payload: value
    }
}
export function setPopBankAccountAccountHolderIdentifierId(value){
    return {
        type: SET_POP_UP_BANK_ACCOUNT_AH_ID,
        payload: value
    }
}
