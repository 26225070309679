import React, {useEffect} from 'react';

import {connect} from 'react-redux';
import {changeBidAward, changeLoader} from '../../../redux/actions.js';
import {
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus
} from '../../../redux/actions/auctionBid/auctionBid.js';

import {
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName
} from '../../../redux/actions/documents/documents.js';

import procedure from '../../../redux/procedure'
import ViewBidList from "./viewList/viewBidList";
import CreateBid from "./create/createBid";
import {getUserBidsListByAuction} from "../../_api/bidApi";
import {saveArrBids} from "../../../redux/allAuctionBids";

const BidComponent = (props) => {
    useEffect(() => {
        if (props?.token?.access_token) {
            getUserBidsListByAuction(props, props?.auction?.id)
        }
    }, [])

    let userBids = props?.auction?.privateData?.bids ?? [],
        isMultiAward = procedure.isREM(props.auction?.sellingMethod)

    let canCreateBid = false
    if (
        (
            // userBids.length === 0 ||
            isMultiAward ||
            props?.allUserBids?.arr?.length === 0
        ) &&
        props?.userProfiles?.canCreateBid &&
        (props.auction.status === "active_tendering" || checkBidPermissions(props))
    ) {
        canCreateBid = true
    }
    return (
        <div>
            {userBids?.length > 0 || props?.allUserBids?.arr?.length > 0 ?
                <ViewBidList/>
                :
                null
            }
            {canCreateBid ?
                <CreateBid isMultiAward={isMultiAward}/>
                :
                null
            }
        </div>
    )
}

//@todo refactor date checks
function checkBidPermissions(props) {
    if (
        //@todo remove?
        //@todo check dutchPart of auction module docs on prozorro
        //@deprecated - old cope part
        (props.auction.status === "active_auction") && procedure.isDutch(props.auction.sellingMethod)
    ) {
        let startDate = new Date(props.auction.auctionPeriod.startDate)
        let tmpDate = startDate.getTime()
        let timePoyas = ((1000 * 60) * 60) * 2
        let timeDutch = (((1000 * 60) * 60) * 6) + ((1000 * 60) * 45)
        let tmpSumm = tmpDate + timePoyas + timeDutch
        if (tmpSumm > Date.now()) {
            return true
        }
    }

    //part, moved from upper level
    if (props.auction.tenderPeriod !== null) {
        let dateTenderStart = new Date(props.auction.tenderPeriod.startDate)
        let tmpStart = dateTenderStart.getTime()
        let dateTenderEnd = new Date(props.auction.tenderPeriod.endDate)
        let tmpEnd = dateTenderEnd.getTime()
        if (tmpStart < Date.now() && Date.now() < tmpEnd) {
            return true
        }
    }

    if (props?.auction?.tenderPeriod) {
        const tenderPeriodStartDate = new Date(props.auction.tenderPeriod.startDate)
        const tenderPeriodEndDate = new Date(props.auction.tenderPeriod.endDate)
        const now = new Date()
        if (tenderPeriodStartDate < now && now < tenderPeriodEndDate) {
            return true
        }
    }

    return false
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        userProfiles: state.start.userProfiles,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        allUserBids: state.start.auctionsAllBids,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName,
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus,
    changeLoader,
    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidComponent)