import React, {useEffect} from 'react';
import styles from './auctionInfoTab.module.css';
import {connect} from 'react-redux';

import procedure from "../../../../../redux/procedure";

import Title from '../../../../product/title/title'
import Tabs from '../../../../product/tabs/tabs'

import {saveArrBids} from "../../../../../redux/allAuctionBids";
import {getUserBidsListByAuction} from "../../../../_api/bidApi";

import Documents from '../../../../documents/AucDocuments'
import MessageLot from "../../../../product/messageLot/messageLot";
import InformationAboutTheOrganizer from "../../../../product/informationAboutTheOrganizer/informationAboutTheOrganizer";
import QuantityLimitBlock from "../../../../product/informationAboutTheOrganizer/quantityLimitBlock";
import DisqualifiedBidsDetails from "../../../../product/disqualifiedBidsDetails/disqualifiedBidsDetails";
import PropertyOwner from "../../../../product/informationAboutTheOrganizer/propertyOwner";
import CurrentTenantLAE from "../../../../product/informationAboutTheOrganizer/currentTenantLAE";
import CurrentTenant from "../../../../product/informationAboutTheOrganizer/currentTenant";
import DebtorInformation from "../../../../product/informationAboutTheOrganizer/debtorInformation";
import OrganizationEntity from "../../../../product/informationAboutTheOrganizer/organizationEntity";
import ContactPoint from "../../../../product/informationAboutTheOrganizer/contactPoint";
import BanksGroupe from "../../../../product/informationAboutTheOrganizer/banksGroupe";
import Arrests from "../../../../product/informationAboutTheOrganizer/arrests";
import AdditionalInformation from "../../../../product/informationAboutTheOrganizer/additionalInformation";

import AuctionInformationBlock from "./blocks/auctionInformationBlock";
import StructureLot from "../../../../product/structureLot/structureLot";
import ActiveAuctionLinkNew from "../../../../product/activeAuctionLinkNew/activeAuctionLinkNew";

import BidComponent from "../../../bid/BidComponent";
import SidebarRightViewProcedure from "../../../../sidebarRightViewProcedure";

const AuctionInfoTab = (props) => {
    useEffect(() => {
        if (props?.token?.access_token) {
            getUserBidsListByAuction(props, props?.auction?.id)
        }
    }, [])

    if (props.auction !== null) {
        return (
            <>
                <div className={styles.wrapNew}>
                    <div className={styles.mainBlock}>
                        <Title/>
                        <div className={styles.aucProperties}>
                            <Tabs/>
                            <InformationAboutTheOrganizer/>
                            <QuantityLimitBlock/>
                            <DisqualifiedBidsDetails/>
                            <PropertyOwner/>
                            {procedure.isLAP(props.auction.sellingMethod)
                                ? <CurrentTenantLAE/>
                                : <CurrentTenant/>
                            }
                            <DebtorInformation/>
                            <OrganizationEntity/>
                            <ContactPoint/>
                            <BanksGroupe/>
                            <Arrests/>
                            {props.auction.hasOwnProperty('additionalInformation') === true
                            && props.auction.additionalInformation !== null
                            && props.auction.additionalInformation.length > 0
                                ? <AdditionalInformation/>
                                : null
                            }
                            <AuctionInformationBlock/>
                            <StructureLot/>
                            <Documents data={props.auction}/>
                            <MessageLot
                                content={
                                    <>Даний аукціон оголошено і проводиться в рамках&nbsp;
                                        <a href="https://www.dto.com.ua/info/rules" target="blank">Регламенту роботи ЕТС
                                            Prozorro:Продажі</a>
                                    </>
                                }
                            />
                            <ActiveAuctionLinkNew bidData={props?.auctionsAllBids?.arr} />
                            <BidComponent/>
                        </div>
                    </div>
                    <SidebarRightViewProcedure/>
                </div>
            </>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        userProfiles: state.start.userProfiles,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

const mapDispatchToProps = {
    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionInfoTab)