//Elem 0 = ua, 1 = en
export const COPYRITE_FOOTER_LANG = ['Усі права захищено.', 'All rights reserved'];

export const REGULATIONS_FOOTER_LANG = ['Регламенти', 'REGULATIONS'];

export const TARIFFS_FOOTER_LANG = ['Тарифи', 'TARIFFS'];

export const INSTRUCTIONS_FOOTER_LANG = ['Інструкції', ''];

export const AGREEMENT_OFFER_ON_PROVIDING_SERVICES_FOOTER_LANG = ['Договір-оферта про надання послуг', 'AGREEMENT-OFFER ON PROVIDING SERVICES'];

export const CONTACT_US_FOOTER_LANG = ['Контакти', 'CONTACT US'];

export const FOLLOW_FACEBOOK_FOOTER_LANG = ['Слідкуй за нами в Facebook', 'Follow us in Facebook'];