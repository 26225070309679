import React from 'react';
import styles from './arrests.module.css';
import {connect} from 'react-redux';

import Button from '../../../../elements/buttons/button';
import InstructionBlock from '../../../../elements/viewData/instructionBlock/instructionBlock'
import BanksItem from './bankItem';

import {isALE,
    isLLE, isLLD, isLLP, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD, isLAW, isLAE, isLAP, isLPE,
    isAPE, isAPD,
    isLRE, isLSE, isLSP
} from '../../../../../redux/procedure'

import {
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';


const Banks = ({bankAccounts, setBankAccounts, auction, bankAccount, ...props}) => {
    function viewArrests(){
        if(bankAccounts === null) return null
        if(bankAccounts.length === 0) return null
        return bankAccounts
        .map(
            (i, count) => { 
                return <BanksItem 
                    bankAccounts={bankAccounts}
                    setBankAccounts={setBankAccounts}
                    item={i}
                    count={count}
                    auction={auction}
                    bankAccount={bankAccount}
                />
            }
        )
    }

    function isDisabled(){
        if(auction.status === 'active_tendering') return true
        return false
    }

    function optionBankAccount(){
        if( 
            isLRE(auction.sellingMethod) || isLSE(auction.sellingMethod) || isLSP(auction.sellingMethod)
        ){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                ]
        }
        if( 
            isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)
        ){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                    {value: 'other', name: 'Інші платежі'},
                    {value: 'advancePayment', name: 'Рахунок для авансового внеску'},
                    {value: 'lease', name: 'Рахунок для оплати оренди'}
                ]
        }
        if( 
            isALE(auction.sellingMethod)
        ){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if( 
           isBRE(auction.sellingMethod) || isBRD(auction.sellingMethod) || isBRW(auction.sellingMethod)
        ){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    /*{value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'organizer', name: 'Організатор'},
                    {value: 'advancePayment', name: 'Авансовий внесок'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                    {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                    {value: 'lease', name: 'Орендні платежі'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'other', name: 'Інші платежі'}*/
                ]
        }
        if(isRLE(auction.sellingMethod) || isRLD(auction.sellingMethod)){
            return [
                {value: 'lease', name: 'Орендні платежі', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'organizer', name: 'Організатор'}
            ]
        }
        if(isCLE(auction.sellingMethod) || isCLD(auction.sellingMethod)){
            return [
                {value: 'lease', name: 'Орендні платежі', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'other', name: 'Інші платежі'}
            ]
        }
        if(isSPE(auction.sellingMethod) || isSPD(auction.sellingMethod)){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'payment', name: 'Плата за лот'},
                ]
        }
        if(isNLE(auction.sellingMethod) || isNLD(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if(isLAE(auction.sellingMethod) || isLAP(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот', style: 'bold'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
                ]
        }
        if(isLAW(auction.sellingMethod)){
            return [
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                    {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
                ]
        }
        if(isLPE(auction.sellingMethod)){
            return [
                    {value: 'registrationFee', name: 'Реєстраційний внесок'},
                    {value: 'guarantee', name: 'Гарантійний внесок'},
                    {value: 'payment', name: 'Плата за лот'},
                ]
        }
        if(isAPE(auction.sellingMethod)){
            return [
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        if(isAPD(auction.sellingMethod)){
            return [
                    {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                    {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                    {value: 'payment', name: 'Плата за лот'},
                    {value: 'other', name: 'Інші платежі'}
                ]
        }
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }

    function disableBttons(){
        if(bankAccounts.length >= optionBankAccount().length){
            return (
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='gray'
                    data={'Додати групу'}
                />
            )
        }
        return (
            <Button
                height={'50px'}
                font={'16px'}
                color='blue'
                data={'Додати групу'}
                onClick={
                    () =>{
                        let tmp = []
                        // console.log(bankAccounts)
                        if(bankAccounts === null){
                            tmp[0] = {
                                accountType: null,
                                accounts: null,
                                holderType: null,
                                percent: null,
                            }
                        }else{
                            tmp = [...bankAccounts]
                            tmp.push({
                                accountType: null,
                                accounts: null,
                                holderType: null,
                                percent: null,
                            })
                        }
                        setBankAccounts(tmp)
                    }
                }
                disabled={isDisabled()}
            />
        )
    }

    function helperBanks(){
        if(isLLE(auction.sellingMethod) || isLLD(auction.sellingMethod) || isLLP(auction.sellingMethod)){
            return 'УВАГА! Для реквізитів з типом "Рахунок для авансового внеску" та "Рахунок для оплати орендних платежів" необхідно обовʼязково вказати "Тип власника обʼєкта" та "Відсоток" платежу, який буде перераховано отримувачу. Без зазначення даних рахунок не буде збережено. Якщо з відповідним типом вказано МЕНШЕ ТРЬОХ банківських рахунків, то загальна сума відсотків повинна не перевищувати 100; якщо додано ТРИ банківські рахунки однакового типу, то загальна сума відсотків повинна дорівнювати 100.'
        }
        if(isLRE(auction.sellingMethod) || isLSE(auction.sellingMethod) || isLSP(auction.sellingMethod)){
            return "УВАГА! Обов'язково додайте один банківський рахунок з типом 'Плата за лот' і валютою UAH. Інші банківські рахунки не є обов'язковими. Рахунків для кожного типу в UAH/USD/EUR може бути безліч."
        }
        return null
    }

    return (
        <div className={styles.bankBlock}>
            <InstructionBlock
                title='Важлива інформація'
                content={helperBanks()}
            />
            <div className={styles.bankBlockTitle}>Банківські реквізити *</div>
            <div style={{width:"100%"}}>{viewArrests()}</div>
            <div className={styles.editBanks}>
                {disableBttons()}
                <Button
                    //disabled={isLAW(props.auctionsMy.sellingMethod) ? false : elementDisabledCreate(props)}
                    color={'gray'}
                    onClick={
                        () => {
                            props.openBankWindows(true)
                        }
                    }
                >
                    Створити та редагувати банківський рахунок
                </Button>
            </div>
        </div>
    )
}

//export default Banks
const mapDispatchToProps = {
    openBankWindows
}

export default connect(null, mapDispatchToProps)(Banks)