import React, {useEffect} from 'react';
import styles from '../windowAuction.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {disabledElement} from '../../disabled'

const RelatedOrganizationsOwnershipType = ( props ) => {
    let tmp = 'null'
    if(props.auctionsMy.relatedOrganizations !== null){
        if(props.auctionsMy.relatedOrganizations.hasOwnProperty('ownershipType') === true){
            if(props.auctionsMy.relatedOrganizations.ownershipType !== null && props.auctionsMy.relatedOrganizations.ownershipType !== ''){
                tmp = props.auctionsMy.relatedOrganizations.ownershipType
            }else{
                tmp = 'null'
            }
        }
    }else{
        props.setDataAddress({
            propertyOwner: {id: 'null'},
            sellingEntity: {id: 'null'},
            currentTenant: {id: 'null'},
            ownershipType: 'null',
        }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
    }
    useEffect(
        () => {
            if(props.objectReestr !== null){
                if(props.objectReestr.hasOwnProperty('relatedOrganizations') === true){
                    if(props.objectReestr.relatedOrganizations !== null){
                        if(props.objectReestr.relatedOrganizations.hasOwnProperty('ownershipType') === true){
                            if(props.objectReestr.relatedOrganizations.ownershipType === props.auctionsMy.relatedOrganizations.ownershipType){
                                if(props.auctionsMy.relatedOrganizations.ownershipType !== null && props.auctionsMy.relatedOrganizations.ownershipType !== ''){
                                    tmp = props.auctionsMy.relatedOrganizations.ownershipType
                                }else{
                                    tmp = 'null'
                                }

                            }
                        }
                    }
                }
            }
        }, []
    )   
    if(props.typeElem === 1){
        return (
            <div className={styles.groupSelect}>
                <select
                    disabled={disabledElement(props.auctionsMy, '01')}
                    className={styles.validfilterSelect}
                    value = {tmp}
                    onChange={
                        (e) => {
                            props.setDataAddress(e.target.value, 'SET_AUCTION_OWNERSHIP_TYPE')
                        }
                    }
                >
                    <option key={0} className={styles.hoverOptions} selected disabled value='null'>Обрати</option>
                    <option key={1} className={styles.hoverOptions} value='state'>державний</option>
                    <option key={2} className={styles.hoverOptions} value='municipal'>комунальний</option>
                    <option key={3} className={styles.hoverOptions} value='compatible'>сумісний</option>
                </select>
                <div className={styles.validfilterSelectTitle}>Тип власності</div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupSelect}>
                <select
                    disabled={disabledElement(props.auctionsMy, '01')}
                    className={styles.validfilterSelect}
                    value = {tmp}
                    onChange={
                        (e) => {
                            props.setDataAddress(e.target.value, 'SET_AUCTION_OWNERSHIP_TYPE')
                        }
                    }
                >
                    <option key={0} className={styles.hoverOptions} selected disabled value='null'>Обрати</option>
                    <option key={1} className={styles.hoverOptions} value='state'>державний</option>
                    <option key={2} className={styles.hoverOptions} value='municipal'>комунальний</option>
                    <option key={3} className={styles.hoverOptions} value='compatible'>сумісний</option>
                </select>
                <div className={styles.validfilterSelectTitle}>Тип власності</div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        objectReestr: state.start.objectReestr,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedOrganizationsOwnershipType)
