import React, {useState, useEffect} from 'react';
import styles from './cse.module.css';

import {connect, useDispatch} from 'react-redux';
import {setDataAddress} from '../../../../redux/createAddress';
import { changeLoader } from '../../../../redux/actions.js';

import Select from '../../../elements/inputs/inputSelect';

import ItemsList from './itemsList/itemsList.js';

import ReturnButton from '../../returnButton'
import Sidebar from '../../auctionEditNew/components/sidebar/sidebar'

import LandItem from './items/landItem'
import { unitsGetByTypeZU } from '../../../../redux/actions/itemUnits/itemUnits';

import { clearDataLot } from '../basicSell/clearDataItem';

// import CreateAuctionLoader from '../../createAuctionLoader.js';
import { checkProp } from '../../../../redux/check';


const LAE = ( props ) => {
    const [edit, setEdit] = useState(false)
    const [editData, setData] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        getAdditionalClassificationKvtspz(props)
        if(props.auctionsMy.items && props.auctionsMy.items.length === 0) setEdit(true)
        if(props.classificationsZU === null){
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                header:{
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }
            dispatch(unitsGetByTypeZU(data, data.header))
        }
        props.setDataAddress('06', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
        smartClasif(props, '06')
    }, [])
        
    let tmpText = 'Додавання лота';
    if(props.auctionsMy !== null && props.auctionsMy.items !== null && !edit && props.auctionsMy.items.length === 0){
        if(!edit && props.auctionsMy.items.length === 0) tmpText = 'Додайте лот'
        if(!edit && props.auctionsMy.items.length > 0) tmpText = 'Список лотів'
        if(edit && checkProp(editData, 'id')) tmpText = 'Додавання лота'
        if(props.auctionsMy.items.length > 0 && editData !== null && edit ) tmpText = 'Редагування лота'
    }else{
        tmpText = 'Додайте лот'
    }

    function whatToRender(){
        if (!edit) {
            return  <div className={styles.wrapContent}>
                        <div className={styles.headers}></div>
                        <ItemsList edit={setEdit} setEdit={setData} />
                    </div>
        }
        if (edit || editData !== {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {/*viewMainClassif(props)*/}
                    {viewTypeItems(props, setEdit, editData)}
                </div>
        }
        if (edit || editData === {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {/*viewMainClassif(props)*/}
                    {viewTypeItems(props, setEdit)}
                </div>
        }
    }
    
    return (
        <div className={styles.mainWrap}>
            <Sidebar data={props.auctionsMy} />
            <div className={styles.wrapBlock}>
                <div className={styles.wrapBlockTitle}>
                    {tmpText}
                    {
                        !edit
                        ? props?.auctionsMy?.items?.length === 0
                            ? <button className={styles.editButton} 
                                    onClick={()=> {
                                    setEdit(true)
                                    setData(null)
                                    props.setDataAddress('06', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                                    smartClasif(props, '06')
                                }}>
                                    Додати лот
                                </button>
                            : null
                        : <button className={styles.cancel} 
                                onClick={()=> {
                                clearDataLot(props)
                                setEdit(false)
                                setData(null)
                            }}>
                                Скасувати
                            </button>
                    }
                </div>
                {whatToRender()}
                <div className={styles.buttonBack}>
                    <ReturnButton />
                </div>
            </div>
            
        </div>
    )
}

async function getAdditionalClassificationKvtspz(props){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

function viewSelectTypeItems(props){
    if(
        props.createItems.zu.vehicleItem.id !== null ||
        props.createItems.zu.realEstateItem.id !== null ||
        props.createItems.landRental.id !== null ||
        props.createItems.zu.jointPropertyComplexItem.id !== null
    ){
    }
    return (
        <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
            <div>
                <Select
                    data={{
                        label: "Основний класифікатор",
                        target: props.createItems.zu.baseClasif,
                        info: 'Дане поле автогенеруються на рівні ЦБД'
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                        props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                        smartClasif(props, event)
                    }}
                    options={mainCAV}
                    disabled={true}
                />
            </div>
        </div>
    )
}

const mainCAV = [
    {key: '06000000-2', value: '06', name: 'CAV 06 Земельні ділянки (та усі вкладені коди)'},
]

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
}

function viewTypeItems(props, setEdit, data){
    return <LandItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
}

async function getDictionaryJointPropertyComplex(props, mask){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationsZU: state.start.classificationsZU.unitCode
    }
}

const mapDispatchToProps = {
    // getMyAuction,
    setDataAddress,
    changeLoader,
    unitsGetByTypeZU
}

export default connect(mapStateToProps, mapDispatchToProps)(LAE)