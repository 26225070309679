import React from 'react';
import styles from './buttonAnswer.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../../redux/actions/questions/questions.js';
import {setPopUpAnswerAuction} from '../../../../redux/actions/auctions/auctions.js';
import {auctionGetId} from '../../../../redux/actions/auctions/auctions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const ButtonAnswer = (props) => {
    let tmpOpen = false
    if(props.edit === 'EDIT'){
        for(let t=0; t < props.auction.privateData.questions.length; t++){
            if(props.item.id === props.auction.privateData.questions[t]) tmpOpen = true
        }
        if(tmpOpen){
            if(props.item.answer === null){
                return (
                    <div
                        className={styles.blueBtn}
                        onClick={
                            () =>{
                                props.setDataAddress(props.item, 'SET_POP_UP_EDIT_AUCTION')
                            }
                        }
                    >
                        Редагувати
                    </div>
                )
            }
        }
        return null
    }else{
        return (
            <div
                className={styles.blueBtn}
                onClick={
                    () =>{
                        props.setPopUpAnswerAuction(props.itemAnswer)
                    }
                }
            >
                Відповідь
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        questionCreate: state.start.questionCreate,
        auctionId: state.start.auctionsGetId,
    }
}

const mapDispatchToProps = {
    answerTitle,
    answerDiscription,
    setPopUpAnswerAuction,
    auctionGetId,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAnswer)
