import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import ContactPoints from '../../contactPoint/contactPoint'

const ContactPoint = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('contactPoint')){
            if(props.auctionId.contactPoint !== null){
                return (
                    <div className={styles.infoBlock}>
                        <h3>Особа відповідальна за забезпечення огляду</h3>
                        {contactPoint(props)}
                    </div>
                )
            }
        }
    }
    return null
}
function contactPoint(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('contactPoint')){
            if(props.auctionId.contactPoint !== null){
                return <ContactPoints data={props.auctionId.contactPoint} />
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(ContactPoint)
