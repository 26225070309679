import {addProperty, addPropertyMonga, addPropertyValue, addAuctionPeriodProperty,
    addBanksNew} from './utilsCreateBody'

export function createBodyLRE(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotId')
        body = addProperty(data, body, 'sellingMethod')
        body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        body = addPropertyMonga(data, body, 'x_documentRequirements')
        body = addPropertyMonga(data, body, 'x_additionalInformation')
        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'previousAuctionId')
        body = addBanksNew(data, body)
        body.leaseDuration = data.base.leaseDuration
        
        body.restrictions = data.base.restrictions
        body.conditions = data.base.conditions
        
        body.normativeMonetaryValuation = data.base.normativeMonetaryValuation
        body.expertMonetaryValuation = data.base.expertMonetaryValuation
        body.budgetSpent = data.base.budgetSpent
        return body
    }
    return null
}