import React from 'react';
import style from './regulations.module.css';

export default function Regulations(){
    return (
        <section>
            <header className={style.header}><h1>Регламенти роботи Електронного майданчику «Державніторги.Онлайн»</h1></header>
            <article>
                <p><strong>Державніторги.онлайн працює на засадах та у повній відповідності до <a href="/files/PPP_DTO.pdf" target="_blank">ПРАВИЛ ПРОФЕСІЙНОЇ ПОВЕДІНКИ</a> Оператора електронного майданчика, що підключений до Електронної торгової системи Prozorro.Продажі</strong></p>
                <ol>
                    <li><a href="http://torgi.fg.gov.ua/upload/tmp/REGLAMENT/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%20%D0%86%D0%86%20%D0%95%D0%A2%D0%A1%20%D0%B3%D0%BE%D0%BB%D0%BB%D0%B0%D0%BD%D0%B4%D0%B5%D1%86%D1%8C_.pdf" rel="noopener noreferrer">Регламент&nbsp;роботи електронної торгової системи для проведення електронного аукціону, який складається з автоматичного покрокового зниження початкової (стартової) ціни лоту, етапів подання закритих цінових пропозицій та цінової пропозиції для продажу активів (майна) банків, що виводяться з ринку або ліквідуються. діє з 10.09.2019р. (голландець)</a></li>
                    <li><a href="http://torgi.fg.gov.ua/upload/tmp/REGLAMENT/%D0%A0%D0%B5%D0%B3%D0%BB%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%20%D0%86%20%D0%95%D0%A2%D0%A1%20%D0%B0%D0%BD%D0%B3%D0%BB%D1%96%D1%94%D1%86%D1%8C_.pdf" rel="noopener noreferrer">Регламент роботи електронної торгової системи щодо організації та проведення відкритих торгів (аукціонів) з продажу активів (майна) банків, що виводяться з ринку та банків, що ліквідуються. діє з 10.09.2019р.</a></li>
                    <li><a href="https://drive.google.com/file/d/0B1JNSQkVx7j2eEFEZGpoUFhqTG8/view" rel="noopener noreferrer">Регламент роботи електронної торгової системи щодо організації та проведення електронних торгів з відчуження майна, набутого у власність Національним банком України у рахунок погашення боргу за кредитами через електронну систему ProZorro.Продажі.</a></li>
                    <li><a href="/files/Reglament_CBD2_banky_do_22.09.2012.pdf" rel="noopener noreferrer">Регламент роботи електронної торгової системи Prozorro.Продажі ЦБД2 щодо проведення електронних аукціонів з продажу активів (майна) банків, процедуру ліквідації яких розпочато до 22 вересня 2012 року</a></li>
                    <li><a href="https://drive.google.com/file/d/0B1JNSQkVx7j2eEFEZGpoUFhqTG8/view" rel="noopener noreferrer">Регламент роботи електронної торгової системи Prozorro.Продажі щодо проведення благодійних торгів (аукціонів). від 20.04.2017р</a><a href="https://drive.google.com/file/d/0B1JNSQkVx7j2eEFEZGpoUFhqTG8/view">.</a></li>
                    <li><a href="/files/_May_10_2018__432_Print_version.pdf" rel="noopener noreferrer">Постанова №432 від 10.05.2018р. "Про затвердження Порядку проведення електронних аукціонів для продажу об'єктів малої приватизації"</a></li>
                    <li><a href="/files/18.01.2018__2269-VIII.pdf" rel="noopener noreferrer">Закон України №2269-VIII від 18.01.2018р. "Про приватизацію державного і комунального майна"</a></li>
                    <li><a href="http://zakon.rada.gov.ua/laws/show/848-2018-%D0%BF" rel="noopener noreferrer">Постанова КМУ "Про реалізацію експериментального проекту із запровадження проведення аукціонів з продажу спеціальних дозволів на користування надрами шляхом електронних торгів" №848 від 17 жовтя 2018 року</a></li>
                    <li><a title="Договір про взаємодію" href="/files/Vagoni_dogovir.pdf" rel="noopener noreferrer">Договір "&nbsp;Про взаємодію під час реалізації Пілотного проекту щодо продажу із застосуванням&nbsp;електронної торгової системи ProZorro.Продажі послуг із використання вагонів власності&nbsp;АТ «Укрзалізниця» під час здійснення перевезень вантажів" (проект)</a></li>
                    <li><a href="/files/Reglament_CBD2_05.03.2020.pdf" rel="noopener noreferrer">Регламент роботи електронної торгової системи ProZorro.Продажі ЦБД2 щодо проведення електронних аукціонів з продажу/надання в оренду майна (активів)/передачі права 05.03.2020р.</a></li>
                    <li><a href="https://drive.google.com/file/d/152RdTB7prVlfDpSCChbfCJpJ__QZYDqh/view" rel="noopener noreferrer">Регламент&nbsp;роботи електронної торгової системи Prozorro.Продажі щодо організації та проведення&nbsp;аукціонів з продажу майна боржників у справах про банкрутство від 18.10.2019р.</a></li>
                    <li><a href="/files/Postanova_803_06.06.2007.pdf" rel="noopener noreferrer">Постанова КМУ №803 від 06.06.2007р. "Про затвердження Порядку відчуження об'єктів державної власності"</a></li>
                    <li><a href="/files/Reglament_wood_100320.pdf" rel="noopener noreferrer">Регламент роботи електронної торгової системи&nbsp;Prozorro.Продажі ЦБД2 щодо проведення&nbsp;електронних аукціонів з продажу окремих партій необробленої деревини від 10.03.2020р.</a></li>
                    <li><a href="/files/Postanova_1050_04.12.2019.pdf" rel="noopener noreferrer">Постанова КМУ "Про затвердження Порядку реалізації товарів на митних&nbsp;аукціонах, редукціонах та визнання такими, що втратили&nbsp;чинність, деяких постанов Кабінету Міністрів України" №1050 від 04.12.2019р</a>.</li>
                </ol>
            </article>
        </section>
    )
}
