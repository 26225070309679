import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const BankruptcyCaseNumber = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('bankruptcyCaseNumber') === true){
            if(props.auctionId.bankruptcyCaseNumber !== null){
                return (
                    <TextHalf
                        title="№ справи про банкрутство"
                        data={props.auctionId.bankruptcyCaseNumber.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(BankruptcyCaseNumber)