import React, {useState} from 'react';
import styles from '../../../product/contractNew/newContract.module.css';

import {connect} from 'react-redux';

import procedure from '../../../../redux/procedure';

import {changeAwardRejectPopUpStatus,} from '../../../../redux/actions/awards/awards.js';

import {setAwardAllUserPopUp, setAwardAllUserPopUpUser} from '../../../../redux/actions.js';
import {getUpdateDataAuction} from '../../../product/updateData';
import {setDataAddress} from '../../../../redux/createAddress';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../../redux/actions/contracts/contracts.js';

import TextHalf from '../../../elements/viewData/textHalf'
import PopDown from '../../../elements/viewData/popdownBlock/popDownBlock'
import DocItem from '../../../documents/docItem';
import AddDocPopup from '../../../documents/addDocPopup/addDocPopup';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../../redux/modifiDate'

import {viewStringValue} from '../../../../api/valueToString.js'
import {getDefaultHeaders} from "../../../_api/bidApi";
import ContractEditRemPopUp from "../edit/contractEditRemPopUp";

const ContractREM = (props) => {
    const [showDocumentPopUp, setShowDocumentPopUp] = useState(false)
    const [showContractEditPopUp, setShowContractEditPopUp] = useState(false)

    const docScopeData = {}
    docScopeData.typeOf = 'contract'
    docScopeData.aucOwner = props.auction.privateData.isOwner
    docScopeData.aucId = props.auction.id
    docScopeData.aucStatus = props.auction.status
    docScopeData.aucType = props.auction.sellingMethod
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auction.id)
    }
    if (props.auction.contracts !== null && props.auction.contracts.length > 0) {
        docScopeData.docList = props.contract.documents
    }

    const contractDateSignedString = props.contract?.dateSigned ? modifiDateString(props.contract.dateSigned, 3, false) : null
    const contractDatePublishedString = props.contract?.datePublished ? modifiDateString(props.contract.datePublished, 3, false) : null
    const contractDateModifiedString = props.contract?.dateModified ? modifiDateString(props.contract.dateModified, 3, false) : null

    return (
        <div className={styles.contractBlock}>
            <TextHalf
                title='Інформація про учасника'
                data={
                    <>
                        <div>{props.contract?.buyers[0]?.name?.uk_UA}</div>
                        <div>{props.contract?.buyers[0]?.identifier.scheme}, {props.contract?.buyers[0]?.identifier.id}, {props.contract?.buyers[0]?.identifier.legalName.uk_UA}</div>
                    </>
                }
            />
            {props.contract?.contractNumber ?
                <TextHalf title='Номер договору' data={props.contract?.contractNumber}/> : null}

            {props.contract?.title ? <TextHalf title='Назва договору' data={props.contract?.title?.uk_UA}/> : null}

            {props.contract?.description ?
                <TextHalf title='Опис договору' data={props.contract?.description?.uk_UA}/> : null}

            {props.contract?.status ?
                <TextHalf title='Статус договору' data={getContractStatusText(props.contract.status)}/> : null}

            {props.contract?.value ? <TextHalf title='Вартість в договорі за 1 кВт⋅год"'
                                              data={viewStringValue(props.contract.value)}/> : null}
            {props.contract?.contractTotalValue ?
                <TextHalf title='Заключна вартість договору' data={viewStringValue(props.contract.contractTotalValue)}/> : null}
            {contractDatePublishedString !== null ?
                <TextHalf title="Дата створення об'єкта договору" data={contractDatePublishedString}/> : null}

            {contractDateModifiedString !== null ?
                <TextHalf title="Остання дата редагування договору" data={contractDateModifiedString}/> : null}

            {contractDateSignedString ?
                <TextHalf title='Дата підписання договору' data={contractDateSignedString}/> : null}


            {props?.auction?.privateData?.isOwner === true
                ?
                <div className={styles.btnBlock}>
                    {viewDocumentsButton(props.auction, props.contract, props.count, 1, docScopeData, setShowDocumentPopUp, showDocumentPopUp)}
                    {viewContractDataPopup(props, props.contract, setShowContractEditPopUp, showContractEditPopUp)}
                    {viewApproveContractButton(
                        props,
                        {
                            aucId: props.auction.id,
                            contractId: props.contract.id,
                            sellingMethod: props.auction.sellingMethod,
                            count: props.count,
                            fullAuction: props.auction,
                            typeView: 2,
                        },
                        props.contract
                    )}
                </div>
                :
                null
            }

            {viewContractDocuments(props, props.contract, props.count, docScopeData, setShowDocumentPopUp)}
        </div>
    )
}

function viewContractDataPopup(props, contract, setShowContractEditPopUp, showContractEditPopUp) {
    if (contract.status === 'pending') {
        return (
            <>
                {showContractEditPopUp ?
                    <ContractEditRemPopUp
                        contract={props.contract}
                        actionClose={() => {
                            setShowContractEditPopUp(false)
                        }}
                        actionNo={() => {
                            setShowContractEditPopUp(false)
                        }}
                    /> : null}
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setShowContractEditPopUp(true)
                        }
                    }
                >
                    Дані договору
                </div>
            </>
        )
    }
    return <div className={styles.btnToList}>
        Дані договору
    </div>
}

function viewDocumentsButton(auction, contract, count, type, docScopeData, setShowDocumentPopUp, showDocumentPopUp) {
    if (auction?.privateData?.isOwner === true) {
        if (contract.status === 'pending' || contract.status === 'signed') {
            docScopeData.docTypesList = listContractDoc()
            docScopeData.contractId = contract.id
            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${auction.id}/contract/${contract.id}/documents`
            return (
                <>
                    {
                        showDocumentPopUp ?
                            <AddDocPopup
                                scopeData={docScopeData}
                                actionClose={() => {
                                    setShowDocumentPopUp(false)
                                }}
                                actionNo={() => {
                                    setShowDocumentPopUp(false)
                                }}
                            />
                            : null
                    }
                    <div
                        className={styles.btnAccept}
                        onClick={
                            () => {
                                setShowDocumentPopUp(true)
                            }
                        }
                    >
                        Документи договору
                    </div>
                </>
            )
        }
        return (
            <div className={styles.btnToList}>
                Документи договору
            </div>
        )
    }
    return null
}

function viewApproveContractButton(props, data, contract) {
    if (props?.auction?.privateData?.isOwner === true) {
        let hasRequiredDocuments = false
        let canApproveContract = false
        if (props.auction.contracts[data.count].documents.length > 0) {
            for (let t = 0; t < props.auction.contracts[data.count].documents.length; t++) {
                if (contract.documents[t].documentType === 'contractSigned') {
                    hasRequiredDocuments = true
                }
            }
            canApproveContract =
                contract.status === 'pending' &&
                procedure.getAwardByContract(props.auction?.awards, contract)?.status === 'protocol_signed' &&
                contract.title !== null && contract.title !== '' &&
                contract.contractNumber !== null && contract.contractNumber !== '' &&
                contract.dateSigned !== null && contract.dateSigned !== '' &&
                hasRequiredDocuments === true

            return (
                <>
                    {canApproveContract
                        ? <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    promisApproveContractLR(props, data)
                                }
                            }
                        >
                            Підтвердити договір
                        </div>
                        : <div className={styles.btnToList}>
                            Підтвердити договір
                        </div>
                    }
                </>
            )
        }
    }
}

function listContractDoc() {
    return [
        {value: 'contractSigned', name: 'Підписаний договір'},
        {value: 'contractAnnexe', name: 'Додатки до договору'},
        {value: 'contractNotice', name: 'Повідомлення про договір'},
    ]
}

/////////////////////////////////////////////////////////////////////

async function syncPromisContract(props) {
    await props.changeLoader(true)
    await getUpdateDataAuction(props, props.auction.id)
    await props.changeLoader(false)
}

/////////////////////////////////////////////////////////////////////

async function promisApproveContractLR(props, data) {
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${data.aucId}/contract/${data.contractId}/set_status/active`
    const response = await fetch(endPoint, {
        method: 'POST',
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
        props.changeLoader(false)
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = JSON.stringify({title: 'Помилка при затвердженні контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        } else {
            await setTimeout(() => syncPromisContract(props), 2000)
        }
    }
    await props.changeLoader(false)
}

function viewContractDocuments(props, contract, count, docScopeData, setShowDocumentPopUp) {
    if (contract?.documents?.length > 0) {
        return <PopDown
            title={`Документи та додатки повʼязані з актом/договором (${contract?.documents?.length})`}
            content={
                <>
                    {viewDocContract(contract, count, props, docScopeData, setShowDocumentPopUp)}
                </>
            }
        />
    }
    return null
}

function getContractStatusText(status) {
    switch (status) {
        case 'pending':
            return 'Очікується договір'
        case 'active':
            return 'Договір підтверджено'
        case 'cancelled':
            return 'Договір скасовано'
        default:
            return null
    }
}

function viewDocContract(contract, countContract, props, docScopeData, setShowDocumentPopUp) {
    if (contract) {
        docScopeData.contractStatus = contract.status
        docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/contract/${contract.id}/documents`
        docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/contract/${contract.id}/documents`
        docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/contracts/${contract.id}/documents`
        return contract.documents.map(
            (t, count) => {
                docScopeData.docTypesList = listContractDoc()
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            dataFuncOpenPopUp={setShowDocumentPopUp}
                            key={count}
                        />
                    </>
                )
            }
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        history: state.start.history,
        auction: state.start.auctionFull,
        historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractREM)
