import React from 'react';
import styles from './title.module.css';

import {connect} from 'react-redux';

import { Link } from 'react-router-dom';
import { checkProp } from '../../../redux/check';

const Title = (props) => {
    let tmp
    if(window.location.pathname.indexOf('/questions') !== -1){
        tmp = 'Обговорення'
    }else if(window.location.pathname.indexOf('/auctionResult') !== -1){
        tmp = 'Результат аукціону'
    }else{
        tmp = 'Оголошення'
    }
    let url = `https://prozorro.sale/auction/${contactAuctionId(props)}`

    return (
        <div className={styles.auctionTitle}>
            <ul>
                <Link to={`/`}>
                    <li>Всі аукцони </li>
                </Link>
                <li><a className={styles.url} href={url} target='_blank'>Аукціон № {contactAuctionId(props)}</a></li>
                <li>{tmp}</li>
            </ul>
            <h1 className={styles.aucTitle}>{contactName(props)}</h1>
        </div>
    )
}

function contactName(props){
    let tmp = ''
    if(checkProp(props.auctionId, 'title')){
                tmp += props.auctionId.title.uk_UA
    }
    return tmp
}

function contactAuctionId(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionId')){
            if(props.auctionId.auctionId !== null){
                tmp += props.auctionId.auctionId
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Title)
