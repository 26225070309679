import {addProperty, addPropertyMonga, addPropertyValue, addBankProperty, addDebtorInformation, addSellingEntity} from './utilsCreateBody'

export function createBodyBRW(data, oldData=null){
    if(data !== null){
        let body = {}
        if(oldData === null){
            body = addPropertyMonga(data, body, 'title')
            body = addPropertyMonga(data, body, 'description')
            if(data.base.accessDetails !== null){
                body = addPropertyMonga(data, body, 'accessDetails')
            }else{
                body.accessDetails = null
            }
            body = addProperty(data, body, 'tenderAttempts')
            body = addProperty(data, body, 'lotId')
            body.auctionPeriod = {
                startDate: "2022-12-20T16:03:01+00:00"
            }
             //= addAuctionPeriodProperty(data, body, 'auctionPeriod')
            body = addProperty(data, body, 'sellingMethod')
            // body = addPropertyMonga(data, body, 'x_documentRequirements')
            if(data.base.x_additionalInformation !== null){
                body = addPropertyMonga(data, body, 'x_additionalInformation')
            }else{
                body.x_additionalInformation = null
            }
            
                //body = addProperty(data, body, 'minNumberOfQualifiedBids')
            body = addProperty(data, body, 'valueAddedTaxCharged')
            body = addPropertyValue(data, body, 'value')
                //body = addPropertyValue(data, body, 'minimalStep')
                //body = addPropertyValue(data, body, 'guarantee')
                //body = addPropertyValue(data, body, 'registrationFee')
            body = addProperty(data, body, 'previousAuctionId')
                //body = addPropertyDiscount(data, body, 'discount')
            body = addBankProperty(data, body, 'banks')
            if(data.base.restrictions !== null){
                body = addPropertyMonga(data, body, 'restrictions')
            }else{
                body.restrictions = null
            }
            body = addProperty(data, body, 'isTaxInvoiceProvided')
            body = addPropertyMonga(data, body, 'economicCourtName')
            body = addPropertyMonga(data, body, 'bankruptcyCaseNumber')
            // body = addPropertyMonga(data, body, 'sellingMethodType')
            body = addDebtorInformation(data, body, 'debtorInformation')
            body = addSellingEntity(data, body, 'sellingEntity')
        }else if(oldData !== null){
            if(data.base['title'] !== null){
                if(data.base['title'].uk_UA !== oldData['title'].uk_UA) body = addPropertyMonga(data, body, 'title')
            }
            if(data.base['description'].uk_UA !== oldData['description'].uk_UA) body = addPropertyMonga(data, body, 'description')
            if(data.base.accessDetails !== null){
                if(data.base['accessDetails'].uk_UA !== null) body = addPropertyMonga(data, body, 'accessDetails')
            }else{
                body.accessDetails = null
            }
            
            if(data.base['tenderAttempts'] !== oldData['tenderAttempts']) body = addProperty(data, body, 'tenderAttempts')
            body.auctionPeriod = {
                startDate: "2022-12-20T16:03:01+00:00"
            }

            if(data.base['lotId'] !== oldData['lotId']) body = addProperty(data, body, 'lotId')
                /*if(data.base['auctionPeriod'] !== null && data.base['auctionPeriod'].startDate !== null){
                    if(oldData['auctionPeriod'] === null || (data.base['auctionPeriod'].startDate !== oldData['auctionPeriod'].startDate)) body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
                }*/
            if(data.base['sellingMethod'] !== oldData['sellingMethod']) body = addProperty(data, body, 'sellingMethod')

            // if(data.base['x_documentRequirements'] !== null && data.base['x_documentRequirements'].uk_UA !== null && data.base['x_documentRequirements'].uk_UA !== oldData['x_documentRequirements'].uk_UA) body = addPropertyMonga(data, body, 'x_documentRequirements')
            if(data.base.x_additionalInformation !== null){
                if(data.base['x_additionalInformation'] !== null && data.base['x_additionalInformation'].uk_UA !== null && data.base['x_additionalInformation'].uk_UA !== oldData['x_additionalInformation'].uk_UA) body = addPropertyMonga(data, body, 'x_additionalInformation')
            }else{
                body.x_additionalInformation = null
            }
            if(data.base['valueAddedTaxCharged'] !== oldData['valueAddedTaxCharged']) body = addProperty(data, body, 'valueAddedTaxCharged')
                
            if(
                (
                    data.base['value'].currency !== oldData['value'].currency ||
                    data.base['value'].currency === '' ||
                    data.base['value'].currency === null ||
                    data.base['value'].currency === 'null'
                ) ||
                data.base['value'].amount !== oldData['value'].amount ||
                data.base['value'].valueAddedTaxIncluded !== oldData['value'].valueAddedTaxIncluded
            ) body = addPropertyValue(data, body, 'value')
                // if(
                //     (
                //         data.base['minimalStep'].currency === '' ||
                //         data.base['minimalStep'].currency === null ||
                //         data.base['minimalStep'].currency === 'null'
                //     ) ||
                //     data.base['minimalStep'].currency !== oldData['minimalStep'].currency ||
                //     data.base['minimalStep'].amount !== oldData['minimalStep'].amount
                // ) body = addPropertyValue(data, body, 'minimalStep')
                // if(
                //     (
                //         data.base['guarantee'].currency === '' ||
                //         data.base['guarantee'].currency === null ||
                //         data.base['guarantee'].currency === 'null'
                //     ) ||
                //     data.base['guarantee'].currency !== oldData['guarantee'].currency ||
                //     data.base['guarantee'].amount !== oldData['guarantee'].amount
                // ) body = addPropertyValue(data, body, 'guarantee')
            
            // if(data.base['bankAccounts'] !== null && data.base['bankAccounts'].length > 0){
            //     if(data.base['bankAccounts'].length !== oldData['bankAccounts'].length){
            //         body = addBankProperty(data, body, 'banks')
            //     }else if(checkDelBank(data.base['bankAccounts'][0].accounts) || (data.base['bankAccounts'][0].accounts.length !== oldData['bankAccounts'][0].accounts.length)){
            //         body = addBankProperty(data, body, 'banks')
            //     }
            // }

            if(data.base.restrictions !== null){
                if(data.base['restrictions'].uk_UA !== null) body = addPropertyMonga(data, body, 'restrictions')
            }else{
                body.restrictions = null
            }
            if(data.base['isTaxInvoiceProvided'] !== oldData['isTaxInvoiceProvided']) body = addProperty(data, body, 'isTaxInvoiceProvided')
            
            if(data.base['economicCourtName'] !== null && data.base['economicCourtName'].uk_UA !== null
                && (oldData['economicCourtName'] === null || oldData['economicCourtName'].uk_UA === null
                || data.base['economicCourtName'].uk_UA !== oldData['economicCourtName'].uk_UA)) body = addPropertyMonga(data, body, 'economicCourtName')
            if(data.base['bankruptcyCaseNumber'] !== null && data.base['bankruptcyCaseNumber'].uk_UA !== null
            && (oldData['bankruptcyCaseNumber'] === null || oldData['bankruptcyCaseNumber'].uk_UA === null
            || data.base['bankruptcyCaseNumber'].uk_UA !== oldData['bankruptcyCaseNumber'].uk_UA)) body = addPropertyMonga(data, body, 'bankruptcyCaseNumber')
            
            // if(data.base['sellingMethodType'] !== null && data.base['sellingMethodType'].uk_UA !== null && data.base['sellingMethodType'].uk_UA !== oldData['sellingMethodType'].uk_UA) body = addPropertyMonga(data, body, 'sellingMethodType')
            if(data.base['debtorInformation'] !== null){
                if(
                    (data.base['debtorInformation'].identifier !== null
                    && data.base['debtorInformation'].identifier.scheme !== null
                    && data.base['debtorInformation'].identifier.scheme !== oldData['debtorInformation'].identifier.scheme)
                    || (data.base['debtorInformation'].identifier.legalName !== null
                        && data.base['debtorInformation'].identifier.legalName.uk_UA !== null
                        && data.base['debtorInformation'].identifier.legalName.uk_UA !== oldData['debtorInformation'].identifier.legalName.uk_UA)
                    || (data.base['debtorInformation'].identifier !== null
                        && data.base['debtorInformation'].identifier.id !== null
                        && data.base['debtorInformation'].identifier.id !== oldData['debtorInformation'].identifier.id)
                    || (data.base['debtorInformation'].address.countryName.uk_UA !== null && data.base['debtorInformation'].address.countryName.uk_UA !== oldData['debtorInformation'].address.countryName.uk_UA) ||
                    (data.base['debtorInformation'].address.region.uk_UA !== null && data.base['debtorInformation'].address.region.uk_UA !== oldData['debtorInformation'].address.region.uk_UA) ||
                    (data.base['debtorInformation'].address.locality.uk_UA !== null && data.base['debtorInformation'].address.locality.uk_UA !== oldData['debtorInformation'].address.locality.uk_UA) ||
                    (data.base['debtorInformation'].address.streetAddress.uk_UA !== null && data.base['debtorInformation'].address.streetAddress.uk_UA !== oldData['debtorInformation'].address.streetAddress.uk_UA) ||
                    data.base['debtorInformation'].address.postalCode !== oldData['debtorInformation'].address.postalCode ||

                    (data.base['debtorInformation'].contactPoint.name.uk_UA !== null && data.base['debtorInformation'].contactPoint.name.uk_UA !== oldData['debtorInformation'].contactPoint.name.uk_UA) ||
                    (data.base['debtorInformation'].contactPoint.email !== null && data.base['debtorInformation'].contactPoint.email !== oldData['debtorInformation'].contactPoint.email) ||
                    (data.base['debtorInformation'].contactPoint.telephone !== null && data.base['debtorInformation'].contactPoint.telephone !== oldData['debtorInformation'].contactPoint.telephone) ||
                    (data.base['debtorInformation'].contactPoint.faxNumber !== null && data.base['debtorInformation'].contactPoint.faxNumber !== oldData['debtorInformation'].contactPoint.faxNumber) ||
                    (data.base['debtorInformation'].contactPoint.url !== null && data.base['debtorInformation'].contactPoint.url !== oldData['debtorInformation'].contactPoint.url)
                ) body = addDebtorInformation(data, body, 'debtorInformation')
            }

            if(
                data.base['sellingEntity'].representativeCertificateNumber !== oldData['sellingEntity'].representativeCertificateNumber ||
                data.base['sellingEntity'].representativeCertificateDate !== oldData['sellingEntity'].representativeCertificateDate
            ) body = addSellingEntity(data, body, 'sellingEntity')
        }
        return body
    }
    return null
}

