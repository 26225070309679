import React from 'react';
import styles from './helpfulMenu.module.css';

import {connect} from 'react-redux';

const SecondMenu = () => {
    return (
        <div className={styles.aukcionmenu}>
            <div className={styles.aukcontent}>
                <div className={styles.auktionmenublok}>ІНФОРМАЦІЙНІ МАТЕРІАЛИ</div>
                <div className={styles.auktionmenublok_off}>ЗАПИТАННЯ ТА ВІДПОВІДІ</div>
                <div className={styles.auktionmenublok_off}>НОРМАТИВНІ ДОКУМЕНТИ</div>
                <div className={styles.auktionmenublok_off}>МИ НА FACEBOOK</div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

export default connect(mapStateToProps, null)(SecondMenu)