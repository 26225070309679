import React, {useState} from 'react';
import styles from './dropDownMenu.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {changeCreateAuctionTab} from '../../../../redux/actions.js';
import {changeAuctionsSwitchWindow, auctionsGetMy, setPopUpSendAuction, setPopUpErrorAuction} from '../../../../redux/actions/auctions/auctions.js';
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    setEditAuctionFromId,
    changeLoader,
} from '../../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../../../redux/actions/discount/discount.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {
    setPopUpAuctionMyNull,
} from '../../../../redux/actions/auctions/auctions.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount.js';

import {getAPI} from '../../../../redux/actions/crud.js';

import {setPopUpDelAuction} from '../../../../redux/actions/auctions/auctions.js';

import DropDownMenuLinks from './dropDownMenu_Links'
import { checkProp } from '../../../../redux/check';

import procedure, {
    isBRD, isBRE, isBRW,
    isALE,
    isBSD, isBSE,
    isCSD, isCSE,
    isLRE, isLSE, isLSP,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD,
    isLLE, isLLD, isLLP
} from '../../../../redux/procedure';

import {toAuctionDraftId, toAuctionDraftCopy} from '../../../../api/auctions/auctionEndpoints'
import {getDefaultHeaders} from "../../../_api/bidApi";

const DropDownMenu = (props) => {
    const [dropDomnMenu, setdropDomnMenu] = useState(styles.noDisplay)
    const [buttonActivate, setbuttonActivate] = useState(styles.tdBtn)
    const [popUpCadastr, setPopUpCadastr] = useState(null)

    function dropDomnToggle() {
        if (dropDomnMenu === styles.noDisplay) {
            setdropDomnMenu(styles.dropDown)
            setbuttonActivate(styles.btnActive)
        }
        else { 
            setdropDomnMenu(styles.noDisplay)
            setbuttonActivate(styles.tdBtn)
        }
    }
    
    return (
        <>
            <div className={styles.tdBtnWrap}>
                <div className={buttonActivate} onClick={dropDomnToggle}>
                    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="-11 1 46 4">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                            <path className={styles.svgIcon} d="M5.33,2.67A2.67,2.67,0,1,0,2.67,5.34,2.66,2.66,0,0,0,5.33,2.67Z"/>
                            <path className={styles.svgIcon} d="M14.67,2.67A2.67,2.67,0,1,0,12,5.34,2.67,2.67,0,0,0,14.67,2.67Z"/>
                            <path className={styles.svgIcon} d="M24,2.67a2.67,2.67,0,1,0-2.67,2.67A2.66,2.66,0,0,0,24,2.67Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className={dropDomnMenu}>
                    <div className={styles.dropdownContent}>
                        <ul className={styles.menuList}>
                            <DropDownMenuLinks 
                                i={props.i}
                                dropDomnToggle={dropDomnToggle}
                            />
                            {ReadAndWriteProcedure(props, props.i, dropDomnToggle)}
                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                ? buttonPublishAuctionNew(props, props.i, dropDomnToggle, setPopUpCadastr)
                                : null
                            }
                            {buttonsCancelAndDeleteAuction(props, dropDomnToggle)}

                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                ? cloneZU(props, props.i)
                                : null
                            }
                            {isLLE(props.i.sellingMethod)
                                ? cloneZU1(props, props.i)
                                : null
                            }
                            {!isSPE(props.i.sellingMethod) && !isSPD(props.i.sellingMethod)
                                && !isLAW(props.i.sellingMethod) && !isLPE(props.i.sellingMethod)
                                && !isLLE(props.i.sellingMethod)// && props.i.tenderAttempts !== 1 && props.i.tenderAttempts !== 3)
                                ? <li
                                    onClick={
                                        () => {
                                            if (!isLLE(props.i.sellingMethod)) {
                                                cloneAucNewMongo(props, props.i.id, props.i.auctionId)
                                            } else {
                                                if (props.i.tenderAttempts !== 1 && props.i.tenderAttempts !== 3) {
                                                    cloneAucNewMongo(props, props.i.id, props.i.auctionId)
                                                }
                                            }
                                        }
                                    }
                                >
                                    Створити копію аукціону
                                </li>
                                : null
                            }
                            {(isLAE(props.i.sellingMethod) || isLAP(props.i.sellingMethod)) && (props.i.status === "cancelled" || props.i.status === "unsuccessful")
                                ? <li
                                    onClick={
                                        ()=>{
                                            copyTo(props, props.i.id, "landArrested-withoutAuction")
                                        }
                                    }
                                >
                                    Створити Арештована земля: викуп
                                </li>
                                : null
                            }
                            {viewcompleteAuction(props, props.i, dropDomnToggle)}
                        </ul>
                    </div>
                    <div className={styles.dropdownBlock} onClick={dropDomnToggle}></div>
                </div>
            </div>
            {popUpCadastr !== null ? <div className={styles.popup_wrap}>
            <div className={styles.popup_message}>
                <div
                    className={styles.popUpExit}
                    onClick={
                        ()=>{
                            setPopUpCadastr(null)
                        }
                    }
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={styles.closeSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>

                </div>
                <div className={styles.popUpTitle}>Для вказаної вами земельної ділянки вже було опублікований аукціон іншим організатором, ви впевнені, що хочете продовжити публікацію?</div>
                <div className={styles.blokButton}>
                    <div
                        className={styles.popupButtonYes}
                        onClick={
                            ()=>{
                                if(!isLAE(props.i.sellingMethod) && !isLAW(props.i.sellingMethod) && !isLAP(props.i.sellingMethod)
                                    && !isLRE(props.i.sellingMethod) && !isLSE(props.i.sellingMethod) && !isLSP(props.i.sellingMethod)
                                ){
                                    sendAuction(props, popUpCadastr.id)
                                    setPopUpCadastr(null)
                                }else{
                                    sendAuctionMonga(props, popUpCadastr.id)
                                    setPopUpCadastr(null)
                                }
                            }
                        }
                    >Так</div>
                    <div
                        className={styles.popupButtonNo}
                        onClick={
                            ()=>{
                                setPopUpCadastr(null)
                            }
                        }
                    >Ні</div>
                </div>
                
            </div>
        </div> : null}
        </>
    )
}

//Просмотр/редагування
function ReadAndWriteProcedure(props, i, data){
    if(isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)){
        if(
            i.status === 'active_auction'
            || i.status === 'active_qualification'
            || i.status === 'active_awarded'
            || i.status === 'unsuccessful'
            || i.status === 'cancelled'
            || i.status === 'complete'
        ) return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(isLLE(i.sellingMethod) || isLLD(i.sellingMethod) || isLLP(i.sellingMethod)){
        if(
            i.status === 'active_auction'
            || i.status === 'active_qualification'
            || i.status === 'active_awarded'
            || i.status === 'unsuccessful'
            || i.status === 'cancelled'
            || i.status === 'complete'
        ) return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(isBRW(i.sellingMethod) &&  i.status === 'active_awarded'){
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        isLAW(i.sellingMethod)
        && (
            i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        if(i?.awards[0]?.status === 'active') return null
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        (isSPE(i.sellingMethod) || isSPD(i.sellingMethod) || isLPE(i.sellingMethod))
        && (
            i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        return (
            <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            data()
                            createButton(props, true)
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                    >
                    Редагувати
                </li>
            </Link>
        )
    }else if(
        (isAPE(i.sellingMethod) || isAPD(i.sellingMethod))
        && (
            i.status !== 'active_auction'
            && i.status !== 'active_qualification'
            && i.status !== 'pending_payment'
            && i.status !== 'active_awarded'
            && i.status !== 'unsuccessful'
            && i.status !== 'cancelled'
            && i.status !== 'complete'
        )
    ){
        let tmpEnd = null
        if(i.rectificationPeriod !== null && i.rectificationPeriod.endDate !== null) tmpEnd = new Date(i.rectificationPeriod.endDate)
        let tmpEnd1 = null
        if(tmpEnd !== null) tmpEnd1 = tmpEnd.getTime()
        if(tmpEnd === null || tmpEnd1 > Date.now()){
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(2)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }else{
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(2)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }
    }else if(
        i.status === null ||
        i.status === ''
    ){
        // if(checkProp( props.i, 'dataAuction') && props.i.dataAuction.mysqlDraftId !== null){ //TODO: добаил чекпроп, бо тут падало на том, что нету датаАукшн
        //     return <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
        //         <li
        //             onClick={
        //                 ()=>{
        //                     props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
        //                     data()
        //                     createButton(props)
        //                     if(
        //                         i.sellingMethod.startsWith('landSell-english') ||
        //                         i.sellingMethod.startsWith('landSell-priorityEnglish')
        //                     ){
        //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
        //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
        //                     }
        //                     props.changeAuctionsSwitchWindow(1)
        //                 }
        //             }
        //         >
        //             Редагувати
        //         </li>
        //     </Link>
        // }else{
            return <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                                i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish") ||
                                i.sellingMethod.startsWith('landRental-english') ||
                                i.sellingMethod.startsWith('landSell-english') ||
                                i.sellingMethod.startsWith('landSell-priorityEnglish')
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        // }
    }else if(i.status === 'active_rectification'){
        // if(checkProp( props.i, 'dataAuction') && props.i.dataAuction.mysqlDraftId !== null){ //TODO: добаил чекпроп, бо тут падало на том, что нету датаАукшн
        //     return <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
        //         <li
        //             onClick={
        //                 ()=>{
        //                     props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
        //                     props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
        //                     data()
        //                     createButton(props)
        //                     if(
        //                         i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        //                         i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish") ||
        //                         i.sellingMethod.startsWith('landRental-english') ||
        //                         i.sellingMethod.startsWith('landSell-english') ||
        //                         i.sellingMethod.startsWith('landSell-priorityEnglish')
        //                     ){
        //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
        //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
        //                     }
        //                     props.changeCreateAuctionTab(2)
        //                     props.changeAuctionsSwitchWindow(1)
        //                 }
        //             }
        //         >
        //             Редагувати
        //         </li>
        //     </Link>
        // }else{
            return <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            props.setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                            props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                            props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                            data()
                            createButton(props)
                            if(
                                i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                                i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish") ||
                                i.sellingMethod.startsWith('landRental-english') ||
                                i.sellingMethod.startsWith('landSell-english') ||
                                i.sellingMethod.startsWith('landSell-priorityEnglish')
                            ){
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                            }
                            props.changeCreateAuctionTab(2)
                            props.changeAuctionsSwitchWindow(1)
                        }
                    }
                >
                    Редагувати
                </li>
            </Link>
        // }
    }else if(i.status === 'active_tendering'){
        if(
            i.sellingMethod.startsWith('landSell-priorityEnglish')
        ){
            // if(props.i.dataAuction.mysqlDraftId !== null){
            //     return (
            //         <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
            //             <li
            //                 onClick={
            //                     ()=>{
            //                         data()
            //                         createButton(props)
            //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
            //                         props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
            //                         props.changeAuctionsSwitchWindow(1)
            //                     }
            //                 }
            //             >
            //                 Редагувати
            //             </li>
            //         </Link>
            //     )
            // }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props)
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                        >
                            Редагувати
                        </li>
                    </Link>
                )
            // }
        }else if(
            isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
            isALE(i.sellingMethod) ||
            isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
            isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
            isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
            isCLE(i.sellingMethod) || isCLD(i.sellingMethod) ||
            isNLE(i.sellingMethod) || isNLD(i.sellingMethod) ||
            isLAE(i.sellingMethod) || isLAW(i.sellingMethod) || isLAP(i.sellingMethod) ||
            isAPE(i.sellingMethod) || isAPD(i.sellingMethod) ||
            isLLE(i.sellingMethod) || isLLD(i.sellingMethod) || isLLP(i.sellingMethod)
            || isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)
        ){
            // if(props.i.dataAuction.mysqlDraftId !== null){
            //     return (
            //         <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
            //             <li
            //                 onClick={
            //                     ()=>{
            //                         data()
            //                         createButton(props)
            //                         props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
            //                         props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
            //                         props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
            //                         props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
            //                         props.changeCreateAuctionTab(2)
            //                         props.changeAuctionsSwitchWindow(1)
            //                         if(isLAE(i.sellingMethod) || isLAW(i.sellingMethod) || isLAP(i.sellingMethod)){
            //                             props.setDataAddress(2, 'CREATE_AUCTION_TAB')
            //                         }
            //                     }
            //                 }
            //             >
            //                 Редагувати
            //             </li>
            //         </Link>
            //     )
            // }else{
                if(checkEditDoc(props)){
                    return (
                        <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                            <li
                                onClick={
                                    ()=>{
                                        data()
                                        createButton(props)
                                        props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                        props.changeAuctionsSwitchWindow(1)
                                    }
                                }
                            >
                                Редагувати
                            </li>
                        </Link>
                    )
                }else{
                    return (
                        <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                            <li
                                onClick={
                                    ()=>{
                                        data()
                                        createButton(props)
                                        props.setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
                                        props.setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')
                                        props.changeAuctionsSwitchWindow(1)
                                        props.changeCreateAuctionTab(2)
                                    }
                                }
                            >
                                Редагувати
                            </li>
                        </Link>
                    )
                }
            // }
        }else{
            // if(props.i.dataAuction.mysqlDraftId !== null){
            //     return (
            //         <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
            //             <li
            //                 onClick={
            //                     ()=>{
            //                         data()
            //                         createButton(props, true)
            //                         props.changeCreateAuctionTab(2)
            //                         if(
            //                             i.sellingMethod.startsWith("legitimatePropertyLease-english") ||
            //                             i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
            //                             i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
            //                         ){
            //                             props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
            //                             props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
            //                             props.changeAuctionsSwitchWindow(1)
            //                         }
            //                     }
            //                 }
            //                 >
            //                 Редагувати документи
            //             </li>
            //         </Link>
            //     )
            // }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    if(
                                        i.sellingMethod.startsWith("legitimatePropertyLease-english") ||
                                        i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                                        i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
                                    ){
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
                                        props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
                                        props.changeAuctionsSwitchWindow(1)
                                    }
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            // }
        }
    }else{
        if (
            (i.status === 'active_auction' && (i.sellingMethod.startsWith('alienation-english'))) ||
            ((i.status === 'qualification' || i.status === 'active_qualification') && procedure.isREM(i.sellingMethod))
        ) {
            // if(props.i.dataAuction.mysqlDraftId === null){
            //     return (
            //         <Link to={`/create_auctions/edit/${props.i.dataAuction.mysqlDraftId}`} title="Посилання на аукціон">
            //             <li
            //                 onClick={
            //                     ()=>{
            //                         data()
            //                         createButton(props, true)
            //                         props.changeCreateAuctionTab(2)
            //                         props.changeAuctionsSwitchWindow(1)
            //                     }
            //                 }
            //                 >
            //                 Редагувати документи
            //             </li>
            //         </Link>
            //     )
            // }else{
                return (
                    <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                        <li
                            onClick={
                                ()=>{
                                    data()
                                    createButton(props, true)
                                    props.changeCreateAuctionTab(2)
                                    props.changeAuctionsSwitchWindow(1)
                                }
                            }
                            >
                            Редагувати документи
                        </li>
                    </Link>
                )
            // }
        }else if(
            (isBRE(props.i.sellingMethod) || isBRD(props.i.sellingMethod))
            && i.status !== 'complete'
            && i.status !== 'cancelled'
            && i.status !== 'unsuccessful'
        ){
            return (
                <Link to={`/create_auctions/editNew/${props.i.id}`} title="Посилання на аукціон">
                    <li
                        onClick={
                            ()=>{
                                data()
                                createButton(props, true)
                                props.changeCreateAuctionTab(0)
                                props.changeAuctionsSwitchWindow(1)
                            }
                        }
                        >
                        Редагувати
                    </li>
                </Link>
            )
        }
    }
    return null
}

function checkEditDoc(props){
    if(props.i !== null !== null && props.i.status !== null){
        if(props.i !== null && props.i.documents !== null && props.i.documents.length > 0){
            for(let i = 0; i < props.i.documents.length; i++){
                if(props.i.documents[i].documentType ==='clarifications') return true
            }
        }
        return false
    }else{
        return true
    }
}

async function createButton(props){
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    createArrayBankAccounts(props, props.i)
    if(props.i.discount !== null){
        props.changeDiscountPercent(props.i.discount.discountPercent)
        props.changeDiscountId(props.i.discount.id)
        props.discountAuc(props.i.discount.discount)
        if(props.i.discount.previousAuctionValue !== null){
            props.changeDiscountPreviousAuctionCurrency(props.i.discount.previousAuctionValue.currency)
            props.changeDiscountPreviousAuctionAmount(props.i.discount.previousAuctionValue.amount)
        }
    }
    props.changeDiscountPreviousAuctionId(props.i.previousAuctionId)
    await getAucionFromId(props, props.i, 1)
}

function createArrayBankAccounts(props, i){
    let tmp = [];
    if(i !== null){
        if(checkProp(i, 'bankAccounts') && i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setPopBankAccountIdArr(tmp)
    }
}

//@deprecated
async function getAucionFromId(props, aucId, type, tenderAttempts=null, tenderAtemptVariants){
    props.changeLoader(true)
    let endPoint
    // if(aucId.dataAuction.mysqlDraftId !== null){
    //     endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId.dataAuction.mysqlDraftId}`;
    // }else{
        if(aucId.dataAuction.mongoPublicId !== null){
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${aucId.id}`;
        }else{
            endPoint = toAuctionDraftId(aucId.id)
        }
    // }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            if(type === 3){
                json.status = null
                if (json.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")) {
                    json.tenderAttempts = json.tenderAttempts + 1
                }
            }
            await setAuctionEdit(props, json)
            await discountUpdate(props, json)
            await props.setEditAuctionFromId(json)
            if(tenderAttempts === '2'){
                await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
            }else if(tenderAttempts === '3'){
                if(tenderAtemptVariants === true){
                    await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                    if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                        await props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                    }else{
                        await props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                    }
                }else{
                    await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                }
            }else if(tenderAttempts === '4'){
                await props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    await props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                }else{
                    await props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                }
            }
            if(type===1){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
            }

            if(aucId.dataAuction.mongoDraftId === null){
                await getAddressOrg(props, json, type)
            }
        }
    }
    props.changeLoader(false)
}

async function setAuctionEdit(props, data){
    props.setDataAddress(data, 'EDIT_AUCTION_FROM_ID')
    props.setDataAddress(data, 'CREATE_AUCTIONS_ZU_ATTEMP')
    props.changeDiscountPreviousAuctionId(data.auctionId)
}

async function discountUpdate(props, data){
    if(data.previousAuctionId !== null){
        if(data.discount !== null){
            if(data.discount.previousAuctionValue !== null){
                props.changeDiscountPreviousAuctionAmount(data.discount.previousAuctionValue.amount)
                props.setDataAddress(data.discount.previousAuctionValue.valueAddedTaxIncluded, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
            }
            props.discountAuc(data.discount.discount)
            props.changeDiscountId(data.discount.id)
            props.changeDiscountPercent(data.discount.discountPercent)
        }
    }
}

async function getAddressOrg(props, auction, type=1){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                    }
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    if(type === 3){
        await props.setDataAddress(null, 'CLONE_AUCTIONS_STATUS_CHANGE')
    }
    props.changeLoader(false)
}

//Публікація аукціону
function buttonPublishAuctionNew(props, i, data, setPopUpCadastr){
    let tmp = null
    if (i.status === null) {
        switch (true) {
            // Старі аукціони MYSQL
            case i.sellingMethod.startsWith("timber-english"):
                if (checkItemsPublish(i) === true && checkDocumentsPublish(i) === true) {
                    tmp = <li onClick={() => {
                        data()
                        sendAuction(props, i.id)
                    }}>Публікація аукціону</li>
                }
                break;
            case i.sellingMethod.startsWith("railwayCargo-english"):
            case i.sellingMethod.startsWith("railwayCargo-dutch"):
            case i.sellingMethod.startsWith("subsoil-english"):
            case i.sellingMethod.startsWith("subsoil-dutch"):
                if (checkItemsPublish(i) === true) {
                    tmp = <li onClick={() => {
                        data()
                        sendAuction(props, i.id)
                    }}>Публікація аукціону</li>
                }
                break;
            default:
                // Нові аукціони MongoDb
                switch (true) {
                    case isBSE(i.sellingMethod):
                    case isBSD(i.sellingMethod):
                        if (isTestFish(i) === true) {
                            if (checkItemsPublish(i) === true) {
                                return tmp = <li onClick={() => {
                                    data()
                                    sendAuctionMonga(props, i.id)
                                }}>Публікація аукціону</li>
                            }
                        } else {
                            return null
                        }
                        break;
                    case isLAE(i.sellingMethod):
                    case isLAP(i.sellingMethod):
                    case isLAW(i.sellingMethod):
                        if (checkItemsPublish(i) === true) {
                            return tmp = <li onClick={() => {
                                data()
                                testCadastrMonga(props, i, setPopUpCadastr)
                            }}>Публікація аукціону</li>
                        }
                        break;

                    default:
                        if (checkItemsPublish(i) === true) {
                            tmp = <li onClick={() => {
                                data()
                                if (isLRE(i.sellingMethod) || isLSE(i.sellingMethod) || isLSP(i.sellingMethod)) {
                                    testCadastr(props, i, setPopUpCadastr)
                                } else {
                                    sendAuctionMonga(props, i.id)
                                }
                            }}>Публікація аукціону</li>
                        }
                }
        }
    }
    return tmp
}

function isTestFish(data){
    if(data.hasOwnProperty("items")){
        if(data.items !== null){
            if(data.items.length > 0){
                if(data.items[0].hasOwnProperty("classification")){
                    if(data.items[0].classification !== null){
                        if(data.items[0].classification.hasOwnProperty("id")){
                            if(data.items[0].classification.id === "77700000-7"){
                                if(data.hasOwnProperty("auctionPeriod")){
                                    if(data.auctionPeriod !== null){
                                        if(data.auctionPeriod.hasOwnProperty("startDate")){
                                            if(data.auctionPeriod.startDate !== null){
                                                let dateStartAuc = new Date(data.auctionPeriod.startDate)
                                                let dateNow = Date.now()
                                                dateNow = dateNow + (86400000 * 9)
                                                let tmpStart = dateStartAuc.getTime()
                                                if(tmpStart > dateNow) return true
                                            }
                                        }
                                    }
                                }
                            }else{
                                return true
                            }
                        }
                    }
                }
            }
        }
    }
    return false
}

function checkItemsPublish(i){
    return true
}

function checkDocumentsPublish(i){
    if(i.hasOwnProperty('documents') !== true){
        return false
    }
    let tmpTechnicalSpecifications = false
    let tmpContractProforma = false
    let tmpX_auctionDocumentationPackage = false
    let tmpX_submissionBidProcedure = false
    let tmpX_nonSanctionedRequirements = false
    let tmpX_brokerPaymentConditions = false
    let tmpX_qualificationSigningTerms = false
    let tmpEvaluationCriteria = false
    if (i.sellingMethod.startsWith("timber-english")) {
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = true
                tmpContractProforma = true
            }
        }
    }else if(
        i.sellingMethod.startsWith( "subsoil-english" ) ||
        i.sellingMethod.startsWith( "subsoil-dutch")
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = true
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = true
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = true
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = true
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = true
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = true
            }
        }
        if(
            tmpX_auctionDocumentationPackage === true &&
            tmpX_submissionBidProcedure === true &&
            tmpX_nonSanctionedRequirements === true &&
            tmpX_brokerPaymentConditions === true &&
            tmpX_qualificationSigningTerms === true &&
            tmpEvaluationCriteria === true
        ){
            return true
        }else{
            return false
        }
    }else if(
        i.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        let tmpContractProformaZU = false
        let tmpIllustrationZU = false
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = true
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = true
            }
        }
        return (tmpContractProformaZU === true && tmpIllustrationZU === true)
    }

	return tmpTechnicalSpecifications === true && tmpContractProforma === true;
}

async function testCadastr(props, data, setPopUpCadastr){
    let url = ``
    let cadastralNumber
    cadastralNumber = (isLRE(data.sellingMethod) || isLSE(data.sellingMethod) || isLSP(data.sellingMethod)) ?
        data?.items[0]?.itemProps?.cadastralNumber :
        data?.items[0]?.landProps?.cadastralNumber;
    if (cadastralNumber == null ) {
        let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Кадастровий номер вказано невірно'})
        props.setUniversalError(tmp)
        return
    }

    url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/check/auction/byCadastralNumber/${cadastralNumber}`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: getDefaultHeaders(props)
    })
    .catch(console.error)
    if(response1 !== null){
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                if(json1.length === 0){
                    sendAuctionMonga(props, data.id)
                }else{
                    setPopUpCadastr(data)
                }
            }
        }
    }
}

async function testCadastrMonga(props, data, setPopUpCadastr){
    if(data.items !== null) {
        if(data.items.length > 0) {
            if(data.items[0].itemProps !== null) {
                if(data.items[0].itemProps.cadastralNumber !== null) {
                    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/check/auction/byCadastralNumber/${data.items[0].itemProps.cadastralNumber}`;
                    const response1 = await fetch(url, {
                        method: "POST",
                        body: JSON.stringify({
                            excludeCurrentUser: true,
                        }),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    })
                    .catch(console.error)
                    if(response1 !== null){
                        if(response1 === undefined || response1 === 'undefined'){
                            props.changeLoader(false)
                            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
                            props.setUniversalError(tmp)
                        }else{
                            const json1 = await response1.json()
                            if(json1.hasOwnProperty('message') === true){
                                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                                props.changeLoader(false)
                                props.setUniversalError(tmp)
                            }else{
                                if(json1.length === 0){
                                    sendAuctionMonga(props, data.id)
                                }else{
                                    setPopUpCadastr(data)
                                }
                            }
                        }
                    }
                }else{
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не не заповнено поле кадастрового номеру'})
                    props.setUniversalError(tmp)
                }
            }else{
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не не заповнено поле кадастрового номеру'})
                props.setUniversalError(tmp)
            }
        }else{
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не створений лот'})
            props.setUniversalError(tmp)
        }
    }else{
        let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: 'Не створений лот'})
        props.setUniversalError(tmp)
    }
}

async function sendAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: getDefaultHeaders(props)
    })
    .then(
        async function(response){
            if(response.status !== 200){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            return response
        }
    )
    .catch(console.error)
    if(response1 !== null){
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAuc(props, id), 2000)
                
            }
        }
    }
}

async function sendAuctionMonga(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: getDefaultHeaders(props)
    })
    .then(
        async function(response){
            if(response.status === 400 || response.status === 401){
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let message = typeof json.message === 'object' ?  Object.values(json.message) : json.message
                    let tmp = JSON.stringify({
                        title: 'Помилка при активації аукціону',
                        message:  message,
                        sellingMethod: props.i.sellingMethod
                    })
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }else{
                    let tmpText = 'Помилка без сповіщення'
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: tmpText, sellingMethod: props.i.sellingMethod})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            let errorCodes = [403, 404, 500, 501, 502, 503, 504]
            if (errorCodes.includes(response.status)) {
                const json = await response.json()
                if (json.hasOwnProperty('message') === true) {
                    let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json)})
                    props.changeLoader(false)
                    props.setUniversalError(tmp)
                    return null
                }
            }
            if(response.status === 200){
                return response
            }
        }
    )
    .catch(console.error)
    if(response1 !== null){
    /*** */
        if(response1 === undefined || response1 === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response1.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAucMonga(props), 2000)
                
            }
        }
    }
}

async function syncAuc(props, id){
    const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync?` + Math.random();
    const response2 = await fetch(url2, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.setPopUpAuctionMyNull()
        let data = {}
        data.domen = process.env.REACT_APP_END_POINT_BD
        data.version = 'v1'
        data.local = props.langFlag
        if(props.myCabinetPage === 0){
            data.params = `?offset=${props.myCabinetPage}`
        }else{
            data.params = `?offset=${props.myCabinetPage}0` 
        }

        //props.auctionsGetMy(data, header)
        props.changeLoader(false)
    }else{
        props.setPopUpAuctionMyNull()
        let data = {}
        data.domen = process.env.REACT_APP_END_POINT_BD
        data.version = 'v1'
        data.local = props.langFlag
        if(props.myCabinetPage === 0){
            data.params = `?offset=${props.myCabinetPage}`
        }else{
            data.params = `?offset=${props.myCabinetPage}0` 
        }

        //getAucionsList(props)
        props.changeLoader(false)
    }
    getAucionsList(props)
    props.changeLoader(false)
}

async function copyTo(props, id, sellingMethod){
    const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/copy_to/${sellingMethod}`; 
    const response = await fetch(url2, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response !== null){
    /*** */
        if(response === undefined || response === 'undefined'){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: ''})
            props.setUniversalError(tmp)
        }else{
            const json1 = await response.json()
            if(json1.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
                props.changeLoader(false)
                props.setUniversalError(tmp)
            }else{
                await setTimeout(() => syncAucMonga(props), 2000)
                
            }
        }
    }
}

async function syncAucMonga(props){
    getAucionsList(props)
    props.changeLoader(false)
}

//Видалити аукціон
function buttonsCancelAndDeleteAuction(props, data){
    let tmp = null;
    if(props.i.status !== 'complete' && props.i.status !== 'cancelled' && props.i.status !== 'unsuccessful'){
        tmp = <li
            onClick={
                ()=>{
                    data()
                    props.setPopUpDelAuction(props.i)
                }
            }
            title="Видалення аукціону"
        >Видалити/скасувати аукціон</li>
    }
    return tmp
}

async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}
//Створення копії аукціону

function cloneZU(props, i){
    if(
        i.status === 'cancelled' ||
        i.status === 'unsuccessful' ||
        i.status === 'complete'
    ){
        if(
            i.sellingMethod.startsWith( "legitimatePropertyLease-english")
        ){
            if(i.tenderAttempts === 1){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMongo(props, props.i.id, props.i.auctionId, '?type=discount')
                                }
                            }
                        >
                            Створити копію аукціону зі зниженням стартової ціни
                        </li>
                    </>
                )
            }else if(i.tenderAttempts === 2){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMongo(props, props.i.id, props.i.auctionId)
                                }
                            }
                        >
                            Створити копію аукціону зі зниженням стартової ціни
                        </li>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMongo(props, props.i.id, props.i.auctionId, '?type=discount')
                                }
                            }
                        >
                            Створити аукціон за методом покрокового зниження
                        </li>
                    </>
                )
            }else if(i.tenderAttempts === 3){
                return (
                    <>
                        <li
                            onClick={
                                ()=>{
                                    cloneAucNewMongo(props, props.i.id, props.i.auctionId, '?type=discount')
                                }
                            }
                        >
                            Створити аукціон за методом покрокового зниження
                        </li>
                    </>
                )
            }
        }
    }
}

function cloneZU1(props, i){
    if(i.tenderAttempts === 2) return null
    return (
        <>
            <li
                onClick={
                    ()=>{
                        cloneAucNewMongo(props, props.i.id, props.i.auctionId)
                    }
                }
            >
                Створити копію аукціону
            </li>
        </>
    )
}

//Завершення аукціону
function viewcompleteAuction(props, i, data){
    let tmp = null
    let tmpSellingMethod = null
    if(i !== null){
        if(i.hasOwnProperty('sellingMethod') === true){
            if(i.sellingMethod !== null){
                tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
            }
            if( tmpSellingMethod === -1){
                if(i.hasOwnProperty('contracts') === true){
                    if(i.contracts.length > 0){
                        if(i.contracts.length > 0){
                            for(let t=0; t < i.contracts.length; t++){
                                if(i.contracts[t].status === 'active'){
                                    tmp = <li
                                        onClick={
                                            () => {
                                                data()
                                                if(
                                                    isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
                                                    isALE(i.sellingMethod) ||
                                                    isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
                                                    isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
                                                    isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
                                                    isCLE(i.sellingMethod) || isCLD(i.sellingMethod)
                                                ){
                                                    completeAuctionMongo(props, i.id)
                                                }else{
                                                    completeAuction(props, i.id)
                                                }
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </li>
                                }
                            }
                        }
                    }
                }
            }else{
                if(i.status === 'active_qualification'){
                    if(i.hasOwnProperty('awards') === true){
                        if(i.awards.length > 0){
                            for(let iAwards = 0; iAwards < i.awards.length; iAwards++){
                                if(i.awards[iAwards].status === 'active'){
                                    tmp = <li
                                        onClick={
                                            () => {
                                                data()
                                                if(
                                                    isBSE(i.sellingMethod) || isBSD(i.sellingMethod) ||
                                                    isALE(i.sellingMethod) ||
                                                    isCSE(i.sellingMethod) || isCSD(i.sellingMethod) ||
                                                    isBRE(i.sellingMethod) || isBRD(i.sellingMethod) || isBRW(i.sellingMethod) ||
                                                    isRLE(i.sellingMethod) || isRLD(i.sellingMethod) ||
                                                    isCLE(i.sellingMethod) || isCLD(i.sellingMethod)
                                                ){
                                                    completeAuctionMongo(props, i.id)
                                                }else{
                                                    completeAuction(props, i.id)
                                                }
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </li>
                                    break
                                }
                            }
                        }
                    }
                }
            }
            if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") tmp = null
        }
    }
    return tmp
}

async function completeAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync?` + Math.random();
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                await response2.json();
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }else{
                await response2.json();
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
        props.changeLoader(false)
    }
}

async function completeAuctionMongo(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: getDefaultHeaders(props)
    }).catch(error => {
        props.changeLoader(false)
        console.error('Api request error:', error);
    })
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            await getAucionsList(props)   
        }
        props.changeLoader(false)
    }
}

async function cloneAucNewMongo(props, id, auctionId, data=''){
    props.changeLoader(true)
    let url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/copy${data}`;
    if(auctionId === null) url = toAuctionDraftCopy(id);
    const response1 = await fetch(url, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            props.setPopUpAuctionMyNull()
            props.setDataAddress(null, 'AUCTIONS_GET_MY')
            await getAucionsList(props)
        }
    }
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,

    getMyAuction,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    auctionsGetMy,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    changeDiscountId,
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,
    setPopUpDelAuction,

    changeLoader,
    setPopUpAuctionMyNull,

    changeCreateAuctionTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownMenu)