import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
} from '../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../redux/actions/auctionItems/auctionItems.js';

import {
    changePopUpBankAccount,
    setPopBankAccountOpus,
    setPopBankAccountNazvaBanku,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setPopBankAccountEditId,
    setBankAccountDelete,
} from '../../../redux/actions/bankAccount/bankAccount.js';

import Button from '@material-ui/core/Button';

const ViewAddBankAccont = ( props ) => {
    let tmp = null;
    if(props.auctionsMy !== null){
        //if(props.auctionsMy.bankAccounts !== null){
            //if(props.auctionsMy.bankAccounts.length > 0){
                if(props.bankAccount !== null){
                    if(props.auctionsMy.status === null){
                        tmp = <>
                            {allBanckAccountProduct(props)}
                            <div>Банки що будуть додані після збереження</div>
                            {allBanckAccountProductAdd(props)}
                            <div>Банки що будуть вилучені після збереження</div>
                            {allBanckAccountProductDelete(props)}
                        </>
                    }else if(props.auctionsMy.status === 'active_rectification'){
                        tmp = <>
                            {allBanckAccountProduct(props)}
                            <div>Банки що будуть додані після збереження</div>
                            {allBanckAccountProductAdd(props)}
                            <div>Банки що будуть вилучені після збереження</div>
                            {allBanckAccountProductDelete(props)}
                        </>
                    }else{
                        tmp = <>
                            {allBanckAccountProductDisabled(props)}
                        </>
                    }
                }else{
                    tmp = <div>Підключені банки відсутні</div>
                }
            /*}else{
                tmp = <div>Підключені банки відсутні</div>
            }
        }else{
            tmp = <div>Підключені банки відсутні</div>
        }*/
    }
    return (
        <div className={styles.bankAccountElements}>
            {tmp}
        </div>
    )
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function allBanckAccountProduct(props){
    let tmp = null;
    if(props.auctionsSwitchWindow === 1){
        tmp = props.auctionsMy.bankAccounts
        .filter(
            (i) => {
                return props.bankAccountWork.deleteBank.includes(String(i.id)) === false
            }
        )
        .map(
            (i) => {
                return (
                    <div className={styles.bankAccountElement}>
                        <div>
                            <div>Назва банку: {i.bankName}</div>
                            <div>Опис: {i.description}</div>
                        </div>
                        <div>
                            <div>Опис до банківських реквізитів: {i.accountIdentifications[0].description}</div>
                            <div>Ідінтіфікатор: {i.accountIdentifications[0].identifier}</div>
                            <div>Тип банківського ідентифікатора: {i.accountIdentifications[0].scheme}</div>
                        </div>
                        <div>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        let bankScheme = null
                                        if(props.popUpBankAccountSheme !== null){
                                            for(let tt = 0; tt < props.popUpBankAccountSheme.length; tt++){
                                                if(props.popUpBankAccountSheme[tt].classificationId === i.accountIdentifications[0].scheme){
                                                    bankScheme = JSON.stringify({
                                                        id: props.popUpBankAccountSheme[tt].id,
                                                        classificationId: props.popUpBankAccountSheme[tt].classificationId,
                                                        description: props.popUpBankAccountSheme[tt].description,
                                                        scheme: props.popUpBankAccountSheme[tt].scheme
                                                    })
                                                }
                                            }
                                        }
                                        //editBankAccounts(props, i)
                                        props.setPopBankAccountNazvaBanku(i.bankName)
                                        props.setPopBankAccountOpus(i.description)
                                        props.setPopBankAccountNazva(i.accountIdentifications[0].description)
                                        props.setPopBankAccountIdentif(i.accountIdentifications[0].identifier)
                                        props.setPopBankAccountSheme(bankScheme)
                                        props.setPopBankAccountEditId(i.id)
                                        props.changePopUpBankAccount(true)
                                    }
                                }
                            >Редагувати</Button>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        let tmpBankAdd = props.bankAccountWork.deleteBank
                                        tmpBankAdd.push(String(i.id))
                                        props.setBankAccountDelete(tmpBankAdd)
                                        //removeArrayBankAccounts(props, i.id)
                                    }
                                }
                            >Вилучити</Button>
                        </div>
                    </div>
                )
            }
        )
    }
    return tmp
}

function allBanckAccountProductAdd(props){
    let tmp;
    tmp = props.bankAccount
    .filter(
        (i) => {
            return props.bankAccountWork.addNewBank.includes(String(i.id)) === true
        }
    )
    .map(
        (i) => {
            return (
                <div className={styles.bankAccountElement}>
                    <div>
                        <div>Назва банку: {i.bankName}</div>
                        <div>Опис: {i.description}</div>
                    </div>
                    <div>
                        <div>Опис до банківських реквізитів: {i.accountIdentifications[0].description}</div>
                        <div>Ідінтіфікатор: {i.accountIdentifications[0].identifier}</div>
                        <div>Тип банківського ідентифікатора: {i.accountIdentifications[0].scheme}</div>
                    </div>
                    {/*<div>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    let bankScheme = null
                                    if(props.popUpBankAccountSheme !== null){
                                        for(let tt = 0; tt < props.popUpBankAccountSheme.length; tt++){
                                            if(props.popUpBankAccountSheme[tt].classificationId === i.accountIdentifications[0].scheme){
                                                bankScheme = JSON.stringify({
                                                    id: props.popUpBankAccountSheme[tt].id,
                                                    classificationId: props.popUpBankAccountSheme[tt].classificationId,
                                                    description: props.popUpBankAccountSheme[tt].description,
                                                    scheme: props.popUpBankAccountSheme[tt].scheme
                                                })
                                            }
                                        }
                                    }
                                    //editBankAccounts(props, i)
                                    props.setPopBankAccountNazvaBanku(i.bankName)
                                    props.setPopBankAccountOpus(i.description)
                                    props.setPopBankAccountNazva(i.accountIdentifications[0].description)
                                    props.setPopBankAccountIdentif(i.accountIdentifications[0].identifier)
                                    props.setPopBankAccountSheme(bankScheme)
                                    props.setPopBankAccountEditId(i.id)
                                    props.changePopUpBankAccount(true)
                                }
                            }
                        >Редагувати</Button>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    removeArrayBankAccounts(props, i.id)
                                }
                            }
                        >Вилучити</Button>
                    </div>*/}
                </div>
            )
        }
    )
    return tmp
}

function allBanckAccountProductDelete(props){
    let tmp = null;
    if(props.auctionsSwitchWindow === 1){
        tmp = props.auctionsMy.bankAccounts
        .filter(
            (i) => {
                return props.bankAccountWork.deleteBank.includes(String(i.id)) === true
            }
        )
        .map(
            (i) => {
                return (
                    <div className={styles.bankAccountElement}>
                        <div>
                            <div>Назва банку: {i.bankName}</div>
                            <div>Опис: {i.description}</div>
                        </div>
                        <div>
                            <div>Опис до банківських реквізитів: {i.accountIdentifications[0].description}</div>
                            <div>Ідінтіфікатор: {i.accountIdentifications[0].identifier}</div>
                            <div>Тип банківського ідентифікатора: {i.accountIdentifications[0].scheme}</div>
                        </div>
                        {/*<div>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        let bankScheme = null
                                        if(props.popUpBankAccountSheme !== null){
                                            for(let tt = 0; tt < props.popUpBankAccountSheme.length; tt++){
                                                if(props.popUpBankAccountSheme[tt].classificationId === i.accountIdentifications[0].scheme){
                                                    bankScheme = JSON.stringify({
                                                        id: props.popUpBankAccountSheme[tt].id,
                                                        classificationId: props.popUpBankAccountSheme[tt].classificationId,
                                                        description: props.popUpBankAccountSheme[tt].description,
                                                        scheme: props.popUpBankAccountSheme[tt].scheme
                                                    })
                                                }
                                            }
                                        }
                                        //editBankAccounts(props, i)
                                        props.setPopBankAccountNazvaBanku(i.bankName)
                                        props.setPopBankAccountOpus(i.description)
                                        props.setPopBankAccountNazva(i.accountIdentifications[0].description)
                                        props.setPopBankAccountIdentif(i.accountIdentifications[0].identifier)
                                        props.setPopBankAccountSheme(bankScheme)
                                        props.setPopBankAccountEditId(i.id)
                                        props.changePopUpBankAccount(true)
                                    }
                                }
                            >Редагувати</Button>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        let tmpBankAdd = props.bankAccountWork.deleteBank
                                        tmpBankAdd.push(i.id)
                                        props.setBankAccountDelete(tmpBankAdd)
                                        removeArrayBankAccounts(props, i.id)
                                    }
                                }
                            >Вилучити</Button>
                        </div>*/}
                    </div>
                )
            }
        )
    }
    return tmp
}

function allBanckAccountProductDisabled(props){
    let tmp;
    tmp = props.auctionsMy.bankAccounts
    .map(
        (i) => {
            return (
                <div className={styles.bankAccountElement}>
                    <div>
                        <div>Назва банку: {i.bankName}</div>
                        <div>Опис: {i.description}</div>
                    </div>
                    <div>
                        <div>Опис до банківських реквізитів: {i.accountIdentifications[0].description}</div>
                        <div>Ідінтіфікатор: {i.accountIdentifications[0].identifier}</div>
                        <div>Тип банківського ідентифікатора: {i.accountIdentifications[0].scheme}</div>
                    </div>
                    <div>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="disabled"
                        >Редагувати</Button>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="disabled"
                        >Вилучити</Button>
                    </div>
                </div>
            )
        }
    )
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        bankAccountEditId: state.start.bankAccountEditId,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        popUpBankAccountSheme: state.start.popUpBankAccountSheme,
        bankAccountWork: state.start.bankAccountWork,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    setPopBankAccountNazvaBanku,
    setPopBankAccountOpus,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountEditId,
    setBankAccountDelete,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAddBankAccont)
