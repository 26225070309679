import React, {useState, useEffect} from 'react';
import styles from './bankAccounts.module.css';

import { useSelector } from 'react-redux';
import BankItem from './bankItem';

const BankGroup = ( { data, bankAccounts, setBankAccounts, deleteBanks, setDelBankArr, disabledBtn} ) => {
    const [del] = useState(false);
    const [banksGroups, setBanksGroups] = useState(data)

    useSelector(state => state.start.auctionsMy);

    useEffect(() => {
        setBanksGroups(data)
    }, [bankAccounts]);

    let obj = {}
    obj.tmpText = 'Видалити групу'
    obj.tmpColorButton = 'primary'

    if(data.hasOwnProperty('holderType')){
        if(data.holderType === 'balanceHolder'){
        }else if(data.holderType === 'landlord'){
        }else if(data.holderType === 'budget'){
        }
    }

    let tmpAccountType = null
    if(data.accountType === 'registrationFee'){
        tmpAccountType = 'Рахунок для реєстраційного внеску'
    }else if(data.accountType === 'guarantee'){
        //tmpAccountType = 'Рахунок для гарантійного внеску'
        tmpAccountType = 'Гарантійний внесок'
    }else if(data.accountType === 'securityDeposit'){
        tmpAccountType = 'Рахунок для забезпечувального депозиту'
    }else if(data.accountType === 'improvementCompensation'){
        tmpAccountType = 'Рахунок для компенсації невід\'ємних поліпшень'
    }else if(data.accountType === 'other'){
        //tmpAccountType = 'Інші банківські рахунки'
        tmpAccountType = 'Інші платежі'
    }else if(data.accountType === 'advancePayment'){
        tmpAccountType = 'Рахунок для авансового внеску'
    }else if(data.accountType === 'lease'){
        tmpAccountType = 'Рахунок для оплати оренди'
    }else if(data.accountType === 'organizer'){
        tmpAccountType = 'Організатор'
    }else if(data.accountType === 'advancePayment'){
        tmpAccountType = 'Авансовий внесок'
    }else if(data.accountType === 'preparationPayment'){
        tmpAccountType = 'Плата за підготовку лота'
    }else if(data.accountType === 'payment'){
        tmpAccountType = 'Плата за лот'
    }

    function viewDeleteBanks(data, bankAccounts, setBankAccounts, type){ //банки которые есть уже
        if(data.length > 0){
            return data.map(
                (i) => { 
                    return <BankItem 
                                key={i.id}
                                data={i}
                                bankAccounts={bankAccounts}
                                setBankAccounts={setBankAccounts}
                                group={type}
                                disabledBtn={disabledBtn}
                                allDel={del}
                            />
                }
            )
        }
    }


    return (
        <div className={del ? `${styles.viewBankGroup} ${styles.bankDelete}` : styles.viewBankGroup}>
            <div className={styles.groupeBank}>
                <div className={styles.banksNameLine}>
                    {tmpAccountType}
                </div>
            </div>
            {viewDeleteBanks(data.accounts, bankAccounts, setBankAccounts, data.accountType, deleteBanks, setDelBankArr ) }
        </div>
    )


}

export default BankGroup