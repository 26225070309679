import React from 'react';

import {connect} from 'react-redux';

import TextHalfLink from '../../../elements/viewData/textFullLink'

const AuctionUrlString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionUrl') === true){
            if(props.auctionId.auctionUrl !== null){
                return (
                    <TextHalfLink
                        title="Веб-адреса для перегляду аукціону"
                        data={props.auctionId.auctionUrl}
                        text='Посилання на сторінку аукціону'
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(AuctionUrlString)