import React from 'react';
import styles from './catalog.module.css';

import Button from '@material-ui/core/Button';

import {setTogetherApi, changeLoader, setUniversalError} from '../../../redux/actions.js';
import {
    setGetTimberSpecies,
    setGetTimberSortment,
    setGetTimberStorage,
    setGetTimberDiameter,
    setGetTimberClass,

    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
} from '../../../redux/togetherApi.js';

import ItemsCard from './itemsCard/itemsCard'
import TogetherAPI from './togetherAPI/togetherAPI.js';

import {connect} from 'react-redux';

import {
    auctionsSearchTogetherAPI,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
} from '../../../redux/actions/auctions/auctions.js';

const CURRENT_AUCTIONS_LOTS_LANG = ['АКТУАЛЬНІ АУКЦІОНИ', 'CURRENT AUCTIONS'];

const Catalog = (props) => {
    let tmp = null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true || props.userProfiles.canCreateAuction === true){
            tmp = buttonTogetherAPI(props)
        }
    }
    return (
        <section className = { styles.wrap } >
            <header className={styles.header_title}>
                <h1 className={styles.titleText}>
                    {CURRENT_AUCTIONS_LOTS_LANG[props.langFlag]}
                </h1>
                {tmp}
            </header>
            {componentTogetherAPI(props)}
        </section>
    )
}

function componentTogetherAPI(props){
    let tmp;
    switch(props.search.category){
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
            if(props.togetherApi === true){
                //@todo remove all TogetherAPI components
                tmp = <TogetherAPI />
            }else{
                tmp = <>
                    {logicTypeAuction(props.auctions)}
                    {createMoreProduct(props.auctions)}
                </>
            }
            break;
        default:
            tmp = <>
                    {logicTypeAuction(props.auctions)}
                    {createMoreProduct(props.auctions)}
                </>
            break;
    }
    return tmp
}

function buttonTogetherAPI(props){
    let tmp = null;
    switch(props.search.category){
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
            if(props.token !== null){
                tmp = <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            combainGetAllInterface(props)
                        }
                    }
                >
                    Єдиний інтерфейс
                </Button>
            }
            break;
        default:
            break;
    }
    return tmp
}

async function combainGetAllInterface(props){
    await props.changeLoader(true)
    await props.setTogetherApi()
    let header5 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=100'
    }
    await props.auctionsGetClassificationsByType(data5, header5)

    await proxyGetTimberSpecies(props)
    await proxyGetTimberSortment(props)
    await proxyGetTimberStorage(props)
    await proxyGetTimberDiameter(props)
    await proxyGetTimberClass(props)

    let data6 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let headerTogetherApi = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let dataSearch = {
        category: props.search.category,
    }
    await props.auctionsSearchTogetherAPI(data6, headerTogetherApi, dataSearch)


    await props.togetherApiPageCurrentAuction(0)
    if(props.auctions.length === 10) {
        await props.togetherApiPageNextAuction(1)
    }else{
        await props.togetherApiPageNextAuction(0)
    }
    await props.changeLoader(false)
}

async function proxyGetTimberSpecies(props){
    let url;
        url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberSpecies`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberSpecies(response)
}

async function proxyGetTimberSortment(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberSortment`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberSortment(response)
}

async function proxyGetTimberStorage(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberStorage`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberStorage(response)
}

async function proxyGetTimberDiameter(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberDiameter`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberDiameter(response)
}

async function proxyGetTimberClass(props){
    let url = `https://procedure-sandbox.prozorro.sale/api/dictionaries/timberClass`;
    const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.setGetTimberClass(response)
}

function logicTypeAuction(auctions){
    let tmp = null;
    if(window.location.pathname.length <= 1){
        if(auctions !== null){
            tmp = auctions
            .filter(
                (e) => {return e.items.length > 0}
            )
            .map(
                (item) => {
                    return (
                        <>
                            <ItemsCard 
                                key={'1'+item.id}
                                item={item}
                            />
                        </>
                    )
                }
            )
        }
    }
    return tmp
}

function createMoreProduct(auctions){
    let tmp = null;
    if(window.location.pathname.length >= 1){
        if(auctions !== null){
            tmp = auctions
            .map(
                (item) => {
                    return (
                        <>
                            <ItemsCard 
                                key={'2'+item.id}
                                item={item}
                            />
                        </>
                    )
                }
            )
        }
    }
    return tmp
}


const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        togetherApi: state.start.togetherApi,
        token: state.start.token,
        togetherAPIelem: state.togetherAPIelem,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setTogetherApi,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    setGetTimberSpecies,
    setGetTimberSortment,
    setGetTimberStorage,
    setGetTimberDiameter,
    setGetTimberClass,
    auctionsSearchTogetherAPI,
    changeLoader,
    setUniversalError,

    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
