import {
    ALL_AUCTION_BIDS_ARR,
    ALL_AUCTION_BIDS_AUCTION_ID,
} from "./types";

//@deprecated
export function saveArrBids(value) {
    return {
        type: ALL_AUCTION_BIDS_ARR,
        payload: value
    }
}

//@deprecated
export function saveAuctionId(value) {
    return {
        type: ALL_AUCTION_BIDS_AUCTION_ID,
        payload: value
    }
}