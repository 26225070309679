import React, {useState} from 'react';
import styles from './createBid.module.css';

import {connect} from 'react-redux';
import {changeBidAward, changeBidQuantity} from '../../../../redux/actions.js';
import {
    auctionsBidsGetId,
    changeBidErrorPopUpStatus
} from '../../../../redux/actions/auctionBid/auctionBid.js';

import {changeLoader, setUniversalError} from '../../../../redux/actions.js';
import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';
import TextFull from '../../../elements/viewData/textFull';

import {
    setDataAddress,
} from '../../../../redux/createAddress';
import {
    saveArrBids,
} from '../../../../redux/allAuctionBids.js';
import {getCurrency} from "../../../../redux/check";
import procedure from "../../../../redux/procedure";
import {getDefaultHeaders, getUserBidsListByAuction} from "../../../_api/bidApi";

const CreateBid = (props, isMultiAward) => {
    const [bidValue, setBidValue] = useState(null)
    const [bidQuantity, setBidQuantity] = useState(props?.auction?.items[0]?.quantity)
    const [slim, setSlim] = useState({state: !isMultiAward, text: 'Подати заяву на участь'})

    function Slimmer() {
        slim.state ? setSlim({state: false, text: 'Подати заяву на участь'}) : setSlim({state: true, text: 'Скасувати'})
    }

    function createDraftBid(props, tmpId, minCoast, bidValue) {
        switch (true) {
            case procedure.isDutch(props.auction.sellingMethod):
                if (
                    props.checkBox.waitPersonalData &&
                    props.checkBox.waitZgoda &&
                    props.checkBox.waitZgodaReglament
                ) {
                    return renderCreateDraftButton(true, props, tmpId)
                }
                return renderCreateDraftButton(false)

            case procedure.isLLE(props.auction.sellingMethod):
            case procedure.isLLP(props.auction.sellingMethod):
            case procedure.isLRE(props.auction.sellingMethod):
            case procedure.isLSE(props.auction.sellingMethod):
            case procedure.isLSP(props.auction.sellingMethod):
                if (props.checkBox.agreement === true && props.checkBox.wait === true) {
                    if (bidValue >= minCoast) {
                        return renderCreateDraftButton(true, props, tmpId, bidValue)
                    }
                    return renderCreateDraftButton(false)
                }
                return renderCreateDraftButton(false)

            case procedure.isLLD(props.auction.sellingMethod):
                if (props.checkBox.agreement === true && props.checkBox.wait === true) {
                    return renderCreateDraftButton(true, props, tmpId)
                }
                return renderCreateDraftButton(false)


            case (procedure.isREM(props.auction.sellingMethod)):
                if (bidValue <= minCoast && bidValue) {
                    if (
                        props.checkBox.waitPersonalData &&
                        props.checkBox.waitZgoda &&
                        props.checkBox.waitZgodaReglament &&
                        bidValue <= props.auction.value.amount &&
                        bidQuantity > 0
                    ) {
                        return renderCreateDraftButton(true, props, tmpId, bidValue)
                    }

                }
                return renderCreateDraftButton(false)

            default:
                if (bidValue >= minCoast) {
                    if (
                        props.checkBox.waitPersonalData &&
                        props.checkBox.waitZgoda &&
                        props.checkBox.waitZgodaReglament
                    ) {
                        return renderCreateDraftButton(true, props, tmpId, bidValue)
                    }

                }
                return renderCreateDraftButton(false)
        }
    }

    function renderCreateDraftButton(enabled, props, tmpId, bidValue) {
        enabled = enabled ?? false
        return (
            enabled ?
                <div
                    className={styles.button}
                    onClick={
                        () => {
                            saveBid(props, tmpId, bidValue)
                        }
                    }
                >Створити чернетку</div>
                :
                <div className={styles.button_disabled}>Створити чернетку</div>
        )
    }

    async function saveBid(props, tmpId, bidValue) {
        await props.changeLoader(true)
        let tmpData;

        switch (true) {
            case procedure.isDutch(props.auction.sellingMethod):
                tmpData = {
                    classificationId: props.auction.items[0].classification.id,
                    unitCode: props.auction.items[0].unit.code,
                    documentIds: [],
                    qualified: true,
                    quantity: props.auction.items[0].quantity
                }
                break;
            case (procedure.isREM(props.auction.sellingMethod)):
                tmpData = {
                    classificationId: props.auction.items[0].classification.id,
                    unitCode: props.auction.items[0].unit.code,
                    qualified: true,
                    value: {
                        currency: 'eurocent',
                        amount: Number(bidValue)
                    },
                    quantity: bidQuantity
                }
                break
            default:
                tmpData = {
                    classificationId: props.auction.items[0].classification.id,
                    unitCode: props.auction.items[0].unit.code,
                    documentIds: [],
                    qualified: true,
                    value: {
                        currency: 'UAH',
                        amount: Number(bidValue)
                    },
                    quantity: props.auction.items[0].quantity
                }
                break;
        }


        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid`;
        await props.changeLoader(true)
        const response = await fetch(endPoint, {
            method: "POST",
            body: JSON.stringify(tmpData),
            headers: getDefaultHeaders(props)
        })
            .catch((error) => {
                //@todo error
                props.changeLoader(false)
                console.error(error)
            })

        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            await props.changeLoader(false)
            let tmp
            if (json.message === 'Forbidden status. Cannot create bid object with status active_auction') {
                tmp = JSON.stringify({
                    title: 'Помилка при створенні чернетки',
                    message: 'Аукціон розпочався, створення ставки заборонено'
                })
            } else {
                tmp = JSON.stringify({title: 'Помилка при створенні чернетки', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
            props.changeBidAward('')
            props.changeBidQuantity('')
        } else {
            // await getPublicApiAuction(props, props.auction.id)
            await getUserBidsListByAuction(props, props.auction.id)

            props.changeBidAward('')
            props.changeBidQuantity('')
            await props.changeLoader(false)
        }

        await props.setDataAddress(false, 'CHECKBOX_BID_ALL_1')
        await props.setDataAddress(false, 'CHECKBOX_BID_ALL_2')
        await props.setDataAddress(false, 'CHECKBOX_BID_ALL_3')
        Slimmer()
        setBidValue(null)

        await props.changeLoader(false)
    }


    isMultiAward = props.isMultiAward ?? false
    let tmpBaseUrl = null;
    let tmpId = null;
    if (window.location.pathname.indexOf('/auctions/') !== -1) {
        tmpBaseUrl = window.location.pathname.split('/auctions/');
        tmpId = tmpBaseUrl[1].split('/')
        tmpId = tmpId[0]
    }
    let minCoast = null;
    if (props.auction !== null) {
        minCoast = props.auction.value.amount;
    }

    let tmpSumm = ''

    if (props.userProfiles?.isFinAuth) {
        if (props.bidSumm !== null) {
            if (props.bidSumm.summ !== null) {
                tmpSumm = props.bidSumm.summ
            }
        }
        if (procedure.isLAP(props.auction.sellingMethod)) {
            tmpSumm = 0
            if (props.auction.guarantee.amount !== null) tmpSumm += props.auction.guarantee.amount
            if (props.auction.registrationFee.amount !== null) tmpSumm += props.auction.registrationFee.amount
        }

        return (
            <div className={styles.blockBidLot}>
                {isMultiAward && !slim.state ?
                    <div className={styles.button + ' ' + styles.big} onClick={() => Slimmer()}>{slim.text}</div>
                    :
                    null
                }
                {slim.state || !isMultiAward ?
                    <div>
                        <div className={styles.blockBidLot_title}>Ваша пропозиція</div>
                        <InstructionBlock
                            title='Важлива інформація'
                            content={
                                procedure.isREM(props.auction.sellingMethod) ?
                                    <>
                                        <p>Для участі в даному аукціоні Вам необхідно завантажити до своєї пропозиції
                                            документи згідно <a className={styles.instrLink}
                                                                href='https://zakon.rada.gov.ua/laws/show/1175-2019-%D0%BF#n291'
                                                                target='blank'>Постанови КМУ №1175 від
                                                27.12.2019р.</a> та інші документи згідно вимог організатора, зазначених
                                            в оголошенні про
                                            проведення аукціону.</p>

                                        <p>{`Детальніше в `}
                                            <a className={styles.instrLink} href='https://www.dto.com.ua/info/rules'
                                               target='blank'>Регламенті роботи ЕТС Prozorro:Продажі</a>
                                            {` або в іншому нормативно-правовому акті, який регулює проведення даного аукціону.`}
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p>Для участі в даному аукціоні на Вашому балансі має бути сума
                                            розмірі: {startCostAuction(tmpSumm)} грн.</p>
                                        <p>Реєстраційний внесок є платою за участь в торгах та не підлягає
                                            поверненню.</p>
                                        <p>У випадку вашої перемоги в аукціоні гарантійний внесок залишається
                                            заблокованим
                                            до завершення процедури торгів. У разі дискваліфікації учасник втрачає
                                            гарантійний внесок. В такому випадку, згідно регламенту гарантійний внесок в
                                            повному обсязі перераховується на організатора торгів.</p>

                                        <p>{`Детальніше в `}
                                            <a className={styles.instrLink} href='https://www.dto.com.ua/info/rules'
                                               target='blank'>Регламенті роботи ЕТС Prozorro:Продажі</a>
                                            {` або в іншому нормативно-правовому акті, який регулює проведення даного аукціону.`}
                                        </p>
                                    </>
                            }
                        />
                        {createInputValue(props, minCoast, bidValue, setBidValue)}
                        {createInputQuantity(props, bidQuantity, setBidQuantity)}
                        {
                            (procedure.isLLE(props.auction.sellingMethod) ||
                                procedure.isLLP(props.auction.sellingMethod) ||
                                procedure.isLLD(props.auction.sellingMethod) ||
                                procedure.isLRE(props.auction.sellingMethod) ||
                                procedure.isLSE(props.auction.sellingMethod) ||
                                procedure.isLSP(props.auction.sellingMethod)
                            ) ?
                                checkBox(props)
                                :
                                viewCheckBoxAll(props, 0)
                        }
                        {createDraftBid(props, tmpId, minCoast, bidValue, setSlim)}
                    </div>
                    : null
                }
            </div>
        )
    }
    return (
        <div>
            <div className={styles.blockBidLot}>
                <div className={styles.blockBidLot_title}>Ваша пропозиція</div>
                <InstructionBlock
                    title='Важлива інформація'
                    content={
                        <>
                            <p>Дані про організацію-користувача наразі не підтверджено необхідно пройти ідентифікацію
                                користувача</p>
                        </>
                    }
                />
            </div>
        </div>
    )
}

function viewCheckBoxAll(props, flag) {
    let tmpStylesPersonalData
    if (props.checkBox.waitPersonalData === false) {
        tmpStylesPersonalData = styles.pseudoCheckboxOn
    } else {
        tmpStylesPersonalData = styles.pseudoCheckboxOff
    }
    let tmpStylesZgoda
    if (props.checkBox.waitZgoda === false) {
        tmpStylesZgoda = styles.pseudoCheckboxOn
    } else {
        tmpStylesZgoda = styles.pseudoCheckboxOff
    }
    let tmpStylesZgodaReglament
    if (props.checkBox.waitZgodaReglament === false) {
        tmpStylesZgodaReglament = styles.pseudoCheckboxOn
    } else {
        tmpStylesZgodaReglament = styles.pseudoCheckboxOff
    }
    if (flag === 0) {
        return (
            <>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesPersonalData}
                        onClick={
                            () => {
                                if (props.checkBox.waitPersonalData === false) {
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_1')
                                } else {
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_1')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду на обробку моїх персональних даних</div>
                </div>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesZgoda}
                        onClick={
                            () => {
                                if (props.checkBox.waitZgoda === false) {
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_2')
                                } else {
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_2')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду з умовами проведення аукціону</div>
                </div>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesZgodaReglament}
                        onClick={
                            () => {
                                if (props.checkBox.waitZgodaReglament === false) {
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_3')
                                } else {
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_3')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду з умовами проведення аукціону/з Регламентом та тарифами ознайомлений</div>
                </div>
            </>
        )
    }
    return null
}

function checkBox(props) {
    let checkBoxStyle = (props.checkBox.agreement === false) ? styles.pseudoCheckboxOn : styles.pseudoCheckboxOff

    return (
        <>
            <div className={styles.chekbox}>
                <div
                    className={checkBoxStyle}
                    onClick={
                        () => {
                            if (props.checkBox.wait === false) {
                                props.setDataAddress(true, 'CHECKBOX_WAIT')
                            } else {
                                props.setDataAddress(false, 'CHECKBOX_WAIT')
                            }
                        }
                    }
                ></div>
                <div>Попередня згода на очікування</div>
            </div>
            <div className={styles.chekbox}>
                <div
                    className={checkBoxStyle}
                    onClick={
                        () => {
                            if (props.checkBox.agreement === false) {
                                props.setDataAddress(true, 'CHECKBOX_AGREEMENT')
                            } else {
                                props.setDataAddress(false, 'CHECKBOX_AGREEMENT')
                            }
                        }
                    }
                ></div>
                <div>Згода на обробку персональних даних</div>
            </div>
        </>
    )
}

function createInputValue(props, minCoast, bidValue, setBidValue) {
    let tmp = null;
    if (props.auction !== null) {
        if (!procedure.isDutch(props.auction.sellingMethod)) {
            tmp = <div>
                <div className={styles.bidLotCost}>
                    <TextFull
                        title='Початкова ціна продажу лоту:'
                        data={`${startCostAuction(minCoast)} ${getCurrency(props?.auction?.value?.currency)}.`}
                    />
                </div>
                {procedure.isREM(props.auction.sellingMethod) ?
                    <div
                        className={styles.bidLotCost_descr}>{`Ваша пропозиція має бути менше або рівна ${startCostAuction(minCoast)} євроцентів.`}</div>
                    : null}
                <div className={styles.bidLotCost_input}>
                    <input
                        className={styles.inputNumber}
                        type='number'
                        value={bidValue}
                        onChange={(e) => {
                            let value = parseFloat(e.target.value)
                            value = Math.round((value + Number.EPSILON) * 100) / 100
                            if (procedure.isREM(props.auction.sellingMethod)) {
                                value = value > props?.auction?.value?.amount ? props?.auction?.value?.amount : value
                                value = value < 0 ? 0.01 : value
                            } else {
                                //@todo
                            }
                            setBidValue(value)
                        }}
                    />
                    <div className={styles.bidLotCost_title}>Цінова пропозиція</div>
                </div>
                {procedure.isREM(props.auction.sellingMethod) ?
                    null
                    :
                    <div
                        className={styles.bidLotCost_descr}>{`Ваша пропозиція має бути не менш ніж ${startCostAuction(minCoast)} грн.`}</div>
                }
                <div className={styles.bidLotBtn}></div>
            </div>
        }
    }
    return tmp
}

function createInputQuantity(props, bidQuantity, setBidQuantity) {
    if (procedure.isREM(props.auction.sellingMethod)) {
        return (
            <div className={styles.bidLotCost_input}>
                <input
                    className={styles.inputNumber}
                    type='number'
                    value={bidQuantity}
                    onChange={(e) => {
                        const quantity = props?.auction?.items[0]?.quantity
                        let value = parseFloat(e.target.value)
                        value = Math.round((value + Number.EPSILON) * 100) / 100
                        if (isNaN(value)) {
                            setBidQuantity(null)
                            return;
                        }
                        if (value <= 0) return
                        if (value > quantity) value = quantity
                        setBidQuantity(value)
                    }}
                />
                <div className={styles.bidLotCost_title}>Розмір частки квоти в заяві (кВт)</div>
            </div>
        )
    }
    return null
}

//@todo refactor
function startCostAuction(data) {
    let tmp = null
    let amount = null
    if (data !== null) {
        amount = String(data);
        if (amount !== null) {
            if (amount.indexOf('.') !== -1) {
                amount = amount.split('.');
                tmp = String(amount[0]);
                amount = amount[1];
            } else {
                tmp = String(amount);
                amount = '00'
            }
            tmp = tmp.split("").reverse().join("");
            let tmp1 = [];
            for (let i = 0; i < tmp.length; i++) {
                if (((i + 1) % 3) === 0) {
                    if (i !== tmp.length - 1) {
                        tmp1[i] = tmp[i] + '\'';
                    } else {
                        tmp1[i] = tmp[i];
                    }
                } else {
                    tmp1[i] = tmp[i];
                }
            }
            tmp = tmp1.join('')
            tmp = tmp.split("").reverse().join("");
        }
    }
    return tmp + ',' + amount
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        checkBox: state.start.checkBox,
        userProfiles: state.start.userProfiles,
        bidSumm: state.start.bidSumm,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    auctionsBidsGetId,
    changeBidErrorPopUpStatus,
    changeBidQuantity,
    setUniversalError,
    changeLoader,
    setDataAddress,
    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBid)
