import React from 'react';

import {connect} from 'react-redux';

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    minNumberOfQualifiedBids,
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../redux/actions.js';

import {
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../redux/actions.js';

/////////////////////////////////////

import 'date-fns';

import SwitcherTypeWorkAuction from './createEditAndCloneAuction/windowAuction'

const WindowAuction = ( ) => {
    return (
        <>
            {/*<NewDiz />*/}
            <SwitcherTypeWorkAuction />
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    minNumberOfQualifiedBids,
    setUniversalError,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    setDataAddress,

    changeLoader,
    openBankWindows,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)