import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../elements/viewData/textFull'
import TextHalf from '../../elements/viewData/textHalf'
import {startCostAuction} from '../util';

const StringAmount = (props) => {
    if(props.obj === null) return null
    if(props.obj.hasOwnProperty(props.field) !== true) return null
    if(props.obj[props.field] === null) return null

    let tmp = startCostAuction(props.obj, props.field) + typeCurunsy(props.obj[props.field].currency) + taxStatusAuction(props);
    if(props.type === 'full'){
        return (
            <TextFull
                title={props.title}
                data={tmp}
            />
        )
    }
    return (
        <TextHalf
            title={props.title}
            data={tmp}
        />
    )
}

function typeCurunsy(data){
    switch(data){
        case 'UAH': return ' грн.'
        case 'USD': return ' usd.'
        case 'EUR': return ' eur.'
    }
    return null
}

function taxStatusAuction(props){
    if(props.obj[props.field].hasOwnProperty('valueAddedTaxIncluded') !== true) return ''
    if(props.obj[props.field].valueAddedTaxIncluded) return ', з ПДВ'
    return ', без ПДВ'
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StringAmount)