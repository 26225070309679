import React from 'react';
import styles from './subsoil.module.css';

import {connect} from 'react-redux';
import {
    getMyAuction,
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    changeLoader,
    changeCreateItemClassificationAddIdNadra,
} from '../../../../redux/actions.js';
import {auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    setPopUpAuctionMyNull,
} from '../../../../redux/actions/auctions/auctions.js';

import {popUpAddress} from '../../../../redux/actions/address/address.js';

import 'date-fns';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import ItemsList from './itemsList/itemsList.js';

import ReturnButton from '../../returnButton'

import ZuMap from '../../../map/zuMaps'
import {setDataAddress} from '../../../../redux/createAddress';

const WindowItems = ( props ) => {
    let tmpZuMap
    tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory+ ' ' + styles.groupElemCategoryTwo}>
        <div>
            <ZuMap
                latitude = {props.createItems.landRental.location.latitude}
                longitude = {props.createItems.landRental.location.longitude}
            />
        </div>
    </div>
    let tmpText;
    if(props.createItems.id === null){
        tmpText = 'Створення нового лоту';
    }else{
        tmpText = 'Редагування лоту';
    }
    let formItem = null;
    if(props.auctionsItemsSwitchWindow > 0){
        formItem = (
            <>
                <div className={styles.group}>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                id="outlined-select-currency-native"
                                select
                                label="Основний класифікатор"
                                value={props.createItems.classificationId}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationId(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть основний класифікатор"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Додатковий класифікатор"
                                value={props.createItems.additionalClassificationIds.subsoilUsageTypeAdditional}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationAddIdNadra(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберить додатковий класифікатор"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationAddIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Одиниці виміру"
                                value={props.createItems.unitCode}
                                onChange={(event)=>{
                                    props.changeCreateItemUnitCode(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть одиницю виміру"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationSubsoilUnitCode(props)}
                            </TextField>
                        </div>
                    </div>
                    {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Адреса"
                                value={props.createItems.addressesGetMy}
                                onChange={(event)=>{
                                    props.changeCreateItemAddress(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть адресу"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {addressArr(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.popUpAddress(true)
                                    }
                                }
                            >
                                Створити адресу
                            </Button>
                        </div>
                    </div>*/}

                    {tmpZuMap}
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    ()=>{
                                        setNewAddress(props)
                                    }
                                }
                            >
                                Додати обрані координати
                            </Button>
                        </div>
                    </div>
                    <div>Адреса</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Країна"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.countryName}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props.createItems.landRental.addressItem.region)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Область"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.region}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Населенний пункт"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.locality}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Вулиця, буд. №"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.streetAddress}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Поштовий індекс"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.postalCode}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                }}
                            />
                        </div>
                    </div>


                    {/*<div>Координати</div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.location.latitude)}
                                disabled
                                id="outlined-select-currency-native"
                                label="Широта"
                                value={props.createItems.landRental.location.latitude}
                                onChange={(event) => {
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.location.longitude)}
                                disabled
                                id="outlined-select-currency-native"
                                label="Довгота"
                                value={props.createItems.landRental.location.longitude}
                                onChange={(event) => {
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>*/}



                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Опис"
                                multiline
                                rows={10}
                                value={props.createItems.description}
                                variant="outlined"
                                onChange={(event)=>{
                                    props.changeCreateItemDescription(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість"
                                value={props.createItems.quantity}
                                onChange={(event) => {
                                    props.changeCreateItemQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    let formButton = null;
    if(props.auctionsItemsSwitchWindow === 1){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                createItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                                
                                props.changeCreateItemClassificationId('null')
                                //props.changeCreateItemGenerationType(event.target.value)
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }else if(props.auctionsItemsSwitchWindow === 2){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                saveItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemDescription('')
                                props.changeCreateItemAuctionId('')
                                props.changeAdditionalClassificationIds('')
                                props.changeCreateItemUnitValueCurrency('')
                                props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')

                                props.changeCreateItemClassificationId('null')
                                //props.changeCreateItemGenerationType(event.target.value)
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>
                        <Button
                            disabled={elementDisabledCreate(props)}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeAuctionsItemsSwitchWindow(1);
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=20'
                                    }
                                    let header = {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    props.auctionsGetClassificationsByGenerationType(data, header)
                                    props.changeCreateItemClassificationId('null')
                                    props.changeCreateItemUnitCode('null')
                                    props.changeCreateItemDescription('')
                                    props.changeCreateItemQuantity('')
                                }
                            }
                        >
                            Створити новий лот
                        </Button>
                    </div>
                    <ItemsList />
                </div>
                <div className={styles.flexBlockElem}>
                    <div className={styles.headers}>{tmpText}</div>
                    {formItem}
                    {formButton}
                </div>
            </div>
            <div className={styles.bottonBlock}>
                {/*<Button
                    className={styles.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.setPopUpAuctionMyNull()
                            props.changeCreateAuctionTab(0);
                            props.changeAuctionsSwitchWindow(0)
                        }
                    }
                >
                    Повернутися до списку аукціонів
                </Button>*/}
                <ReturnButton />
            </div>
        </div>
    )
}

function elementDisabled(props){
    let tmpStatus = null;
    if(
        props.createItems.classificationId === null ||
        props.createItems.classificationId === '' ||
        //=== null ||
        //=== '' || тут доп классификатор по земле
        props.createItems.unitCode === null ||
        props.createItems.unitCode === '' ||
        props.createItems.description=== null ||
        props.createItems.description === '' ||
        props.createItems.quantity === null ||
        props.createItems.quantity === ''
    ){
        tmpStatus = 'disabled'
    }
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationAddIdOption(props){
    if(props.classificationsNadraEnglish.classificationsByShemaSubsoilUsageType !== null){
        return props.classificationsNadraEnglish.classificationsByShemaSubsoilUsageType.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationIdOption(props){
    if(props.classificationsNadraEnglish.classificationsByTypeMain !== null){
        return props.classificationsNadraEnglish.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsNadraEnglish.unitCode !== null){
        return props.classificationsNadraEnglish.unitCode.map(
            (i, count) => {
                return <option key={count} value={i.code}>{i.name}</option>
            }
        )
    }
}

async function createItem(props){
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            /*const arrAddClass = [];
            if(props.createItems.additionalClassificationIds.generationType !== null){
                arrAddClass.push(Number(props.createItems.additionalClassificationIds.generationType))
            }*/
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassificationIds: [Number(props.createItems.additionalClassificationIds.subsoilUsageTypeAdditional)],
                    //addressId: Number(props.createItems.addressesGetMy),  //+
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    description: props.createItems.description,             //+
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    quantity: Number(props.createItems.quantity), //+                         //Колличество

                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemClassificationId('null')
                    //props.changeCreateItemGenerationType(event.target.value)
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
} 

async function saveItem(props){
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/item/${props.createItems.id}`;*/
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.id}`;
            /*const arrAddClass = [];
            if(props.createItems.additionalClassificationIds.generationType !== null){
                arrAddClass.push(Number(props.createItems.additionalClassificationIds.generationType))
            }*/
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    /*auctionId: props.createAuction.id,*/                      //+
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassificationIds: [Number(props.createItems.additionalClassificationIds.subsoilUsageTypeAdditional)],
                    //locationId: 0,                                                                    //New необязательное поле
                    //addressId: Number(props.createItems.addressesGetMy),  //+
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    //unitId: props.createItems.unitCode, //Новое поле
                    description: props.createItems.description,             //+
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    /*unitValue: {
                        currency: 'eurocent',                                 //+            //Валюта
                        amount: props.createItems.unitValue.amount,      //+                               //Цена
                    },*/
                    quantity: Number(props.createItems.quantity), //+                         //Колличество
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemClassificationId('null')
                    //props.changeCreateItemGenerationType(event.target.value)
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
}

function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(amount === null || amount === '' || amount === 'null'){
                return 'error'
            }
            return null
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    auctionsGetMyId,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    //auctionsGetClassificationsByType,
    
    auctionsGetClassificationsByGenerationType,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateItemGenerationType,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    popUpAddress,
    changeLoader,
    getMyAuction,
    setPopUpAuctionMyNull,
    changeCreateItemClassificationAddIdNadra,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)
