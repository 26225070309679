import React from 'react';
import styles from './logo.module.css';

import {connect} from 'react-redux';

//import ImgLogo from './ukraine.png';
import ImgLogo from './logo.png';

const Logo = (props) => {
    if(props.logo <= 1){
        return (
            <div className={styles.wrap} >
                <div className={styles.logo_wrap}>
                    <div>
                        <img src={ImgLogo} alt=""/>
                    </div>
                    {/*<div>
                        <div className={styles.titletext}>{LOGO_TITLE_TOP[props.langFlag]}</div>
                        <div className={styles.bootontext}>{LOGO_TITLE_BOTTOM[props.langFlag]}</div>
                    </div>*/}
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.wrap}>
                <a className={styles.logoA} href={window.location.origin}>
                    <div className={styles.logo_wrap}>
                        <div>
                            <img src={ImgLogo} alt=""/>
                        </div>
                       {/*<div>
                            <div className={styles.titletext}>{LOGO_TITLE_TOP[props.langFlag]}</div>
                            <div className={styles.bootontext}>{LOGO_TITLE_BOTTOM[props.langFlag]}</div>
                        </div>*/}
                    </div>
                </a>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        logo: state.start.logo,
    }
}

export default connect(mapStateToProps, null)(Logo)