import React from 'react';

import {connect} from 'react-redux';
import AucDocs from '../auctionEditNew/components/aucInfoView/aucDocs';
import {changeAuctionsSwitchWindow, auctionsGetMy} from '../../../redux/actions/auctions/auctions.js';
import { getMyAuctionId } from '../utils';
import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import {setDataAddress} from '../../../redux/createAddress';

const WindowDocument = (props) => {
    return (
        <AucDocs
            data={props}
            reload={()=> getMyAuctionId(props)}
        />
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        createDocuments: state.start.createDocuments
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowDocument)
