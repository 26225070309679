import React from 'react';
import style from './organizations.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';

import {
    changePopUpBankAccount,
    bankAccountGet,
    openBankWindows,
    setPopBankAccountNazvaBanku,
    setPopBankAccountOpus,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountEditId,
} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    addressesGetMy,
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../redux/actions/address/address.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

const ListAddress = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={() => {
                            props.setDataAddress(false, 'SET_POPUP_ORGANIZATIONS')
                        }
                    }
                    
                >
                    X
                </div>
                <div className={style.popup_main_doc}>
                    <h3>Робота з організаціями</h3>
                    <Button
                        className={style.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                            }
                        }
                    >
                        Створення організації
                    </Button>
                    <div className={style.bankAccountElement}>
                        <div className={style.pn}>П/н</div>
                        <div className={style.bankTitle}>Назва</div>
                    </div>
                    <div className={style.mainDocumentsBlock}>
                        {viewListBankAccounts(props)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function viewListBankAccounts(props){
    let tmp = null;
    if(props.addressNew !== null){
        if(props.addressNew.getAddress !== null){
            tmp = props.addressNew.getAddress
            .map(
                (i, count) => {
                    return (
                        <div className={style.bankAccountElement}>
                            <div className={style.pn}>{count + 1}</div>
                            {/*<div className={style.bankTitle}>{i.name}</div>*/}
                            <div className={style.bankTitle}>{i.contactPoint.name}</div>
                            <Button
                                className={style.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.setDataAddress(i.id, 'CREATE_ADDRESS_NEW_ID')
                                        //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                        props.setDataAddress(i.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                        props.setDataAddress(i.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                        props.setDataAddress(i.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                        props.setDataAddress(i.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                        props.setDataAddress(i.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                        props.setDataAddress(i.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(i.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(i.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(i.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                        if(i.address.addressIdentifier !== null){
                                            props.setDataAddress(i.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                            props.setDataAddress(i.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                            props.setDataAddress(i.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                            props.setDataAddress(i.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                        }
                                        props.setDataAddress(i.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                        props.setDataAddress(i.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                        let tmp = null
                                        if(i.address.region === 'Автономна Республіка Крим' || i.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                            tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                        }else if(i.address.region === 'Вінницька область' || i.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                            tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                        }else if(i.address.region === 'Волинська область' || i.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                            tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                        }else if(i.address.region === 'Дніпропетровська область' || i.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                            tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                        }else if(i.address.region === 'Донецька область' || i.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                            tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                        }else if(i.address.region === 'Житомирська область' || i.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                            tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                        }else if(i.address.region === 'Закарпатська область' || i.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                            tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                        }else if(i.address.region === 'Запорізька область' || i.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                            tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                        }else if(i.address.region === 'Івано-Франківська область' || i.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                            tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                        }else if(i.address.region === 'Київська область' || i.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                            tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                        }else if(i.address.region === 'Кіровоградська область' || i.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                            tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                        }else if(i.address.region === 'Луганська область' || i.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                            tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                        }else if(i.address.region === 'Львівська область' || i.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                            tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                        }else if(i.address.region === 'Миколаївська область' || i.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                            tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                        }else if(i.address.region === 'Одеська область' || i.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                            tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                        }else if(i.address.region === 'Полтавська область' || i.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                            tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                        }else if(i.address.region === 'Рівненська область' || i.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                            tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                        }else if(i.address.region === 'Сумська область' || i.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                            tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                        }else if(i.address.region === 'Тернопільська область' || i.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                            tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                        }else if(i.address.region === 'Харківська область' || i.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                            tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                        }else if(i.address.region === 'Херсонська область' || i.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                            tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                        }else if(i.address.region === 'Хмельницька область' || i.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                            tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                        }else if(i.address.region === 'Черкаська область' || i.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                            tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                        }else if(i.address.region === 'Чернівецька область' || i.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                            tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                        }else if(i.address.region === 'Чернігівська область' || i.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                            tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                        }else if(i.address.region === 'Київ' || i.address.region === "М.КИЇВ"){
                                            tmp = "8000000000+:+М.КИЇВ"
                                        }else if(i.address.region === 'Севастополь' || i.address.region === "М.СЕВАСТОПОЛЬ"){
                                            tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                        }
                                        /*let tmpRegionOption = null
                                        if(props.koatuu.region !== null){
                                            for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                                if(props.koatuu.region[tt].description === tmp){
                                                    tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                                }
                                            }
                                        }*/
                                        props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        createLine(props, tmp)
                                        props.setDataAddress(i.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                        props.setDataAddress(i.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                        props.setDataAddress(i.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                        props.setDataAddress(i.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                        props.setDataAddress(i.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                        props.setDataAddress(i.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                        props.setDataAddress(i.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                        props.setDataAddress(i.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                        props.setDataAddress(i.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                        props.setDataAddress(i.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                        if(i.currentContractTime !== null){
                                            props.setDataAddress(i.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                            props.setDataAddress(i.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                        }

                                        props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    }
                                }
                            >
                                Редагувати
                            </Button>
                            {/*<Button
                                className={style.buttonExitDocument}
                                variant="contained"
                                color="secondary"
                                onClick={
                                    () => {
                                        deleteAddress(props, i.id)
                                    }
                                }
                            >
                                Вилучити
                            </Button>*/}
                        </div>
                    )
                }
            )
        }
    }
    return tmp
}

function createLine(props, tmp){
    let tmpRegionOption = null
    if(props.koatuu.region !== null){
        for(let i = 0; i < props.koatuu.region.length; i++){
            if(props.koatuu.region[i].description === tmp){
                tmpRegionOption = `${props.koatuu.region[i].classificationId}+:+${props.koatuu.region[i].description}`
            }
        }
    }
    if(tmpRegionOption !== null){
        props.regionAddress(tmpRegionOption)
        let t = tmpRegionOption.substr(0, 2)
        props.setKoatuuGorod(null)
        props.localityAddress('null')
        getKoatuuGorod(props, t)
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createDocuments: state.start.createDocuments,
        addressGetId: state.start.addressGetId,
        popUpBankAccountSheme: state.start.popUpBankAccountSheme,
        koatuu: state.start.koatuu,
        addressNew: state.start.addressNew,
    }
}

const mapDispatchToProps = {
    openBankWindows,
    changePopUpBankAccount,
    bankAccountGet,
    setPopBankAccountNazvaBanku,
    setPopBankAccountOpus,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountEditId,

    addressesGetMy,
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAddress)