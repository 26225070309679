import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

const TextHalf = (props) => {
    return (
        <div className={styles.lotHalf}>
            <p className={styles.desc}>{props.title}</p>
            <p>{props.data}</p>
        </div>
    )
}

export default connect(null, null)(TextHalf)
