import React from 'react';

import {connect} from 'react-redux';

import {backendAPI} from '../../../../lib/backendAPI'
import {errorAPI} from '../../../../lib/errorAPI'
import { setDataAddress } from '../../../../../redux/createAddress';
import {setUniversalError, changeLoader} from '../../../../../redux/actions.js';

import PartialPayment from './partialPayment'

function PartialPaymentHOC(props){
    let requestBody = {
        dataProps: props,
        dataPropsI: props.i,
        dataFunction: patchIsPartiallyPaid,
    }

    if(
        props.auctionId.status === 'pending_payment'
        && props.i.status === 'pending_payment'
        && props.i.isPartiallyPaid === null
        //&& isDoc(props.i) === true  
    ){
        return <PartialPayment requestBody={requestBody} />
    }
    return null
}

async function patchIsPartiallyPaid(props, i, data){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}`
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify({
            isPartiallyPaid: data
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка збереження даних', message: JSON.stringify(response)})
        props.setUniversalError(tmp)
    }else{
        //if(data === true){
            //approveWinerAward(props, i)
            updateData(props, props.auctionId.id)
        //}
    }
}

/////////////////////////////////////

/////////////////////////////////////

async function updateData(props, aucId){
    let tmpData = {}
    //tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${aucId}/sync?` + Math.random()
    tmpData.body = {}
    tmpData.body.method = "GET"
    if(props.token !== null){
        tmpData.body.headers = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        tmpData.body.headers = {
            'Content-Type': 'application/json'
        }
    }
    let tmpResponse = await backendAPI(tmpData)
    let tmpResponse2 = await errorAPI(tmpResponse)
    if(tmpResponse2.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(tmpResponse2)})
        props.setUniversalError(tmp)
    }else{
        await props.setDataAddress(tmpResponse2, 'SET_FULL_AUCTION')
    }
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    setUniversalError,
    changeLoader,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(PartialPaymentHOC)