import React from 'react';

import TextHalf from '../textHalf'
import TextFullHeader from '../textFullHeader'
import { modifiDateString } from '../../../../../redux/modifiDate.js'

import { checkProp } from '../../../../../redux/check';


const RegistrationDetails = (props) => {

    function zuRegistrationDetails(data){
        if(data !== null){
            if(data.hasOwnProperty('registrationDetails') === true){
                if(props.data.registrationDetails !== null){
                    let tmp = null
                    if(checkProp(data.registrationDetails,'registrationDate') && data.registrationDetails.registrationDate !== null){
                        tmp = modifiDateString(data.registrationDetails.registrationDate, 1)
                    }
                    
                    return <>
                        <TextFullHeader
                            title="Інформація щодо реєстрації:"
                            data=''
                        />
                        {checkProp(data.registrationDetails,'registrationStatus') && data.registrationDetails.registrationStatus !== null
                            ? <TextHalf
                                title="Стан державної реєстрації об'єкту"
                                data={strRegistrationStatus(data.registrationDetails.registrationStatus)}
                            />
                            : null
                        }
                        {checkProp(data.registrationDetails,'registrationID') && data.registrationDetails.registrationID !== null
                            ? <TextHalf
                                title="Номер запису"
                                data={data.registrationDetails.registrationID}
                            />
                            : null
                        }
                        {tmp !== null
                            ? <TextHalf
                                title="Дата реєстрації"
                                data={tmp}
                            />
                            : null
                        }
                    </>
                }
            }
        }
        return null
    }
    
    function strRegistrationStatus(data){
        if(data === 'notRegistered'){
            return 'Не зареєстровано'
        }else if(data === 'oldRegistration'){
            return 'Зареєстровано до 1 січня 2013 р. відповідно до законодавства, що діяло на момент виникнення речового права на майно'
        }else if(data === 'properlyRegistered'){
            return 'Зареєстровано в Державному реєстрі речових прав на нерухоме майно'
        }else if(data === 'registrationIsNotRequired'){
            return 'Реєстрація не вимагається'
        }else if(data === 'unknown'){
            return 'Невідомо'
        }else if(data === 'registering'){
            return 'Об’єкт реєструється'
        }else if(data === 'complete'){
            return 'Об’єкт зареєстровано'
        }
        return data
    }
    
    
    return (
        zuRegistrationDetails(props.data)
    )
}

export default RegistrationDetails
