import React from 'react';
import styles from './warehouseComposition.module.css';


const TextHalf = ({ title, data, insideMoreInfo, ...props }) => {
    let descStyle = styles.insideInfo
    if (props.disabled) {descStyle = styles.insideInfoDisabled}

    return (
                <div className={styles.blockHalf}>
                    <div className={styles.description}>{title}</div>
                    <div className={descStyle}>{data}</div>
                    <div className={descStyle}>{insideMoreInfo}</div>
                </div>
    )
}

export default TextHalf
