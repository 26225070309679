import React from 'react';

import {connect} from 'react-redux';
import ViewBid from "../../../product/newBidLot/viewBid/viewBid";
import {getUserBidsListByAuction} from "../../../_api/bidApi";

import {saveArrBids} from "../../../../redux/allAuctionBids";

const ViewBidList = (props) => {
    if (props?.allUserBids?.arr?.length > 0) {
        let bidViewList = props?.allUserBids?.arr
            .map((bid) => {
                return <ViewBid elemBid={bid} key={bid.id}/>
            })
        return (
            <div>
                {bidViewList}
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        allUserBids: state.start.auctionsAllBids,
        token: state.start.token,
        auction: state.start.auctionFull,
    }
}
const mapDispatchToProps = {
    saveArrBids,
    getUserBidsListByAuction
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewBidList)