import React from 'react';
import style from './togetherAPI.module.css';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../redux/actions/auctions/auctions.js';
import {setUniversalError} from '../../../redux/actions.js';

const TogetherAPI = (props) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Хочте створити чернетку?</div>
                <div className={style.button_wrap}>
                    <div
                        className={style.popup_message_button}
                        onClick={
                            ()=>{
                                saveBidDocument(props)
                                props.setPopUpBidTogetherAPI(null)
                            }
                        }
                    >Так</div>
                    <div
                        className={style.popup_message_button}
                        onClick={
                            ()=>{props.setPopUpBidTogetherAPI(null)}
                        }
                    >Ні</div>
                </div>
            </div>
        </div>
    )
}

async function saveBidDocument(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.togetherAPIelem.popup.id}/bid`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            classificationId: props.togetherAPIelem.popup.classificationId,
            unitCode: props.togetherAPIelem.popup.unitCode,
            documentIds: [],
            qualified: true,
            value: {
                currency: 'UAH',
                amount: props.togetherAPIelem.popup.value.amount
            },
            quantity: props.togetherAPIelem.popup.quantity
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка створення чернетки ставки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        togetherAPIelem: state.start.togetherAPIelem,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)