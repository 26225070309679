import React from 'react';
import styles from './find.module.css';

import SecondMenu from './secondMenu/secondMenu.js';
import HelpfulMenu from './helpfulMenu/helpfulMenu.js';
import FilterBlock from './filterBlock/filterBlock.js';

import { Switch, Route } from 'react-router-dom';

export default function Find (){
    return (
        <div className = { styles.find } >
            <div className = { styles.find_wrap } >
                <Switch>
                    <Route exact path='/'
                        render={ () => <SecondMenu /> }
                    />
                    <Route path='/search/'
                        render={ () => <SecondMenu /> }
                    />
                    <Route path='/tenders'
                        render={ () => <SecondMenu /> }
                    />
                    <Route exact path='/helpful/:number'
                        render={ () => <HelpfulMenu /> }
                    />
                </Switch>
            </div>
            <div className = { styles.find_wrap } >
                <Switch>
                    <Route exact path='/'
                        render={ () => <FilterBlock /> }
                    />
                    <Route path='/search/'
                        render={ () => <FilterBlock /> }
                    />
                </Switch>
            </div>
        </div>
    )
}
