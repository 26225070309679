//AUCTIONS

//GET, POST, PATCH, DELETE

//GET, PATCH, DELETE
export const toAuctionDraftId = ( auctionDraftId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${auctionDraftId}`
//GET, PATCH, DELETE

//GET, PATCH, DELETE

//POST

//GET
export const toAuctionDraftCopy = ( auctionDraftId ) => `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${auctionDraftId}/copy`

//AUCTIONS ITEMS

//AUCTIONS DOCUMENTS