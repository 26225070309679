import React from 'react';
import style from './createBankAccountComplite.module.css';

 import Button from '../../../../elements/buttons/button';


import {connect} from 'react-redux';

import {setPopBankAccountComplite, bankAccountGet} from '../../../../../redux/actions/bankAccount/bankAccount.js'

const AuctionError = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div className={style.content}>Акаунт банку створено</div>
                <Button
                    color='blue'
                    onClick={
                        () => {
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: props.langFlag,
                                params: '?limit=100'
                            }
                            let header = {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                            props.bankAccountGet(data, header)
                            props.setPopBankAccountComplite(false)
                        }
                    }
                >
                    Закрити
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
    }
}

const mapDispatchToProps = {
    setPopBankAccountComplite,
    bankAccountGet,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionError)