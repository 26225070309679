import React,{useState} from 'react'
import styles from './aucLotItem.module.css'

import { useSelector } from 'react-redux';

import { checkProp, isMongo } from '../../../../../../redux/check'

import TextFull from '../../../../../elements/viewData/textFull'
import TextHalf from '../../../../../elements/viewData/textHalf'
import PopAsk from "../../../../../_popups/popAsk/popAsk";


const AucLotItem = ({ data, edit, del}) => {

    const myAuc = useSelector( state => state.start.auctionsMy)
    const [popDelete, setPopDelete] = useState(false)

    function getContent(a) {
        if(checkProp(a, 'vehicleProps') ){
                return <>
                {
                    checkProp(a.vehicleProps, 'brand') ? 
                    <TextHalf
                        title={'Марка траспортного засобу'}
                        data={a.vehicleProps.brand} 
                    /> : null
                }
                {checkProp(a.vehicleProps, 'model')? 
                    <TextHalf
                        title={'Модель траспортного засобу'}
                        data={a.vehicleProps.model} 
                    /> : null
                }
                </>
        }
        return null
    }

    const editButton =( myAuc )=> {
        Date.now();
        if (myAuc.status === null
            || myAuc.status === 'null'
            || myAuc.status === 'active_tendering'
            || myAuc.status === "active_rectification"
        ) {
            return <div className={styles.editBtn}
                        onClick={edit}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.editIcon} fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59598L16.9757 4.48123L14.5187 2.02416L13.4039 3.13892L15.861 5.59598ZM14.4542 7.00276L11.9971 4.54569L2.80565 13.7372L2.24014 16.7597L5.26272 16.1942L14.4542 7.00276ZM18.4419 3.13387C19.186 3.87799 19.186 5.08446 18.4419 5.82859L6.45174 17.8188C6.30989 17.9606 6.12847 18.0563 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.5091 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557973C13.9154 -0.186154 15.1219 -0.186154 15.866 0.557973L18.4419 3.13387ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"/>
                        </svg>
                    </div>
        }
        return null
    }



    let tmpDescription = ''
    if(data?.classification?.description !== null) tmpDescription = isMongo(data?.classification?.description)
    return (
        <div className={styles.wrapBlock}>
            <div className={styles.wrapBlockTitle}>
                {data.classification !== null 
                    ? `${data?.classification?.scheme} ${data?.classification?.id} ${tmpDescription}`
                    : "Відсутній класифікатор"}
                <div className={styles.blockBtn}>
                    {editButton(myAuc)}
                </div>
            </div>
            <div className={styles.wrapContent}>
                {checkProp(data, 'id') ? 
                    <TextHalf
                        title={'ID лота'}
                        data={data.id} 
                    /> : null
                }
                {getContent(data)}
                {checkProp(data, 'description')
                    ? <TextFull
                        title={'Опис лоту'}
                        data={isMongo(data.description)} 
                    />
                    : null
                }
                {checkProp(data, 'address')
                    ? <TextFull
                        title={'Розташування'}
                        data={`${checkProp(data.address, 'countryName') ? isMongo(data.address.countryName) + ', ' : ''}
                        ${checkProp(data.address, 'region') ? isMongo(data.address.region) + ', ' : ''}
                        ${checkProp(data.address, 'locality') ? isMongo(data.address.locality) : ''}
                        ${checkProp(data.address, 'streetAddress') ? ', ' + isMongo(data.address.streetAddress) : ''}
                        ${checkProp(data.location, 'latitude') && checkProp(data.location, 'longitude') ? ' - ('  + data.location.latitude + ', ' + data.location.longitude +  ')' : ''}
                        `} 
                    />
                    : null
                }
            </div>
            {
                popDelete ? 
                <PopAsk
                    title={`Ви дійсно хочете видалити лот ${isMongo(data?.classification?.description) ?? '' }?`}
                    actionYes={del}
                    actionNo={()=>{setPopDelete(false)}}
                />
        : null
            }
        </div>
    )
}

export default AucLotItem