import React from 'react';
import styles from './windowContract.module.css';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';
import {changeCreateAuctionTab} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';

import ListDocuments from './itemsList/itemsList.js';
import ReturnButton from '../returnButton'

const WindowAuction = ( props ) => {
    return (
        <div className={styles.documentWrap}>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    <ListDocuments />
                </div>
            </div>
            <ReturnButton />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,
    changeCreateAuctionTab,
    setPopUpAuctionMyNull,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)
