import React from 'react';
import styles from './textArea.module.css';
import InputInfo from './inputInfoBlock';

const TextArea = ({value, label, placeholder, onChange, info, ...props}) => {

    return (
        <div className={styles.inputWrap}>
            <div className={styles.inPutInfo}>
                    <textarea className={!!props.disabled ? styles.disabled : styles.inPutTextArea}
                    // id={id}
                    disabled={!!props.disabled} 
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    />
                    <div className={styles.label}>
                        {props.required ? label + ' *': label}
                    </div>
                {!!props.info ? <InputInfo data={info} /> : null}
            </div>
            {props.descr ? <div className={styles.descript}>{props.descr}</div> : null}
        </div >
    )
}

export default TextArea