import React from 'react';
import styles from './windowAnswer.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../redux/actions/questions/questions.js';
import {setPopUpAnswerAuction} from '../../../redux/actions/auctions/auctions.js';

import ButtonAnswer from './buttonAnswer/buttonAnswer.js';

const WindowAnswer = ( props ) => {
    let tmp;
    /*if(props.auctionsMy !== null){
        tmp = props.auctionsMy.questions.map(
            (i, count) => {
                let tmpAnswer = null;
                if(i.answer !== null){
                    tmpAnswer = <div className={styles.answerWrap_line}><span>Відповідь:</span> {i.answer}</div>
                }else{
                    tmpAnswer = <ButtonAnswer
                        itemAnswer={i.id}
                    />
                }
                return (
                    <div className={styles.answerWrap}>
                        <div className={styles.answerWrap_line}><span>Заголовок:</span> {i.title}</div>
                        <div className={styles.answerWrap_line}><span>Текст:</span> {i.description}</div>
                        {tmpAnswer}
                    </div>
                )
            }
        )
    }*/
    //if(props.auctionsGetMy !== null && props.auctionsMy.id !== null){
    //    if(props.auctionsGetMy.length > 0){
    //        tmp = props.auctionsGetMy
    //        .filter(
    //            (i) => {
    //                return i.id === props.auctionsMy.id
    //            }
    //        )
    
            //.map(
                //(i) => {
                    //return i.questions.map(
                    tmp = props.auctionsMy.questions.map(
                        (r) => {
                            let tmpAnswer;
                            if(r.answer !== null){
                                tmpAnswer = <div className={styles.answerWrap_line}><span>Відповідь:</span> {r.answer}</div>
                            }else{
                                tmpAnswer = <ButtonAnswer
                                    itemAnswer={r.id}
                                />
                            }
                            return (
                                <div className={styles.answerWrap}>
                                    <div className={styles.answerWrap_line}><span>Заголовок:</span> {r.title}</div>
                                    <div className={styles.answerWrap_line}><span>Текст:</span> {r.description}</div>
                                    {tmpAnswer}
                                </div>
                            )
                        }
                    )
                //}
            //)
        //}
    //}
    if(tmp === null){
        tmp = 'Наразі немає жодного запитання від учасників'
    }else if(tmp.length === 0){
        tmp = 'Наразі немає жодного запитання від учасників'
    }
    return (
        <>
            <div className={styles.answerBlock}>
                {tmp}
            </div>
            {/*chatForm(props)*/}
        </>
    )
}

/*function chatForm(props, id){
    return (
        <div className={styles.terminu}>
            <div className={styles.documentElem}>
                <div className={styles.bidLotCost}>
                    <div className={styles.bidLotCost_title}>Відповідь</div>
                    <div className={styles.bidLotCost_input}>
                        <textarea 
                            className={styles.inputTextArea}
                            value={props.questionCreate.description}
                            onChange={(e) => {
                                props.answerDiscription(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className={styles.bidLotCost}>
                    <div className={styles.answerButton}
                        onClick = {
                            () => {
                                createAnswer(props, id)
                            }
                        }
                    >
                        Надіслати відповідь
                    </div>
                </div>
            </div>
        </div>
    )
}*/

/*async function  createAnswer(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/question/${id}/answer`;
    const response1 = await fetch(endPoint, {
        method: "patch",
        body: JSON.stringify({
            answer: props.questionCreate.description
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        props.answerDiscription('')
    }
}*/

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        auctionsGetMy: state.start.auctionsGetMy,
        questionCreate: state.start.questionCreate,
    }
}

const mapDispatchToProps = {
    answerTitle,
    answerDiscription,
    setPopUpAnswerAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAnswer)
