import React from 'react';

import { connect } from 'react-redux';

import TextHalf from '../../elements/viewData/textHalf'
import TextFull from '../../elements/viewData/textFull'
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';

import {modifiDateString} from '../../../redux/modifiDate'

const Arrests = (props) => {

    if(props.auctionId !== null){
        if(
            props.auctionId.sellingMethod === "landArrested-english"
            || props.auctionId.sellingMethod === "landArrested-english-ultra-fast"
            || props.auctionId.sellingMethod === "landArrested-english-fast"
            || props.auctionId.sellingMethod === "landArrested-english-fast-manual"
            || props.auctionId.sellingMethod === "landArrested-english-fast-auction-manual-qualification"
            || props.auctionId.sellingMethod === "landArrested-english-fast-auction-prod"
            || props.auctionId.sellingMethod === "landArrested-english-initial-auction"
            || props.auctionId.sellingMethod === "landArrested-english-initial-auction-manual"
            || props.auctionId.sellingMethod === "landArrested-english-initial-qualification"
            || props.auctionId.sellingMethod === "landArrested-english-initial-qualification-prod"
            || props.auctionId.sellingMethod === "landArrested-withoutAuction"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-ultra-fast"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-manual"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-manual-qualification"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-fast-auction-prod"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-auction-manual"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification"
            || props.auctionId.sellingMethod === "landArrested-priorityEnglish-initial-qualification-prod"
        ){
            return (
                <SlimmerBlock
                    title={`Інформація про арешти`}
                    content={viewBanksAccounts(props)}
                />
            )
        }
    }
    return null
}

function viewBanksAccounts(props){
    if(props.auctionId.hasOwnProperty('arrests') === true){
        return props.auctionId.arrests.map(
            (e) => {
                return (
                    <div  style={{display: "flex", flexWrap: "wrap", border: '1px solid #c6c6c6', borderRadius: "6px", padding: "10px 18px", marginBottom: "15px"}}>
                        <h4>Інформація про накладені виконавчі провадження накладені на лот</h4>
                        <TextHalf
                            title='Номер виконавчого провадження'
                            data={e.statementID}
                        />
                        <TextHalf
                            title='Дата виконавчого провадження'
                            data={e.restrictionDate ? modifiDateString(e.restrictionDate, 1) : ''}
                        />
                        <TextFull
                            title='Орган що видав'
                            data={e.restrictionOrganization}
                        />
                        <TextHalf
                            title='Арешт може бути знятий після продажу виконавцем'
                            data={e.isRemovable === true ? 'Так' : 'Ні'}
                        />
                    </div>
                )
            }
        )
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(Arrests)
