import React from 'react';
import styles from './createAuction.module.css';

import {connect} from 'react-redux';

////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../redux/actions/auctions/auctions.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';

import TabMenu from './tabMenu/tabMenu.js';
import WindowAuction from './windowAuction/windowAuction.js';
import WindowDocument from './windowDocument/windowDocument.js';
import WindowAwards from './windowAwards/windowAwards.js';
import WindowContract from './windowContract/windowContract.js';
import WindowAnswer from './windowAnswer/windowAnswer';

import {getAPI} from '../../redux/actions/crud.js';

import {setLogo} from '../../redux/actions/support.js';
import { isLLE, isLLD, isLLP, isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP } from '../../redux/procedure';

import {
    setPopUpAuctionMyNull,
} from '../../redux/actions/auctions/auctions.js';

import WindowItems from "../_CRUD/item/windowItems";

const Product = ( props ) => {
    if(props.token !== null && props.userProfiles !== null){
        if(window.location.href.indexOf('/create_auctions/new') !== -1){
            if(props.auctionsSwitchWindow !== 2){
                createButton(props)
            }
        }
        return (
            <div className={styles.auctions__wrap}>
                {switchViewAuctionsMy(props)}
            </div>
        )
    }
    return null
}

async function createButton(props){
    props.changeAuctionsSwitchWindow(2)
    props.getMyAuction(props.auctionsMyNew)
    props.changeCreateAuctionTenderAttempts(1)
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
}

function switchViewAuctionsMy(props){
    return windowCreateEditMyAuction(props, false)
}

function windowCreateEditMyAuction(props, type){
    let tmpTab = [];
    if(type === true){
        tmpTab[0] = <TabMenu count={0} state={true}/>
        tmpTab[1] = <TabMenu count={2} state={true}/>
    }else{
        if(props.auctionsSwitchWindow === 1){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-english") ||
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-dutch")
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={true}/>
            }
        }else if(props.auctionsSwitchWindow === 2){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-english") ||
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-dutch")
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={false}/>
            }
        }
    }
    if(
        props.auctionsMySingl !== null && (
            isLRE(props.auctionsMySingl.sellingMethod) || isLSE(props.auctionsMySingl.sellingMethod) || isLSP(props.auctionsMySingl.sellingMethod) ||
            isLLE(props.auctionsMySingl.sellingMethod) || isLLD(props.auctionsMySingl.sellingMethod) || isLLP(props.auctionsMySingl.sellingMethod) ||
            isBSE(props.auctionsMySingl.sellingMethod) || isBSD(props.auctionsMySingl.sellingMethod) ||
            isALE(props.auctionsMySingl.sellingMethod) ||
            isCSE(props.auctionsMySingl.sellingMethod) || isCSD(props.auctionsMySingl.sellingMethod) ||
            isBRE(props.auctionsMySingl.sellingMethod) || isBRD(props.auctionsMySingl.sellingMethod)
        )
    ){
        return (viewWindowChoiseTab(props))
    }else{
        return (
            <>
                <div className={styles.tab__wrap}>
                    {tmpTab}
                </div>
                {viewWindowChoiseTab(props)}
            </>
        )
    }
}

function viewWindowChoiseTab(props){
    let tmp;
    switch(props.createAuctionTab){
        case 0:
            tmp = <WindowAuction />
            break;
        case 1:
            tmp = <WindowItems />
            break;
        case 2:
            tmp = <WindowDocument />
            break;
        case 3:
            tmp = <WindowAnswer />
            //tmp = ''
            break;
        case 4:
            tmp = <WindowAwards />
            //tmp = ''
            break;
        case 5:
            tmp = <WindowContract />
            //tmp = ''
            break
        default:
            tmp = ''
            break;
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,
	auctionsBidsGetMy,
	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
