import React from 'react';

import {connect} from 'react-redux';

import PopDownBlock from '../../elements/viewData/popdownBlock/popDownBlock';
import HistoryDocItem from './historyDocItem';
import Spinner from '../../popUp/loader/spinner';
import { checkProp } from '../../../redux/check';

const DocumentHistory = (props) => {
    let tmp = null
    if (checkProp(props, 'data') && Array.isArray(props.data)) {
        tmp = props.data.map(
            (item, count) => {
                return <HistoryDocItem data={item} key={count} docType={props.docType} />
            }
        )
    } else if (checkProp(props, 'data') && checkProp(props.data, 'message')) {
        tmp = <div style={{color: 'red', fontSize: '14px'}}>Помилка історії: {props.data.message}</div>
    }

    while (props.data === null && tmp === null) {
        return <PopDownBlock open={true}
            title={`Історія змін документу завантажується`}
            content={<Spinner />} />
    }
    
    // if (props.data !== null && tmp !== null) {
        return <PopDownBlock
            open={true}
            title={`Історія змін документу (${tmp !== null ? (tmp.length === undefined ? '...' : tmp.length) : '0'})`}
            content={tmp} />
    // }
    // return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}

export default connect(mapStateToProps, null)(DocumentHistory)
