import React from 'react';
import styles from './itemsList.module.css';

import {connect} from 'react-redux';

import 'date-fns';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardApprowePopUpStatus, changeAwardView} from '../../../../redux/actions/awards/awards.js';

const ItemsList = ( props ) => {
    let tmp = null;
    if(props.auctionAwards !== null){
        tmp = props.auctionAwards.map(
            (i, count) => {
                let statusAward;
                let tmpInfo;
                if(i.bid !== null){
                    tmpInfo = <div className={styles.enabledButton}
                        onClick={
                            () => {
                                props.changeAwardView(i)
                            }
                        }
                    >
                        Інформація
                    </div>
                }else{
                    tmpInfo = <div className={styles.enabledButton + ' ' + styles.elementOff}>Інформація</div>
                }
                if(i.status !== 'unsuccessful' && i.status !== 'cancelled'){
                    if(props.auctionsMy.createdByCurrentUser !== true){
                        let tmpSellingMethod = props.auctionsMy.sellingMethod.indexOf('railwayCargo-english')
                        if( tmpSellingMethod === -1 && i.status !== 'active' && props.auctionsMy.createdByCurrentUser !== true){
                            if(props.auctionsMy.status !== 'unsuccessful' && props.auctionsMy.status !== 'cancelled' && props.auctionsMy.status !== 'complete'){
                                statusAward = (
                                    <div className={styles.auctionsLineElem}>
                                        {tmpInfo}
                                        {buttonReject(props, i, count)}
                                    </div>
                                )
                            }else{
                                statusAward = (
                                    <div className={styles.auctionsLineElem}>
                                        {tmpInfo}
                                        <div className={styles.disabledButton}>
                                            Документи до аварду
                                        </div>
                                    </div>
                                )
                            }
                        }else{
                            statusAward = (
                                <div className={styles.auctionsLineElem}>
                                    {tmpInfo}
                                    <div className={styles.disabledButton}>
                                        Документи до аварду
                                    </div>
                                </div>
                            )
                        }
                    }else{
                        statusAward = (
                            <div className={styles.auctionsLineElem}>
                                {tmpInfo}
                                {buttonReject(props, i, count)}
                            </div>
                        )
                    }
                }else{
                    statusAward = (
                        <div className={styles.auctionsLineElem}>
                            {tmpInfo}
                            <div className={styles.disabledButton}>
                                Документи до аварду
                            </div>
                        </div>
                    )
                }
                return (
                    //<div key={i.id} className={styles.auctionsLine}>
                    <>
                        <div className={styles.lineWrapAwards}>
                            <div className={styles.lineWrapAwards_title}>Ідентифікатор заяви на участь учасника аукціону:</div>
                            <div className={styles.lineWrapAwards_content}>{i.id}</div>
                        </div>
                        <div className={styles.lineWrapAwards}>
                            <div className={styles.lineWrapAwards_title}>Статус кваліфікації учасника аукціону:</div>
                            <div className={styles.lineWrapAwards_content}>{viewTextStatusBidUkr(i.status)}</div>
                        </div>
                        {/*<div className={styles.auctionsLineElem}>
                            <div className={styles.documentsId}>Ідентифікатор заяви на участь учасника аукціону: {i.id}</div>
                            <div>Статус кваліфікації учасника аукціону: {viewTextStatusBidUkr(i.status)}</div>
                        </div>*/}
                        {statusAward}
                    </>
                )
            }
        )
    }
    return tmp
}

function viewTextStatusBidUkr(status){
    switch(status){
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            return 'Переможець. Очікується договір'
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
    }
    return status
}

function buttonReject(props, i, count){
    if(props.userProfiles.canCreateAuction === true || i.bid.createdByCurrentUser === true){
        if(i.status !== 'pending_waiting'){
            if(i.bid !== null){
                return (
                    <div className={styles.enabledButton}
                        onClick={
                            () => {
                                props.changeAwardRejectPopUpStatus({aucId: i.auction.id, awardId: i.id, bidId: i.bid, status: i.status, awards: count, sellingMethod: i.auction.sellingMethod})
                            }
                        }
                    >
                        Документи до аварду
                    </div>
                )
            }else{
                return <div className={styles.enabledButton + ' ' + styles.elementOff}>Документи до аварду</div>
            }
        }else if(i.status === 'pending_waiting'){
            if(i.bid !== null){
                if(i.bid.createdByCurrentUser === true){
                    return (
                        <div className={styles.enabledButton}
                            onClick={
                                () => {
                                    props.changeAwardRejectPopUpStatus({aucId: i.auction.id, awardId: i.id, status: i.status, awards: count, sellingMethod: i.auction.sellingMethod})
                                }
                            }
                        >
                            Документи до аварду
                        </div>
                    )
                }else{
                    return <div className={styles.enabledButton + ' ' + styles.elementOff}>Документи до аварду</div>
                }
            }else{
                return <div className={styles.enabledButton + ' ' + styles.elementOff}>Документи до аварду</div>
            }
        }else{
            return <div className={styles.enabledButton + ' ' + styles.elementOff}>Документи до аварду</div>
        }
    }else{
        return <div className={styles.enabledButton + ' ' + styles.elementOff}>Документи до аварду</div>
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardApprowePopUpStatus,
    changeAwardView,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList)
