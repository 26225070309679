import React from 'react';
import styles from './basicSell.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchCount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {changeMyCurentPage} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const Nadra = ( {langFlag, aucyionsPath, search, token, auctionsSearch, setAuctionCategory, changeCurentPage, changeMyCurentPage, setDataAddress, clearSearch} ) => {
    let active = null;
    if(search.category === 'basicSell-english'){
        active = styles.active;
    }
    return (
            <div
                className={styles.aukpoisk_second_elem}
            >
                <Link
                    onClick={
                        ()=>{
                            changeMyCurentPage(0)
                            setAuctionCategory('basicSell-all');
                            setDataAddress('jointPropertyComplex', 'SEARCH_ITEM_TYPE')
                            changeCurentPage(0);
                            let dataSearch = {}
                            dataSearch.order = search.order
                            dataSearch.region = search.region
                            dataSearch.category = 'basicSell-all'
                            dataSearch.priceCurrency = search.priceCurrency
                            dataSearch.priceFrom = search.priceFrom
                            dataSearch.priceTo = search.priceTo
                            dataSearch.measures = search.measures
                            dataSearch.measuresFrom = search.measuresFrom
                            dataSearch.measuresTo = search.measuresTo
                            dataSearch.itemType = 'jointPropertyComplex'
                            if(search.status === null || search.status === ''){
                                dataSearch.status = null
                            }else{
                                dataSearch.status = search.status
                            }
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: langFlag,
                                params: '?offset=0'
                            }
                            if(token !== null){
                                let header = {
                                    'Authorization': token.access_token,
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }else{
                                let header = {
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }
                            clearSearch()
                        }
                    }
                    to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-all')}`}
                >
                    <div className={styles.base + ' ' + active}>
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 480 480" xmlSpace="preserve">
<g>
	<g>
		<path d="M472,96h-65.272C398.896,41.616,351.696,0,296,0c-55.84,0-103.08,41.784-110.672,96H120c-4.416,0-8,3.584-8,8v104H8
			c-4.416,0-8,3.584-8,8v96v160c0,4.416,3.584,8,8,8h112h352c4.416,0,8-3.584,8-8V216V104C480,99.584,476.416,96,472,96z M112,464
			H16V320h96V464z M112,304H16v-80h96V304z M396.016,162.28c0.408-0.8,0.824-1.592,1.216-2.408c1.032-2.168,1.984-4.368,2.864-6.592
			c0.128-0.328,0.288-0.648,0.416-0.984c1-2.592,1.896-5.216,2.696-7.88c0.04-0.144,0.104-0.288,0.144-0.432H408
			c8.824,0,16,7.176,16,16c0,4.264-1.664,8.28-4.696,11.304C416.272,174.312,412.264,176,408,176h-20.12
			c0.368-0.52,0.672-1.08,1.032-1.608c1.008-1.488,1.976-3,2.912-4.536c0.528-0.872,1.048-1.736,1.552-2.624
			C394.304,165.608,395.176,163.952,396.016,162.28z M200.328,105.136c0.024-0.184,0.024-0.352,0.04-0.536
			C204.08,54.912,246.08,16,296,16c49.888,0,91.928,38.92,95.712,88.728c0.224,2.4,0.288,4.8,0.288,7.272
			c0,7.536-0.864,14.992-2.592,22.168c-0.944,4.008-2.168,7.936-3.624,11.776c-0.496,1.304-1.152,2.528-1.704,3.808
			c-1.072,2.496-2.136,4.992-3.416,7.384c-0.776,1.448-1.704,2.8-2.552,4.208c-1.28,2.12-2.544,4.248-3.992,6.272
			c-1.008,1.416-2.152,2.736-3.24,4.096c-1.168,1.456-2.36,2.896-3.616,4.288H224.736c-1.256-1.392-2.44-2.824-3.608-4.28
			c-1.088-1.36-2.24-2.688-3.248-4.104c-1.432-2-2.688-4.112-3.96-6.208c-0.864-1.424-1.808-2.808-2.592-4.28
			c-1.248-2.328-2.272-4.76-3.32-7.176c-0.584-1.352-1.28-2.656-1.8-4.032c-1.376-3.64-2.52-7.368-3.432-11.16
			c-0.048-0.208-0.136-0.408-0.192-0.624c-1.72-7.144-2.584-14.6-2.584-22.136C200,109.664,200.064,107.4,200.328,105.136z
			 M188.656,144c0.048,0.152,0.112,0.304,0.152,0.456c0.8,2.64,1.68,5.248,2.672,7.824c0.16,0.416,0.352,0.808,0.512,1.224
			c0.856,2.144,1.776,4.272,2.768,6.36c0.4,0.84,0.832,1.664,1.256,2.496c0.832,1.64,1.688,3.264,2.6,4.864
			c0.512,0.896,1.04,1.784,1.576,2.664c0.928,1.52,1.888,3.024,2.888,4.504c0.36,0.536,0.672,1.096,1.04,1.624H184
			c-8.824,0-16-7.176-16-16c0-4.264,1.664-8.28,4.696-11.304c3.032-3.024,7.04-4.712,11.304-4.712H188.656z M335.464,464h-79.48
			l-0.416-80h79.896V464z M464,464H351.456v-80H368v-16H224v16h15.56l0.416,80H128V312v-88h336V464z M464,208H128v-96h56
			c0,3.936,0.272,7.832,0.68,11.712c0.08,0.784,0.152,1.568,0.248,2.344c0.08,0.648,0.112,1.304,0.208,1.944H184
			c-8.536,0-16.568,3.336-22.616,9.384C155.336,143.432,152,151.464,152,160c0,17.648,14.352,32,32,32h37.04h149.92H408
			c8.536,0,16.568-3.336,22.616-9.384C436.664,176.568,440,168.536,440,160c0-17.648-14.352-32-32-32h-1.136
			c0.096-0.648,0.128-1.304,0.208-1.952c0.096-0.776,0.168-1.552,0.248-2.328c0.408-3.88,0.68-7.784,0.68-11.72h56V208z"/>
	</g>
</g>
<g>
	<g>
		<rect x="32" y="344" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="32" y="384" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="32" y="424" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="72" y="344" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="72" y="384" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="72" y="424" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="144" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="144" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="144" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="184" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="184" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="184" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="264" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="264" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="264" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="304" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="304" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="304" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="384" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="384" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="384" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="424" y="248" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="424" y="288" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<rect x="424" y="328" width="24" height="16"/>
	</g>
</g>
<g>
	<g>
		<path d="M304,104h-16c-4.408,0-8-3.592-8-8s3.592-8,8-8h16c4.408,0,8,3.592,8,8h16c0-13.232-10.768-24-24-24V56h-16v16
			c-13.232,0-24,10.768-24,24s10.768,24,24,24h16c4.408,0,8,3.592,8,8s-3.592,8-8,8h-16c-4.408,0-8-3.592-8-8h-16
			c0,13.232,10.768,24,24,24v16h16v-16c13.232,0,24-10.768,24-24S317.232,104,304,104z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
                    </div>
                    <div className={styles.aukpoisk_second_elem_text}>Базовий продаж. Об'єкт ЄМК</div>
                </Link>
                <div className={styles.aukpoisk_second_elem_1}>
                    <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-english')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('basicSell-english');
                                    setDataAddress('jointPropertyComplex', 'SEARCH_ITEM_TYPE')
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'basicSell-english'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    dataSearch.itemType = 'jointPropertyComplex'
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Базовий продаж. Англієць
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-dutch')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('basicSell-dutch');
                                    setDataAddress('jointPropertyComplex', 'SEARCH_ITEM_TYPE')
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'basicSell-dutch'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    dataSearch.itemType = 'jointPropertyComplex'
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Базовий продаж. Голандець
                        </div>
                    </Link>
                </div>
            </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.itemType !== null){
        if(tmp === null){
            tmp = '?itemType=jointPropertyComplex'
        }else{
            tmp += '&itemType=jointPropertyComplex'
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nadra)