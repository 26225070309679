import React from 'react';
import style from './answerAuctionEdit.module.css';

import {connect} from 'react-redux';

import {setPopUpAnswerAuction, auctionsGetMy, auctionGetId, auctionGetIdToken} from '../../../../redux/actions/auctions/auctions.js';
import {answerTitle, answerDiscription} from '../../../../redux/actions/questions/questions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const AnswerAuction = (props) => {
    let tmpTitle
    if(props.questionCreate.popUpEdit.title.hasOwnProperty('uk_UA')){
        tmpTitle = props.questionCreate.popUpEdit.title.uk_UA
    }else{
        tmpTitle = props.questionCreate.popUpEdit.title
    }
    let tmpDescription
    if(props.questionCreate.popUpEdit.description.hasOwnProperty('uk_UA')){
        tmpDescription = props.questionCreate.popUpEdit.description.uk_UA
    }else{
        tmpDescription = props.questionCreate.popUpEdit.description
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div className={style.newQuestionBlock}>
                    <h3 className={style.title}>Редагування запитання</h3>
                    <input type="text"
                        className={style.inputStyle}
                        placeholder='Тема запитання'
                        value={tmpTitle}
                        onChange={(e) => {
                            let tmp = props.questionCreate.popUpEdit
                            tmp.title = e.target.value
                            props.setDataAddress(tmp, 'SET_POP_UP_EDIT_AUCTION')
                        }}
                    />
                    <textarea 
                        className={style.textArea}
                        placeholder='Зміст запитання'
                        value={tmpDescription}
                        onChange={(e) => {
                            let tmp = props.questionCreate.popUpEdit
                            tmp.description = e.target.value
                            props.setDataAddress(tmp, 'SET_POP_UP_EDIT_AUCTION')
                        }}
                    >
                    </textarea>
                    <div className={style.btnBlock}>
                        <div
                            className={style.blueBtn}
                            onClick = {
                                () => {
                                    createAnswer(props)
                                    //props.setDataAddress(null, 'SET_POP_UP_EDIT_AUCTION')
                                }
                            }
                        >
                            Надіслати
                        </div>
                        <div
                            className={style.blueBtn}
                            onClick = {
                                () => {
                                    props.setDataAddress(null, 'SET_POP_UP_EDIT_AUCTION')
                                }
                            }
                        >
                            Закрити
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

async function  createAnswer(props){
    let tmpTitle
    if(props.questionCreate.popUpEdit.title.hasOwnProperty('uk_UA')){
        tmpTitle = props.questionCreate.popUpEdit.title.uk_UA
    }else{
        tmpTitle = props.questionCreate.popUpEdit.title
    }
    let tmpDescription
    if(props.questionCreate.popUpEdit.description.hasOwnProperty('uk_UA')){
        tmpDescription = props.questionCreate.popUpEdit.description.uk_UA
    }else{
        tmpDescription = props.questionCreate.popUpEdit.description
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctions.id}/question/${props.questionCreate.popUpEdit.id}`;
    const response1 = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            title: tmpTitle,
            description: tmpDescription
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        await props.answerDiscription('')
        await response1.json();
        getPublicApiAuction(props)
        
    }
}

async function getPublicApiAuction(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/auction/${props.auctions.id}`
    let header
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        //props.setDataAddress(null, 'SET_FULL_AUCTION')
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
            props.setDataAddress(null, 'SET_POP_UP_EDIT_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        questionCreate: state.start.questionCreate,
        pageNumber: state.start.pageNumber,
    }
}

const mapDispatchToProps = {
    setPopUpAnswerAuction,
    auctionsGetMy,
    auctionGetId,
    auctionGetIdToken,

    answerTitle,
    answerDiscription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerAuction)