import React from 'react';
import styles from './railwayCargo.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchCount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeMyCurentPage,
} from '../../../../redux/actions.js';

const Nadra = ( {langFlag, aucyionsPath, search, token, auctionsSearch, setAuctionCategory, changeCurentPage, changeMyCurentPage, clearSearch} ) => {
    let active = null;
    if(search.category === 'railwayCargo-english'){
        active = styles.active;
    }
    return (
        <div
            className={styles.aukpoisk_second_elem}
        >
            <Link
                onClick={
                    ()=>{
                        changeMyCurentPage(0)
                        setAuctionCategory('railwayCargo-all');
                        changeCurentPage(0);
                        let dataSearch = {}
                        dataSearch.order = search.order
                        dataSearch.region = search.region
                        dataSearch.category = 'railwayCargo-all'
                        dataSearch.priceCurrency = search.priceCurrency
                        dataSearch.priceFrom = search.priceFrom
                        dataSearch.priceTo = search.priceTo
                        dataSearch.measures = search.measures
                        dataSearch.measuresFrom = search.measuresFrom
                        dataSearch.measuresTo = search.measuresTo
                        if(search.status === null || search.status === ''){
                            dataSearch.status = null
                        }else{
                            dataSearch.status = search.status
                        }
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: langFlag,
                            params: '?offset=0'
                        }
                        if(token !== null){
                            let header = {
                                'Authorization': token.access_token,
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        }else{
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        }
                        clearSearch()
                    }
                }
                to={`${aucyionsPath}/railway-cargo${getUrlFiltersParam(search, 'railwayCargo-all')}`}
            >
                <div className={styles.base + ' ' + active}></div>
                <div className={styles.aukpoisk_second_elem_text}>Залізничні перевезення</div>
            </Link>
            <div className={styles.aukpoisk_second_elem_1}>
                <Link to={`${aucyionsPath}/railway-cargo${getUrlFiltersParam(search, 'railwayCargo-english')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                changeMyCurentPage(0)
                                setAuctionCategory('railwayCargo-english');
                                changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = search.order
                                dataSearch.region = search.region
                                dataSearch.category = 'railwayCargo-english'
                                dataSearch.priceCurrency = search.priceCurrency
                                dataSearch.priceFrom = search.priceFrom
                                dataSearch.priceTo = search.priceTo
                                dataSearch.measures = search.measures
                                dataSearch.measuresFrom = search.measuresFrom
                                dataSearch.measuresTo = search.measuresTo
                                if(search.status === null || search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: langFlag,
                                    params: '?offset=0'
                                }
                                if(token !== null){
                                    let header = {
                                        'Authorization': token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }
                                clearSearch()
                            }
                        }
                    >
                        Залізничні перевезення. Англієць
                    </div>
                </Link>
                <Link to={`${aucyionsPath}/railway-cargo${getUrlFiltersParam(search, 'railwayCargo-dutch')}`}>
                    <div
                        className={styles.subMenu}
                        onClick={
                            ()=>{
                                changeMyCurentPage(0)
                                setAuctionCategory('railwayCargo-dutch');
                                changeCurentPage(0);
                                let dataSearch = {}
                                dataSearch.order = search.order
                                dataSearch.region = search.region
                                dataSearch.category = 'railwayCargo-dutch'
                                dataSearch.priceCurrency = search.priceCurrency
                                dataSearch.priceFrom = search.priceFrom
                                dataSearch.priceTo = search.priceTo
                                dataSearch.measures = search.measures
                                dataSearch.measuresFrom = search.measuresFrom
                                dataSearch.measuresTo = search.measuresTo
                                if(search.status === null || search.status === ''){
                                    dataSearch.status = null
                                }else{
                                    dataSearch.status = search.status
                                }
                                let data = {
                                    domen: process.env.REACT_APP_END_POINT_BD,
                                    version: 'v1',
                                    local: langFlag,
                                    params: '?offset=0'
                                }
                                if(token !== null){
                                    let header = {
                                        'Authorization': token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }else{
                                    let header = {
                                        'Content-Type': 'application/json'
                                    }
                                    auctionsSearch(data, header, dataSearch);
                                }
                                clearSearch()
                            }
                        }
                    >
                        Залізничні перевезення. Голандець
                    </div>
                </Link>
            </div>
        </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nadra)