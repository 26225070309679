import React from 'react';

import {connect} from 'react-redux';
import {transformData} from '../../../../redux/modifiDate'

import TextHalf from '../../../elements/viewData/textFull'

const LeaseDurationString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('leaseDuration') === true){
            if(props.auctionId.leaseDuration !== null){
                return (
                    <TextHalf
                        title="Строк оренди"
                        data={transformData(props.auctionId.leaseDuration, 'Y') + ' ' + transformData(props.auctionId.leaseDuration, 'M') + ' ' + transformData(props.auctionId.leaseDuration, 'D')}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LeaseDurationString)