import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import {checkProp} from '../../../../redux/check';

import OverdueDurationString from '../../lotInformation/auctionElements/overdueDuration'
import {TranslationsUk} from "../../../../redux/translations.uk";

const RenewablesMultiAwardItem = (props) => {
    let tmp = null;
    if (props.auction !== null) {
        if (props.auction.items.length === 0) {
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані лоти відсутні</div>
        } else {
            tmp = props.auction.items
                .map(
                    (item, count) => {
                        return (
                            <div key={count}>
                                {viewTypeAuction(item, count)}
                            </div>
                        )
                    }
                )
        }
    }

    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data) {
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if (checkProp(data, 'classification')) {
        tmpClassificationId = data?.classification?.id
        tmpClassificationDescription = checkProp(data?.classification, 'description') &&
        checkProp(data?.classification?.description, 'uk_UA') ? data?.classification?.description?.uk_UA : null
    }
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`

    return (
        <>
            <TextFull
                title={TranslationsUk['renewables'].Item?.description?.legalName}
                data={data?.description?.uk_UA}
            />
            <TextFull
                title={TranslationsUk['renewables'].Item?.itemProps?.regions?.legalName}
                data={data?.itemProps?.regions.length === 0 ? '-' : data?.itemProps?.regions.join(', ')}
            />
            <TextFull
                title={TranslationsUk['renewables'].Item?.itemProps?.techParams?.legalName}
                data={data?.itemProps?.techParams}
            />
            <TextFull
                title={TranslationsUk['renewables'].Item?.itemProps?.timeSlots?.legalName}
                data={data?.itemProps?.timeSlots}
            />
            <TextFull
                title={TranslationsUk['renewables'].Item?.itemProps?.loadProfiles?.legalName}
                data={data?.itemProps?.loadProfiles}
            />
            <TextFull
                title={TranslationsUk['renewables']?.Item?.classification?.legalName}
                data={tmpClassificationSchemeStr}
            />
            <OverdueDurationString obj={data}/>
            {unitName(data)}
            {quantityName(data)}
        </>
    )
}

function unitName(data) {
    if (data?.unit?.name !== null) {
        return <TextHalf
            title={TranslationsUk['renewables'].Item?.unit.legalName}
            data={data?.unit.name.uk_UA}
        />
    }
    return null
}

function quantityName(data) {
    if (data?.quantity !== null) {
        return <TextHalf
            title={TranslationsUk['renewables'].Item?.quantity.legalName}
            data={data?.quantity}
        />
    }
    return null
}

const mapStateToProps = state => {
    return {
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(RenewablesMultiAwardItem)
