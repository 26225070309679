export function startCostAuction(data, dataName){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(viewAmount(data, dataName));
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

function viewAmount(data, dataName){
    if(data.hasOwnProperty(dataName) === true){
        if(data[dataName] !== null){
            if(data[dataName].hasOwnProperty('amount') === true){
                if(data[dataName].amount !== null){
                    return data[dataName].amount;
                }
            }
        }
    }
    return 0
}