import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../../redux/modifiDate'

import ContactPoints from '../../contactPoint/contactPoint'

const OrganizationEntity = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.hasOwnProperty("organizationEntity") !== true) return null
    if(props.auctionId.organizationEntity === null) return null
    if(props.auctionId !== null && props.auctionId.organizationEntity !== null){
        return (
            <div className={styles.infoBlock}>
                <h3>Контактні дані організатора аукціону</h3>
                <div className={styles.orgTitle}>
                    <p className={styles.desc}>Повна юридична назва організації або ПІБ</p>
                    <p>{contactName(props)}</p>
                </div>
                <div className={styles.orgContact}>
                    <p className={styles.desc}>Контактні дані</p>
                    {contactPoint(props)}
                </div>
                <div className={styles.orgId}>
                    <p className={styles.desc}>Код ЄДРПОУ або ІПН або паспорт</p>
                    <p>{contactEDRPOU(props)}</p>
                </div>
                <div className={styles.orgAdress}>
                    <p className={styles.desc}>Юридична адреса</p>
                    <p>{contactAddress(props)}</p>
                </div>
                {contactKOATUU(props)}
                {currentContractTime(props)}
            </div>
        )
    }
    return null
}

function contactKOATUU(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity.hasOwnProperty('address')){
                    if(props.auctionId.organizationEntity.address !== null){
                        if(props.auctionId.organizationEntity.address.hasOwnProperty('addressID')){
                            if(props.auctionId.organizationEntity.address.addressID !== null){
                                if(props.auctionId.organizationEntity.address.addressID.hasOwnProperty('id')){
                                    if(props.auctionId.organizationEntity.address.addressID.id !== null){
                                        return (
                                            <div className={styles.orgAdress}>
                                                <p className={styles.desc}>КОАТУУ</p>
                                                <p>{props.auctionId.organizationEntity.address.addressID.id}</p>
                                            </div>
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function contactName(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity !== null){
                    if(props.auctionId.organizationEntity.hasOwnProperty('name')){
                        if(props.auctionId.organizationEntity.name !== null){
                            tmp += props.auctionId.organizationEntity.name.uk_UA
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function currentContractTime(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity !== null){
                    if(props.auctionId.organizationEntity.hasOwnProperty('currentContractTime')){
                        if(props.auctionId.organizationEntity.currentContractTime !== null){
                            if(
                                props.auctionId.organizationEntity.currentContractTime.dateFrom !== null &&
                                props.auctionId.organizationEntity.currentContractTime.dateTill !== null
                            ){
                                return (
                                    <>
                                        <div className={styles.orgAdress}>
                                            <p className={styles.desc}>Дата укладення та закінчення договору оренди</p>
                                            <p></p>
                                        </div>
                                        <div className={styles.orgId}>
                                            <p className={styles.desc}>Дата та час початку</p>
                                            <p>{modifiDateString(props.auctionId.organizationEntity.currentContractTime.dateFrom, 3)}</p>
                                        </div>
                                        <div className={styles.orgId}>
                                            <p className={styles.desc}>Дата та час закінчення</p>
                                            <p>{modifiDateString(props.auctionId.organizationEntity.currentContractTime.dateTill, 3)}</p>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactPoint(props){
    // console.log(props.auctionId)
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity.hasOwnProperty('contactPoint')){
                    if(props.auctionId.organizationEntity.contactPoint !== null){
                        return <ContactPoints data={props.auctionId.organizationEntity.contactPoint} />
                    }
                }
            }
        }
    }
    return null
}

function contactEDRPOU(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity !== null){
                    if(props.auctionId.organizationEntity.hasOwnProperty('identifier')){
                        if(props.auctionId.organizationEntity.identifier !== null){
                            if(props.auctionId.organizationEntity.hasOwnProperty('identifier')){
                                if(props.auctionId.organizationEntity.identifier.id !== null){
                                    tmp += props.auctionId.organizationEntity.identifier.id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return tmp
}

function contactAddress(props){
    let tmp = ''
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('organizationEntity')){
            if(props.auctionId.organizationEntity !== null){
                if(props.auctionId.organizationEntity !== null){
                    if(props.auctionId.organizationEntity.hasOwnProperty('address')){
                        if(props.auctionId.organizationEntity.address !== null){
                            if(props.auctionId.organizationEntity.address.hasOwnProperty('postalCode')){
                                if(props.auctionId.organizationEntity.address.postalCode !== null){
                                    tmp += props.auctionId.organizationEntity.address.postalCode + ', '
                                }
                            }
                            if(props.auctionId.organizationEntity.address.hasOwnProperty('countryName')){
                                if(props.auctionId.organizationEntity.address.countryName !== null){
                                    tmp += props.auctionId.organizationEntity.address.countryName.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.organizationEntity.address.hasOwnProperty('region')){
                                if(props.auctionId.organizationEntity.address.region !== null){
                                    tmp += props.auctionId.organizationEntity.address.region.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.organizationEntity.address.hasOwnProperty('locality')){
                                if(props.auctionId.organizationEntity.address.locality !== null){
                                    tmp += props.auctionId.organizationEntity.address.locality.uk_UA + ', '
                                }
                            }
                            if(props.auctionId.organizationEntity.address.hasOwnProperty('streetAddress')){
                                if(props.auctionId.organizationEntity.address.streetAddress !== null){
                                    tmp += props.auctionId.organizationEntity.address.streetAddress.uk_UA
                                }
                            }                                    
                        }
                    }
                }
            }
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(OrganizationEntity)
