import React, {useState} from 'react';
import styles from '../docItem.module.css';
import {connect} from 'react-redux';
import { viewBidDeleteDoc, deleteBidDocuments } from './logic/bidLogic';
import { viewAucDeleteDoc, deleteAucDocuments } from './logic/aucEditLogic';
import { changeLoader } from '../../../redux/actions';
import { setDataAddress } from '../../../redux/createAddress'

import { saveArrBids } from '../../../redux/allAuctionBids';
import PopAsk from "../../_popups/popAsk/popAsk";


const DocDeleteButton = (props) => {
    const [popDelete, setPopDelete] = useState(false)

    function viewDeleteDoc(props) {
        switch (props.scope.typeOf) {
            case 'bid':
                return viewBidDeleteDoc(props);
            case 'auctionEdit':
                return viewAucDeleteDoc(props);
            default:
                return false;
        }
    }

    function setDeleteDoc(props) {
        switch (props.scope.typeOf) {
            case 'bid':
                return deleteBidDocuments(props);
            case 'auctionEdit':
                return deleteAucDocuments(props);
            default:
                return false;
        }
    }

if (viewDeleteDoc(props)) {
        return <>
            <div
                className={styles.btnDelete}
                title='Видалити документ'
                onClick={
                        () => {
                        setPopDelete(true)
                        // deleteDocuments(props)
                    }
                }
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={styles.btnDeleteSVG} fillRule="evenodd" clipRule="evenodd" d="M5.87966 3.065L6.51813 1.14958C6.64744 0.761659 7.01047 0.5 7.41938 0.5H12.5494C12.9583 0.5 13.3213 0.761659 13.4506 1.14958L14.0891 3.065H18.5343C19.059 3.065 19.4843 3.49033 19.4843 4.015C19.4843 4.53967 19.059 4.965 18.5343 4.965H17.7201L17.0229 16.8174C16.9343 18.3238 15.6869 19.5 14.1779 19.5H5.7909C4.28189 19.5 3.03443 18.3238 2.94582 16.8174L2.24862 4.965H1.44951C0.924841 4.965 0.499512 4.53967 0.499512 4.015C0.499512 3.49033 0.924841 3.065 1.44951 3.065H5.87966ZM7.88243 3.065H12.0863L11.8647 2.4H8.1041L7.88243 3.065ZM15.8169 4.965H4.1519L4.84254 16.7058C4.87207 17.2079 5.28789 17.6 5.7909 17.6H14.1779C14.6809 17.6 15.0967 17.2079 15.1262 16.7058L15.8169 4.965ZM11.6012 7.37574C11.634 6.85209 12.085 6.45412 12.6086 6.48685C13.1323 6.51958 13.5303 6.97061 13.4975 7.49426L13.07 14.3343C13.0373 14.8579 12.5863 15.2559 12.0626 15.2232C11.539 15.1904 11.141 14.7394 11.1737 14.2157L11.6012 7.37574ZM8.79503 14.2157C8.82775 14.7394 8.42979 15.1904 7.90614 15.2232C7.38249 15.2559 6.93146 14.8579 6.89873 14.3343L6.47123 7.49426C6.4385 6.97061 6.83647 6.51958 7.36012 6.48685C7.88377 6.45412 8.3348 6.85209 8.36753 7.37574L8.79503 14.2157Z" fill="#FFA047"/>
                </svg>
            </div>
            {
                popDelete ? <PopAsk
                        title={`Ви дійсно хочете видалити ${props.state.docType}?`}
                        actionYes={()=>{
                                setDeleteDoc(props)
                                setPopDelete(false)
                            }}
                        actionNo={()=>{setPopDelete(false)}}
                        />
                : null
            }
        </>
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionsGetId
    }
}

const mapDispatchToProps = {
    changeLoader,
    // auctionsBidsGetId,
    setDataAddress,
    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps) (DocDeleteButton)