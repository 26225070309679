import React from 'react';
import style from './bridge.module.css';

import {connect} from 'react-redux';

import {Redirect} from 'react-router-dom';

import {
    setDataAddress,
} from '../../../redux/createAddress';

const GarantieCost = ( props ) => {
    let tmpText = null
    if(props.auctionsMy !== null){
        if(props.auctionsMy.sellingMethod !== null){
            if(
                props.auctionsMy.sellingMethod === 'landRental-english' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'landSell-english' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
            ){
                if(props.auctionsMy.status === 'active_rectification'){
                    return <div className={style.popup_message}>
                        <div className={style.title}>Попередження</div>
                        <div className={style.title}>Увага!</div>
                        <div className={style.baseText}>Перед початком редагування завантажте обов'язковий документ "Погодження змін до опису лоту. Опис причин редагування."</div>
                        {closeButton(props)}
                    </div>
                }else{
                    return null
                }
            }else{
                tmpText = <div className={style.popup_message}>
                    <div className={style.title}>Попередження</div>
                    <div className={style.title}>Увага!</div>
                    <div>Основна інформація в аукціон переноситься з об'єкту переліку.</div>
                    <div className={style.baseText}>За потреби спочатку внесіть необхідні зміни до об'єкту у переліку і вже після цього переходити до етапу публікації аукціону</div>
                    {closeButton(props)}
                </div>
            }
        }
    }else{
        if(props.token === null){
            tmpText = <div className={style.popup_message}>
                <div className={style.title}>Попередження</div>
                <div className={style.title}>Увага!</div>
                <div className={style.baseText}>Для користування свзкою реєстри - створення процедури авторизуйтесь</div>
                {closeButton(props)}
            </div>
        }
    }

    if(window.location.pathname.indexOf('/create_auctions/bridge/') !== -1 && props.bridge.openButtonPopUp === true){
        //return <Redirect to='/create_auctions'/>
        return <Redirect to='/'/>
    }

    return tmpText
}

function closeButton(props){
    //if(props.bridge.openButtonPopUp === true){
    
    return (
        <div className={style.button_wrap}>
            <div
                className={style.popup_message_button}
                onClick={
                    () => {
                        props.setDataAddress(false, 'SET_BRIDGE_OPEN_POPUP')
                        props.setDataAddress(false, 'SET_BRIDGE_OPEN_BUTTON')
                    }
                }
            >Закрити</div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctionsMy: state.start.auctionsGetMy,
        auctionsMy: state.start.auctionsMy,
        bridge: state.start.bridge,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(GarantieCost)
