import React from 'react';
import styles from './informationAboutTheOrganizer.module.css';

import {connect} from 'react-redux';

const RelatedEntities = (props) => {
    
    function viewData(data){
        if(data === null) return null
        if(data === undefined || data === "undefined") return null
        let tmpURL = null
        let tmpURLs = null
        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
            tmpURL = "https://registry.dto.com.ua"
            tmpURLs = "https://beta.dto.com.ua"
        }else{
            //tmpURL = "https://staging-registry.dto.com.ua"
            tmpURL = "https://sandbox-registry.dto.com.ua"
            //tmpURLs = "https://staging.dto.com.ua"
            tmpURLs = "https://sandbox.dto.com.ua"
        }
        return data.map((e) =>{
            return (
                <div style={{width:"50%"}} key={e.objectId}>
                    <div className={styles.orgTitle}>
                        <p className={styles.desc}>Тип зв'язку</p>
                        <p>{typeProc(e.type)}</p>
                    </div>
                    <div className={styles.orgTitle}>
                        <p className={styles.desc}>Id пов'язанного об'єкту</p>
                        <p>{e.objectId}</p>
                    </div>
                    <div className={styles.orgTitle}>
                        {e.type === "asset" ? <a href={`${tmpURL}/asset/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "large_asset" ? <a href={`${tmpURL}/asset/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "announcement" ? <a href={`${tmpURL}/asset/announcement/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "large_announcement" ? <a href={`${tmpURL}/asset/announcement/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "execution" ? <a href={`${tmpURL}/asset/execution/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "large_execution" ? <a href={`${tmpURL}/asset/execution/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                        {e.type === "procedure" ? <a href={`${tmpURLs}/auctions/${e.objectId}`} className={styles.desc}>Посилання</a> : null}
                    </div>
                </div>
            )
        })
    }

    function typeProc(data){
        if(data === "announcement") return "Інформаційне повідомлення"
        if(data === "large_announcement") return "Інформаційне повідомлення"
        if(data === "asset") return "Об'єкт МП"
        if(data === "large_asset") return "Об'єкт великої приватизації"
        if(data === "procedure") return "Процедура"
        if(data === "execution") return "Контрактинг"
        if(data === "large_execution") return "Контрактинг"
        return null
    }
    
    if(props.auctionId !== null && props.auctionId.relatedEntities !== null){
        return (
            <div className={styles.infoBlock}>
                <h3 style={{width:"100%"}}>Список пов'язаних процесів</h3>
                {viewData(props.auctionId.relatedEntities)}
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(RelatedEntities)
