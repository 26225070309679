import { 
    //Old site users
    USER_PROFILES_GET
} from "../../types";

import {postRefToken} from "../default/default";

//Old site users

export function userProfileGet(_url, header, props, isRecursive){
    props = props ?? null
    isRecursive = isRecursive ?? false

    return async dispatch => {
        fetch(`${_url.domen}/api/v1/uk/private/userProfile`, {
            method: 'GET',
            headers: header
        }).catch(error => {
            //@todo some error message?
            console.error(error)
            // dispatch({type: 'TOKEN_CLEAR', payload: null})
        }).then(response => {
            if (response.status === 401) {
                if (isRecursive && typeof props?.token?.refresh_token !== 'undefined') {
                    //token refresh failed, clear storage
                    dispatch({type: 'TOKEN_CLEAR', payload: null})
                    dispatch({type: 'SET_PROFILE_NULL', payload: null})
                    return
                } else {
                    //refresh auth token and try again:
                    const refresh = postRefToken(process.env.REACT_APP_END_POINT_BD, props?.token?.refresh_token)
                    refresh(dispatch)
                    return userProfileGet(_url,header,props,true)
                }
            }
            response.json().then(parsedJson => {
                dispatch({type: USER_PROFILES_GET, payload: parsedJson})
            })
        })
    }
}