import React from 'react';
import style from './editDocument.module.css';

import {connect} from 'react-redux';

//import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {editAwardDoc, editAwardDocTitle, editAwardDocDiscript, editAwardDocFiles} from '../../../../redux/editAwardDoc.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
} from '../../../../redux/actions/awards/awards.js';

import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';


const DeleteBidDocument = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={() => {
                            props.editAwardDoc(null)
                        }
                    }
                    
                >
                    X
                </div>
                <div className={style.popup_main_doc}>
                    <div>Редагування документу</div>
                    <div className={style.uploadDoc}>
                        <div>
                            <TextField
                                required
                                id="filled-required"
                                className={style.inputText}
                                label="Назва документу"
                                variant="outlined"
                                value={props.popUpEditAppruveDoc.document.title}
                                onChange={
                                    (e) => {
                                        props.editAwardDocTitle(e.target.value)
                                    }
                                }
                            />
                        </div>
                        <div>Тип документу: {props.popUpEditAppruveDoc.document.documentType}</div>
                        <TextField
                            id="filled-required"
                            className={style.inputText}
                            label="Опис"
                            variant="outlined"
                            value={props.popUpEditAppruveDoc.document.description}
                            onChange={
                                (e) => {
                                    props.editAwardDocDiscript(e.target.value)
                                }
                            }
                        />
                    </div>
                    <div className={style.uploadDoc}>
                        {createView(props)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function createView(props){
    let tmp;
    tmp = (
        <div className={style.documentsForm + ' ' + style.widthBlok}>
            <div className={style.groupElem + ' ' + style.widthBlok}>
                {buttonCreateImg(props)}
            </div>
        </div>
    )
    return tmp
}

function buttonCreateImg(props){
    //let tmpEcp = null
    /*if(props.togetherApiPage.ecpOnOff === true){
        tmpEcp = blocEcpData(props)
    }*/
    let fileElem;
    if(props.popUpEditAppruveDoc.hasOwnProperty('files') === true){
        fileElem = <span className={style.input__file_button_text}>
            <span>Доданий документ:</span> <span>{props.popUpEditAppruveDoc.files}</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Додати документ</span>
        </span>
    }
    return (
        <div className={style.formLine + ' ' + style.widthBlok}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props)
                }}
                id="addFileForm"
                className={style.widthBlok}
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__fileEditAward"
                        className={style.input + ' ' + style.input__file}
                        onChange={
                            () => {
                                let addFileInp1 = document.getElementById('input__fileEditAward');
                                props.editAwardDocFiles(addFileInp1.files[0].name)
                            }
                        }
                    />
                    <label for="input__fileEditAward" className={style.input__file_button}>
                        {fileElem}
                    </label>
                </div>
                {onOffButtonAddDoc(props)}
            </form>
        </div>
    )
}

function onOffButtonAddDoc(props){
    let tmp;
    if(props.popUpEditAppruveDoc.document.title !== null){
            if(
                props.popUpEditAppruveDoc.document.title !== null &&
                //props.createDocuments.fileName !== null &&
                props.popUpEditAppruveDoc.document.title !== 'null' &&
                //props.createDocuments.fileName !== 'null' &&
                props.popUpEditAppruveDoc.document.title !== '' //&&
                //props.createDocuments.fileName !== ''
            ){
                tmp = <button
                    type="submit"
                    className={style.popup_message_button}
                >Зберегти зміни</button>
            }else{
                tmp = <div
                    className={style.popup_message_button + ' ' + style.popup_message_buttonOff}
                >
                    Зберегти зміни
                </div>
            }
    }else{
        tmp = <div
            className={style.popup_message_button + ' ' + style.popup_message_buttonOff}
        >
            Зберегти зміни
        </div>
    }
    return tmp
}

function handleSubmit(e, props){
    e.preventDefault()
    if(props.popUpEditAppruveDoc.hasOwnProperty('files') === true){
        uploadNewDocFull(props)
    }else{
        uploadNewDocText(props)
    }
}

async function uploadNewDocText(props){
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/documents/${props.popUpEditAppruveDoc.document.id}`;
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${props.popUpEditAppruveDoc.document.id}`;
    
    let tmpDisc = null
    if(props.popUpEditAppruveDoc.document.description !== null && props.popUpEditAppruveDoc.document.description !== ''){
        tmpDisc = props.popUpEditAppruveDoc.document.description
    }
    const response = await fetch(endPoint, {
        method: "PATCH",
        //method: "PUT",
        body: JSON.stringify({
            title: props.popUpEditAppruveDoc.document.title,
            description: tmpDisc,
            language: 'uk',
            documentOf: 'contract',
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        await props.editAwardDoc(null)
        await props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            let header;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            if(
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast-manual" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-auction" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-auction-manual" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-qualification" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast-manual" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-auction" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-qualification" ||
                props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-auction-manual"
            ){
                const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/sync?` + Math.random();
                const responseAsync = await fetch(urlAsync, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(responseAsync === undefined || responseAsync === 'undefined'){
                    await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
                }else{
                    await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
                }
            }else{
                await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
            }
            await props.editAwardDoc(null)
            await props.changeLoader(false)
        }
    }
}

async function uploadNewDocFull(props){
    props.changeLoader(true)
    const addFileInp = document.getElementById('input__fileEditAward');
    let endPoint89;
    if(
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-fast" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-fast-manual" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-initial-auction" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-initial-auction-manual" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-english-initial-qualification" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-fast" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-fast-manual" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-fast-auction-manual-qualification" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-initial-auction" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-initial-qualification" ||
        props.popUpContractApprowe.fullAuction.sellingMethod === "dgf-dutch-initial-auction-manual"

    ){
        endPoint89 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint89 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', props.popUpEditAppruveDoc.document.documentType)
    formData.append('file', addFileInp.files[0])
    const response89 =  await fetch(endPoint89, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response89 === undefined || response89 === 'undefined'){
        await props.editAwardDoc(null)
        await props.changeLoader(false)
    }else{
        const json89 = await response89.json()
        if(json89.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json89)})
            props.setUniversalError(tmp)
        }else{
            
            let endPoint
            let body
            let tmpDisc = null
            if(props.popUpEditAppruveDoc.document.description !== null && props.popUpEditAppruveDoc.document.description !== ''){
                tmpDisc = props.popUpEditAppruveDoc.document.description
            }
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/documents/${props.popUpEditAppruveDoc.document.id}`;
            //endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/${props.popUpEditAppruveDoc.document.id}`;
            body = JSON.stringify({
                title: props.popUpEditAppruveDoc.document.title,
                description: tmpDisc,
                documentType: props.popUpEditAppruveDoc.document.documentType,
                //documentOf: 'bid',
                documentOf: 'contract',
                language: 'uk',
                token: json89.jwt
            })
            const response = await fetch(endPoint, {
                //method: "PUT",
                method: "PATCH",
                body: body,
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
                await props.editAwardDoc(null)
                await props.changeLoader(false)
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    let header;
                    if(props.token !== null){
                        header = {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }else{
                        header = {
                            'Content-Type': 'application/json'
                        }
                    }
                    let data5 = {
                        domen: process.env.REACT_APP_END_POINT_BD,
                        version: 'v1',
                        local: props.langFlag
                    }
                    if(
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast-manual" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-fast-auction-manual-qualification" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-auction" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-auction-manual" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-english-initial-qualification" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast-manual" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-fast-auction-manual-qualification" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-auction" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-qualification" ||
                        props.popUpContractApprowe.fullAuction.sellingMethod !== "dgf-dutch-initial-auction-manual"
                    ){
                        const urlAsync = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/sync?` + Math.random();
                        const responseAsync = await fetch(urlAsync, {
                            method: "GET",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(responseAsync === undefined || responseAsync === 'undefined'){
                            await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
                        }else{
                            await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
                        }
                    }else{
                        await props.contractsAuctionIdGet(data5, header, props.popUpContractApprowe.aucId)
                    }
                    await props.editAwardDoc(null)
                    await props.changeLoader(false)
                }
            }

        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,

        awardDocument: state.start.awardDocument,
        popUpEditAppruveDoc: state.start.popUpEditAppruveDoc,
        popUpContractApprowe: state.start.popUpContractApprowe,
    }
}

const mapDispatchToProps = {
    editAwardDoc,
    editAwardDocTitle,
    editAwardDocDiscript,
    editAwardDocFiles,
    changeLoader,
    setUniversalError,
    auctionsGetAwards,
    auctionsGetAwardsId,
    contractsAuctionIdGet,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBidDocument)