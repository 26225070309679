import React from 'react';
import styles from './vehicleProps.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
} from '../../../../../redux/actions.js';

const WindowItems = ( props ) => {
    return (
        <div className={styles.group}>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Марка транспортного засобу"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.brand}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsBrand(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Модель транспортного засобу"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.model}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsModel(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Рік випуску"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.productionYear}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsProductionYear(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Об’єм двигуна, см³"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.engineCapacity}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsEngineCapacity(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Тип палива"
                        value={props.createItems.dgf.vehicleProps.fuelType}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsFuelType(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберить тип палива"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesFuelType(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Тип коробки передач"
                        value={props.createItems.dgf.vehicleProps.transmission}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsTransmission(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберить тип коробки передач"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {dictionariesTransmission(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Колір"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.color}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsColor(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Пробіг, км"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.kilometrage}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsKilometrage(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Номер кузова або шасі"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.identificationNumber}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsIdentificationNumber(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Комплектація"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.configuration}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsConfiguration(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Технічний стан"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.condition}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsCondition(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Потреба у ремонті"
                        value={props.createItems.dgf.vehicleProps.damagePresence}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsDamagePresence(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберить тип палива"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        <option key='1' value="true">Так</option>
                        <option key='2' value="false">Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис пошкоджень"
                        variant="outlined"
                        value={props.createItems.dgf.vehicleProps.damagedDescription}
                        onChange={(event)=>{
                            props.changeCreateItemDgfVehiclePropsDamagedDescription(event.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

function dictionariesTransmission(props){
    if(props.dictionaries.transmissionType !== null){
        return props.dictionaries.transmissionType.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function dictionariesFuelType(props){
    if(props.dictionaries.fuelType !== null){
        return props.dictionaries.fuelType.map(
            (i, count) => {
                return <option key={count} value={i.name}>{i.description}</option>
            }
        )
    }
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    changeCreateItemDgfVehiclePropsId,
    changeCreateItemDgfVehiclePropsBrand,
    changeCreateItemDgfVehiclePropsModel,
    changeCreateItemDgfVehiclePropsProductionYear,
    changeCreateItemDgfVehiclePropsEngineCapacity,
    changeCreateItemDgfVehiclePropsFuelType,
    changeCreateItemDgfVehiclePropsTransmission,
    changeCreateItemDgfVehiclePropsColor,
    changeCreateItemDgfVehiclePropsKilometrage,
    changeCreateItemDgfVehiclePropsIdentificationNumber,
    changeCreateItemDgfVehiclePropsConfiguration,
    changeCreateItemDgfVehiclePropsCondition,
    changeCreateItemDgfVehiclePropsDamagePresence,
    changeCreateItemDgfVehiclePropsDamagedDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)