import React, { useEffect } from 'react';
import styles from './product.module.css';

import { Switch, Route } from 'react-router-dom'


import Chat from './chat/chat'

import {connect} from 'react-redux';
import {changePageNumber} from '../../redux/actions.js';
import {auctionGetId, auctionGetIdToken} from '../../redux/actions/auctions/auctions.js';
import {auctionsBidsGetId} from '../../redux/actions/auctionBid/auctionBid.js';
import {setLogo} from '../../redux/actions/support.js';
import {auctionsGetAwards} from '../../redux/actions/awards/awards.js';
import {contractsAuctionIdGet, saveHistoryDocsContracts} from '../../redux/actions/contracts/contracts.js';
import {setDataAddress} from '../../redux/createAddress';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../redux/history.js';

import {
    saveArrBids,
} from '../../redux/allAuctionBids.js';

import Errors from '../errors/errors'
import PopUpLoader from '../popUp/loader/loader.js';

import {backendAPI} from '../lib/backendAPI'
import {errorAPI} from '../lib/errorAPI'
import {getDefaultHeaders, getUserBidsListByAuction} from "../_api/bidApi";


import AuctionResultTab from "../_CRUD/auction/view/auctionResultTab/auctionResultTab";
import AuctionInfoTab from "../_CRUD/auction/view/auctionInfoTab/auctionInfoTab";


const Lot = (props) => {
    //TODO: Подключить новую монгу
    if(props.logo === null){
        props.setLogo(window.location.pathname.length)
    }else{
        if(window.location.pathname.length === 1){
            if(props.logo !== window.location.pathname.length){
                props.setLogo(null)
            }
        }else{
            if(props.logo !== window.location.pathname.length){
                props.setLogo(window.location.pathname.length)
            }
        }
    }

    useEffect(() => {
        if (props?.auctionId?.privateData?.bids?.length > 0) {
            getUserBidsListByAuction(props, props?.auctionId.id)
        }
    }, [])


    let tmpId = setPageId()
    setStatePageNumber(props)
    useEffect(() => {
        combineSetData(props, tmpId[0])
    }, [])
    let viewTmp;
    if(/*props.auctionsGetIdNotFound === false && */props.auctionId !== null){
        if(Number(props.auctionId)){
            viewTmp = <Errors errorCode={props.auctionId}/>
        }else{
            viewTmp = (
                <Switch>
                    <Route exact path='/auctions/:number'
                        render={ () => <AuctionInfoTab /> }
                    />
                    <Route exact path='/auctions/:number/questions'
                        render={ () => <Chat /> }
                    />
                    <Route exact path='/auctions/:number/auctionResult'
                        render={ () => <AuctionResultTab /> }
                    />
                </Switch>
            )
        }
    }else{
        viewTmp = <PopUpLoader />
    }
    return (
        <div className={styles.lotWrap} >
            <div className={styles.lotContent}>
                {viewTmp}
            </div>
        </div>
    )
}

async function combineSetData(props, aucId){
    let tmpData = {}
    tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    tmpData.body = {}
    tmpData.body.method = "GET"
    tmpData.body.headers = getDefaultHeaders(props)
    let tmpResponse = await backendAPI(tmpData)
    let tmpResponse2 = await errorAPI(tmpResponse)
    if(tmpResponse2.hasOwnProperty('message') === true){
        let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(tmpResponse2)})
        props.setUniversalError(tmp)
    }else{
        if(tmpResponse2 !== null && !Number(tmpResponse2)){
            if(tmpResponse2.awards !== null){
                if(tmpResponse2.awards.length > 0){
                    if(tmpResponse2.awards[0].id === null){
                        tmpData.endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${tmpResponse2.id}/sync?` + Math.random()
                        let tmpResponse3 = await backendAPI(tmpData)
                        tmpResponse2 = await errorAPI(tmpResponse3)
                    }
                }
            }
        }
        await props.setDataAddress(tmpResponse2, 'SET_FULL_AUCTION')
    }
}

function setBaseUrl(){
    if(window.location.pathname.indexOf('/auctions/') !== -1){
        return window.location.pathname.split('/auctions/');
    }
    return null
}

function setPageId(){
    let tmp = setBaseUrl()
    if(tmp !== null){
        return tmp[1].split('/')
    }
    return null
}

function setStatePageNumber(props){
    let tmp = setPageId()
    if(tmp !== null){
        if(props.pageNumber === null){
            props.changePageNumber(tmp[0])
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        history: state.start.history,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        historyDocsContracts: state.start.historyDocsContracts,
        auctionsAllBids: state.start.auctionsAllBids,
        //auctionFull: state.start.auctionFull,
        auctionFullId: state.start.auctionFullId,
        bidSumm: state.start.bidSumm,
    }
}

const mapDispatchToProps = {
    changePageNumber,
    auctionGetId,
    auctionGetIdToken,
    auctionsBidsGetId,
    setLogo,
    auctionsGetAwards,
    contractsAuctionIdGet,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    saveArrBids,
    saveHistoryDocsContracts,
    setDataAddress,

}

export default connect(mapStateToProps, mapDispatchToProps)(Lot)
