import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

const TextHalf = (props) => {
    return (
        <div className={styles.lotHalf}>
            <div className={styles.desc}>{props.title}</div>
            <div><a href={props.data} target='blank'>{props.text}</a></div>
        </div>
    )
}

export default connect(null, null)(TextHalf)
