import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

const IsPerishableString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('isPerishable') === true){
            if(props.auctionId.isPerishable !== null){
                let tmp
                if(props.auctionId.isPerishable === true){
                    tmp = 'Так'
                }else{
                    tmp = 'Ні'
                }
                return (
                    <TextFull
                        title="Майно, що швидко псується"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(IsPerishableString)