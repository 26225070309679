import React from 'react';
import style from './mobMenu.module.css';

import {
    CURRENT_AUCTIONS,
    ALL_AUCTIONS,
    REGISTRY_OF_OBJECTS_OF_PRIVATIZATION,
    REGISTRY_OF_LOTS_OF_PRIVATIZATION,
    COMPLETION_OF_PRIVATIZATION,
    MAP_OF_OBJECTS_OF_PRIVATIZATION
} from '../../model/lang/secondMenu.js';

import {connect} from 'react-redux';
import {changeMenu} from '../../redux/actions.js';

const MobMenu = ( {langFlag, changeMenu} ) => {
    return (
        <div className={style.mobMenu_wrap}>
            <div className={style.mobMenu_leftBlock}>
                <div
                    onClick={() => {changeMenu(false)}}
                >
                    Кнопка закрітия
                </div>
                <div className={style.auktionmenublok}>{CURRENT_AUCTIONS[langFlag]}</div>
                <div className={style.auktionmenublok_off}>{ALL_AUCTIONS[langFlag]}</div>
                <div className={style.auktionmenublok_off}>{REGISTRY_OF_OBJECTS_OF_PRIVATIZATION[langFlag]}</div>
                <div className={style.auktionmenublok_off}>{REGISTRY_OF_LOTS_OF_PRIVATIZATION[langFlag]}</div>
                <div className={style.auktionmenublok_off}>{COMPLETION_OF_PRIVATIZATION[langFlag]}</div>
                <div className={style.auktionmenublok_off}>{MAP_OF_OBJECTS_OF_PRIVATIZATION[langFlag]}</div>
            </div>
            <div
                className={style.mobMenu_rightBlock}
                onClick={() => {changeMenu(false)}}
            >
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag
    }
}

const mapDispatchToProps = {
    changeMenu
  }

export default connect(mapStateToProps, mapDispatchToProps)(MobMenu)