export function setTenderAttemptList(){
    return [
            {value: 'null', name: 'Обрати', selected: true},
            {value: 1, name: 'Вперше'},
            {value: 2, name: 'Вдруге'},
            {value: 3, name: 'Втретє'},
            {value: 4, name: 'Вчетверте'},
            {value: 5, name: 'Вп`яте'},
            {value: 6, name: 'Вшосте'},
            {value: 7, name: 'Всьоме'},
            {value: 8, name: 'Ввосьме'},
            {value: 9, name: 'Вдев`яте'},
            {value: 10, name: 'Вдесяте'},
            {value: 11, name: 'Невідомо'},
        ]
}

export function setTenderAttemptListBREBRD(){
    return [
            {value: 'null', name: 'Обрати', selected: true},
            {value: 1, name: 'Вперше'},
            {value: 2, name: 'Вдруге'},
            {value: 3, name: 'Втретє'},
        ]
}

export function setDutchStepQuantityList(data=100){
    let tmp = []
    if(data === 100){
        for (let i = 1; i < data; i++ ){
            tmp.push({name: i, value: i})
        }
    } else if(data === 0.1){
        for (let i = 1; i < 1000; i++ ){
            tmp.push({name: i, value: i})
        }
    }else{
        for (let i = 1; i < 100; i++ ){
            tmp.push({name: i, value: i})
        }
    }
    return tmp
}