import procedure from "../../../../redux/procedure";

// EDIT_BUTTON
export function viewAucEditDoc(props) {
    if (props.scope.aucStatus === null || props.scope.aucStatus === "draft") {
        return true
    }
    if (props.scope.aucStatus === "active_rectification" || procedure.inPeriod('rectificationPeriod', props.auction)) {
        return true
    }

    if (props.scope.aucStatus === 'active_tendering') { //TODO: CSE, CSD - isPerishable не прилетает???
        return true
    }
    if (props.scope.aucStatus === 'active_qualification' || props.scope.aucStatus === 'active_awarded') {
        if (procedure.isBRW(props.scope.aucType) || procedure.isLAW(props.scope.aucType)) {
            return true
        }
    }
    if (procedure.isREM(props.scope.aucType)) {
        if (props.data.documentType === 'x_verificationAct') return true
        if (props.data.documentType === 'clarifications') return true
    }
    return false
}

// DELETE_BUTTON
export function viewAucDeleteDoc(props) {
    return props.scope.aucStatus === null || props.scope.aucStatus === 'draft'
}

export async function deleteAucDocuments(props) {
    props.changeLoader(true)
    const endPoint = `${props.scope.deleteURL}/${props.data.id}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)
    if (response === undefined || response === 'undefined') {
        props.changeLoader(false)
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            props.changeLoader(false)
        } else {
            await props.scope.reload()
            await props.changeLoader(false)
        }
    }
}