import React from 'react';
import styles from './partialPayment.module.css';

export default function PartialPayment(props){
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__title}>Чи внесена часткова оплата?</div>
            <div className={styles.buttons}>
                {viewButton(styles.btn + ' ' + styles.accept, props.requestBody, true, 'Так')}
                {viewButton(styles.btn + ' ' + styles.decline, props.requestBody, false, 'Ні')}
            </div>
        </div>
    )
}

function viewButton(style, obj, status, name){
    return (
        <div className={style}
            onClick={
                () => {
                    obj.dataFunction(obj.dataProps, obj.dataPropsI, status)
                }
            }
        >
            {name}
        </div>
    )
}