import React, { useState, useEffect } from 'react';
import styles from './arrests.module.css';

import Button from '../../../../elements/buttons/button';
import ArrestsItem from './arrestsItem';

const Arrests = ({arrests, setArrests}) => {
    const [arrest, setArrest] = useState(arrests !== null && arrests.length > 0 ? arrests : null);
    
    useEffect(() => {
        setArrests(arrest)
    }, [arrest])

    function viewArrests(){
        if(arrest === null) return null
        if(arrest.length === 0) return null
        return arrest
        .map(
            (i, count) => { 
                return <ArrestsItem arrest={arrest} setArrest={setArrest} setArrests={setArrests} item={i} count={count} />
            }
        )
    }

    return (
        <div className={styles.bankBlock}>
            <div className={styles.bankBlockTitle}>Інформація про арешти *</div>
            <div style={{width:"100%"}}>
                {viewArrests()}
            </div>
            <Button
                height={'50px'}
                font={'16px'}
                color='blue'
                data={'Додати новий запис'}
                onClick={
                    () =>{
                        let tmp = []
                        if(arrest === null){
                            tmp[0] = {
                                statementID: null,
                                restrictionDate: null,
                                restrictionOrganization: null,
                                isRemovable: false,
                                status: false
                            }
                        }else{
                            tmp = [...arrest]
                            tmp.push({
                                statementID: null,
                                restrictionDate: null,
                                restrictionOrganization: null,
                                isRemovable: false,
                                status: false
                            })
                        }
                        setArrest(null)
                        setArrest(tmp)
                    }
                }
            />
        </div>
    )
}

export default Arrests