export function checkingFilledFields(data, onOffCurrentTenat){
    let tmpStatus = null;
    if(data !== null){
        switch(data.auctionsMy.sellingMethod){
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
            case "subsoil-dutch":
            case "subsoil-dutch-fast":
            case "subsoil-dutch-fast-manual":
            case "subsoil-dutch-initial-auction":
            case "subsoil-dutch-initial-qualification":
            case "subsoil-dutch-initial-auction-manual":
            case "subsoil-english-fast-auction-manual-qualification":
                if(data !== null){
                    if(
                        data.auctionsMy.lotIdentifier === null ||
                        data.auctionsMy.lotIdentifier === '' ||
                        data.auctionsMy.lotIdentifier === 'null' ||
                        data.auctionsMy.title === null ||
                        data.auctionsMy.title === '' ||
                        data.auctionsMy.title === 'null' ||
                        data.auctionsMy.description === null ||
                        data.auctionsMy.description === '' ||
                        data.auctionsMy.description === 'null' ||
                        data.auctionsMy.tenderAttempts === null ||
                        data.auctionsMy.tenderAttempts === '' ||
                        data.auctionsMy.tenderAttempts === 'null' ||
                        data.auctionsMy.accessDetails === null ||
                        data.auctionsMy.accessDetails === '' ||
                        data.auctionsMy.accessDetails === 'null' ||
                        data.auctionsMy.xAdditionalInformation === null ||
                        data.auctionsMy.xAdditionalInformation === '' ||
                        data.auctionsMy.xAdditionalInformation === 'null' ||
                        data.auctionsMy.sellingMethod === null ||
                        data.auctionsMy.sellingMethod === '' ||
                        data.auctionsMy.sellingMethod === 'null' ||
                        data.auctionsMy.value === null ||
                        data.auctionsMy.value.amount === null ||
                        data.auctionsMy.value.amount === '' ||
                        data.auctionsMy.value.amount === 'null' ||
                        data.auctionsMy.minimalStep === null ||
                        data.auctionsMy.minimalStep.amount === null ||
                        data.auctionsMy.minimalStep.amount === '' ||
                        data.auctionsMy.minimalStep.amount === 'null' ||
                        data.auctionsMy.startDate === null ||
                        data.auctionsMy.startDate === '' ||
                        data.auctionsMy.startDate === 'null' ||
                        data.bankAccountId === null ||
                        data.bankAccountId === '' ||
                        data.bankAccountId === 'null'
                    ){
                        tmpStatus = 'disabled'
                    }
                }
                break;
            case "legitimatePropertyLease-english":
            case "legitimatePropertyLease-english-fast":
            case "legitimatePropertyLease-english-fast-manual":
            case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            case "legitimatePropertyLease-english-initial-auction":
            case "legitimatePropertyLease-english-initial-auction-manual":
            case "legitimatePropertyLease-english-initial-qualification":
            case "legitimatePropertyLease-english-initial-qualification-prod":
            case "legitimatePropertyLease-dutch":
            case "legitimatePropertyLease-dutch-fast":
            case "legitimatePropertyLease-dutch-fast-manual":
            case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            case "legitimatePropertyLease-dutch-initial-auction":
            case "legitimatePropertyLease-dutch-initial-auction-manual":
            case "legitimatePropertyLease-dutch-initial-qualification-prod":
            case "legitimatePropertyLease-priorityEnglish":
            case "legitimatePropertyLease-priorityEnglish-fast":
            case "legitimatePropertyLease-priorityEnglish-fast-manual":
            case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-auction":
            case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                break;
            case 'landRental-english':
            case 'landRental-english-fast':
            case 'landRental-english-fast-manual':
            case 'landRental-english-fast-auction-manual-qualification':
            case 'landRental-english-initial-auction':
            case 'landRental-english-initial-auction-manual':
            case 'landRental-english-initial-qualification':
            case 'landRental-english-initial-qualification-prod':
                break;
            case 'landSell-english':
            case 'landSell-english-fast':
            case 'landSell-english-fast-manual':
            case 'landSell-english-fast-auction-manual-qualification':
            case 'landSell-english-initial-auction':
            case 'landSell-english-initial-auction-manual':
            case 'landSell-english-initial-qualification':
            case 'landSell-english-initial-qualification-prod':
                break;
            case 'landSell-priorityEnglish':
            case 'landSell-priorityEnglish-fast':
            case 'landSell-priorityEnglish-fast-manual':
            case 'landSell-priorityEnglish-fast-auction-manual-qualification':
            case 'landSell-priorityEnglish-initial-auction':
            case 'landSell-priorityEnglish-initial-auction-manual':
            case 'landSell-priorityEnglish-initial-qualification':
            case 'landSell-priorityEnglish-initial-qualification-prod':
                if(data !== null){
                    return onOffCurrentTenat(data.auctionsMy)
                }
            default:
                if(data !== null){
                    if(
                        data.auctionsMy.lotIdentifier === null ||
                        data.auctionsMy.lotIdentifier === '' ||
                        data.auctionsMy.lotIdentifier === 'null' ||
                        data.auctionsMy.title === null ||
                        data.auctionsMy.title === '' ||
                        data.auctionsMy.title === 'null' ||
                        data.auctionsMy.description === null ||
                        data.auctionsMy.description === '' ||
                        data.auctionsMy.description === 'null' ||
                        data.auctionsMy.tenderAttempts === null ||
                        data.auctionsMy.tenderAttempts === '' ||
                        data.auctionsMy.tenderAttempts === 'null' ||
                        data.auctionsMy.accessDetails === null ||
                        data.auctionsMy.accessDetails === '' ||
                        data.auctionsMy.accessDetails === 'null' ||
                        data.auctionsMy.xAdditionalInformation === null ||
                        data.auctionsMy.xAdditionalInformation === '' ||
                        data.auctionsMy.xAdditionalInformation === 'null' ||
                        data.auctionsMy.sellingMethod === null ||
                        data.auctionsMy.sellingMethod === '' ||
                        data.auctionsMy.sellingMethod === 'null' ||
                        data.auctionsMy.value === null ||
                        data.auctionsMy.value.amount === null ||
                        data.auctionsMy.value.amount === '' ||
                        data.auctionsMy.value.amount === 'null' ||
                        data.auctionsMy.guarantee === null ||
                        data.auctionsMy.guarantee.amount === null ||
                        data.auctionsMy.guarantee.amount === '' ||
                        data.auctionsMy.guarantee.amount === 'null' ||
                        data.auctionsMy.minimalStep === null ||
                        data.auctionsMy.minimalStep.amount === null ||
                        data.auctionsMy.minimalStep.amount === '' ||
                        data.auctionsMy.minimalStep.amount === 'null' ||
                        data.auctionsMy.startDate === null ||
                        data.auctionsMy.startDate === '' ||
                        data.auctionsMy.startDate === 'null' ||
                        data.bankAccountId === null ||
                        data.bankAccountId === '' ||
                        data.bankAccountId === 'null'
                    ){
                        tmpStatus = 'disabled'
                    }
                }
                break;
        }
    }
    if(data !== null){
        tmpStatus = checkStatusAuctions(data)
    }
    return tmpStatus
}

function checkStatusAuctions(data){
    if(data.auctionsMy.status !== null){
        if(data.auctionsMy.status !== 'active_rectification'){
            let tmpStartData = null
            let tmpEndData = null
            if(data.hasOwnProperty('auctionPeriods')){
                for(let tt = 0; tt < data.auctionsMy.auctionPeriods.length; tt++){
                    if(data.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                        tmpStartData = new Date(data.auctionsMy.auctionPeriods[tt].startDate).getTime()
                        tmpEndData = new Date(data.auctionsMy.auctionPeriods[tt].endDate).getTime()
                    }
                }
            }else{
                if(data.auctionsMy.hasOwnProperty('rectificationPeriod')){
                    if(data.auctionsMy.rectificationPeriod !== null){
                        tmpStartData = new Date(data.auctionsMy.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(data.auctionsMy.rectificationPeriod.endDate).getTime()
                    }
                }
            }
            if(
                (data.auctionsMy.sellingMethod === 'basicSell-english' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-fast' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-fast-manual' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-initial-auction' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'alienation-english' ||
                data.auctionsMy.sellingMethod === 'alienation-english-fast' ||
                data.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
                data.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
                data.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-fast' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-fast-manual' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-fast' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-manual' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification-prod' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                data.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod'
                ) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                return null
            }else{
                return 'disabled'
            }
        }
    }
    return null
}