import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const SaleCondition = (props) => {
        if(props?.auctionId?.hasOwnProperty('saleCondition') === true){
            return (
                <TextHalf
                    title="Наявність умов продажу"
                    data={typeSaleCondition(props.auctionId.saleCondition)}
                />
            )
        }
    return null
}

function typeSaleCondition(data){
    switch(data){
        case "yes": return "Так"
        case "no": return "Ні"
        case "notSpecified": return "Не визначено"
        default: return "Не визначено"
    }
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(SaleCondition)