import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import procedure from '../../../../redux/procedure.js';

import {viewStringValue} from '../../../../api/valueToString.js'

const MinimalStepString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.minimalStep === null) return null
    let tmp = viewStringValue(props.auctionId.minimalStep)
    return (
        <TextFull
            title={ title( props.auctionId.sellingMethod ) }
            data={ tmp }
        />
    )
}

function title(data) {
    switch (true) {
        case procedure.isGFE(data):
        case procedure.isGFD(data):
            return "Мінімальний крок аукціону"
        case procedure.isLRE(data):
        case procedure.isLSE(data):
        case procedure.isLSP(data):
        case procedure.isRLE(data):
        case procedure.isRLD(data):
        case procedure.isCLE(data):
        case procedure.isCLD(data):
        case procedure.isLAE(data):
        case procedure.isLAP(data):
            return "Мінімальний крок"
        default:
            return "Розмір кроку аукціону"

    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(MinimalStepString)