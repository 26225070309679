import { checkProp } from "../../../../redux/check"
// EDIT BTN
export function viewAwardEditDoc(props) {
    if (props.data.documentOf === 'award') {
        if (props.scope.awardStatus !== 'unsuccessful'){
            if (props.scope.aucOwner === true) {
                if(props.data.documentType === "auctionProtocol" && props.scope.awardStatus !== 'pending') return false
                return true
            }
        }
    }
    if (props.scope.awardStatus === 'pending' || props.scope.awardStatus === 'pending_admission' || props.scope.awardStatus === 'verification') {
        if (props.data.documentOf === 'bid') {
            if (props.scope.bidOwner === true ) return true
        }
    }
    if (props.scope.bidOwner === true || props.scope.isAdmin === true) {
        if ( props.scope.awardStatus === 'pending_admission') {
            if (props.data.documentOf === 'bid') {
                if(props.data.documentType === "auctionProtocol") return true
            }
        }
    }
    return false
}

// Cоздать УРЛЫ для КЕП
// export function createKEPUrl(props) {
//     let tmpTypeOf = null
//     let tmpUrlNew = null
//     let tmpUrl = null
//     let tmpUrlKep = null
//     let tmpRelatedDocument = null
//     if(props.popUpContractUniversal.type === 'auctionProtocol'){
//         if(props.userProfiles.canCreateBid === true){
//             tmpTypeOf = 'bid'
//             if(props.popUpContractUniversal.id === null){
//                 tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents`
//             }else{
//                 tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
//                 tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents`
//                 if(props.auctionAwards !== null){
//                     if(props.auctionAwards.length > 0){
//                         for(let i = 0; i < props.auctionAwards.length; i++){
//                             if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
//                                 if(props.auctionAwards[i].bid !== null){
//                                     if(props.auctionAwards[i].bid.documents !== null){
//                                         if(props.auctionAwards[i].bid.documents.length > 0){
//                                             for(let t = 0; t < props.auctionAwards[i].bid.documents.length; t++){
//                                                 if(props.auctionAwards[i].bid.documents[t].id === props.popUpContractUniversal.id){
//                                                     tmpRelatedDocument = props.auctionAwards[i].bid.documents[t].documentId
//                                                 }
//                                             }
//                                             if(tmpRelatedDocument !== null){
//                                                 for(let d = 0; d < props.auctionAwards[i].bid.documents.length; d++){
//                                                     if(props.auctionAwards[i].bid.documents[d].relatedDocument === tmpRelatedDocument){
//                                                         tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.auctionAwards[i].bid.documents[d].id}`
//                                                     }
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//                 //tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/bid/${props.popUpContractApprowe.currentUser.bid.id}/documents/${props.popUpContractUniversal.id}`
//             }
//         }else{
//             tmpTypeOf = 'award'
//             if(props.popUpContractUniversal.id === null){
//                 tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
//             }else{
//                 tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
//                 tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents`
//                 if(props.auctionAwards !== null){
//                     if(props.auctionAwards.length > 0){
//                         for(let i = 0; i < props.auctionAwards.length; i++){
//                             if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
//                                 if(props.auctionAwards[i].documents !== null){
//                                     if(props.auctionAwards[i].documents.length > 0){
//                                         for(let t = 0; t < props.auctionAwards[i].documents.length; t++){
//                                             if(props.auctionAwards[i].documents[t].id === props.popUpContractUniversal.id){
//                                                 tmpRelatedDocument = props.auctionAwards[i].documents[t].documentId
//                                             }
//                                         }
//                                         if(tmpRelatedDocument !== null){
//                                             for(let d = 0; d < props.auctionAwards[i].documents.length; d++){
//                                                 if(props.auctionAwards[i].documents[d].relatedDocument === tmpRelatedDocument){
//                                                     tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.auctionAwards[i].documents[d].id}`
//                                                 }
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }else{
//         tmpTypeOf = 'award'
//         if(props.popUpContractUniversal.id === null){
//             tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.contractId}/documents`
//         }else{
//             tmpUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.popUpContractUniversal.id}`
//             tmpUrlNew = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents`
//             if(props.auctionAwards !== null){
//                 if(props.auctionAwards.length > 0){
//                     for(let i = 0; i < props.auctionAwards.length; i++){
//                         if(props.auctionAwards[i].id === props.popUpContractApprowe.currentUser.id){
//                             if(props.auctionAwards[i].documents !== null){
//                                 if(props.auctionAwards[i].documents.length > 0){
//                                     for(let t = 0; t < props.auctionAwards[i].documents.length; t++){
//                                         if(props.auctionAwards[i].documents[t].id === props.popUpContractUniversal.id){
//                                             tmpRelatedDocument = props.auctionAwards[i].documents[t].documentId
//                                         }
//                                     }
//                                     if(tmpRelatedDocument !== null){
//                                         for(let d = 0; d < props.auctionAwards[i].documents.length; d++){
//                                             if(props.auctionAwards[i].documents[d].relatedDocument === tmpRelatedDocument){
//                                                 tmpUrlKep = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.popUpContractApprowe.aucId}/award/${props.popUpContractApprowe.currentUser.id}/documents/${props.auctionAwards[i].documents[d].id}`
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     return {
//         urlNew: tmpUrlNew,
//         url: tmpUrl,
//         urlKep: tmpUrlKep
//     }
// }

export function createAwardKEPUrl(props){
    let tmpUrlNew = null
    let tmpUrl = null
    let tmpUrlKep = null
    // let tmpRelatedDocument = null

    if (props.scopeData.bidOwner) {
        if (checkProp(props.docData, 'id')) {
                tmpUrl = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
                tmpUrlNew = props.scopeData.uploadNewDocURL
                if (checkProp(props.scopeData, 'bidDocList') && props.scopeData.bidDocList.length > 0) {
                    for (let key in props.scopeData.bidDocList) {
                        if (props.scopeData.bidDocList[key].relatedDocument === props.docData.id) {
                            // tmpRelatedDocument = props.scopeData.docList[key].documentId
                            tmpUrlKep = `${props.scopeData.patchNewDocFilesURL}/${props.scopeData.docList[key].id}`
                        }
                    }
                }
        } else {
            tmpUrlNew = props.scopeData.uploadNewDocURL
        }
    }
    if (props.scopeData.aucOwner) {
        if (checkProp(props.docData, 'id')) {
            tmpUrl = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
            tmpUrlNew = props.scopeData.uploadNewDocURL
            if (checkProp(props.scopeData, 'docList') && props.scopeData.docList.length > 0) {
                for (let key in props.scopeData.docList) {
                    if (props.scopeData.docList[key].relatedDocument === props.docData.id) {
                        // tmpRelatedDocument = props.scopeData.docList[key].documentId
                        tmpUrlKep = `${props.scopeData.patchNewDocFilesURL}/${props.scopeData.docList[key].id}`
                    }
                }
            }
        } else {
            tmpUrlNew = props.scopeData.uploadNewDocURL
        }
    }
    return {
        urlNew: tmpUrlNew,
        url: tmpUrl,
        urlKep: tmpUrlKep
    }
}