import React  from 'react'
import styles from './ecpItem.module.css'

import TextHalf from '../../elements/viewData/textHalf'
import { dateTransform } from '../../../redux/modifiDate'

const EcpItem = (props) => {
    
    let keySvg = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path d="M14.2187 6.72106C14.9091 6.72106 15.4687 6.16142 15.4687 5.47106C15.4687 4.78071 14.9091 4.22107 14.2187 4.22107C13.5284 4.22107 12.9688 4.78071 12.9688 5.47106C12.9688 6.16142 13.5284 6.72106 14.2187 6.72106Z" fill="#00B191"/>
                    <path d="M19.7842 6.48245C19.367 5.42019 18.602 3.8875 17.3266 2.61813C16.0557 1.35313 14.5393 0.610009 13.4909 0.209307C12.34 -0.230574 11.0321 0.0466905 10.1591 0.915632L7.09122 3.96914C6.18181 4.87425 5.9197 6.26698 6.43896 7.43475C6.59997 7.79693 6.77509 8.15119 6.9622 8.49345L0.228827 15.2268C0.0823044 15.3733 0 15.572 0 15.7792V19.2167C0 19.6482 0.349764 19.998 0.781247 19.998H4.21873C4.65022 19.998 4.99998 19.6482 4.99998 19.2167V17.8105L6.40622 17.8104C6.83771 17.8104 7.18747 17.4607 7.18747 17.0292V15.623H8.59372C9.0252 15.623 9.37496 15.2732 9.37496 14.8418C9.37496 14.4103 9.0252 14.0605 8.59372 14.0605H6.40622C5.97474 14.0605 5.62498 14.4103 5.62498 14.8418V16.248L4.21873 16.248C3.78725 16.248 3.43749 16.5978 3.43749 17.0292V18.4355H1.56249V16.1028L8.4904 9.17498C8.74493 8.92044 8.79262 8.52502 8.60594 8.21728C8.3352 7.77104 8.08649 7.29416 7.86677 6.79991C7.60899 6.22018 7.74032 5.52765 8.19356 5.07651L11.2614 2.02305C11.6994 1.58715 12.3556 1.44813 12.9332 1.66883C13.8403 2.01555 15.1476 2.65375 16.2244 3.72554C17.3078 4.80382 17.9674 6.1305 18.3299 7.05354C18.5561 7.62963 18.4186 8.28412 17.9797 8.72099L14.8748 11.8114C14.4226 12.2613 13.7478 12.3929 13.1554 12.1465C12.6669 11.9433 12.1937 11.7019 11.7492 11.4291C11.3815 11.2034 10.9004 11.3185 10.6747 11.6863C10.449 12.054 10.5641 12.5351 10.9318 12.7608C11.4456 13.0761 11.9918 13.3548 12.5553 13.5892C13.7328 14.079 15.0758 13.8157 15.977 12.9188L19.0819 9.82849C19.9626 8.95197 20.2382 7.63857 19.7842 6.48245Z" fill="#00B191"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>

    
    let dowldSvg = <a href={props.data.url} download>
                    <div className={styles.btnDwnld} title='Завантажити підписаний документ'>
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.dnldSvg} fillRule="evenodd" clipRule="evenodd" d="M4.19941 0.499878C2.10073 0.499878 0.399414 2.2012 0.399414 4.29988V15.6999C0.399414 17.7986 2.10073 19.4999 4.19941 19.4999H11.7994C13.8981 19.4999 15.5994 17.7986 15.5994 15.6999V6.91642C15.5994 6.48096 15.4498 6.05871 15.1757 5.72036L11.5165 1.20381C11.1557 0.758537 10.6132 0.499878 10.0402 0.499878H4.19941ZM11.4432 4.13156V5.62988H12.6571L11.4432 4.13156ZM4.19941 2.39988C3.15007 2.39988 2.29941 3.25054 2.29941 4.29988V15.6999C2.29941 16.7492 3.15007 17.5999 4.19941 17.5999H11.7994C12.8488 17.5999 13.6994 16.7492 13.6994 15.6999V7.52988H11.4432C10.3938 7.52988 9.54316 6.67922 9.54316 5.62988V2.39988H4.19941ZM8.94941 12.3749L9.32941 12.0899C9.74915 11.7751 10.3446 11.8601 10.6594 12.2799C10.9742 12.6996 10.8891 13.2951 10.4694 13.6099L8.57709 15.0291C8.41702 15.1519 8.21674 15.2249 7.99941 15.2249C7.78209 15.2249 7.58181 15.1519 7.42174 15.0291L5.52941 13.6099C5.10968 13.2951 5.02461 12.6996 5.33941 12.2799C5.65422 11.8601 6.24968 11.7751 6.66941 12.0899L7.04941 12.3749V8.68071C7.04941 8.15604 7.47474 7.73071 7.99941 7.73071C8.52408 7.73071 8.94941 8.15604 8.94941 8.68071V12.3749Z" fill="#92929D"/>
                        </svg>
                    </div>
                </a>

    
    return (
        <div className={styles.ecpWrap}>
            <div className={styles.ecpTitle}>
                <div className={styles.keyBlock}>{keySvg}</div>
                <div className={styles.docSigned}>Документ підписано</div>
                {dowldSvg}
            </div>
            <div className={styles.ecpContent}>
                <TextHalf
                    title={<>Версія документа, що підписаний КЕП<br/>iD прозорро</>}
                    data={props.data.relatedDocument}
                />
                <TextHalf
                    title='Тип документу'
                    data={'Документ з КЕП'}
                />
                <TextHalf
                    title='Документ опубліковано'
                    data={dateTransform(props.data.datePublished, 'time')}
                />
                <TextHalf
                    title='Дата останнього оновлення'
                    data={dateTransform(props.data.dateModified, 'time')}
                />
            </div>
        </div>
    )
}

export default EcpItem