import React, { useEffect } from 'react';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress'

import Select from '../../elements/inputs/inputSelect';
import { getOptions } from '../../../redux/check'; //TODO: ПЕРЕДАЛАТЬ НА ПО-НОРМАЛЬНОМУ

import { isLLE, isLLD, isLLP } from '../../../redux/procedure';

import {disabledElement} from '../disabled'

const MartialLawDisclaimer = ( props ) => {
    let tmp = null;

    useEffect(() => {
        if(
            props.auctionsMy.martialLawDisclaimer !== false
        ){
            props.setDataAddress(true, 'CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER')
        }
    }, [])
    if(props.auctionsMy !== null && props.auctionsMy.martialLawDisclaimer === null){
        props.setDataAddress(true, 'CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER')
    }

    
    if(
        props.auctionsMy !== null
        && (isLLE(props.auctionsMy.sellingMethod)
        || isLLD(props.auctionsMy.sellingMethod)
        || isLLP(props.auctionsMy.sellingMethod))
    ){
        tmp = <Select
            disabled={disabledElement(props.auctionsMy, '00')}
            data={{
                label: "Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)",
                target: props.auctionsMy.martialLawDisclaimer,
                // info: 'оберіть основний класифікатор лоту'

            }}
            onChange={(event)=>{
                props.setDataAddress(event, 'CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER')
            }}
            options={getOptions(legitimatePropertyLeaseTenderAttempts())}
        />
    }
    return tmp
}

function legitimatePropertyLeaseTenderAttempts(){
    return (
        <>
            <option key={1} value={true}>Так</option>
            <option key={2} value={false}>Ні</option>
        </>
    )
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(MartialLawDisclaimer)
