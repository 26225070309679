import React, { useState } from 'react';
import styles from './inputInfoBlock.module.css'

const InputInfo = ({ data }) => {
    const [info, setInfo] = useState(false)

    function toggle() {
        setInfo(!info)
    }

    return <>
                    <div className={styles.infoIcon} onClick={toggle}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.svgInfo} d="M11 14.0836L11 9.08362C11 8.53133 10.5523 8.08362 10 8.08362C9.44772 8.08362 9 8.53133 9 9.08362L9 14.0836C9 14.6359 9.44772 15.0836 10 15.0836C10.5523 15.0836 11 14.6359 11 14.0836Z" fill="#92929D"/>
                            <path className={styles.svgInfo} d="M9 6.08362C9 5.53133 9.44772 5.08362 10 5.08362C10.5523 5.08362 11 5.53133 11 6.08362C11 6.6359 10.5523 7.08362 10 7.08362C9.44772 7.08362 9 6.6359 9 6.08362Z" fill="#92929D"/>
                            <path className={styles.svgInfo} fillRule="evenodd" clipRule="evenodd" d="M8.74228e-07 10.0836C1.35705e-06 4.56077 4.47715 0.0836168 10 0.0836173C15.5228 0.0836178 20 4.56077 20 10.0836C20 15.6065 15.5228 20.0836 10 20.0836C4.47715 20.0836 3.91405e-07 15.6065 8.74228e-07 10.0836ZM18 10.0836C18 5.66534 14.4183 2.08362 10 2.08362C5.58172 2.08362 2 5.66534 2 10.0836C2 14.5019 5.58172 18.0836 10 18.0836C14.4183 18.0836 18 14.5019 18 10.0836Z" fill="#92929D"/>
                        </svg>
        </div>
        {info ? <>
                    <div className={styles.wrapperInfo} onClick={toggle}></div>
                    <div className={styles.inputInfoBlock}>
                        <p>{data}</p>
                        <div className={styles.bottomBtnBlock}>
                            <div className={styles.inputInfoBlockBtn} onClick={toggle}>
                                Зрозуміло
                            </div>
                        </div>
                    </div>
                </> : null
        }
    </>
}
export default InputInfo