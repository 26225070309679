import React from 'react';
import styles from './legacy.module.css';

import {Link} from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchCount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {changeMyCurentPage} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress.js';

const Legacy = (
    {
        langFlag,
        aucyionsPath,
        search,
        token,
        auctionsSearch,
        setAuctionCategory,
        changeCurentPage,
        changeMyCurentPage,
        clearSearch
    }) => {
    let active = null;
    if (search.category === 'legacy') {
        active = styles.active;
    }
    return (
        <div className={styles.aukpoisk_second_elem}>
            <Link
                onClick={
                    () => {
                        changeMyCurentPage(0)
                        changeCurentPage(0);
                        setAuctionCategory('legacy');
                        let dataSearch = {}
                        dataSearch.isLegacy = true
                        dataSearch.category = 'legacy'
                        dataSearch.order = search.order
                        dataSearch.region = search.region
                        dataSearch.priceCurrency = search.priceCurrency
                        dataSearch.priceFrom = search.priceFrom
                        dataSearch.priceTo = search.priceTo
                        dataSearch.measures = search.measures
                        dataSearch.measuresFrom = search.measuresFrom
                        dataSearch.measuresTo = search.measuresTo
                        // dataSearch.status = (search.status === null || search.status === '') ? [] : search.status
                        let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: langFlag,
                            params: '?offset=0'
                        }
                        if (token !== null) {
                            let header = {
                                'Authorization': token.access_token,
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        } else {
                            let header = {
                                'Content-Type': 'application/json'
                            }
                            auctionsSearch(data, header, dataSearch);
                        }
                        clearSearch()
                    }
                }
                to={`${aucyionsPath}/legacy${getUrlFiltersParam(search, 'legacy')}`}
            >
                <div className={styles.base + ' ' + active}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         viewBox="0 0 1024 1024" xmlSpace="preserve">
                        <path fill="white" d="M0 0L1024 0L1024 1024L0 1024L0 0Z"/>
                        <path
                            d="M60.4675 84.6709C70.3879 83.6465 81.1422 84.5549 91.1528 84.5662L153.108 84.5185L270.901 84.561C289.8 84.5905 308.856 84.0325 327.739 84.6348C330.939 84.7369 333.94 85.0638 336.994 86.1244C342.546 88.0522 346.866 91.3714 350.47 95.9936C356.176 103.31 359.767 113.13 363.745 121.524L382.998 162.447L726.702 162.398L827.208 162.494L855.982 162.474C862.386 162.467 868.992 162.151 875.35 163.02C885.328 164.385 894.446 169.18 901.531 176.295C907.543 182.332 912.494 190.727 914.057 199.164C915.588 207.429 915.01 216.427 914.988 224.808L914.926 261.352L914.966 395.048L943.686 395.012C950.896 395.009 958.306 394.694 965.447 395.804C977.02 397.603 987.423 403.747 994.324 413.246C1000.22 421.367 1003.05 430.999 1002.63 441.006C1002.33 448.331 1000.75 455.68 999.414 462.874L993.835 492.466L977.121 582.177C958.045 683.97 938.826 785.81 922.131 888.026C920.757 896.541 919.184 904.275 914.257 911.538C907.683 921.229 897.552 927.647 886.053 929.728C876.997 931.366 866.949 930.559 857.743 930.556L810.495 930.554L629.558 930.571L249.783 930.608L128.071 930.786C106.22 930.817 84.1571 931.41 62.3304 930.617C53.7557 930.305 45.9704 927.988 38.9413 922.961C30.6935 917.063 23.891 907.067 22.3546 896.931C20.9177 887.451 21.8347 876.799 21.8565 867.197L21.8032 814.723L21.668 638.936L21.933 318.129L21.8846 190.782C21.9039 170.047 21.0648 147.402 22.0258 126.892C22.4581 117.664 24.9343 109.44 30.4693 101.97C37.9441 91.8832 48.1847 86.4338 60.4675 84.6709Z"/>
                        <path fill="white"
                              d="M127.779 247.992C155.981 247.543 184.27 247.994 212.48 247.996L382.254 248.001L815.949 248.022L815.682 395.184L550.25 395.024L311.602 395.149L242.043 395.075C230.2 395.039 217.661 394.059 205.918 395.323C198.61 396.109 191.434 399.147 185.499 403.427C178.501 408.475 172.951 415.174 170.021 423.338C167.062 431.582 166.288 441.338 164.813 449.988L156.59 497.94L127.838 661.724C126.54 648.64 127.512 630.158 127.521 616.626L127.513 528.589L127.779 247.992Z"/>
                        <path
                            d="M196.232 317.17C227.414 316.267 258.88 317.016 290.083 317.01L470.287 317.007L660.492 317.03L722.596 317.136C730.591 317.137 738.639 316.677 746.626 316.884C749.053 316.947 751.579 317.316 753.804 318.331C755.854 319.265 757.65 320.698 758.347 322.914C759.116 325.36 758.228 328.369 757.031 330.531C755.624 333.073 753.259 334.16 750.601 334.983L378.302 334.986L263.575 334.717L220.208 334.791C213.644 334.806 206.967 335.177 200.418 334.92C198.034 334.826 195.58 334.513 193.408 333.467C191.645 332.619 190.152 331.227 189.558 329.325C188.763 326.78 189.329 323.164 190.685 320.892C191.969 318.741 193.91 317.819 196.232 317.17Z"/>
                        <path fill="white"
                              d="M301.645 101.75C306.772 101.781 311.9 101.616 317.026 101.53L319.104 101.497C323.853 101.428 329.582 101.204 333.647 104.049C336.133 105.789 337.983 108.225 339.554 110.786C343.069 116.514 345.546 123.365 348.382 129.494L364.086 163.2C366.182 167.725 368.076 173.158 371.015 177.171C372.242 178.846 373.509 180.117 375.647 180.397C380.828 181.077 386.552 180.514 391.78 180.441C399.677 180.331 407.582 180.4 415.48 180.381L506.478 180.352L822.845 180.385L854.342 180.226C861.201 180.189 868.811 179.513 875.53 180.951C880.211 181.952 885.112 184.191 888.546 187.582C892.789 191.772 896.009 197.091 897.055 203.016C898.242 209.741 897.648 217.071 897.629 223.89L897.587 256.747L897.49 395.104L832.934 395.222L832.855 283.243L832.954 255.214C832.983 249.423 833.465 243.174 832.51 237.458C831.934 234.016 829.786 232.533 827.084 230.597C820.524 229.644 813.501 230.126 806.866 230.134L773.253 230.165L653.697 230.017L242.01 230.026L156.422 230.242C147.157 230.264 123.212 228.995 116.068 231.119C114.11 232.598 112.133 234.102 111.224 236.473C109.395 241.244 110.374 257.951 110.351 263.718L110.287 605.615L110.449 710.284L110.554 740.328C110.624 746.829 111.042 753.46 110.505 759.944C109.452 772.642 106.312 785.781 104.127 798.348L92.49 865.972L87.8742 892.5C87.227 896.402 87.0671 900.555 85.8753 904.33C85.3483 905.999 84.5099 907.38 83.3399 908.682C79.9874 912.413 75.5216 913.49 70.6902 913.664C61.7464 913.985 53.1235 913.119 46.321 906.609C41.9967 902.47 39.7718 897.639 39.2682 891.719C38.5022 882.712 39.2084 873.238 39.2835 864.193L39.2816 820.093L39.3276 664.747L39.1396 151.768C39.0999 138.104 36.3159 119.348 46.9574 109.048C50.9012 105.231 55.5465 103.033 61.0117 102.331C69.4356 101.249 78.66 101.938 87.1784 101.947L136.525 101.986L301.645 101.75Z"/>
                        <path fill="white"
                              d="M209.731 412.745C228.285 412.435 246.893 412.705 265.452 412.71L379.451 412.748L706.969 412.711L888.205 412.74L933.422 412.838C942.191 412.824 951.09 412.485 959.84 412.913C965.102 413.17 969.418 413.967 973.826 417.095C979.862 421.377 984.219 427.881 985.231 435.284C986.679 445.869 973.554 506.552 970.709 522.229L921.581 789.788L909.872 858.247C907.91 869.851 906.636 882.376 903.535 893.665C902.58 897.141 901.252 900.574 898.952 903.394C893.718 909.809 886.617 912.209 878.688 912.937L344.034 912.879L186.337 913.002C158.198 913.056 129.898 912.391 101.791 913.405C104.6 899.059 106.696 884.569 109.209 870.169L124.302 784.514L161.125 574.133L177.147 479.522C179.795 464.138 181.92 448.354 185.398 433.148C186.278 429.299 187.324 425.893 189.928 422.809C194.868 416.958 202.2 413.48 209.731 412.745Z"/>
                        <path
                            d="M692.847 610.142C706.375 609.498 720.188 610.106 733.741 610.144L811.469 610.213L863.136 610.091C872.407 610.072 882.38 609.302 891.534 610.616C893.247 611.731 894.994 613.054 895.907 614.939C896.671 616.516 896.896 618.301 896.696 620.03C895.629 629.249 892.794 638.768 890.89 647.883C888.731 658.219 886.787 672.333 883.322 682.108C882.211 685.242 880.2 686.261 877.324 687.605C871.023 688.099 864.473 687.65 858.133 687.674L817.982 687.797L716.77 687.634C710.444 687.619 684.921 688.666 680.266 687.158C678.069 686.446 675.883 684.699 674.913 682.58C674.074 680.75 674.277 677.814 674.521 675.869C675.518 667.931 678.008 659.973 679.554 652.103L683.902 629.635C684.742 625.243 685.268 620.462 686.84 616.276C688.16 612.763 689.438 611.642 692.847 610.142Z"/>
                        <path fill="white"
                              d="M702.22 627.658L877.642 627.657C874.022 641.765 871.306 656.244 868.251 670.492L693.144 670.413C695.835 656.124 699.209 641.887 702.22 627.658Z"/>

                    </svg>
                </div>
                <div className={styles.aukpoisk_second_elem_text}>ЦБД2 Аукціони</div>
            </Link>

        </div>
    )
}

function getUrlFiltersParam(search, type) {
    let tmp = null
    if (search.orderBy !== null) {
        if (tmp === null) {
            tmp = '?orderBy=' + search.orderBy
        } else {
            tmp += '&orderBy=' + search.orderBy
        }
    }
    // if (search.status !== null) {
    //     if (tmp === null) {
    //         tmp = '?status=' + search.status
    //     } else {
    //         tmp += '&status=' + search.status
    //     }
    // }
    if (search.itemType !== null) {
        if (tmp === null) {
            tmp = '?itemType=' + search.itemType
        } else {
            tmp += '&itemType=' + search.itemType
        }
    }
    if (search.region !== null) {
        if (tmp === null) {
            tmp = '?region=' + search.region
        } else {
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
    if (tmp === null) {
        tmp = `?category=${type}`
    } else {
        tmp += `&category=${type}`
    }
    //}
    if (search.priceCurrency !== null) {
        if (tmp === null) {
            tmp = '?priceCurrency=' + search.priceCurrency
        } else {
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if (search.priceFrom !== null) {
        if (tmp === null) {
            tmp = '?priceFrom=' + search.priceFrom
        } else {
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if (search.priceFromBag !== false) {
        if (tmp === null) {
            tmp = '?priceFromBag=true'
        } else {
            tmp += '&priceFromBag=true'
        }
    }
    if (search.priceTo !== null) {
        if (tmp === null) {
            tmp = '?priceTo=' + search.priceTo
        } else {
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if (search.priceToBag !== false) {
        if (tmp === null) {
            tmp = '?priceToBag=true'
        } else {
            tmp += '&priceToBag=true'
        }
    }
    if (search.measures !== null) {
        if (tmp === null) {
            tmp = '?measures=' + search.measures
        } else {
            tmp += '&measures=' + search.measures
        }
    }
    if (search.measuresFrom !== null) {
        if (tmp === null) {
            tmp = '?measuresFrom=' + search.measuresFrom
        } else {
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if (search.measuresTo !== null) {
        if (tmp === null) {
            tmp = '?measuresTo=' + search.measuresTo
        } else {
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if (search.textSting !== null) {
        if (tmp === null) {
            tmp = '?keyword=' + search.textSting
        } else {
            tmp += '&keyword=' + search.textSting
        }
    }
    if (search.selectTypeKeyword !== null) {
        if (tmp === null) {
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        } else {
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if (tmp !== null) {
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Legacy)