import React from 'react';
import style from './auctionError.module.css';

import {connect} from 'react-redux';

import {setPopUpErrorAuction} from '../../../../redux/actions/auctions/auctions.js';

const AuctionError = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>Виникла помилка при роботі з аукціоном</div>
                <div className={style.popup_message_button}
                    onClick={
                        () => {
                            props.setPopUpErrorAuction(null)
                        }
                    }
                >
                    Закрити
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
    }
}

const mapDispatchToProps = {
    setPopUpErrorAuction
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionError)