import React, {useState, useEffect} from 'react';
import styles from './cse.module.css';

import {connect, useDispatch} from 'react-redux';
import {getMyAuction} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';
import { changeLoader } from '../../../../redux/actions.js';

import Select from '../../../elements/inputs/inputSelect';

import ItemsList from './itemsList/itemsList.js';

import ReturnButton from '../../returnButton'
import Sidebar from '../../auctionEditNew/components/sidebar/sidebar'

import LandItem from './landtem/landItem'
import OtherPropertyItemNew from './otherPropertyItemNew/otherPropertyItemNew'
import JointPropertyComplexItem from './jointPropertyComplexItem/jointPropertyComplexItem'
import VehicleItem from './vehicleItem/vehicleItem'
import RealEstateItem from './realEstateItem/realEstateItem'
import { unitsGetByTypeZU } from '../../../../redux/actions/itemUnits/itemUnits';

import { clearDataLot } from '../basicSell/clearDataItem';

import { checkProp } from '../../../../redux/check';
import {getDefaultHeaders} from "../../../_api/bidApi";


const CommSell = ( props ) => {
    const [edit, setEdit] = useState(false)
    const [editData, setData] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
        if(props.auctionsMy.items && props.auctionsMy.items.length === 0) setEdit(true)
        if(props.classificationsZU === null){
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                header: getDefaultHeaders(props)
            }
            dispatch(unitsGetByTypeZU(data, data.header))
        }
    }, [])
        
    let tmpText = 'Додавання лота';
    if(!edit && props.auctionsMy.items.length === 0) tmpText = 'Додайте лот'
    if(!edit && props.auctionsMy.items.length > 0) tmpText = 'Список лотів'
    if(edit && checkProp(editData, 'id')) tmpText = 'Додавання лота'
    if(props.auctionsMy.items.length > 0 && editData !== null && edit ) tmpText = 'Редагування лота'



    function whatToRender(){
        if (!edit) {
            return  <div className={styles.wrapContent}>
                        <div className={styles.headers}></div>
                        <ItemsList edit={setEdit} setEdit={setData} />
                    </div>
        }
        if (edit || editData !== {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {viewMainClassif(props)}
                    {viewTypeItems(props, setEdit, editData)}
                </div>
        }
        if (edit || editData === {}){
            return <div className={styles.wrapContent}>
                    {viewSelectTypeItems(props)}
                    {viewMainClassif(props)}
                    {viewTypeItems(props, setEdit)}
                </div>
        }
    }
    
    return (
        <div className={styles.mainWrap}>
            <Sidebar data={props.auctionsMy} />
            <div className={styles.wrapBlock}>
                <div className={styles.wrapBlockTitle}>
                    {tmpText}
                    {
                        !edit ? 
                        <button className={styles.editButton} 
                            onClick={()=> {
                            setEdit(true)
                            setData(null)
                        }}>
                            Додати лот
                        </button> :
                        <button className={styles.cancel} 
                            onClick={()=> {
                            clearDataLot(props)
                            setEdit(false)
                            setData(null)
                        }}>
                            Скасувати
                        </button>
                    }
                </div>
                {whatToRender()}
                <div className={styles.buttonBack}>
                    <ReturnButton />
                </div>
            </div>
            
        </div>
    )
}

function viewSelectTypeItems(props){
    if(
        props.createItems.zu.vehicleItem.id !== null ||
        props.createItems.zu.realEstateItem.id !== null ||
        props.createItems.landRental.id !== null ||
        props.createItems.zu.jointPropertyComplexItem.id !== null
    ){
    }
    return (
        <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
            <div>
                <Select
                    data={{
                        label: "Основний класифікатор",
                        target: props.createItems.zu.baseClasif,
                        info: 'оберіть основний класифікатор лоту'
                    }}
                    onChange={(event) => {
                        props.setDataAddress(event, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                        props.setDataAddress(null, 'SET_CLASSIFICATIONS_BSE_BSD')
                        props.setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                        smartClasif(props, event)
                    }}
                    options={mainCAV}
                />
            </div>
        </div>
    )
}

const mainCAV = [
            { key:'04000000-8', value:'04', name: 'CAV 04 Нерухоме майно (та усі вкладені коди)'},
            { key:'05000000-5', value:'05', name: 'CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)'},
             { key:'16000000-5', value:'16', name: 'CAV 16 Сільськогосподарська техніка (та усі вкладені коди)'},
            { key:'18000000-9', value:'18', name: 'CAV 18 Одяг, взуття, сумки та аксесуари (та усі вкладені коди)'},
            { key:'19000000-6', value:'19', name: 'CAV 19 Шкіряні та текстильні, пластмасові та гумові матеріали (та усі вкладені коди)'},
            { key:'22000000-0', value:'22', name: 'CAV 22 Друкована та супутня продукція (та усі вкладені коди)'},
            { key:'30000000-9', value:'30', name: 'CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)'},
            { key:'31000000-6', value:'31', name: 'CAV 31 Електротехнічне устаткування, апаратура, обладнання та матеріали; освітлювальне устаткування (та усі вкладені коди)'},
            { key:'32000000-3', value:'32', name: 'CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)'},
            { key:'33000000-0', value:'33', name: 'CAV 33 Медичне обладнання, фармацевтична продукція та засоби особистої гігієни (та усі вкладені коди)'},
            { key:'34000000-7', value:'34', name: 'CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)'},
            { key:'35000000-4', value:'35', name: 'CAV 35 Охоронне, протипожежне, поліцейське та оборонне обладнання (та усі вкладені коди)'},
            { key:'37000000-8', value:'37', name: 'CAV 37 Музичні інструменти, спортивні товари, ігри, іграшки, ремісничі, художні матеріали та приладдя (та усі вкладені коди)'},
            { key:'38000000-5', value:'38', name: 'CAV 38 Лабораторне, оптичне та високоточне обладнання (крім лінз) (та усі вкладені коди)'},
            { key:'39000000-2', value:'39', name: 'CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)'},
            { key:'42000000-6', value:'42', name: 'CAV 42 Промислова техніка (та усі вкладені коди)'},
            { key:'43000000-3', value:'43', name: 'CAV 43 Гірничодобувне та будівельне обладнання (та усі вкладені коди)'},
            { key:'44000000-0', value:'44', name: 'CAV 44 Конструкції та конструкційні матеріали; допоміжна будівельна продукція (крім електроапаратури) (та усі вкладені коди)'},
            ]

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
}

function viewMainClassif(props){
    
    if(props.classificationsBseBsd !== null){ // TODO: CSE-CSD ??
        return (<>
            <Select
                    data={{
                        label: "Вкладені коди основного класифікатора",
                        target: props.createItems.landRental.classification,
                        info: 'Виберіть вкладений код основного класифікатора'
                    }}
                    onChange={(event) => {
                        let tmp = null
                            if(props.classificationsBseBsd !== null){
                                tmp = props.classificationsBseBsd.filter(
                                    (i) => {
                                        return i.id === event
                                    }
                                )
                            }
                            if(tmp !== null && tmp.length > 0){
                                if(tmp[0].id !== null && tmp[0].id !== 'null'){
                                    if(
                                        tmp[0].id === '07121000-3' ||
                                        tmp[0].id === '07221000-4' ||
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '4')
                                    ){
                                        props.setDataAddress('realEstate', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '6') ||
                                        (
                                            tmp[0].id[0] === '0' &&
                                            tmp[0].id[1] === '7' &&
                                            tmp[0].id[2] === '1' &&
                                            tmp[0].id[3] === '2' &&
                                            tmp[0].id[4] === '3'
                                        ) ||
                                        (
                                            tmp[0].id[0] === '0' &&
                                            tmp[0].id[1] === '7' &&
                                            tmp[0].id[2] === '2' &&
                                            tmp[0].id[3] === '2' &&
                                            tmp[0].id[4] === '3'
                                        )
                                    ){
                                        props.setDataAddress('otherProperty', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        tmp[0].id === '07126000-8' ||
                                        tmp[0].id === '07226000-9' ||
                                        (tmp[0].id[0] === '3' && tmp[0].id[1] === '4')
                                    ){
                                        props.setDataAddress('vehicle', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else if(
                                        tmp[0].id === '07122000-0' ||
                                        tmp[0].id === '07222000-1' ||
                                        (tmp[0].id[0] === '0' && tmp[0].id[1] === '5')
                                    ){
                                        props.setDataAddress('jointPropertyComplex', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }else{
                                        props.setDataAddress(null, 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
                                    }
                                }
                                props.setDataAddress(tmp[0].id, 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
                            }
                        }}
                    options={classificationIdOption(props)}
                />
            </>
        )
    }else{
        if(props.createItems.zu.baseClasif !== 'null'){
            return (
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        Зачекайте. Вкладені коди основного класифікатора завантажуються
                    </div>
                </div>
            )
        }
    }
}

function classificationIdOption(props){
    let collatore = new Intl.Collator('uk-UA')
    if(props.classificationsBseBsd !== null){
        let tmp = props.classificationsBseBsd
        tmp.sort(
            (a, b) => collatore.compare(a.id, b.id)
        )
        if(tmp[0].id[0] === '3' && tmp[0].id[1] === '4'){
            return tmp
            .filter(
                (i) => {
                    return i.id[2] === '1' || i.id[2] === '4' || i.id[2] === '5' || i.id[2] === '6' || i.id[2] === '7'
                }
            )
            .map(
                (i) => {
                    return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
                }
            )    
        }
        return tmp.map(
            (i) => {
                return { key: i.id, value: i.id, name: `${ i.scheme + ' ' + i.id + ' ' + i.description.uk_UA }`}
            }
        )
    }
}

function viewTypeItems(props, setEdit, data){
    if(props.createItems.landRental.classification !== 'null' && props.createItems.landRental.classification !== null){
        switch(props.createItems.zu.chousenType){
            case 'otherProperty':
                return <LandItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
            case 'jointPropertyComplex':
                return <JointPropertyComplexItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
            case 'vehicle':
                return <VehicleItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
            case 'realEstate':
                return <RealEstateItem edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
            default:
                return <OtherPropertyItemNew edit={setEdit} data={data} classification={props.createItems.landRental.classification} />
        }
    }
    return null
}

async function getDictionaryJointPropertyComplex(props, mask){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        addressGetId: state.start.addressGetId,
        setMask: state.start.setMask,
        classificationsDgf: state.start.classificationsDgf,
        classificationsBseBsd: state.start.classificationsBseBsd,
        classificationsZU: state.start.classificationsZU.unitCode
    }
}

const mapDispatchToProps = {
    getMyAuction,
    setDataAddress,
    changeLoader,
    unitsGetByTypeZU
}

export default connect(mapStateToProps, mapDispatchToProps)(CommSell)