import React from 'react';
import styles from '../docItem.module.css';

import { connect } from 'react-redux';
import { getViewBidDocuments } from './logic/bidLogic';
import { getViewAucDocuments } from './logic/aucLogic';

const DocLink = ({ privateData, ...props}) => {
    async function getFile(props) {
        let products = props.data.url
        if (props.data._ds_scope === "private" && (privateData.hasBids || privateData.isOwner)) {
            switch (props.data.documentOf) {
                case 'bid':
                    products = await getViewBidDocuments(props);
                    break;
                case 'auction':
                    products = await getViewAucDocuments(props);
                    break;
                default:
                    return false;
            }
        }else if(props.data.scope === "private" && (privateData.hasBids || privateData.isOwner)) {
            switch (props.data.documentOf) {
                case 'bid':
                    products = await getViewBidDocuments(props);
                    break;
                case 'auction':
                    products = await getViewAucDocuments(props);
                    break;
                default:
                    return false;
            }
        }
        window.open(products)
    }

    if (props.data.url !== null && props.data._ds_scope !== "private" && props.data.scope !== "private" ) {
        return (
            <div className={styles.downLoadBtn}
                title='Завантажити документ'
                onClick={() => window.open(props.data.url)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-11 -8 42 42">
                    <g id="Layer_2" data-name="Layer_2">
                        <g id="Layer_2-2" data-name="Layer_2">
                            <path className={styles.svgDownld} fill="#92929d" fillRule="evenodd" d="M5.33,0A5.33,5.33,0,0,0,0,5.33v16a5.34,5.34,0,0,0,5.33,5.34H16a5.33,5.33,0,0,0,5.33-5.34V9a2.67,2.67,0,0,0-.59-1.68L15.6,1a2.63,2.63,0,0,0-2.07-1ZM15.5,5.1V7.2h1.7ZM5.33,2.67A2.66,2.66,0,0,0,2.67,5.33v16A2.66,2.66,0,0,0,5.33,24H16a2.67,2.67,0,0,0,2.67-2.67V9.87H15.5A2.68,2.68,0,0,1,12.83,7.2V2.67Zm6.67,14,.53-.4a1.33,1.33,0,1,1,1.6,2.13l-2.65,2a1.37,1.37,0,0,1-.81.28,1.32,1.32,0,0,1-.81-.28l-2.66-2a1.33,1.33,0,1,1,1.6-2.13l.53.4V11.48a1.34,1.34,0,0,1,2.67,0Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    } else if (props.data.url !== null && (privateData.hasBids || privateData.isOwner)){
        return (
            <div className={styles.downLoadBtn}
                title='Завантажити документ'
                onClick={() => getFile(props)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-11 -8 42 42">
                    <g id="Layer_2" data-name="Layer_2">
                        <g id="Layer_2-2" data-name="Layer_2">
                            <path className={styles.svgDownld} fill="#92929d" fillRule="evenodd" d="M5.33,0A5.33,5.33,0,0,0,0,5.33v16a5.34,5.34,0,0,0,5.33,5.34H16a5.33,5.33,0,0,0,5.33-5.34V9a2.67,2.67,0,0,0-.59-1.68L15.6,1a2.63,2.63,0,0,0-2.07-1ZM15.5,5.1V7.2h1.7ZM5.33,2.67A2.66,2.66,0,0,0,2.67,5.33v16A2.66,2.66,0,0,0,5.33,24H16a2.67,2.67,0,0,0,2.67-2.67V9.87H15.5A2.68,2.68,0,0,1,12.83,7.2V2.67Zm6.67,14,.53-.4a1.33,1.33,0,1,1,1.6,2.13l-2.65,2a1.37,1.37,0,0,1-.81.28,1.32,1.32,0,0,1-.81-.28l-2.66-2a1.33,1.33,0,1,1,1.6-2.13l.53.4V11.48a1.34,1.34,0,0,1,2.67,0Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )

    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}

export default connect(mapStateToProps, null)(DocLink)