import React from 'react';

import {connect} from 'react-redux';

import TextHalf from './textHalf'

import LotId from './auctionElements/lotId'
import AccessDetailsString from './auctionElements/accessDetails'
import GuaranteeString from './auctionElements/guarantee'
import MinimalStepString from './auctionElements/minimalStep_old'
import XAdditionalInformationString from './auctionElements/xAdditionalInformation'
import MinNumberOfQualifiedBidsString from './auctionElements/minNumberOfQualifiedBids'
import SellingMethodString from './auctionElements/sellingMethod'
import TenderAttemptsString from './auctionElements/tenderAttempts'
import AuctionIdString from './auctionElements/auctionId'
import DescriptionString from './auctionElements/description'
import ValueString from './auctionElements/value'
import XDocumentRequirements from './auctionElements/xDocumentRequirements'

import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';

const LotInformation = (props) => {
    let tmp = <>
        <LotId />
        <ValueString />
        <DescriptionString />
        <AuctionIdString />
        <TenderAttemptsString />
        <SellingMethodString />
        <XAdditionalInformationString />
        <MinNumberOfQualifiedBidsString />
        <MinimalStepString />
        <GuaranteeString />
        <AccessDetailsString />
        <XDocumentRequirements />
        {strAuctionUrl(props)}
    </>
    return (
        <SlimmerBlock
            title='Інформація про лот'
            content={tmp}
            slim={true}
        />
    )
}

function strAuctionUrl(props){
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('auctionUrl') === true){
            if(props.auctionId.auctionUrl !== null){
                return (
                    <TextHalf
                        title="Веб-адреса для перегляду аукціону"
                        data={props.auctionId.auctionUrl}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LotInformation)