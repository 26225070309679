import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount, addAuctionPeriodProperty,
    addBanksNew} from './utilsCreateBody'

export function createBodyLLE(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotId')
        body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        body = addProperty(data, body, 'sellingMethod')
        body = addPropertyMonga(data, body, 'x_documentRequirements')
        body = addPropertyMonga(data, body, 'x_additionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addProperty(data, body, 'valueAddedTaxCharged')
        body = addPropertyValue(data, body, 'value')
        body = addPropertyValue(data, body, 'minimalStep')
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addProperty(data, body, 'previousAuctionId')
        body = addPropertyDiscount(data, body, 'discount')
        body = addBanksNew (data, body)
        //body = addBankProperty(data, body, 'banks')
        //body = addPropertyMonga(data, body, 'restrictions')
        //body = addProperty(data, body, 'isTaxInvoiceProvided')
        //body = addPropertyMonga(data, body, 'economicCourtName')
        //body = addPropertyMonga(data, body, 'bankruptcyCaseNumber')
        //body = addPropertyMonga(data, body, 'sellingMethodType')
        //body = addDebtorInformation(data, body, 'debtorInformation')
        //body = addSellingEntity(data, body, 'sellingEntity')
        body = addProperty(data, body, 'martialLawDisclaimer')
        body = addProperty(data, body, 'calcOnBookValue')
        body.leaseRules = data.base.leaseRules
        body.leaseDuration = data.base.leaseDuration
        body.relatedOrganizations = data.base.relatedOrganizations
        body.valuePeriod = data.base.valuePeriod
        return body
    }
    return null
}