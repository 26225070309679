import React from 'react';

import {connect} from 'react-redux';

import AuthUser from './authUser/authUser.js';
import NotAuthUser from './notAuthUser/notAuthUser.js';

const Faq = (props) => {
    if (props?.token?.access_token?.length > 0) {
        return <AuthUser/>
    } else {
        return <NotAuthUser/>
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}

export default connect(mapStateToProps, null)(Faq)