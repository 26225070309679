import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionTenderAttempts} from '../../../redux/actions.js';

import Select from '../../elements/inputs/inputSelect';
import { getOptions } from '../../../redux/check'; //TODO: ПЕРЕДАЛАТЬ НА ПО-НОРМАЛЬНОМУ

import {
    discountAuc,
} from '../../../redux/actions/discount/discount.js';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';
import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE } from '../../../redux/procedure';

import {disabledElement} from '../disabled'

const WindowAuctionTenderAttempt = ( props ) => {
    let tmp;
    if(props.typeElem === 1){
        tmp = <Select
                    disabled={disabledElement(props.auctionsMy, dataDisabled(props))}
                    data={{
                        label: viewNameInput(props),
                        target: props.auctionsMy.tenderAttempts,
                        // info: 'оберіть основний класифікатор лоту'

                    }}
                    onChange={(event)=>{
                                if(event !== '1'){
                                    props.discountAuc(true)
                                }else{
                                    props.discountAuc(false)
                                }
                                props.changeCreateAuctionTenderAttempts(event)
                            }}
                    options={getOptions(legitimatePropertyLeaseTenderAttempts(props))}
                />
            // <TextField
            //     error = {redBlock(props, props.auctionsMy.tenderAttempts)}
            //     className={styles.inputMax}
            //     disabled={disabledElement(props.auctionsMy, dataDisabled(props))}
            //     id="outlined-select-currency-native"
            //     select
            //     label={viewNameInput(props)}
            //     value={props.auctionsMy.tenderAttempts}
            //     onChange={(event)=>{
            //         if(event.target.value !== '1'){
            //             props.discountAuc(true)
            //         }else{
            //             props.discountAuc(false)
            //         }
            //         props.changeCreateAuctionTenderAttempts(event.target.value)
            //     }}
            //     SelectProps={{
            //         native: true,
            //     }}
            //     helperText=""
            //     variant="outlined"
            // >
            //     {legitimatePropertyLeaseTenderAttempts(props)}
            // </TextField>
    }else{
        tmp = <div className={styles.inputMax}>
            <TextField
                error = {redBlock(props, props.auctionsMy.tenderAttempts)}
                disabled={disabledElement(props.auctionsMy, dataDisabled(props))}
                className={styles.inputMax}
                id="outlined-select-currency-native"
                select
                label={viewNameInput(props)}
                value={props.auctionsMy.tenderAttempts}
                onChange={(event)=>{
                    if(event.target.value !== '1'){
                        props.discountAuc(true)
                    }else{
                        props.discountAuc(false)
                    }
                    props.changeCreateAuctionTenderAttempts(event.target.value)
                }}
                SelectProps={{
                    native: true,
                }}
                helperText=""
                variant="outlined"
            >
                {legitimatePropertyLeaseTenderAttempts(props)}
            </TextField>
        </div>
    }
    return tmp
}

function dataDisabled(props){
    if(
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return '00'
    }else{
        return '11'
    }
}


function viewNameInput(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        return TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.tenderAttempts.legalName
    }else if(
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return 'Лот виставляється'
    }else{
        return TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.tenderAttempts.legalName
    }
}

function legitimatePropertyLeaseTenderAttempts(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
    ){
        if(props.auctionsSwitchWindow === 3){
            if(props.auctionsZUAttemp !== null){
                if(props.auctionsZUAttemp.tenderAttempts === 1 ||props.auctionsZUAttemp.tenderAttempts === '1' || props.auctionsZUAttemp.tenderAttempts === null){
                    if(props.auctionsZUAttemp.status === 'unsuccessful' || props.auctionsZUAttemp.status === 'cancelled' || props.auctionsZUAttemp.status === 'complete'){
                        return (
                            <>
                                <option key={0} selected disabled value='null'>Обрати</option>
                                <option key={1} value='1'>Вперше</option>
                                <option key={2} value='2'>Вдруге</option>
                            </>
                        )
                    }
                }if(props.auctionsZUAttemp.tenderAttempts === 2 || props.auctionsZUAttemp.tenderAttempts === '2'){
                    if(props.auctionsZUAttemp.status === 'unsuccessful' || props.auctionsZUAttemp.status === 'cancelled' || props.auctionsZUAttemp.status === 'complete'){
                        return (
                            <>
                                <option key={0} selected disabled value='null'>Обрати</option>
                                <option key={1} value='1'>Вперше</option>
                                <option key={2} value='2'>Вдруге</option>
                                <option key={3} value='3'>Втретє</option>
                            </>
                        )
                    }
                }else{
                    return (
                        <>
                            <option key={0} selected disabled value='null'>Обрати</option>
                            <option key={1} value='1'>Вперше</option>
                            <option key={2} value='2'>Вдруге</option>
                        </>
                    )
                }
            }else{
                return (
                    <>
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='1'>Вперше</option>
                        <option key={2} value='2'>Вдруге</option>
                    </>
                )
            }
        }else{
            if(props.auctionsMy.tenderAttempts === 1 || props.auctionsMy.tenderAttempts === '1' || props.auctionsMy.tenderAttempts === null){
                return (
                    <>
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='1'>Вперше</option>
                        <option key={2} value='2'>Вдруге</option>
                    </>
                )
            }else if(props.auctionsMy.tenderAttempts === 2 || props.auctionsMy.tenderAttempts === '2'){
                return (
                    <>
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='1'>Вперше</option>
                        <option key={2} value='2'>Вдруге</option>
                    </>
                )
            }else if(props.auctionsMy.tenderAttempts === 3 || props.auctionsMy.tenderAttempts === '3'){
                return (
                    <>
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='1'>Вперше</option>
                        <option key={2} value='2'>Вдруге</option>
                        <option key={3} value='3'>Втретє</option>
                    </>
                )
            }
        }
    }else if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    ){
        return (
            <>
                <option key={0} selected disabled value='null'>Обрати</option>
                <option key={1} value='1'>Вперше</option>
                <option key={2} value='2'>Вдруге</option>
                <option key={3} value='3'>Втретє</option>
                <option key={4} value='4'>Вчетверте</option>
                <option key={5} value='5'>Вп'яте</option>
                <option key={6} value='6'>Вшосте</option>
                <option key={7} value='7'>Всьоме</option>
                <option key={8} value='8'>Ввосьме</option>
                <option key={9} value='9'>Вдев'яте</option>
                <option key={10} value='10'>Вдесяте</option>
                <option key={11} value='11'>Невідомо</option>
            </>
        )
    }else if(
        props.auctionsMy.sellingMethod === 'alienation-english' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod'
    ){
        return (
            <>
                <option key={0} selected disabled value='null'>Обрати</option>
                <option key={1} value='1'>Вперше</option>
                <option key={2} value='2'>Вдруге</option>
            </>
        )
    }else{
        return (
            <>
                <option key={0} selected disabled value='null'>Обрати</option>
                <option key={1} value='1'>Вперше</option>
                <option key={2} value='2'>Вдруге</option>
                <option key={3} value='3'>Втретє</option>
                <option key={4} value='4'>Вчетверте</option>
                <option key={5} value='5'>Вп'яте</option>
                <option key={6} value='6'>Вшосте</option>
                <option key={7} value='7'>Всьоме</option>
                <option key={8} value='8'>Ввосьме</option>
                <option key={9} value='9'>Вдев'яте</option>
                <option key={10} value='10'>Вдесяте</option>
                <option key={11} value='11'>Невідомо</option>
            </>
        )
    }
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTenderAttempts,
    discountAuc,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionTenderAttempt)
