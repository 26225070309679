import React,{useState} from 'react';
import styles from './vehicleItem.module.css';

import {connect} from 'react-redux';
import { createItemCS, saveItemCS, getValues, getValuesUA } from '../utils';
import { isMongo, checkProp } from '../../../../../redux/check';

import TextArea from '../../../../elements/inputs/textArea'
import Input from '../../../../elements/inputs/input';
import Select from '../../../../elements/inputs/inputSelect'
import Button from '../../../../elements/buttons/button';
import StateRegistration from '../../../elems/stateRegistration/stateRegistration';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
    setUniversalError,
} from '../../../../../redux/actions.js';

import {popUpAddress} from '../../../../../redux/actions/address/address.js';

import Localization from '../../../elems/localization/localization';

import {clearDataLot} from '../clearDataItem'

const VehicleItem = ( {data, classification, ...props} ) => {
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [unit, setUnit] = useState('H87')
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)
    const [location, setLocation] = useState(checkProp(data, 'location') ? data.location : null)
    const [address, setAdress] = useState(checkProp(data, 'address') ? data.address : null)
    const [stateRegisration, setSateRegisration] = useState(checkProp(data, 'registrationDetails') ? data.registrationDetails : null )

    const [brand, setBrand] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'brand') ? data.vehicleProps.brand : null)
    const [model, setModel] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'model') ? data.vehicleProps.model : null)
    const [productionYear, setProductionYear] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'productionYear') ? data.vehicleProps.productionYear : null)
    const [engineCapacity, setEngineCapacity] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'engineCapacity') ? data.vehicleProps.engineCapacity : null)
    const [fuelType, setFuelType] = useState(checkProp(checkProp(data, 'vehicleProps') && data.vehicleProps, 'fuelType') ? data.vehicleProps.fuelType : null)
    const [transmission, setTransmission] = useState(checkProp(checkProp(data, 'vehicleProps') && data.vehicleProps, 'transmission') ? data.vehicleProps.transmission : null)
    const [color, setColor] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'color') ? data.vehicleProps.color : null)
    const [kilometrage, setKilometrage] = useState(checkProp(checkProp(data, 'vehicleProps') && data.vehicleProps, 'kilometrage') ? data.vehicleProps.kilometrage : null)
    const [identificationNumber, setIdentificationNumber] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'identificationNumber') ? data.vehicleProps.identificationNumber : null)
    const [configuration, setConfiguration] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'configuration') ? data.vehicleProps.configuration : null)
    const [condition, setCondition] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'condition') ? data.vehicleProps.condition : null)
    const [damagedDescription, setDamagedDescription] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'damagedDescription') ? data.vehicleProps.damagedDescription : null)
    const [damagePresence, setDamagePresence] = useState(checkProp(data, 'vehicleProps') && checkProp(data.vehicleProps, 'damagePresence') ? data.vehicleProps.damagePresence : null)

    let requestBody = {
        description: {
            uk_UA: description,
        },
        classification: {
            id: classification,
            scheme: getValues(props.classificationsBseBsd, classification, 'id', 'scheme'),
            description: {
                uk_UA: getValuesUA(props.classificationsBseBsd, classification, 'id', 'description'),
            }
        },
        unit: {
            code: unit,
            name: {
                uk_UA: getValues(props.classificationsZU.unitCode, unit, 'code', 'name'),
            }
        },
        quantity: +quantity,
        address: address,
        location: location,
        itemType: "vehicle",
        vehicleProps: {
            brand: brand,
            model: model,
            productionYear: productionYear,
            engineCapacity: +engineCapacity,
            fuelType: fuelType,
            transmission: transmission,
            color: color,
            kilometrage: +kilometrage,
            identificationNumber: identificationNumber,
            configuration: configuration,
            condition: condition,
            damagedDescription: damagePresence !== null ? damagedDescription : null,
            damagePresence: damagePresence
        },
        registrationDetails: stateRegisration,
        // commercialSellItemType: null
    }

    function viewButtons(props){
        if(
            description !== null && description !== ''
        ){
            return (
                <div className={styles.lineButtons}>
                    <Button
                        data={'Зберегти зміни'}
                        color={'blue'}
                        onClick={
                            () => {
                                if(
                                    !checkProp(data, 'id')
                                ){
                                    createItemZU(props, requestBody)
                                }else{
                                    saveItemZU(props, requestBody, data.id)
                                }
                                props.edit(false)
                            }
                        }
                    />
                    <Button
                        data={'Закрити'}
                        color={'gray'}
                        onClick={
                            () => {
                                clearDataLot(props)
                                props.edit(false)
                            }
                        }
                    />
                </div>
            )
        }
    }

    return (
        <>
            <TextArea
                value={description}
                //disabled={disabledElement(props.auctionsMy, '01')}
                label="Опис об'єкта"
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <StateRegistration 
                data={stateRegisration} 
                setValue={(e)=> setSateRegisration(e)}
            />
            <div style={{width: '100%', height: '20px'}}></div>
            <Input
                label="Марка транспортного засобу"
                value={brand}
                onChange={(e)=>{ setBrand(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Модель транспортного засобу"
                value={model}
                onChange={(e)=>{ setModel(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Рік випуску"
                type='number'
                value={productionYear}
                onChange={(e)=>{ if(e.length <= 4) setProductionYear(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Об’єм двигуна, см³"
                type='number'
                value={engineCapacity}
                onChange={(e)=>{ setEngineCapacity(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Select
                data={{
                    label: "Тип палива",
                    target: fuelType,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setFuelType(e) }}
                options={[
                    {value: "diesel", name: 'Дизель'},
                    {value: "electric", name: 'Електро'},
                    {value: "gasoline", name: 'Бензин'},
                    {value: "hybrid", name: 'Гібридний'},
                    {value: "liquefiedPetroleumGas", name: 'Зріджений нафтовий газ'},
                    {value: "naturalGas", name: 'Газ'},
                    {value: "other", name: 'Інший'},
                ]}
            />
            <Select
                data={{
                    label: "Тип коробки передач",
                    target: transmission,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setTransmission(e) }}
                options={[
                    {value: "automatic", name: 'Автоматична'},
                    {value: "manual", name: 'Механічна'},
                    {value: "semiAutomatic", name: 'Напівавтоматична'},
                    {value: "other", name: 'Інший'},
                ]}
            />
            <Input
                label="Колір"
                value={color}
                onChange={(e)=>{ setColor(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Пробіг, км"
                value={kilometrage}
                onChange={(e)=>{ setKilometrage(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Номер кузова або шасі (VIN-код)"
                value={identificationNumber}
                onChange={(e)=>{ setIdentificationNumber(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <TextArea
                label="Комплектація"
                value={configuration}
                onChange={(e)=>{ setConfiguration(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Input
                label="Технічний стан"
                value={condition}
                onChange={(e)=>{ setCondition(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            <Select
                data={{
                    label: "Потреба у ремонті",
                    target: damagePresence,
                    // info: 'оберіть основний класифікатор лоту'
                }}
                onChange={(e) => { setDamagePresence(e)
                }}
                options={[
                    {value: false, name: 'Ні'},
                    {value: true, name: 'Так'},
                ]}
            />
            {damagePresence === true || damagePresence === 'true' ? 
                <TextArea
                label="Опис пошкоджень"
                value={damagedDescription}
                onChange={(e)=>{ setDamagedDescription(e) }}
                //disabled={disabledElement(props.auctionsMy, '01')}
            />
            : null}
            <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
                <div style={{width: '49%'}}>
                    <Input
                        type='number'
                        label="Кількість одиниць"
                        value={quantity}
                        onChange={(e)=>{ setQuantity(e) }}
                        //disabled={disabledElement(props.auctionsMy, '01')}
                    />
                </div>
                <div style={{width: '49%'}}>
                    <Select
                        data={{
                            label: "Одиниці виміру",
                            target: unit,
                        }}
                        onChange={(e) => { setUnit(e) }}
                        options={classificationSubsoilUnitCode(props)}
                    />
                </div>
            </div>
            <Localization address={address} location={location} setLocation={setLocation} setAdress={setAdress} />
            {viewButtons(props)}
        </>
    )
}

function createItemZU(props, requestBody){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            createItemCS(props, requestBody)
        }
    }
}

function saveItemZU(props, requestBody, itemId){
    props.changeLoader(true)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            saveItemCS(props, requestBody, itemId)
        }
    }
}

function classificationSubsoilUnitCode(props){
    if(props.classificationsZU.unitCode !== null){
        return props.classificationsZU.unitCode
        .filter(
            (i) => {
                return i.code === 'H87'
            }
        )
        .map(
            (i) => {
                return {value: i.code, name: i.name, selected: true}
            }
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationsBseBsd: state.start.classificationsBseBsd
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    popUpAddress,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleItem)