import React from 'react';
import PopDownBlock from '../viewData/popdownBlock/popDownBlock';
import TextHalf from '../viewData/textHalf';
// import TextFull from '../viewData/textFull';
import { checkProp } from '../../../redux/check';

const ContactPoint =(props)=> {
    if(checkProp(props, 'data')){
                return (
                    <PopDownBlock
                        title={props.title}
                        content={
                            <>
                                {checkProp(props.data, 'name') && checkProp(props.data.name, 'uk_UA') ?
                                    <TextHalf
                                        title='ПІБ'
                                        data={props.data.name.uk_UA}
                                    /> : null
                                }
                                {checkProp(props.data, 'telephone') ?
                                    <TextHalf title='Номер телефону' data={props.data.telephone} />
                                    : null
                                }
                                { checkProp(props.data, 'faxNumber') ?
                                    <TextHalf title='Номер факсу' data={props.data.faxNumber}
                                    /> : null }
                                { checkProp(props.data, 'email') ? <TextHalf title='Адреса електронної пошти'
                                    data={<a href={`mailto:${props.data.email}`}>{props.data.email}</a>}
                                /> : null }
                                { checkProp(props.data, 'url') ?
                                    <TextHalf title='Веб адреса'
                                        data={<a href={`${props.data.url}`}>{props.data.url}</a>}
                                /> : null }
                            </>
                        }
                    />
                )
            }
    return null
}

export default ContactPoint