import React from 'react';
import styles from './notAuthUser.module.css';

import {FAQ_TITLE_TOP, FAQ_TITLE_BOTTOM} from '../../../../model/lang/topMenu.js';
import { connect } from 'react-redux';

import SvgNotAuthUser from './notAuthUser.svg';

const NotAuthUser = (props) => {
    return (
        <div className={styles.wrapFaq} >
            <a className={styles.korusne_link} href="https://www.dto.com.ua/helpful/presentations">
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img src={SvgNotAuthUser} alt='' />
                        <div className={styles.korusne_title}>{FAQ_TITLE_TOP[props.langFlag]}</div>
                    </div>
                    <div className={styles.korusne_text}>{FAQ_TITLE_BOTTOM[props.langFlag]}</div>
                </div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token:  state.start.token,
        userProfiles: state.start.userProfiles
    }
}

export default connect(mapStateToProps, null)(NotAuthUser)