export function clearDataLot(props, setDataAddress){
    setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
    setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION')
    setDataAddress('null', 'CREATE_ITEMS_ZU_CHOUSEN_TYPE')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_DESCRIPTION')


    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
    setDataAddress('50.44395', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    setDataAddress('30.51589', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
    
    //Очищаем Джоин проперти
    setDataAddress(null, 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID')

    setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER')
    setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE')
    setDataAddress('null', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS')
    setDataAddress('', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY')
    //props.setDataAddress('HAR', 'CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE')

    //Очищаем землю
    setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')

    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER')
    setDataAddress('null', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    //props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')

    //Очищаем инше
    setDataAddress(null, 'CREATE_ITEMS_LAND_RENTAL_ID')
    setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_QUANTITY')
    //props.setDataAddress('', 'CREATE_ITEMS_LAND_RENTAL_UNIT_CODE')

    //Очищаем недвигу
    setDataAddress(null, 'CREATE_ITEMS_REAL_ESTATEITEM_ID')

    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')

    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM')
    setDataAddress('null', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX')
    setDataAddress('', 'CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY')
    //Очищаем авто
    setDataAddress(null, 'CREATE_ITEMS_VEHICLE_ITEM_ID')
    
    setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE')
    
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY')
    setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE')
    setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION')
    setDataAddress('', 'CREATE_ITEMS_VEHICLE_ITEM_QUANTITY')
    setDataAddress('null', 'CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE')
}