import {
    POPUP_EDIT_APPROVE_DOC,
    POPUP_EDIT_APPROVE_DOC_TITLE,
    POPUP_EDIT_APPROVE_DOC_DISCRIP,
    POPUP_EDIT_APPROVE_DOC_FILES,
} from "./types";

export function editAwardDoc(value) {
    return {
        type: POPUP_EDIT_APPROVE_DOC,
        payload: value
    }
}

export function editAwardDocTitle(value) {
    return {
        type: POPUP_EDIT_APPROVE_DOC_TITLE,
        payload: value
    }
}

export function editAwardDocDiscript(value) {
    return {
        type: POPUP_EDIT_APPROVE_DOC_DISCRIP,
        payload: value
    }
}

export function editAwardDocFiles(value) {
    return {
        type: POPUP_EDIT_APPROVE_DOC_FILES,
        payload: value
    }
}