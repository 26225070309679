import { 
    //Questions
    QUESTIONS_AUCTION_ID_GET,

    ANSWER_TITLE,
    ANSWER_DISCRIPTION
} from "../../types";

import {getAPI} from '../crud';
import {setLocal} from '../support';

//Questions

export function questionsGetId(_url, header){
    const data = {
        url: `${_url.domen}/api/${_url.version}/${setLocal(_url.local)}/auction/${_url.id}/questions${_url.params}`,
        actionType: QUESTIONS_AUCTION_ID_GET,
        header: header
    }
    return getAPI(data);
}

export function answerTitle(value) {
    return {
        type: ANSWER_TITLE,
        payload: value
    }
}

export function answerDiscription(value) {
    return {
        type: ANSWER_DISCRIPTION,
        payload: value
    }
}