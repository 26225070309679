import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const EconomicCourtName = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('economicCourtName') === true){
            if(props.auctionId.economicCourtName !== null){
                return (
                    <TextHalf
                        title="Найменування господарського суду"
                        data={props.auctionId.economicCourtName.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(EconomicCourtName)