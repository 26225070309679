import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const SaleTypeString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('saleType') === true){
            if(props.auctionId.saleType !== null){
                return (
                    <TextHalf
                        title="Тип продажу"
                        data={textType(props.auctionId.saleType)}
                    />
                )
            }
        }
    }
    return null
}

function textType(data){
    switch(data){
        case 'landSale': return 'Продаж земельних ділянок'
        case 'landRental': return 'Оренда земельних ділянок'
        case 'superficiesSale': return 'Продаж прав суперфіцію'
        case 'emphyteusisSale': return 'Продаж прав емфітевзису'
        default: return null
    }
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(SaleTypeString)