import React, {useState} from 'react';
import style from './../../../../components/popUp/contracts/popupContractAppruve.module.css';

import {connect} from 'react-redux';

import {
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
} from '../../../../redux/actions/awards/awards.js';

import {
    changeContractErrorPopUpStatus,
} from '../../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputDate from '../../../elements/inputs/inputDate';


import {getUpdateDataAuction} from '../../../product/updateData'
import {setDataAddress} from "../../../../redux/createAddress";

const ContractEditRemPopUp = (props) => {
    const [title, setTitle] = useState(props.contract?.title?.uk_UA)
    const [description, setDescription] = useState(props.contract?.description?.uk_UA)
    const [contractNumber, setContractNumber] = useState(props.contract?.contractNumber)
    const [contractTotalValue, setContractTotalValue] = useState(props.contract?.contractTotalValue?.amount)
    const [value, setlValue] = useState(props.contract?.value?.amount)
    const [dateSigned, setDateSigned] = useState(props.contract?.dateSigned)

    function viewDataContract(props) {
        let saveButton = null
        if (props?.auction?.privateData?.isOwner !== false) {
            saveButton = <div
                className={style.btnDone}
                onClick={
                    () => {
                        updateContractData(props)
                    }
                }
            >
                Зберегти
            </div>
        }

        return (
            <div className={style.formLine}>
                <InputText
                    value={title}
                    label='Назва договору'
                    onChange={e => setTitle(e)}
                    reqiured={true}
                />
                <TextArea
                    value={description}
                    label='Опис договору'
                    onChange={e => setDescription(e)}
                    reqiured={true}
                />
                <div className={style.baseWrap}>
                    <div className={style.baseWrapHalf}>
                        <InputText
                            value={contractNumber}
                            label='Номер договору'
                            onChange={e => setContractNumber(e)}
                            reqiured={true}
                        />
                        <InputText
                            value={value}
                            label="Вартість в договорі за 1 кВт⋅год"
                            onChange={e => setlValue(e)}
                            reqiured={true}
                            disabled={true}
                        />
                    </div>
                    <div className={style.baseWrapHalf}>
                        <InputDate
                            data={{
                                target: dateSigned,
                                label: `Дата підписання договору`,
                                reqiured: true,
                                time: true
                            }}
                            onChange={e => setDateSigned(e)}
                        />
                        <InputText
                            value={contractTotalValue}
                            label="Заключна вартість договору"
                            onChange={e => setContractTotalValue(e)}
                            reqiured={true}
                            disabled={false}
                        />
                    </div>
                </div>
                {saveButton}
            </div>
        )
    }


    async function updateContractData(props) {

        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/contract/${props.contract.id}`
        let body = {}
        body.title = title
        body.description = description
        body.contractNumber = contractNumber
        if (contractTotalValue) {
            body.contractTotalValue = {
                amount: contractTotalValue,
                currency: props?.auction?.value?.currency ?? 'eurocent'
            }
        }
        body.dateSigned = dateSigned

        // console.log(body, endPoint);return;
        await props.changeLoader(true)
        //@todo move to api
        await fetch(endPoint, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }

        }).catch(error => {
            props.changeLoader(false)
            console.error('Api request error:', error);
            props.setUniversalError(JSON.stringify({
                title: 'Помилка оновлення данних контракту',
                message: ''
            }))
        }).then(response => {
            if (response.status === 200) {
                props.actionClose(false)
                getUpdateDataAuction(props, props.auction.id)
                props.changeLoader(false)
                return
            }
            const json = response.json()
            if (json.hasOwnProperty('message') === true) {
                let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
                if (json.message.hasOwnProperty('x_prepaymentDetails') === true) {
                    if (json.message.x_prepaymentDetails.hasOwnProperty('date') === true) {
                        if (json.message.x_prepaymentDetails.date === 'Value should be greater than auctionPeriod.endDate and less than now') {
                            tmp = JSON.stringify({
                                title: 'Помилка оновлення данних контракту',
                                message: 'Дата контракту не правильно виставлена'
                            })
                        }
                    }
                }
                props.setUniversalError(tmp)
                props.changeLoader(false)
                return;
            }
            props.changeLoader(false)
            props.setUniversalError(JSON.stringify({
                title: 'Помилка оновлення данних контракту',
                message: ''
            }))
        })
    }

    return (
        <div className={style.wrapMain}>
            <div className={style.wrap}>
                <div
                    className={style.close}
                    onClick={() => {
                        props.actionClose(false)
                    }}
                >✕
                </div>

                <div className={style.popup_main_doc}>
                    <div className={style.dataContracts}>
                        <div className={style.dataContractsTitle}>Дані договору</div>
                        {viewDataContract(props)}
                    </div>
                </div>
                <div className={style.bottomPart}>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    setUniversalError,
    changeContractErrorPopUpStatus,
    changeLoader,
    getUpdateDataAuction,
    setDataAddress,
}

export default  connect(mapStateToProps, mapDispatchToProps)(ContractEditRemPopUp)