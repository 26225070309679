import React, { useEffect, useState } from 'react';
import style from './createAuction.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
    getMyAuction,
    setEditAuctionFromId,
} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

import {setPopUpAuctionCreate, changeAuctionsSwitchWindow, setPopUpAuctionMyNull, auctionsGetMy} from '../../../../redux/actions/auctions/auctions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../../redux/actions/discount/discount.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';
import {
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
} from '../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../redux/actions.js';
import {
    changeLoader,
} from '../../../../redux/actions.js';

import ReturnButton from '../../../createAuction/returnButton'

import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD } from '../../../../redux/procedure';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

const AuctionCreate = (props) => {
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        setFlag(false)
        props.discountAuc(false)
        props.changeDiscountPreviousAuctionId('')
        props.changeDiscountPercent('')
        props.changeDiscountPreviousAuctionAmount('')
        props.setDataAddress(false, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
    }, [])


    if(flag === true) return <Redirect to={`/create_auctions/edit/${props.popUpAuctionCreate.id}`} />
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div>{textForAuctionPopUp(props)}</div>
                {buttonForAuctionPopUp(props, setFlag)}
            </div>
        </div>
    )
}


function buttonForAuctionPopUp(props){
    let tmp;
    tmp = <>

        <ReturnButton />
        <Link
            to={setRoute(props)}
            className={style.buttons}
            onClick={
                () => {
                    props.changeLoader(true)
                    setTimeout(() => 
                    {
                        ///auctionEditSync(props)
                        document.location.reload();
                    }, 1000)
                }
            }
        >
            Продовжити редагування аукціону
        </Link>
    </>
    return tmp
}

function setRoute(props){
    if(
        isBSE(props.popUpAuctionCreate.sellingMethod) || isBSD(props.popUpAuctionCreate.sellingMethod) ||
        isALE(props.popUpAuctionCreate.sellingMethod) ||
        isCSE(props.popUpAuctionCreate.sellingMethod) || isCSD(props.popUpAuctionCreate.sellingMethod) ||
        isBRE(props.popUpAuctionCreate.sellingMethod) || isBRD(props.popUpAuctionCreate.sellingMethod) || isBRW(props.popUpAuctionCreate.sellingMethod) ||
        isRLE(props.popUpAuctionCreate.sellingMethod) || isRLD(props.popUpAuctionCreate.sellingMethod) ||
        isCLE(props.popUpAuctionCreate.sellingMethod) || isCLD(props.popUpAuctionCreate.sellingMethod)

    ){
        return `/create_auctions/editNew/${props.popUpAuctionCreate.id}`
    }
    return `/create_auctions/edit/${props.popUpAuctionCreate.id}`
}

function textForAuctionPopUp(props){
    let tmp;
    if(props.popUpAuctionCreate === 1){
        tmp = 'Помилка при створенні аукціона';
    }else if(props.popUpAuctionCreate === 2){
        tmp = 'Зміни в аукціоні збережено';
    }else{
        tmp = 'Аукціон створено';
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        popUpAwardReject: state.start.popUpAwardReject,
        popUpAwardError: state.start.popUpAwardError,
        popUpAuctionCreate: state.start.popUpAuctionCreate,
    }
}

const mapDispatchToProps = {
    setPopUpAuctionCreate,
    changeAuctionsSwitchWindow,
    setPopUpAuctionMyNull,
    auctionsGetMy,

    getMyAuction,
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    auctionsGetAwards,
    auctionsGetAwardsId,

    getAuctionsBidsId,
    contractsAuctionIdGet,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    
    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeLoader,
    setDataAddress
}

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCreate)