import {
    SET_AUCTIONS_PATH,
    SET_CATEGORY_AUCTION,
    SET_LOGO,
} from '../types.js';

export function setLocal(data){
    if(data === 0){
        return 'uk'
    }else if(data === 1){
        return 'en'
    }
}

export function setAuctionPath(data){
    return {
        type: SET_AUCTIONS_PATH,
        payload: data
    }
}


export function setAuctionCategory(data){
    return {
        type: SET_CATEGORY_AUCTION,
        payload: data
    }
}

export function setLogo(data){
    
    return {
        type: SET_LOGO,
        payload: data
    }
}