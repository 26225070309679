import React from 'react';
import style from './address.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';

import {
    changePopUpBankAccount,
    bankAccountGet,
    openBankWindows,
    setPopBankAccountNazvaBanku,
    setPopBankAccountOpus,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountEditId,
} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    addressesGetMy,
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../redux/actions/address/address.js';

import {setDataAddress} from '../../../redux/createAddress';

const ListAddress = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={() => {
                            props.setDataAddress(false, 'SET_POPUP_ADDRESSES')
                        }
                    }
                    
                >
                    X
                </div>
                <div className={style.popup_main_doc}>
                    <h3>Робота з адресами</h3>
                    <Button
                        className={style.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.popUpAddress(true)
                            }
                        }
                    >
                        Створити нову адресу
                    </Button>
                    <div className={style.bankAccountElement}>
                        <div className={style.pn}>П/н</div>
                        <div className={style.bankTitle}>Назва країни</div>
                        <div className={style.bankDescription}>Область</div>
                        <div className={style.accountIdentificationsDescription}>Місто</div>
                        <div className={style.accountIdentificationsIdentifier}>Поштовий індекс</div>
                        <div className={style.accountIdentificationsShema}>Адреса</div>
                    </div>
                    <div className={style.mainDocumentsBlock}>
                        {viewListBankAccounts(props)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function viewListBankAccounts(props){
    let tmp = null;
    if(props.addressGetId !== null){
        tmp = props.addressGetId
        .map(
            (i, count) => {
                return (
                    <div className={style.bankAccountElement}>
                        <div className={style.pn}>{count + 1}</div>
                        <div className={style.bankTitle}>{i.countryName}</div>
                        <div className={style.bankDescription}>{i.region}</div>
                        <div className={style.accountIdentificationsDescription}>{i.locality}</div>
                        <div className={style.accountIdentificationsIdentifier}>{i.postalCode}</div>
                        <div className={style.accountIdentificationsShema}>{i.streetAddress}</div>
                        <Button
                            className={style.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {

                                    props.countryNameAddress(i.countryName)
                                    let tmp = null
                                    switch(i.region){
                                        case 'Автономна Республіка Крим':
                                            tmp = "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                            break;
                                        case 'Вінницька область':
                                            tmp = "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                            break;
                                        case 'Волинська область':
                                            tmp = "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                            break;
                                        case 'Дніпропетровська область':
                                            tmp = "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                            break;
                                        case 'Донецька область':
                                            tmp = "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                            break;
                                        case 'Житомирська область':
                                            tmp = "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                            break;
                                        case 'Закарпатська область':
                                            tmp = "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                            break;
                                        case 'Запорізька область':
                                            tmp = "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                            break;
                                        case 'Івано-Франківська область':
                                            tmp = "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                            break;
                                        case 'Київська область':
                                            tmp = "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                            break;
                                        case 'Кіровоградська область':
                                            tmp = "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                            break;
                                        case 'Луганська область':
                                            tmp = "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                            break;
                                        case 'Львівська область':
                                            tmp = "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                            break;
                                        case 'Миколаївська область':
                                            tmp = "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                            break;
                                        case 'Одеська область':
                                            tmp = "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                            break;
                                        case 'Полтавська область':
                                            tmp = "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                            break;
                                        case 'Рівненська область':
                                            tmp = "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                            break;
                                        case 'Сумська область':
                                            tmp = "СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                            break;
                                        case 'Тернопільська область':
                                            tmp = "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                            break;
                                        case 'Харківська область':
                                            tmp = "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                            break;
                                        case 'Херсонська область':
                                            tmp = "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                            break;
                                        case 'Хмельницька область':
                                            tmp = "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                            break;
                                        case 'Черкаська область':
                                            tmp = "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                            break;
                                        case 'Чернівецька область':
                                            tmp = "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                            break;
                                        case 'Чернігівська область':
                                            tmp = "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                            break;
                                        case 'Київ':
                                            tmp = "М.КИЇВ"
                                            break;
                                        case 'Севастополь':
                                            tmp = "М.СЕВАСТОПОЛЬ"
                                            break;
                                        default:
                                            break;
                                    }
                                    createLine(props, tmp)
                                    //props.popUpAddressId(i.id)
                                    props.setDataAddress(i.id, 'SET_POP_UP_ADDRESS_ID')
                                    //Сохранение Айди Области
                                    //Сделать если АйДи не нулл то пут на пересохранение адреса и очистку полей при закрытии
                                    props.localityAddress(i.locality)
                                    props.postalCodeAddress(i.postalCode)
                                    props.streetAddress(i.streetAddress)
                                    props.popUpAddress(true)
                                }
                            }
                        >
                            Редагувати
                        </Button>
                        {/*<Button
                            className={style.buttonExitDocument}
                            variant="contained"
                            color="secondary"
                            onClick={
                                () => {
                                    deleteAddress(props, i.id)
                                }
                            }
                        >
                            Вилучити
                        </Button>*/}
                    </div>
                )
            }
        )
    }
    return tmp
}

function createLine(props, tmp){
    let tmpRegionOption = null
    if(props.koatuu.region !== null){
        for(let i = 0; i < props.koatuu.region.length; i++){
            if(props.koatuu.region[i].description === tmp){
                tmpRegionOption = `${props.koatuu.region[i].classificationId}+:+${props.koatuu.region[i].description}`
            }
        }
    }
    if(tmpRegionOption !== null){
        props.regionAddress(tmpRegionOption)
        let t = tmpRegionOption.substr(0, 2)
        props.setKoatuuGorod(null)
        props.localityAddress('null')
        getKoatuuGorod(props, t)
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createDocuments: state.start.createDocuments,
        addressGetId: state.start.addressGetId,
        popUpBankAccountSheme: state.start.popUpBankAccountSheme,
        koatuu: state.start.koatuu,
    }
}

const mapDispatchToProps = {
    openBankWindows,
    changePopUpBankAccount,
    bankAccountGet,
    setPopBankAccountNazvaBanku,
    setPopBankAccountOpus,
    setPopBankAccountNazva,
    setPopBankAccountIdentif,
    setPopBankAccountSheme,
    setPopBankAccountEditId,

    addressesGetMy,
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAddress)