import React from 'react';

import {connect} from 'react-redux';

import EditDocumentAward from './editDocumentAward'
import EditDocumentContract from './editDocumentContract'

const DeleteBidDocument = ( props ) => {
    if(props.popUpEditAppruveDoc.status === 'AWARD'){
        return <EditDocumentAward />
    }else if(props.popUpEditAppruveDoc.status === 'CONTRACT'){
        return <EditDocumentContract />
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,

        awardDocument: state.start.awardDocument,
        popUpEditAppruveDoc: state.start.popUpEditAppruveDoc,
    }
}

export default connect(mapStateToProps, null)(DeleteBidDocument)