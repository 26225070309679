import React,{useState} from 'react';
import styles from './slimmerBlock.module.css';


const SlimmerBlock =(props)=> {	
	const [slim, setSlim] = useState(()=>{
            if(props.slim) return { state: true, text: 'Згорнути' }
            return { state: false, text: 'Розгорнути' }
        }
    )
    
    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }
						
						
	return <div className={styles.slimmerBlock}>
				<div className={styles.slimmerBlock_title}>{props.title}</div>
				<div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
				{slim.state ?
					<div className={styles.slimmerBlockContent}>
						{props.content}
					</div> : null
				}
			</div>
}

export default SlimmerBlock