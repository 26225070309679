import React from "react"

const ProzoroSalesLogo =( )=> {
    return  <svg style={{display:'flex'}}
                width="120" 
                height="40"  
                viewBox="0 0 421 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M218.711 35.6077C218.711 44.5527 213.214 50.8888 205.201 50.8888C200.635 50.8888 197.001 48.8389 194.671 45.4845V60.2997H191.503V20.7924H194.206L194.578 26.1035C196.908 22.4696 200.728 20.2333 205.201 20.2333C213.214 20.3265 218.711 26.6626 218.711 35.6077ZM215.45 35.6077C215.45 28.0603 211.164 23.0287 204.921 23.0287C198.492 23.0287 194.392 28.0603 194.392 35.6077C194.392 43.155 198.585 48.1866 204.921 48.1866C211.257 48.1866 215.45 43.155 215.45 35.6077Z"
                    fill="black"/>
                <path d="M241.354 20.4197V23.4946C240.422 23.4014 239.304 23.4014 238.652 23.4014C232.688 23.4014 228.588 27.408 228.588 33.3714V50.4229H225.42V20.7924H228.123L228.495 26.0104C230.545 22.4696 234.645 20.3265 239.49 20.3265C240.049 20.3265 240.608 20.3265 241.354 20.4197Z"
                    fill="black"/>
                <path d="M242.658 35.6077C242.658 26.6626 248.528 20.3265 257.008 20.3265C265.58 20.3265 271.357 26.4763 271.357 35.6077C271.357 44.5527 265.487 50.8888 257.008 50.8888C248.435 50.8888 242.658 44.8323 242.658 35.6077ZM268.096 35.6077C268.096 27.9671 263.716 23.0287 257.008 23.0287C250.392 23.0287 245.919 28.0603 245.919 35.6077C245.919 43.2482 250.392 48.1867 257.008 48.1867C263.623 48.1867 268.096 43.1551 268.096 35.6077Z"
                    fill="black"/>
                <path d="M298.192 47.5344V50.4229H273.5V47.348L293.812 23.6809H274.338V20.7924H297.446V23.8673L277.227 47.5344H298.192Z"
                    fill="black"/>
                <path d="M300.242 35.6077C300.242 26.6626 306.112 20.3265 314.591 20.3265C323.163 20.3265 328.94 26.4763 328.94 35.6077C328.94 44.5527 323.07 50.8888 314.591 50.8888C305.926 50.8888 300.242 44.8323 300.242 35.6077ZM325.586 35.6077C325.586 27.9671 321.207 23.0287 314.498 23.0287C307.882 23.0287 303.41 28.0603 303.41 35.6077C303.41 43.2482 307.882 48.1867 314.498 48.1867C321.113 48.1867 325.586 43.1551 325.586 35.6077Z"
                    fill="black"/>
                <path d="M351.396 20.4197V23.4946C350.465 23.4014 349.346 23.4014 348.694 23.4014C342.731 23.4014 338.631 27.408 338.631 33.3714V50.4229H335.463V20.7924H338.165L338.538 26.0104C340.588 22.4696 344.687 20.3265 349.533 20.3265C350.185 20.3265 350.744 20.3265 351.396 20.4197Z"
                    fill="black"/>
                <path d="M372.175 20.4197V23.4946C371.243 23.4014 370.125 23.4014 369.473 23.4014C363.51 23.4014 359.41 27.408 359.41 33.3714V50.4229H356.242V20.7924H358.944L359.317 26.0104C361.366 22.4696 365.466 20.3265 370.312 20.3265C370.871 20.3265 371.523 20.3265 372.175 20.4197Z"
                    fill="black"/>
                <path d="M373.573 35.6077C373.573 26.6626 379.443 20.3265 387.922 20.3265C396.494 20.3265 402.271 26.4763 402.271 35.6077C402.271 44.5527 396.401 50.8888 387.922 50.8888C379.257 50.8888 373.573 44.8323 373.573 35.6077ZM398.917 35.6077C398.917 27.9671 394.538 23.0287 387.829 23.0287C381.213 23.0287 376.741 28.0603 376.741 35.6077C376.741 43.2482 381.213 48.1867 387.829 48.1867C394.445 48.1867 398.917 43.1551 398.917 35.6077Z"
                    fill="black"/>
                <path d="M218.898 98.0368H210.978V75.4878H199.424V98.0368H191.503V68.4063H218.991V98.0368H218.898Z" fill="black"/>
                <path d="M255.423 83.2215C255.423 92.4461 250.299 98.5027 242.751 98.5027C238.931 98.5027 235.856 97.0118 233.527 94.5892V107.914H225.606V68.4063H232.688L233.061 72.5993C235.297 69.6176 238.558 67.9404 242.658 67.9404C250.485 67.9404 255.423 73.9038 255.423 83.2215ZM247.41 83.2215C247.41 77.8172 244.708 74.4628 240.329 74.4628C235.856 74.4628 233.34 77.8172 233.34 83.2215C233.34 88.6258 235.949 91.9802 240.329 91.9802C244.801 91.9802 247.41 88.6258 247.41 83.2215Z"
                    fill="black"/>
                <path d="M258.591 83.2215C258.591 73.9969 264.834 67.9404 274.059 67.9404C283.47 67.9404 289.526 73.9969 289.526 83.2215C289.526 92.4461 283.283 98.5027 274.059 98.5027C264.648 98.5027 258.591 92.4461 258.591 83.2215ZM281.42 83.2215C281.42 77.7241 278.531 74.4628 274.059 74.4628C269.493 74.4628 266.698 77.7241 266.698 83.2215C266.698 88.719 269.586 91.9802 274.059 91.9802C278.625 91.9802 281.42 88.719 281.42 83.2215Z"
                    fill="black"/>
                <path d="M325.866 90.8621V104.839H318.225V97.9436H299.403V104.839H291.763V90.8621H295.397C295.769 88.6258 295.956 85.3646 295.956 80.7057V68.4063H321.859V90.9553H325.866V90.8621ZM314.032 91.1416V75.3014H303.689V80.9853C303.689 84.8056 303.503 88.2531 303.13 91.0485H314.032V91.1416Z"
                    fill="black"/>
                <path d="M357.826 91.2348V98.0368C357.267 98.13 356.428 98.2232 354.937 98.2232C351.303 98.2232 349.347 96.8255 348.694 93.7506C346.645 96.7323 342.731 98.5027 338.445 98.5027C332.575 98.5027 328.754 95.0551 328.754 89.9303C328.754 82.1966 335.929 80.8921 341.52 80.1467C344.874 79.774 347.576 79.2149 347.576 77.4445C347.576 75.581 345.62 74.2765 342.265 74.2765C339.19 74.2765 336.395 75.4878 334.159 77.7241L329.407 72.8788C332.947 69.5244 337.42 67.8472 342.731 67.8472C350.465 67.8472 354.937 71.5743 354.937 77.8172V89.4644C354.937 90.5826 355.496 91.1417 356.615 91.1417H357.826V91.2348ZM347.297 86.4828V83.8738C346.085 84.526 344.408 84.8987 342.265 85.2715C339.19 85.7373 336.302 86.2964 336.302 89.0917C336.302 91.0485 337.699 92.2598 340.122 92.2598C344.222 92.2598 347.297 89.744 347.297 86.4828Z"
                    fill="black"/>
                <path d="M392.208 85.3646L388.667 89.0917V97.9436H380.747V89.0917L377.113 85.3646L369.473 98.0368H360.155L371.336 80.1467L360.341 68.4063H370.218L380.654 80.5194V68.4063H388.574V80.4262L399.01 68.4063H408.794L397.799 80.0535L409.073 98.0368H399.755L392.208 85.3646Z"
                    fill="black"/>
                <path d="M412.521 57.8772H421V64.586H412.521V57.8772ZM412.894 68.4063H420.814V98.0368H412.894V68.4063Z"
                    fill="black"/>
                <path d="M38.133 87.6008C34.1263 91.6074 27.6039 91.6074 23.6904 87.6008L3.00498 66.9154C-1.00166 62.9087 -1.00166 56.3863 3.00498 52.4728L23.6904 31.6942C27.6971 27.6876 34.2195 27.6876 38.133 31.6942L58.8184 52.3796C62.8251 56.3863 62.8251 62.9087 58.8184 66.8222L38.133 87.6008ZM97.5803 28.3398C100.469 25.4513 100.469 20.7924 97.5803 17.9971L82.7651 3.08865C79.8766 0.200145 75.2177 0.200145 72.4224 3.08865L57.5139 17.9039C54.6254 20.7924 54.6254 25.4513 57.5139 28.2466L72.3292 43.0619C75.2177 45.9504 79.8766 45.9504 82.6719 43.0619L97.5803 28.3398ZM97.7667 101.298C100.562 98.5026 100.562 93.9369 97.7667 91.1416L83.3241 76.699C80.5288 73.9037 75.9631 73.9037 73.1678 76.699L58.7252 91.1416C55.9299 93.9369 55.9299 98.5026 58.7252 101.298L73.1678 115.74C75.9631 118.536 80.5288 118.536 83.3241 115.74L97.7667 101.298ZM122.645 63.2814C124.695 61.2315 124.695 57.9703 122.645 55.9204L112.209 45.4845C110.159 43.4346 106.898 43.4346 104.848 45.4845L94.4123 55.9204C92.3624 57.9703 92.3624 61.2315 94.4123 63.2814L104.848 73.7173C106.898 75.7672 110.159 75.7672 112.209 73.7173L122.645 63.2814ZM144.821 41.1983C146.033 39.987 146.033 37.9371 144.821 36.7258L138.485 30.3897C137.274 29.1784 135.224 29.1784 134.013 30.3897L127.677 36.7258C126.465 37.9371 126.465 39.987 127.677 41.1983L134.013 47.5344C135.224 48.7457 137.274 48.7457 138.485 47.5344L144.821 41.1983ZM145.008 82.8487C146.126 81.7306 146.126 79.8671 145.008 78.7489L139.138 72.8787C138.019 71.7606 136.156 71.7606 135.038 72.8787L129.168 78.7489C128.049 79.8671 128.049 81.7306 129.168 82.8487L135.038 88.7189C136.156 89.8371 138.019 89.8371 139.138 88.7189L145.008 82.8487ZM159.171 60.952C159.916 60.2066 159.916 58.9953 159.171 58.2498L155.35 54.4296C154.605 53.6841 153.394 53.6841 152.648 54.4296L148.828 58.2498C148.083 58.9953 148.083 60.2066 148.828 60.952L152.648 64.7723C153.394 65.5177 154.605 65.5177 155.35 64.7723L159.171 60.952Z"
                    fill="url(#paint0_linear)"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="0.0232944" y1="59.4057" x2="159.708" y2="59.4057"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.0999999" stopColor="#9100C3"/>
                        <stop offset="0.5" stopColor="#F32058"/>
                        <stop offset="0.9" stopColor="#FC7A19"/>
                    </linearGradient>
                </defs>
            </svg>

    
    
}

export default ProzoroSalesLogo