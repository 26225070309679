import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const NonRemovable = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('nonRemovable') === true){
            if(props.auctionId.nonRemovable !== null){
                let tmp
                if(props.auctionId.nonRemovable === true){
                    tmp = 'Так'
                }else{
                    tmp = 'Ні'
                }
                return (
                    <TextHalf
                        title="Наявність арештів, що не можуть бути зняті виконавцем після продажу"
                        data={tmp}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(NonRemovable)