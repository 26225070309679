import React from 'react';

import {connect} from 'react-redux';
import {transformData} from '../../../../redux/modifiDate'

import TextHalf from '../../../elements/viewData/textFull'

const OverdueDurationString = (obj) => {
    if(obj.obj !== null){
        if(obj.obj.hasOwnProperty('overdueDuration') === true){
            if(obj.obj.overdueDuration !== null){
                return (
                    <TextHalf
                        title="Термін прострочення платежу"
                        data={transformData(obj.obj.overdueDuration, 'Y') + ' ' + transformData(obj.obj.overdueDuration, 'M') + ' ' + transformData(obj.obj.overdueDuration, 'D')}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(OverdueDurationString)