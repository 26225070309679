import React from 'react';
import style from './tariffs.module.css';

export default function Tariffs(){
    return (
        <section>
            <header className={style.header}><h1>ТАРИФИ</h1></header>
            <article>
                <p><strong>Державніторги.онлайн працює на засадах та у повній відповідності до&nbsp;<a href="/files/PPP_DTO.pdf" target="_blank">ПРАВИЛ ПРОФЕСІЙНОЇ ПОВЕДІНКИ</a>&nbsp;Оператора електронного майданчика, що підключений до Електронної торгової системи Prozorro.Продажі</strong></p>
                <p><a href="/files/Tarifi_FGVFO.pdf" rel="noopener noreferrer">- ТАРИФИ ФГВФО З РЕАЛІЗАЦІЇ АКТИВІВ (МАЙНА) БАНКІВ, ЩО ЛІКВІДУЮТЬСЯ</a></p>
                <p><a href="/files/Tarifi_CBD2_02.12.2019.pdf" rel="noopener noreferrer">- </a><a href="/files/Tarifi_CBD2_22.01.2020.pdf" rel="noopener noreferrer">ТАРИФИ З ПРОДАЖУ/ОРЕНДИ МАЙНА КОМУНАЛЬНИХ, ДЕРЖАВНИХ ПІДПРИЄМСТВ&nbsp;ТА ПІДПРИЄМСТВ ПРИВАТНОЇ ФОРМИ ВЛАСНОСТІ</a></p>
                <p><a href="/files/Tarifi_privatization_834.pdf" rel="noopener noreferrer">- </a><a href="/files/Privatization_tarif_22.01.20.pdf" rel="noopener noreferrer">ТАРИФИ З ПРОДАЖУ ОБ'ЄКТІВ МАЛОЇ ПРИВАТИЗАЦІЇ</a></p>
                <p><a href="/files/Tarifi_Bankrutstvo_01.01.2020.pdf" rel="noopener noreferrer"><span>- ТАРИФИ З ПРОДАЖУ МАЙНА БОРЖНИКІВ У СПРАВАХ ПРО БАНКРУТСТВО ( НЕПЛАТОСПРОМОЖНІСТЬ)</span></a></p>
                <p><a href="/files/tarify_wood.pdf" rel="noopener noreferrer"><span>- ТАРИФИ З ПРОДАЖУ НЕОБРОБЛЕНОЇ ДЕРЕВИНИ</span></a></p>
            </article>
        </section>
    )
}
