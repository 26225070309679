import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'
import TextFullHeader from '../../structureLot/auctionType/textFullHeader'

import {modifiDateString} from '../../../../redux/modifiDate'

const LotCurrentTenantString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('currentTenant') === true){
            if(props.auctionId.currentTenant !== null){
                return (
                    <>
                        <TextFullHeader
                            title="Дані про учасника з переважним правом"
                            data=''
                        />
                        {viewName(props.auctionId.currentTenant)}
                        {viewRepresentativeInfo(props.auctionId.currentTenant)}
                        {viewAddress(props.auctionId.currentTenant)}
                        {viewIdentifier(props.auctionId.currentTenant)}
                        {viewCurrentContractTime(props.auctionId.currentTenant)}
                        {viewCurrentTenantValue(props.auctionId.currentTenant)}
                    </>
                )
            }
        }
        if(props.auctionId.hasOwnProperty('currentTenants') === true){
            if(props.auctionId.currentTenants !== null){
                return props.auctionId.currentTenants.map((e) =>{
                    return <>
                        <TextFullHeader
                            title="Дані про учасника з переважним правом"
                            data=''
                        />
                        {viewName(e)}
                        {viewRepresentativeInfo(e)}
                        {viewAddress(e)}
                        {viewIdentifier(e)}
                        {viewCurrentContractTime(e)}
                        {viewCurrentTenantValue(e)}
                        {viewPriority(e)}
                        {viewPriorityTransferInfo(e)}
                    </>
                })
            }
        }
    }
    return null
}

function viewPriorityTransferInfo(data){
    if(data.hasOwnProperty('priorityTransferInfo') === true && data.priorityTransferInfo !== null){
        return <>
            <TextFullHeader
                title="Інформація про передачу переважного права"
                data=''
            />
            {viewTransferredFrom(data.priorityTransferInfo)}
            {viewNameNew(data.priorityTransferInfo)}
            {viewIdentifierId(data.priorityTransferInfo)}
        </>
    }
    return null
}

function viewName(data){
    if(data.hasOwnProperty('name') === true){
        if(data.name !== null){
            return (
                <TextHalf
                    title="Повна юридична назва організації або ПІБ"
                    data={data.name.uk_UA}
                />
            )
        }
    }
    return null
}
function viewNameNew(data){
    if(data.hasOwnProperty('name') === true){
        if(data.name !== null){
            return (
                <TextHalf
                    title="Назва організації"
                    data={data.name.uk_UA}
                />
            )
        }
    }
    return null
}

function viewTransferredFrom(data){
    if(data.hasOwnProperty('transferredFrom') === true){
        if(data.priority !== null){
            return (
                <TextHalf
                    title="Право передано від"
                    data={data.transferredFrom === '0' ? "Орендаря" : "Надрокористувача"}
                />
            )
        }
    }
    return null
}

function viewPriority(data){
    if(data.hasOwnProperty('priority') === true){
        if(data.priority !== null){
            return (
                <TextHalf
                    title="Учасник"
                    data={data.priority === 0 ? "З переважним правом" : "З переважним правом другої черги"}
                />
            )
        }
    }
    return null
}

function viewRepresentativeInfo(data){
    if(data.hasOwnProperty('representativeInfo') === true){
        if(data.representativeInfo !== null){
            return (
                <TextHalf
                    title="Інформація щодо підтвердження повноважень"
                    data={data.representativeInfo}
                />
            )
        }
    }
    return null
}


function viewAddress(data){
    if(data.hasOwnProperty('address') === true){
        if(data.address !== null){
            return (
                <>
                    <TextFullHeader
                        title="Адреса"
                        data=''
                    />
                    <TextHalf
                        title="Країна"
                        data={data.address.countryName.uk_UA}
                    />
                    <TextHalf
                        title="Поштовий індекс"
                        data={data.address.postalCode}
                    />
                    <TextHalf
                        title="Область"
                        data={data.address.region.uk_UA}
                    />
                    <TextHalf
                        title="Населений пункт"
                        data={data.address.locality.uk_UA}
                    />
                    <TextHalf
                        title="Адреса"
                        data={data.address.streetAddress.uk_UA}
                    />
                    {viewAddressID(data.address)}
                </>
            )
        }
    }
    return null
}

function viewAddressID(data){
    if(data.hasOwnProperty('addressID') === true){
        if(data.addressID !== null){
            return (
                <>
                    <TextFullHeader
                        title="Код об’єктів адміністративно-територіального устрою України"
                        data=''
                    />
                    <TextHalf
                        title="Країна"
                        data={data.addressID.scheme}
                    />
                    <TextHalf
                        title="Поштовий індекс"
                        data={data.addressID.name.uk_UA}
                    />
                    <TextHalf
                        title="Область"
                        data={data.addressID.id}
                    />
                </>
            )
        }
    }
    return null
}

function viewIdentifier(data){
    if(data.hasOwnProperty('identifier') === true){
        if(data.identifier !== null){
            if(data.identifier.scheme !== null && data.identifier.legalName !== null && data.identifier.id !== null){
                return (
                    <>
                        <TextFullHeader
                            title="Ідентифікатори організації або особи"
                            data=''
                        />
                        <TextHalf
                            title="Ідентифікатори організації"
                            data={schemeUA(data.identifier.scheme)}
                        />
                        <TextHalf
                            title="Повна юридична назва організації"
                            data={data.identifier.legalName.uk_UA}
                        />
                        <TextHalf
                            title="Код ЄДРПОУ або ІПН або паспорт"
                            data={data.identifier.id}
                        />
                    </>
                )
            }
        }
    }
    return null
}

function viewIdentifierId(data){
    if(data.hasOwnProperty('identifier') === true){
        if(data.identifier !== null){
            if(data.identifier.scheme !== null && data.identifier.legalName !== null && data.identifier.id !== null){
                return (
                    <>
                        <TextFullHeader
                            title="Ідентифікатори організації або особи"
                            data=''
                        />
                        <TextHalf
                            title="Ідентифікатори організації"
                            data={schemeUA(data.identifier.scheme)}
                        />
                        <TextHalf
                            title="Повна юридична назва організації"
                            data={data.identifier.legalName.uk_UA}
                        />
                        <TextHalf
                            title="Код ЄДРПОУ або ІПН або паспорт"
                            data={data.identifier.id}
                        />
                    </>
                )
            }
        }
    }
    return null
}

function schemeUA(data){
    switch(data){
        case 'UA-EDR': return 'ЄДРПОУ'
         case 'UA-IPN': return 'ІПН/РНОКПП'
         case 'UA-PASSPORT': return 'Паспорт' 
         case 'UA-ID-CARD': return 'ID-карта' 
         case 'UA-IPN-FOP': return 'ІПН/РНОКПП (ФОП)' 
         case 'AE-ACCI': return 'Ajman Chamber of Commerce and Industry' 
         case 'AE-ADCD': return 'Abu Dhabi Commercial Directory' 
         case 'AE-AFZ': return 'Ajman Free Zone Authority' 
         case 'AE-DCCI': return 'Dubai Chamber of Commerce and Industry' 
         case 'AE-DFSA': return 'Dubai Financial Services Authority' 
         case 'AE-DIFC': return 'Dubai International Financial Centre' 
         case 'AE-DMCC': return 'Dubai Multi Commodities Centre' 
         case 'AE-FCCI': return 'Fujairah Chamber of Commerce and Industry' 
         case 'AE-FFZ': return 'Fujairah Free Zone Company Listing' 
         case 'AE-HFZA': return 'Hamriyah Free Zone Authority' 
         case 'AE-RAKIA': return 'Ras al-Khaimah Investment Authority' 
         case 'AE-RK_CR': return 'Ras al-Khaimah Chamber of Commerce and Industry' 
         case 'AE-SAIF': return 'Sharjah Airport International Free Zone' 
         case 'AE-SCCI': return 'Sharjah Chamber of Commerce and Industry' 
         case 'AE-UQCCI': return 'Umm al-Quwain Chamber of Commerce and Industry' 
         case 'AF-CBR': return 'Afghanistan Central Business Registry' 
         case 'AF-MOE': return 'Ministry of Economy NGO Department' 
         case 'AR-CENOC': return 'The National Centre of Community Organisations (Argentina)' 
         case 'AR-CUIT': return 'Unique Tax Identification Code (Argentina)' 
         case 'AT-FB': return 'Austria Company Register' 
         case 'AT-UID': return 'VAT number (Austria Company Register)' 
         case 'AU-ABN': return 'Australian Business Register' 
         case 'AU-ACNC': return 'Australian Charities and Not-for-profits Commission' 
         case 'AZ-IVI': return 'State Register of Commercial Entities (Ministry of Taxes of Azerbaijan Republic)' 
         case 'BD-NAB': return 'Bangladesh NGO Affairs Bureau' 
         case 'BE-BCE_KBO': return 'Crossroads Bank for Enterprises' 
         case 'BE-GTCF': return 'Au Greffe du Tribunal de Commerce Francophone de Bruxelles' 
         case 'BG-EIK': return 'Commercial Register' 
         case 'BJ-IFU': return 'Unique Tax Identifier' 
         case 'BW-CIPA': return 'Companies and Intellectual Property Authority (Botswana)' 
         case 'BY-ADR': return 'Unified State Register of Legal Entities and Indiv… (Ministry of Justice of the Republic of Belarus)' 
         case 'CA-CC': return 'Corporations Canada' 
         case 'CA-CRA_ACR': return 'Canadian Revenue Agency' 
         case 'CA-GOV': return 'List of legal department legalNames (Government of Canada)' 
         case 'CA_AB-ABT': return 'Corporate Registry Office' 
         case 'CA_BC-BRC_CBR': return 'British Columbia Corporate Registry' 
         case 'CA_MB-MTB': return 'Manitoba Companies Office, Department of Entrepreneurship, Training and Trade' 
         case 'CA_NB-NWB_NOB': return 'Corporate Registry' 
         case 'CA_NL-NFL_TNL': return 'Registry of Companies, Department of Government Services' 
         case 'CA_NS-NVS_NVE': return 'Nova Scotia Registry of Joint Stock Companies' 
         case 'CA_NT-NWT_TNO': return 'Canadian Provincial Corporate Registration - Northwest Territories' 
         case 'CA_NU-NNV': return 'Nunavut Department of Justice - Corporate Registries' 
         case 'CA_ON-ONT': return 'ServiceOntario, Ministry of Government Services' 
         case 'CA_PE-PEI_IPE': return 'Prince Edward Island Corporate' 
         case 'CA_QC-QBC': return 'Quebec Business Registrar' 
         case 'CA_SK-SKN': return 'Saskatchewan Corporate Registry' 
         case 'CA_YT-YKT': return 'Yukon Corporate Affairs' 
         case 'CH-FDJP': return 'Commercial Registry, Federal Office of Justice (Switzerland)' 
         case 'CN-SAIC': return 'State Administration for Industry and Commerce (SAIC)' 
         case 'CO-CCB': return 'Bogota Chamber of Commerce' 
         case 'CO-RUE': return 'Unified Commercial and Social Registry (RUES)' 
         case 'CY-DRCOR': return 'Cyprus Department of Registrar of Companies and Official Receiver (DRCOR)' 
         case 'CZ-DIC': return 'Tax ID (DI\\u010C) Czech Republic' 
         case 'CZ-ICO': return 'Access to Registers of Economic Subjects' 
         case 'DE-CR': return 'Common Register Portal of the German Federal States (CRP)' 
         case 'DK-CVR': return 'Danish Central Business Register' 
         case 'EE-KMKR': return 'e-Business Register (Estonia)' 
         case 'EE-RIK': return 'Centre of Registers and Information Systems (RIK)' 
         case 'EG-MOSS': return 'Ministry of Social Solidarity and Justice (Egypt)' 
         case 'ES-DIR3': return 'Common Directory of Organizational Units and Offices - DIR3' 
         case 'ES-RMC': return 'Central Commercial Register of the Kingdom of Spain' 
         case 'ET-CSA': return 'Charities and Societies Agency (Ethiopia)' 
         case 'ET-MFA': return 'Ministry of Foreign Affairs' 
         case 'ET-MOT': return 'Ministry of Trade (Ethiopia)' 
         case 'FI-PRO': return 'Finnish Patent and Registration Office' 
         case 'FR-INSEE': return 'The National Institute of Statistics and Economic Studies' 
         case 'FR-RCS': return 'Trade and Companies Register' 
         case 'GB-CHC': return 'Charity Commission' 
         case 'GB-COH': return 'Companies House' 
         case 'GB-EDU': return 'Register of Schools (England and Wales)' 
         case 'GB-GOR': return 'Government Organisation Register' 
         case 'GB-GOV': return 'UK Government Departments Reference Numbers (IATI Standard)' 
         case 'GB-GOVUK': return 'GOV.UK - UK Government Departments, Agencies &amp; Public Bodies' 
         case 'GB-IRN': return 'Schools Plus, Department of Education (Northern Ireland)' 
         case 'GB-LAE': return 'Local Authorities for England Register' 
         case 'GB-LAS': return 'Scottish Local Authority Register' 
         case 'GB-MPR': return 'Mutuals Public Register' 
         case 'GB-NHS': return 'NHS Digital - Organisation Data Service' 
         case 'GB-NIC': return 'The Charity Commission for Northern Ireland' 
         case 'GB-PLA': return 'Principal Local Authority Register for Wales' 
         case 'GB-REV': return 'HM Revenue and Customs' 
         case 'GB-SC': return 'Scottish Charity Register' 
         case 'GB-SHPE': return 'Registered Social Housing Providers (England)' 
         case 'GB-UKPRN': return 'UK Register of Learning Providers' 
         case 'GE-NAPR': return 'Register of Entrepreneurial and Non-Entrepreneurial Legal Entities, Georgia' 
         case 'GG-RCE': return 'Guernsey Registry' 
         case 'GH-DSW': return 'Department of Social Developments' 
         case 'HK-CR': return 'Hong Kong Companies Register' 
         case 'HR-MBS': return 'Croatian Court Business Register' 
         case 'HR-OIB': return 'Croatia Court Register' 
         case 'HU-AFA': return 'Information and Electronic Company Registration Service' 
         case 'ID-KDN': return 'Ministry of Home Affairs' 
         case 'ID-KHH': return 'Ministry of Justice &amp; Human Rights' 
         case 'ID-KLN': return 'Ministry of Foreign affairs' 
         case 'ID-PRO': return 'Indonesia - NGOs registered at Provinicial Level' 
         case 'ID-SMR': return 'The SMERU Research Institute' 
         case 'IE-CHY': return 'Charities Regulatory Authority of Ireland' 
         case 'IE-CRO': return 'Irish CompaniesRegistration Office' 
         case 'IL-ROC': return 'Registrar of Companies (Israel)' 
         case 'IM-CR': return 'Isle of Man Companies Registry' 
         case 'IM-GR': return 'Isle of Man Index of Registered Charities' 
         case 'IN-MCA': return 'Government of India, Ministry of Corporate Affairs' 
         case 'IN-MHA': return 'Ministry of Home Affairs (India) Foreign Contributions (Regulation) Act Register' 
         case 'IT-CF': return 'Italian Tax Code / VAT Number' 
         case 'IT-RI': return 'Business Register of the Italian Chambers of Commerce' 
         case 'JE-FSC': return 'Jersey Financial Services Commission (JFSC)' 
         case 'JE-OAC': return 'Jersey Overseas Aid Commission' 
         case 'JO-CCD': return 'Companies Control Department (Jordan)' 
         case 'JO-MSD': return 'Register of Associations, Jordan' 
         case 'JP-JCN': return 'National Tax Agency Corporate Number Publication Site' 
         case 'KE-NCB': return "NGO's Coordination Board" 
         case 'KE-RCO': return 'Registar of Companies' 
         case 'KE-RSO': return 'Registrar of Societies' 
         case 'KG-ID': return 'Electronic database of legal entities and branches (Krygyzstan)' 
         case 'KG-INN': return 'Kyrgyz Republic Register of Legal Entities' 
         case 'KZ-BIN': return 'Business Identification Number (BIN)' 
         case 'LB-CR': return 'Lebanese Ministry of Justice, Commercial Register' 
         case 'LB-MOI': return 'Ministry of Interior (Lebanon)' 
         case 'LS-LCN': return 'Lesotho Council of Non Governmental Organisations' 
         case 'LT-PVM': return 'Lithuania Register of Legal Entities' 
         case 'LT-RC': return 'Information Platform of Legal Entities (Lithuania)' 
         case 'LV-RE': return 'Register of Enterprises of the Republic of Latvia' 
         case 'MD-IDNO': return 'Legal entity registration number (IDNO) Moldova' 
         case 'ML-CCIM': return 'The Chamber of Commerce and Industry of Mali' 
         case 'ML-NIF': return 'Tax Identification Number' 
         case 'MM-MHA': return 'Ministry of Home Affairs - Central Committee for the Registration and Supervision of Organisations' 
         case 'MU-CR': return 'Companies and Businesses Registration Integrated System, Mauritius' 
         case 'MW-CNM': return 'The Council for Non Governmental Organisations in Malawi' 
         case 'MW-MRA': return 'Malawi Revenue Authority' 
         case 'MW-NBM': return 'NGO Board of Malawi' 
         case 'MW-RG': return 'Registrar General, Department of Justice' 
         case 'MX-CPA': return 'Budget Classification of Public Entities (Mexico)' 
         case 'MX-RFC': return 'Federal Taxpayers Registry' 
         case 'MY-SSM': return 'Companies Commission of Malaysia' 
         case 'MZ-CR': return 'Mozambique Commercial Registry' 
         case 'MZ-MOJ': return 'Mozambique Ministry of Justice' 
         case 'MZ-NUIT': return 'Taxpayer Single Identification Number (Mozambique)' 
         case 'NG-BPP': return 'Bureau of Public Procurement (BPP) Contractor Registration System (Nigeria)' 
         case 'NG-CAC': return 'Nigerian Corporate Affairs Commission' 
         case 'NL-KVK': return 'Chamber of Commerce (Netherlands)' 
         case 'NL-OWMS': return 'Overheid.nl Web Metadata Standard' 
         case 'NO-BRC': return 'Brønnøysundregistrene' 
         case 'NP-CRO': return 'Company Registrar Office' 
         case 'NP-SWC': return 'Social Welfare Council Nepal' 
         case 'PE-SUNARP': return 'Peruvian National Superintendency of Public Registries - Registered Legal Entities' 
         case 'PH-SEC': return 'Securities and Exchange Commission (Philippines)' 
         case 'PK-PCP': return 'Pakistan Centre for Philanthropy' 
         case 'PK-VSWA': return 'Pakistan Voluntary Social Welfare Agency' 
         case 'PL-KRS': return 'The National Court Register (Poland)' 
         case 'PL-NIP': return 'Tax Identification Number (Poland)' 
         case 'PL-REGON': return 'REGON - Statistical number of an economy entity' 
         case 'PS-MOI': return 'Ministry of Interior (Palestine)' 
         case 'PT-NIPPC': return 'Portal of Public Services' 
         case 'PY-PGN': return 'Classification of Entities in the National Budget for Paraguay' 
         case 'PY-RUC': return 'Unique Taxpayer Registry, Paraguay' 
         case 'RO-CUI': return 'National Trade Register (Romania)' 
         case 'RS-APR': return 'Serbian Business Registrations Agency' 
         case 'RS-PIB': return 'Tax identification number register' 
         case 'RU-INN': return 'Uniform State Register of Legal Entities of Russian Federation' 
         case 'RU-OGRN': return 'Unified State Register of Legal Entities (USRLE), Russian Federation' 
         case 'SE-BLV': return 'Bolagsverket' 
         case 'SE-KK': return 'Legal, Financial and Administrative Services Agency (Sweden)' 
         case 'SE-ON': return 'Business Registration Number (Organisationsnummer), Sweden' 
         case 'SG-ACRA': return 'Accounting and Corporate Regulatory Authority (ACRA)' 
         case 'SI-PRS': return 'Slovenian Business Register' 
         case 'SI-TIN': return 'Tax Identification Number (Slovenia)' 
         case 'SK-ORSR': return 'Ministry of Justice Business Register' 
         case 'SK-ZRSR': return 'Slovakia Ministry Of Interior Trade Register' 
         case 'SN-NINEA': return 'National Identification Number of Companies and Associations (NINEA), Senegal' 
         case 'SS-RRC': return 'South Sudan Relief and Rehabilitation Commission' 
         case 'TR-MERSIS': return 'MERSIS - Central Trade Registry System' 
         case 'TR-MOI': return 'Department of Associations (Ministry of Interior, Turkey)' 
         case 'TZ-BRLA': return 'Tanzania Business Registrations and Licensing Agency' 
         case 'TZ-TRA': return 'Tanzania Revenue Agency' 
         case 'UG-NGB': return 'NGO Board, Ministry of Internal Affairs' 
         case 'UG-RSB': return 'Registration Services Bureau' 
         case 'US-DOS': return 'Corporation registration is the responsibility of each state (see link)' 
         case 'US-EIN': return 'Employer Identification Number - Internal Revenue Service' 
         case 'US-USAGOV': return 'Index of U.S. Government Departments and Agencies' 
         case 'UZ-KTUT': return 'United State Register of Corporate Entites' 
         case 'XE-EXAMPLE': return 'Example Data Prefix' 
         case 'XI-EORI': return 'Economic Operators Identification and Registration system' 
         case 'XI-GRID': return 'Global Research Identifiers Database' 
         case 'XI-IATI': return 'International Aid Transparency Initiative Organisation Identifier' 
         case 'XI-PB': return 'Public Bodies - Open Knowledge Foundation' 
         case 'XI-PID': return 'PermID: Thompson Reuters Permanent Identifier' 
         case 'XI-WIKIDATA': return 'Wikidata' 
         case 'XM-DAC': return 'OECD Development Assistance Committee' 
         case 'XM-OCHA': return 'United Nations Office for the Coordination of Huma…n Affairs Financial Tracking Services Identifiers' 
         case 'XR-NUTS': return 'NUTS - European Union Nomenclature of Territorial Units for Statistics' 
         case 'ZA-CIP': return 'Companies and Intellectual Property Commission (CIPC)' 
         case 'ZA-NPO': return 'Nonprofit Organisation Directorate - South African Department of Social Development' 
         case 'ZA-PBO': return 'SA Revenue Service Tax Exemption Unit' 
         case 'ZM-PCR': return 'Patents and Companies Registration Agency' 
         case 'ZW-PVO': return 'Private Voluntary Organisations Council (Zimbabwe)' 
         case 'ZW-ROD': return 'Registrar of Deeds' 
         case 'ME-MNE': return 'Central Registry of Commercial Entities' 
        default: return ''
    }
}

function viewCurrentContractTime(data){
    if(data.hasOwnProperty('currentContractTime') === true){
        if(data.currentContractTime !== null){
            if(data.currentContractTime.startDate !== null && data.currentContractTime.endDate !== null){
                
                return (
                    <>
                        <TextFullHeader
                            title="Дата початку та закінчення договору оренди"
                            data=''
                        />
                        <TextHalf
                            title="Період з"
                            data={modifiDateString(data.currentContractTime.dateFrom, 3, false)}
                        />
                        <TextHalf
                            title="Період по"
                            data={modifiDateString(data.currentContractTime.dateTill, 3, false)}
                        />
                    </>
                )
            }
        }
    }
    return null
}

function viewCurrentTenantValue(data){
    if(data.hasOwnProperty('currentTenantValue') === true){
        if(data.currentTenantValue !== null){
            if(data.currentTenantValue.amount !== null){
                return (
                    <TextHalf
                        title="Орендна ставка (сума на рік)"
                        data={`${startCostAuction(data.currentTenantValue.amount)} грн.`}
                    />
                )
            }
        }
    }
    return null
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(LotCurrentTenantString)