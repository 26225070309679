import {
    ECP_API_PASS,
    TOGETHER_API_LIST,
    TOGETHER_API_LIST_CA,
} from "./types";

export function ecpApiPass(value) {
    return {
        type: ECP_API_PASS,
        payload: value
    }
}

export function ecpApiList(value) {
    return {
        type: TOGETHER_API_LIST,
        payload: value
    }
}

export function changeCA(value) {
    return {
        type: TOGETHER_API_LIST_CA,
        payload: value
    }
}
