import { checkProp } from "../../../../redux/check"

// HISTORY >
export function getAucHistory(props) {
    if (props.data.dateModified !== props.data.datePublished) {
        props.state.setHistory(!props.state.state)
        getAucDocumentHistory(props)
    }
}

async function getAucDocumentHistory(props) {
    let endPoint = process.env.REACT_APP_END_POINT_BD
    let url = `${endPoint}/api/v2/uk/public/history/auction/${props.scope.aucId}/documents`
    const response = await fetch(url, {
    method: "GET",
    headers: {
        'Content-Type': 'application/json'
    }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        alert('Нажаль виникла помилка і сервер не відповідає')
    }else{
        const json = await response.json()
        if(json !== null){
            let tmp = []
            tmp.push(json)
            await props.state.setArr(tmp)
        }
    }
}

//DOWNLOAD
//DOWNLOAD_BUTTON
export async function getViewAucDocuments(props){
    if(props.data.scope === 'private'){
        if(props.token !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.scope.aucId}/bid/${props.scope.bidId}/documents/${props.data.id}/download?returnLink=1`
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    ///'Content-Type': 'application/json'
                }
            })
            .catch(console.error)
            if(response === undefined || response === 'undefined'){
                return null
            }else{
                let url = await response.json()
                return url.link
            }
        }
    }
    return null
}

// KEP URL
export function createAucKEPUrl(props){
    let tmpUrlNew
    let tmpUrl = null
    let tmpUrlKep = null
    // let tmpRelatedDocument = null

   if (checkProp(props.docData, 'id')) {
            tmpUrl = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
            tmpUrlNew = props.scopeData.uploadNewDocURL
            if (checkProp(props.scopeData, 'docList') && props.scopeData.docList.length > 0) {
                for (let key in props.scopeData.docList) {
                    if (props.scopeData.docList[key].relatedDocument === props.docData.id) {
                        // tmpRelatedDocument = props.scopeData.docList[key].documentId
                        tmpUrlKep = `${props.scopeData.patchNewDocFilesURL}/${props.scopeData.docList[key].id}`
                    }
                }
            }
    } else {
        tmpUrlNew = props.scopeData.uploadNewDocURL
    }
    return {
        urlNew: tmpUrlNew,
        url: tmpUrl,
        urlKep: tmpUrlKep
    }
}
