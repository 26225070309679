import { isBSD, isBSE,
    isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isRLE, isRLD,
    isCLE, isCLD, isSPE, isSPD, isLPE } from "./procedure"

export async function kepMainEdit(props, KEPdata) {
    props.dataFilesAtribute.changeLoader(true)
    //let prozzoroJWTFile = await epUploadDataProzzoro(props)
    //let prozzoroStringId = await epCreateDocProzzoro(props, prozzoroJWTFile)
    ////let prozzoroStringId = await epAddDocToAucProzzoro(props, propzzoroDocId)
    //if(prozzoroStringId !== null || prozzoroStringId !== undefined || prozzoroStringId !== 'undefined'){
        let data = await epSessionStart()               //Создаем сессию
        await epSessionTest(data)                       //Проверка создания сессии
        // сюда засунуть скачку файла
        await epSessionDataLoad(props.dataFilesAtribute.fileFile, data, KEPdata)                   //Загрузка документа
        await epMetadata(props, data, KEPdata.file)                   //Загрузка метадаты
        let param = {
            caId: KEPdata.type,     //!!!! Тут это надо будет переподключить на передаваемый параметр
            cadesType: "CAdESXLong",
            cleanUpForm: false,
            dataToSignQualifier: "notSignedBefore",
            dataTsVerifyOption: "ignore",
            duplicateSign: false,
            embedCertificateType: "signerAndCaCert",
            embedDataTs: "true",
            embedDataTsChecked: true,
            embedSignatureTs: "true",
            embedSignatureTsChecked: true,
            expandCreateDSCadesType: false,
            expandCreateDSTSVerify: false,
            expandCreateDSType: true,
            generateQR: false,
            showUpgradeCard: false,
            signatureTsVerifyOption: "ignore",
            signatureType: "attached",
            tsAdditionalVerifying: false,
        }
        await epSessionSetParam2(data, param)           //Настройка параметров сессии
        await epSessionMainContainer(data, KEPdata.file)              //Загрузка ключа
        await epCreateEP(data, KEPdata)                   //Подгрузка пароля ключа
        let data1
    data1 = await epGetDataEP(data)             //Получить данные
        await epGetCreatorDataEP(data)                  //Получить данные
        await epSessionDelete(data)                     //Закрытие сессии*/
    let prozzoroJWTFile = await epUploadDataProzzoro(props)
    let prozzoroStringId = await epEditDocProzzoro(props, prozzoroJWTFile)
    if(
        //prozzoroStringId !== null &&
        //prozzoroStringId !== undefined &&
        //prozzoroStringId !== 'undefined' &&
        !!data1 &&
        data1 !== 'undefined'
    ){
        if(props.dataFilesAtribute.uploadUrlKep !== null){
            await epUploadDataEdit(props, data1, prozzoroStringId)
        }else{
            await epUploadData(props, data1, prozzoroStringId)
        }
    }
    await props.dataFilesAtribute.changeLoader(false)
    if(props.dataFilesAtribute.setViewBtns){
        await props.dataFilesAtribute.setViewBtns(false)
        await props.dataFilesAtribute.actionNo()
    }else{
        await props.dataFilesAtribute.actionNo()
    }
}

export async function kepMain(props, KEPdata) {
    props.dataFilesAtribute.changeLoader(true)
    //let prozzoroJWTFile = await epUploadDataProzzoro(props)
    //let prozzoroStringId = await epCreateDocProzzoro(props, prozzoroJWTFile)
    ////let prozzoroStringId = await epAddDocToAucProzzoro(props, propzzoroDocId)
    //if(prozzoroStringId !== null || prozzoroStringId !== undefined || prozzoroStringId !== 'undefined'){
        let data = await epSessionStart()               //Создаем сессию
        await epSessionTest(data)                       //Проверка создания сессии
        await epSessionDataLoad(props.dataFilesAtribute.fileFile, data, KEPdata)                   //Загрузка документа
        await epMetadata(props, data, KEPdata.file)                   //Загрузка метадаты
        let param = {
            caId: KEPdata.type,     //!!!! Тут это надо будет переподключить на передаваемый параметр
            cadesType: "CAdESXLong",
            cleanUpForm: false,
            dataToSignQualifier: "notSignedBefore",
            dataTsVerifyOption: "ignore",
            duplicateSign: false,
            embedCertificateType: "signerAndCaCert",
            embedDataTs: "true",
            embedDataTsChecked: true,
            embedSignatureTs: "true",
            embedSignatureTsChecked: true,
            expandCreateDSCadesType: false,
            expandCreateDSTSVerify: false,
            expandCreateDSType: true,
            generateQR: false,
            showUpgradeCard: false,
            signatureTsVerifyOption: "ignore",
            signatureType: "attached",
            tsAdditionalVerifying: false,
        }
        await epSessionSetParam2(data, param)           //Настройка параметров сессии
        await epSessionMainContainer(data, KEPdata.file)              //Загрузка ключа
        await epCreateEP(data, KEPdata)                   //Подгрузка пароля ключа
        let data1
    data1 = await epGetDataEP(data)             //Получить данные
        await epGetCreatorDataEP(data)                  //Получить данные
        await epSessionDelete(data)                     //Закрытие сессии*/
    
    if(data1 !== null){
        let prozzoroJWTFile = await epUploadDataProzzoro(props)
        let prozzoroStringId = await epCreateDocProzzoro(props, prozzoroJWTFile)
        if(
            !!prozzoroStringId &&
            prozzoroStringId !== 'undefined' &&
            !!data1 &&
            data1 !== 'undefined'
            // data1 !== null &&
            // data1 !== undefined &&
            // data1 !== 'undefined'
        ){
            await epUploadData(props, data1, prozzoroStringId)
        }
    }
    await props.dataFilesAtribute.changeLoader(false)
    if(props.dataFilesAtribute.setViewBtns){
        await props.dataFilesAtribute.setViewBtns(false)
        await props.dataFilesAtribute.actionNo()
    }else{
        await props.dataFilesAtribute.actionNo()
    }
}

async function epUploadDataProzzoro(props){
    //props.changeLoader(true)
    document.getElementById('input__file');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.dataFilesAtribute.fileType)
    formData.append('file', props.dataFilesAtribute.fileFile)
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epCreateDocProzzoro(props, data){
    const json = await data
    const endPoint = props.dataFilesAtribute.uploadUrlNew;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.dataFilesAtribute.fileName,
            description: props.dataFilesAtribute.fileDiscription,
            documentType: props.dataFilesAtribute.fileType,
            language: 'uk',              //не меняем
            documentOf: props.dataFilesAtribute.documentOf,
            //hash: "string",
            token: json.jwt                 //не меняем
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmp = await response.json()
        if(props.dataFilesAtribute.documentOf === 'contract' || props.dataFilesAtribute.documentOf === 'award'){
            return await tmp.id    
        }
        return await tmp.documentId // почему documentId?
    }
    //return response
    return null
}

async function epEditDocProzzoro(props, data){
    const getMethod =( data )=> {
        if(
            isBSE(data.aucType) || isBSD(data.aucType) ||
            isALE(data.aucType) ||
            isCSE(data.aucType) || isCSD(data.aucType) ||
            isBRE(data.aucType) || isBRD(data.aucType) || isBRW(data.aucType) ||
            isRLE(data.aucType) || isRLD(data.aucType) ||
            isCLE(data.aucType) || isCLD(data.aucType)
        ){
            if(data.aucStatus === null) return 'PUT'
        }else if(isSPE(data.aucType) || isSPD(data.aucType) || isLPE(data.aucType)){
            return 'POST'
        }
        return 'PATCH'
    }

    const json = await data
    let endPoint
    if(props.dataFilesAtribute.uploadUrl !== null){
        endPoint = props.dataFilesAtribute.uploadUrl;
    }else{
        endPoint = props.dataFilesAtribute.uploadUrlNew;
    }
    let body = {}
    //if(props.dataFilesAtribute.id !== null) body.id = props.dataFilesAtribute.id
    body.title = props.dataFilesAtribute.fileName
    body.description = props.dataFilesAtribute.fileDiscription
    body.documentType = props.dataFilesAtribute.fileType
    body.language = 'uk'              //не меняем
    body.documentOf = props.dataFilesAtribute.documentOf
    body.token = json.jwt                 //не меняем

    const response = await fetch(endPoint, {
        method: getMethod(props.dataFilesAtribute),
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmp = await response.json()
        //if(props.dataFilesAtribute.documentOf === 'contract'){
        //    return await tmp.id    
        //}
        return await tmp.documentId
    }
    //return response
    await props.dataFilesAtribute.reload()
    return null
}


/**
 * Создание сессии
 */
 async function epSessionStart(){
    let url = 'https://caas-api.dzo.com.ua/ccs/api/v1/ticket';
    const response = await fetch(url, {
            method: "POST",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Проверка наличия сессии
 */
 async function epSessionTest(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 * Заменить
 */ 
 async function epSessionDataLoad(file, data){
    //const addFileInp = document.getElementById('input__file');
    const url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/data`;
    const formData = new FormData();
    formData.append('uploadTicketData', file) // props.dataFilesAtribute.fileFile было до того тут
    const response = await fetch(url, {
        method: "POST",
        body: formData
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Передача мета данных
 */ 
 async function epMetadata(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/metadata`;

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            metaData: props.dataFilesAtribute.fileFile.name
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 */
 async function epSessionSetParam2(data, param){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/option`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить ключевой контейнер сессии
 */
 async function epSessionMainContainer(data, KEPdata){
    //const addFileInpEP = document.getElementById('input__fileECP');
    const file = await toBase64(KEPdata)

    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/keyStore`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json' //Base64
        },
        body: JSON.stringify({
            "base64Data": file.substr(37)
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

/**
 * Создать ЭП
 */
async function epCreateEP(data, KEPdata){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            //keyStorePassword: "tect3"
            keyStorePassword: KEPdata.pass
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
async function epGetCreatorDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Удаление сессии
 * @param {*} data 
 */
async function epSessionDelete(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
    const response = await fetch(url, {
        method: "DELETE",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
 async function epGetDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/base64Data`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
           return response.json()
        }
    )
    .catch(console.error)
    return response
}

async function epUploadData(props, data, relatedDocument){
    const base64 = `data:application/pkcs7-signature;base64,${data.base64Data}`  //Файл в бейс 64
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
        upload(props, blob, relatedDocument)
        //kepCreateDoc(props, tmpJWT, relatedDocument)
    })
}

async function upload(props, blob, relatedDocument){
    //props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', 'digitalSignature')
    let fileName = props.dataFilesAtribute.fileFile.name + '.p7s'
    formData.append('name', fileName)
    formData.append("file", blob);
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            //return response.json()
            kepCreateDoc(props, response.json(), relatedDocument)
        }
    )
    .catch(console.error)
    return response
}

async function kepCreateDoc(props, data, relatedDocument){
    const json = await data
    const endPoint = props.dataFilesAtribute.uploadUrlNew;
    let body = {}
    body.title = props.dataFilesAtribute.fileName
    body.description = props.dataFilesAtribute.fileDiscription
    body.documentType = 'digitalSignature'
    body.language = 'uk'
    body.documentOf = props.dataFilesAtribute.documentOf
    body.token = json.jwt
    //body.relatedDocument: relatedDocument,
    if(props.dataFilesAtribute.documentOf === 'contract' || props.dataFilesAtribute.documentOf === 'award' || props.dataFilesAtribute.documentOf === 'bid'){
        if(props.dataFilesAtribute.id !== null){
            body.relatedDocument = props.dataFilesAtribute.id
        }else{
            body.relatedDocument = relatedDocument
        }
    }else{
        if(relatedDocument !== null){
            body.relatedDocument = relatedDocument
        }else{
            body.relatedDocument = props.dataFilesAtribute.relatedDocument
        }
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    } else {
        await props.dataFilesAtribute.reload()


        
        // await props.dataFilesAtribute.auctionsGetAwards(dataAward, headers1, props.dataFilesAtribute.aucId)
        // await props.dataFilesAtribute.contractsAuctionIdGet(dataAward, headers1, props.dataFilesAtribute.aucId)
        // let dataBid = {
        //     domen: process.env.REACT_APP_END_POINT_BD,
        //     version: 'v1',
        //     local: 'uk',
        //     params: '?limit=20'
        // }
        // await props.dataFilesAtribute.auctionsBidsGetId(dataBid, props.token.access_token, props.dataFilesAtribute.aucId)
    }
    //return response
    return null
}

async function epUploadDataEdit(props, data, relatedDocument){
    const base64 = `data:application/pkcs7-signature;base64,${data.base64Data}`  //Файл в бейс 64
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
        uploadEdit(props, blob)
        //kepCreateDoc(props, tmpJWT, relatedDocument)
    })
}

async function uploadEdit(props, blob){
    //props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', 'digitalSignature')
    let fileName = props.dataFilesAtribute.fileFile.name + '.p7s'
    formData.append('name', fileName)
    formData.append("file", blob);
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            //return response.json()
            kepEditDoc(props, response.json())
        }
    )
    .catch(console.error)
    return response
}

async function kepEditDoc(props, data){
    const json = await data
    const endPoint = props.dataFilesAtribute.uploadUrlKep;
    let body = {}
    body.title = props.dataFilesAtribute.fileName
    body.description = props.dataFilesAtribute.fileDiscription
    body.documentType = 'digitalSignature'
    body.language = 'uk'
    body.documentOf = props.dataFilesAtribute.documentOf
    body.token = json.jwt
    if(props.dataFilesAtribute.documentOf === 'contract' || props.dataFilesAtribute.documentOf === 'award' || props.dataFilesAtribute.documentOf === 'bid'){
        body.relatedDocument = props.dataFilesAtribute.id
    }else{
        body.relatedDocument = props.dataFilesAtribute.relatedDocument
    }

    const response = await fetch(endPoint, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    } else {
        await props.dataFilesAtribute.reload()
        // let dataAward = {
        //     domen: process.env.REACT_APP_END_POINT_BD,
        //     version: 'v1',
        //     local: 'uk'
        // }
        // let headers1 = {
        //     'Authorization': props.token.access_token,
        //     'Content-Type': 'application/json'
        // }
        // await props.dataFilesAtribute.auctionsGetAwards(dataAward, headers1, props.dataFilesAtribute.aucId)
        // await props.dataFilesAtribute.contractsAuctionIdGet(dataAward, headers1, props.dataFilesAtribute.aucId)
        // let dataBid = {
        //     domen: process.env.REACT_APP_END_POINT_BD,
        //     version: 'v1',
        //     local: 'uk',
        //     params: '?limit=20'
        // }
        // await props.dataFilesAtribute.auctionsBidsGetId(dataBid, props.token.access_token, props.dataFilesAtribute.aucId)
    }
    //return response
    return null
}