import React from 'react';
import styles from './filterBlock.module.css';

import CategoryMenu from '../categoryMenu/categoryMenu.js';
import MainBlock from "../mainFind/mainBlock";

export default function Find (){
    return (
        <div className={styles.auction_main}>
            <div className={styles.auction_main_wrap}>
                <MainBlock />
                <CategoryMenu />
            </div>
        </div>
    )
}