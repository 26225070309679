import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';

import {disabledElement} from '../disabled'

const WindowAuctionTenderAttempt = ( props ) => {
    let tmp = null;
    let tmpCurrent = null
    let tmpAmount = null
    let tmpCurrentExpertMonetaryValuation = null
    let tmpAmountExpertMonetaryValuation = null
    let tmpCurentBudgetSpent = null
    let tmpBudgetSpent = null
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('normativeMonetaryValuation') === true){
            if(props.auctionsMy.normativeMonetaryValuation !== null){
                tmpCurrent = props.auctionsMy.normativeMonetaryValuation.currency
                tmpAmount = props.auctionsMy.normativeMonetaryValuation.amount
            }
        }
        if(props.auctionsMy.hasOwnProperty('expertMonetaryValuation') === true){
            if(props.auctionsMy.expertMonetaryValuation !== null){
                tmpCurrentExpertMonetaryValuation = props.auctionsMy.expertMonetaryValuation.currency
                tmpAmountExpertMonetaryValuation = props.auctionsMy.expertMonetaryValuation.amount
            }
        }
        if(props.auctionsMy.hasOwnProperty('budgetSpent') === true){
            if(props.auctionsMy.budgetSpent !== null){
                tmpCurentBudgetSpent = props.auctionsMy.budgetSpent.amount
                tmpBudgetSpent = props.auctionsMy.budgetSpent.currency
            }
        }
        if(
            props.auctionsMy.sellingMethod === 'landRental-english' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-english' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            tmp = <div className={styles.group}>
                <div className={styles.titleValue}>Нормативна грошова оцінка</div>
                <div className={styles.groupElem}>
                    <div className={styles.valuta}>
                        <div className={styles.titleValue}>Тип валюти</div>
                        <TextField
                            className={styles.valutaInput}
                            disabled
                            id="outlined-select-currency-native"
                            select
                            value={tmpCurrent}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Тип валюти"
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <div className={styles.titleValue}>Сума</div>
                        <TextField
                            //error = {redBlock(props, tmpAmount)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            value={tmpAmount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.setDataAddress(convertStrToNumber(event.target.value), 'SET_AUCTION_NORMATIVE_MONETARY_VALUATION')
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                </div>
                <div className={styles.titleValue}>Експертна грошова оцінка</div>
                <div className={styles.groupElem}>
                    <div className={styles.valuta}>
                        <div className={styles.titleValue}>Тип валюти</div>
                        <TextField
                            className={styles.valutaInput}
                            disabled
                            id="outlined-select-currency-native"
                            select
                            value={tmpCurrentExpertMonetaryValuation}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Тип валюти"
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <div className={styles.titleValue}>Сума</div>
                        <TextField
                            //error = {redBlock(props, tmpAmountExpertMonetaryValuation)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            value={tmpAmountExpertMonetaryValuation}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.setDataAddress(convertStrToNumber(event.target.value), 'SET_AUCTION_EXPERT_MONETARY_VALUATION')
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                </div>
                <div className={styles.titleValue}>Сума витрат (видатків), здійснених на підготовку лота</div>
                <div className={styles.groupElem}>
                    <div className={styles.valuta}>
                        <div className={styles.titleValue}>Тип валюти</div>
                        <TextField
                            className={styles.valutaInput}
                            disabled
                            id="outlined-select-currency-native"
                            select
                            value={tmpBudgetSpent}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Тип валюти"
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <div className={styles.titleValue}>Сума</div>
                        <TextField
                            //error = {redBlock(props, tmpCurentBudgetSpent)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            value={tmpCurentBudgetSpent}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.setDataAddress(convertStrToNumber(event.target.value), 'SET_AUCTION_BUDGET_SPENT')
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="filled-required"
                            className={styles.inputText}
                            label='Містобудівні умови земельної ділянки'
                            defaultValue={props.auctionsMy.conditions}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_CONDITIONS')
                                }
                            }
                            helperText=""
                            //required
                        />
                    </div>
                </div>

                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="filled-required"
                            className={styles.inputText}
                            label='Обтяження прав на земельну ділянку, обмеження у використанні земель'
                            defaultValue={props.auctionsMy.restrictions}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_RESTRICTIONS')
                                }
                            }
                            helperText=""
                            //required
                        />
                    </div>
                </div>
                {leaseOrSaleType(props)}
            </div>
            return tmp
        }
    }
    return null
}

function leaseOrSaleType(props){
    if(
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod'
    ){
        return (
            <div className={styles.inputMax}>
                <TextField
                    error = {redBlock(props, props.auctionsMy.leaseType)}
                    className={styles.inputMax}
                    disabled='disabled'
                    id="outlined-select-currency-native"
                    select
                    label='Тип оренди'
                    value={props.auctionsMy.leaseType}
                    onChange={(event)=>{
                        props.setDataAddress(event.target.value, 'AUCTION_lEASE_TYPE')
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    helperText=""
                    variant="outlined"
                >
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option key='1' value='landSale'>Продаж земельних ділянок</option>
                    <option key='2' value='landRental'>Оренда земельних ділянок</option>
                    <option key='3' value='superficiesSale'>Продаж прав суперфіцію</option>
                    <option key='4' value='emphyteusisSale'>Продаж прав емфітевзису</option>
                </TextField>
            </div>
        )
    }else if(
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        return (
            <div className={styles.inputMax}>
                <TextField
                    error = {redBlock(props, props.auctionsMy.saleType)}
                    className={styles.inputMax}
                    disabled={disabledElement(props.auctionsMy, '11')}
                    id="outlined-select-currency-native"
                    select
                    label='Тип продажу'
                    value={props.auctionsMy.saleType}
                    onChange={(event)=>{
                        props.setDataAddress(event.target.value, 'AUCTION_SALE_TYPE')
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    helperText=""
                    variant="outlined"
                >
                    <option key='null' selected disabled value="null">Обрати</option>
                    <option key='1' value='landSale'>Продаж земельних ділянок</option>
                    <option key='3' value='superficiesSale'>Продаж прав суперфіцію</option>
                    <option key='4' value='emphyteusisSale'>Продаж прав емфітевзису</option>
                </TextField>
            </div>
        )
    }
    return null
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            if(position === 1){
                tmp = <>
                    <option key={4} value="eurocent">євроцент</option>
                </>
            }else if(position === 2){
                tmp = <>
                    <option key={3} value="EUR">EUR</option>
                </>
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
    }
    return tmp
}

function convertStrToNumber(data){
    let str
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    return str
}

function redBlock(props, amount){
    if(amount === null || amount === '' || amount === 'null'){
        return 'error'
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionTenderAttempt)
