import React from 'react';
import styles from './buttonAnswer.module.css';

import {connect} from 'react-redux';

import {answerTitle, answerDiscription} from '../../../../redux/actions/questions/questions.js';
import {setPopUpAnswerAuction} from '../../../../redux/actions/auctions/auctions.js';

const ButtonAnswer = (props) => {
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('auctionPeriods')  === true){
            if(props.auctionsMy.auctionPeriods  !== null){
                if(props.auctionsMy.auctionPeriods.length > 0){
                    for(let i = 0; i < props.auctionsMy.auctionPeriods.length; i++){
                        if(props.auctionsMy.auctionPeriods[i].status === 'enquiryPeriod'){
                            let tmpDate = new Date()
                            let tmpDateStart = new Date(props.auctionsMy.auctionPeriods[i].startDate)
                            let tmpDateEnd = new Date(props.auctionsMy.auctionPeriods[i].endDate)
                            if(tmpDateStart.getTime() >= tmpDate.getTime() && tmpDate.getTime() <= tmpDateEnd.getTime()){
                                return (
                                    <div
                                        className={styles.answerButton}
                                        onClick={
                                            () =>{
                                                props.setPopUpAnswerAuction(props.itemAnswer)
                                            }
                                        }
                                    >
                                        Відповідь
                                    </div>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    /*return (
        <div
            className={styles.answerButton}
            onClick={
                () =>{
                    props.setPopUpAnswerAuction(props.itemAnswer)
                }
            }
        >
            Відповідь
        </div>
    )*/
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        questionCreate: state.start.questionCreate,
    }
}

const mapDispatchToProps = {
    answerTitle,
    answerDiscription,
    setPopUpAnswerAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonAnswer)
