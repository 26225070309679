//Elem 0 = ua, 1 = en
export const CURRENT_AUCTIONS = ['АКТУАЛЬНІ АУКЦІОНИ', 'CURRENT AUCTIONS'];

export const ALL_AUCTIONS = ['ВСІ АУКЦІОНИ', 'ALL AUCTIONS'];

export const REGISTRY_OF_OBJECTS_OF_PRIVATIZATION = ['РЕЄСТР ОБ\'ЄКТІВ ПРИВАТИЗАЦІЇ', 'REGISTRY OF OBJECTS OF PRIVATIZATION'];

export const REGISTRY_OF_LOTS_OF_PRIVATIZATION = ['РЕЄСТР ЛОТІВ ПРИВАТИЗАЦІЇ', 'REGISTRY OF LOTS OF PRIVATIZATION'];

export const COMPLETION_OF_PRIVATIZATION = ['ЗАВЕРШЕННЯ ПРИВАТИЗАЦІЇ', 'COMPLETION OF PRIVATIZATION'];

export const MAP_OF_OBJECTS_OF_PRIVATIZATION = ['МАПА ОБ\'ЄКТІВ ПРИВАТИЗАЦІЇ', 'MAP OF OBJECTS OF PRIVATIZATION'];
