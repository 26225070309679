import React from 'react';
import styles from './paging.module.css';

import {connect} from 'react-redux';

const pagingButtonNext = ( ) => {
    return (
            <svg width="7" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12">
             <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path className={styles.svgIcon} d="M4.59,6,.29,1.71A1,1,0,0,1,1.71.29l5,5a1,1,0,0,1,0,1.42l-5,5A1,1,0,0,1,.29,10.29Z" fill="#92929D"/></g></g>
            </svg>
    )
}

export default connect(null, null)(pagingButtonNext)
