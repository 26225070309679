import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {modifiDateString} from '../../redux/modifiDate'

import { getAuctionsStatus } from "../../api/dictonaries/statusAuctions"

const ProcedureStatus = (props) => {

    return (
        <div className={styles.aucStatus}>
            <p>Статус</p>
            <p className={styles.lotStatus}>{getAuctionsStatus(props.auctionId.sellingMethod, props.auctionId.status)}</p>
            {dataAccessEditAuction(props.auctionId)}
        </div>
    )
}

function dataAccessEditAuction(data){
    if(data !== null){
        if(data.status === 'active_rectification'){
            return (
                <p className={styles.lotStatus}>По {viewDate(data, 'rectificationPeriod', 'end', 3)}</p>
            )
        }
    }
    return null
}

function viewDate(data, typeDate, startEnd, flag){
    if(data !== null){
        if(data.rectificationPeriod !== null){
            if(startEnd === 'start'){
                return modifiDateString(data.rectificationPeriod.startDate, flag, false)
            }
            if(startEnd === 'end'){
                return modifiDateString(data.rectificationPeriod.endDate, flag, false)
            }    
        }
        
    }
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedureStatus)