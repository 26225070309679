import {addProperty, addPropertyMonga, addPropertyValue, addBankProperty, addArrest
} from './utilsCreateBody'

export function createBodyLAW(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        //body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'lotId')
        body = addProperty(data, body, 'sellingMethod')
        //body = addPropertyMonga(data, body, 'x_documentRequirements')
        body = addPropertyMonga(data, body, 'x_additionalInformation')
        //body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addProperty(data, body, 'saleType')
        //body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        //body = addProperty(data, body, 'valueAddedTaxCharged')
        body = addPropertyValue(data, body, 'value')
        // body = addPropertyValue(data, body, 'minimalStep')
        // body = addPropertyValue(data, body, 'guarantee')
        // body = addPropertyValue(data, body, 'registrationFee')
        // body = addPropertyValue(data, body, 'expertMonetaryValuation')
        // body = addPropertyValue(data, body, 'normativeMonetaryValuation')
        // body = addPropertyValue(data, body, 'budgetSpent')
        body = addProperty(data, body, 'previousAuctionId')
        // body = addPropertyDiscount(data, body, 'discount')
        body = addBankProperty(data, body, 'banks')
        // body = addPropertyMonga(data, body, 'restrictions')
        // body = addPropertyMonga(data, body, 'conditions')
        body = addArrest(data, body)
        return body
    }
    return null
}