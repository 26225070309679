import React from 'react';
import styles from './togetherAPI.module.css';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {connect} from 'react-redux';

import {
    auctionsSearchTogetherAPI,
    auctionsSearchCount,

    setTimberSortment,
    setTimberSpecies,
    setTimberClass,
    setTimberDiameter,
    setTimberStorage,
    setTimberProductionYear,
    setTimberProductionQuarter,
    setTimberLength1,
    setTimberLength2,
} from '../../../../redux/actions/auctions/auctions.js';
import {changeLoader, setUniversalError} from '../../../../redux/actions.js';

import {
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
} from '../../../../redux/togetherApi.js';


const TogetherAPIFilter = (props) => {
    return (
        <>
            <div className={styles.filterBlock}>
                <div className={styles.filterImputBlock}>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Асортимент"
                        value={props.togetherAPIelem.timberSortment}
                        onChange={(event)=>{
                            props.setTimberSortment(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {viewSortment(props)}
                    </TextField>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Порода"
                        value={props.togetherAPIelem.timberSpecies}
                        onChange={(event)=>{
                            props.setTimberSpecies(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {viewSpecies(props)}
                    </TextField>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Клас якості"
                        value={props.togetherAPIelem.timberClass}
                        onChange={(event)=>{
                            props.setTimberClass(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {viewClass(props)}
                    </TextField>
                </div>
                <div className={styles.filterImputBlock}>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Група діаметрів"
                        value={props.togetherAPIelem.timberDiameter}
                        onChange={(event)=>{
                            props.setTimberDiameter(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {viewDiameter(props)}
                    </TextField>
                    <TextField
                        className={styles.filterImputBlock_input}
                        label="Довжина від. Приклад (1,2)"
                        value={props.togetherAPIelem.timberLength1}
                        onChange={(event) => {
                            props.setTimberLength1(event.target.value)
                        }}
                        variant="outlined"
                    />
                    <TextField
                        className={styles.filterImputBlock_input}
                        label="Довжина до. Приклад (2,3)"
                        value={props.togetherAPIelem.timberLength2}
                        onChange={(event) => {
                            props.setTimberLength2(event.target.value)
                        }}
                        variant="outlined"
                    />
                </div>
                <div className={styles.filterImputBlock}>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Склад"
                        value={props.togetherAPIelem.timberStorage}
                        onChange={(event)=>{
                            props.setTimberStorage(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {viewStorage(props)}
                    </TextField>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Рік заготівлі"
                        value={props.togetherAPIelem.timberProductionYear}
                        onChange={(event)=>{
                            props.setTimberProductionYear(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value={null}>Обрати</option>
                        {viewProductionYear(props)}
                    </TextField>
                    <TextField
                        className={styles.filterImputBlock_input}
                        id="outlined-select-currency-native"
                        select
                        label="Квартал заготівлі"
                        value={props.togetherAPIelem.timberProductionQuarter}
                        onChange={(event)=>{
                            props.setTimberProductionQuarter(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key='null' selected disabled value={null}>Обрати</option>
                        {viewQuarted(props)}
                    </TextField>
                </div>
            </div>
            <div className={styles.filterBlock}>
                <Button
                    className={styles.filterImputBlock_button}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            getSearchAuctions(props)
                        }
                    }
                >
                    Пошук
                </Button>
            </div>
        </>
    )
}

async function getSearchAuctions(props){
    await props.changeLoader(true)
    let tmpArr = [];
    if(props.togetherAPIelem.classificationsByTypeMain !== null){tmpArr.push(props.togetherAPIelem.classificationsByTypeMain)}
    if(props.togetherAPIelem.timberSortment !== null){tmpArr.push(props.togetherAPIelem.timberSortment)}
    if(props.togetherAPIelem.timberSpecies !== null){tmpArr.push(props.togetherAPIelem.timberSpecies)}
    if(props.togetherAPIelem.timberClass !== null){tmpArr.push(props.togetherAPIelem.timberClass)}
    if(props.togetherAPIelem.timberDiameter !== null){tmpArr.push(props.togetherAPIelem.timberDiameter)}

    let timberLength1 = null
    let timberLength2 = null
    if(props.togetherAPIelem.timberLength1 !== null && props.togetherAPIelem.timberLength2 !== null){
        timberLength1 = props.togetherAPIelem.timberLength1.replace('.', ',')
        timberLength2 = props.togetherAPIelem.timberLength2.replace('.', ',')
        if(timberLength1.indexOf(',') === -1 ){
            timberLength1 = timberLength1 + ',0'
        }
        if(timberLength2.indexOf(',') === -1 ){
            timberLength2 = timberLength2 + ',0'
        }
        timberLength1 = timberLength1.replace(',,', ',')
        timberLength2 = timberLength2.replace(',,', ',')
        timberLength1 = timberLength1.replace(',', '.')
        timberLength2 = timberLength2.replace(',', '.')
        /*tmpArr.push(
            timberLength1.trim() + ' - ' + timberLength2.trim()
        )*/
    }
    if(props.togetherAPIelem.timberStorage !== null){tmpArr.push(props.togetherAPIelem.timberStorage)}
    if(props.togetherAPIelem.timberProductionYear !== null){tmpArr.push(props.togetherAPIelem.timberProductionYear)}
    if(props.togetherAPIelem.timberProductionQuarter !== null){tmpArr.push(props.togetherAPIelem.timberProductionQuarter)}
    let dataSearch = {
        category: props.search.category,
        lengthFrom: parseFloat(timberLength1),
        lengthTo: parseFloat(timberLength2),
        additionalClassifications: tmpArr,
    }
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        await props.auctionsSearchTogetherAPI(data, header, dataSearch);
        await props.togetherApiPageCurrentAuction(0)
        if(props.auctions.length === 10) {
	        await props.togetherApiPageNextAuction(1)
        }else{
	        await props.togetherApiPageNextAuction(null)
        }
        /*let data8 = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            //params: '?limit=200&offset=0'
        }
        let elemArr = ''
        for(let it = 0; it < props.auctions.length; it++){
            if(it === 0){
                elemArr = `ids[]=${props.auctions[it].id}`
            }else{
                elemArr = elemArr + `&ids[]=${props.auctions[it].id}`
            }
        }
        await props.auctionsSearchTestElemArr(data8, header, elemArr)*/
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        await props.auctionsSearchTogetherAPI(data, header, dataSearch);
        await props.togetherApiPageCurrentAuction(0)
        if(props.auctions.length === 10) {
	        await props.togetherApiPageNextAuction(1)
        }else{
	        await props.togetherApiPageNextAuction(0)
        }
    }
    await props.changeLoader(false)
}

function viewSortment(props){
    let tmp = null;
    if(props.classificationsTimber.timberSortment !== null){
        if(props.classificationsTimber.timberSortment.length > 0){
            tmp = props.classificationsTimber.timberSortment.map(
                (i) => {
                    let text = i.name
                    if(props.togetherApiPage.proxy.timberSortment !== null){
                        for (var key in props.togetherApiPage.proxy.timberSortment) {
                            if(key === i.name){
                                text = props.togetherApiPage.proxy.timberSortment[key].uk_UA
                            }
                        }
                    }
                    return <option key={i.name} value={i.name}>{text}</option>
                }
            )
        }
    }
    return tmp
}

function viewSpecies(props){
    let tmp = null;
    if(props.classificationsTimber.timberSpecies !== null){
        if(props.classificationsTimber.timberSpecies.length > 0){
            tmp = props.classificationsTimber.timberSpecies.map(
                (i) => {
                    //let text = i.classificationId
                    let text = i.name
                    if(props.togetherApiPage.proxy.timberSpecies !== null){
                        for (var key in props.togetherApiPage.proxy.timberSpecies) {
                            if(key === i.name){
                                text = props.togetherApiPage.proxy.timberSpecies[key].uk_UA
                            }
                        }
                    }
                    return <option key={i.name} value={i.name}>{text}</option>
                }
            )
        }
    }
    return tmp
}

function viewClass(props){
    let tmp = null;
    if(props.classificationsTimber.timberClass !== null){
        if(props.classificationsTimber.timberClass.length > 0){
            tmp = props.classificationsTimber.timberClass.map(
                (i) => {
                    let text = i.name
                    if(props.togetherApiPage.proxy.timberClass !== null){
                        for (var key in props.togetherApiPage.proxy.timberClass) {
                            if(key === i.name){
                                text = props.togetherApiPage.proxy.timberClass[key].uk_UA
                            }
                        }
                    }
                    return <option key={i.name} value={i.name}>{text}</option>
                }
            )
        }
    }
    return tmp
}

function viewDiameter(props){
    let tmp = null;
    if(props.classificationsTimber.timberDiameter !== null){
        if(props.classificationsTimber.timberDiameter.length > 0){
            tmp = props.classificationsTimber.timberDiameter.map(
                (i) => {
                    let text = i.name
                    if(props.togetherApiPage.proxy.timberDiameter !== null){
                        for (var key in props.togetherApiPage.proxy.timberDiameter) {
                            if(key === i.name){
                                text = props.togetherApiPage.proxy.timberDiameter[key].uk_UA
                            }
                        }
                    }
                    return <option key={i.name} value={i.name}>{text}</option>
                }
            )
        }
    }
    return tmp
}

function viewStorage(props){
    let tmp = null;
    if(props.classificationsTimber.timberStorage !== null){
        if(props.classificationsTimber.timberStorage.length > 0){
            tmp = props.classificationsTimber.timberStorage.map(
                (i) => {
                    let text = i.name
                    if(props.togetherApiPage.proxy.timberStorage !== null){
                        for (var key in props.togetherApiPage.proxy.timberStorage) {
                            if(key === i.name){
                                text = props.togetherApiPage.proxy.timberStorage[key].uk_UA
                            }
                        }
                    }
                    return <option key={i.name} value={i.name}>{text}</option>
                }
            )
        }
    }
    return tmp
}

function viewProductionYear(props){
    let tmp = null;
    if(props.classificationsTimber.timberProductionYear !== null){
        if(props.classificationsTimber.timberProductionYear.length > 0){
            tmp = props.classificationsTimber.timberProductionYear.map(
                (i) => {
                    return <option key={i.classificationId} value={i.classificationId}>{i.classificationId}</option>
                }
            )
        }
    }
    return tmp
}

function viewQuarted(props){
    let tmp = null;
    if(props.classificationsTimber.timberProductionQuarter !== null){
        if(props.classificationsTimber.timberProductionQuarter.length > 0){
            tmp = props.classificationsTimber.timberProductionQuarter.map(
                (i) => {
                    return <option key={i.classificationId} value={i.classificationId}>{i.classificationId}</option>
                }
            )
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        classificationsTimber: state.start.classificationsTimber,
        togetherAPIelem: state.start.togetherAPIelem,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    auctionsSearchTogetherAPI,
    auctionsSearchCount,

    setTimberSortment,
    setTimberSpecies,
    setTimberClass,
    setTimberDiameter,
    setTimberStorage,
    setTimberProductionYear,
    setTimberProductionQuarter,
    setTimberLength1,
    setTimberLength2,
    changeLoader,
    setUniversalError,
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPIFilter)
