import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import BankAccountsGroups from './zuBankAccountsEdit'

import {disabledElement} from '../disabled'

const BankAccounts = ( props ) => {
    let tmp = null;
    if(props.typeElem === 1){
        if(props.bankAccountZU !== null){
            tmp = <>
                <BankAccountsGroups />
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            className={styles.inputMaxBank}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="outlined-select-currency-native"
                            select
                            label="Банківські рахунки"
                            value={props.createBankAccountZU.groupe}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'CREATE_BANK_ACCOUNT_ZU_GROUPE')
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати банківський рахунок"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {optionBankAccount(props)}
                        </TextField>
                    </div>
                </div>
                {viewCreatGroup(props)}
                <Button
                    disabled={disabledElement(props.auctionsMy, '00')}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            let tmp = {
                                id: props.createBankAccountZU.id,
                                group: props.createBankAccountZU.group,
                                type: props.createBankAccountZU.type
                            }
                            addArrayBankAccounts(props, tmp)
                            props.setDataAddress('null', 'CREATE_BANK_ACCOUNT_ZU_GROUPE')
                            props.setDataAddress('null', 'CREATE_BANK_ACCOUNT_ZU_ID')
                        }
                    }
                >
                    Додати
                </Button>
            </>
        }
    }else{
        if(props.bankAccountZU !== null){
            tmp = <>
                <BankAccountsGroups />
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            className={styles.inputMaxBank}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="outlined-select-currency-native"
                            select
                            label="Банківські рахунки"
                            value={props.createBankAccountZU.groupe}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'CREATE_BANK_ACCOUNT_ZU_GROUPE')
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати банківський рахунок"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {optionBankAccount(props)}
                        </TextField>
                    </div>
                </div>
                {viewCreatGroup(props)}
                <Button
                    error = {redBlock(props, props.auctionsMy.bankAccountsGroups)}
                    disabled={disabledElement(props.auctionsMy, '00')}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            handle(props, props.createBankAccountZU)
                            props.setDataAddress('null', 'CREATE_BANK_ACCOUNT_ZU_GROUPE')
                            props.setDataAddress('null', 'CREATE_BANK_ACCOUNT_ZU_ID')
                        }
                    }
                >
                    Додати
                </Button>
            </>
        }
    }
    return tmp
}

function optionBankAccount(props){
    if(
        props.auctionsMy.sellingMethod === 'alienation-english' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod'
    ){
        return (
            <>
                <option key={1} value='registrationFee' className={styles.boltFont}>Реєстраційний внесок</option>
                <option key={2} value='guarantee' className={styles.boltFont}>Гарантійний внесок</option>
                <option key={8} value='other'>Інші платежі</option>
            </>
        )
    }
    return (
        <>
            <option key={1} value='registrationFee'>Реєстраційний внесок</option>
            <option key={2} value='guarantee' className={styles.boltFont}>Гарантійний внесок</option>
            <option key={8} value='other'>Інші платежі</option>
        </>
    )
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null' || amount.length === 0){
            return 'error'
        }
        return null
    }
    return null
}

function viewCreatGroup(props){
    if(props.createBankAccountZU.groupe !== 'null' && props.createBankAccountZU.groupe !== null){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        className={styles.inputMaxBank}
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        label="Рахунки"
                        value={props.createBankAccountZU.id}
                        onChange={(e)=>{
                            props.setDataAddress(e.target.value, 'CREATE_BANK_ACCOUNT_ZU_ID')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText=" "
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        {selectBankAuction(props)}
                    </TextField>
                </div>
            </div>
        )
    }
    return null
}

async function handle(props, data){
    await addArrayBankAccounts(props, data)
    await addBankAccountZUtimestamp(props)
}

async function addArrayBankAccounts(props, elem){
    let tmp = props.bankAccountZU;
    if(tmp !== null){
        if(tmp.length === 0){
            tmp = []
            tmp.push(elem)
            props.setDataAddress(tmp, 'SET_POP_UP_BANK_ACCOUNT_ZU')
        }else{
            tmp.push(elem)
            props.setDataAddress(tmp, 'SET_POP_UP_BANK_ACCOUNT_ZU')
        }
    }else{
        tmp = []
        tmp.push(elem)
        props.setDataAddress(tmp, 'SET_POP_UP_BANK_ACCOUNT_ZU')
    }
}

async function addBankAccountZUtimestamp(props){
    let tmp = new Date()
    props.setDataAddress(String(tmp), 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP')
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i, count) => {
                return <option key={count+1} value={i.id}>{i.bankName}</option>
            }
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        bankAccountZU: state.start.bankAccountZU,
        bankAccount: state.start.bankAccount,
        createBankAccountZU: state.start.createBankAccountZU,
        createBankAccountZUtimestamp: state.start.createBankAccountZUtimestamp,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)
