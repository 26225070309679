import React from 'react';
import style from './settings.module.css';

import {connect} from 'react-redux';

const Settings = (props) => {
    let tmp = null;
    if(props.token !== null){
        tmp = (
            <div className={style.info_wrap}>
                Настройки
            </div>
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}
  
//const mapDispatchToProps = {}
  
export default connect(mapStateToProps, null)(Settings)