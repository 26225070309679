import React from 'react';
import styles from './togetherAPI_tab.module.css';

import {connect} from 'react-redux';

import {
    togetherApiPageSwitch,
    togetherApiBasePullAucEI,
    togetherApiSaveDataLoad,
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
} from '../../../../redux/togetherApi.js';

import {auctionsBidsGetMy} from  '../../../../redux/actions/auctionBid/auctionBid.js';
import {changeLoader, setUniversalError} from '../../../../redux/actions.js';
import {
    setDataAddress,
} from '../../../../redux/createAddress';
import {togetherApiBidsWorkArr} from '../../../../redux/togetherApi.js';

import {
    auctionsSearchTogetherAPI,
} from '../../../../redux/actions/auctions/auctions.js';

const TogetherAPIButtons = (props) => {
    let tmpStyleAuc = null;
    let tmpStyleBid = null;
    let tmpStyleActualAuc = null;
    if(props.togetherApiPage.pageSwitch === 0){
        tmpStyleAuc = styles.activ
    }else if(props.togetherApiPage.pageSwitch === 2){
        tmpStyleBid = styles.activ
    }else if(props.togetherApiPage.pageSwitch === 1){
        tmpStyleActualAuc = styles.activ
    }
    return (
        <div className={styles.buttonsTabAuctionPull}>
            <div
                className={styles.buttonsTabAuctionPull_buttons + ' ' + tmpStyleAuc}
                onClick={
                    () => {
                        props.togetherApiPageSwitch(0)
                        getActiveTenderAuction(props)
                    }
                }
            >
                Аукціони
            </div>
            <div
                className={styles.buttonsTabAuctionPull_buttons + ' ' + tmpStyleBid}
                onClick={
                    () => {
                        props.togetherApiPageSwitch(2)
                        getDraftBids(props)
                    }
                }
            >
                Чернетки та ставки
            </div>
            <div
                className={styles.buttonsTabAuctionPull_buttons + ' ' + tmpStyleActualAuc}
                onClick={
                    () => {
                        props.togetherApiSaveDataLoad(null)
                        props.togetherApiPageSwitch(1)
                        getActivAuction(props)
                    }
                }
            >
                Активні аукціони
            </div>
        </div>
    )
}

async function getActiveTenderAuction(props){
    await props.changeLoader(true)
    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let headerTogetherApi = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    let dataSearch = {
        category: props.search.category,
    }
    await props.auctionsSearchTogetherAPI(data5, headerTogetherApi, dataSearch)
    await props.togetherApiPageCurrentAuction(0)
    if(props.auctions.length === 10) {
        await props.togetherApiPageNextAuction(1)
    }else{
        await props.togetherApiPageNextAuction(0)
    }
    await props.changeLoader(false)
}

async function getDraftBids(props){
    await props.changeLoader(true)
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=25&status=active_tendering'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.togetherApiBidsWorkArr(null)
	props.setDataAddress(null, 'SET_MY_BIDS')
	props.setDataAddress(null, 'TOGETHER_API_BIDS_ARR')
	props.setDataAddress(null, 'TOGETHER_API_BIDS_WORK_ARR')
    await props.auctionsBidsGetMy(data, header)
    await props.changeLoader(false)
}

async function getActivAuction(props){
    await props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auctions/today?limit=50&sellingMethod=timber-english`
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка получення списку активних аукціонів', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            const arrAddClass = []
            for(let ii = 0; ii < json1.length; ii++){
                let tmpObj  = null;
                let tmpObj2  = null;
                if(json1[ii].bids.length > 0){
                    tmpObj = { [`${json1[ii].auctionId}`]: {
                        acc_token: json1[ii].bids[0].ownerToken,
                        bidder_id: json1[ii].bids[0].bidIdentifier
                    }}
                    tmpObj2 = { 
                        auctionId: json1[ii].auctionId,
                        acc_token: json1[ii].bids[0].ownerToken,
                        bidder_id: json1[ii].bids[0].bidIdentifier
                    }
                }
                let test = {
                    auction: json1[ii],
                    tmpObj: tmpObj,
                    tmpObj2: tmpObj2,
                    saveDataSoket: null,
                }
                arrAddClass.push(test)
            }
            let arrAddClass2 = []
            for(let ct = 0; ct < arrAddClass.length; ct++){
                if(arrAddClass[ct].tmpObj !== null) {
                    arrAddClass2.push(arrAddClass[ct])
                }
            }
            await props.togetherApiBasePullAucEI(arrAddClass2)            
        }
    }
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        classificationsTimber: state.start.classificationsTimber,
        togetherAPIelem: state.start.togetherAPIelem,
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
    }
}

const mapDispatchToProps = {
    togetherApiPageSwitch,
    auctionsBidsGetMy,
    setUniversalError,
    togetherApiBasePullAucEI,
    togetherApiSaveDataLoad,
    auctionsSearchTogetherAPI,
    changeLoader,
    togetherApiPageCurrentAuction,
    togetherApiPageNextAuction,
    togetherApiBidsWorkArr,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPIButtons)
