import React from 'react';
import style from './popupDoc.module.css';

import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiDocFileNameECP,
    togetherApiECP,
} from '../../../../../redux/togetherApi.js';


import {ecpApiPass, ecpApiList, changeCA} from '../../../../../redux/ecpApi.js';

import {changeLoader, setUniversalError} from '../../../../../redux/actions.js';

const BidError = ( props ) => {
    let tmpStyle
    let ecp
    if(props.togetherApiPage.ecpOnOff === false){
        tmpStyle = style.pseudoCheckboxOn
        ecp = <div className={style.groupElem}>
            {buttonCreateImg(props)}
        </div>
    }else{
        tmpStyle = style.pseudoCheckboxOff
        ecp = blocEcpData(props)
    }
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={
                        () => {
                            props.togetherApiPopUpOnOff(0)
                            props.togetherApiDocTitle('')
                            props.togetherApiDocDescription('')
                            props.togetherApiDocLang('null')
                            props.togetherApiDocType('null')
                            props.togetherApiDocFileName('')
                            props.togetherApiDocFileNameECP('')
                            props.ecpApiPass('')
                            props.changeCA('null')
                        }
                    }
                >X</div>
                <div className={style.title}>Додання документу</div>
                <div className={style.chekbox}>
                    <div>Створення КЕП</div>
                    <div
                        className={tmpStyle}
                        onClick={
                            () => {
                                epListCertificateAuthority(props)
                                props.togetherApiDocFileName('')
                                props.togetherApiDocFileNameECP('')
                                if(props.togetherApiPage.ecpOnOff === false){
                                    props.togetherApiECP(true)
                                }else{
                                    props.togetherApiECP(false)
                                }
                            }
                        }
                    ></div>
                </div>
                <div className={style.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={style.inputText}
                            label="Назва"
                            variant="outlined"
                            value={props.togetherApiPage.docData.title}
                            onChange={
                                (e) => {
                                    props.togetherApiDocTitle(e.target.value)
                                }
                            }
                        />
                    </div>
                </div>
                <div className={style.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={style.inputText}
                            label="Опис"
                            variant="outlined"
                            value={props.togetherApiPage.docData.description}
                            onChange={
                                (e) => {
                                    props.togetherApiDocDescription(e.target.value)
                                }
                            }
                        />
                    </div>
                </div>
                {typeDocument(props)}
                <div className={style.groupElem}>
                    <div>
                        <select
                            disabled='disabled'
                            className={style.selectWidth}
                            value={props.togetherApiPage.docData.lang}
                            onChange={(event)=>{
                                props.togetherApiDocLang(event.target.value)
                            }}
                        >
                            <option value='uk'>Українська</option>
                        </select>
                    </div>
                </div>
                {ecp}
            </div>
        </div>
    )
}

function typeDocument(props){
    return <div className={style.groupElem}>
        <div>
            <select
                className={style.selectWidth}
                value={props.togetherApiPage.docData.type}
                onChange={(event)=>{
                    props.togetherApiDocType(event.target.value)
                }}
            >
                <option key='null' selected disabled value="null">Обрати тип документу</option>
                <option key={11} disabled>Обов'язкові документи</option>
                <option key={7} value='x_nonSanctionedStatement'>Заява про непоширення обмежувальних заходів (санкції)</option>
                <option key={12} disabled>Не обов'язкові документи</option>
                <option key={1} value='commercialProposal'>Заява на участь</option>
                <option key={2} value='qualificationDocuments'>Документи що підтверджують кваліфікацію</option>
                <option key={3} value='x_passport'>Копія паспорта або документа, що посвідчує особу</option>
                <option key={4} value='x_IPN'>Копія ІПН/РНОКПП</option>
                <option key={5} value='x_tenderersRegisterExtract'>Витяг з ЄДРПОУ</option>
                <option key={6} value='x_nonResidentRegistrations'>Документ про реєстрацію у державі її місцезнаходження (нерезидент)</option>
                <option key={8} value='eligibilityDocuments'>Документи що підтверджують відповідність</option>
            </select>
        </div>
    </div>
}

function buttonCreateImg(props){
    let fileElem;
    if(props.togetherApiPage.docData.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Завантажити документ</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Доданий документ:</span> <span>{props.togetherApiPage.docData.fileName}</span>
        </span>
    }
    return (
        <div className={style.formLine}>
            <form 
                onSubmit={(e) => {
                    handleSubmit(e, props)
                }}
                id="addFileForm"
            >
                <div className={style.input__wrapper}>
                    <input
                        name="file"
                        type="file"
                        id="input__file"
                        className={style.input + ' ' + style.input__file}
                        onChange={() => {
                            let addFileInp1 = document.getElementById('input__file');
                            props.togetherApiDocFileName(addFileInp1.files[0].name)
                        }}
                    />
                    <label for="input__file" className={style.input__file_button}>
                        {fileElem}
                    </label>
                </div>
                <div className={style.lineButtons}>
                    <div className={style.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Зберегти
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

async function handleSubmit(e, props){
    e.preventDefault();

    if(props.togetherApiPage.popUpOnOff === 2){
        if(props.togetherApiPage.ecpOnOff === false){
            massAddDocBid(props)
        }else{
            massAddEcpDocBid(props)
        }
    }else{
        if(props.togetherApiPage.ecpOnOff === false){
            saveDocument(props)
        }else{
            await signetDocNew(
                props, 
                props.togetherApiPage.popUpOnOff.data.auction.id,
                props.togetherApiPage.popUpOnOff.data.id
            )
            await props.togetherApiDocTitle('')
            await props.togetherApiDocDescription('')
            await props.togetherApiDocLang('null')
            await props.togetherApiDocType('null')
            await props.togetherApiDocFileName('')
            await props.togetherApiDocFileNameECP('')
            await props.ecpApiPass('')
            await props.changeCA('null')
        }
    }
}

async function saveDocument(props){
    await props.changeLoader(true)
    const addFileInp = document.getElementById('input__file');
    const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.togetherApiPage.docData.type)
    formData.append('file', addFileInp.files[0])
    const response1 =  await fetch(endPoint1, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json2 = await response1.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка загрузки файлу', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
        }else{
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
            const response2 = await fetch(endPoint2, {
                method: "POST",
                body: JSON.stringify({
                    title: props.togetherApiPage.docData.title,
                    description: props.togetherApiPage.docData.description,
                    documentType: props.togetherApiPage.docData.type,
                    language: 'uk',
                    documentOf: 'bid',          //не меняем
                    //hash: "string",
                    token: json2.jwt                 //не меняем
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json3 = await response2.json()
                if(json3.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка створення документу', message: JSON.stringify(json3)})
                    props.setUniversalError(tmp)
                }else{
                    const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.togetherApiPage.popUpOnOff.data.auction.id}/bid/${props.togetherApiPage.popUpOnOff.data.id}/documents/${json3.id}`;
                    const response3 = await fetch(endPoint3, {
                        method: "POST",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response3 === undefined || response3 === 'undefined'){
                    }else{
                        const json4 = await response3.json()
                        if(json2.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка додання документу до чернетки', message: JSON.stringify(json4)})
                            props.setUniversalError(tmp)
                        }else{
                            await props.changeLoader(false)
                            await props.togetherApiDocTitle('')
                            await props.togetherApiDocDescription('')
                            await props.togetherApiDocLang('null')
                            await props.togetherApiDocType('null')
                            await props.togetherApiDocFileName('')
                            await props.togetherApiDocFileNameECP('')
                            await props.ecpApiPass('')
                            await props.changeCA('null')
                        }
                    }
                }
            }
        }
    }
}

async function massAddDocBid(props){
    await props.changeLoader(true)
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            for(let i = 0; i < props.togetherApiPage.bidsDraftWorkArr.length; i++){
                if(props.togetherApiPage.bidsDraftWorkArr[i] === undefined) continue
                if(props.togetherApiPage.bidsDraftWorkArr[i].chekbox === true){
                    await saveMassDocument(props, props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id, props.togetherApiPage.bidsDraftWorkArr[i].auction.id)
                }
            }
        }
    }
    await props.changeLoader(false)
    await props.togetherApiDocTitle('')
    await props.togetherApiDocDescription('')
    await props.togetherApiDocLang('null')
    await props.togetherApiDocType('null')
    await props.togetherApiDocFileName('')
    await props.togetherApiDocFileNameECP('')
    await props.ecpApiPass('')
    await props.changeCA('null')

}

async function massAddEcpDocBid(props){
    await props.changeLoader(true)
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            for(let i = 0; i < props.togetherApiPage.bidsDraftWorkArr.length; i++){
                if(props.togetherApiPage.bidsDraftWorkArr[i] === undefined) continue
                if(props.togetherApiPage.bidsDraftWorkArr[i].chekbox === true){
                    //await saveMassDocument(props, props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id, props.togetherApiPage.bidsDraftWorkArr[i].auction.id)
                    await signetDocNew(
                        props, 
                        props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id,
                        props.togetherApiPage.bidsDraftWorkArr[i].auction.id
                    )
                }
            }
        }
    }
    await props.changeLoader(false)
    await props.togetherApiDocTitle('')
    await props.togetherApiDocDescription('')
    await props.togetherApiDocLang('null')
    await props.togetherApiDocType('null')
    await props.togetherApiDocFileName('')
    await props.togetherApiDocFileNameECP('')
    await props.ecpApiPass('')
    await props.changeCA('null')
}

async function saveMassDocument(props, aucId, bidId){
    const addFileInp = document.getElementById('input__file');
    const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.togetherApiPage.docData.type)
    formData.append('file', addFileInp.files[0])
    const response1 =  await fetch(endPoint1, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json2 = await response1.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка загрузки файлу', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
        }else{
            const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
            const response2 = await fetch(endPoint2, {
                method: "POST",
                body: JSON.stringify({
                    title: props.togetherApiPage.docData.title,
                    description: props.togetherApiPage.docData.description,
                    documentType: props.togetherApiPage.docData.type,
                    language: 'uk',
                    documentOf: 'bid',          //не меняем
                    //hash: "string",
                    token: json2.jwt                 //не меняем
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
            }else{
                const json3 = await response2.json()
                if(json3.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка створення документу', message: JSON.stringify(json3)})
                    props.setUniversalError(tmp)
                }else{
                    const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/bid/${bidId}/documents/${json3.id}`;
                    const response3 = await fetch(endPoint3, {
                        method: "POST",
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response3 === undefined || response3 === 'undefined'){
                    }else{
                        const json4 = await response3.json()
                        if(json2.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка додання документу до чернетки', message: JSON.stringify(json4)})
                            props.setUniversalError(tmp)
                        }else{

                        }
                    }
                }
            }
        }
    }
}

///////////////////////////////////////////////
///////////////////////////////////////////////


function blocEcpData(props){
    let fileElem;
    if(props.togetherApiPage.docData.fileName === null){
        fileElem = <span className={style.input__file_button_text}>
            <span>Завантажити документ</span>
        </span>
    }else{
        fileElem = <span className={style.input__file_button_text}>
            <span>Доданий документ:</span> <span>{props.togetherApiPage.docData.fileName}</span>
        </span>
    }
    let fileElemECP;
    if(props.togetherApiPage.docData.fileName === null){
        fileElemECP = <span className={style.input__file_button_text}>
            <span>Завантажити ключ</span>
        </span>
    }else{
        fileElemECP = <span className={style.input__file_button_text}>
            <span>Доданий ключ:</span> <span>{props.togetherApiPage.docData.fileNameECP}</span>
        </span>
    }
    return (
        <div className={style.formLine}>
            <form 
                onSubmit={(e) => {
                    //handleSubmit2(e, props)
                    handleSubmit(e, props)
                }}
                id="addFileForm"
            >
                <div className={style.input__wrapper}>
                    Документ
                    <input
                        name="file"
                        type="file"
                        id="input__fileDoc"
                        className={style.input + ' ' + style.input__file}
                        onChange={() => {
                            let addFileInp1 = document.getElementById('input__fileDoc');
                            props.togetherApiDocFileName(addFileInp1.files[0].name)
                        }}
                    />
                    <label for="input__fileDoc" className={style.input__file_button}>
                        {fileElem}
                    </label>
                </div>
                <div className={style.input__wrapper}>
                    Ключ КЕП
                    <input
                        name="file"
                        type="file"
                        id="input__fileECP"
                        className={style.input + ' ' + style.input__file}
                        onChange={() => {
                            let addFileInpEcp = document.getElementById('input__fileECP');
                            props.togetherApiDocFileNameECP(addFileInpEcp.files[0].name)
                        }}
                    />
                    <label for="input__fileECP" className={style.input__file_button}>
                        {fileElemECP}
                    </label>
                </div>
                {listCA(props)}
                <div className={style.groupElem}>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            className={style.inputText}
                            label="Пароль"
                            variant="outlined"
                            value={props.ecpData.pass}
                            onChange={
                                (e) => {
                                    props.ecpApiPass(e.target.value)
                                }
                            }
                        />
                    </div>
                </div>
                <div className={style.lineButtons}>
                    <div className={style.editButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Зберегти
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

/////////////////////////////////////////////
//Креплю КЕП
/////////////////////////////////////////////

async function upload(props, blob, idBid) {
    return new Promise(function(resolve, reject){
        let request = new XMLHttpRequest();
        let jsonData;
        request.onload = function() {
            if(request.status === 200 && request.readyState === 4){
                jsonData = JSON.parse(request.responseText);
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
                fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify({
                        title: props.togetherApiPage.docData.title,
                        description: props.togetherApiPage.docData.description,
                        //documentType: props.bidCvalification,
                        documentOf: 'bid',
                        language: 'uk',
                        //hash: "string",
                        token: jsonData.jwt
                    }),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(
                    async (response) => {
                        const json = await response.json()
                        const endPoint1 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.togetherApiPage.bidsDraftArr[0].id}/bid/${idBid}/documents/${json.id}`;
                        await fetch(endPoint1, {
                            method: "POST",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        })
                    }
                );
                resolve(jsonData);
            }else{
                reject(request.status);
            }
        }
        request.onerror = function (error) {
            reject(error);
        }
        let fd = new FormData();
        //fd.append('name', "test.txt");
        let fileName = props.togetherApiPage.docData.fileName + '.p7s'
        fd.append('name', fileName)
        fd.append("file", blob);
        //fd.append('documentType', 'illustration')
        fd.append('documentType', props.togetherApiPage.docData.type)
        fd.append('Content-Type', 'multipart/form-data')
        fd.append('Authorization', props.token.access_token)
        request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`, true);
        request.send(fd);
    })
}



/*function post( _url, data ) {
    return new Promise(function(resolve, reject){
        let request = new XMLHttpRequest();
        let jsonData;
        request.open("POST", _url);
        request.setRequestHeader( 'X-Auth-Token', state.school.baseData.token);
        request.setRequestHeader( 'Api-key', state.school.baseData.apikey);
        request.setRequestHeader("Content-type", "application/json");
        request.onload = function () {
            if (request.status === 200 && request.readyState === 4) {
                jsonData = JSON.parse(request.responseText);
                resolve(jsonData);
            } else {
                if(request.response.indexOf("bad_client") === 0){
                    reject(999);
                }else{
                    reject(request.status);    
                }
            }
        }
        request.onerror = function (error) {
            reject(error);
        }
        request.send(data);
    });
},*/

//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//Подвязка сингл КЕП
/////////////////////////////////////////////
//Креплю КЕП
///////////////////////////////////////////// 
async function signetDocNew(props, idAuc, idBid){
    await props.changeLoader(true)
    let prozzoroJWTFile = await epUploadDataProzzoro(props)
    let propzzoroDocId = await epCreateDocProzzoro(props, prozzoroJWTFile)
    let prozzoroStringId = await epAddDocToAucProzzoro(props, propzzoroDocId, idAuc, idBid)
    if(prozzoroStringId !== null || prozzoroStringId !== undefined || prozzoroStringId !== 'undefined'){
        let data = await epSessionStart()       //Создаем сессию
        await epSessionTest(data)               //Проверка создания сессии
        await epSessionDataLoad(data)           //Загрузка документа
        await epMetadata(props, data)                  //Загрузка метадаты
        let param = {
            caId: props.togetherApiPage.listChoise,
            cadesType: "CAdESXLong",
            cleanUpForm: false,
            dataToSignQualifier: "notSignedBefore",
            dataTsVerifyOption: "ignore",
            duplicateSign: false,
            embedCertificateType: "signerAndCaCert",
            embedDataTs: "true",
            embedDataTsChecked: true,
            embedSignatureTs: "true",
            embedSignatureTsChecked: true,
            expandCreateDSCadesType: false,
            expandCreateDSTSVerify: false,
            expandCreateDSType: true,
            generateQR: false,
            showUpgradeCard: false,
            signatureTsVerifyOption: "ignore",
            signatureType: "attached",
            tsAdditionalVerifying: false,
        }
        await epSessionSetParam2(data, param)   //Настройка параметров сессии
        await epSessionMainContainer(data)      //Загрузка ключа
        await epCreateEP(props, data)                  //Подгрузка пароля ключа
        let data1 = await epGetDataEP(data)                 //Получить данные
        await epGetCreatorDataEP(data)          //Получить данные
        await epSessionDelete(data)             //Закрытие сессии*/
        await epUploadDataNew(props, data1, prozzoroStringId, idAuc, idBid)
    }
    await props.changeLoader(false)
}


///////////////////////////////////////////////////////////////////////////////////
//Загрузка неподписаного файла на прозоро
async function epUploadDataProzzoro(props){
    const addFileInp = document.getElementById('input__fileDoc');
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    const formData = new FormData();
    formData.append('documentType', props.togetherApiPage.docData.type)
    formData.append('file', addFileInp.files[0])
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

//////////////////////////////////////////////////
//Подключение файла к документу
async function epCreateDocProzzoro(props, data){
    const json = await data
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            title: props.togetherApiPage.docData.title,
            description: props.togetherApiPage.docData.description,
            documentType: props.togetherApiPage.docData.type,
            language: 'uk',
            documentOf: 'bid',          //не меняем
            //hash: "string",
            token: json.jwt                 //не меняем
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

///////////////////////////////////////////////////
//Подключение документа к биду
async function epAddDocToAucProzzoro(props, data, idAuc, idBid){
    const json = await data
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${idAuc}/bid/${idBid}/documents/${json.id}`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let tmp = await response.json()
        for(let i = 0; i < tmp.documents.length; i++){
            if(tmp.documents[i].id === json.id) return tmp.documents[i].documentId
        }
        return null
    }
}

/**
 * Создание сессии
 */
async function epSessionStart(){
    let url = 'https://caas-api.dzo.com.ua/ccs/api/v1/ticket';
    const response = await fetch(url, {
            method: "POST",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Проверка наличия сессии
 */
async function epSessionTest(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 * Заменить
 */ 
async function epSessionDataLoad(data){
    const addFileInp = document.getElementById('input__fileDoc');
    const url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/data`;
    const formData = new FormData();
    formData.append('uploadTicketData', addFileInp.files[0])
    const response = await fetch(url, {
        method: "POST",
        body: formData
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Передача мета данных
 */ 
async function epMetadata(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/metadata`;

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            metaData: props.togetherApiPage.docData.fileName
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить данные сессии
 */
async function epSessionSetParam2(data, param){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/option`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(param)
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузить ключевой контейнер сессии
 */
async function epSessionMainContainer(data){
    const addFileInpEP = document.getElementById('input__fileECP');
    const file = await toBase64(addFileInpEP.files[0])

    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/keyStore`;
    const response = await fetch(url, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json' //Base64
        },
        body: JSON.stringify({
            "base64Data": file.substr(37)
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    //reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

/**
 * Создать ЭП
 */
async function epCreateEP(props, data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            keyStorePassword: "tect3"
            //keyStorePassword: props.ecpData.pass
        })
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
async function epGetDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/base64Data`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
           return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Получить данные ЭП
 */
async function epGetCreatorDataEP(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}/ds/creator`;
    const response =  fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Удаление сессии
 * @param {*} data 
 */
async function epSessionDelete(data){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/ticket/${data.ticketUuid}`;
    const response = await fetch(url, {
        method: "DELETE",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    return response
}

/**
 * Загрузка файла КЕП на прозоро
 */
async function epUploadDataNew(props, data, relatedDocument, idAuc, idBid){
    const base64 = `data:application/pkcs7-signature;base64,${data.base64Data}`  //Файл в бейс 64
    fetch(base64)
    .then(res => res.blob())
    .then(blob => {
        uploadNew(props, blob, relatedDocument, idAuc, idBid)
    })
}

async function uploadNew(props, blob, relatedDocument, idAuc, idBid) {
    return new Promise(function(resolve, reject){
        let request = new XMLHttpRequest();
        let jsonData;
        request.onload = function() {
            if(request.status === 200 && request.readyState === 4){
                jsonData = JSON.parse(request.responseText);
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/document`;
                fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify({
                        title: props.togetherApiPage.docData.title,
                        description: props.togetherApiPage.docData.description,
                        documentType: 'digitalSignature',
                        language: 'uk',
                        documentOf: 'bid',          //не меняем
                        //hash: "string",
                        token: jsonData.jwt,
                        relatedDocument: relatedDocument,
                    }),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(
                    async (response) => {
                        const json = await response.json()
                        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${idAuc}/bid/${idBid}/documents/${json.id}`;
                        const response2 = await fetch(endPoint2, {
                            method: "POST",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(response2 === undefined || response2 === 'undefined'){
                        }else{
                        }
                    }
                );
                resolve(jsonData);
            }else{
                reject(request.status);
            }
        }
        request.onerror = function (error) {
            reject(error);
        }
        let fd = new FormData();
        //fd.append('name', "test.txt");
        let fileName = props.togetherApiPage.docData.fileName + '.p7s'
        fd.append('name', fileName)
        fd.append("file", blob);
        //fd.append('documentType', 'illustration')
        fd.append('documentType', 'digitalSignature')
        fd.append('Content-Type', 'multipart/form-data')
        fd.append('Authorization', props.token.access_token)
        request.open('POST', `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`, true);
        request.send(fd);
    })
}

//////////////////////////////////////////////
//Получаем список выдавателей сертификатов
async function epListCertificateAuthority(props){
    let url = `https://caas-api.dzo.com.ua/ccs/api/v1/certificateAuthority/supported`;
        const response = await fetch(url, {
        method: "GET",
    })
    .then(
        (response) => {
            return response.json()
        }
    )
    .catch(console.error)
    props.ecpApiList(response)
}

function listCA(props){
    return <div className={style.groupElem}>
        <div>
            Обрати КНЕДП/АЦСК
            <select
                className={style.selectWidth}
                value={props.togetherApiPage.listChoise}
                onChange={(event)=>{
                    props.changeCA(event.target.value)
                }}
            >
                <option key='null' selected disabled value="null">Обрати</option>
                {optionCA(props)}
            </select>
        </div>
    </div>
}

function optionCA(props){
    let tmp = null
    if(props.togetherApiPage.list !== null){
        if(props.togetherApiPage.list.ca.length > 0){
            tmp = props.togetherApiPage.list.ca.map(
                (i, count) => {
                    return <option key={count} value={i.id}>{i.name}</option>
                }
            )
        }
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        togetherApiPage: state.start.togetherApiPage,
        endPoint: state.start.endPoint,
        ecpData: state.start.ecp,
    }
}

const mapDispatchToProps = {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiDocFileNameECP,
    setUniversalError,
    togetherApiECP,
    ecpApiPass,
    ecpApiList,
    changeCA,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidError)