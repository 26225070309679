import React, {useState} from 'react';
import styles from './../windowItems.module.css';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader, setUniversalError,
} from '../../../../redux/actions.js';

import {checkProp, isMongo} from "../../../../redux/check";
import MultiSelectSimple from "../../../elements/inputs/multiSelect/multiSelectSimple";
import {TranslationsUk} from "../../../../redux/translations.uk.js";
import {getDefaultHeaders} from "../../../_api/bidApi";
import Select from "../../../elements/inputs/inputSelect";
import Input from "../../../elements/inputs/input";
import TextArea from "../../../elements/inputs/textArea";
import Button from '../../../elements/buttons/button';


//@todo DUPLICATES

const regionsList = ['Севастополь', 'Київ', 'Чернігівська область', 'Чернівецька область', 'Черкаська область',
    'Хмельницька область', 'Херсонська область', 'Харківська область', 'Тернопільська область', 'Сумська область',
    'Рівненська область', 'Полтавська область', 'Одеська область', 'Миколаївська область', 'Львівська область',
    'Луганська область', 'Кіровоградська область', 'Київська область', 'Івано-Франківська область', 'Запорізька область',
    'Закарпатська область', 'Житомирська область', 'Донецька область', 'Дніпропетровська область',
    'Волинська область', 'Вінницька область', 'Автономна Республіка Крим',
]


const RenewablesItemProps = ({data, classification, ...props}) => {
    const [itemId, setItemId] = useState(checkProp(data, 'id') ? data.id : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [quantity, setQuantity] = useState(checkProp(data, 'quantity') ? data.quantity : null)

    const [regions, setRegions] = useState(data?.itemProps?.regions?.length > 0 ? data?.itemProps?.regions : null)
    const [techParams, setTechParams] = useState(checkProp(data?.itemProps, 'techParams') ? data.itemProps.techParams : null)
    const [timeSlots, setTimeSlots] = useState(checkProp(data?.itemProps, 'timeSlots') ? data.itemProps.timeSlots : null)
    const [loadProfiles, setLoadProfiles] = useState(checkProp(data?.itemProps, 'loadProfiles') ? data.itemProps.loadProfiles : null)

    async function saveItem(props, itemId) {
        props.changeLoader(true)
        let route = 'auction'
        let method = itemId ? 'PUT' : 'POST'
        if (props.auctionsMy.status === null) route = 'auction-draft'

        let url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}/item`;
        if (itemId) {
            url += '/' + itemId
        }

        let body = {
            itemProps: {}
        }
        body.description = description && description?.length > 1 ? {'uk_UA': description} : null
        body.quantity = quantity ?? null;
        body.unit = {code: 'KWT'};

        body.itemProps.techParams = techParams ?? null
        body.itemProps.timeSlots = timeSlots ?? null
        body.itemProps.loadProfiles = loadProfiles ?? null
        body.itemProps.regions = regions ?? null

        // console.log(`updating item: ${method}`, itemId, body);

        const response = await fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: getDefaultHeaders(props)
        }).catch(error => {
            props.changeLoader(false)
            console.error('Api request error:', error);
            props.setUniversalError(JSON.stringify({
                title: 'Помилка',
                message: 'При збереженні інформації виникла помилка'
            }))
        })
        let json = await response.json()
        if (json?.items[0]?.id) {
            setItemId(json?.items[0]?.id)
        }
        if (json.hasOwnProperty('message') === true) {
            props.changeLoader(false)
            let tmp
            if (JSON.stringify(json) === '{"message":{"documents":"Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"}}') {
                tmp = JSON.stringify({
                    title: 'Помилка при збереженні доданих даних',
                    message: 'Для редагування аукціону спочатку необхідно додати документ "Погодження змін до опису лоту, опис причин редагування"'
                })
            } else {
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}`;
            const response = await fetch(endPoint, {
                method: "GET",
                headers: getDefaultHeaders(props)
            }).catch(error => {
                props.changeLoader(false)
                console.error('Error during procedure sync:', error);
            })
            const json = await response.json()
            if (json.hasOwnProperty('message') === true) {
                let tmp = JSON.stringify({
                    title: 'Помилка при завантаженні аукціонів',
                    message: JSON.stringify(json)
                })
                props.setUniversalError(tmp)
            } else {
                await props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            }

            props.setUniversalError(JSON.stringify({
                title: 'Інформацію збережено',
                message: ''
            }))
            props.changeLoader(false)
        }
    }

    function viewButtons(props, itemId) {
        if (
            description?.length > 0
            && quantity > 0
        ) {
            return (
                <div className={styles.lineButtons}>
                    <Button
                        data={'Зберегти зміни'}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                saveItem(props, itemId)
                            }
                        }
                    />
                </div>
            )
        }
    }

    return (
        <>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Select
                    data={{
                        label: TranslationsUk['renewables']?.Item?.classification?.legalName ?? "Класифікатор об'єкта",
                        info: "Автогенерується після публікації аукціону"
                    }}
                    label="09300000-2 - Електрична, теплова, сонячна та атомна енергія"
                    options={[
                        {value: "09300000-2", name: "09300000-2 - Електрична, теплова, сонячна та атомна енергія"},
                    ]}
                    disabled={true}
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Select
                    data={{
                        label: TranslationsUk['renewables']?.Item?.AdditionalClassification?.legalName ?? "Додатковий класифікатор",
                        // target: "09300000-2",
                        info: "Автогенерується після публікації аукціону"
                    }}
                    label="Автогенерується під час публікації процедури на ЦБД"
                    disabled={true}
                    options={[
                        {value: "09300000-2", name: "09300000-2 - Електрична, теплова, сонячна та атомна енергія"},
                    ]}
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <TextArea
                    value={description}
                    label={TranslationsUk['renewables'].Item?.description?.legalName ?? "Опис об'єкта"}
                    required
                    onChange={(event) => {
                        setDescription(event)
                    }}
                />
            </div>

            {/*<div>Специфічні поля для об'єкта електроенергетики</div>*/}
            <div className={styles.inPutWrapColumn}>
                <MultiSelectSimple
                    label={TranslationsUk['renewables'].Item?.itemProps?.regions?.legalName}
                    value={regions}
                    options={regionsList}
                    dictionary={true}
                    setUpLevel={(value) => {
                        setRegions(value)
                    }}
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Input
                    label={TranslationsUk['renewables'].Item?.itemProps?.techParams?.legalName}
                    value={techParams}
                    onChange={(event) => {
                        setTechParams(event)
                    }}
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Input
                    label={TranslationsUk['renewables'].Item?.itemProps?.timeSlots?.legalName}
                    value={timeSlots}
                    onChange={(event) => {
                        setTimeSlots(event)
                    }}
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Input
                    label={TranslationsUk['renewables'].Item?.itemProps?.loadProfiles?.legalName}
                    value={loadProfiles}
                    onChange={(event) => {
                        setLoadProfiles(event)
                    }}
                />
            </div>

            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Input
                    type={'number'}
                    label={TranslationsUk['renewables'].Item?.quantity?.legalName}
                    value={quantity}
                    min={0}
                    step={0.0001}
                    onChange={(event) => {
                        let value = parseFloat(event)
                        value = Math.round((value + Number.EPSILON) * 10000) / 10000
                        setQuantity(value)
                        console.log(value, quantity)
                    }}
                    required
                />
            </div>
            <div className={styles.groupElem + ' ' + styles.fullWidth}>
                <Select
                    data={{
                        label: TranslationsUk['renewables'].Item?.unit?.legalName,
                        info: TranslationsUk['renewables'].Item?.unit?.legalName
                    }}
                    label="кВт"
                    options={[
                        {value: "KWT", name: "кВт"},
                    ]}
                    disabled={true}
                />
            </div>
            {viewButtons(props, itemId)}
        </>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewablesItemProps)