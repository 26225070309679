import React from 'react';
import styles from './togetherAPI.module.css';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import {connect} from 'react-redux';

import {setPopUpBidTogetherAPI} from '../../../../redux/actions/auctions/auctions.js';
import {togetherApiBidsArr, togetherApiBidsWorkArr, togetherApiPopUpOnOff} from '../../../../redux/togetherApi.js';
import CreateAuctionLoader from '../../../createAuction/createAuctionLoader.js';
import {auctionsBidsGetMy} from  '../../../../redux/actions/auctionBid/auctionBid.js';
import {changeLoader, setUniversalError} from '../../../../redux/actions.js';
import {
    setDataAddress,
} from '../../../../redux/createAddress';

import DeleteIcon from '@material-ui/icons/Delete'

const TogetherAPI = (props) => {
    let tmp;
    tmp = <div className={styles.auc_base_block}>
        <div className={styles.auc_line}>
            <div className={styles.baseData + ' ' + styles.chekbox}><div className={styles.obratu}>Обрати</div></div>
            <div className={styles.baseData + ' ' + styles.seller}>Продавець</div>
            <div className={styles.baseData + ' ' + styles.sortment}>Назва сортименту</div>
            <div className={styles.baseData + ' ' + styles.species}>Порода</div>
            <div className={styles.baseData + ' ' + styles.class}>Клас якості</div>
            <div className={styles.baseData + ' ' + styles.length}>Розміри</div>
            <div className={styles.baseData + ' ' + styles.storage}>Склад</div>
            <div className={styles.baseData + ' ' + styles.quantity}>Обсяг</div>
            <div className={styles.baseData + ' ' + styles.baseCoast}>Початкова ціна, грн.</div>
            <div className={styles.baseData + ' ' + styles.baseCoast}>Початкова пропозиція, грн.</div>
            <div className={styles.baseData + ' ' + styles.auctionStatus}>Статус аукціону</div>
            <div className={styles.baseData + ' ' + styles.buttonDoc}>Додавання документу</div>
            <div className={styles.baseData + ' ' + styles.bidStart}>Створити чернетку</div>
            <div className={styles.baseData + ' ' + styles.bidDel}>Видалення</div>
            <div className={styles.baseData + ' ' + styles.linkToAuction}>Посилання на аукціон</div>
        </div>
        {listsMyAuctionsBids(props)}
        <div className={styles.massButtonBlock}>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            massOffChekbox(props)
                        }
                    }
                >
                    Зняти флажок з усіх чернеток
                </Button>
            </div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            massDeleteBid(props)
                        }
                    }
                >
                    Видалити обрані чернетки
                </Button>
            </div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.togetherApiPopUpOnOff(2)
                        }
                    }
                >
                    Додати документ до обраних чернеток
                </Button>
            </div>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            massSendBid(props)
                        }
                    }
                >
                    Активувати обрані чернетки
                </Button>
            </div>
        </div>
    </div>
    return (
        <div className={styles.wrap_auc}>
            {tmp}
        </div>
    )
}

//props.changeLoader(false)

function listsMyAuctionsBids(props){
    let tmpArr = []
    if(props.myBids !== null){
        if(props.myBids.length > 0){
            for(let i = 0; i < props.myBids.length; i++){
                if(props.myBids[i].auction.sellingMethod === "renewables") continue
                if(props.myBids[i].auction.sellingMethod === "renewables-fast") continue
                if(props.myBids[i].auction.sellingMethod === "renewables-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "renewables-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "renewables-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "renewables-initial-qualification-fast") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-english") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-fast") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "railwayCargo-dutch-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch-fast") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-dutch-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "subsoil-english-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-fast") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "dgf-dutch-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-fast") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "landRental-english-initial-qualification-prod") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-fast") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-fast-manual") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-fast-auction-manual-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-initial-auction") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-initial-auction-manual") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-initial-qualification") continue
                if(props.myBids[i].auction.sellingMethod === "landSell-english-initial-qualification-prod") continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-fast') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-fast-manual') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification') continue
                if(props.myBids[i].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod') continue
                if(props.myBids[i].auction.status === "unsuccessful") continue
                if(props.myBids[i].auction.status === "cancelled") continue
                if(props.myBids[i].auction.status === "complete") continue
                if(props.myBids[i].auction.status === "active_awarded") continue
                if(props.myBids[i].auction.status === "active_qualification") continue
                tmpArr[i] = {auction: props.myBids[i], chekbox: false}
            }
            if(props.togetherApiPage.bidsDraftWorkArr === null){
                //if(props.togetherApiPage.bidsDraftWorkArr.length === 0) {
                    props.togetherApiBidsWorkArr(tmpArr)
                //}
            }
        }
    }
    

    let tmp = null;
    //if(props.togetherApiPage.bidsDraftArr !== null){
    //    if(props.togetherApiPage.bidsDraftArr.length > 0){
    //        tmp = props.togetherApiPage.bidsDraftArr
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            tmp = props.togetherApiPage.bidsDraftWorkArr
            .filter(
                (e) => {
                    return e.auction.auction.status === 'active_tendering'
                }
            )
            .map(
                (i, count) => {
                    let tmpSortment = null
                    let tmpSpecies = null
                    let tmpClass = null
                    let tmpLength = null
                    let tmpStorage = null
                    for(let tt = 0; tt < i.auction.auction.items[0].additionalClassifications.length; tt++){
                        if(i.auction.auction.items[0].additionalClassifications[tt].scheme === "timberSortment") tmpSortment = i.auction.auction.items[0].additionalClassifications[tt].description
                        if(i.auction.auction.items[0].additionalClassifications[tt].scheme === "timberSpecies") tmpSpecies = i.auction.auction.items[0].additionalClassifications[tt].description
                        if(i.auction.auction.items[0].additionalClassifications[tt].scheme === "timberClass") tmpClass = i.auction.auction.items[0].additionalClassifications[tt].description
                        if(i.auction.auction.items[0].additionalClassifications[tt].scheme === "timberLength") tmpLength = i.auction.auction.items[0].additionalClassifications[tt].classificationId
                        if(i.auction.auction.items[0].additionalClassifications[tt].scheme === "timberStorage") tmpStorage = i.auction.auction.items[0].additionalClassifications[tt].description
                    }
                    let tmpStyle
                    if(i.chekbox === false){
                        tmpStyle = styles.pseudoCheckboxOn
                    }else{
                        tmpStyle = styles.pseudoCheckboxOff
                    }
                    let auctionUserName = null
                    if(i.auction !== null){
                        if(i.auction.auction !== null){
                            if(i.auction.auction.sellingEntity !== null){
                                if(i.auction.auction.sellingEntity.name !== null){
                                    auctionUserName = i.auction.auction.sellingEntity.name
                                }
                            }
                        }
                    }
                    return (
                        <div className={styles.auc_line}>
                        <div className={styles.chekbox}>
                            <div
                                className={tmpStyle}
                                onClick={
                                    () => {
                                        let tmp = props.togetherApiPage.bidsDraftWorkArr
                                        let tmpCheckbox = i.chekbox
                                        if(tmpCheckbox === false){
                                            tmpCheckbox = true
                                        }else{
                                            tmpCheckbox = false
                                        }
                                        tmp[count] = {auction: i.auction, chekbox: tmpCheckbox}
                                        props.togetherApiBidsWorkArr(tmp)
                                    }
                                }
                            ></div>
                        </div>
                        <div className={styles.seller}>{auctionUserName}</div>
                        <div className={styles.sortment}>{tmpSortment}</div>
                        <div className={styles.species}>{tmpSpecies}</div>
                        <div className={styles.class}>{tmpClass}</div>
                        <div className={styles.length}>{tmpLength}</div>
                        <div className={styles.storage}>{tmpStorage}</div>
                        <div className={styles.quantity}>{i.auction.auction.items[0].quantity}</div>
                        <div className={styles.baseCoast}>{i.auction.auction.value.amount}</div>
                        <div className={styles.baseCoast}>{i.auction.value.amount}</div>
                        <div className={styles.auctionStatus}>{viewTextStatusUkr(i.auction.auction.status)}</div>
                        <div className={styles.buttonDoc}>
                            <Button
                                className={styles.buttonElement}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.togetherApiPopUpOnOff({data: i.auction})
                                    }
                                }
                            >
                                Додати документ
                            </Button>
                        </div>
                        <div className={styles.bidStart}>
                            {viewButton(props, i)}
                        </div>
                        {statusButtonsDel(props, i.auction.auction.id, i.auction.id, i)}
                        <div className={styles.linkToAuction}>
                            <a href={window.location.origin + '/tenders/' + i.auction.auction.id}>
                                <IconButton aria-label="edit">
                                    <LinkIcon/>
                                </IconButton>
                            </a>
                        </div>
                    </div>
                    )
                }
            )
        }
    }else{
        tmp = <CreateAuctionLoader />
    }
    return <div>{tmp}</div>
}

function statusButtonsDel(props, auctionId, bidId, data){
    if(data.auction.auction.status === 'active_tendering'){
        return (
            <div className={styles.bidDel}>
                <div 
                    title="Видалити"
                    onClick={
                        () => {
                            deleteBid(props, auctionId, bidId)
                        }
                    }
                >
                    <IconButton
                        disabled
                        aria-label="delete"
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.bidDel}>
                <div title="Видалити">
                    <IconButton
                        disabled
                        aria-label="delete"
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
        )
    }
}

function viewButton(props, lot){
    let tmp
    if(lot.auction.needModeration === false && lot.auction.status !== 'active' && lot.auction.status !== 'cancelled'){
        tmp = <Button
            className={styles.buttonElement}
            variant="contained"
            color="primary"
            onClick={
                () => {
                    let tmp = lot.auction.value.amount / lot.auction.auction.value.amount
                    if(tmp < 10){
                        sendDraft(props, lot.auction.auction.id, lot.auction.id)
                        /////sendBid(props, lot.auction.auction.id, lot.auction.id)
                    }else{
                        rout10(props, lot.auction.auction.id, lot.auction.id, lot.auction.datePublished)
                    }
                }
            }
        >
            Активувати чернетку
        </Button>
    }else{
        tmp = <Button
            className={styles.buttonElement}
            variant="contained"
            color="disabled"

        >
            Активувати чернетку
        </Button>
    }
    return tmp
}

function rout10(props, tmpId, bidId, datePublished){
    let tmp = {}
    tmp.aucId = tmpId
    tmp.lotId = bidId
    tmp.datePublished = datePublished
    props.setDataAddress(tmp, 'SET_TOGETHER_API_HELP_POPUP')
}

function viewTextStatusUkr(status){
    if(status === 'active_rectification'){
        return 'Редагування доступне'
    }else if(status === 'active_tendering'){
        return 'Період подання пропозицій'
    }else if(status === 'active_auction'){
        return 'Аукціон'
    }else if(status === 'active_qualification'){
        return 'Кваліфікація'
    }else if(status === 'active_awarded'){
        return 'Очікується підписання договору'
    }else if(status === 'cancelled'){
        return 'Аукціон скасовано'
    }else if(status === 'unsuccessful'){
        return 'Торги не відбулися'
    }else if(status === 'complete'){
        return 'Аукціон завершено'
    }else if(status === null){
        return 'Чернетка'
    }else{
        return status
    }
}

async function massOffChekbox(props){
    let tmp = props.togetherApiPage.bidsDraftWorkArr
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            for(let i = 0; i < props.togetherApiPage.bidsDraftWorkArr.length; i++){
                if(props.togetherApiPage.bidsDraftWorkArr[i] === undefined) continue
                if(props.togetherApiPage.bidsDraftWorkArr[i].chekbox === true){
                    tmp[i] = {auction: props.togetherApiPage.bidsDraftWorkArr[i].auction, chekbox: false}
                }
            }
        }
    }
    props.togetherApiBidsWorkArr(tmp)
}

async function deleteBid(props, auction, bid){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auction}/bid/${bid}`;
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=25&status=active_tendering'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.togetherApiBidsWorkArr(null)
        props.setDataAddress(null, 'SET_MY_BIDS')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_ARR')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_WORK_ARR')
        await props.auctionsBidsGetMy(data, header)
        props.setDataAddress(null, 'SET_TOGETHER_API_HELP_POPUP')
    }
    await props.changeLoader(false)
}

async function massDeleteBid(props){
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            for(let i = 0; i < props.togetherApiPage.bidsDraftWorkArr.length; i++){
                if(props.togetherApiPage.bidsDraftWorkArr[i] === undefined) continue
                if(props.togetherApiPage.bidsDraftWorkArr[i].chekbox === true){
                    await deleteBidForMass(props, props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id, props.togetherApiPage.bidsDraftWorkArr[i].auction.id)
                }
            }
        }
    }
    await sendBidForMassGetBids(props)
}

async function deleteBidForMass(props, auction, bid){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auction}/bid/${bid}`;
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=25&status=active_tendering'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.togetherApiBidsWorkArr(null)
        props.setDataAddress(null, 'SET_MY_BIDS')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_ARR')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_WORK_ARR')
        await props.auctionsBidsGetMy(data, header)
    }
}

async function sendDraft(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/send_draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
        }else{
            await sendBid(props, tmpId, bidId)
        }
    }
}

async function sendBid(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/publish`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=25&status=active_tendering'
        }
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.togetherApiBidsWorkArr(null)
        props.setDataAddress(null, 'SET_MY_BIDS')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_ARR')
        props.setDataAddress(null, 'TOGETHER_API_BIDS_WORK_ARR')
        await props.auctionsBidsGetMy(data, header)
        props.setDataAddress(null, 'SET_TOGETHER_API_HELP_POPUP')
    }
}

async function massSendBid(props){
    if(props.togetherApiPage.bidsDraftWorkArr !== null){
        if(props.togetherApiPage.bidsDraftWorkArr.length > 0){
            for(let i = 0; i < props.togetherApiPage.bidsDraftWorkArr.length; i++){
                if(props.togetherApiPage.bidsDraftWorkArr[i] === undefined) continue
                if(props.togetherApiPage.bidsDraftWorkArr[i].chekbox === true){
                    await sendDraftMass(props, props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id, props.togetherApiPage.bidsDraftWorkArr[i].auction.id)
                    //await sendBidForMass(props, props.togetherApiPage.bidsDraftWorkArr[i].auction.auction.id, props.togetherApiPage.bidsDraftWorkArr[i].auction.id)
                }
            }
        }
    }
    await sendBidForMassGetBids(props)
}

async function sendDraftMass(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/send_draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
        }else{
            await sendBidForMass(props, tmpId, bidId)
        }
    }
}
async function sendBidForMass(props, tmpId, bidId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid/${bidId}/publish`;
    await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error);
}

async function sendBidForMassGetBids(props){
    props.changeLoader(true)
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=25&status=active_tendering'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    await props.auctionsBidsGetMy(data, header)
    await props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        auctions: state.start.auctionsGet,
        auctionsOld: state.start.auctionsGetOld,
        search: state.start.search,
        myBids: state.start.myBids,
        togetherApiPage: state.start.togetherApiPage,
        token: state.start.token,
        bidsDraftWorkArr: state.start.bidsDraftWorkArr,
    }
}

const mapDispatchToProps = {
    setPopUpBidTogetherAPI,
    togetherApiBidsArr,
    togetherApiBidsWorkArr,
    togetherApiPopUpOnOff,
    auctionsBidsGetMy,
    changeLoader,
    setUniversalError,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(TogetherAPI)