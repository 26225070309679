export const TranslationsUk = {
    "base": {
        "MultiLang": {
            "uk_UA": {
                "legalName": "Назва українською"
            },
            "en_US": {
                "legalName": "Назва англійською"
            }
        },
        "Period": {
            "startDate": {
                "legalName": "Дата та час початку"
            },
            "endDate": {
                "legalName": "Дата та час завершення"
            }
        },
        "LifeTime": {
            "dateFrom": {
                "legalName": "Період з"
            },
            "dateTill": {
                "legalName": "Період по"
            }
        },
        "Value": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "DutchStep": {
            "dutchStepQuantity": {
                "legalName": "Кількість кроків"
            },
            "dutchStepPercent": {
                "legalName": "Відсоток зниження, %"
            },
            "dutchStepValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку голландського раунду, грн"
            },
            "legalName": "Крок голландського раунду"
        },
        "DutchMinimalStep": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            },
            "legalName": "Розмір кроку аукціону"
        },
        "InfinityMinimalStep": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "legalName": "Розмір кроку аукціону"
        },
        "AddressIdentifier": {
            "scheme": {
                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних об’єктів України"
            },
            "id": {
                "legalName": "Код адміністративно-територіальних об’єктів України"
            }
        },
        "AddressIdentifierKATOTTG": {
            "scheme": {
                "legalName": "Кодифікатор адміністративно-територіальних одиниць та територій територіальних громад"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва адміністративно-територіальних одиниць та територій територіальних громад"
            },
            "id": {
                "legalName": "Код адміністративно-територіальних одиниць та територій територіальних громад"
            }
        },
        "Address": {
            "addressID": {
                "scheme": {
                    "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва адміністративно-територіальних об’єктів України"
                },
                "id": {
                    "legalName": "Код адміністративно-територіальних об’єктів України"
                },
                "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "countryName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Країна"
            },
            "region": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Область"
            },
            "locality": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Населений пункт"
            },
            "streetAddress": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Адреса"
            },
            "postalCode": {
                "legalName": "Поштовий індекс"
            }
        },
        "AddressUa": {
            "addressID": {
                "scheme": {
                    "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва адміністративно-територіальних об’єктів України"
                },
                "id": {
                    "legalName": "Код адміністративно-територіальних об’єктів України"
                },
                "legalName": "Код об’єктів адміністративно-територіального устрою України"
            },
            "countryName": {
                "en_US": {
                    "legalName": "Назва англійською"
                }
            },
            "region": {
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Область"
            },
            "locality": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Населений пункт"
            },
            "streetAddress": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Адреса"
            },
            "postalCode": {
                "legalName": "Поштовий індекс"
            }
        },
        "ContactPoint": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "ПІБ"
            },
            "email": {
                "legalName": "Адреса електронної пошти"
            },
            "telephone": {
                "legalName": "Номер телефону"
            },
            "faxNumber": {
                "legalName": "Номер факсу"
            },
            "url": {
                "legalName": "Веб адреса"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Unit": {
            "code": {
                "legalName": "Код одиниці виміру"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва одиниці виміру"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            }
        },
        "Identifier": {
            "scheme": {
                "legalName": "Ідентифікатори організації"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "SellingEntityIdentifier": {
            "scheme": {
                "legalName": "Тип ідентифікації Організатора аукціону"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "OrganizationWithoutContacts": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "SellingEntity": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Тип ідентифікації Організатора аукціону"
                },
                "legalName": "Ідентифікатор Організатора аукціону",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Організатора аукціону"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "Document": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "documentOf": {
                "legalName": "Об'єкт документу"
            },
            "documentType": {
                "legalName": "Тип документу"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "CancellationDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Question": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор запитання"
            },
            "author": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Автор запитання"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва запитання"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Текст запитання"
            },
            "datePublished": {
                "legalName": "Дата публікації запитання"
            },
            "dateModified": {
                "legalName": "Дата редагування запитання"
            },
            "dateAnswered": {
                "legalName": "Дата відповіді на запитання"
            },
            "answer": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Відповідь на запитання"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "RelatedEntity": {
            "_id": {
                "legalName": "Внутрішній ідентифікатор пов'язанного процесу"
            },
            "objectId": {
                "legalName": "Id ов'язанного об'єкту"
            }
        },
        "VerificationDocumentInfo": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документа"
            },
            "id": {
                "legalName": "Номер документа"
            },
            "date": {
                "legalName": "Дата видачі документа"
            }
        },
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "ExtendedAccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора або ідентифікатора одержувача"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountHolder": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування одержувача"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатор одержувача",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "legalName": "Реквізити одержувача"
        },
        "BankAccount": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "AwardWithoutBid": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            }
        },
        "InfiniteContract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "Discount": {
            "previousAuctionValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова вартість попереднього аукціону"
            },
            "discountPercent": {
                "legalName": "Розмір знижки від попереднього аукціону, %"
            },
            "discount": {
                "legalName": "Знижка"
            }
        },
        "Location": {
            "latitude": {
                "legalName": "Широта"
            },
            "longitude": {
                "legalName": "Довгота"
            },
            "elevation": {
                "legalName": "Висота"
            }
        },
        "CommonClientBid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "RegistrationDetails": {
            "registrationStatus": {
                "legalName": "Cтатус реєстрації"
            },
            "registrationID": {
                "legalName": "Номер запису"
            },
            "registrationDate": {
                "legalName": "Дата реєстрації"
            }
        },
        "RequiredRegistrationDetails": {
            "registrationStatus": {
                "legalName": "Стан державної реєстрації об''єкту"
            },
            "registrationID": {
                "legalName": "Номер запису"
            },
            "registrationDate": {
                "legalName": "Дата реєстрації"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            }
        },
        "RealEstateBaseProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "ManagingEntityIdentifier": {
            "scheme": {
                "legalName": "Тип ідентифікації юридичної особи"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва юридичної особи"
            },
            "id": {
                "legalName": "Ідентифікатор юридичної особи"
            }
        },
        "ManagingEntity": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування юридичної особи"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Тип ідентифікації юридичної особи"
                },
                "legalName": "Ідентифікатор юридичної особи",
                "id": {
                    "legalName": "Ідентифікатор юридичної особи"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження юридичної особи"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "Schedule": {
            "hoursBy": {
                "value": {
                    "legalName": "Годин"
                },
                "by": {
                    "legalName": "Період"
                }
            },
            "daysBy": {
                "value": {
                    "legalName": "Днів"
                },
                "by": {
                    "legalName": "Початок"
                }
            },
            "period": {
                "legalName": "Період дії графіку"
            },
            "other": {
                "legalName": "Інший графік використання"
            }
        },
        "LeaseRules": {
            "schedule": {
                "hoursBy": {
                    "value": {
                        "legalName": "Годин"
                    },
                    "by": {
                        "legalName": "Період"
                    }
                },
                "daysBy": {
                    "value": {
                        "legalName": "Днів"
                    },
                    "by": {
                        "legalName": "Початок"
                    }
                },
                "period": {
                    "legalName": "Період дії графіку"
                },
                "other": {
                    "legalName": "Інший графік використання"
                },
                "legalName": "Пропонований графік оренди"
            },
            "intendedUse": {
                "legalName": "Обмеження щодо використання майна (заборонені цільові призначення)"
            },
            "intendedUseRestrictionMethod": {
                "legalName": "Спосіб обмеження цільового призначення об'єкта"
            },
            "intendedUseRestrictionDescription": {
                "legalName": "Цільове призначення об'єкта оренди (за наявності)"
            },
            "additionalLeaseConditions": {
                "legalName": "Додаткові умови оренди майна"
            },
            "subleaseAllowed": {
                "legalName": "Можливість суборенди"
            }
        },
        "PauseDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Pause": {
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина зупинки Аукціону"
            },
            "datePublished": {
                "legalName": "Дата зупинки"
            },
            "dateResumption": {
                "legalName": "Дата відновлення"
            }
        },
        "Prolongation": {
            "id": {
                "legalName": "Ідентифікатор об'єкту пролонгації"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "reason": {
                "legalName": "Причина рішення згідно якого проводиться пролонгація"
            },
            "prolongationDate": {
                "legalName": "Дата набуття чинності рішення"
            },
            "prolongationTerm": {
                "legalName": "Строк пролонгації"
            },
            "documents": {
                "legalName": "Документи щодо пролонгації"
            }
        },
        "AdditionalInformation": {
            "id": {
                "legalName": "Ідентифікатор об'єкту додаткової інформації"
            },
            "owner": {
                "legalName": "Ідентифікатор власника об'єкта додаткової інформації"
            },
            "datePublished": {
                "legalName": "Дата публікації"
            },
            "dateModified": {
                "legalName": "Дата модифікації"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткової інформації"
            },
            "initiator": {
                "legalName": "Ініціатор публікації додаткової інформації"
            },
            "initiatorOther": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Ініціатор публікації"
            },
            "reason": {
                "legalName": "Причина публікації додаткової інформації"
            },
            "reasonOther": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина публікації"
            },
            "documents": {
                "legalName": "Документи додаткової інформації"
            }
        }
    },
    "basicSell": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "basicSellItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "AwardWithoutBid": {
            "id": {
                "legalName": "Ідентифікатор рішення про передачу права"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення про передачу права"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення про передачу права"
            },
            "status": {
                "legalName": "Статус рішення про передачу права"
            },
            "terminationReason": {
                "legalName": "Причина відмови набувачу права"
            },
            "datePublished": {
                "legalName": "Дата публікації рішення про передачу права"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "buyers": {
                "legalName": "Інформація про набувача права власності"
            },
            "documents": {
                "legalName": "Документи рішення про передачу права"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        }
    },
    "basicSell-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "basicSellEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "isPerishable": {
                "legalName": "Майно, що швидко псується"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "basicSell-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "BasicSellDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Інші відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "isPerishable": {
                "legalName": "Майно, що швидко псується"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "basicSell-withoutAuction": {
        "basicSellWithoutAuctionProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор процедури"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор процедури"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попередньої процедури"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва оголошення про передачу права"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис оголошення про передачу права"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Вартість лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи оголошення та пов'язані додатки"
            },
            "awards": {
                "legalName": "Рішення про передачу права"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування передачі права"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "dgf": {
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "dgfItemType": {
                "legalName": "Тип об'єкта"
            },
            "itemProps": {
                "legalName": "Технічні характеристики активу"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Cтатус реєстрації"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            }
        },
        "BankAccount": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            }
        },
        "Prolongation": {
            "id": {
                "legalName": "Ідентифікатор об'єкту пролонгації"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "reason": {
                "legalName": "Причина рішення згідно якого проводиться пролонгація"
            },
            "prolongationDate": {
                "legalName": "Дата набуття чинності рішення"
            },
            "prolongationTerm": {
                "legalName": "Строк пролонгації"
            },
            "documents": {
                "legalName": "Документи щодо пролонгації"
            },
            "decisionId": {
                "legalName": "Номер рішення згідно якого проводиться пролонгація"
            },
            "description": {
                "legalName": "Пояснення"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "datePaid": {
                "legalName": "Дата сплати"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        }
    },
    "dgf-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "DgfEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "eligibilityCriteria": {
                "legalName": "Умови участі фінансових установ"
            },
            "decision": {
                "decisionId": {
                    "legalName": "Номер рішення"
                },
                "decisionDate": {
                    "legalName": "Дата рішення"
                },
                "legalName": "Рішення ФГВФО про затвердження умов продажу"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccount": {
                "legalName": "Банківський рахунок"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "virtualDataRoom": {
                "legalName": "Посилання на VDR (Virtual Data Room)"
            },
            "dgfPublicAssetCertificate": {
                "legalName": "Посилання на публічний паспорт активу"
            },
            "x_dgfPlatformLegalDetails": {
                "legalName": "Юридична інформація майданчиків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "prolongations": {
                "legalName": "Інформація щодо пролонгації"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "unsuccessfulReason": {
                "legalName": "Причина, за якою аукціон не відбувся"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "dgf-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "DgfDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "eligibilityCriteria": {
                "legalName": "Умови участі фінансових установ"
            },
            "decision": {
                "decisionId": {
                    "legalName": "Номер рішення"
                },
                "decisionDate": {
                    "legalName": "Дата рішення"
                },
                "legalName": "Рішення ФГВФО про затвердження умов продажу"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccount": {
                "legalName": "Банківський рахунок"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "virtualDataRoom": {
                "legalName": "Посилання на VDR (Virtual Data Room)"
            },
            "dgfPublicAssetCertificate": {
                "legalName": "Посилання на публічний паспорт активу"
            },
            "x_dgfPlatformLegalDetails": {
                "legalName": "Юридична інформація майданчиків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "prolongations": {
                "legalName": "Інформація щодо пролонгації"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "unsuccessfulReason": {
                "legalName": "Причина, за якою аукціон не відбувся"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "legitimatePropertyLease": {
        "Value": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "CurrentTenant": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            },
            "id": {
                "legalName": "Ідентифікатор орендаря"
            },
            "currentContractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Дата укладення та закінчення договору оренди"
            },
            "legalName": "Дані про орендаря"
        },
        "RelatedOrganizations": {
            "propertyOwner": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування юридичної особи"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації юридичної особи"
                    },
                    "legalName": "Ідентифікатор юридичної особи",
                    "id": {
                        "legalName": "Ідентифікатор юридичної особи"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження юридичної особи"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Балансоутримувач"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування юридичної особи"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації юридичної особи"
                    },
                    "legalName": "Ідентифікатор юридичної особи",
                    "id": {
                        "legalName": "Ідентифікатор юридичної особи"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження юридичної особи"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Орендодавець"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            }
        },
        "CurrentTenantPriorityLease": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "id": {
                "legalName": "Ідентифікатор учасника з пріорітетним правом"
            },
            "currentContractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Дата укладення та закінчення договору оренди"
            },
            "priority": {
                "legalName": "Пріорітет учасника"
            },
            "legalName": "Дані про учасника з переважним правом"
        },
        "RelatedOrganizationsPriorityLease": {
            "propertyOwner": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування юридичної особи"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації юридичної особи"
                    },
                    "legalName": "Ідентифікатор юридичної особи",
                    "id": {
                        "legalName": "Ідентифікатор юридичної особи"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження юридичної особи"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Балансоутримувач"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування юридичної особи"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації юридичної особи"
                    },
                    "legalName": "Ідентифікатор юридичної особи",
                    "id": {
                        "legalName": "Ідентифікатор юридичної особи"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження юридичної особи"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Орендодавець"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            }
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "BankAccountsGroup": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "accountType": {
                "legalName": "Тип банківських рахунків"
            },
            "accounts": {
                "legalName": "Банківські рахунки"
            },
            "legalName": "Банківські рахунки"
        },
        "HolderBankAccountsGroup": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "accountType": {
                "legalName": "Тип банківських рахунків"
            },
            "accounts": {
                "legalName": "Банківські рахунки"
            },
            "holderType": {
                "legalName": "Тип власника об'єкта"
            },
            "legalName": "Банківські рахунки"
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Основний класифікатор об'єкта"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Координати"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "RealEstateItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                }
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Координати"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "itemType": {
                "legalName": "Тип об'єкта майна"
            },
            "reProps": {
                "totalBuildingArea": {
                    "legalName": "Загальна площа будівлі, кв.м."
                },
                "totalObjectArea": {
                    "legalName": "Загальна площа об''єкта в будівлі, кв.м."
                },
                "usableArea": {
                    "legalName": "Корисна площа об''єкта в будівлі, кв.м."
                },
                "constructionYear": {
                    "legalName": "Рік будівництва"
                },
                "constructionTechnology": {
                    "legalName": "Технологія будівництва"
                },
                "livingArea": {
                    "legalName": "Житлова площа, кв. м"
                },
                "kitchenArea": {
                    "legalName": "Площа кухні, кв. м"
                },
                "landArea": {
                    "legalName": "Площа ділянки, кв. м"
                },
                "locationInBuilding": {
                    "legalName": "Місце розташування об’єкта в будівлі"
                },
                "floors": {
                    "legalName": "Номер поверху або поверхів"
                },
                "generalCondition": {
                    "legalName": "Технічний стан об'єкта"
                },
                "serviceElectricity": {
                    "legalName": "Чи приєднаний об'єкт до електромережі"
                },
                "powerSupplyCapacity": {
                    "legalName": "Потужність електромережі, кВт"
                },
                "powerSupplyClass": {
                    "legalName": "Ступінь потужності електромережі"
                },
                "serviceWater": {
                    "legalName": "Водозабезпечення присутнє"
                },
                "serviceSewerage": {
                    "legalName": "Каналізація присутня"
                },
                "serviceGas": {
                    "legalName": "Газифікація присутня"
                },
                "serviceCentralHeating": {
                    "legalName": "Централізоване опалення присутнє"
                },
                "serviceAutonomousHeating": {
                    "legalName": "Автономне опалення присутнє"
                },
                "serviceHeatingCounter": {
                    "legalName": "Лічильник опалення присутній"
                },
                "serviceVentilation": {
                    "legalName": "Вентиляція присутня"
                },
                "serviceAirConditioning": {
                    "legalName": "Кондиціонування присутнє"
                },
                "servicePhone": {
                    "legalName": "Телефонізація присутня"
                },
                "serviceTV": {
                    "legalName": "Телебачення присутнє"
                },
                "serviceInternet": {
                    "legalName": "Інтернет присутній"
                },
                "serviceElevator": {
                    "legalName": "Ліфт присутній"
                },
                "serviceSecurityAlarm": {
                    "legalName": "Охоронна сигналізація присутня"
                },
                "serviceFireAlarm": {
                    "legalName": "Пожежна сигналізація присутня"
                },
                "servicesDescription": {
                    "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
                },
                "servicesAccounting": {
                    "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
                },
                "landTax": {
                    "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
                },
                "legalName": "Технічні характеристики, нерухомість"
            },
            "quantity": {
                "legalName": "Площа об'єкта, що здається в оренду"
            },
            "unit": {
                "code": {
                    "legalName": "Одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            }
        },
        "JointPropertyComplexItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                }
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Координати"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "itemType": {
                "legalName": "Тип об'єкта майна"
            },
            "jpcProps": {
                "quantityAndNomenclature": {
                    "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                },
                "workplacesQuantity": {
                    "legalName": "Кількість робочих місць, ociб"
                },
                "buildingsInformation": {
                    "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                },
                "landPlotInformation": {
                    "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                },
                "landProps": {
                    "landArea": {
                        "legalName": "Площа ділянки, га"
                    },
                    "cadastralNumber": {
                        "legalName": "Кадастровий номер"
                    },
                    "ownershipType": {
                        "legalName": "Тип власності"
                    },
                    "encumbrances": {
                        "legalName": "Інформація про наявні обтяження"
                    },
                    "jointOwnership": {
                        "legalName": "Інформація про співвласників"
                    },
                    "utilitiesAvailability": {
                        "legalName": "Інформація про інженерні мережі"
                    },
                    "hasEncumbrances": {
                        "legalName": "Присутні обтяження"
                    },
                    "hasJointOwnership": {
                        "legalName": "Наявні співвласники"
                    },
                    "hasUtilitiesAvailability": {
                        "legalName": "Інженерні мережі наявні"
                    },
                    "landIntendedUse": {
                        "legalName": "Поточне використання земельної ділянки"
                    },
                    "legalName": "Характеристики земельної ділянки"
                },
                "mainObligations": {
                    "legalName": "Основні зобов’язання (договірні та позадоговірні)"
                },
                "legalName": "Технічні характеристики, єдиний майновий комплекс"
            },
            "quantity": {
                "legalName": "Площа або кількість одиниць"
            },
            "unit": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            }
        },
        "VehicleItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                }
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Координати"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "itemType": {
                "legalName": "Тип об'єкта майна"
            },
            "vehicleProps": {
                "brand": {
                    "legalName": "Марка транспортного засобу"
                },
                "model": {
                    "legalName": "Модель транспортного засобу"
                },
                "productionYear": {
                    "legalName": "Рік випуску"
                },
                "engineCapacity": {
                    "legalName": "Об’єм двигуна, см³"
                },
                "fuelType": {
                    "legalName": "Тип палива"
                },
                "transmission": {
                    "legalName": "Тип коробки передач"
                },
                "color": {
                    "legalName": "Колір"
                },
                "kilometrage": {
                    "legalName": "Пробіг, км"
                },
                "identificationNumber": {
                    "legalName": "Номер кузова або шасі (VIN-код)"
                },
                "configuration": {
                    "legalName": "Комплектація"
                },
                "condition": {
                    "legalName": "Технічний стан"
                },
                "damagedDescription": {
                    "legalName": "Опис пошкоджень"
                },
                "damagePresence": {
                    "legalName": "Потреба у ремонті"
                },
                "legalName": "Технічні характеристики, транспортний засіб"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "unit": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            }
        },
        "OtherPropertyItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                }
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Координати"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "itemType": {
                "legalName": "Тип об'єкта майна"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            },
            "cancellationOf": {
                "legalName": "Об'єкт скасування"
            }
        }
    },
    "legitimatePropertyLease-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "legitimatePropertyLeaseEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "registryObjectId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "registryId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "martialLawDisclaimer": {
                "legalName": "Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "relatedOrganizations": {
                "propertyOwner": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Балансоутримувач"
                },
                "sellingEntity": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Орендодавець"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "legalName": "Організації, задіяні в керуванні об'єктом"
            },
            "leaseRules": {
                "schedule": {
                    "hoursBy": {
                        "value": {
                            "legalName": "Годин"
                        },
                        "by": {
                            "legalName": "Період"
                        }
                    },
                    "daysBy": {
                        "value": {
                            "legalName": "Днів"
                        },
                        "by": {
                            "legalName": "Початок"
                        }
                    },
                    "period": {
                        "legalName": "Період дії графіку"
                    },
                    "other": {
                        "legalName": "Інший графік використання"
                    },
                    "legalName": "Пропонований графік оренди"
                },
                "intendedUse": {
                    "legalName": "Обмеження щодо використання майна (заборонені цільові призначення)"
                },
                "intendedUseRestrictionMethod": {
                    "legalName": "Спосіб обмеження цільового призначення об'єкта"
                },
                "intendedUseRestrictionDescription": {
                    "legalName": "Цільове призначення об'єкта оренди (за наявності)"
                },
                "additionalLeaseConditions": {
                    "legalName": "Додаткові умови оренди майна"
                },
                "subleaseAllowed": {
                    "legalName": "Можливість суборенди"
                },
                "legalName": "Правила та умови передачі об'єкта в оренду"
            },
            "listType": {
                "legalName": "Тип переліку, до якого віднесено об'єкт"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова орендна плата"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "calcOnBookValue": {
                "legalName": "Стартова орендна плата розрахована від балансової вартості"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "legitimatePropertyLease-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "legitimatePropertyLeaseDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "registryObjectId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "registryId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "martialLawDisclaimer": {
                "legalName": "Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "relatedOrganizations": {
                "propertyOwner": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Балансоутримувач"
                },
                "sellingEntity": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Орендодавець"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "legalName": "Організації, задіяні в керуванні об'єктом"
            },
            "leaseRules": {
                "schedule": {
                    "hoursBy": {
                        "value": {
                            "legalName": "Годин"
                        },
                        "by": {
                            "legalName": "Період"
                        }
                    },
                    "daysBy": {
                        "value": {
                            "legalName": "Днів"
                        },
                        "by": {
                            "legalName": "Початок"
                        }
                    },
                    "period": {
                        "legalName": "Період дії графіку"
                    },
                    "other": {
                        "legalName": "Інший графік використання"
                    },
                    "legalName": "Пропонований графік оренди"
                },
                "intendedUse": {
                    "legalName": "Обмеження щодо використання майна (заборонені цільові призначення)"
                },
                "intendedUseRestrictionMethod": {
                    "legalName": "Спосіб обмеження цільового призначення об'єкта"
                },
                "intendedUseRestrictionDescription": {
                    "legalName": "Цільове призначення об'єкта оренди (за наявності)"
                },
                "additionalLeaseConditions": {
                    "legalName": "Додаткові умови оренди майна"
                },
                "subleaseAllowed": {
                    "legalName": "Можливість суборенди"
                },
                "legalName": "Правила та умови передачі об'єкта в оренду"
            },
            "listType": {
                "legalName": "Тип переліку, до якого віднесено об'єкт"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова орендна плата"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "calcOnBookValue": {
                "legalName": "Стартова орендна плата розрахована від балансової вартості"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "legitimatePropertyLease-priorityEnglish": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            },
            "priority": {
                "legalName": "Учасник"
            }
        },
        "legitimatePropertyLeasePriorityEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "registryObjectId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "registryId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "martialLawDisclaimer": {
                "legalName": "Строк оренди - 12 місяців після припинення чи скасування воєнного стану (виключно для оренди державного майна)"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "relatedOrganizations": {
                "propertyOwner": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Балансоутримувач"
                },
                "sellingEntity": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Найменування юридичної особи"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Тип ідентифікації юридичної особи"
                        },
                        "legalName": "Ідентифікатор юридичної особи",
                        "id": {
                            "legalName": "Ідентифікатор юридичної особи"
                        }
                    },
                    "address": {
                        "addressID": {
                            "scheme": {
                                "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                            },
                            "name": {
                                "uk_UA": {
                                    "legalName": "Назва українською"
                                },
                                "en_US": {
                                    "legalName": "Назва англійською"
                                },
                                "legalName": "Назва адміністративно-територіальних об’єктів України"
                            },
                            "id": {
                                "legalName": "Код адміністративно-територіальних об’єктів України"
                            },
                            "legalName": "Код об’єктів адміністративно-територіального устрою України"
                        },
                        "countryName": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            }
                        },
                        "region": {
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Область"
                        },
                        "locality": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Населений пункт"
                        },
                        "streetAddress": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Адреса"
                        },
                        "postalCode": {
                            "legalName": "Поштовий індекс"
                        },
                        "legalName": "Місцезнаходження юридичної особи"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Орендодавець"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "legalName": "Організації, задіяні в керуванні об'єктом"
            },
            "numberOfCurrentTenants": {
                "legalName": "Кількість учасників з переважним правом"
            },
            "leaseRules": {
                "schedule": {
                    "hoursBy": {
                        "value": {
                            "legalName": "Годин"
                        },
                        "by": {
                            "legalName": "Період"
                        }
                    },
                    "daysBy": {
                        "value": {
                            "legalName": "Днів"
                        },
                        "by": {
                            "legalName": "Початок"
                        }
                    },
                    "period": {
                        "legalName": "Період дії графіку"
                    },
                    "other": {
                        "legalName": "Інший графік використання"
                    },
                    "legalName": "Пропонований графік оренди"
                },
                "intendedUse": {
                    "legalName": "Обмеження щодо використання майна (заборонені цільові призначення)"
                },
                "intendedUseRestrictionMethod": {
                    "legalName": "Спосіб обмеження цільового призначення об'єкта"
                },
                "intendedUseRestrictionDescription": {
                    "legalName": "Цільове призначення об'єкта оренди (за наявності)"
                },
                "additionalLeaseConditions": {
                    "legalName": "Додаткові умови оренди майна"
                },
                "subleaseAllowed": {
                    "legalName": "Можливість суборенди"
                },
                "legalName": "Правила та умови передачі об'єкта в оренду"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова орендна плата"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "calcOnBookValue": {
                "legalName": "Стартова орендна плата розрахована від балансової вартості"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "currentTenantGuaranteeFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок чинного орендаря"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір мінімального кроку підвищення стартової орендної плати під час аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landRental": {
        "BidDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація про уповноважену на підписання особу"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Представник"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "hasEmphyteusisLease": {
                "legalName": "Право користування чужою земельною ділянкою"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            },
            "quantity": {
                "legalName": "Площа земельної ділянки"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місце розташування"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "itemProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "hasEmphyteusisLease": {
                    "legalName": "Право користування чужою земельною ділянкою"
                },
                "legalName": "Характеристики земельної ділянки"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "lotPaymentConfirmation": {
                "legalName": "Підтвердження сплати за лот"
            }
        }
    },
    "landRental-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "LandRentalEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону (аукціону з єдиним учасником)"
            },
            "previousAuctionBidder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "legalName": "Учасник з попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "leaseType": {
                "legalName": "Тип оренди"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обтяження прав на земельну ділянку, обмеження у використанні земель"
            },
            "conditions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Містобудівні умови земельної ділянки"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_platformLegalDetails": {
                "legalName": "Реквізити майданчиків"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "normativeMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Нормативна грошова оцінка"
            },
            "expertMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Експертна грошова оцінка"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час проведення аукціону"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landSell": {
        "BidDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація про уповноважену на підписання особу"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Представник"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "hasEmphyteusisLease": {
                "legalName": "Право користування чужою земельною ділянкою"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            },
            "quantity": {
                "legalName": "Площа земельної ділянки"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місце розташування"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "itemProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "hasEmphyteusisLease": {
                    "legalName": "Право користування чужою земельною ділянкою"
                },
                "legalName": "Характеристики земельної ділянки"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "lotPaymentConfirmation": {
                "legalName": "Підтвердження сплати за лот"
            }
        },
        "CurrentTenant": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "id": {
                "legalName": "Ідентифікатор учасника"
            },
            "currentContractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Дата початку та закінчення договору оренди"
            },
            "currentTenantValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Орендна ставка (сума на рік)"
            },
            "priority": {
                "legalName": "Учасник"
            },
            "legalName": "Дані про учасника з переважним правом"
        }
    },
    "landSell-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "LandSellEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону (аукціону з єдиним учасником)"
            },
            "previousAuctionBidder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "legalName": "Учасник з попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "saleType": {
                "legalName": "Тип продажу"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обтяження прав на земельну ділянку, обмеження у використанні земель"
            },
            "conditions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Містобудівні умови земельної ділянки"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_platformLegalDetails": {
                "legalName": "Реквізити майданчиків"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "normativeMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Нормативна грошова оцінка"
            },
            "expertMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Експертна грошова оцінка"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час проведення аукціону"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landSell-priorityEnglish": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            },
            "priority": {
                "legalName": "Учасник"
            },
            "dateActivated": {
                "legalName": "Дата активації заяви на участь"
            }
        },
        "LandSellPriorityEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону (аукціону з єдиним учасником)"
            },
            "previousAuctionBidder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "legalName": "Учасник з попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "currentTenants": {
                "legalName": "Дані про учасників з переважним правом"
            },
            "numberOfCurrentTenants": {
                "legalName": "Кількість учасників з переважним правом"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "saleType": {
                "legalName": "Тип продажу"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обтяження прав на земельну ділянку, обмеження у використанні земель"
            },
            "conditions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Містобудівні умови земельної ділянки"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_platformLegalDetails": {
                "legalName": "Реквізити майданчиків"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "normativeMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Нормативна грошова оцінка"
            },
            "expertMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Експертна грошова оцінка"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "transferPriorityPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період передачі переважного права"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час проведення аукціону"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "railwayCargo": {
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "Unit": {
            "code": {
                "legalName": "Код одиниці виміру"
            }
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                }
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                }
            },
            "quantity": {
                "legalName": "Кількість вагонів"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "routeQuantity": {
                "legalName": "Кількість маршрутів"
            },
            "routesAccounting": {
                "wagonsPerRouteQuantity": {
                    "legalName": "Кількість вагонів по кожному маршруту"
                },
                "routeNumber": {
                    "legalName": "Порядковий номер маршруту"
                },
                "legalName": "Облік маршрутів"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати розташування"
            },
            "requestLifeTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Період проставлення електронної заявки"
            },
            "loadLifeTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Період подачі вагону"
            },
            "wagonTypes": {
                "legalName": "Перелік кодів типів вагонів"
            },
            "wagonSpecies": {
                "legalName": "Код роду вагонів"
            },
            "loadingRestriction": {
                "legalName": "Полігон навантаження"
            },
            "auctionRestriction": {
                "legalName": "Обмеження полігону навантаження/ вивантаження"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "SellingEntity": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Тип ідентифікації Організатора аукціону"
                },
                "legalName": "Ідентифікатор Організатора аукціону",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Організатора аукціону"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        }
    },
    "railwayCargo-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "RailwayCargoEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "carryType": {
                "legalName": "Тип аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "railwayCargo-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "RailwayCargoDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "carryType": {
                "legalName": "Тип аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "renewables": {
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "Value": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            }
        },
        "Unit": {
            "code": {
                "legalName": "Код одиниці виміру"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва одиниці виміру"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "RenewablesProps": {
            "regions": {
                "legalName": "Області, в яких розподіляється обсяг лота"
            },
            "techParams": {
                "legalName": "Технічні параметри установок зберігання енергії, які можуть бути встановлені на об’єкті"
            },
            "timeSlots": {
                "legalName": "Денні часові інтервали, протягом яких учасник може набути право на підтримку"
            },
            "loadProfiles": {
                "legalName": "Профілі навантаження об’єкта електроенергетики"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Розмір частки річної квоти"
            },
            "itemProps": {
                "regions": {
                    "legalName": "Області, в яких розподіляється обсяг лота"
                },
                "techParams": {
                    "legalName": "Технічні параметри установок зберігання енергії, які можуть бути встановлені на об’єкті"
                },
                "timeSlots": {
                    "legalName": "Денні часові інтервали, протягом яких учасник може набути право на підтримку"
                },
                "loadProfiles": {
                    "legalName": "Профілі навантаження об’єкта електроенергетики"
                },
                "legalName": "Специфічні поля для об'єкта електроенергетики"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації"
            },
            "datePublished": {
                "legalName": "Дата створення"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція за 1 кВт⋅год"
            },
            "buyers": {
                "legalName": "Дані учасника"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу та договору"
            },
            "admissionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період прийняття рішення щодо набуття статусу переможця"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція за 1 кВт⋅год"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Розмір частки квоти в заяві"
            },
            "initialValueAmount": {
                "legalName": "Початкова ціна"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість в договорі за 1 кВт⋅год"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            }
        },
        "SellingEntity": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування Замовника аукціону"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Тип ідентифікації Організатора аукціону"
                },
                "legalName": "Ідентифікатори Замовника аукціону",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Замовника аукціону"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            },
            "x_verificationDocuments": {
                "legalName": "Ліцензія"
            }
        }
    },
    "renewables-multiAwards": {
        "RenewablesMultiAwardsProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування Замовника аукціону"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатори Замовника аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Замовника аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "x_verificationDocuments": {
                    "legalName": "Ліцензія"
                },
                "legalName": "Інформація про замовника аукціону"
            },
            "lotId": {
                "legalName": "Номер лоту"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Заголовок аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_quantityLimit": {
                "legalName": "80% сукупної величини потужності учасників"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Максимальна цінова пропозиція за 1 кВт⋅год"
            },
            "bankGuaranteeDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Інформація щодо банківської гарантії"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв учасників"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Запитання до аукціону"
            },
            "awards": {
                "legalName": "Обʼєкт кваліфікації"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період прийняття заяв на участь"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період верифікації документів"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "subsoil": {
        "Classification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Класифікатор об'єкта"
            }
        },
        "Unit": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва одиниці виміру"
            }
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Класифікатор об'єкта"
                }
            },
            "unit": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор об'єкта"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період сплати до бюджету"
            },
            "admissionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період виконання умов договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов'язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "datePaid": {
                "legalName": "Дата сплати до державного бюджету України"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "RegistrationFee": {
            "currency": {
                "legalName": "Валюта"
            }
        }
    },
    "subsoil-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "SubsoilEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "legalName": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Повна юридична назва організації"
                    },
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "geologicDataValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Вартість геологічної інформації;"
            },
            "documentationValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Вартість пакета аукціонної документації, що сплачується переможцем;"
            },
            "leaseDuration": {
                "legalName": "Строк, на який надається дозвіл"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Вимоги до оформлення документів"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "subsoil-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "SubsoilDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "legalName": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Повна юридична назва організації"
                    },
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "geologicDataValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість геологічної інформації;"
            },
            "documentationValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість пакета аукціонної документації, що сплачується переможцем;"
            },
            "leaseDuration": {
                "legalName": "Строк, на який надається дозвіл"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Вимоги до оформлення документів"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "timber": {
        "Unit": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва одиниці виміру"
            }
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "Identifier": {
            "scheme": {
                "legalName": "Ідентифікатори організації"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "PrepaymentDetails": {
            "date": {
                "legalName": "Дата передоплати"
            },
            "prepayment": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір передоплати"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            },
            "x_verificationDocuments": {
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис документа"
                },
                "id": {
                    "legalName": "Номер документа"
                },
                "date": {
                    "legalName": "Дата видачі документа"
                },
                "legalName": "Реквізити документу, що підтверджує повноваження"
            }
        },
        "BankAccount": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            }
        }
    },
    "timber-english": {
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Розмір партії деревини"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "unitValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Стартова ціна за 1 м.куб"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата розміщення заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "x_prepaymentDetails": {
                "date": {
                    "legalName": "Дата передоплати"
                },
                "prepayment": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Розмір передоплати"
                },
                "legalName": "Інформація про передоплату"
            }
        },
        "TimberEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccount": {
                "legalName": "Банківський рахунок"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "timber-multiAwards": {
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Розмір партії деревини"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "quantity": {
                "legalName": "Розмір партії деревини"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "x_prepaymentDetails": {
                "date": {
                    "legalName": "Дата передоплати"
                },
                "prepayment": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Розмір передоплати"
                },
                "legalName": "Інформація про передоплату"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата розміщення заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція за 1 м.куб"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "quantity": {
                "legalName": "Розмір партії деревини"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "TimberMultiAwardsProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccount": {
                "legalName": "Банківський рахунок"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "minQuantity": {
                "legalName": "Мінімальний розмір партії деревини"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "arrestedAssets": {
        "BidDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Custodian": {
            "title": {
                "legalName": "Дані зберігача"
            },
            "type": {
                "legalName": "Тип зберігача"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "Pause": {
            "reason": {
                "legalName": "Причина зупинки Аукціону"
            },
            "datePublished": {
                "legalName": "Дата зупинки"
            },
            "dateResumption": {
                "legalName": "Дата відновлення"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "category": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Категорія"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "isPaymentComplete": {
                "legalName": "Вартість лоту сплачена Виконавцю"
            },
            "isPaymentRenumerationComplete": {
                "legalName": "Винагорода сплачена Оператору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період отримання інформації щодо сплату вартості лота Виконавцю та щодо сплати винагороди Оператору"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        }
    },
    "arrestedAssets-english": {
        "ArrestedAssetsEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "resumptionReason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Підстава для відновлення торгів"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "department": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Інформація про підрозділ"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "custodian": {
                "title": {
                    "legalName": "Дані зберігача"
                },
                "type": {
                    "legalName": "Тип зберігача"
                },
                "legalName": "Дані зберігача"
            },
            "executorDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Дані Виконавця"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "fixedPrice": {
                "legalName": "Торги за фіксованою ціною"
            },
            "isPerishable": {
                "legalName": "Майно, що швидко псується"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "ДПеріод аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "previousStatus": {
                "legalName": "Статус процедури до зупинення"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "pauses": {
                "legalName": "Зупинка Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "alienation": {
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "alienationItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        }
    },
    "alienation-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "alienationEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "commercialSell": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора або ідентифікатора одержувача"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "commercialSellItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        }
    },
    "commercialSell-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "commercialSellEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "commercialSell-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "commercialSellDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Інші відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "commercialSell-infinity": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "commercialSellInfinityProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Інші відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "bankRuptcy": {
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Організатора аукціону"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "DebtorInformation": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження боржника"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "OrganizationDetailedRepresentativeInfo": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Організатора аукціону"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            },
            "representativeCertificateNumber": {
                "legalName": "№ свідоцтва про право на провадження діяльності арбітражного керуючого"
            },
            "representativeCertificateDate": {
                "legalName": "Дата видачі про право на провадження діяльності арбітражного керуючого"
            }
        },
        "BankAccountsByType": {
            "accountType": {
                "legalName": "Тип банківського рахунку"
            },
            "legalName": "Банківські рахунки"
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Матеріали стін"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху будинку"
            },
            "notLivingArea": {
                "legalName": "Не житлова площа, кв. м"
            },
            "numberOfRooms": {
                "legalName": "Кількість кімнат або приміщень"
            },
            "numberOfStoreys": {
                "legalName": "Поверховість будинку"
            },
            "utilityRoomsDescription": {
                "legalName": "Інформація про допоміжні, підсобні приміщення та споруди"
            },
            "realEstateTenantsInfo": {
                "legalName": "Інформація про наявність інших зареєстрованих, крім боржника, мешканців (зокрема дітей), орендарів та інших користувачів приміщення"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Наявність на земельній ділянці комунікацій"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            },
            "specialLandPurpose": {
                "legalName": "Цільове призначення земельної ділянки"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "bankRuptcyItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Додаткові характеристики"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "limitedCirculationProperty": {
                "legalName": "Обмежене в обороті майно"
            },
            "limitedCirculationPropertyDescription": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обмеження для майна з обмеженим оборотом"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "isPartiallyPaid": {
                "legalName": "Чи внесена часткова оплата?"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "brokerPaymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати повної винагороди оператору"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            }
        },
        "AwardWithoutBid": {
            "id": {
                "legalName": "Ідентифікатор рішення щодо продажу майна зебезпеченому кредитору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо продажу майна зебезпеченому кредитору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо продажу майна зебезпеченому кредитору"
            },
            "status": {
                "legalName": "Статус рішення щодо продажу майна зебезпеченому кредитору"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата публікації рішення щодо продажу майна зебезпеченому кредитору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про забезпеченого кредитора"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу про продаж майна"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            }
        },
        "ContractWithoutAuction": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Інформація про забезпеченого кредитора"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            }
        }
    },
    "bankRuptcy-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "bankRuptcyEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingMethodType": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Підтип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "representativeCertificateNumber": {
                    "legalName": "№ свідоцтва про право на провадження діяльності арбітражного керуючого"
                },
                "representativeCertificateDate": {
                    "legalName": "Дата видачі про право на провадження діяльності арбітражного керуючого"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "bankruptcyCaseNumber": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "№ справи про банкрутство"
            },
            "economicCourtName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування господарського суду"
            },
            "debtorInformation": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження боржника"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про боржника"
            },
            "isTaxInvoiceProvided": {
                "legalName": "Податкова накладна надається?"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обмеження"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Акт/договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "bankRuptcy-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "bankRuptcyDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingMethodType": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Підтип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "representativeCertificateNumber": {
                    "legalName": "№ свідоцтва про право на провадження діяльності арбітражного керуючого"
                },
                "representativeCertificateDate": {
                    "legalName": "Дата видачі про право на провадження діяльності арбітражного керуючого"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "bankruptcyCaseNumber": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "№ справи про банкрутство"
            },
            "economicCourtName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування господарського суду"
            },
            "debtorInformation": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження боржника"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про боржника"
            },
            "isTaxInvoiceProvided": {
                "legalName": "Податкова накладна надається?"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обмеження"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Акт/договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "bankRuptcy-withoutAuction": {
        "bankRuptcyWithoutAuctionProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор процедури2"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "representativeCertificateNumber": {
                    "legalName": "№ свідоцтва про право на провадження діяльності арбітражного керуючого"
                },
                "representativeCertificateDate": {
                    "legalName": "Дата видачі про право на провадження діяльності арбітражного керуючого"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "bankruptcyCaseNumber": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "№ справи про банкрутство"
            },
            "economicCourtName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Найменування господарського суду"
            },
            "debtorInformation": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження боржника"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про боржника"
            },
            "isTaxInvoiceProvided": {
                "legalName": "Податкова накладна надається?"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обмеження"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Акт/договір"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "regulationsPropertyLease": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "accounts": {
                "legalName": "Банківські рахунки"
            }
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Основний класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "regulationsPropertyLeaseItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            },
            "cancellationOf": {
                "legalName": "Об'єкт скасування"
            }
        }
    },
    "regulationsPropertyLease-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Розмір закритої цінової пропозиції"
            }
        },
        "regulationsPropertyLeaseEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "schedule": {
                "hoursBy": {
                    "value": {
                        "legalName": "Годин"
                    },
                    "by": {
                        "legalName": "Період"
                    }
                },
                "daysBy": {
                    "value": {
                        "legalName": "Днів"
                    },
                    "by": {
                        "legalName": "Початок"
                    }
                },
                "period": {
                    "legalName": "Період дії графіку"
                },
                "other": {
                    "legalName": "Інший графік використання"
                },
                "legalName": "Пропонований графік оренди"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "regulationsPropertyLease-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "regulationsPropertyLeaseDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "schedule": {
                "hoursBy": {
                    "value": {
                        "legalName": "Годин"
                    },
                    "by": {
                        "legalName": "Період"
                    }
                },
                "daysBy": {
                    "value": {
                        "legalName": "Днів"
                    },
                    "by": {
                        "legalName": "Початок"
                    }
                },
                "period": {
                    "legalName": "Період дії графіку"
                },
                "other": {
                    "legalName": "Інший графік використання"
                },
                "legalName": "Пропонований графік оренди"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Мінімальний крок"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "valuePeriod": {
                "legalName": "Стартова вартість за"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "commercialPropertyLease": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "accounts": {
                "legalName": "Банківські рахунки"
            }
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Матеріали стін"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            },
            "notLivingArea": {
                "legalName": "Не житлова площа, кв. м"
            },
            "numberOfRooms": {
                "legalName": "Кількість кімнат або приміщень"
            },
            "numberOfStoreys": {
                "legalName": "Поверховість будинку"
            },
            "utilityRoomsDescription": {
                "legalName": "Інформація про допоміжні, підсобні приміщення та споруди"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість та склад робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Основний класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "commercialPropertyLeaseItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            },
            "cancellationOf": {
                "legalName": "Об'єкт скасування"
            }
        }
    },
    "commercialPropertyLease-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Розмір закритої цінової пропозиції"
            }
        },
        "commercialPropertyLeaseEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "schedule": {
                "hoursBy": {
                    "value": {
                        "legalName": "Годин"
                    },
                    "by": {
                        "legalName": "Період"
                    }
                },
                "daysBy": {
                    "value": {
                        "legalName": "Днів"
                    },
                    "by": {
                        "legalName": "Початок"
                    }
                },
                "period": {
                    "legalName": "Період дії графіку"
                },
                "other": {
                    "legalName": "Інший графік використання"
                },
                "legalName": "Пропонований графік оренди"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "commercialPropertyLease-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "commercialPropertyLeaseDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "schedule": {
                "hoursBy": {
                    "value": {
                        "legalName": "Годин"
                    },
                    "by": {
                        "legalName": "Період"
                    }
                },
                "daysBy": {
                    "value": {
                        "legalName": "Днів"
                    },
                    "by": {
                        "legalName": "Початок"
                    }
                },
                "period": {
                    "legalName": "Період дії графіку"
                },
                "other": {
                    "legalName": "Інший графік використання"
                },
                "legalName": "Пропонований графік оренди"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "valuePeriod": {
                "legalName": "Період розрахунку стартової вартості"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "simpleSell": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру об'єкта"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "simpleSellItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            }
        }
    },
    "simpleSell-withoutAuction": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "simpleSellWithoutAuctionProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "nonperformingLoans": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "SellingEntity": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Тип ідентифікації Організатора аукціону"
                },
                "legalName": "Ідентифікатор Організатора аукціону",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Місцезнаходження Організатора аукціону"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            },
            "legalName": "Інформація про Організатора аукціону"
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Залишок заборгованості за кредитними договорами"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "nonperformingLoansItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "overdueDuration": {
                "legalName": "Термін прострочення платежу"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати протоколу"
            }
        },
        "Prolongation": {
            "id": {
                "legalName": "Ідентифікатор об'єкту пролонгації"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "reason": {
                "legalName": "Причина рішення згідно якого проводиться пролонгація"
            },
            "prolongationDate": {
                "legalName": "Дата набуття чинності рішення"
            },
            "prolongationTerm": {
                "legalName": "Строк пролонгації"
            },
            "documents": {
                "legalName": "Документи щодо пролонгації"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "datePaid": {
                "legalName": "Дата сплати"
            }
        }
    },
    "nonperformingLoans-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "nonperformingLoansEnglishProcedure": {
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижка"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "prolongations": {
                "legalName": "Інформація щодо пролонгації"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "waitingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період очікування"
            },
            "waitingPeriodUpdated": {
                "legalName": "Ідентифікатор зміни періоду очікування"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "nonperformingLoans-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "nonperformingLoansDutchProcedure": {
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "discount": {
                "previousAuctionValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова вартість попереднього аукціону"
                },
                "discountPercent": {
                    "legalName": "Розмір знижки від попереднього аукціону, %"
                },
                "discount": {
                    "legalName": "Знижка"
                },
                "legalName": "Знижки"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "prolongations": {
                "legalName": "Інформація щодо пролонгації"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "smallPrivatization": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатора"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "AssetHolderIdentifier": {
            "scheme": {
                "legalName": "Ідентифікатори організації"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "AssetHolder": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Балансоутримувача"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "RequiredRegistrationDetails": {
            "registrationStatus": {
                "legalName": "Стан державної реєстрації об''єкту"
            },
            "registrationID": {
                "legalName": "Номер запису"
            },
            "registrationDate": {
                "legalName": "Дата реєстрації"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "smallPrivatizationItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "participationPayment": {
                "legalName": "Оплата за участь"
            },
            "signaturePeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період публікації договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "PreliminaryContract": {
            "contractNumber": {
                "legalName": "Номер попереднього договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва попереднього договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис попереднього договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання попереднього договору"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк виконання умов приватизації"
            },
            "preliminaryContract": {
                "contractNumber": {
                    "legalName": "Номер попереднього договору"
                },
                "title": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва попереднього договору"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис попереднього договору"
                },
                "dateSigned": {
                    "legalName": "Дата підписання попереднього договору"
                },
                "legalName": "Попередній договір"
            },
            "preliminaryContractSigned": {
                "legalName": "Попередній договір підписано"
            },
            "contractTimeAdded": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        }
    },
    "smallPrivatization-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "smallPrivatizationEnglishProcedure": {
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "ownershipType": {
                "legalName": "Тип власності об’єкта малої приватизації"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "saleCondition": {
                "legalName": "Наявність умов продажу"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "clarificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період уточнень"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "admissionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період публікації рішення про викуп"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "assetHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Балансоутримувача"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про балансоутримувача"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "smallPrivatization-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            }
        },
        "smallPrivatizationDutchProcedure": {
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "ownershipType": {
                "legalName": "Тип власності об’єкта малої приватизації"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "saleCondition": {
                "legalName": "Наявність умов продажу"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "clarificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період уточнень"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "assetHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Балансоутримувача"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про балансоутримувача"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "largePrivatization": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатора"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "AssetHolderIdentifier": {
            "scheme": {
                "legalName": "Ідентифікатори організації"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "AssetHolder": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження Балансоутримувача"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "RequiredRegistrationDetails": {
            "registrationStatus": {
                "legalName": "Стан державної реєстрації об''єкту"
            },
            "registrationID": {
                "legalName": "Номер запису"
            },
            "registrationDate": {
                "legalName": "Дата реєстрації"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "largePrivatizationItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "participationPayment": {
                "legalName": "Оплата за участь"
            },
            "signaturePeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період публікації договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "PreliminaryContract": {
            "contractNumber": {
                "legalName": "Номер попереднього договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва попереднього договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис попереднього договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання попереднього договору"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк виконання умов приватизації"
            },
            "preliminaryContract": {
                "contractNumber": {
                    "legalName": "Номер попереднього договору"
                },
                "title": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва попереднього договору"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис попереднього договору"
                },
                "dateSigned": {
                    "legalName": "Дата підписання попереднього договору"
                },
                "legalName": "Попередній договір"
            },
            "preliminaryContractSigned": {
                "legalName": "Попередній договір підписано"
            },
            "contractTimeAdded": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        }
    },
    "largePrivatization-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                }
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "largePrivatizationEnglishProcedure": {
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікації Організатора аукціону"
                    },
                    "legalName": "Ідентифікатор Організатора аукціону",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "ownershipType": {
                "legalName": "Тип власності об’єкта великої приватизації"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "saleCondition": {
                "legalName": "Наявність умов продажу"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "clarificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період уточнень"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "admissionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період публікації рішення про викуп"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "assetHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "addressID": {
                        "scheme": {
                            "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                        },
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "Назва адміністративно-територіальних об’єктів України"
                        },
                        "id": {
                            "legalName": "Код адміністративно-територіальних об’єктів України"
                        },
                        "legalName": "Код об’єктів адміністративно-територіального устрою України"
                    },
                    "countryName": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        }
                    },
                    "region": {
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Область"
                    },
                    "locality": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Населений пункт"
                    },
                    "streetAddress": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Адреса"
                    },
                    "postalCode": {
                        "legalName": "Поштовий індекс"
                    },
                    "legalName": "Місцезнаходження Балансоутримувача"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про балансоутримувача"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landArrested": {
        "BidDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Arrest": {
            "statementID": {
                "legalName": "Номер виконавчого провадження"
            },
            "restrictionDate": {
                "legalName": "Дата виконавчого провадження"
            },
            "restrictionOrganization": {
                "legalName": "Орган що видав"
            },
            "isRemovable": {
                "legalName": "Арешт може бути знятий після продажу виконавцем"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "Identifier": {
            "scheme": {
                "legalName": "Тип ідентифікатора"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація про уповноважену на підписання особу"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Представник"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "hasEmphyteusisLease": {
                "legalName": "Право користування чужою земельною ділянкою"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                }
            },
            "quantity": {
                "legalName": "Площа земельної ділянки"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місце розташування"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "itemProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "hasEmphyteusisLease": {
                    "legalName": "Право користування чужою земельною ділянкою"
                },
                "legalName": "Характеристики земельної ділянки"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            },
            "PREpaymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період сплати забезпечувального платежу"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер акту/договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва акту/договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис акту/договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість акту/договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість акту/договору з урахуванням податків"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус акту/договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання акту/договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта акту/договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування акту/договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з актом/договором"
            },
            "lotPaymentConfirmation": {
                "legalName": "Підтвердження сплати за лот"
            },
            "securityPaymentConfirmation": {
                "legalName": "Підтвердження сплати забезпечувального платежу"
            }
        },
        "PriorityTransferInfo": {
            "transferredFrom": {
                "legalName": "Право передано від"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва організації"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            }
        },
        "CurrentTenant": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації або особи",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "currentContractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Дата початку та закінчення договору оренди"
            },
            "currentTenantValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Орендна ставка (сума на рік)"
            },
            "id": {
                "legalName": "Ідентифікатор учасника"
            },
            "priority": {
                "legalName": "Учасник"
            },
            "priorityTransferInfo": {
                "transferredFrom": {
                    "legalName": "Право передано від"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва організації"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Інформація про передачу переважного права"
            }
        },
        "AwardWithoutBid": {
            "id": {
                "legalName": "Ідентифікатор рішення про продаж майна"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення про продаж майна"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення про продаж майна"
            },
            "status": {
                "legalName": "Статус рішення про продаж майна"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата публікації рішення про продаж майна"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про покупця"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            }
        }
    },
    "landArrested-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            }
        },
        "LandArrestedEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "nonRemovable": {
                "legalName": "Наявність арештів, що не можуть бути зняті виконавцем після продажу"
            },
            "arrests": {
                "legalName": "Інформація про арешти"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону (аукціону з єдиним учасником)"
            },
            "previousAuctionBidder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "legalName": "Учасник з попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікатора"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Ідентифікатор"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "saleType": {
                "legalName": "Тип продажу"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обтяження прав на земельну ділянку, обмеження у використанні земель"
            },
            "conditions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Містобудівні умови земельної ділянки"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_platformLegalDetails": {
                "legalName": "Реквізити майданчиків"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "normativeMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Нормативна грошова оцінка"
            },
            "expertMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Експертна грошова оцінка"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Акт/Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час проведення аукціону"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landArrested-priorityEnglish": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "initialValueAmount": {
                "legalName": "Початкова сума"
            },
            "priority": {
                "legalName": "Учасник"
            },
            "dateActivated": {
                "legalName": "Дата активації заяви на участь"
            }
        },
        "LandArrestedPriorityEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "nonRemovable": {
                "legalName": "Наявність арештів, що не можуть бути зняті виконавцем після продажу"
            },
            "arrests": {
                "legalName": "Інформація про арешти"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Номер попереднього аукціону (аукціону з єдиним учасником)"
            },
            "previousAuctionBidder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "legalName": "Учасник з попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікатора"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Ідентифікатор"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "currentTenants": {
                "legalName": "Дані про учасників з переважним правом"
            },
            "numberOfCurrentTenants": {
                "legalName": "Кількість учасників з переважним правом"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "saleType": {
                "legalName": "Тип продажу"
            },
            "restrictions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Обтяження прав на земельну ділянку, обмеження у використанні земель"
            },
            "conditions": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Містобудівні умови земельної ділянки"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок та можливий час ознайомлення з лотом"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_platformLegalDetails": {
                "legalName": "Реквізити майданчиків"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "normativeMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Нормативна грошова оцінка"
            },
            "expertMonetaryValuation": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Експертна грошова оцінка"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "legalName": "Мінімальний крок"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість учасників"
            },
            "tenderAttempts": {
                "legalName": "Порядковий номер аукціону"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Акт/Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "transferPriorityPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період передачі переважного права"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час проведення аукціону"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "landArrested-withoutAuction": {
        "landArrestedWithoutAuctionProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор процедури"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "previousAuctionProtocolUrl": {
                "legalName": "Веб-адреса для перегляду протоколу попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "nonRemovable": {
                "legalName": "Наявність арештів, що не можуть бути зняті виконавцем після продажу"
            },
            "arrests": {
                "legalName": "Інформація про арешти"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Тип ідентифікатора"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Ідентифікатор"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження Організатора аукціону"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "saleType": {
                "legalName": "Тип продажу"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "armaProperty": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccountWithCurrency": {
            "id": {
                "legalName": "Ідентифікатор банківського рахунку"
            },
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountHolder": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Найменування одержувача"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатор одержувача",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "legalName": "Реквізити одержувача"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            },
            "currency": {
                "legalName": "Валюта"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "LandProps": {
            "landArea": {
                "legalName": "Площа ділянки, га"
            },
            "cadastralNumber": {
                "legalName": "Кадастровий номер"
            },
            "ownershipType": {
                "legalName": "Тип власності"
            },
            "encumbrances": {
                "legalName": "Інформація про наявні обтяження"
            },
            "jointOwnership": {
                "legalName": "Інформація про співвласників"
            },
            "utilitiesAvailability": {
                "legalName": "Інформація про інженерні мережі"
            },
            "hasEncumbrances": {
                "legalName": "Присутні обтяження"
            },
            "hasJointOwnership": {
                "legalName": "Наявні співвласники"
            },
            "hasUtilitiesAvailability": {
                "legalName": "Інженерні мережі наявні"
            },
            "landIntendedUse": {
                "legalName": "Поточне використання земельної ділянки"
            }
        },
        "RealEstateProps": {
            "totalBuildingArea": {
                "legalName": "Загальна площа будівлі, кв.м."
            },
            "totalObjectArea": {
                "legalName": "Загальна площа об''єкта в будівлі, кв.м."
            },
            "usableArea": {
                "legalName": "Корисна площа об''єкта в будівлі, кв.м."
            },
            "constructionYear": {
                "legalName": "Рік будівництва"
            },
            "constructionTechnology": {
                "legalName": "Технологія будівництва"
            },
            "livingArea": {
                "legalName": "Житлова площа, кв. м"
            },
            "kitchenArea": {
                "legalName": "Площа кухні, кв. м"
            },
            "landArea": {
                "legalName": "Площа ділянки, кв. м"
            },
            "locationInBuilding": {
                "legalName": "Місце розташування об’єкта в будівлі"
            },
            "floors": {
                "legalName": "Номер поверху або поверхів"
            },
            "generalCondition": {
                "legalName": "Технічний стан об'єкта"
            },
            "serviceElectricity": {
                "legalName": "Чи приєднаний об'єкт до електромережі"
            },
            "powerSupplyCapacity": {
                "legalName": "Потужність електромережі, кВт"
            },
            "powerSupplyClass": {
                "legalName": "Ступінь потужності електромережі"
            },
            "serviceWater": {
                "legalName": "Водозабезпечення присутнє"
            },
            "serviceSewerage": {
                "legalName": "Каналізація присутня"
            },
            "serviceGas": {
                "legalName": "Газифікація присутня"
            },
            "serviceCentralHeating": {
                "legalName": "Централізоване опалення присутнє"
            },
            "serviceAutonomousHeating": {
                "legalName": "Автономне опалення присутнє"
            },
            "serviceHeatingCounter": {
                "legalName": "Лічильник опалення присутній"
            },
            "serviceVentilation": {
                "legalName": "Вентиляція присутня"
            },
            "serviceAirConditioning": {
                "legalName": "Кондиціонування присутнє"
            },
            "servicePhone": {
                "legalName": "Телефонізація присутня"
            },
            "serviceTV": {
                "legalName": "Телебачення присутнє"
            },
            "serviceInternet": {
                "legalName": "Інтернет присутній"
            },
            "serviceElevator": {
                "legalName": "Ліфт присутній"
            },
            "serviceSecurityAlarm": {
                "legalName": "Охоронна сигналізація присутня"
            },
            "serviceFireAlarm": {
                "legalName": "Пожежна сигналізація присутня"
            },
            "servicesDescription": {
                "legalName": "Додаткова інформація щодо комунікацій, що є в об''єкті"
            },
            "servicesAccounting": {
                "legalName": "Інформація про наявність окремих особових рахунків на об'єкт, відкритих постачальниками комунальних послуг"
            },
            "landTax": {
                "legalName": "Інформація щодо компенсації сплати земельного податку за користування земельною ділянкою, на якій розташований об'єкт"
            }
        },
        "JointPropertyComplexProps": {
            "quantityAndNomenclature": {
                "legalName": "Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
            },
            "workplacesQuantity": {
                "legalName": "Кількість робочих місць, ociб"
            },
            "buildingsInformation": {
                "legalName": "Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
            },
            "landPlotInformation": {
                "legalName": "Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
            },
            "landProps": {
                "landArea": {
                    "legalName": "Площа ділянки, га"
                },
                "cadastralNumber": {
                    "legalName": "Кадастровий номер"
                },
                "ownershipType": {
                    "legalName": "Тип власності"
                },
                "encumbrances": {
                    "legalName": "Інформація про наявні обтяження"
                },
                "jointOwnership": {
                    "legalName": "Інформація про співвласників"
                },
                "utilitiesAvailability": {
                    "legalName": "Інформація про інженерні мережі"
                },
                "hasEncumbrances": {
                    "legalName": "Присутні обтяження"
                },
                "hasJointOwnership": {
                    "legalName": "Наявні співвласники"
                },
                "hasUtilitiesAvailability": {
                    "legalName": "Інженерні мережі наявні"
                },
                "landIntendedUse": {
                    "legalName": "Поточне використання земельної ділянки"
                },
                "legalName": "Характеристики земельної ділянки"
            },
            "mainObligations": {
                "legalName": "Основні зобов’язання (договірні та позадоговірні)"
            }
        },
        "VehicleProps": {
            "brand": {
                "legalName": "Марка транспортного засобу"
            },
            "model": {
                "legalName": "Модель транспортного засобу"
            },
            "productionYear": {
                "legalName": "Рік випуску"
            },
            "engineCapacity": {
                "legalName": "Об’єм двигуна, см³"
            },
            "fuelType": {
                "legalName": "Тип палива"
            },
            "transmission": {
                "legalName": "Тип коробки передач"
            },
            "color": {
                "legalName": "Колір"
            },
            "kilometrage": {
                "legalName": "Пробіг, км"
            },
            "identificationNumber": {
                "legalName": "Номер кузова або шасі (VIN-код)"
            },
            "configuration": {
                "legalName": "Комплектація"
            },
            "condition": {
                "legalName": "Технічний стан"
            },
            "damagedDescription": {
                "legalName": "Опис пошкоджень"
            },
            "damagePresence": {
                "legalName": "Потреба у ремонті"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "addressID": {
                    "scheme": {
                        "legalName": "Класифікатор об’єктів адміністративно-територіального устрою України"
                    },
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Назва адміністративно-територіальних об’єктів України"
                    },
                    "id": {
                        "legalName": "Код адміністративно-територіальних об’єктів України"
                    },
                    "legalName": "Код об’єктів адміністративно-територіального устрою України"
                },
                "countryName": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    }
                },
                "region": {
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Область"
                },
                "locality": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Населений пункт"
                },
                "streetAddress": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Адреса"
                },
                "postalCode": {
                    "legalName": "Поштовий індекс"
                },
                "legalName": "Місцезнаходження"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "armaPropertyItemType": {
                "legalName": "Тип активу"
            },
            "itemProps": {
                "legalName": "Технічні характеристики, нерухомість"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість договору"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус акту/договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з актом/договором"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії акту/договору"
            },
            "lotPaymentConfirmation": {
                "legalName": "Підтвердження сплати за лот"
            }
        }
    },
    "armaProperty-english": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "initialValueAmount": {
                "legalName": "Закрита цінова пропозиція"
            }
        },
        "armaPropertyEnglishProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "organizationEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Контактні дані організатора аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про замовника"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Особа відповідальна за забезпечення огляду"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські реквізити"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "preparationValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Витрати на підготовку лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "isPerishable": {
                "legalName": "Майно, що швидко псується"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "armaProperty-dutch": {
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "armaPropertyDutchProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "organizationEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Контактні дані організатора аукціону"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про замовника"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Особа відповідальна за забезпечення огляду"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "accessDetails": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
            },
            "bankAccounts": {
                "legalName": "Банківські реквізити"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "preparationValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Витрати на підготовку лота"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "dutchStep": {
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "isPerishable": {
                "legalName": "Майно, що швидко псується"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "legacy": {
        "AccountIdentification": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис до банківських реквізитів"
            },
            "scheme": {
                "legalName": "Тип банківського ідентифікатора"
            },
            "id": {
                "legalName": "Ідентифікатор"
            }
        },
        "BankAccount": {
            "bankName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва банку"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис"
            },
            "accountIdentifications": {
                "legalName": "Банківські реквізити"
            }
        },
        "ValueWithTax": {
            "currency": {
                "legalName": "Валюта"
            },
            "amount": {
                "legalName": "Сума"
            },
            "valueAddedTaxIncluded": {
                "legalName": "Податок"
            }
        },
        "Address": {
            "streetAddress": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Адреса"
            },
            "locality": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Населений пункт"
            },
            "region": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Область"
            },
            "postalCode": {
                "legalName": "Поштовий індекс"
            },
            "countryName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                }
            }
        },
        "AddressUa": {
            "streetAddress": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Адреса"
            },
            "locality": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Населений пункт"
            },
            "region": {
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Область"
            },
            "postalCode": {
                "legalName": "Поштовий індекс"
            },
            "countryName": {
                "en_US": {
                    "legalName": "Назва англійською"
                }
            }
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис коду класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "AdditionalClassification": {
            "scheme": {
                "legalName": "Схема додаткового класифікатору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис додаткового класифікатору"
            },
            "id": {
                "legalName": "Код додаткового класифікатору"
            }
        },
        "Unit": {
            "code": {
                "legalName": "Код одиниці виміру"
            },
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва одиниці виміру"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "wagonSpecies": {
                "legalName": "Код роду вагонів"
            },
            "loadingRestriction": {
                "legalName": "Полігон навантаження"
            },
            "loadLifeTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Період подачі вагону"
            },
            "contractPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період контракту"
            }
        },
        "OtherItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "wagonSpecies": {
                "legalName": "Код роду вагонів"
            },
            "loadingRestriction": {
                "legalName": "Полігон навантаження"
            },
            "loadLifeTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Період подачі вагону"
            },
            "contractPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "legalName": "Період контракту"
            }
        },
        "RailwayCargoItem": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис коду класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "additionalClassifications": {
                "legalName": "Додатковий класифікатор"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "location": {
                "latitude": {
                    "legalName": "Широта"
                },
                "longitude": {
                    "legalName": "Довгота"
                },
                "elevation": {
                    "legalName": "Висота"
                },
                "legalName": "Географічні координати місця розташування"
            },
            "registrationDetails": {
                "registrationStatus": {
                    "legalName": "Стан державної реєстрації об''єкту"
                },
                "registrationID": {
                    "legalName": "Номер запису"
                },
                "registrationDate": {
                    "legalName": "Дата реєстрації"
                },
                "legalName": "Інформація щодо реєстрації"
            },
            "wagonSpecies": {
                "legalName": "Код роду вагонів"
            },
            "loadingRestriction": {
                "legalName": "Полігон навантаження"
            },
            "loadLifeTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Період подачі вагону"
            },
            "contractPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період контракту"
            }
        },
        "Document": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "documentOf": {
                "legalName": "Об'єкт документу"
            },
            "documentType": {
                "legalName": "Тип документу"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Identifier": {
            "scheme": {
                "legalName": "Ідентифікатори організації або особи"
            },
            "legalName": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ фізичної особи"
            },
            "id": {
                "legalName": "Код ЄДРПОУ або ІПН або паспорт"
            }
        },
        "ContactPoint": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "ПІБ"
            },
            "email": {
                "legalName": "Адреса електронної пошти"
            },
            "telephone": {
                "legalName": "Номер телефону"
            },
            "faxNumber": {
                "legalName": "Номер факсу"
            },
            "url": {
                "legalName": "Веб адреса"
            }
        },
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації або особи"
                },
                "legalName": "Ідентифікатори організації, фізичної особи або ФОП",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Місцезнаходження"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "RelatedOrganizations": {
            "propertyOwner": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації або особи"
                    },
                    "legalName": "Ідентифікатори організації, фізичної особи або ФОП",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Балансоутримувач"
            }
        },
        "Question": {
            "id": {
                "legalName": "Ідентифікатор запитання"
            },
            "author": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Автор запитання"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва запитання"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Текст запитання"
            },
            "datePublished": {
                "legalName": "Дата публікації запитання"
            },
            "dateModified": {
                "legalName": "Дата редагування запитання"
            },
            "answer": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Відповідь на запитання"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період публікації договору"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "InfiniteContract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис договору"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "contractNumber": {
                "legalName": "Номер договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "items": {
                "legalName": "Склад лоту"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус договору"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з договором"
            }
        },
        "CancellationDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "legacyProcedure": {
            "_id": {
                "legalName": "Внутрішній ідентифікатор"
            },
            "legacyId": {
                "legalName": "Внутрішній ідентифікатор об'єкта ЦБД2"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "registryId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "registryObjectId": {
                "legalName": "Ідентифікатор об'єкта"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "auctionUrl": {
                "legalName": "Веб-адреса для перегляду аукціону"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації або особи"
                    },
                    "legalName": "Ідентифікатори організації, фізичної особи або ФОП",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Місцезнаходження"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "dutchStep": {
                "dutchStepPercent": {
                    "legalName": "Відсоток зниження, %"
                },
                "dutchStepQuantity": {
                    "legalName": "Кількість кроків"
                },
                "dutchStepValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "legalName": "Розмір кроку голландського раунду, грн"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна лота"
            },
            "minimalStep": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Розмір кроку аукціону"
            },
            "guarantee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Гарантійний внесок"
            },
            "registrationFee": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Реєстраційний внесок"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "relatedOrganizations": {
                "propertyOwner": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Повна юридична назва організації або ПІБ"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Ідентифікатори організації або особи"
                        },
                        "legalName": "Ідентифікатори організації, фізичної особи або ФОП",
                        "id": {
                            "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                        }
                    },
                    "address": {
                        "legalName": "Місцезнаходження"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Балансоутримувач"
                },
                "legalName": "Організації, задіяні в керуванні об'єктом"
            },
            "items": {
                "legalName": "Склад лота"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "auctionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період аукціону"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "budgetSpent": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Сума витрат (видатків), здійснених на підготовку лота"
            },
            "leaseDuration": {
                "legalName": "Строк оренди"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    },
    "governmentFactoring": {
        "Organization": {
            "name": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Повна юридична назва організації або ПІБ"
            },
            "identifier": {
                "scheme": {
                    "legalName": "Ідентифікатори організації"
                },
                "legalName": "Ідентифікатори організації",
                "id": {
                    "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                }
            },
            "address": {
                "legalName": "Адреса"
            },
            "representativeInfo": {
                "legalName": "Інформація щодо підтвердження повноважень"
            },
            "contactPoint": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Контактна особа"
            }
        },
        "Document": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "documentOf": {
                "legalName": "Об'єкт документу"
            },
            "documentType": {
                "legalName": "Тип документу"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "BidDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "documentOf": {
                "legalName": "Об'єкт документу"
            },
            "documentType": {
                "legalName": "Тип документу"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "CancellationDocument": {
            "id": {
                "legalName": "Ідентифікатор документу"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва документу"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис документу"
            },
            "url": {
                "legalName": "Посилання на документ"
            },
            "relatedDocument": {
                "legalName": "Пов'язаний документ"
            },
            "datePublished": {
                "legalName": "Дата публікації документу"
            },
            "dateModified": {
                "legalName": "Остання дата редагування документу"
            },
            "index": {
                "legalName": "Параметр сортування ілюстрацій"
            },
            "format": {
                "legalName": "Формат документу"
            },
            "language": {
                "legalName": "Мова документу"
            },
            "hash": {
                "legalName": "Хеш документу"
            }
        },
        "BankAccountsByType": {
            "legalName": "Банківські рахунки"
        },
        "Classification": {
            "scheme": {
                "legalName": "Схема класифікатора"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис класифікатора"
            },
            "id": {
                "legalName": "Код класифікатора"
            }
        },
        "Perks": {
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Нецінові пропозиції та додаткові умови"
            }
        },
        "Question": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор запитання"
            },
            "author": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "ПІБ"
                },
                "email": {
                    "legalName": "Адреса електронної пошти"
                },
                "telephone": {
                    "legalName": "Номер телефону"
                },
                "faxNumber": {
                    "legalName": "Номер факсу"
                },
                "url": {
                    "legalName": "Веб адреса"
                },
                "legalName": "Автор запитання"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва запитання"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Текст запитання"
            },
            "datePublished": {
                "legalName": "Дата публікації запитання"
            },
            "dateModified": {
                "legalName": "Дата редагування запитання"
            },
            "dateAnswered": {
                "legalName": "Дата відповіді на запитання"
            },
            "answer": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Відповідь на запитання"
            }
        },
        "Item": {
            "id": {
                "legalName": "Внутрішній ідентифікатор об'єкта"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис об'єкта"
            },
            "classification": {
                "scheme": {
                    "legalName": "Схема класифікатора"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Опис класифікатора"
                },
                "id": {
                    "legalName": "Код класифікатора"
                },
                "legalName": "Класифікатор об'єкта"
            },
            "unit": {
                "code": {
                    "legalName": "Код одиниці виміру"
                },
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Назва одиниці виміру"
                },
                "legalName": "Одиниці виміру"
            },
            "quantity": {
                "legalName": "Кількість одиниць"
            },
            "address": {
                "legalName": "Місцезнаходження"
            }
        },
        "Bid": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "id": {
                "legalName": "Ідентифікатор заяви на участь"
            },
            "bidders": {
                "legalName": "Інформація про учасника"
            },
            "datePublished": {
                "legalName": "Дата заяви на участь"
            },
            "dateModified": {
                "legalName": "Остання дата редагування ставки"
            },
            "status": {
                "legalName": "Статус заяви на участь"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "documents": {
                "legalName": "Документи до заяви про участь"
            },
            "participationUrl": {
                "legalName": "Веб-адреса для участі в аукціоні"
            },
            "perks": {
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Нецінові пропозиції та додаткові умови"
                },
                "legalName": "Нецінові пропозиції та додаткові умови"
            },
            "inactiveBidDate": {
                "legalName": "Дата деактивації заяви на участь"
            }
        },
        "Award": {
            "id": {
                "legalName": "Ідентифікатор кваліфікації учасника аукціону"
            },
            "bidId": {
                "legalName": "Ідентифікатор заяви на участь учасника аукціону"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва рішення щодо кваліфікації учасника аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис рішення щодо кваліфікації учасника аукціону"
            },
            "status": {
                "legalName": "Статус кваліфікації учасника аукціону"
            },
            "terminationReason": {
                "legalName": "Причина дискваліфікації/відмови учаснику"
            },
            "datePublished": {
                "legalName": "Дата початку кваліфікації учасника аукціону"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Цінова пропозиція"
            },
            "buyers": {
                "legalName": "Інформація про учасника"
            },
            "perks": {
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Нецінові пропозиції та додаткові умови"
                },
                "legalName": "Нецінові пропозиції та додаткові умови"
            },
            "documents": {
                "legalName": "Документи до аварду"
            },
            "dateModified": {
                "legalName": "Дата редагування"
            },
            "verificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання протоколу"
            },
            "signingPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період підписання договору"
            },
            "paymentPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період оплати"
            }
        },
        "Contract": {
            "id": {
                "legalName": "Внутрішній ідентифікатор контракту"
            },
            "awardId": {
                "legalName": "Ідентифікатор переможця"
            },
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "contractNumber": {
                "legalName": "Номер акту/договору"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва договору"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис акту/договору"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Вартість договору"
            },
            "contractTotalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "legalName": "Заключна вартість акту/договору"
            },
            "contractTime": {
                "dateFrom": {
                    "legalName": "Період з"
                },
                "dateTill": {
                    "legalName": "Період по"
                },
                "legalName": "Строк дії договору"
            },
            "perks": {
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Нецінові пропозиції та додаткові умови"
                },
                "legalName": "Нецінові пропозиції та додаткові умови"
            },
            "buyers": {
                "legalName": "Переможець"
            },
            "status": {
                "legalName": "Статус акту/договору"
            },
            "lotPaymentConfirmation": {
                "legalName": "Підтвердження сплати за лот"
            },
            "dateSigned": {
                "legalName": "Дата підписання договору"
            },
            "datePublished": {
                "legalName": "Дата створення об'єкта договору"
            },
            "dateModified": {
                "legalName": "Остання дата редагування договору"
            },
            "documents": {
                "legalName": "Документи та додатки пов’язані з актом/договором"
            }
        },
        "Cancellation": {
            "id": {
                "legalName": "Ідентифікатор об'єкта скасування"
            },
            "reason": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Причина скасування Аукціону"
            },
            "datePublished": {
                "legalName": "Дата скасування"
            }
        },
        "productEntity": {
            "id": {
                "legalName": "Машинний ідентифікатор процедури закупівлі Прозорро"
            },
            "humanId": {
                "legalName": "Користувацький ідентифікатор процедури закупівлі Прозорро"
            },
            "lotId": {
                "legalName": "Машинний ідентифікатор лота закупівлі Прозорро"
            },
            "debitor": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Дебітора"
            },
            "items": {
                "legalName": "Предмет закупівлі"
            },
            "initialValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Стартова ціна закупівлі"
            },
            "finalValue": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Фінальна ціна закупівлі"
            },
            "legalName": "Опис предметів закупівлі"
        }
    },
    "governmentFactoring-withoutAuction": {
        "governmentFactoringWithoutAuctionProcedure": {
            "owner": {
                "legalName": "Ідентифікатор майданчика"
            },
            "_id": {
                "legalName": "Внутрішній ідентифікатор аукціону"
            },
            "datePublished": {
                "legalName": "Дата публікації процедури"
            },
            "dateModified": {
                "legalName": "Остання дата зміни процедури"
            },
            "auctionId": {
                "legalName": "Ідентифікатор аукціону"
            },
            "previousAuctionId": {
                "legalName": "Ідентифікатор попереднього аукціону"
            },
            "inactivationDate": {
                "legalName": "Дата деактивації заяви на участь"
            },
            "sellingMethod": {
                "legalName": "Тип процедури"
            },
            "sellingEntity": {
                "name": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Повна юридична назва організації або ПІБ"
                },
                "identifier": {
                    "scheme": {
                        "legalName": "Ідентифікатори організації"
                    },
                    "legalName": "Ідентифікатори організації або особи",
                    "id": {
                        "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                    }
                },
                "address": {
                    "legalName": "Адреса"
                },
                "representativeInfo": {
                    "legalName": "Інформація щодо підтвердження повноважень"
                },
                "contactPoint": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "ПІБ"
                    },
                    "email": {
                        "legalName": "Адреса електронної пошти"
                    },
                    "telephone": {
                        "legalName": "Номер телефону"
                    },
                    "faxNumber": {
                        "legalName": "Номер факсу"
                    },
                    "url": {
                        "legalName": "Веб адреса"
                    },
                    "legalName": "Контактна особа"
                },
                "legalName": "Інформація про Організатора аукціону"
            },
            "lotId": {
                "legalName": "Номер лота"
            },
            "title": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Назва аукціону"
            },
            "description": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Опис аукціону"
            },
            "bankAccounts": {
                "legalName": "Банківські рахунки організатора"
            },
            "x_documentRequirements": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Перелік та вимоги до оформлення документів"
            },
            "x_additionalInformation": {
                "uk_UA": {
                    "legalName": "Назва українською"
                },
                "en_US": {
                    "legalName": "Назва англійською"
                },
                "legalName": "Додаткові відомості"
            },
            "value": {
                "currency": {
                    "legalName": "Валюта"
                },
                "amount": {
                    "legalName": "Сума"
                },
                "valueAddedTaxIncluded": {
                    "legalName": "Податок"
                },
                "legalName": "Реальна сума угоди закупівлі"
            },
            "paymentTerms": {
                "duration": {
                    "legalName": "Кількість днів на виконання платежу за договором"
                },
                "description": {
                    "uk_UA": {
                        "legalName": "Назва українською"
                    },
                    "en_US": {
                        "legalName": "Назва англійською"
                    },
                    "legalName": "Додаткова інформація щодо умов розрахунку дебітора за контрактом з організатором"
                },
                "legalName": "Умови виконання оплати дебітором за договором організатору"
            },
            "productEntity": {
                "id": {
                    "legalName": "Машинний ідентифікатор процедури закупівлі Прозорро"
                },
                "humanId": {
                    "legalName": "Користувацький ідентифікатор процедури закупівлі Прозорро"
                },
                "lotId": {
                    "legalName": "Машинний ідентифікатор лота закупівлі Прозорро"
                },
                "debitor": {
                    "name": {
                        "uk_UA": {
                            "legalName": "Назва українською"
                        },
                        "en_US": {
                            "legalName": "Назва англійською"
                        },
                        "legalName": "Повна юридична назва організації або ПІБ"
                    },
                    "identifier": {
                        "scheme": {
                            "legalName": "Ідентифікатори організації"
                        },
                        "legalName": "Ідентифікатори організації або особи",
                        "id": {
                            "legalName": "Код ЄДРПОУ або ІПН або паспорт"
                        }
                    },
                    "address": {
                        "legalName": "Адреса"
                    },
                    "representativeInfo": {
                        "legalName": "Інформація щодо підтвердження повноважень"
                    },
                    "contactPoint": {
                        "name": {
                            "uk_UA": {
                                "legalName": "Назва українською"
                            },
                            "en_US": {
                                "legalName": "Назва англійською"
                            },
                            "legalName": "ПІБ"
                        },
                        "email": {
                            "legalName": "Адреса електронної пошти"
                        },
                        "telephone": {
                            "legalName": "Номер телефону"
                        },
                        "faxNumber": {
                            "legalName": "Номер факсу"
                        },
                        "url": {
                            "legalName": "Веб адреса"
                        },
                        "legalName": "Контактна особа"
                    },
                    "legalName": "Інформація про Дебітора"
                },
                "items": {
                    "legalName": "Предмет закупівлі"
                },
                "initialValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Стартова ціна закупівлі"
                },
                "finalValue": {
                    "currency": {
                        "legalName": "Валюта"
                    },
                    "amount": {
                        "legalName": "Сума"
                    },
                    "valueAddedTaxIncluded": {
                        "legalName": "Податок"
                    },
                    "legalName": "Фінальна ціна закупівлі"
                },
                "legalName": "Опис предметів закупівлі"
            },
            "valueAddedTaxCharged": {
                "legalName": "На фінальну суму нараховується ПДВ"
            },
            "minNumberOfQualifiedBids": {
                "legalName": "Мінімальна кількість заяв"
            },
            "tenderAttempts": {
                "legalName": "Лот виставляється"
            },
            "documents": {
                "legalName": "Всі документи аукціону та пов'язані додатки"
            },
            "bids": {
                "legalName": "Заява на участь"
            },
            "questions": {
                "legalName": "Сервіс обговорення аукціону (запитання-відповідь)"
            },
            "awards": {
                "legalName": "Рішення щодо кваліфікації учасників"
            },
            "contracts": {
                "legalName": "Договір"
            },
            "rectificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період редагування"
            },
            "enquiryPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період відповідей"
            },
            "tenderPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період подання заяви на участь в аукціоні"
            },
            "questionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період запитань"
            },
            "winnerSelectionPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період обрання переможця"
            },
            "qualificationPeriod": {
                "startDate": {
                    "legalName": "Дата та час початку"
                },
                "endDate": {
                    "legalName": "Дата та час завершення"
                },
                "legalName": "Період кваліфікації"
            },
            "status": {
                "legalName": "Статус процедури"
            },
            "cancellations": {
                "legalName": "Скасування Аукціону"
            },
            "additionalInformation": {
                "legalName": "Додаткова інформація"
            }
        }
    }
}