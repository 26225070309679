import React from 'react';
import styles from './authUser.module.css';

import SvgAuthUser from './authUser.png';

import { connect } from 'react-redux';

import {userProfileGet} from '../../../../redux/actions/oldSiteUsers/oldSiteUsers.js';
import {getDefaultHeaders} from "../../../_api/bidApi";

const AuthUser = (props) => {
    if(props.userProfiles === null){
        let header = getDefaultHeaders(props)
        let data = {
          domen: process.env.REACT_APP_END_POINT_BD,
          version: 'v2',
          local: props.langFlag,
        }
        props.userProfileGet(data, header, props)
    }
    let textTmp = null;
    let tetDownText = null;
    if(props.userProfiles === null){
        return ( 
            <div className={styles.wrapFaq} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img className={styles.imgAuthUser} src={SvgAuthUser}  alt={'...'}/>
                        <div className={styles.korusne_title}>Дані відсутні</div>
                    </div>
                    <div className={styles.korusne_text}>Ваш Id: Дані відсутні</div>
                </div>
            </div>
        )
    }else{
        if(props.userProfiles.organization !== null){
            if(props.userProfiles.organization.name.hasOwnProperty("uk_UA") === true){
                textTmp = props.userProfiles.organization.name.uk_UA
            }else{
                textTmp = props.userProfiles.organization.name
            }
            tetDownText = props.userProfiles.oldUserId
        }
        return ( 
            <div className={styles.wrapFaq} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <img className={styles.imgAuthUser} src={SvgAuthUser} alt={'...'}/>
                        <div className={styles.korusne_title}>{textTmp}</div>
                    </div>
                    <div className={styles.korusne_text}>Ваш Id: {tetDownText}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token: state.start.token,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    userProfileGet
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthUser)