import React, { useState} from 'react';
import styles from './instructionBlock.module.css';

const InstructionBlock = (props) => {
    const [collapser, setCollapser] = useState({ state: true, title: 'Згорнути'})

    return (
        <div className={styles.instructionBlock}>
            <div className={styles.addInfo}>
                <div className={styles.addInfoIcon}>
                    <svg className={styles.svgIcon} width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <g id="Слой_2" data-name="Слой 2">
                            <g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} fill="#fff" d="M11,14V9A1,1,0,0,0,9,9v5a1,1,0,0,0,2,0Z"/>
                                <path className={styles.svgIcon} fill="#fff" d="M9,6a1,1,0,1,1,1,1A1,1,0,0,1,9,6Z"/>
                                <path className={styles.svgIcon} fill="#fff" fillRule="evenodd" d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10Zm18,0a8,8,0,1,0-8,8A8,8,0,0,0,18,10Z"/>
                            </g>
                        </g>
                </svg>
                </div>
            </div>
            <div className={styles.addDocInsturuction}>
                <h3>{props.title}</h3>
                <div className={styles.aCollapse}
                    onClick={() => {
                        collapser.state ? setCollapser({ state: false, title: 'Розгорнути'}) :  setCollapser({ state: true, title: 'Згорнути'})
                        }
                    }
                >
                    {collapser.title}
                </div>
                {collapser.state ?
                    props.content : null
                }
            </div>
        </div>
    )
}

export default InstructionBlock