import React from 'react';
import styles from './paging.module.css';

import {connect} from 'react-redux';

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeMyCabinetCurentPage,
    changeLoader,
} from '../../../../redux/actions';

import {setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
    changeCreateItemGenerationType,
    changeCreateItemUnitCode,
} from '../../../../redux/actions/auctions/auctions.js';

import {changePopUpBankAccount, setPopBankAccountId, setPopBankAccountIdArr} from '../../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../../redux/actions/documents/documents.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../../redux/actions/discount/discount.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import NextPage from './next'
import PrevPage from './prev'
import {getDefaultHeaders} from "../../../_api/bidApi";

const pagingButton = ( props ) => {
    let tmpCurrentNumber = props.myCabinetPage + 1
    if(props.auctionsGetMyData !== null){
        return (
            <div className={styles.paging_wrap}>
                {buttonPrev(props)}
                <div className={styles.countPage}>{tmpCurrentNumber}</div>
                {buttonNext(props)}
                {pageNavCounter(props)}
            </div>
        )
    }
    return null
}

function buttonPrev(props){
    if(props.myCabinetPage !== 0){
        return (
            <div 
                className={styles.btnPrev}
                onClick={
                    () => {
                        handlerPrev(props, props.myCabinetPageCounter)
                    }
                }
            >
                <PrevPage />
            </div>
        )
    }
    return null
}

function buttonNext(props){
        return (
            <div 
                className={styles.btnNext}
                onClick={
                    () => {
                        handlerNext(props, props.myCabinetPageCounter)
                    }
                }
            >
                <NextPage />
            </div>
        )
}

function pageNavCounter(props){
    if(props.myCabinetPageCounter){
        return (
            <div className={styles.pageNavCounter}>
                <div className={styles.sumCount}>{props.myCabinetPageCounter}</div>
                <div className={styles.counters}>
                    <div className={styles.sumArrowUp}
                        onClick={
                            () => {
                                let tmp = props.myCabinetPageCounter + 5
                                handlerCount(props, tmp)
                                props.setDataAddress(tmp, 'SET_CABINET_PAGE_COUNTER')
                            }
                        }
                    >
                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M7.5 6C7.91202 6 8.14721 5.52962 7.9 5.2L4.4 0.533333C4.2 0.266666 3.8 0.266667 3.6 0.533333L0.1 5.2C-0.147214 5.52962 0.0879774 6 0.5 6L7.5 6Z" fill="#92929D" />
                        </svg>
                    </div>
                    <div className={styles.sumArrowDn}
                        onClick={
                            () => {
                                let tmp = props.myCabinetPageCounter - 5
                                handlerCount(props, tmp)
                                props.setDataAddress(tmp, 'SET_CABINET_PAGE_COUNTER')
                            }
                        }
                    >
                        <svg width="8" height="6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.59 6.37">
                            <defs>
                            </defs>
                            <g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1">
                                <path className={styles.svgIcon} d="M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z" fill="#92929D" />
                            </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

function handlerNext(props, limit){
    let tmp = props.myCabinetPage + 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?limit=${limit}&offset=${tmp}0`
    if(props.myStatus !== 'all'){
        data.body = {status: props.myStatus}
    }

    props.setDataAddress(null, 'AUCTIONS_GET_MY')
    props.changeMyCabinetCurentPage(tmp)
    getAucionsList(props, `?limit=${limit}&offset=${tmp}0`)
}

async function getAucionsList(props, data){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my${data}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}

function handlerPrev(props, limit){
    let tmp = props.myCabinetPage - 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    if(tmp === 0){
        data.params = `?limit=${limit}&offset=${tmp}`
    }else{
        data.params = `?limit=${limit}&offset=${tmp}0`
    }
    if(props.myStatus !== 'all'){
        data.body = {status: props.myStatus}
    }

    props.setDataAddress(null, 'AUCTIONS_GET_MY')
    props.changeMyCabinetCurentPage(tmp)
    getAucionsList(props, `?limit=${limit}&offset=${tmp}0`)
}

function handlerCount(props, limit){
    let tmp = 0 + 1
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?limit=${limit}&offset=${tmp}`
    if(props.myStatus !== 'all'){
        data.body = {status: props.myStatus}
    }
    props.setDataAddress(null, 'AUCTIONS_GET_MY')
    props.changeMyCabinetCurentPage(0)
    props.auctionsGetMy(data, getDefaultHeaders(props))
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionsGetMyData: state.start.auctionsGetMy,
        myCabinetPage: state.start.myCabinetPage,
        myCabinetPageCounter: state.start.myCabinetPageCounter,
        myStatus: state.start.myStatus,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,

    changeCreateItemGenerationType,
    changeCreateItemUnitCode,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    discountAuc,
    changeMyCabinetCurentPage,

    setDataAddress,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(pagingButton)
