import React, {useEffect} from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import EditAuctionTab from './navTitleBar/navTitleBarMonga'
import WindowDocument from '../../../windowDocument/windowDocument'

import {changeLoader} from '../../../../../redux/actions.js';
import {setDataAddress} from '../../../../../redux/createAddress';
import {bankAccountGet} from '../../../../../redux/actions/bankAccount/bankAccount.js';
import {setUniversalError} from '../../../../../redux/actions.js';
import Procedure from '../../createAuction/procedure/procedure';

import {
    setBankAccountAdd,
    setBankAccountDelete
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

import {setPopUpAuctionCreate} from '../../../../../redux/actions/auctions/auctions.js';
import WindowItems from "../../../../_CRUD/item/windowItems";

const WindowAuction = (props) => {
    function setBaseUrl() {
        if (window.location.pathname.indexOf('/editNew/') !== -1) {
            return window.location.pathname.split('/editNew/');
        }
        return null
    }

    function setPageId() {
        let tmp = setBaseUrl()
        if (tmp !== null) {
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`
        props.bankAccountGet(dataBank, header)
    }, [])

    function viewPage(props) {
        let tmp
        switch (props.createAuctionTab) {
            case 0:
                tmp = <Procedure updateAuc={props}/>
                // tmp = <EditAuctionMonga /> //TODO: Procedure
                break;
            case 1:
                tmp = <WindowItems/>
                break;
            case 2:
                tmp = <WindowDocument/>
                break;
            default:
                tmp = null
        }
        return tmp
    }

    if (props.auctionsMy !== null) {
        return (
            <div className={styles.wrapflexBlock}>
                <EditAuctionTab auctionsMy={props.auctionsMy}/>
                {viewPage(props)}
            </div>
        )
    }
    return null
}


async function getAucions(props, id) {
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${id}`;
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if (response === undefined || response === 'undefined') {
            } else {
                const json = await response.json()
                if (json.hasOwnProperty('message') === true) {
                    let tmp = JSON.stringify({
                        title: 'Помилка при завантаженні аукціонів',
                        message: JSON.stringify(json)
                    })
                    props.setUniversalError(tmp)
                } else {
                    props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                }
            }
        } else {
            // console.log(json)
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
        }
    }
    props.changeLoader(false)
}

export async function createAuc(props, tmpBody) {
    props.changeLoader(true)
    let endPoint
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft`

    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            //let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            //props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
            //props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            //props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            //props.setUniversalError(tmp)
            props.setPopUpAuctionCreate(json)
        }
    }
    //props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    //props.setBankAccountAdd([])
    //props.setBankAccountDelete([])
    props.changeLoader(false)
}

export async function editAuc(props, tmpBody) {
    props.changeLoader(true)
    let endPoint
    if (props.auctionsMy.auctionId === null) {
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${props.auctionsMy.id}`
    } else {
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}`
    }

    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(error => {
            props.changeLoader(false)
            console.error('Api request error:', error);
        })

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setUniversalError(tmp)
        }
    }
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}

export async function editAucCurrentTenant(props, tmpBody) {
    props.changeLoader(true)
    //let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}/currentTenant/${currentTenantId}`
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}/currentTenantList`
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setUniversalError(tmp)
        }
    }
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}


export async function editAucPatch(props, tmpBody) {
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}`

    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setUniversalError(tmp)
        }
    }
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}

export async function editAucArest(props, tmpBody) {
    props.changeLoader(true)
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}/arrests`

    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            // let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            // props.setDataAddress(null, 'EDIT_AUCTION_FROM_ID')
            // props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            // props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            // props.setUniversalError(tmp)
        }
    }
    // props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
    // props.setBankAccountAdd([])
    // props.setBankAccountDelete([])
    props.changeLoader(false)
}

export async function editAucAwardPatch(props, tmpBody) {
    props.changeLoader(true)
    let endPoint
    let tmpMethod = 'POST'
    if (props.auctionsMy.awards.length === 0) {
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${props.auctionsMy.id}/award`
    } else {
        if (props.auctionsMy.status === null) {
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${props.auctionsMy.id}/award/${props.auctionsMy.awards[0].id}`
            tmpMethod = 'PUT'
        } else {
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionsMy.id}/award/${props.auctionsMy.awards[0].id}`
            tmpMethod = 'PATCH'
        }
    }

    const response = await fetch(endPoint, {
        method: tmpMethod,
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
        .catch(console.error)

    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            let tmp = null
            if (json.message.hasOwnProperty('documents') === true) {
                if (json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first") {
                    tmp = JSON.stringify({
                        title: 'Помилка при редагуванні аукціону',
                        message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'
                    })
                }
            }
            if (tmp === null) {
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            // let tmp = JSON.stringify({title: '', message: 'Дані збережено'})
            // props.setUniversalError(tmp)
        }
    }
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    changeLoader,
    setDataAddress,
    bankAccountGet,
    setUniversalError,
    setPopUpAuctionCreate,
    setBankAccountAdd,
    setBankAccountDelete,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)