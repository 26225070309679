import React from 'react';
import styles from './jointPropertyComplexProps.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';

import {
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
} from '../../../../../redux/actions.js';

const WindowItems = ( props ) => {
    return (
        <div className={styles.group}>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Обсяг та основна номенклатура продукції (робіт, послуг), у тому числі експортної"
                        variant="outlined"
                        value={props.createItems.dgf.jointPropertyComplexProps.quantityAndNomenclature}
                        onChange={(event)=>{
                            props.changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Кількість робочих місць, ociб"
                        variant="outlined"
                        value={props.createItems.dgf.jointPropertyComplexProps.workplacesQuantity}
                        onChange={(event)=>{
                            //example: 500
                            props.changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Відомості про будівлі (споруди, приміщення) єдиного майнового комплексу в обсязі, передбаченому для інформації про нерухоме майно"
                        variant="outlined"
                        value={props.createItems.dgf.jointPropertyComplexProps.buildingsInformation}
                        onChange={(event)=>{
                            //example: Будівлі А, Б, В, Г, Д, склад Д, розбірна конструкція Є, ТП 4305, прохідна, мощення
                            props.changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Відомості про земельну ділянку, на якій розташовано єдиний майновий комплекс"
                        variant="outlined"
                        value={props.createItems.dgf.jointPropertyComplexProps.landPlotInformation}
                        onChange={(event)=>{
                            //example: Площа ділянки, кадастровий номер - '8000000000:72:233:0018' або обліковий номер
                            props.changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation(event.target.value)
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Основні зобов’язання (договірні та позадоговірні)"
                        variant="outlined"
                        value={props.createItems.dgf.jointPropertyComplexProps.mainObligations}
                        onChange={(event)=>{
                            //example: Договір поставки №4 від 12.09.2019 року. Зобов'язання виробити для ТОВ «АлкоВайн» 300 літрів горілки «Найкраща». Зобов'язання за кредитним договором №5 від 30.06.2018 року, укладеного з банком ПАТ «Грошібанк» в розмірі 2,4 млн грн.
                            props.changeCreateItemDgfJointPropertyComplexPropsMainObligations(event.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
    }
}

const mapDispatchToProps = {
    changeCreateItemDgfJointPropertyComplexPropsQuantityAndNomenclature,
    changeCreateItemDgfJointPropertyComplexPropsWorkplacesQuantity,
    changeCreateItemDgfJointPropertyComplexPropsBuildingsInformation,
    changeCreateItemDgfJointPropertyComplexPropsLandPlotInformation,
    changeCreateItemDgfJointPropertyComplexPropsMainObligations,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)