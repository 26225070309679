// EDIT BTN
export function viewProlongationEditDoc(props) {
    if (props.data.documentOf === 'prolongation') {
        if (
            props.scope.aucStatus !== 'cancelled' && props.scope.aucStatus !== 'unsuccessful' && props.scope.aucStatus !== 'complete'
        ) {
            //if (props.scope.aucOwner === true && props.scope.contractStatus !== "active") return true
            return true
        }
    }
    return false
}

//KEP URL Create
