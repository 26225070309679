import { 
    //Awards
    SET_AWARDS,
    SET_AWARD_ID,
    SET_POPUP_AWARD_REJECT,
    SET_POPUP_AWARD_ERROR,
    SET_POPUP_AWARD_APPROWE,
    AWARD_POPUP_VIEW,
    SET_AWARD_REJECT_TITLE,
    SET_AWARD_REJECT_DESCRIPTION,
    AWARD_DOCUMENT_RESTRICT,
    SET_AWARD_REJECT_DOCUMENT_TYPE,
    AWARD_DOCUMENT_STATUS,
} from "../../types";

import {getAPI} from '../crud';

export function auctionsGetAwards(_url, header, idAuction) {
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${idAuction}/awards?limit=20&offset=0`,
        actionType: SET_AWARDS,
        header: header
    }
    return getAPI(data);
}

export function auctionsGetAwardsId(_url, header, idAuction, idAward) {
    const data = {
        url: `${_url.domen}/api/v1/uk/auction/${idAuction}/award/${idAward}?limit=20&offset=0`,
        actionType: SET_AWARD_ID,
        header: header
    }
    return getAPI(data);
}

//Попап отклонения аварда
export function changeAwardRejectPopUpStatus(value) {
    return {
        type: SET_POPUP_AWARD_REJECT,
        payload: value
    }
}

export function changeAwardApprowePopUpStatus(value) {
    return {
        type: SET_POPUP_AWARD_APPROWE,
        payload: value
    }
}

export function changeAwardErrorPopUpStatus(value) {
    return {
        type: SET_POPUP_AWARD_ERROR,
        payload: value
    }
}

export function changeAwardView(value) {
    return {
        type: AWARD_POPUP_VIEW,
        payload: value
    }
}


export function changeAwardRejectTitle(value) {
    return {
        type: SET_AWARD_REJECT_TITLE,
        payload: value
    }
}

export function changeAwardRejectDescription(value) {
    return {
        type: SET_AWARD_REJECT_DESCRIPTION,
        payload: value
    }
}

export function changeAwardRejectDocumentType(value) {
    return {
        type: SET_AWARD_REJECT_DOCUMENT_TYPE,
        payload: value
    }
}

export function changeAwardDocumentStatus(value) {
    return {
        type: AWARD_DOCUMENT_STATUS,
        payload: value
    }
}

export function changeAwardRejectRestrict(value) {
    return {
        type: AWARD_DOCUMENT_RESTRICT,
        payload: value
    }
}