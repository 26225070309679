import React from 'react';

import {connect} from 'react-redux';


import { isTIE, isBRE, isBRD, isBRW, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD } from '../../../../redux/procedure';

//Блокировка кнопки если нет документа contractSigned (Підписаний договір)
//Очиска полей при закрытии попапа


import ContractTIE from './contractTIE'
import ContractBRE_BSD from './contractBRE_BSD'
import ContractOther from './contractOther'
import ContractSPE from './contractSPE';
import ContractNLE from './contractNLE';
import ContractLAE from './contractLAE';
import ContractLPE from './contractLPE';
import ContractAPE from './contractAPE';

const AuctionsType = ( props ) => {
    if(isTIE(props.auctionFull.sellingMethod)) return <ContractTIE />
    if(isBRE(props.auctionFull.sellingMethod) || isBRD(props.auctionFull.sellingMethod) || isBRW(props.auctionFull.sellingMethod)) return <ContractBRE_BSD />
    if(isSPE(props.auctionFull.sellingMethod) || isSPD(props.auctionFull.sellingMethod)) return <ContractSPE />
    if(isNLE(props.auctionFull.sellingMethod) || isNLD(props.auctionFull.sellingMethod)) return <ContractNLE />
    if(isLAE(props.auctionFull.sellingMethod) || isLAW(props.auctionFull.sellingMethod) || isLAP(props.auctionFull.sellingMethod)) return <ContractLAE />
    if(isLPE(props.auctionFull.sellingMethod)) return <ContractLPE />
    if(isAPE(props.auctionFull.sellingMethod) || isAPD(props.auctionFull.sellingMethod)) return <ContractAPE />
    return <ContractOther />
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctionFull: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,
        popUpContractUniversal: state.start.popUpContractUniversal,
        timberContractPut: state.start.timberContractPut,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

export default connect(mapStateToProps, null)(AuctionsType)