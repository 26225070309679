import React, {useState} from 'react';
import styles from './awardEditModule.module.css';
import popupStyles from "../../../../_popups/popAsk/popAsk.module.css";
import bidStyles from "../../../bid/create/createBid.module.css";

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus,} from '../../../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../../../redux/actions/contracts/contracts.js';

import {getUpdateDataAuction, getUpdateDataBid} from '../../../../product/updateData';
import {saveArrBids} from '../../../../../redux/allAuctionBids';
import {setDataAddress} from '../../../../../redux/createAddress';

import TextHalf from '../../../../elements/viewData/textHalf'
import PopDown from '../../../../elements/viewData/popdownBlock/popDownBlock'
import AddDocPopup from '../../../../documents/addDocPopup/addDocPopup';
import DocItem from '../../../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../../../redux/modifiDate'
import {viewStringValue} from '../../../../../api/valueToString.js'
import {getAddressAsString} from "../../../../../redux/check";
import procedure from "../../../../../redux/procedure";
import PopAsk from "../../../../_popups/popAsk/popAsk";
import {getDefaultHeaders} from "../../../../_api/bidApi";

const AwardEditREM = (props) => {
    const [popupType, setPopupType] = useState(false)
    const [quote, setQuote] = useState(null)

    const bid = procedure.getBidByAward(props.auction?.bids, props.award)
    return (
        <div className={styles.awardBlock} key={props.award.id}>
            {props.award?.title ? <TextHalf title='Назва' data={props.award?.title}/> : null}
            <TextHalf title='Назва учасника' data={props.award?.buyers[0].name?.uk_UA}/>
            {viewBidValue(props, props.award)}
            {initialValueAmount(props, props.award)}
            {bid?.quantity ? <TextHalf title='Розмір частки квоти в заяві' data={bid?.quantity + ' кВт'}/> : null}
            {props.award.terminationReason ?
                <TextHalf title='Причина дискваліфікації'
                          data={viewTypeTerminationReason(props, props.award?.terminationReason)}
                />
                :
                null
            }
            {viewStatus(props, props.award)}
            <TextHalf title='Юридична адреса' data={getAddressAsString(props.award?.buyers[0]?.address)}/>
            {props.award.admissionPeriod ?
                <TextHalf
                    title='Період прийняття рішення щодо набуття статусу переможця'
                    data={getPeriodAsText(props.award.admissionPeriod)}
                />
                : null}
            {props.award.signingPeriod ?
                <TextHalf
                    title="Період публікації протоколу/підписання договору"
                    data={getPeriodAsText(props.award.signingPeriod)}
                />
                : null}
            {props.award.terminationReason === null ? <div className={styles.awardBlockContent}>
                {viewAwardActionButtonsBlock(props, props.award, props.count, setPopupType, popupType, quote, setQuote)}
            </div> : null}
            {viewDocumentsBlock(props, props.award, props.count)}
        </div>
    )
}

function viewAwardActionButtonsBlock(props, award, count, setPopupType, popupType, quote, setQuote) {

    if (
        props.auction.status === 'active'
        || (props.auction.status === 'waiting' && procedure.isAwardOwner(props.auction, award))
        || props.auction.status === 'cancelled'
        || props.auction.status === 'unsuccessful'
        || props.auction.status === 'complete'
        || award.status === 'cancelled'
        || award.status === 'unsuccessful'
        || award.status === 'rejected'
        || award.status === 'pending_waiting'
        || procedure.getContractByAward(props?.auction?.contracts, award)?.status === 'active'
    ) {
        return null
    }

    function showAddDocPopup(popupType, setPopupType,) {
        return (
            <>
                {popupType === 1 ?
                    <AddDocPopup
                        scopeData={docScopeData}
                        actionClose={() => {
                            setPopupType(false)
                        }}
                        actionNo={() => {
                            setPopupType(false)
                        }}
                    />
                    : null}
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(1)
                        }
                    }
                >
                    Документи кваліфікації
                </div>
            </>
        )
    }

    const docScopeData = {}
    docScopeData.aucId = props.auction.id
    docScopeData.aucStatus = props.auction.status
    docScopeData.aucType = props.auction.sellingMethod
    docScopeData.awardId = award.id
    docScopeData.awardStatus = award.status
    docScopeData.aucOwner = props?.auction?.privateData?.isOwner
    docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
    docScopeData.bidId = award.bidId
    docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auction.id)
        if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = award.documents
    if (props?.auction?.privateData?.isOwner) {
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
    } else if (props.auction?.privateData?.hasBids) {
        let bid = procedure.getBidByAward(props.auction?.bids, award)
        if (bid === null || award?.status === 'protocol_signed' || award?.status === 'rejected' || award?.status === 'waiting') {
            return
        }
        docScopeData.typeOf = 'bidAward'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid.id}/documents`
    }
    if (props.auction?.privateData?.isOwner === true || procedure.isAwardOwner(props.auction, award)) {
        return (
            <>
                {showAddDocPopup(popupType, setPopupType)}
                {viewUserActionButtonsBlock(props, award, count, popupType, setPopupType, quote, setQuote)}
            </>
        )
    }
    return null
}


function getAwardDocTypes(auction, award) {
    if (award.status === 'cancelled' || award.status === 'unsuccessful' || award.status === 'rejected') {
        return []
    }
    let documents = []

    if (award?.status === 'pending' || award?.status === 'pending_admission') {
        documents.push({value: 'auctionProtocol', name: 'Протокол аукціону', main: null})
    }

    if (procedure.isAwardOwner(auction, award)) {
        if (award?.status === 'verification') {
            documents.push({value: 'x_guarantee', name: 'Фінансове забезпечення'},)
            documents.push({
                value: 'x_ultimateBeneficiaryInfo',
                name: 'Інформація про кінцевого бенефіціарного власника'
            },)
            documents.push({value: 'x_governingBodyInfo', name: 'Інформація про органи управління'},)
            documents.push({value: 'x_relatedParties', name: 'Інформація про пов\'язаних осіб'},)
            documents.push({
                value: 'x_generationType',
                name: 'Довідка із зазначенням виду альтернативного джерела енергії'
            },)
            documents.push({value: 'eligibilityDocuments', name: 'Договір про приєднання об\'єкта електроенергетики'},)
        } else {
            documents.push({value: 'x_guarantee', name: 'Фінансове забезпечення', main: null})
        }
    }
    if (auction?.privateData?.isOwner) {
        if (award.status === 'verification') {
            documents.push({value: 'rejectionProtocol', name: 'Акт про невідповідність', main: null})
        }
        if (award?.status !== 'verification') {
            documents.push({value: 'act', name: 'Акт про відмову', main: null})
        }
    }

    return documents
}

function viewBidValue(props, award) {
    if (props?.auction?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auction.bids.length; tmp++) {
            if (props.auction.bids[tmp].value !== null) {
                if (props.auction.bids[tmp].id === award.bidId) {
                    let tmpValue = viewStringValue(props.auction.bids[tmp].value)
                    return (
                        <TextHalf
                            title='Розмір цінової пропозиції'
                            data={tmpValue}
                        />
                    )
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, award) {
    if (props.auction?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auction.bids.length; tmp++) {
            if (props.auction.bids[tmp].value !== null) {
                if (props.auction.bids[tmp].id === award.bidId) {
                    let tmpCost = {
                        amount: props.auction.bids[tmp].initialValueAmount,
                        currency: "eurocent"
                    }
                    let tmpValue = viewStringValue(tmpCost)
                    return props.auction.bids[tmp].initialValueAmount ? <TextHalf
                        title='Розмір закритої цінової пропозиції'
                        data={tmpValue}
                    /> : null
                }
            }
        }

    }
    return null
}

//@todo use API dictionary
function viewTypeTerminationReason(props, data) {

    switch (data) {
        case '1':
            return "Подання неповного пакету документів або відомостей"
        case '2':
            return "Надання недостовірної інформації"
        case '3':
            return "Не розкриття інформації про кінцевих бенефіціарних власників"
        case '4':
            return "Поширення обмежувальних заходів"
        case '5':
            return "Перевищення обсягу річної квоти (25%)"
        case '6':
            return "Відмова від підписання протоколу"
        case '7':
            return "Відмова від підписання договору"
        default:
            return data
    }
}

function viewStatus(props, data) {
    if (data.status !== null && data.status !== '' && data.status !== 'null') {
        return (
            <TextHalf
                title='Статус'
                data={getAwardStatusText(props, data.status)}
            />
        )
    }
    return null
}

function getAwardStatusText(props, status) {
    switch (status) {
        case 'verification':
            return 'Перевірка документів'
        case 'waiting':
            return 'Документи перевірено'
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            return 'Переможець. Договір підписано'
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
        case 'rejected':
            return 'Відхилено'
        case 'protocol_signed':
            return 'Підписано протокол'
        case 'pending_admission':
            return 'Підтвердження набуття статусу переможця'
        default:
            return status
    }

}

function getPeriodAsText(period) {
    return `${modifiDateString(period.startDate, 3)} - ${modifiDateString(period.endDate, 3)}`
}

function viewDocumentsBlock(props, award, count) {
    let bidDocuments = viewAwardBidDocuments(props, award)
    if ((award?.documents?.length > 0) || (bidDocuments?.length > 0)) {
        return <PopDown
            title={`Додані документи (${award?.documents?.length + bidDocuments?.length})`}
            key={award.id}
            content={
                <>
                    {award?.documents?.length > 0
                        ?
                        <>
                            <div className={styles.blockHeader}>Документи організатора:</div>
                            {viewAwardDocuments(award, count, props)}
                        </>
                        : null
                    }
                    {bidDocuments?.length > 0
                        ?
                        <>
                            <div className={styles.blockHeader}>Документи учасника:</div>
                            {bidDocuments}
                        </>
                        : null
                    }
                </>
            }
        />
    }

    return null
}

function viewAwardDocuments(award, countContract, props) { // документы организатора

    function dataECPMapper(award, t) {
        let tmp = award
            .filter(
                (item) => {
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
                })
        if (tmp.length === 0) return null
        return tmp
    }


    return award?.documents
        .map(
            (document) => {
                const getHistoryURL = (props, award) => {
                    if (document.documentOf === 'award') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/awards/${award.id}/documents`
                    }
                    if (document.documentOf === 'bid') {
                        return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${award.bidId}/documents`
                    }
                }

                const docScopeData = {}
                docScopeData.aucId = props.auction.id
                docScopeData.aucStatus = props.auction.status
                docScopeData.aucType = props.auction.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auction.id)
                }
                docScopeData.aucOwner = props?.auction?.privateData?.isOwner
                docScopeData.historyUrl = getHistoryURL(props, award)


                docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
                docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
                docScopeData.awardStatus = award.status
                docScopeData.docList = award.documents

                if (props?.auction?.privateData?.isOwner) {
                    docScopeData.typeOf = 'award'
                    docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/documents`
                }

                return (
                    <DocItem
                        data={document}
                        scopeData={docScopeData}
                        dataECP={dataECPMapper(award.documents, document)}
                        key={document.id}
                    />
                )
            }
        )
}

function viewAwardBidDocuments(props, award) {
    function dataECPMapper(documents, t) {
        let result = documents
            .filter(
                (item) => {
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.documentId
                })
        if (result.length === 0) return null
        return result
    }

    const bid = procedure.getBidByAward(props.auction.bids, award)


    // let documents = null
    // documents = bid?.documents.filter(
    //     (document) => {
    //         return document.documentType !== 'auctionProtocol'// || document.documentType === 'x_guarantee';
    //     }
    // )

    // if (documents !== null) {
     return  bid.documents.map(
            (document) => {
                const docScopeData = {}
                docScopeData.aucId = props.auction.id
                docScopeData.aucStatus = props.auction.status
                docScopeData.aucType = props.auction.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auction?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auction.id)
                }
                docScopeData.aucOwner = props?.auction?.privateData?.isOwner

                docScopeData.bidOwner = procedure.isAwardOwner(props.auction, award)
                docScopeData.docTypesList = getAwardDocTypes(props.auction, award)
                docScopeData.awardStatus = award.status
                docScopeData.typeOf = 'bidAward'
                docScopeData.docList = award.documents

                let bid = procedure.getBidByAward(props.auction?.bids, award)
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auction.id}/bids/${award.bidId}/documents`
                if (props.auction?.privateData?.bids?.length > 0) {
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid?.id}/documents/${document.id}`
                    docScopeData.patchNewDocWithoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/bid/${bid?.id}/documents/${document.id}`
                }
                return (
                    <>
                        <DocItem
                            data={document}
                            scopeData={docScopeData}
                            key={document.id}
                            // dataECP={dataECPMapper(documents, document)}
                            dataECP={dataECPMapper(bid.documents, document)}
                        />
                    </>
                )
            }
        )
    // }
    // return null
}

//////////////////////////////////////////
function viewUserActionButtonsBlock(props, award, count, popupType, setPopupType, quote, setQuote) {
    if (
        props.auction.status !== 'cancelled' &&
        props.auction.status !== 'unsuccessful' &&
        props.auction.status !== 'complete'
    ) {
        if (props.auction?.privateData?.hasBids) {
            return viewBidderActionButtonsBlock(props, award, count, popupType, setPopupType, quote, setQuote)
        }
        if (props.auction?.privateData?.isOwner) {
            return viewOwnerActionButtonsBlock(props, award, count, popupType, setPopupType)
        }
    }
    return null
}

function viewOwnerActionButtonsBlock(props, award, count, popupType, setPopupType) {
    let rejectionProtocolWasAdded = false,
        actWasAdded = false,
        protocolWasAdded = false

    for (let tt = 0; tt < award.documents.length; tt++) {
        if (award.documents[tt].documentType === 'rejectionProtocol') {
            rejectionProtocolWasAdded = true
        }
        if (award.documents[tt].documentType === 'act') {
            actWasAdded = true
        }
        if (award.documents[tt].documentType === 'auctionProtocol') {
            protocolWasAdded = true
        }
    }

    function showRejectionButton() {
        return (
            <div
                className={styles.btnAccept}
                onClick={
                    () => {
                        props.changeAwardRejectPopUpStatus({
                            aucId: props.auction.id,
                            awardId: award.id,
                            status: award.status,
                            awards: count,
                            sellingMethod: props.auction.sellingMethod
                        })
                    }
                }
            >
                Дії з учасником
            </div>
        )
    }

    function showRejectUserDocumentsButton(props, award) {
        return (
            <>
                {
                    popupType === 3
                        ?
                        showAwardStatusPopup(props, award, popupType, setPopupType, 'rejected', `Підтвердити відхилення документів?`, 'Під час відхилення документів виникла помилка')
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(3)
                        }
                    }>
                    Відхилити документи
                </div>
            </>
        )
    }

    function showApproveUserDocumentsButton(props, award) {
        return (
            <>
                {
                    popupType === 2
                        ?
                        showAwardStatusPopup(props, award, popupType, setPopupType, 'waiting', `Підтвердити перевірку документів?`, 'Під час підтвердження перевірки документів виникла помилка')
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(2)
                        }
                    }>
                    Підтвердити документи
                </div>
            </>
        )
    }

    switch (award.status) {
        // case 'pending':
        //     if (!rejectionProtocolWasAdded && !actWasAdded) {
        //         return (
        //             <div className={styles.btnDecline}>
        //                 Дії з учасником
        //             </div>
        //         )
        //     }
        //NO BREAK HERE!
        // eslint-disable-next-line no-fallthrough
        case 'pending':
        case 'pending_waiting':
            if (!protocolWasAdded && !rejectionProtocolWasAdded && !actWasAdded) {
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
            return showRejectionButton()
        case 'verification':
            return (
                <>
                    {(actWasAdded || rejectionProtocolWasAdded) ?
                        showRejectUserDocumentsButton(props, award)
                        : null
                    }
                    {showApproveUserDocumentsButton(props, award)}
                </>
            )
        case 'protocol_signed':
            return (
                <>
                    {actWasAdded ?
                        showRejectionButton()
                        : null
                    }
                </>
            )
        default:
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )

    }
}

function viewBidderActionButtonsBlock(props, award, count, popupType, setPopupType, quote, setQuote) {

    function showApproveFullQuotesButton(props, award) {
        return (
            <>
                {
                    popupType === 4
                        ?
                        showAwardQuotePopup(props, award, setPopupType, quote, setQuote, true)
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(4)
                        }
                    }>
                    Погодитись з повним залишком нерозподіленої квоти
                </div>
            </>
        )
    }

    function showApproveParticularQuotesButton(props, award) {
        return (
            <>
                {
                    popupType === 5
                        ?
                        showAwardQuotePopup(props, award, setPopupType, quote, setQuote, false)
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(5)
                        }
                    }>
                    Погодитись з частиною залишку нерозподіленої квоти
                </div>
            </>
        )
    }

    function showCancelAdmissionButton(props, award) {
        return (
            <>
                {
                    popupType === 6
                        ?
                        showCancelAdmissionPopup(props, award, setPopupType)
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopupType(6)
                        }
                    }>
                    Відмовитись від участі
                </div>
            </>
        )
    }

    if (procedure.isAwardOwner(props.auction, award) && award?.status === 'pending_admission') {
        return (
            <>
                {showCancelAdmissionButton(props, award)}
                <div className={styles.btnBlock}>
                    {showApproveFullQuotesButton(props, award)}
                    {showApproveParticularQuotesButton(props, award)}
                </div>
            </>
        )
    }
    return null
}

function showAwardStatusPopup(props, award, popupType, setPopupType, status, title, errorText) {
    title = title ?? 'Підтвердити?'
    errorText = errorText ?? 'Під час запиту виникла помилка...'
    return (
        <PopAsk
            title={title}
            actionYes={() => {
                props.changeLoader(true)
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/set_status/${status}`
                fetch(endPoint, {
                    method: "POST",
                    headers: getDefaultHeaders(props)
                }).catch(error => {
                    props.changeLoader(false)
                    setPopupType(false)
                    console.error('Api request error:', error);
                    props.setUniversalError(JSON.stringify({
                        title: 'Помилка',
                        message: errorText
                    }))
                }).then(response => {
                    if (response.status === 200) {
                        getUpdateDataAuction(props, props.auction.id)
                        props.changeLoader(false)
                        setPopupType(false)
                        return
                    }
                    props.changeLoader(false)
                    setPopupType(false)
                    console.error('Api request failed with code:', response.status, response);
                    props.setUniversalError(JSON.stringify({
                        title: 'Помилка',
                        message: errorText
                    }))
                })
                setPopupType(false)
            }}
            actionNo={() => {
                setPopupType(false)
            }}
        />)
}

function showAwardQuotePopup(props, award, setPopupType, quote, setQuote, confirmFull) {
    confirmFull = confirmFull ?? false
    let calculatedValue = props?.auction?.x_quantityLimit
    props.auction.awards.map((aw) => {
        if (aw.status === 'pending' || aw.status === 'active') {
            console.log(calculatedValue, aw?.items[0]?.quantity)
            calculatedValue -= aw?.items[0]?.quantity
        }
    })
    if (quote === null) setQuote(calculatedValue)
    return (
        <div className={popupStyles.wrapMain}>
            <div className={popupStyles.wrap}>
                {confirmFull ?
                    <div className={popupStyles.popUpTitle}>Повна частка нерозподіленої квоти становить {calculatedValue} кВт</div>
                    :
                    <>
                        <div className={popupStyles.popUpTitle}>Вкажіть частку нерозподіленої квоти</div>
                        <div className={popupStyles.bottomPart}>
                            <div className={bidStyles.bidLotCost_input}>
                                <input
                                    className={bidStyles.inputNumber}
                                    type='number'
                                    value={quote}
                                    onChange={((e) => {
                                        let value = parseFloat(e.target.value)
                                        value = Math.round((value + Number.EPSILON) * 100) / 100
                                        value = value > calculatedValue ? calculatedValue : value
                                        value = value < 1 ? 1 : value
                                        // console.log(value)
                                        setQuote(value)
                                    })}
                                />
                                <div className={bidStyles.bidLotCost_title}>не більше ніж {calculatedValue} кВт</div>
                            </div>
                        </div>
                    </>
                }
                <div className={popupStyles.bottomPart}>
                    <div className={popupStyles.btnCancel}
                         onClick={
                             () => {
                                 setPopupType(null)
                             }
                         }>
                        Скасувати
                    </div>
                    <div className={popupStyles.btnDone}
                         onClick={
                             () => {
                                 props.changeLoader(true)
                                 const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/confirm_admission`
                                 fetch(endPoint, {
                                     method: "PATCH",
                                     body: JSON.stringify({'quantity': quote}),//@todo SEND OR NOT BODY FOR FULL QUOTE CONFIRM???
                                     headers: getDefaultHeaders(props)
                                 }).catch(error => {
                                     props.changeLoader(false)
                                     setPopupType(false)
                                     console.error('Api request error:', error);
                                     props.setUniversalError(JSON.stringify({
                                         title: 'Помилка',
                                         message: 'Під час запиту виникла помилка'
                                     }))
                                 }).then(response => {
                                     if (response.status === 200) {
                                         getUpdateDataAuction(props, props.auction.id)
                                         props.changeLoader(false)
                                         setPopupType(false)
                                         return
                                     }
                                     props.changeLoader(false)
                                     setPopupType(false)
                                     console.error('Api request failed with code:', response.status, response);
                                     props.setUniversalError(JSON.stringify({
                                         title: 'Помилка',
                                         message: 'Під час запиту виникла помилка'
                                     }))
                                 })
                                 setPopupType(false)
                             }
                         }>
                        Погодитись
                    </div>
                </div>
            </div>
        </div>)
}

function showCancelAdmissionPopup(props, award, setPopupType) {
    return (
        <div className={popupStyles.wrapMain}>
            <div className={popupStyles.wrap}>

                        <div className={popupStyles.popUpTitle}>Ви дійсно хочете відмовитись від участі?</div>

                <div className={popupStyles.bottomPart}>
                    <div className={popupStyles.btnCancel}
                         onClick={
                             () => {
                                 setPopupType(null)
                             }
                         }>
                        Ні
                    </div>
                    <div className={popupStyles.btnDone}
                         onClick={
                             () => {
                                 props.changeLoader(true)
                                 const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auction.id}/award/${award.id}/cancel_admission`
                                 fetch(endPoint, {
                                     method: "POST",
                                     headers: getDefaultHeaders(props)
                                 }).catch(error => {
                                     props.changeLoader(false)
                                     setPopupType(false)
                                     console.error('Api request error:', error);
                                     props.setUniversalError(JSON.stringify({
                                         title: 'Помилка',
                                         message: 'Під час запиту виникла помилка'
                                     }))
                                 }).then(response => {
                                     if (response.status === 200) {
                                         getUpdateDataAuction(props, props.auction.id)
                                         props.changeLoader(false)
                                         setPopupType(false)
                                         return
                                     }
                                     props.changeLoader(false)
                                     setPopupType(false)
                                     console.error('Api request failed with code:', response.status, response);
                                     props.setUniversalError(JSON.stringify({
                                         title: 'Помилка',
                                         message: 'Під час запиту виникла помилка'
                                     }))
                                 })
                                 setPopupType(false)
                             }
                         }>
                        Так
                    </div>
                </div>
            </div>
        </div>)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        auction: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,

    changeContractApprowePopUpStatus,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(AwardEditREM)
