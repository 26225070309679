import React,{useState} from 'react'
import styles from './aucLotItem.module.css'

import { useSelector } from 'react-redux';

import { checkProp, isMongo } from '../../../../../../redux/check'

import TextFull from '../../../../../elements/viewData/textFull'
import TextHalf from '../../../../../elements/viewData/textHalf'
import PopAsk from "../../../../../_popups/popAsk/popAsk";

const AucLotItem = ({ data, edit, del}) => {

    const myAuc = useSelector( state => state.start.auctionsMy)
    const [popDelete, setPopDelete] = useState(false)

    function getContent(a) {
        if(checkProp(a, 'vehicleProps') ){
                return <>
                {
                    checkProp(a.vehicleProps, 'brand') ? 
                    <TextHalf
                        title={'Марка траспортного засобу'}
                        data={a.vehicleProps.brand} 
                    /> : null
                }
                {checkProp(a.vehicleProps, 'model')? 
                    <TextHalf
                        title={'Модель траспортного засобу'}
                        data={a.vehicleProps.model} 
                    /> : null
                }
                </>
        }
        return null
    }



    const delButton = <div className={styles.deleteBtn}
                        onClick={()=> { setPopDelete(true) }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.deleteIcon} fillRule="evenodd" clipRule="evenodd" d="M16.6448 14.4563C17.5006 13.1828 18 11.6496 18 9.99988C18 5.5816 14.4182 1.99988 9.99997 1.99988C8.3488 1.99988 6.81447 2.50011 5.54025 3.35729L16.6448 14.4563ZM15.3278 15.9677L4.02947 4.67499C2.76719 6.08933 1.99997 7.95501 1.99997 9.99988C1.99997 14.4182 5.58169 17.9999 9.99997 17.9999C12.0463 17.9999 13.9131 17.2316 15.3278 15.9677ZM9.99997 -0.00012207C15.5228 -0.00012207 20 4.47703 20 9.99988C20 15.5227 15.5228 19.9999 9.99997 19.9999C4.47712 19.9999 -3.05176e-05 15.5227 -3.05176e-05 9.99988C-3.05176e-05 4.47703 4.47712 -0.00012207 9.99997 -0.00012207Z" fill="#FFA047"/>
                            </svg>
                        </div>

    return (
        <div className={styles.wrapBlock}>
            <div className={styles.wrapBlockTitle}>
                {`${data.classification.scheme} ${data.classification.id} ${isMongo(data.classification.description)}`}
                <div className={styles.blockBtn}>
                    {/*editButton(myAuc)*/}
                { myAuc.status === null ? delButton : null }
                </div>
            </div>
            <div className={styles.wrapContent}>
                {checkProp(data, 'id') ? 
                    <TextHalf
                        title={'ID лота'}
                        data={data.id} 
                    /> : null
                }
                {checkProp(data, 'quantity')  ? 
                    <TextHalf
                        title={'Кількість'}
                        data={`${data.quantity} ${checkProp(data.unit, 'name') ? isMongo(data.unit.name) : ''}`}
                    /> : null
                }
                {getContent(data)}
                {checkProp(data, 'description') ? 
                        <TextFull
                            title={'Опис лоту'}
                            data={isMongo(data.description)} 
                        /> : null
                }
                {checkProp(data, 'address') ? 
                        <TextFull
                            title={'Розташування'}
                            data={`${checkProp(data.address, 'countryName') ? isMongo(data.address.countryName) + ', ' : ''}
                            ${checkProp(data.address, 'region') ? isMongo(data.address.region) + ', ' : ''}
                            ${checkProp(data.address, 'locality') ? isMongo(data.address.locality) : ''}
                            ${checkProp(data.address, 'streetAddress') ? ', ' + isMongo(data.address.streetAddress) : ''}
                            ${checkProp(data.location, 'latitude') && checkProp(data.location, 'longitude') ? ' - ('  + data.location.latitude + ', ' + data.location.longitude +  ')' : ''}
                            `} 
                        /> : null
                }
            </div>
            {
                popDelete ? 
                <PopAsk
                    title={`Ви дійсно хочете видалити лот ${isMongo(data?.classification?.description) ?? '' }?`}
                    actionYes={del}
                    actionNo={()=>{setPopDelete(false)}}
                />
        : null
            }
        </div>
    )
}

export default AucLotItem