export function reverseStr(str) {
    return str.split("").reverse().join("");
}

export function createApostrof(data){
    let tmp = [];
    let count = 0;
    for(let i = 0; i < data.length; i++){
        if(count === 3){
            tmp[i] = '\'' + data[i];
            count = 1;
        }else{
            tmp[i] = data[i];
            count += 1;
        }
        
    }
    return tmp.join('')
}