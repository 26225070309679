import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

const TextFull = (props) => {
    return (
        <div className={styles.blockFull}>
            <div className={styles.description}>{props.title}</div>
            <div><a href={props.data} target='blank'>{props.text}</a></div>
        </div>
    )
}

export default connect(null, null)(TextFull)
