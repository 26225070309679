export function getAPI(data) {
    return async dispatch => {
        const response = await fetch(data.url, {
            method: 'GET', // или 'PUT'
            headers: data.header
        });
        if(response.status === 401){
            if(data.url.indexOf('/token') !== -1){
                dispatch({type: 'TOKEN_CLEAR', payload: null})
            }else{
                dispatch({type: data.actionType, payload: null})    
            }
        }else if(response.status === 500){
            dispatch({type: 'UNIVERSAL_ERROR', payload: '500 помилка'})
        }else{
            const json = await response.json()
            dispatch({type: data.actionType, payload: json})
        }
    }
}

export function postAPI(data) {
    return async dispatch => {
        const response = await fetch(data.url, {
            method: 'POST', // или 'PUT'
            body: data, // данные могут быть 'строкой' или {объектом}!
            headers: data.header
        });
        if(response.status === 401){
            dispatch({type: 'TOKEN_CLEAR', payload: null})
        }else{
            const json = await response.json()
            dispatch({type: data.actionType, payload: json})
        }
    }
}

export function putAPI(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'PUT', // или 'PUT'
                body: data, // данные могут быть 'строкой' или {объектом}!
                headers: data.header
            });
        const json = await response.json()
        dispatch({type: data.actionType, payload: json})
    }
}

export function deleteAPI(data) {
    return async dispatch => {
            const response = await fetch(data.url, {
                method: 'DELETE', // или 'PUT'
                //body: data, // данные могут быть 'строкой' или {объектом}!
                headers: data.header
            });
        const json = await response.json()
        dispatch({type: data.actionType, payload: json})
    }
}
