import React, { useState, useEffect } from 'react';
import styles from './period.module.css';

import InputDate from '../../../../../../../elements/inputs/inputDate';
import WeekDayPeriod from './weekDayPeriod'
import { checkProp } from '../../../../../../../../redux/check';
import AddlIcon from '../../../../../../../elements/svg/addlIcon';
import Button from '../../../../../../../elements/buttons/button'

const Period = ({data, setPeriod, period, index, disabled}) => {

    const [startPeriod, setStartPeriod] = useState(checkProp(data, 'startDate') ? data.startDate : '');
    const [endPeriod, setEndPeriod] = useState(checkProp(data, 'endDate') ? data.endDate : '');
    const [weekDaysPeriod, setWeekDaysPeriod] = useState(checkProp(data, 'weekdays') ? data.weekdays : []);

    useEffect( () => {
        let tmp = period
        for (let key in tmp){
            if (+key === index){
                tmp[key] = {
                    startDate: startPeriod,
                endDate: endPeriod,
                weekdays: weekDaysPeriod
                }
            }
        }
        setPeriod(null)
        setPeriod(tmp)
    }, [startPeriod, endPeriod, weekDaysPeriod]);
    

    function tryPeriodDate(){
        if (Date.parse(startPeriod) > Date.parse(endPeriod)) return true
        return null
    }

    return (
        <div className={styles.period}>
        <div className={styles.periodLabel}>Період</div>
            { tryPeriodDate() ? 
            <div className={styles.fullWidth}>
                <div style={{color: '#FFA047', fontWeight: '600'}}>Дата закінчення періоду не може бути менше дати початку</div>
            </div> : null
            }
            <div className={styles.fullWidth}>
                <div className={styles.halfWidth}>
                    <InputDate
                        data={{
                            label: "Початок періоду",
                            target: startPeriod,
                            time: true
                        }}
                        onChange={ e => setStartPeriod(e) }
                        //disabled={disabled}
                    />
                </div>
                <div className={styles.halfWidth}>
                    <InputDate
                        data={{
                            label: "Кінець періоду",
                            target: endPeriod,
                            alert: tryPeriodDate(),
                            time: true
                        }}
                        onChange={ e => setEndPeriod(e) }
                        //disabled={disabled}
                    />
                </div>
            </div>
            
            {weekDaysPeriod !== null && weekDaysPeriod.length > 0 ?
                weekDaysPeriod.map (
                    (item, count) => {
                        return <WeekDayPeriod 
                                    key={count}
                                    index={count}
                                    data={item}
                                    weekDaysPeriod={weekDaysPeriod}
                                    setWeekDaysPeriod={setWeekDaysPeriod}
                                    //disabled={disabled}
                    />
                    }
                ) : null
            }
            <div className={styles.buttonsBlock}>
                <div className={styles.weekDaysAdd}
                    title='Додати графік'
                    onClick={()=> {setWeekDaysPeriod([...weekDaysPeriod,
                        { weekday: null,
                            endTime: '00:00',
                            startTime: '00:00'
                        }])

                    }}
                    >
                    <AddlIcon
                        style={styles.addSVG}
                        widthHeight={[16, 16]}
                    />
                </div>
                <Button
                    color='gray'
                    onClick={async ()=> {
                        let tmp = period
                        tmp.splice(index, 1)
                        await setPeriod(null)
                        setPeriod(tmp)
                    }}
                    //disabled={disabled}
                >
                    Видалити період
                </Button>
            </div>
            
        </div>
    )
}
export default Period;