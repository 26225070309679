import React from 'react';
import styles from './news.module.css';

import { Link } from 'react-router-dom';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {NEWS_TITLE_TOP, NEWS_TITLE_BOTTOM} from '../../../model/lang/topMenu.js';
import {connect} from 'react-redux';

import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';


import {
    changeCreateItemClassificationAddId,
    getMyAuction,
    changeLoader,
    changeMyCabinetCurentPage,
} from '../../../redux/actions.js';


import {bankAccountGet, bankAccountShemeGet, openBankWindows} from '../../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';

import {localClearToken} from '../../../redux/actions/default/default';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../../redux/actions/itemUnits/itemUnits.js';

import SvgNews from './news.svg';
import SvgArrow from './user_menu_arrowdown.svg';

import {addressesGetMy, setKoatuuRegion} from '../../../redux/actions/address/address.js';
import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../../redux/togetherApi.js';
import {getDefaultHeaders} from "../../_api/bidApi";

const News = (props) => {
    let tmpTypeAccount = null
    if(props.userProfiles !== null){
        if(
            props.userProfiles.canCreateAuction === true ||
            props.userProfiles.canCreateLandAuction === true ||
            props.userProfiles.canCreateLeaseAuction === true ||
            props.userProfiles.canCreateAlienation === true ||
            props.userProfiles.canCreateBasicSell === true ||
            props.userProfiles.canCreateCommercialSellAuction === true ||
            props.userProfiles.canCreateBankruptAuction === true ||
            props.userProfiles.canCreatePrivatization === true ||
            props.userProfiles.canCreateArma === true// ||
        ){
            tmpTypeAccount = 'Мої аукціони'
        }else if(props.userProfiles.canCreateBid === true){
            tmpTypeAccount = 'Мої пропозиції'
        }
    }
    let tmp;
    if(props.token === null){
        tmp = (
            <div className={styles.wrapNews} >
                <a className={styles.korusne_link} href="https://news.dto.com.ua">
                    <div className={styles.wrap_link}>
                        <div className={styles.korusne_img}>
                            <img src={SvgNews} alt='' />
                            <div className={styles.korusne_title}>{NEWS_TITLE_TOP[props.langFlag]}</div>
                        </div>
                        <div className={styles.korusne_text}>{NEWS_TITLE_BOTTOM[props.langFlag]}</div>
                    </div>
                </a>
            </div>
        )
    }else{
        let tmpBalance = null
        if(props.userProfiles !== null){
            tmpBalance = props.userProfiles.balance
        }
        tmp = (
            <div className={styles.wrapNews} >
                <div className={styles.wrap_link}>
                    <div className={styles.korusne_img}>
                        <div className={styles.korusne_title}>МІЙ КАБІНЕТ</div>
                        <img className={styles.svgArrow} src={SvgArrow} alt='' />
                    </div>
                    <div className={styles.korusne_text}>Меню користувача</div>
                    <div className={styles.popUp}>
                        <div className={styles.popUp_menuElem}>Баланс {tmpBalance} грн.</div>
                        <a href="https://www.dto.com.ua/helpful/faq">
                            <div className={styles.popUp_menuElem}>Запитання та відповіді</div>
                        </a>
                        <a href="https://www.dto.com.ua/helpful/presentations">
                            <div className={styles.popUp_menuElem}>Корисне</div>
                        </a>
                        <a href="https://news.dto.com.ua">
                            <div className={styles.popUp_menuElem}>Новини</div>
                        </a>
                        {/*<Link to={`/profile`}>*/}
                        <a href="https://www.dto.com.ua/registration/sellers">
                        {/*<a href="https://beta.dto.com.ua/registration/confirm">*/}
                            <div className={styles.popUp_menuElem}>Профіль</div>
                        </a>
                        {/*</Link>*/}
                        <div
                            className={styles.popUp_menuElem}
                            onClick={
                                () => {
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: props.langFlag,
                                        params: '?limit=25'
                                    }
                                    props.bankAccountGet(data, getDefaultHeaders(props))
                                    props.openBankWindows(true)
                                }
                            }
                        >
                            Банки
                        </div>
                        <Link to={`/create_auctions`}>
                            <div
                                className={styles.popUp_menuElem}
                                onClick={
                                    () => {
                                        props.setDataAddress('all', 'SET_MY_AUCTIONS_STATUS')
                                        props.getMyAuction(null)
                                        props.setPopUpAuctionMyNull()
                                        props.changeAuctionsSwitchWindow(0)
                                            let data1 = {
                                                domen: process.env.REACT_APP_END_POINT_BD,
                                                version: 'v1',
                                                local: props.langFlag,
                                                params: 'timber-english?limit=100'
                                            }
                                            props.unitsGetByType(data1, getDefaultHeaders(props))
                                            props.unitsGetByTypeEnergy(data1, getDefaultHeaders(props))
                                            props.unitsGetByTypeRailway(data1, getDefaultHeaders(props))
                                            props.unitsGetByTypeSubsoil(data1, getDefaultHeaders(props))
                                            props.unitsGetByTypeZU(data1, getDefaultHeaders(props))

                                            //TODO: Нерабочий АПИ 20211018
                                            // props.addressesGetMy(data2, header5)
                                        //}

                                        let data5 = {
                                            domen: process.env.REACT_APP_END_POINT_BD,
                                            version: 'v1',
                                            local: props.langFlag,
                                            params: '?limit=100'
                                        }
                                        props.auctionsGetClassificationsByType(data5, getDefaultHeaders(props))
                                        props.auctionsGetClassificationsByWagonSpecies(data5, getDefaultHeaders(props))
                                        props.auctionsGetClassificationsByTypeNadraEnglish(data5, getDefaultHeaders(props))
                                        props.auctionsGetClassificationsByByShemaSubsoilUsageType(data5, getDefaultHeaders(props))
                                        props.auctionsGetClassificationsByCarryType(data5, getDefaultHeaders(props))
                                        props.auctionsGetClassificationsByTypeRailwayCargoAdd(data5, getDefaultHeaders(props))
                                        
                                        props.auctionsGetClassificationsByTypeRailway(data5, getDefaultHeaders(props))

                                        //TODO: Нерабочий АПИ 20211018

                                        props.auctionsGetWagonRestrict(data5, getDefaultHeaders(props))
                                        props.auctionsGetWagonRestrictType(data5, getDefaultHeaders(props))
                                        
                                        getAdditionalClassificationZU(props)
                                        getAdditionalClassificationCVZU(props)

                                        getData(props)
                                    }
                                }
                            >
                                {tmpTypeAccount}
                            </div>
                        </Link>
                        {/*<Link to={`/finance`}>*/}
                        <a href="https://www.dto.com.ua/cabinet/finance">
                            <div className={styles.popUp_menuElem}>Фінанси</div>
                        </a>
                        {/*</Link>*/}
                        {/*<Link to={`/settings`}>*/}
                        <a href="https://www.dto.com.ua/cabinet/settings">
                            <div className={styles.popUp_menuElem}>Налаштування</div>
                        </a>
                        {/*</Link>*/}
                        <Link to={`/posts`}>
                            <div className={styles.popUp_menuElem}>Повідомлення</div>
                        </Link>
                        <div
                            className={styles.popUp_menuElem + ' ' + styles.popUp_menuElemEnd}
                            onClick={() => {props.localClearToken()}}
                        >
                            Вихід
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return tmp
}

async function getAdditionalClassificationCVZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_CVZU_DATA')
    }
}


async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers:getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}
/////////////////////////////////////////////////////


async function getData(props){
    
    let data = {}
    data.domen = process.env.REACT_APP_END_POINT_BD
    data.version = 'v1'
    data.local = props.langFlag
    data.params = `?offset=0`
    props.changeMyCabinetCurentPage(0)

    props.auctionsBidsGetMy(data, getDefaultHeaders(props))
    props.bankAccountGet(data, getDefaultHeaders(props))
    getAucionsList(props)
    await props.workMyPageCurrentAuction(0)
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length === 10) {
            await props.workMyPageNextAuction(1)
        }else{
            await props.workMyPageNextAuction(null)
        }
    }
}


//@todo duplicate function
async function getAucionsList(props){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-drafts/my`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: getDefaultHeaders(props)
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            const tmp = await createList(json)
            if(tmp.length > 0){
                props.setDataAddress(tmp, 'AUCTIONS_GET_MY')
            }else{
                props.setDataAddress(null, 'AUCTIONS_GET_MY')
            }

        }
    }
    props.changeLoader(false)
}

//@todo duplicate function
function createList(json){
    let tmp = []
    if(json.length > 0){
        for(let i = 0; i < json.length; i++){
            if(json[i].publicAuction !== null){
                tmp[i] = json[i].publicAuction
                tmp[i].dataAuction = json[i]
            }else if(json[i].oldAuctionDraft !== null){
                tmp[i] = json[i].oldAuctionDraft
                tmp[i].dataAuction = json[i]
            }else if(json[i].auctionDraft !== null){
                tmp[i] = json[i].auctionDraft
                tmp[i].dataAuction = json[i]
            }
        }
    }
    return tmp
}



const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token:  state.start.token,
        classificationsGets: state.start.classificationsGets,
        userProfiles: state.start.userProfiles,
        auctionsMy: state.start.auctionsGetMy,
        myCabinetPage: state.start.myCabinetPage,
    }
}

const mapDispatchToProps = {
    auctionsGetMy,
    localClearToken,
    changeAuctionsSwitchWindow,
    unitsGetByType,
    unitsGetByTypeEnergy,
    unitsGetByTypeRailway,
    unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,

    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,

    auctionsBidsGetMy,
    bankAccountGet,
    bankAccountShemeGet,

    changeCreateItemClassificationAddId,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    getMyAuction,
    setPopUpAuctionMyNull,

    workMyPageCurrentAuction,
    workMyPageNextAuction,
    openBankWindows,

    changeMyCabinetCurentPage,
    changeLoader,

    setDataAddress,
    setKoatuuRegion,
}

export default connect(mapStateToProps, mapDispatchToProps)(News)