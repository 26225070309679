import React,{useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import { checkProp } from '../../../../../../redux/check';

import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import InputDate from '../../../../../elements/inputs/inputDate';

//import { setTenderAttemptList, createBodyCSE } from './utils';

import LocationOrg from '../../../../elems/localization/locationOrg';

const RelatedOrganizations = ( {data} ) => {
    const [address, setAdress] = useState(data.i.address)
    const [dateFrom] = useState(checkProp(data.i, 'currentContractTime') && checkProp(data.i.currentContractTime, 'dateFrom') ? data.i.currentContractTime.dateFrom : null)
    const [dateTill] = useState(checkProp(data.i, 'currentContractTime') && checkProp(data.i.currentContractTime, 'dateTill') ? data.i.currentContractTime.dateTill : null)

    useEffect(() => {
        let tmp = [...data.currentTenants]
        tmp[data.count].address = address
        data.setCurrentTenants(tmp)
    }, [address])

    return (
        <div className={styles.costBlock}>
            {/*<div className={styles.costBlockTitle}>{data.name}</div>*/}
            <Input //Повна юридична назва організації або ПІБ
                label='Повна юридична назва організації або ПІБ'
                value={data.i.identifier.legalName.uk_UA}
                disabled={true}
            />
            <div className={styles.orgBlock}>
                <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                <Input //Ідентифікатори організації
                    label='Ідентифікатори організації'
                    value={data.i.identifier.legalName.uk_UA}
                    onChange={(e)=>{ 
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier.legalName.uk_UA = e
                        data.setCurrentTenants(tmp)
                    }}
                    disabled={true}
                />
                <Select 
                    data={{
                        label: 'Тип ідентифікації юридичної особи',
                        target: data.i.identifier.scheme
                    }}
                    onChange={(e) => { 
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier.scheme = e
                        data.setCurrentTenants(tmp)
                    }}
                    options={[
                        {name: 'Обрати', value: null},
                        {name: 'ЄДРПОУ', value: 'UA-EDR'},
                        {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                        {name: 'Паспорт', value: 'UA-PASSPORT'},
                        {name: 'ID-карта', value: 'UA-ID-CARD'},
                        {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                    ]}
                    disabled={true}
                />
                <Input
                    label='Код ЄДРПОУ або ІПН або паспорт'
                    value={data.i.identifier.id}
                    onChange={(e)=>{
                        let tmp = [...data.currentTenants]
                        tmp[data.count].identifier.id = e
                        data.setCurrentTenants(tmp)
                    }}
                    disabled={true}
                />
            </div>
            <LocationOrg 
                address={address}
                setAdress={setAdress}
                dataText='Місцезнаходження Балансоутримувача аукціону'
                disabled={true}
            />
            <Input
                label='Інформація щодо підтвердження повноважень'
                value={data.i.representativeInfo}
                onChange={(e)=>{ 
                    let tmp = [...data.currentTenants]
                    tmp[data.count].representativeInfo = e
                    data.setCurrentTenants(tmp)
                }}
                disabled={true}
            />
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>Дата укладення та закінчення договору оренди</div>
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Період з</div>
                    <InputDate
                        data={{
                            target: dateFrom,
                            time: true
                        }}
                        onChange={(e)=>{ 
                            let tmp = [...data.currentTenants]
                            tmp[data.count].currentContractTime = {
                                dateFrom: e,
                                dateTill: tmp[data.count]?.currentContractTime?.dateTill ? tmp[data.count]?.currentContractTime?.dateTill : null
                            }
                            data.setCurrentTenants(tmp)
                        }}
                    />
                </div>
                <div className={styles.orgBlock}>
                    <div className={styles.costBlockTitle}>Період по</div>
                    <InputDate
                        data={{
                            target: dateTill,
                            time: true
                        }}
                        onChange={(e)=>{ 
                            let tmp = [...data.currentTenants]
                            tmp[data.count].currentContractTime = {
                                dateFrom: tmp[data.count]?.currentContractTime?.dateFrom ? tmp[data.count]?.currentContractTime?.dateFrom : null,
                                dateTill: e
                            }
                            data.setCurrentTenants(tmp)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default RelatedOrganizations