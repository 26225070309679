import React from 'react';

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';

import LandCurrentTenant_LSP from './landCurrentTenant_lsp'

const LandRental = ( props ) => {
    if(props.auctionsMy.minNumberOfQualifiedBids !== 'null')
    return (
        <>
            <LandCurrentTenant_LSP />
        </>
    )
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandRental)
