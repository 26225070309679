import React from 'react';
import styles from './presentations.module.css';

export default function Presentations() {
    return (
        <div className={styles.presentations_wrap}>
            <h1>ІНФОРМАЦІЙНІ МАТЕРІАЛИ</h1>
            <div>
                <a href="https://www.dto.com.ua/files/Prezentaciya_Orenda_170320.pdf" target="_blank" rel="noopener noreferrer">
                    Правила передачі майна в оренду - Презентація ProZorro.Продажі
                </a>
            </div>
            <div>
                <a href="https://www.dto.com.ua/files/QA__Rent.pdf" target="_blank" rel="noopener noreferrer">
                    Запитання та відповіді по оренді майна державної чи комунальної власності через аукціони Prozorro.Продажі
                </a>
            </div>
        </div>
    )
}