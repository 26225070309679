import React from 'react';
import styles from './basicSell.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';

import {setAuctionCategory} from '../../../../redux/actions/support.js';
import {auctionsSearch, auctionsSearchCount, changeCurentPage} from '../../../../redux/actions/auctions/auctions.js';

import {changeMyCurentPage} from '../../../../redux/actions.js';
import {setDataAddress} from '../../../../redux/createAddress';

const Nadra = ( {langFlag, aucyionsPath, search, token, auctionsSearch, setAuctionCategory, changeCurentPage, changeMyCurentPage, setDataAddress, clearSearch} ) => {
    let active = null;
    if(search.category === 'basicSell-english'){
        active = styles.active;
    }
    return (
            <div
                className={styles.aukpoisk_second_elem}
            >
                <Link
                    onClick={
                        ()=>{
                            changeMyCurentPage(0)
                            setAuctionCategory('basicSell-all');
                            setDataAddress('land', 'SEARCH_ITEM_TYPE')
                            changeCurentPage(0);
                            let dataSearch = {}
                            dataSearch.order = search.order
                            dataSearch.region = search.region
                            dataSearch.category = 'basicSell-all'
                            dataSearch.priceCurrency = search.priceCurrency
                            dataSearch.priceFrom = search.priceFrom
                            dataSearch.priceTo = search.priceTo
                            dataSearch.measures = search.measures
                            dataSearch.measuresFrom = search.measuresFrom
                            dataSearch.measuresTo = search.measuresTo
                            dataSearch.itemType = 'land'
                            if(search.status === null || search.status === ''){
                                dataSearch.status = null
                            }else{
                                dataSearch.status = search.status
                            }
                            let data = {
                                domen: process.env.REACT_APP_END_POINT_BD,
                                version: 'v1',
                                local: langFlag,
                                params: '?offset=0'
                            }
                            if(token !== null){
                                let header = {
                                    'Authorization': token.access_token,
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }else{
                                let header = {
                                    'Content-Type': 'application/json'
                                }
                                auctionsSearch(data, header, dataSearch);
                            }
                            clearSearch()
                        }
                    }
                    to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-all')}`}
                >
                    <div className={styles.base + ' ' + active}>
                            <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 55.017 55.017"
                            xmlSpace="preserve"
                        >
                            <g>
                                <path d="M51.688,23.013H40.789c-0.553,0-1,0.447-1,1s0.447,1,1,1h9.102l2.899,27H2.268l3.403-27h9.118c0.553,0,1-0.447,1-1
                                    s-0.447-1-1-1H3.907L0,54.013h55.017L51.688,23.013z"/>
                                <path d="M26.654,38.968c-0.147,0.087-0.304,0.164-0.445,0.255c-0.22,0.142-0.435,0.291-0.646,0.445
                                    c-0.445,0.327-0.541,0.953-0.215,1.398c0.196,0.267,0.5,0.408,0.808,0.408c0.205,0,0.412-0.063,0.591-0.193
                                    c0.178-0.131,0.359-0.257,0.548-0.379c0.321-0.208,0.662-0.403,1.014-0.581c0.468-0.237,0.658-0.791,0.462-1.269
                                    c0.008-0.008,0.018-0.014,0.025-0.022c1.809-1.916,7.905-9.096,10.429-21.058c0.512-2.426,0.627-4.754,0.342-6.919
                                    c-0.86-6.575-4.945-10.051-11.813-10.051c-6.866,0-10.951,3.476-11.813,10.051c-0.284,2.166-0.169,4.494,0.343,6.919
                                    C18.783,29.818,24.783,36.97,26.654,38.968z M17.924,11.314c0.733-5.592,3.949-8.311,9.831-8.311c5.883,0,9.098,2.719,9.83,8.311
                                    c0.255,1.94,0.148,4.043-0.316,6.247C35,28.314,29.59,35.137,27.755,37.207c-1.837-2.072-7.246-8.898-9.514-19.646
                                    C17.776,15.357,17.67,13.255,17.924,11.314z"/>
                                <path d="M27.755,19.925c4.051,0,7.346-3.295,7.346-7.346s-3.295-7.346-7.346-7.346s-7.346,3.295-7.346,7.346
                                    S23.704,19.925,27.755,19.925z M27.755,7.234c2.947,0,5.346,2.398,5.346,5.346s-2.398,5.346-5.346,5.346s-5.346-2.398-5.346-5.346
                                    S24.808,7.234,27.755,7.234z"/>
                                <path d="M31.428,37.17c-0.54,0.114-0.884,0.646-0.769,1.187c0.1,0.47,0.515,0.791,0.977,0.791c0.069,0,0.14-0.007,0.21-0.022
                                    c0.586-0.124,1.221-0.229,1.886-0.313c0.548-0.067,0.938-0.567,0.869-1.115c-0.068-0.549-0.563-0.945-1.115-0.869
                                    C32.763,36.918,32.07,37.033,31.428,37.17z"/>
                                <path d="M36.599,37.576c0.022,0.537,0.466,0.957,0.998,0.957c0.015,0,0.029,0,0.044-0.001l2.001-0.083
                                    c0.551-0.025,0.979-0.493,0.953-1.044c-0.025-0.553-0.539-0.984-1.044-0.954l-1.996,0.083
                                    C37.003,36.557,36.575,37.023,36.599,37.576z"/>
                                <path d="M22.433,42.177c-0.514,0.388-1.045,0.761-1.58,1.107c-0.463,0.301-0.595,0.92-0.294,1.384
                                    c0.191,0.295,0.513,0.455,0.84,0.455c0.187,0,0.375-0.052,0.544-0.161c0.573-0.372,1.144-0.772,1.695-1.188
                                    c0.44-0.333,0.528-0.96,0.196-1.401C23.501,41.936,22.876,41.844,22.433,42.177z"/>
                                <path d="M44.72,35.583c-0.338,0.237-0.777,0.409-1.346,0.526c-0.541,0.111-0.889,0.641-0.777,1.182
                                    c0.098,0.473,0.514,0.798,0.979,0.798c0.067,0,0.135-0.007,0.203-0.021c0.842-0.174,1.526-0.452,2.096-0.853l0.134-0.098
                                    c0.44-0.334,0.527-0.961,0.194-1.401c-0.334-0.44-0.96-0.526-1.401-0.194L44.72,35.583z"/>
                                <path d="M8.86,43.402c0.145-0.533-0.171-1.082-0.704-1.226c-0.529-0.149-1.082,0.169-1.226,0.704
                                    c-0.126,0.464-0.201,0.938-0.225,1.405C6.7,44.4,6.697,44.516,6.697,44.638c0.001,0.196,0.01,0.392,0.029,0.587
                                    c0.053,0.515,0.487,0.898,0.994,0.898c0.033,0,0.067-0.002,0.103-0.005c0.549-0.057,0.949-0.547,0.894-1.097
                                    c-0.014-0.131-0.019-0.264-0.02-0.39c0-0.083,0.003-0.166,0.007-0.248C8.72,44.059,8.772,43.728,8.86,43.402z"/>
                                <path d="M44.698,27.81c-0.794-0.106-1.604-0.041-2.386,0.181c-0.532,0.149-0.841,0.702-0.69,1.233
                                    c0.124,0.441,0.525,0.729,0.961,0.729c0.091,0,0.182-0.012,0.272-0.038c0.52-0.146,1.055-0.192,1.575-0.122
                                    c0.562,0.07,1.052-0.311,1.125-0.857C45.629,28.387,45.245,27.884,44.698,27.81z"/>
                                <path d="M46.688,32.764c-0.163,0.527,0.133,1.088,0.66,1.25c0.099,0.031,0.197,0.045,0.295,0.045c0.428,0,0.823-0.275,0.955-0.705
                                    c0.099-0.318,0.16-0.641,0.183-0.963c0.005-0.083,0.008-0.167,0.008-0.25c0-0.468-0.086-0.937-0.255-1.392
                                    c-0.192-0.519-0.771-0.781-1.285-0.59c-0.519,0.192-0.782,0.768-0.59,1.285c0.086,0.232,0.13,0.467,0.13,0.696l-0.003,0.117
                                    C46.774,32.423,46.742,32.589,46.688,32.764z"/>
                                <path d="M17.481,45.164c-0.586,0.275-1.183,0.53-1.774,0.759c-0.515,0.198-0.771,0.777-0.572,1.293
                                    c0.153,0.396,0.531,0.64,0.933,0.64c0.12,0,0.242-0.021,0.36-0.067c0.635-0.245,1.275-0.519,1.903-0.813
                                    c0.5-0.234,0.715-0.83,0.48-1.33C18.578,45.145,17.984,44.928,17.481,45.164z"/>
                                <path d="M10.201,41.001c0.161,0,0.325-0.039,0.478-0.122c0.288-0.157,0.595-0.255,0.911-0.289c0.135-0.016,0.273-0.016,0.406,0.002
                                    c0.563,0.073,1.05-0.313,1.122-0.86c0.072-0.548-0.313-1.05-0.86-1.122c-0.298-0.039-0.601-0.041-0.891-0.008
                                    c-0.574,0.063-1.128,0.239-1.646,0.521c-0.485,0.265-0.664,0.871-0.399,1.356C9.504,40.813,9.847,41.001,10.201,41.001z"/>
                                <path d="M9.993,48.842c0.216,0.056,0.436,0.098,0.654,0.124c0.256,0.031,0.512,0.047,0.769,0.047c0.313,0,0.627-0.022,0.94-0.062
                                    c0.548-0.069,0.937-0.569,0.867-1.117s-0.567-0.934-1.117-0.867c-0.404,0.052-0.812,0.064-1.216,0.015
                                    c-0.132-0.017-0.264-0.042-0.394-0.075c-0.535-0.143-1.08,0.181-1.22,0.716C9.139,48.158,9.459,48.704,9.993,48.842z"/>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                        </svg>
                    </div>
                    <div className={styles.aukpoisk_second_elem_text}>Базовий продаж. Земельні ділянки</div>
                </Link>
                <div className={styles.aukpoisk_second_elem_1}>
                    <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-english')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('basicSell-english');
                                    setDataAddress('land', 'SEARCH_ITEM_TYPE')
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'basicSell-english'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    dataSearch.itemType = 'land'
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Базовий продаж. Англієць
                        </div>
                    </Link>
                    <Link to={`${aucyionsPath}/basicSell${getUrlFiltersParam(search, 'basicSell-dutch')}`}>
                        <div
                            className={styles.subMenu}
                            onClick={
                                ()=>{
                                    changeMyCurentPage(0)
                                    setAuctionCategory('basicSell-dutch');
                                    setDataAddress('land', 'SEARCH_ITEM_TYPE')
                                    changeCurentPage(0);
                                    let dataSearch = {}
                                    dataSearch.order = search.order
                                    dataSearch.region = search.region
                                    dataSearch.category = 'basicSell-dutch'
                                    dataSearch.priceCurrency = search.priceCurrency
                                    dataSearch.priceFrom = search.priceFrom
                                    dataSearch.priceTo = search.priceTo
                                    dataSearch.measures = search.measures
                                    dataSearch.measuresFrom = search.measuresFrom
                                    dataSearch.measuresTo = search.measuresTo
                                    dataSearch.itemType = 'land'
                                    if(search.status === null || search.status === ''){
                                        dataSearch.status = null
                                    }else{
                                        dataSearch.status = search.status
                                    }
                                    let data = {
                                        domen: process.env.REACT_APP_END_POINT_BD,
                                        version: 'v1',
                                        local: langFlag,
                                        params: '?offset=0'
                                    }
                                    if(token !== null){
                                        let header = {
                                            'Authorization': token.access_token,
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }else{
                                        let header = {
                                            'Content-Type': 'application/json'
                                        }
                                        auctionsSearch(data, header, dataSearch);
                                    }
                                    clearSearch()
                                }
                            }
                        >
                            Базовий продаж. Голандець
                        </div>
                    </Link>
                </div>
            </div>
    )
}

function getUrlFiltersParam(search, type){
    let tmp = null
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.itemType !== null){
        if(tmp === null){
            tmp = '?itemType=land'
        }else{
            tmp += '&itemType=land'
        }
    }
    if(search.region !== null){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    //if(search.category !== null){
        if(tmp === null){
            tmp = `?category=${type}`
        }else{
            tmp += `&category=${type}`
        }
    //}
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }
    if(search.textSting !== null){
        if(tmp === null){
            tmp = '?keyword=' + search.textSting
        }else{
            tmp += '&keyword=' + search.textSting
        }
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchCount,
    setAuctionCategory,
    changeCurentPage,
    changeMyCurentPage,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Nadra)