import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../elements/viewData/textFull'
import TextHalf from '../../elements/viewData/textHalf'

const StringText = (props) => {
    if(props.obj === null) return null
    if(props.obj.hasOwnProperty(props.field) !== true) return null
    if(props.obj[props.field] === null) return null

    if(props.type === 'full'){
        return (
            <TextFull
                title={props.title}
                data={props.obj[props.field]}
            />
        )
    }
    return (
        <TextHalf
            title={props.title}
            data={props.obj[props.field]}
        />
    )
}


const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StringText)