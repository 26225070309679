import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {setDataAddress} from '../../../../redux/createAddress';

const DropDownMenu_Links = (props) => {
    if(props.i.sellingMethod !== null && props.i.sellingMethod !== '' && props.i.auctionId !== null && props.i.auctionId !== ''){
        return (
            <Link to={`/auctions/${props.i.auctionId}`} title="Посилання на аукціон">
                <li
                    onClick={
                        ()=>{
                            getPublicApiAuction(props, props.i.auctionId)
                            props.setDataAddress(String(props.i.auctionId), 'NUMBER_PAGE_LOT')
                            props.dropDomnToggle()
                        }
                    }
                >
                    Перегляд аукціону
                </li>
            </Link>
        )
    }
    return null
}

async function getPublicApiAuction(props, aucId){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${aucId}`
    let header
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownMenu_Links)