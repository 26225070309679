import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

const XAdditionalInformationString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('xAdditionalInformation') === true){
            if(props.auctionId.xAdditionalInformation !== null){
                return (
                    <TextHalf
                        title="Додаткові відомості"
                        data={props.auctionId.xAdditionalInformation}
                    />
                )
            }
        }
        if(props.auctionId.hasOwnProperty('x_additionalInformation') === true){
            if(props.auctionId.x_additionalInformation !== null){
                return (
                    <TextHalf
                        title="Додаткові відомості"
                        data={props.auctionId.x_additionalInformation.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(XAdditionalInformationString)