import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {TranslationsUk} from '../../../redux/translations.uk.js';

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../redux/actions/discount/discount.js';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionCalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
} from '../../../redux/actions.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import RegistrationFee from './windowAuctionRegistrationFee'
import Organization from './zuAddressOrganization'

import CurrentTenantGuaranteeFee from './currentTenantGuaranteeFee'

import {disabledElement} from '../disabled'

import LeaseRules from './ZU/leaseRules'
import RelaitedOrganisations from './ZU/relatedOrganizations'

const WindowAuctionDgf = ( props ) => {
    let tmp;
    let tmpPopUp = null
    if(props.addressNew.openPopUp === true){
        tmpPopUp = <Organization />
    }

    let tmpCurrentTenantGuaranteeFee = null
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        tmpCurrentTenantGuaranteeFee = <CurrentTenantGuaranteeFee />
    }
    if(props.typeElem === 1){
        let tmpValuePeriod = 'null'
        if(props.auctionsMy.valuePeriod !== 'null' && props.auctionsMy.valuePeriod !== null && props.auctionsMy.valuePeriod !== ''){
            tmpValuePeriod = tmpValuePeriod = props.auctionsMy.valuePeriod
        }
        tmp = <>
            {tmpCurrentTenantGuaranteeFee}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText + ' ' + styles.inputTextSmall}
                        id="outlined-select-currency-native"
                        select
                        label='Стартова орендна плата розрахована від балансової вартості'
                        value={props.auctionsMy.calcOnBookValue}
                        onChange={(event)=>{
                            props.changeCreateAuctionCalcOnBookValue(event.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.valueAddedTaxCharged.legalName}
                        defaultValue={props.auctionsMy.valueAddedTaxCharged}
                        onChange={(e)=>{
                            props.changeCreateAuctionValueAddedTaxCharged(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.auctionsMy.registryId)}
                        disabled='disabled'
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Ідентифікатор об'єкта"
                        defaultValue={props.auctionsMy.registryId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionRegistryId(e.target.value)
                                if(e.target.value !== ''){
                                    getObjectId(props, e.target.value)
                                    //createObject(props, e.target.value)
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <RegistrationFee />
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Період розрахунку стартової вартості'
                        value={tmpValuePeriod}
                        onChange={(e)=>{
                            props.changeCreateAuctionValuePeriod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={2} className={styles.hoverOptions} value='hour'>Стартова орендна плата за годину</option>
                        <option key={1} className={styles.hoverOptions} value='day'>Стартова орендна плата за добу</option>
                        <option key={3} className={styles.hoverOptions} value='month'>Стартова орендна плата за місяць</option>
                    </TextField>
                </div>
            </div>
            {viewLesureRules(props)}
            <LeaseRules />
            <RelaitedOrganisations />
            {tmpPopUp}
        </>
    }else{
        let tmpValuePeriod = 'null'
        if(props.auctionsMy.valuePeriod !== 'null' && props.auctionsMy.valuePeriod !== null && props.auctionsMy.valuePeriod !== ''){
            tmpValuePeriod = tmpValuePeriod = props.auctionsMy.valuePeriod
        }
        tmp = <>
            {tmpCurrentTenantGuaranteeFee}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        required
                        className={styles.inputText + ' ' + styles.inputTextSmall}
                        id="outlined-select-currency-native"
                        select
                        label='Стартова орендна плата розрахована від балансової вартості'
                        value={props.auctionsMy.calcOnBookValue}
                        onChange={(event)=>{
                            props.changeCreateAuctionCalcOnBookValue(event.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        required
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.valueAddedTaxCharged.legalName}
                        defaultValue={props.auctionsMy.valueAddedTaxCharged}
                        onChange={(e)=>{
                            props.changeCreateAuctionValueAddedTaxCharged(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.auctionsMy.registryId)}
                        required
                        disabled='disabled'
                        id="filled-required"
                        className={styles.inputText}
                        label="Ідентифікатор об'єкта"
                        defaultValue={props.auctionsMy.registryId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionRegistryId(e.target.value)
                                if(e.target.value !== '') {
                                    //getObjectId(props, e.target.value)
                                    //createObject(props, e.target.value)
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <RegistrationFee />
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Період розрахунку стартової вартості'
                        value={tmpValuePeriod}
                        onChange={(e)=>{
                            props.changeCreateAuctionValuePeriod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={2} className={styles.hoverOptions} value='hour'>Стартова орендна плата за годину</option>
                        <option key={1} className={styles.hoverOptions} value='day'>Стартова орендна плата за добу</option>
                        <option key={3} className={styles.hoverOptions} value='month'>Стартова орендна плата за місяць</option>
                    </TextField>
                </div>
            </div>
            {viewLesureRules(props)}
            <LeaseRules />
            <RelaitedOrganisations />
            {tmpPopUp}
        </>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}


function viewLesureRules(props){
    let tmp = null
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                tmp = <>
                    {viewIntendedUse(props)}
                    <div>Обрані значення</div>
                    {viewArrIntendedUse(props)}
                </>
            }
        }
    }
    if(props.typeElem === 1){
        return <>
            <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.legalName}</div>
            {viewIntendedUseRestrictionMethod(props)}
            {tmp}
            {viewIntendedUseRestrictionDescription(props)}
            {viewAdditionalLeaseConditions(props)}
            {viewSubleaseAllowed(props)}
        </>
    }else{
        return <>
            <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.legalName}</div>
            {viewIntendedUseRestrictionMethod(props)}
            {tmp}
            {viewIntendedUseRestrictionDescription(props)}
            {viewAdditionalLeaseConditions(props)}
            {viewSubleaseAllowed(props)}
        </>
    }
}

function viewIntendedUse(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
            if(props.auctionsMy.leaseRules.intendedUse !== null){
                tmp = props.auctionsMy.leaseRules.intendedUse
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Обмеження щодо використання майна (заборонені цільові призначення)'
                        defaultValue='null'
                        onChange={(e)=>{
                            let tmpArr = null
                            if(props.auctionsMy.leaseRules !== null){
                                if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
                                    if(props.auctionsMy.leaseRules.intendedUse !== null){
                                        tmpArr = props.auctionsMy.leaseRules.intendedUse
                                    }
                                }
                            }
                            if(tmpArr !== null){
                                if(tmpArr.length === 0){
                                    tmpArr = []
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }else{
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }
                            }else{
                                tmp = []
                                tmp.push(e.target.value)
                                props.changeCreateAuctionLeaseRulesIntendedUse(tmp)
                            }
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='agricultureFoodNoAlcoEntp'>Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)</option>
                        <option key={2} className={styles.hoverOptions} value='atelierBeautyServices'>Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню</option>
                        <option key={3} className={styles.hoverOptions} value='authoritiesGovernments'>Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів</option>
                        <option key={4} className={styles.hoverOptions} value='buildingMaterialEntp'>Підприємства з виробництва будівельних матеріалів</option>
                        <option key={5} className={styles.hoverOptions} value='concertEntertExhibEntp'>Підприємства з організації концертно-видовищної та виставкової діяльності</option>
                        <option key={6} className={styles.hoverOptions} value='consumerServicesEntp'>Підприємства побутового обслуговування</option>
                        <option key={7} className={styles.hoverOptions} value='coursesTrainingsEarly'>Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги</option>
                        <option key={8} className={styles.hoverOptions} value='deputyOffice'>Громадська приймальня народного депутата України або депутата місцевої ради</option>
                        <option key={9} className={styles.hoverOptions} value='diplomats'>Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні</option>
                        <option key={10} className={styles.hoverOptions} value='disabilityRehabilitation'>Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ</option>
                        <option key={11} className={styles.hoverOptions} value='education'>Заклади освіти</option>
                        <option key={12} className={styles.hoverOptions} value='electricEquipEntp'>Підприємства з виробництва електричного та електронного устаткування</option>
                        <option key={13} className={styles.hoverOptions} value='electricGasChemicalFerrousEntp'>Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії</option>
                        <option key={14} className={styles.hoverOptions} value='eventsElection'>Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії</option>
                        <option key={15} className={styles.hoverOptions} value='eventsSienceCulturalPublic'>Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів</option>
                        <option key={16} className={styles.hoverOptions} value='exchangeAtmTelecomAdvertizingPress'>Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів</option>
                        <option key={17} className={styles.hoverOptions} value='exhibitions'>Проведення виставок</option>
                        <option key={18} className={styles.hoverOptions} value='ferryRailRoadTranspot'>Розміщення морського, залізничного та автомобільного транспорту</option>
                        <option key={19} className={styles.hoverOptions} value='foodBarRestuarantExcisable'>Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи</option>
                        <option key={20} className={styles.hoverOptions} value='foodBuffetCafeNotExcisable'>Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи</option>
                        <option key={21} className={styles.hoverOptions} value='forestryFisheryEntp'>Підприємства лісового господарства, рибного господарства</option>
                        <option key={22} className={styles.hoverOptions} value='fuelEntp'>Підприємства паливної промисловості</option>
                        <option key={23} className={styles.hoverOptions} value='garmentTextileEntp'>Підприємства з виробництва швейної та текстильної промисловості</option>
                        <option key={24} className={styles.hoverOptions} value='gymSport'>Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту</option>
                        <option key={25} className={styles.hoverOptions} value='healthcarePharmaLaboratory'>Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії</option>
                        <option key={26} className={styles.hoverOptions} value='libraryCinemaTheatre'>Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів</option>
                        <option key={27} className={styles.hoverOptions} value='lotteryEntp'>Підприємства з випуску лотерейних білетів та проведення лотерей</option>
                        <option key={28} className={styles.hoverOptions} value='metallworkLightNoTextileEntp'>Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості</option>
                        <option key={29} className={styles.hoverOptions} value='museums'>Музеї</option>
                        <option key={30} className={styles.hoverOptions} value='ngo'>Громадські об’єднання та благодійні організації</option>
                        <option key={31} className={styles.hoverOptions} value='nightclubRestuarantSaunaHotelInternetclub'>Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе</option>
                        <option key={32} className={styles.hoverOptions} value='noRestuarantRestuarantEntp'>Підприємства ресторанного господарства (крім ресторанів)</option>
                        <option key={33} className={styles.hoverOptions} value='nonEnergyMiningEntp'>Підприємства з видобування неенергетичних матеріалів</option>
                        <option key={34} className={styles.hoverOptions} value='nonFerrousOilGasEntp'>Підприємства кольорової металургії, нафтогазодобувної промисловості</option>
                        <option key={35} className={styles.hoverOptions} value='officePostMassmediaBank'>Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг</option>
                        <option key={36} className={styles.hoverOptions} value='other'>Інше</option>
                        <option key={37} className={styles.hoverOptions} value='otherProduction'>Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність</option>
                        <option key={38} className={styles.hoverOptions} value='parkingCarriageCarRepair'>Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів</option>
                        <option key={39} className={styles.hoverOptions} value='pensionFund'>Пенсійний фонд України та його органи</option>
                        <option key={40} className={styles.hoverOptions} value='publicCultural'>Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)</option>
                        <option key={41} className={styles.hoverOptions} value='pulpPaperWasteEntp'>Підприємства целюлозно-паперової промисловості, переробки відходів</option>
                        <option key={42} className={styles.hoverOptions} value='religion'>Релігійні організації для забезпечення проведення релігійних обрядів та церемоній</option>
                        <option key={43} className={styles.hoverOptions} value='ritualWcRaw'>Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини</option>
                        <option key={44} className={styles.hoverOptions} value='rubberPlasticEntp'>Підприємства з виробництва гумових та пластмасових виробів</option>
                        <option key={45} className={styles.hoverOptions} value='science'>Науково-дослідні установи, наукові парки</option>
                        <option key={46} className={styles.hoverOptions} value='socialServicesLaw'>Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”</option>
                        <option key={47} className={styles.hoverOptions} value='stateMunicipalCultural'>Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв</option>
                        <option key={48} className={styles.hoverOptions} value='stateMunicipalMassMedia'>Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)</option>
                        <option key={49} className={styles.hoverOptions} value='stockArchive'>Склади. Камери схову, архіви</option>
                        <option key={50} className={styles.hoverOptions} value='telecomEntp'>Підприємства зв’язку</option>
                        <option key={51} className={styles.hoverOptions} value='tobaccoAlcoEntp'>Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості</option>
                        <option key={52} className={styles.hoverOptions} value='tradeEntp'>Підприємства торгівлі</option>
                        <option key={53} className={styles.hoverOptions} value='transportAdditionalEntp'>Підприємства з надання додаткових транспортних послуг та допоміжних операцій</option>
                        <option key={54} className={styles.hoverOptions} value='transportProductionServiceEntp'>Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій</option>
                        <option key={55} className={styles.hoverOptions} value='veterans'>Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів</option>
                        <option key={56} className={styles.hoverOptions} value='woodFurnitureEntp'>Підприємства з виробництва деревини та виробів з деревини, меблів</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Обмеження щодо використання майна (заборонені цільові призначення)'
                        defaultValue='null'
                        onChange={(e)=>{
                            let tmpArr = null
                            if(props.auctionsMy.leaseRules !== null){
                                if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
                                    if(props.auctionsMy.leaseRules.intendedUse !== null){
                                        tmpArr = props.auctionsMy.leaseRules.intendedUse
                                    }
                                }
                            }
                            if(tmpArr !== null){
                                if(tmpArr.length === 0){
                                    tmpArr = []
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }else{
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }
                            }else{
                                tmp = []
                                tmp.push(e.target.value)
                                props.changeCreateAuctionLeaseRulesIntendedUse(tmp)
                            }
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='agricultureFoodNoAlcoEntp'>Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)</option>
                        <option key={2} className={styles.hoverOptions} value='atelierBeautyServices'>Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню</option>
                        <option key={3} className={styles.hoverOptions} value='authoritiesGovernments'>Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів</option>
                        <option key={4} className={styles.hoverOptions} value='buildingMaterialEntp'>Підприємства з виробництва будівельних матеріалів</option>
                        <option key={5} className={styles.hoverOptions} value='concertEntertExhibEntp'>Підприємства з організації концертно-видовищної та виставкової діяльності</option>
                        <option key={6} className={styles.hoverOptions} value='consumerServicesEntp'>Підприємства побутового обслуговування</option>
                        <option key={7} className={styles.hoverOptions} value='coursesTrainingsEarly'>Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги</option>
                        <option key={8} className={styles.hoverOptions} value='deputyOffice'>Громадська приймальня народного депутата України або депутата місцевої ради</option>
                        <option key={9} className={styles.hoverOptions} value='diplomats'>Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні</option>
                        <option key={10} className={styles.hoverOptions} value='disabilityRehabilitation'>Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ</option>
                        <option key={11} className={styles.hoverOptions} value='education'>Заклади освіти</option>
                        <option key={12} className={styles.hoverOptions} value='electricEquipEntp'>Підприємства з виробництва електричного та електронного устаткування</option>
                        <option key={13} className={styles.hoverOptions} value='electricGasChemicalFerrousEntp'>Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії</option>
                        <option key={14} className={styles.hoverOptions} value='eventsElection'>Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії</option>
                        <option key={15} className={styles.hoverOptions} value='eventsSienceCulturalPublic'>Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів</option>
                        <option key={16} className={styles.hoverOptions} value='exchangeAtmTelecomAdvertizingPress'>Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів</option>
                        <option key={17} className={styles.hoverOptions} value='exhibitions'>Проведення виставок</option>
                        <option key={18} className={styles.hoverOptions} value='ferryRailRoadTranspot'>Розміщення морського, залізничного та автомобільного транспорту</option>
                        <option key={19} className={styles.hoverOptions} value='foodBarRestuarantExcisable'>Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи</option>
                        <option key={20} className={styles.hoverOptions} value='foodBuffetCafeNotExcisable'>Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи</option>
                        <option key={21} className={styles.hoverOptions} value='forestryFisheryEntp'>Підприємства лісового господарства, рибного господарства</option>
                        <option key={22} className={styles.hoverOptions} value='fuelEntp'>Підприємства паливної промисловості</option>
                        <option key={23} className={styles.hoverOptions} value='garmentTextileEntp'>Підприємства з виробництва швейної та текстильної промисловості</option>
                        <option key={24} className={styles.hoverOptions} value='gymSport'>Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту</option>
                        <option key={25} className={styles.hoverOptions} value='healthcarePharmaLaboratory'>Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії</option>
                        <option key={26} className={styles.hoverOptions} value='libraryCinemaTheatre'>Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів</option>
                        <option key={27} className={styles.hoverOptions} value='lotteryEntp'>Підприємства з випуску лотерейних білетів та проведення лотерей</option>
                        <option key={28} className={styles.hoverOptions} value='metallworkLightNoTextileEntp'>Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості</option>
                        <option key={29} className={styles.hoverOptions} value='museums'>Музеї</option>
                        <option key={30} className={styles.hoverOptions} value='ngo'>Громадські об’єднання та благодійні організації</option>
                        <option key={31} className={styles.hoverOptions} value='nightclubRestuarantSaunaHotelInternetclub'>Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе</option>
                        <option key={32} className={styles.hoverOptions} value='noRestuarantRestuarantEntp'>Підприємства ресторанного господарства (крім ресторанів)</option>
                        <option key={33} className={styles.hoverOptions} value='nonEnergyMiningEntp'>Підприємства з видобування неенергетичних матеріалів</option>
                        <option key={34} className={styles.hoverOptions} value='nonFerrousOilGasEntp'>Підприємства кольорової металургії, нафтогазодобувної промисловості</option>
                        <option key={35} className={styles.hoverOptions} value='officePostMassmediaBank'>Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг</option>
                        <option key={36} className={styles.hoverOptions} value='other'>Інше</option>
                        <option key={37} className={styles.hoverOptions} value='otherProduction'>Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність</option>
                        <option key={38} className={styles.hoverOptions} value='parkingCarriageCarRepair'>Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів</option>
                        <option key={39} className={styles.hoverOptions} value='pensionFund'>Пенсійний фонд України та його органи</option>
                        <option key={40} className={styles.hoverOptions} value='publicCultural'>Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)</option>
                        <option key={41} className={styles.hoverOptions} value='pulpPaperWasteEntp'>Підприємства целюлозно-паперової промисловості, переробки відходів</option>
                        <option key={42} className={styles.hoverOptions} value='religion'>Релігійні організації для забезпечення проведення релігійних обрядів та церемоній</option>
                        <option key={43} className={styles.hoverOptions} value='ritualWcRaw'>Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини</option>
                        <option key={44} className={styles.hoverOptions} value='rubberPlasticEntp'>Підприємства з виробництва гумових та пластмасових виробів</option>
                        <option key={45} className={styles.hoverOptions} value='science'>Науково-дослідні установи, наукові парки</option>
                        <option key={46} className={styles.hoverOptions} value='socialServicesLaw'>Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”</option>
                        <option key={47} className={styles.hoverOptions} value='stateMunicipalCultural'>Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв</option>
                        <option key={48} className={styles.hoverOptions} value='stateMunicipalMassMedia'>Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)</option>
                        <option key={49} className={styles.hoverOptions} value='stockArchive'>Склади. Камери схову, архіви</option>
                        <option key={50} className={styles.hoverOptions} value='telecomEntp'>Підприємства зв’язку</option>
                        <option key={51} className={styles.hoverOptions} value='tobaccoAlcoEntp'>Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості</option>
                        <option key={52} className={styles.hoverOptions} value='tradeEntp'>Підприємства торгівлі</option>
                        <option key={53} className={styles.hoverOptions} value='transportAdditionalEntp'>Підприємства з надання додаткових транспортних послуг та допоміжних операцій</option>
                        <option key={54} className={styles.hoverOptions} value='transportProductionServiceEntp'>Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій</option>
                        <option key={55} className={styles.hoverOptions} value='veterans'>Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів</option>
                        <option key={56} className={styles.hoverOptions} value='woodFurnitureEntp'>Підприємства з виробництва деревини та виробів з деревини, меблів</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function viewArrIntendedUse(props){
    let tmp = null
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
            if(props.auctionsMy.leaseRules.intendedUse !== null && props.auctionsMy.leaseRules.intendedUse !== 'null'){
                tmp = props.auctionsMy.leaseRules.intendedUse
                return tmp.map(
                    (i, count) => {
                        return (
                            <div>
                                {viewElem(props, i)}
                                <Button
                                    disabled={disabledElement(props.auctionsMy, '01')}
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            let tmpS = []
                                            let countS = 0
                                            for(let t = 0; t < props.auctionsMy.leaseRules.intendedUse.length; t++){
                                                if(count !== t){
                                                    tmpS[countS] = props.auctionsMy.leaseRules.intendedUse[t]
                                                    countS++
                                                }
                                            }
                                            props.changeCreateAuctionLeaseRulesIntendedUse(tmpS)
                                        }
                                    }
                                >
                                    Видалити
                                </Button>
                            </div>
                        )
                    }
                )
            }
        }
    }
}
function viewElem(props, data){
    const arrIntendedUse = [
        {value: 'agricultureFoodNoAlcoEntp', name: "Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)"},
        {value: 'atelierBeautyServices', name: "Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню"},
        {value: 'authoritiesGovernments', name: "Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів"},
        {value: 'buildingMaterialEntp', name: "Підприємства з виробництва будівельних матеріалів"},
        {value: 'concertEntertExhibEntp', name: "Підприємства з організації концертно-видовищної та виставкової діяльності"},
        {value: 'consumerServicesEntp', name: "Підприємства побутового обслуговування"},
        {value: 'coursesTrainingsEarly', name: "Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги"},
        {value: 'deputyOffice', name: "Громадська приймальня народного депутата України або депутата місцевої ради"},
        {value: 'diplomats', name: "Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні"},
        {value: 'disabilityRehabilitation', name: "Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ"},
        {value: 'education', name: "Заклади освіти"},
        {value: 'electricEquipEntp', name: "Підприємства з виробництва електричного та електронного устаткування"},
        {value: 'electricGasChemicalFerrousEntp', name: "Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії"},
        {value: 'eventsElection', name: "Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії"},
        {value: 'eventsSienceCulturalPublic', name: "Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів"},
        {value: 'exchangeAtmTelecomAdvertizingPress', name: "Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів"},
        {value: 'exhibitions', name: "Проведення виставок"},
        {value: 'ferryRailRoadTranspot', name: "Розміщення морського, залізничного та автомобільного транспорту"},
        {value: 'foodBarRestuarantExcisable', name: "Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи"},
        {value: 'foodBuffetCafeNotExcisable', name: "Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи"},
        {value: 'forestryFisheryEntp', name: "Підприємства лісового господарства, рибного господарства"},
        {value: 'fuelEntp', name: "Підприємства паливної промисловості"},
        {value: 'garmentTextileEntp', name: "Підприємства з виробництва швейної та текстильної промисловості"},
        {value: 'gymSport', name: "Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту"},
        {value: 'healthcarePharmaLaboratory', name: "Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії"},
        {value: 'libraryCinemaTheatre', name: "Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів"},
        {value: 'lotteryEntp', name: "Підприємства з випуску лотерейних білетів та проведення лотерей"},
        {value: 'metallworkLightNoTextileEntp', name: "Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості"},
        {value: 'museums', name: "Музеї"},
        {value: 'ngo', name: "Громадські об’єднання та благодійні організації"},
        {value: 'nightclubRestuarantSaunaHotelInternetclub', name: "Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе"},
        {value: 'noRestuarantRestuarantEntp', name: "Підприємства ресторанного господарства (крім ресторанів)"},
        {value: 'nonEnergyMiningEntp', name: "Підприємства з видобування неенергетичних матеріалів"},
        {value: 'nonFerrousOilGasEntp', name: "Підприємства кольорової металургії, нафтогазодобувної промисловості"},
        {value: 'officePostMassmediaBank', name: "Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг"},
        {value: 'other', name: "Інше"},
        {value: 'otherProduction', name: "Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність"},
        {value: 'parkingCarriageCarRepair', name: "Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів"},
        {value: 'pensionFund', name: "Пенсійний фонд України та його органи"},
        {value: 'publicCultural', name: "Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)"},
        {value: 'pulpPaperWasteEntp', name: "Підприємства целюлозно-паперової промисловості, переробки відходів"},
        {value: 'religion', name: "Релігійні організації для забезпечення проведення релігійних обрядів та церемоній"},
        {value: 'ritualWcRaw', name: "Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини"},
        {value: 'rubberPlasticEntp', name: "Підприємства з виробництва гумових та пластмасових виробів"},
        {value: 'science', name: "Науково-дослідні установи, наукові парки"},
        {value: 'socialServicesLaw', name: "Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”"},
        {value: 'stateMunicipalCultural', name: "Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв"},
        {value: 'stateMunicipalMassMedia', name: "Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)"},
        {value: 'stockArchive', name: "Склади. Камери схову, архіви"},
        {value: 'telecomEntp', name: "Підприємства зв’язку"},
        {value: 'tobaccoAlcoEntp', name: "Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості"},
        {value: 'tradeEntp', name: "Підприємства торгівлі"},
        {value: 'transportAdditionalEntp', name: "Підприємства з надання додаткових транспортних послуг та допоміжних операцій"},
        {value: 'transportProductionServiceEntp', name: "Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій"},
        {value: 'veterans', name: "Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів"},
        {value: 'woodFurnitureEntp', name: "Підприємства з виробництва деревини та виробів з деревини, меблів"},
    ]
    return arrIntendedUse
    .filter(
        (i) => {
            return i.value === data
        }
    )
    .map(
        (i) => {
            return (
                <div>{i.name}</div>
            )
        }
    )
}

function viewIntendedUseRestrictionMethod(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod !== null){
                tmp = props.auctionsMy.leaseRules.intendedUseRestrictionMethod
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="Цільове призначення об'єкта оренди (за наявності)"
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='exceptDescribed'>Окрім зазначеного</option>
                        <option key={2} className={styles.hoverOptions} value='noRestrictions'>Без обмежень</option>
                        <option key={3} className={styles.hoverOptions} value='onlyDescribed'>Тільки зазначене</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="Цільове призначення об'єкта оренди (за наявності)"
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='exceptDescribed'>Окрім зазначеного</option>
                        <option key={2} className={styles.hoverOptions} value='noRestrictions'>Без обмежень</option>
                        <option key={3} className={styles.hoverOptions} value='onlyDescribed'>Тільки зазначене</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function viewIntendedUseRestrictionDescription(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionDescription') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionDescription !== null){
                tmp = props.auctionsMy.leaseRules.intendedUseRestrictionDescription
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.intendedUseRestrictionDescription.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.intendedUseRestrictionDescription.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }
}

function viewAdditionalLeaseConditions(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('additionalLeaseConditions') === true){
            if(props.auctionsMy.leaseRules.additionalLeaseConditions !== null){
                tmp = props.auctionsMy.leaseRules.additionalLeaseConditions
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.additionalLeaseConditions.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesAdditionalLeaseConditions(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.additionalLeaseConditions.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesAdditionalLeaseConditions(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }
}

function viewSubleaseAllowed(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('subleaseAllowed') === true){
            if(props.auctionsMy.leaseRules.subleaseAllowed !== null){
                tmp = props.auctionsMy.leaseRules.subleaseAllowed
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.subleaseAllowed.legalName}
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.subleaseAllowed.legalName}
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function setOption(props){
    if(props.addressNew.getAddress !== null){
        return props.addressNew.getAddress.map(
            (e, count) => {
                //return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.name}</option>
                return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.contactPoint.name}</option>
            }
        )
    }
}

async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPreviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

async function getObjectId(props, objId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/bridge/${objId}/raw`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            if(json.hasOwnProperty('leaseRules') === true){
                if(json.leaseRules !== null){
                    if(json.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
                        if(json.leaseRules.intendedUseRestrictionMethod !== null){
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(json.leaseRules.intendedUseRestrictionMethod)
                        }
                    }
                    if(json.leaseRules.hasOwnProperty('subleaseAllowed') === true){
                        if(json.leaseRules.subleaseAllowed !== null){
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(json.leaseRules.subleaseAllowed)
                        }
                    }
                    if(json.leaseRules.hasOwnProperty('schedule') === true){
                        if(json.leaseRules.schedule !== null){
                            if(json.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                                if(json.leaseRules.schedule.daysBy !== null){
                                    if(json.leaseRules.schedule.daysBy.hasOwnProperty('by') === true){
                                        if(json.leaseRules.schedule.daysBy.by !== null){
                                            props.setDataAddress(json.leaseRules.schedule.daysBy.by, 'SET_SCHEDULE_DAYS_BY_BY')
                                        }
                                    }
                                    if(json.leaseRules.schedule.daysBy.hasOwnProperty('value') === true){
                                        if(json.leaseRules.schedule.daysBy.value !== null){
                                            props.setDataAddress(json.leaseRules.schedule.daysBy.value, 'SET_SCHEDULE_DAYS_BY_VALUE')
                                        }
                                    }
                                }
                            }
                            if(json.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                                if(json.leaseRules.schedule.hoursBy !== null){
                                    if(json.leaseRules.schedule.hoursBy.hasOwnProperty('by') === true){
                                        if(json.leaseRules.schedule.hoursBy.by !== null){
                                            props.setDataAddress(json.leaseRules.schedule.hoursBy.by, 'SET_SCHEDULE_HOURS_BY_BY')
                                        }
                                    }
                                    if(json.leaseRules.schedule.hoursBy.hasOwnProperty('value') === true){
                                        if(json.leaseRules.schedule.hoursBy.value !== null){
                                            props.setDataAddress(json.leaseRules.schedule.hoursBy.value, 'SET_SCHEDULE_HOURS_BY_VALUE')
                                        }
                                    }
                                }
                            }
                            if(json.leaseRules.schedule.hasOwnProperty('period') === true){
                
                            }
                        }
                    }
                }
            }
            if(json.hasOwnProperty('relatedOrganizations') === true){
                if(json.relatedOrganizations !== null){
                    if(json.relatedOrganizations.hasOwnProperty('ownershipType') === true){
                        if(json.relatedOrganizations.ownershipType !== null){
                            props.setDataAddress(json.relatedOrganizations.ownershipType, 'SET_AUCTION_OWNERSHIP_TYPE')
                        }
                    }
                }
            }
            props.setDataAddress(json, 'SET_OBJECT_REESTR')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        objectReestr: state.start.objectReestr,
        shedullePeriod: state.start.shedullePeriod,
        koatuu: state.start.koatuu,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionCalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
    setDataAddress,

    setEditAuctionFromId,
    changeDiscountPreviousAuctionId,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPercent,

    getMyAuction,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDgf)
