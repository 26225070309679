// EDIT BTN
export function viewCancellationEditDoc(props) {
    if (props.data.documentOf === 'cancellation') {
        if (props.scope.aucOwner === true) {
            return true
        }
    }
    return false
}


