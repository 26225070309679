import React from "react";
import styles from './button.module.css';

const Button =({data, color, onClick, height, font, disabled, children })=> {

    function setColor(color){
        if (disabled) return styles.disabled
        switch(color){
            case 'blue': return styles.blue
            case 'gray': return styles.gray
            case 'white': return styles.white
            default: return styles.blue
        }
    }

        return (
        <button
            style={{height: height, fontSize: font}}
            className={setColor(color)}
            color="primary"
            onClick={!disabled ? onClick : null}
            disabled={disabled}
        >
            {data}
            {children}
        </button>)
}

export default Button