import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import { viewAuctionType } from '../../redux/procedure';

const ProcedureType = (props) => {

    return (<div className={styles.aucType}>{viewAuctionType(props.auctionId.sellingMethod)}</div>)
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ProcedureType)