import React from 'react';

import {connect} from 'react-redux';
import TextFull from '../../../elements/viewData/textFull';


const DescriptionString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('description') === true){
            if(props.auctionId.description !== null){
                return (
                    <TextFull
                        title="Опис аукціону"
                        data={props.auctionId.description.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(DescriptionString)