export function setHeaderApiSignAndNotSign(value) {
    if(value.token !== null){
        return {
            'Authorization': value.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        return {
            'Content-Type': 'application/json'
        }
    }
}