import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'
import TextHalf from '../../../elements/viewData/textHalf'

import {viewStringValue} from '../../../../api/valueToString.js'

const Discount = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('discount') === true){
            if(props.auctionId.discount !== null){
                return (
                    <>
                        <TextFull
                            title="Знижка:"
                            data=''
                        />
                        {discountPercent(props.auctionId.discount)}
                        {previousAuctionValue(props.auctionId.discount)}
                    </>
                )
            }
        }
    }
    return null
}

function previousAuctionValue(data){
    if(data !== null){
        if(data.hasOwnProperty('previousAuctionValue') === true){
            if(data.previousAuctionValue !== null){
                let tmp = viewStringValue(data.previousAuctionValue)
                return (
                    <>
                        <TextFull
                            title="Стартова вартість попереднього аукціону:"
                            data={tmp}
                        />
                        {/*<TextHalf
                            title="Валюта"
                            data={typeValut(data.previousAuctionValue.currency)}
                        />
                        <TextHalf
                            title="Сума"
                            data={tmp}
                        />
                        {data.previousAuctionValue.valueAddedTaxIncluded ?
                            <TextHalf
                                title="Податок"
                                data='Так'
                            /> : <TextHalf
                            title="Податок"
                            data='Ні'
                        />*/}
                    </>
                )
            }
        }
    }
    return null
}

function discountPercent(data){
    return (
        <TextHalf
            title="Розмір знижки від попереднього аукціону, %"
            data={data.discountPercent}
        />
    )
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(Discount)