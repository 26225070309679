import React from 'react';
import styles from './scrap.module.css';

import {
    SECONDARY_RAW_MATERIALS_SCRAP_CATEGORY_MENU_LANG,
} from '../../../../model/lang/iconCategoryMenu.js';
import {connect} from 'react-redux';

const Scrap = ( {langFlag} ) => {
    return (
        <div className={styles.aukpoisk_second_elem}>
            <a href='https://www.dto.com.ua/tenders/current/scrap'>
			<div className={styles.base}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g>
	<g>
		<path d="M505.061,394.613l-52.931-52.932l9.288-19.257c2.786-5.775,3.156-12.291,1.042-18.344
			c-2.114-6.053-6.459-10.921-12.235-13.707c-9.186-4.432-20.201-2.564-27.408,4.644c-1.905,1.906-3.468,4.109-4.643,6.549
			l-2.004,4.155l-16.859-16.859l9.288-19.255c2.786-5.775,3.157-12.29,1.044-18.344c-2.114-6.054-6.459-10.922-12.235-13.708
			c-9.183-4.429-20.199-2.565-27.408,4.644c-1.906,1.906-3.469,4.11-4.644,6.549l-2.004,4.155l-16.859-16.859l9.288-19.257
			c2.786-5.775,3.157-12.29,1.044-18.344c-2.114-6.054-6.459-10.922-12.235-13.708c-9.182-4.428-20.198-2.565-27.407,4.643
			c-0.001,0-0.001,0-0.002,0.001c-1.905,1.905-3.468,4.108-4.644,6.549l-2.004,4.155l-16.859-16.858l9.288-19.257
			c2.786-5.775,3.156-12.291,1.042-18.344c-2.114-6.053-6.459-10.921-12.234-13.707c-9.184-4.431-20.2-2.566-27.409,4.644
			c-3.069,3.07-4.795,6.865-6.647,10.704l-22.369-22.37l39.609-39.609c9.358-9.36,9.358-24.59,0-33.951L231.193,7.578
			c-9.255-9.254-24.696-9.254-33.951,0l-48.402,48.403c-3.125,3.125-3.125,8.192,0,11.316c3.126,3.125,8.192,3.125,11.317,0
			l13.319-13.319l55.074,55.075l-86.762,86.762L86.714,140.74l45.587-45.587c3.125-3.125,3.125-8.192,0-11.316
			c-3.126-3.125-8.192-3.125-11.317,0l-80.667,80.668c-9.255,9.254-9.255,24.696-0.001,33.951l43.759,43.759
			c9.24,9.24,24.711,9.239,33.951,0l39.609-39.609l49.505,49.505l-9.289,19.258c-5.75,11.922-0.729,26.301,11.193,32.051
			c5.71,2.756,12.354,3.136,18.341,1.046c6.056-2.113,10.926-6.46,13.711-12.238l2.004-4.155l16.858,16.859l-9.288,19.255
			c-3.287,6.815-3.138,14.898,0.367,21.601c3.17,6.061,8.925,10.524,15.563,12.159c4.487,1.104,9.244,0.859,13.605-0.663
			c6.056-2.113,10.925-6.46,13.71-12.238l2.004-4.155l16.858,16.859l-9.288,19.255c-4.393,9.107-2.496,20.329,4.682,27.457
			c7.408,7.358,19.035,9.052,28.251,4.156c4.482-2.381,8.109-6.184,10.311-10.754l2.004-4.155l16.859,16.859l-9.289,19.256
			c-4.393,9.105-2.495,20.329,4.682,27.457c7.408,7.358,19.035,9.052,28.251,4.156c4.482-2.381,8.109-6.184,10.311-10.754
			l2.005-4.156l25.795,25.795c9.255,9.254,24.696,9.254,33.951,0l43.757-43.758C514.313,419.31,514.313,403.868,505.061,394.613z
			 M106.709,230.897c-3.121,3.12-8.197,3.121-11.317,0l-43.759-43.759c-3.083-3.085-3.082-8.233,0.001-11.316l23.765-23.766
			l55.075,55.075L106.709,230.897z M184.795,42.661l23.765-23.766c3.082-3.083,8.233-3.083,11.315,0l43.759,43.758
			c3.121,3.12,3.121,8.197,0,11.317L239.87,97.735L184.795,42.661z M168.951,191.288l55.074-55.074l26.321,26.322l-35.842,74.306
			L168.951,191.288z M226.68,285.275c-2.069,4.292-7.594,5.82-11.587,3.218c-3.32-2.164-4.547-6.607-2.829-10.172l61.867-128.258
			c1.898-3.933,6.749-5.616,10.683-3.73c3.929,1.884,5.624,6.758,3.731,10.683L226.68,285.275z M250.465,272.803l35.842-74.307
			l16.859,16.858l-35.842,74.308L250.465,272.803z M279.499,338.094c-1.895,3.932-6.748,5.619-10.685,3.729
			c-3.927-1.885-5.623-6.758-3.73-10.683l61.866-128.258c1.859-3.86,6.611-5.587,10.517-3.806c4.028,1.836,5.827,6.758,3.898,10.76
			L279.499,338.094z M303.284,325.622l35.842-74.307l16.859,16.859l-35.842,74.307L303.284,325.622z M332.318,390.914
			c-1.895,3.932-6.746,5.618-10.683,3.728c-3.927-1.885-5.624-6.757-3.731-10.683l61.866-128.258
			c1.895-3.934,6.752-5.616,10.685-3.73c3.927,1.884,5.623,6.758,3.73,10.683L332.318,390.914z M356.102,378.44l35.842-74.308
			l16.859,16.859l-35.843,74.307L356.102,378.44z M385.137,443.733c-1.895,3.932-6.747,5.618-10.683,3.728
			c-3.927-1.885-5.624-6.757-3.731-10.683l61.867-128.258c1.897-3.933,6.75-5.618,10.683-3.73c3.927,1.884,5.624,6.758,3.731,10.683
			L385.137,443.733z M493.742,417.247l-43.757,43.758c-1.512,1.512-3.522,2.344-5.659,2.344c-2.138,0-4.147-0.832-5.658-2.344
			l-29.747-29.747l35.842-74.307l48.979,48.98c1.511,1.512,2.343,3.521,2.343,5.658S495.254,415.735,493.742,417.247z"/>
	</g>
</g>
<g>
	<g>
		<path d="M229.59,395.33l-46.2-80.022c-4.275-7.404-12.242-12.003-20.791-12.003H70.197c-8.549,0-16.515,4.6-20.79,12.003
			l-46.2,80.022c-4.275,7.404-4.275,16.603,0,24.007l46.2,80.022c4.275,7.404,12.241,12.003,20.79,12.003h13.768
			c4.42,0,8.002-3.583,8.002-8.002c0-4.419-3.582-8.002-8.002-8.002H70.197c-2.85,0-5.504-1.533-6.93-4.001l-46.2-80.022
			c-1.425-2.468-1.425-5.534,0-8.002l46.2-80.022c1.425-2.468,4.08-4.001,6.93-4.001h92.402c2.85,0,5.505,1.533,6.931,4.001
			l46.2,80.022c1.425,2.468,1.425,5.534,0,8.002l-46.2,80.022c-1.425,2.468-4.081,4.001-6.931,4.001h-35.954
			c-4.42,0-8.002,3.583-8.002,8.002c0,4.419,3.582,8.002,8.002,8.002h35.954c8.55,0,16.515-4.6,20.791-12.003l46.2-80.022
			C233.865,411.933,233.865,402.734,229.59,395.33z"/>
	</g>
</g>
<g>
	<g>
		<path d="M204.641,403.333l-40.656-70.419c-1.43-2.475-4.07-4.001-6.93-4.001H75.742c-2.859,0-5.5,1.526-6.93,4.001l-40.655,70.419
			c-1.43,2.475-1.43,5.527,0,8.002l40.655,70.419c1.43,2.475,4.07,4.001,6.93,4.001h81.313c2.858,0,5.5-1.525,6.93-4.001
			l40.656-70.419C206.071,408.859,206.071,405.808,204.641,403.333z M152.435,469.751H80.362l-36.036-62.417l36.036-62.417h72.073
			l36.037,62.417L152.435,469.751z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</div>
                <div className={styles.aukpoisk_second_elem_text}>{SECONDARY_RAW_MATERIALS_SCRAP_CATEGORY_MENU_LANG[langFlag]}</div>
            </a>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        token: state.start.token,
        searchCount: state.start.searchCount,
    }
}

export default connect(mapStateToProps, null)(Scrap)